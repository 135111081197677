import React, { useEffect, useState } from "react";
import moment from "moment";
import { DB } from "../../../../../firebase";
import { Typography } from "@material-ui/core";
import DashboardCard from "../../general/DashboardCard";
import { UserRole } from "../../../../../firestore/models";

const PluginCheckoutV2 = ({ type = "day" }: { type: "month" | "day" }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const dateString =
    type === "day"
      ? moment().set("hour", 1).set("minute", 0).toDate().toISOString()
      : moment()
          .startOf("month")
          .set("hour", 1)
          .set("minute", 0)
          .toDate()
          .toISOString();
  const [checkoutSize, setCheckoutSize] = useState<number>(0);

  useEffect(() => {
    const loadData = () => {
      DB()
        .collection("webhook")
        .where("event_type", "==", "exs.order.completed")
        .where("created_at", ">", dateString)
        .get()
        .then((docs) => {
          let counter = 0;
          docs.forEach((doc) => {
            const data = doc.data();
            if (data?.resource?.metas) {
              if (
                ["prod", "free"].includes(
                  data?.resource?.metas?.license_type
                ) &&
                data?.resource?.metas?.order_type === "create"
              ) {
                counter++;
              }
            }
          });
          setCheckoutSize(counter);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    loadData();
    const interval = setInterval(loadData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <DashboardCard
        title="Checkouts"
        headerChip={type === "day" ? `Heute` : `Monat`}
        theme="plugin"
        loading={loading}
        role={UserRole.Developer}
      >
        <div
          style={{
            width: "100%",
            minWidth: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              fontSize: 28,
              fontFamily: "Roboto",
              fontWeight: `500`,
            }}
          >
            {`${checkoutSize}`}
          </Typography>
        </div>
      </DashboardCard>
    </>
  );
};

export default PluginCheckoutV2;
