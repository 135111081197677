import { Button } from "@material-ui/core";
import React, { useState } from "react";
import CreateModal from "./CreateModal";

const ActionBar = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openCreateModal = () => {
    setModalOpen(true);
  };

  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
      }}
      className="flex"
    >
      <Button variant="outlined" onClick={openCreateModal}>
        API-Key erstellen
      </Button>
      <CreateModal
        close={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
      />
    </div>
  );
};

export default ActionBar;
