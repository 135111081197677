import React from "react";
import {Replay} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {functions} from "../../../firebase";

type RetryProps = {
  id: string
  text?: string | null
}

const Retry = ({id, text = 'retry'}: RetryProps) => {

  const handleRetry = () => {
    const handleWebhook = functions().httpsCallable('webhook-jtl-handleWebhookCall')
    handleWebhook(id)
        .then(value => console.log(value))
        .catch(console.log)
    return true;
  }

  return text ? <Button startIcon={<Replay/>} variant={"outlined"} title={"retry"} color={"primary"}
                        onClick={handleRetry}>{text}</Button> :
      <Button variant={"outlined"} title={"retry"} color={"primary"}
              onClick={handleRetry}><Replay/></Button>;
}

export default Retry;