import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import useAuth from "../../../../hooks/useAuth";
import TimeAgo from "react-timeago";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { ICON_STYLE } from "../../../../utils/constants";

const User = () => {
  const auth = useAuth();
  return (
    <Grid
      container
      spacing={2}
      style={{ flexGrow: 1, width: "100%" }}
      justifyContent={"flex-start"}
    >
      <Grid item xs={12} lg={12}>
        <Avatar src={auth.photoURL} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "lighter",
          }}
        >
          {`UserID:`}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          {auth.uid}
        </Typography>
      </Grid>
      {auth?.displayName && (
        <>
          <Grid item xs={12} lg={3}>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "lighter",
              }}
            >
              {`DisplayName:`}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              {auth.displayName}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} lg={3}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "lighter",
          }}
        >
          {`E-Mail:`}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          {auth.email}{" "}
          {auth.emailVerified ? (
            <FontAwesomeIcon
              color={"green"}
              icon={[ICON_STYLE, "check-circle"]}
            />
          ) : (
            <FontAwesomeIcon
              color={"red"}
              icon={[ICON_STYLE, "times-circle"]}
            />
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "lighter",
          }}
        >
          {`Last Login:`}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          <TimeAgo date={moment(parseInt(auth.lastLoginAt)).toDate()} />
        </Typography>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "lighter",
          }}
        >
          {`Account created:`}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          <TimeAgo date={moment(parseInt(auth.createdAt)).toDate()} />
        </Typography>
      </Grid>
    </Grid>
    // <dl>
    //   <dt>
    //     <Avatar src={auth.photoURL} />
    //   </dt>
    //   <dt>
    //     <strong>UserID:</strong>
    //   </dt>
    //   <dd>
    //     <code>{auth.uid}</code>
    //   </dd>

    //   {auth.displayName ? (
    //     <>
    //       <dt>
    //         <strong>DisplayName:</strong>
    //       </dt>
    //       <dd>{auth.displayName}</dd>
    //     </>
    //   ) : null}

    //   <dt>
    //     <strong>E-Mail:</strong>
    //   </dt>
    //   <dd>
    //     {auth.email}{" "}
    //     {auth.emailVerified ? (
    //       <FontAwesomeIcon color={"green"} icon={[ICON_STYLE, "check-circle"]} />
    //     ) : (
    //       <FontAwesomeIcon color={"red"} icon={[ICON_STYLE, "times-circle"]} />
    //     )}
    //   </dd>

    //   <dt>
    //     <strong>Last login:</strong>
    //   </dt>
    //   <dd>
    //     <TimeAgo date={moment(parseInt(auth.lastLoginAt)).toDate()} />
    //   </dd>

    //   <dt>
    //     <strong>Account created:</strong>
    //   </dt>
    //   <dd>
    //     <TimeAgo date={moment(parseInt(auth.createdAt)).toDate()} />
    //   </dd>
    // </dl>
  );
};

export default User;
