import { useSelector } from "react-redux";

import { IAuth } from "../types/Auth";

export const ROLES = {
  OPERATIV: "operativ",
  ADMIN: "admin",
  SHORTURL: "shorturl",
  PLUGINS: "plugins",
  LICENCES: "licences",
  USERS: "users",
  WEBHOOKS: "webhooks",
  DEVELOPER: "developer",
  DASHBAR: "dashbar",
  ABOCLOUD: "abocloud",
  LUNCH: "lunch_user",
};

export const ADMIN_ROLE = ROLES.ADMIN;

const useAuth = () => {
  const auth = useSelector(
    (state: { firebase: { auth: IAuth } }) =>
      state.firebase.auth || {
        uid: "",
        isAuthed: false,
        isLoaded: false,
        isEmpty: true,
        providerData: [],
      }
  );

    return { ...auth, isAuthed: auth && auth.isLoaded && !auth.isEmpty };
};

export default useAuth;
