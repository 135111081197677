import {FilterOptions} from "../types/FilterOptions";
import _ from "lodash";
import {ExsBasicHook, ExsOrderCompleted, IShop, ExsLicense} from "@dash.bar/types";
import React from "react";
import {Chip} from "@material-ui/core";
import firebase from "firebase/compat/app";

export const shorten = (url: string, len = 50) => url.length > len ? url.substring(0, len) + "[...]" : url;
export const shortenL = (url: string, len = 50) => url.length > len ? "[...]" + url.substring(url.length - len, url.length) : url;
export const shortenM = (url: string, len = 50) => url.length < len ? url : url.substring(0, len / 2) + "[...]" + url.substring(url.length - (len / 2));

export const FSTimestamp = ({_seconds, _nanoseconds}: any) => {
  return new firebase.firestore.Timestamp(_seconds, _nanoseconds)
}

export async function makeShopRequest<T>(shop: IShop, data: Record<string, any>): Promise<T> {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${shop.secretToken}`)
  const body = JSON.stringify(data);
  const response = await fetch(shop.endpoint, {
    method: 'POST',
    headers: headers,
    body: body,
    redirect: 'follow',
  })
  return await response.json() as T;
}

export const extractHostname = (url: string): string => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}

export const asyncForEach = async (array: Array<any>, callback: Function) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const webhookStatusLabel = (status: string) => {
  switch (status) {
    case 'error':
      return <Chip label={status} style={{backgroundColor: "red"}}/>
    case 'complete':
      return <Chip label={status} style={{backgroundColor: "green"}}/>
    case 'ready':
      return <Chip label={status} style={{backgroundColor: "royalblue"}}/>
    case 'open':
      return <Chip label={status} style={{backgroundColor: "orange"}}/>
    default:
      return <Chip label={status}/>
  }
}

export const formatCurrency = function (value: number, currency: string = 'EUR'): string {
  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency,
  }).format(value);
}

export const toggleFilter = (filter: FilterOptions, name: string, values: any[]): FilterOptions => {
  const newFilter = _.cloneDeep(filter)
  if (!filter[name].active) {
    newFilter[name].active = true;
    newFilter[name].value = values[0];
  } else {
    if (values.indexOf(filter[name].value) === (values.length - 1)) {
      newFilter[name].active = false;
      newFilter[name].value = false;
    } else {
      newFilter[name].value = values[values.indexOf(filter[name].value) + 1];
    }
  }
  return newFilter;
}

export const exsBasicInfo = (data: ExsBasicHook): string | JSX.Element => {
  switch (data.event_type) {
    case "exs.order.completed":
      return <>
        <strong>{(data as ExsOrderCompleted).resource.buyer.company}</strong><br/>
        {((data as ExsOrderCompleted).resource.items.map(item => item.name)).join(', ')}<br/>
        <code>{formatCurrency(parseFloat((data as ExsOrderCompleted).resource.amount_total), (data as ExsOrderCompleted).resource.currency_code)}</code>
      </>
    case "exs.license.bound":
    case "exs.license.expired":
    case "exs.license.extended":
    case "exs.license.created":
      return <>
        <strong>{(data as ExsLicense).resource.metas.exs_name}</strong><br/>
        {(data as ExsLicense).resource.metas.exs_valid_until}<br/>
        <code>{(data as ExsLicense).resource.metas.exs_domain}</code>
      </>;
  }
  return 'info';
}
