import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { UserRole } from "../../firestore/models/User";
import withRole from "../../hoc/withRole";
import PluginLicenses from "./pluginExtensions";
import PluginCustomer from "./pluginCustomer";
import PluginCustomerDetail from "./pluginCustomerDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../utils/constants";
import { Typography } from "@material-ui/core";

const LicenseModul = () => {
  const navigate = useNavigate();
  const LicenseDashboard = () => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignContent: "center",
          alignItems: "center",
          padding: 40,
        }}
      >
        <div
          style={{
            width: "20%",
            height: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 10,
            borderRadius: 15,
            backgroundColor: `#3c3f45`,
            cursor: "pointer",
          }}
          onClick={() => navigate(`/licenses/plugin/customer`)}
        >
          <FontAwesomeIcon size="5x" icon={[ICON_STYLE, "user"]} />
          <Typography
            style={{
              fontSize: 24,
              fontWeight: "normal",
              color: "white",
            }}
          >
            {`Kunden`}
          </Typography>
        </div>
        <div
          style={{
            width: "20%",
            height: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 10,
            borderRadius: 15,
            backgroundColor: `#3c3f45`,
            cursor: "pointer",
          }}
          onClick={() => navigate(`/licenses/plugin/extensions`)}
        >
          <FontAwesomeIcon
            size="5x"
            icon={[ICON_STYLE, "puzzle-piece-simple"]}
          />
          <Typography
            style={{
              fontSize: 24,
              fontWeight: "normal",
              color: "white",
            }}
          >
            {`Lizenzen`}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Routes>
      <Route
        path={`plugin/customer/:customerid`}
        element={<PluginCustomerDetail />}
      />
      <Route path={`plugin/customer/`} element={<PluginCustomer />} />
      <Route path={`plugin/extensions/`} element={<PluginLicenses />} />
      <Route path="/" element={<LicenseDashboard />} />
    </Routes>
  );
};

export default withRole(UserRole.Licences)(LicenseModul);
