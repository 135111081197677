import { ERROR, SUCCESS, WARNING } from "./colors";

export const merchantStatusNameFromId = (id: number): string => {
  switch (id) {
    case 0:
      return `Deaktiviert`;
    case 1:
      return `Gültig`;
    case 2:
      return `Gelöscht`;
    case 3:
    default:
      return `Entfernt`;
  }
};

export const merchantStatusColorFromId = (id: number): string => {
  switch (id) {
    case 0:
      return ERROR;
    case 1:
      return SUCCESS;
    case 2:
      return ERROR;
    case 3:
    default:
      return ERROR;
  }
};

export const profileStatusNameFromId = (id: number): string => {
  switch (id) {
    case 0:
      return `Angefragt`;
    case 1:
      return `Gültig`;
    case 2:
    default:
      return `Deaktiviert`;
  }
};

export const profileStatusColorFromId = (id: number): string => {
  switch (id) {
    case 0:
      return WARNING;
    case 1:
      return SUCCESS;
    case 2:
    default:
      return ERROR;
  }
};
