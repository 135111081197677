import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Container,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { PopupSchema } from "../../../../utils/schemas";
import { useSnackbar } from "notistack";
import firebase from "firebase/compat/app";
import getDashBar from "../../../../firebase/dashbar";
import { Popup } from "../../../../firestore/models/Popup";
import { firestore } from "firebase-admin";
import moment from "moment";

const PopupForm = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [popup, setPopup] = useState<
    firebase.firestore.DocumentSnapshot | undefined
  >();
  const [desktopImage, setDesktopImage] = useState<File>();
  const [desktopImageUrl, setDesktopImageUrl] = useState<string>();
  const [mobileImage, setMobileImage] = useState<File>();
  const [mobileImageUrl, setMobileImageUrl] = useState<string>();
  const [active, setActive] = useState<boolean>(false);
  const [noAbo, setNoAbo] = useState<boolean>(false);
  const [bundleFree, setBundleFree] = useState<boolean>(false);
  const [bundleBusiness, setBundleBusiness] = useState<boolean>(false);
  const [bundlePro, setBundlePro] = useState<boolean>(false);
  const [coupons, setCoupons] = useState<Array<{ id: string; name: string }>>();
  const [selectedCoupon, selectCoupon] = useState<string | null>();
  const [hasCoupon, setHasCoupon] = useState<boolean>(false);

  const onDesktopImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setDesktopImage(e.currentTarget?.files[0]);
    }
  };
  const onMobileImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setMobileImage(e.currentTarget?.files[0]);
    }
  };

  const form = useForm<Popup>({
    //@ts-ignore
    resolver: yupResolver(PopupSchema),
  });

  const deleteDesktopImage = async () => {
    if (!id) return;
    if (
      !window.confirm(
        "Really want to delete the Desktop-Image? (Can not be undone)"
      )
    )
      return;
    const ref = getDashBar().storage.ref(`popups/${id}/desktop_image`);
    await ref.delete();
    await getDashBar()
      .db.doc(`popup/${id}`)
      .set({ imageLarge: null }, { merge: true });
    setDesktopImageUrl(undefined);
    return;
  };

  const deleteMobileImage = async () => {
    if (!id) return;
    if (
      !window.confirm(
        "Really want to delete the Mobile-Image? (Can not be undone)"
      )
    )
      return;
    const ref = getDashBar().storage.ref(`popups/${id}/mobile_image`);
    await ref.delete();
    await getDashBar()
      .db.doc(`popup/${id}`)
      .set({ imageSmall: null }, { merge: true });
    setMobileImageUrl(undefined);
    return;
  };

  useEffect(() => {
    getDashBar()
      .db.doc(`popup/${id}`)
      .get()
      .then((data) => {
        if (data.exists) {
          setPopup(data);
          setDesktopImageUrl(data.data()?.imageLarge || undefined);
          setMobileImageUrl(data.data()?.imageSmall || undefined);
          setBundleFree(data.data()?.validFor?.includes("free"));
          setBundleBusiness(data.data()?.validFor?.includes("business"));
          setBundlePro(data.data()?.validFor?.includes("pro"));
          setActive(data.data()?.active === true);
          setNoAbo(data.data()?.noAbo === true);
          selectCoupon(data.data()?.coupon || null);
          setHasCoupon(data.data()?.coupon && data.data()?.coupon !== null);
        } else {
          setPopup(undefined);
        }
      });
  }, [setPopup, id]);

  const handleSave = async (data: Popup) => {
    console.log(`Save Popup`);
    const includedBundles: Array<string> = [];
    if (bundleFree) {
      includedBundles.push("free");
    }
    if (bundleBusiness) {
      includedBundles.push("business");
    }
    if (bundlePro) {
      includedBundles.push("pro");
    }
    const popupData = {
      name: data.name,
      actionUrl: data.actionUrl,
      active: active,
      noAbo: noAbo,
      validFor: includedBundles,
      users: data.users ? data.users.trim().split(";") : [],
      validFrom: new Date(data.validFrom || ""),
      validUntil: new Date(data.validUntil || ""),
      coupon: hasCoupon ? data.coupon : null,
    };
    try {
      await getDashBar()
        .db.doc(`popup/${data.id}`)
        .set(popupData, { merge: true });

      if (desktopImage && desktopImage !== null) {
        await uploadDesktopImage(data.id);
      }
      if (mobileImage && mobileImage !== null) {
        await uploadMobileImage(data.id);
      }
      enqueueSnackbar("Success: Saved " + data.name, { variant: "success" });
      navigate(`/dashbar/popup`);
    } catch (e) {
      enqueueSnackbar("Something went wrong.", { variant: "error" });
    }
  };

  const uploadDesktopImage = async (popupId: string) => {
    if (!desktopImage || desktopImage === null || !popupId) return;
    const url = URL.createObjectURL(desktopImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const ref = getDashBar().storage.ref(`popups/${popupId}/desktop_image`);
    await ref.put(blob);
    const desktopUrl = await ref.getDownloadURL();
    setDesktopImageUrl(desktopUrl);
    await getDashBar()
      .db.doc(`popup/${popupId}`)
      .set({ imageLarge: desktopUrl }, { merge: true });

    return;
  };
  const uploadMobileImage = async (popupId: string) => {
    if (!mobileImage || mobileImage === null || !popupId) return;
    const url = URL.createObjectURL(mobileImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const ref = getDashBar().storage.ref(`popups/${popupId}/mobile_image`);
    await ref.put(blob);
    const mobileUrl = await ref.getDownloadURL();
    setDesktopImageUrl(mobileUrl);
    await getDashBar()
      .db.doc(`popup/${popupId}`)
      .set({ imageSmall: mobileUrl }, { merge: true });

    return;
  };

  useEffect(() => {
    getDashBar()
      .db.collection(`coupons`)
      .get()
      .then((data) => {
        const couponList: Array<{ id: string; name: string }> = [];
        if (!data.empty) {
          data.docs.forEach((coupon) => {
            couponList.push({
              id: coupon.id,
              name: coupon.data()?.name || "n/a",
            });
          });
          setCoupons(couponList);
          console.debug(couponList);
        } else {
          setCoupons(undefined);
        }
      });
  }, [setCoupons]);

  if (id && !popup) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Container>
        <Card>
          <CardContent>
            <h2>{popup ? "Edit" : "New"} Popup</h2>
            <form onSubmit={form.handleSubmit(handleSave)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="ID:"
                    fullWidth
                    inputProps={{
                      readOnly: !!popup?.id,
                      ...form.register("id"),
                    }}
                    error={!!form.formState.errors.id}
                    defaultValue={popup?.id}
                    placeholder={popup?.id}
                    helperText={form.formState.errors.id?.message ?? null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Name:"
                    fullWidth
                    inputProps={form.register("name")}
                    error={!!form.formState.errors.name}
                    defaultValue={popup?.data()?.name}
                    helperText={form.formState.errors.name?.message ?? null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Action-URL:"
                    fullWidth
                    inputProps={form.register("actionUrl")}
                    error={!!form.formState.errors.actionUrl}
                    defaultValue={popup?.data()?.actionUrl}
                    helperText={
                      form.formState.errors.actionUrl?.message ?? null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="User:"
                    fullWidth
                    inputProps={form.register("users")}
                    error={!!form.formState.errors.users}
                    defaultValue={
                      popup?.data()?.users
                        ? popup
                            ?.data()
                            ?.users.reduce((prev: string, curr: string) => {
                              return prev.concat(";", curr);
                            })
                        : ""
                    }
                    helperText={form.formState.errors.users?.message ?? null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"date"}
                    label="ValidFrom"
                    fullWidth
                    inputProps={form.register("validFrom")}
                    error={!!form.formState.errors.validFrom}
                    defaultValue={
                      popup?.data()?.validFrom &&
                      moment(
                        (
                          popup?.data()
                            ?.validFrom as unknown as firestore.Timestamp
                        ).toDate()
                      ).format("YYYY-MM-DD")
                    }
                    helperText={
                      form.formState.errors.validFrom?.message ?? null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"date"}
                    label="ValidUntil"
                    fullWidth
                    inputProps={form.register("validUntil")}
                    error={!!form.formState.errors.validUntil}
                    defaultValue={
                      popup?.data()?.validUntil &&
                      moment(
                        (
                          popup?.data()
                            ?.validUntil as unknown as firestore.Timestamp
                        ).toDate()
                      ).format("YYYY-MM-DD")
                    }
                    helperText={
                      form.formState.errors.validUntil?.message ?? null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      checked={hasCoupon}
                      control={
                        <Switch
                          onChange={() => {
                            setHasCoupon(!hasCoupon);
                          }}
                        />
                      }
                      value="hasCoupon"
                      label="Has Coupon"
                    />
                  </FormGroup>
                </Grid>

                {hasCoupon && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Coupon hinterlegen
                      </InputLabel>
                      <Select
                        inputProps={form.register("coupon")}
                        defaultValue={popup?.data()?.coupon}
                        value={selectedCoupon}
                        label="Coupon"
                        error={!!form.formState.errors.coupon}
                        onChange={(e) => {
                          selectCoupon(e.target.value as string);
                        }}
                      >
                        {coupons &&
                          coupons.map((coupon) => {
                            return (
                              <MenuItem value={coupon.id}>
                                {coupon.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      checked={active}
                      control={
                        <Switch
                          onChange={() => {
                            setActive(!active);
                          }}
                        />
                      }
                      value="active"
                      label="Active"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      checked={noAbo}
                      control={
                        <Switch
                          onChange={() => {
                            setNoAbo(!noAbo);
                          }}
                        />
                      }
                      value="noabo"
                      label="No Abo"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={10}>
                  <p>Image Desktop</p>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={onDesktopImageChanged}
                  />
                </Grid>
                <Grid item xs={2}>
                  <img
                    className="max-h-16 mx-3 rounded mt-2"
                    alt="No Ad-Image"
                    src={desktopImageUrl}
                  />
                  {desktopImageUrl && (
                    <Button
                      variant={"outlined"}
                      color={"primary"}
                      onClick={deleteDesktopImage}
                    >
                      löschen
                    </Button>
                  )}
                </Grid>
                <Grid item xs={10}>
                  <p>Image Mobile</p>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={onMobileImageChanged}
                  />
                </Grid>
                <Grid item xs={2}>
                  <img
                    className="max-h-16 mx-3 rounded mt-2"
                    alt="No Mobile Image"
                    src={mobileImageUrl}
                  />
                  {mobileImageUrl && (
                    <Button
                      variant={"outlined"}
                      color={"primary"}
                      onClick={deleteMobileImage}
                    >
                      löschen
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      checked={bundleFree}
                      control={
                        <Checkbox
                          onChange={() => {
                            setBundleFree(!bundleFree);
                          }}
                        />
                      }
                      value="free"
                      label="Free"
                    />
                    <FormControlLabel
                      checked={bundleBusiness}
                      control={
                        <Checkbox
                          onChange={() => {
                            setBundleBusiness(!bundleBusiness);
                          }}
                        />
                      }
                      value="business"
                      label="Business"
                    />
                    <FormControlLabel
                      checked={bundlePro}
                      control={
                        <Checkbox
                          onChange={() => {
                            setBundlePro(!bundlePro);
                          }}
                        />
                      }
                      value="pro"
                      label="Pro"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    type={"submit"}
                  >
                    speichern
                  </Button>
                  <Button
                    type={"reset"}
                    onClick={() => navigate("/dashbar/popup")}
                  >
                    zurück
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default PopupForm;
