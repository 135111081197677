import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { functions } from "../../../../../../firebase";
import Input from "../../../../../marketingNew/Dashboard/components/InputComponent";

interface ICreateModalProps {
  open: boolean;
  close: () => void;
}

const CreateModal = ({ open, close }: ICreateModalProps) => {
  const createKey = functions().httpsCallable("calls-api-plugin-apikey-createKey");
  const [generating, setGenerating] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [affId, setAffId] = useState<string | undefined>(undefined);
  const [scopes, setScopes] = useState<Array<string>>([]);
  const [operations, setOperations] = useState<Array<string>>([]);

  //Scopes
  const [scopePlugin, setScopePlugin] = useState<boolean>(false);
  const [scopeTemplate, setScopeTemplate] = useState<boolean>(false);
  const [scopeHosting, setScopeHosting] = useState<boolean>(false);

  //Operations
  const [operationList, setOperationList] = useState<boolean>(false);
  const [operationGet, setOperationGet] = useState<boolean>(false);

  useEffect(() => {
    const scopeArray: Array<string> = [];
    if (scopePlugin === true) {
      scopeArray.push(`plugin`);
    }
    if (scopeTemplate === true) {
      scopeArray.push(`template`);
    }
    if (scopeHosting === true) {
      scopeArray.push(`hosting`);
    }
    setScopes(scopeArray);
  }, [scopePlugin, scopeTemplate, scopeHosting]);

  useEffect(() => {
    const operationArray: Array<string> = [];
    if (operationList === true) {
      operationArray.push(`LIST`);
    }
    if (operationGet === true) {
      operationArray.push(`GET`);
    }
    setOperations(operationArray);
  }, [operationGet, operationList]);

  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };
  const onAffIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAffId(event.currentTarget.value);
  };

  const createApiKey = async () => {
    setGenerating(true);
    //ToDo Call function to create apikey
    await createKey({
      name: name,
      scopes: scopes,
      operations: operations,
      affiliateId: affId,
    });
    setGenerating(false);
    close();
  };

  return (
    <Modal open={open} onClose={close}>
      <Card
        style={{
          padding: 20,
          borderRadius: 15,
          flex: 1,
          flexDirection: "column",
          alignContent: "flex-start",
          justifyContent: "center",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        }}
      >
        <div className="flex flex-row justify-center w-full">
          <Typography
            style={{
              fontSize: 24,
              fontWeight: "lighter",
              marginBottom: 20,
            }}
          >
            API-Key erstellen
          </Typography>
        </div>
        <Grid
          container
          spacing={1}
          className="w-full content-center items-center"
          style={{ marginLeft: 10 }}
        >
          <Grid item xs={3}>
            <Label text="Name" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"Name"}
              onChange={onNameChanged}
              value={name}
            />
          </Grid>
          <Grid item xs={3}>
            <Label text="Scope" />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={scopePlugin}
                  onChange={() => {
                    setScopePlugin(!scopePlugin);
                  }}
                />
              }
              label="Plugin"
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={scopeTemplate}
                  onChange={() => {
                    setScopeTemplate(!scopeTemplate);
                  }}
                />
              }
              label="Template"
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={scopeHosting}
                  onChange={() => {
                    setScopeHosting(!scopeHosting);
                  }}
                />
              }
              label="Hosting"
            />
          </Grid>
          <Grid item xs={3}>
            <Label text="Operations" />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={operationList}
                  onChange={() => {
                    setOperationList(!operationList);
                  }}
                />
              }
              label="List"
            />
          </Grid>
          <Grid item xs={3}>
            <Label text="" />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF", margin: 0 }}
                  checked={operationGet}
                  onChange={() => {
                    setOperationGet(!operationGet);
                  }}
                />
              }
              label="Get"
            />
          </Grid>
          <Grid item xs={3}>
            <Label text="Affiliate ID" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"Affiliate ID"}
              onChange={onAffIdChanged}
              value={affId || ""}
            />
          </Grid>
        </Grid>
        <div className="flex flex-row justify-center w-full mt-12">
          <Button
            onClick={createApiKey}
            disabled={generating}
            variant="contained"
          >
            {generating ? "..." : "Erstellen"}
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default CreateModal;
