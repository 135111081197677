import { Grid, IconButton, Typography } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import React from "react";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const AbocloudInfo = ({ company }: { company: Record<string, any> }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Customer-ID:`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {company?.aboCloud?.customerId ?? `n/a`}
          </Typography>
          {company?.aboCloud?.customerId && (
            <IconButton
              className={"c2c"}
              data-clipboard-text={company?.aboCloud?.customerId}
            >
              <FileCopy fontSize={"small"} />
            </IconButton>
          )}
        </div>
      </Grid>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Abonnement-ID:`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {company?.aboCloud?.aboId ?? `n/a`}
          </Typography>
          {company?.aboCloud?.aboId && (
            <IconButton
              className={"c2c"}
              data-clipboard-text={company?.aboCloud?.aboId}
            >
              <FileCopy fontSize={"small"} />
            </IconButton>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default AbocloudInfo;
