import React from "react";
import { Chip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { PluginLicence } from "@dash.bar/types";
import { ICON_STYLE } from "../utils/constants";

type ExplicitProps = {
  label?: string;
  props?: any;
};

type GeneralProps = {
  color: string;
  icon: JSX.Element;
  label: string;
  props?: any;
};

export const GeneralChip = (props: GeneralProps) => (
  <Chip
    style={{ backgroundColor: props.color, color: "#fff" }}
    label={props.label}
    icon={props.icon}
    {...props.props}
  />
);

export const ServerChip = (props: ExplicitProps) =>
  props.label ? (
    <GeneralChip
      color="#029639"
      icon={
        <FontAwesomeIcon
          style={{ color: "#ffffff" }}
          icon={[ICON_STYLE, "server"]}
        />
      }
      {...{
        ...props.props,
        label: props.label,
      }}
    />
  ) : null;

export const PHPChip = (props: ExplicitProps) =>
  props.label ? (
    <GeneralChip
      color="#777bb4"
      icon={
        <FontAwesomeIcon style={{ color: "#ffffff" }} icon={["fab", "php"]} />
      }
      {...{
        ...props.props,
        label: props.label,
      }}
    />
  ) : null;

export const OSChip = (props: ExplicitProps) =>
  props.label ? (
    <GeneralChip
      color="#ff630a"
      icon={
        <FontAwesomeIcon
          style={{ color: "#ffffff" }}
          icon={["fab", "ubuntu"]}
        />
      }
      {...{
        ...props.props,
        label: props.label,
      }}
    />
  ) : null;

export const ShopChip = (props: ExplicitProps) =>
  props.label ? (
    <GeneralChip
      color="#555"
      icon={
        <FontAwesomeIcon
          style={{ color: "#ffffff" }}
          icon={[ICON_STYLE, "store"]}
        />
      }
      {...{
        ...props.props,
        label: props.label,
      }}
    />
  ) : null;

export const SQLChip = (props: ExplicitProps) =>
  props.label ? (
    <GeneralChip
      color="#01728b"
      icon={
        <FontAwesomeIcon
          style={{ color: "#ffffff" }}
          icon={[ICON_STYLE, "database"]}
        />
      }
      {...{
        ...props.props,
        label: props.label,
      }}
    />
  ) : null;

type LicenceStatusProps = {
  plugin: PluginLicence;
};

export const LicenceStatusChip = (props: LicenceStatusProps) => {
  let color = "green";
  if (moment.unix(props.plugin.validUntil.seconds).isBefore(moment())) {
    if (props.plugin.test) {
      color = "red";
    } else {
      color = "orange";
    }
  }

  return (
    <GeneralChip
      color={color}
      icon={
        <FontAwesomeIcon
          style={{ color: "#ffffff" }}
          icon={[ICON_STYLE, "key"]}
        />
      }
      label={props.plugin.test ? "test" : "live"}
    />
  );
};

export const LicenceChip = ({
  hasLicense,
}: {
  hasLicense?: boolean | null;
}) => {
  let color = "green";
  let label = "valid";
  if (hasLicense === undefined || hasLicense === null) {
    color = "gray";
    label = "unkown";
  } else if (!hasLicense) {
    color = "red";
    label = "invalid";
  }

  return (
    <GeneralChip
      color={color}
      icon={
        <FontAwesomeIcon
          style={{ color: "#ffffff" }}
          icon={[ICON_STYLE, "key"]}
        />
      }
      label={label}
    />
  );
};

export const SubscriptionChip = ({
  hasSubscription,
}: {
  hasSubscription?: boolean | null;
}) => {
  let color = "green";
  let label = "valid";
  if (hasSubscription === undefined || hasSubscription === null) {
    color = "gray";
    label = "unkown";
  } else if (!hasSubscription) {
    color = "red";
    label = "invalid";
  }

  return (
    <GeneralChip
      color={color}
      icon={
        <FontAwesomeIcon
          style={{ color: "#ffffff" }}
          icon={[ICON_STYLE, "arrows-rotate"]}
        />
      }
      label={label}
    />
  );
};
