import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import LoadingWrapper from "../../../../components/LoadingWrapper";
import withRole from "../../../../hoc/withRole";
import { UserRole } from "../../../../firestore/models/User";
import PageContainer from "../../../../components/PageContainer";
import DescriptionForm from "../components/DescriptionForm";
import useDocument from "../../../../firestore/hooks/useDocument";
import { Hosting } from "../types/Hosting";
import { DB } from "../../../../firebase";

const HostingDetails = () => {
  const navigate = useNavigate();

  const { hostingId } = useParams<{ hostingId: string }>();
  const hosting = useDocument<Hosting>("hosting", hostingId);

  const deleteHosting = async () => {
    if (window.confirm(`Hosting ${hostingId} wirklich löschen?`)) {
      await DB().collection(`hosting`).doc(hostingId).delete();
      navigate("/developer/hosting");
    }
  };

  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = false; //We want to use only light mode at the moment

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <LoadingWrapper loading={hosting === undefined}>
      <PageContainer
        title={
          <>
            <div className="flex justify-start items-center content-center w-full">
              <Typography
                key={`hosting_title`}
                style={{
                  fontSize: 30,
                  fontWeight: "normal",
                  marginRight: 15,
                }}
              >
                {hosting?.name}
              </Typography>
            </div>
          </>
        }
        onClose={() => navigate("/developer/hosting")}
        onDelete={deleteHosting}
      >
        <Grid container>
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            alignContent={"center"}
          >
            <Grid item xs={12} sm={8} lg={9} xl={10}>
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs={2}>
                  <Label text="Hosting ID:" />
                </Grid>
                <Grid item xs={10}>
                  <Text text={hosting?.id || "n/a"} />
                </Grid>
                <Grid item xs={2}>
                  <Label text="Hosting Preis:" />
                </Grid>
                <Grid item xs={10}>
                  <Text
                    text={`${hosting?.price?.value || "0.00"} ${
                      hosting?.price?.currency || "EUR"
                    }`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Label text="Exs SKU:" />
                </Grid>
                <Grid item xs={10}>
                  <Text text={hosting?.exs_sku || "n/a"} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} lg={3} xl={2} className="text-center">
              <img
                style={{
                  maxWidth: "200px",
                  padding: 10,
                  borderRadius: 150,
                  ...(prefersDarkMode ? null : { backgroundColor: "#eee" }),
                }}
                alt={`Hosting Icon of ${hosting?.name}`}
                className="mx-auto w-full"
                // src={
                //   `https://cdn.webstollen.de/templates/${templateId.replace(
                //     "ws5_",
                //     "ws_"
                //   )}` + (prefersDarkMode ? "_ws_w.png" : "_ws.svg")
                // }
                src={
                  `https://ws-cdn.de/hostings/icons/${hosting?.id}` +
                  (prefersDarkMode ? "_w.png" : ".svg")
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
              >
                {/* {auth && !profile.roles.includes(UserRole.Extern) && ( */}
                <Tab
                  value={0}
                  label={
                    <Typography
                      key={`releases`}
                      style={{
                        fontSize: 14,
                        fontWeight: "light",
                      }}
                    >
                      {`BESCHREIBUNG`}
                    </Typography>
                  }
                />
              </Tabs>
              {value === 0 ? (
                <DescriptionForm hostingId={`${hostingId}`} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </LoadingWrapper>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 16,
        fontWeight: "bold",
      }}
    >
      {text}
    </Typography>
  );
};

const Text = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 16,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default withRole(UserRole.Developer)(HostingDetails);
