import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Typography } from "@material-ui/core";
import firebase from "firebase/compat/app";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ICON_STYLE } from "../../../../../../utils/constants";

interface IPreviewBoxProps {
  adspaceName: string;
  startDate: Date;
  endDate: Date;
  filePath?: string;
  fileUrl?: string;
  filePathSecond?: string;
  fileUrlSecond?: string;
  bookedAt?: Date | null;
  bookedUrl?: string | null;
  reminderAt?: Date | null;
}

const PreviewBox = ({
  filePath,
  fileUrl,
  filePathSecond,
  fileUrlSecond,
  startDate,
  endDate,
  adspaceName,
  bookedAt,
  bookedUrl,
  reminderAt,
}: IPreviewBoxProps) => {
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");
  const ref = storage.ref(filePath);
  const [meta, setMeta] = useState<Record<string, any>>({});

  useEffect(() => {
    console.log(ref);
    if (filePath)
      ref.getMetadata().then((metas) => {
        setMeta(metas);
      });
  }, [filePath]);

  const isImage = (): boolean => {
    if (!meta) return false;
    switch (meta.contentType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
      case "image/svg":
        return true;
      default:
        return false;
    }
  };

  if (!filePath || !fileUrl) return null;

  return (
    <div
      key={`${adspaceName}_${fileUrl}`}
      style={{
        borderColor: "#FFF",
        borderWidth: 1,
        borderStyle: "solid",
        width: "33%",
        maxWidth: "33%",
        minWidth: "33%",
        borderRadius: 10,
        padding: 10,
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        margin: 5,
      }}
      className="flex flex-row"
    >
      <Typography
        style={{
          fontSize: 16,
          fontWeight: "lighter",
          marginBottom: 5,
        }}
      >
        {`${adspaceName}`}
      </Typography>
      <Grid
        container
        spacing={1}
        className="w-full"
        style={{ marginLeft: 10, marginBottom: 5 }}
      >
        <Grid item xs={3}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: "normal",
              marginBottom: 0,
            }}
          >
            Zeitraum:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: "lighter",
              marginBottom: 0,
            }}
          >
            {`${moment(startDate).format("DD.MM.YYYY")}-${moment(
              endDate
            ).format("DD.MM.YYYY")}`}
          </Typography>
        </Grid>
        {bookedAt && bookedAt !== null && (
          <>
            <Grid item xs={3}>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  marginBottom: 0,
                }}
              >
                Gebucht am:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: "lighter",
                  marginBottom: 0,
                }}
              >
                {`${moment(bookedAt).format("DD.MM.YYYY")}`}
              </Typography>
            </Grid>
            {bookedUrl && bookedUrl !== null && (
              <Grid item xs={2}>
                <IconButton
                  size={"small"}
                  title="Buchung öffnen"
                  style={{ margin: "0 5px" }}
                >
                  <a
                    target="_blank"
                    href={bookedUrl && bookedUrl !== null ? bookedUrl : ""}
                  >
                    <FontAwesomeIcon
                      icon={[ICON_STYLE, "external-link"]}
                      size={"xs"}
                    />
                  </a>
                </IconButton>
              </Grid>
            )}
          </>
        )}
        {reminderAt && reminderAt !== null && (
          <>
            <Grid item xs={3}>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  marginBottom: 0,
                }}
              >
                Erinnerung am:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: "lighter",
                  marginBottom: 0,
                }}
              >
                {`${moment(reminderAt).format("DD.MM.YYYY")}`}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {isImage() ? (
        <img src={fileUrl} width="100%" style={{ objectFit: "contain" }} />
      ) : (
        <FontAwesomeIcon
          width={100}
          style={{ width: 200 }}
          scale={4}
          icon={[ICON_STYLE, "file"]}
          size={"8x"}
        />
      )}
      {fileUrlSecond && filePathSecond !== null && (
        <img
          src={fileUrlSecond}
          width="100%"
          style={{ objectFit: "contain" }}
        />
      )}
    </div>
  );
};

export default PreviewBox;
