import { useCallback, useState } from "react";
import Loading from "../components/Loading";

export type LoadingState = {
  loading: boolean;
  setLoading: (loading: boolean, label?: string) => void;
  setError: (
    retry?: () => void,
    onCancel?: () => void
  ) => (error?: any) => void;
  setRetry: (retry?: () => void) => void;
  finallyLoading: () => void;
  Loading: any;
  setLabel: (label?: string) => void;
  loadingProps: {
    loading: boolean;
    error?: any;
    retry?: () => void;
    label?: string;
    cancelable?: boolean;
    onCancel?: () => void;
  };
};

type UseLoadingState = {
  loading: boolean;
  error?: any;
  retry?: () => void;
  label?: string;
  onCancel?: () => void;
};

/**
 * @deprecated
 * @param initialState
 */
const useLoading = (initialState: boolean = true): LoadingState => {
  const [state, setState] = useState<UseLoadingState>({
    loading: initialState,
  });

  const setLabel = useCallback(
    (label?: string) =>
      setState((prev) => {
        return { ...prev, label: label };
      }),
    [setState]
  );

  const setRetry = useCallback(
    (retry?: () => void) =>
      setState((prev) => {
        return { ...prev, retry: retry };
      }),
    [setState]
  );

  const setLoading = useCallback(
    (loading: boolean, label?: string) =>
      setState((prev) => {
        return {
          ...prev,
          loading: loading,
          label: label,
          error: loading ? undefined : prev.error,
        };
      }),
    [setState]
  );

  const setError = useCallback(
    (retry?: () => void, onCancel?: () => void) => (error?: any) =>
      setState((prev) => {
        return {
          ...prev,
          onCancel: onCancel,
          retry: retry,
          error: error,
        };
      }),
    [setState]
  );

  const finallyLoading = useCallback(
    () =>
      setState((prev) => {
        return { ...prev, loading: !!state.error };
      }),
    [setState, state.error]
  );

  return {
    setLabel,
    loading: state.loading,
    setLoading,
    setError,
    setRetry,
    finallyLoading,
    Loading,
    loadingProps: {
      loading: state.loading,
      label: state.label,
      error: state.error,
      retry: state.retry,
      onCancel: () => {
        if (state.onCancel) {
          state.onCancel();
        }
        setState((prev) => {
          return { ...prev, loading: false };
        });
      },
    },
  };
};
export default useLoading;
