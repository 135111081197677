import React, { useCallback, useState } from "react";
import IDLabel from "../../../../../components/IDLabel";
import { Button, Link } from "@material-ui/core";
import PageContainer from "../../../../../components/PageContainer";
import { Company, User } from "@dash.bar/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashbar from "../../../../../firebase/dashbar";
import ReactTimeago from "react-timeago";

import { auth } from "firebase-admin";
import { Alert } from "@material-ui/lab";
import BillingAddress from "../../../../../components/BillingAddress";
import FeatureConfiguration from "../../../../../components/FeatureConfiguration";
import CompanySelect from "../../../modal/CompanySelect";
import firebase from "firebase/compat/app";
import { useSnackbar } from "notistack";
import { ICON_STYLE } from "../../../../../utils/constants";

type TabInfoProps = {
  userDoc?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
  id: string;
  companyDoc?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
  authUser: auth.UserRecord;
  makeSuperAdmin: (admin: boolean) => () => void;
  makeAdmin: (admin: boolean) => () => void;
  syncUser: () => void;
  handleDelete: () => void;
  handleDisable: (disable: boolean) => () => void;
  makeOwner: () => void;
};

const TabInfo = ({
  id,
  authUser,
  makeSuperAdmin,
  syncUser,
  handleDelete,
  handleDisable,
  makeAdmin,
  companyDoc,
  makeOwner,
  userDoc,
}: TabInfoProps) => {
  const [selectCompany, setSelectCompany] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const switchCompany = useCallback(
    (cid: string) => () => {
      if (window.confirm(`Really switch company tp '${cid}'?`)) {
        setSelectCompany(false);
        dashbar()
          .functions.httpsCallable("calls-user-setCompany")({
            userId: id,
            companyId: cid,
          })
          .then(() =>
            enqueueSnackbar("Successfully saved!", { variant: "success" })
          )
          .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
      }
    },
    [setSelectCompany, enqueueSnackbar, id]
  );

  const company = companyDoc?.data() as Company;
  const user = userDoc?.data() as User;
  return (
    <div className="grid grid-cols-2 gap-2">
      <div>
        <PageContainer
          bgColor="bg-gray-800"
          heading={3}
          title="User"
          className="mb-2"
          loading={userDoc === undefined}
        >
          <dl className="horizontal center">
            <dt>ID:</dt>
            <dd>
              <IDLabel label={id} />
            </dd>
            <dt>Name:</dt>
            <dd>
              {user?.firstName ?? "-"} {user?.lastName ?? "-"}
            </dd>
            <dt>E-Mail:</dt>
            <dd>
              {user?.email && (
                <Link href={`mailto:${user?.email}`}>{user?.email}</Link>
              )}
            </dd>
            <dt>Company:</dt>
            <dd>
              {user?.company ? (
                <IDLabel
                  to={`/dashbar/companies/${user?.company}`}
                  label={user?.company ?? "-"}
                />
              ) : (
                <pre>n/a</pre>
              )}
            </dd>
          </dl>
        </PageContainer>

        {/* AUTH USER */}

        <PageContainer
          bgColor="bg-gray-800"
          title="Auth User"
          heading={3}
          loading={!authUser}
          onRefresh={syncUser}
          onDelete={handleDelete}
        >
          <h4>Login Provider</h4>
          <ul className="list-inside">
            {authUser &&
              authUser.providerData.map((pD) => (
                <li key={pD.providerId} className="rounded my-2 text-base">
                  <span
                    className="rounded-l bg-blue-800 text-white px-2 py-1"
                    title={pD.providerId}
                  >
                    {getProviderIcon(pD.providerId)}
                  </span>
                  {pD.email && (
                    <Link
                      className="rounded-r bg-gray-800 px-2 py-1"
                      href={`mailto:${pD.email}`}
                      title={pD.email}
                    >
                      {pD.displayName ?? pD.email}
                    </Link>
                  )}
                  <div className="inline-block relative align-middle h-6">
                    {pD.photoURL && (
                      <img
                        alt="Avatar"
                        referrerPolicy="no-referrer"
                        className="rounded transition-height hover:h-auto h-6 mx-3 mb-1 inline-block cursor-zoom-in"
                        src={pD.photoURL}
                      />
                    )}
                  </div>
                </li>
              ))}
          </ul>

          <h4>Metadata</h4>
          {authUser && (
            <dl className="horizontal center">
              <dt>E-Mail verified:</dt>
              <dd>
                {authUser.emailVerified ? (
                  <FontAwesomeIcon
                    className="text-green-600"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>

              <dt>SuperAdmin:</dt>
              <dd className="flex items-center content-between">
                <div className="flex-grow">
                  {authUser?.customClaims?.admin ? (
                    <FontAwesomeIcon
                      className="text-green-600"
                      icon={[ICON_STYLE, "check-circle"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="text-red-600"
                      icon={[ICON_STYLE, "times-circle"]}
                    />
                  )}
                </div>
                <Button
                  disabled={authUser.uid === dashbar().auth.currentUser?.uid}
                  onClick={makeSuperAdmin(!authUser?.customClaims?.admin)}
                >
                  {authUser?.customClaims?.admin ? "demote" : "promote"}
                </Button>
              </dd>

              <dt>Company:</dt>
              {authUser?.customClaims?.company &&
              authUser?.customClaims?.company === user?.company ? (
                <dd>
                  <FontAwesomeIcon
                    className="text-green-600"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                </dd>
              ) : (
                <dd className="flex items-center content-between">
                  <div className="flex-grow">
                    <FontAwesomeIcon
                      className="text-red-600"
                      icon={[ICON_STYLE, "times-circle"]}
                    />
                  </div>
                  <Button disabled={!user?.company} onClick={syncUser}>
                    fix
                  </Button>
                </dd>
              )}

              <dt>Active:</dt>
              <dd className="flex items-center content-between">
                <div className="flex-grow">
                  {authUser.disabled ? (
                    <FontAwesomeIcon
                      className="text-red-600"
                      icon={[ICON_STYLE, "times-circle"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="text-green-600"
                      icon={[ICON_STYLE, "check-circle"]}
                    />
                  )}
                </div>
                <Button onClick={handleDisable(!authUser.disabled)}>
                  {authUser.disabled ? "enable" : "disable"}
                </Button>
              </dd>

              {authUser.tokensValidAfterTime && (
                <>
                  <dt>Tokens Valid after:</dt>
                  <dd className="flex items-center content-between">
                    <div className="flex-grow">
                      <ReactTimeago date={authUser.tokensValidAfterTime} />
                    </div>
                    <Button onClick={() => alert("TBD")}>revoke</Button>
                  </dd>
                </>
              )}

              <dt>Created:</dt>
              <dd>
                <ReactTimeago date={authUser?.metadata.creationTime} />
              </dd>

              <dt>Last SignIn:</dt>
              <dd>
                <ReactTimeago date={authUser?.metadata.lastSignInTime} />
              </dd>

              {authUser?.metadata.lastRefreshTime && (
                <>
                  <dt>Last Refresh:</dt>
                  <dd>
                    <ReactTimeago date={authUser?.metadata.lastRefreshTime} />
                  </dd>
                </>
              )}
            </dl>
          )}
        </PageContainer>
      </div>

      {/* Comapny */}

      <PageContainer
        bgColor="bg-gray-800"
        title="Company"
        heading={3}
        loading={companyDoc === undefined}
        onEdit={() => setSelectCompany(true)}
      >
        {!companyDoc?.exists ? (
          <Alert severity="warning">No Company.</Alert>
        ) : (
          <>
            <h4>Permissions</h4>
            <dl className="horizontal center">
              <dt>Owner:</dt>
              <dd className="flex items-center content-between">
                {company?.owner === userDoc?.id ? (
                  <FontAwesomeIcon
                    icon={[ICON_STYLE, "check-circle"]}
                    className="text-green-600"
                  />
                ) : (
                  <>
                    <div className="flex-grow">
                      <FontAwesomeIcon
                        icon={[ICON_STYLE, "times-circle"]}
                        className="text-red-600"
                      />
                    </div>
                    <Button onClick={makeOwner}>make Owner</Button>
                  </>
                )}
              </dd>
              <dt>Admin:</dt>
              <dd className="flex items-center content-between">
                {company?.admin?.includes(userDoc?.id ?? "") ? (
                  <>
                    <div className="flex-grow">
                      <FontAwesomeIcon
                        icon={[ICON_STYLE, "check-circle"]}
                        className="text-green-600"
                      />
                    </div>
                    {company?.owner !== userDoc?.id && (
                      <Button onClick={makeAdmin(false)}>DEMOTE</Button>
                    )}
                  </>
                ) : (
                  <>
                    <div className="flex-grow">
                      <FontAwesomeIcon
                        icon={[ICON_STYLE, "times-circle"]}
                        className="text-red-600"
                      />
                    </div>
                    {company?.owner !== userDoc?.id && (
                      <Button onClick={makeAdmin(true)}>PROMOTE</Button>
                    )}
                  </>
                )}
              </dd>
            </dl>

            <h4>Billing Address</h4>
            <BillingAddress company={company} />
            <h4>Feature Configuration</h4>
            <FeatureConfiguration company={company} />
          </>
        )}
      </PageContainer>

      {selectCompany ? (
        <CompanySelect
          onCancel={() => setSelectCompany(false)}
          onSave={switchCompany}
        />
      ) : null}
    </div>
  );
};

export default TabInfo;

const getProviderIcon = (providerId: string) => {
  switch (providerId) {
    case "google.com":
      return <FontAwesomeIcon fixedWidth icon={["fab", "google"]} />;
    case "apple.com":
      return <FontAwesomeIcon fixedWidth icon={["fab", "apple"]} />;
    case "password":
      return <FontAwesomeIcon fixedWidth icon={[ICON_STYLE, "key"]} />;
    default:
      return <FontAwesomeIcon fixedWidth icon={[ICON_STYLE, "user-shield"]} />;
  }
};
