import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import getDashBar from "../../../../firebase/dashbar";
import { IComponentProps } from "../../helper/ComponentProps";
import { DB } from "../../../../firebase";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../DashboardContext";

const FailedInvoices = ({ id }: IComponentProps) => {
  const { setLoading, setVisible } = useContext(DashboardContext);
  const navigate = useNavigate();
  const [failedInvoices, setFailedInvoices] = useState<
    Array<Record<string, any>>
  >([]);
  const [failedDashbarInvoices, setFailedDashbarInvoices] = useState<
    Array<Record<string, any>>
  >([]);
  const [handlingInvoice, setHandlingInvoice] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);

  const [modalId, setModalId] = useState<string | null>(null);
  const clearModalId = () => {
    setModalId(null);
  };

  const [handlingDBInvoice, setHandlingDBInvoice] = useState<boolean>(false);
  const [reloadDB, setReloadDB] = useState<boolean>(true);

  const [modalIdDB, setModalIdDB] = useState<string | null>(null);
  const clearModalIdDB = () => {
    setModalIdDB(null);
  };

  const handleFailedInvoice = async (id: string | null) => {
    if (id === null) return;
    setHandlingInvoice(true);
    await DB()
      .collection("webhook")
      .doc(id)
      .set({ status_intern: "complete" }, { merge: true });
    setReload(true);
    clearModalId();
  };

  const handleFailedInvoiceDB = async (id: string | null) => {
    if (id === null) return;
    setHandlingDBInvoice(true);
    await getDashBar().db.collection(`failed_invoices`).doc(id).delete();
    setReloadDB(true);
    clearModalIdDB();
  };

  useEffect(() => {
    if (reloadDB === true) {
      getDashBar()
        .db.collection(`failed_invoices`)
        .get()
        .then((docs) => {
          const invoiceArray: Array<{
            id: string;
            name: string;
            amount: string;
            product: string;
            created: Date;
          }> = [];
          docs.forEach((doc) => {
            const data = doc.data();
            invoiceArray.push({
              id: doc.id,
              name: data.name,
              amount: `${data?.invoicePositions[0].price}`,
              product: data?.invoicePositions[0].name,
              created: data?.invoiceData?.invoiceDate?.toDate(),
            });
          });
          setFailedDashbarInvoices(invoiceArray);
        })
        .catch((err) => {
          console.log(`Error getting dashbar invoices: ${err}`);
        })
        .finally(() => {
          setTimeout(() => setLoading(id || "_", false), 8000);
        });
    }
  }, [reloadDB]);

  useEffect(() => {
    if (reload === true) {
      DB()
        .collection("webhook")
        .where("status_intern", "==", "invoice_failed")
        .where("event_type", "==", "exs.order.completed")
        .get()
        .then((docs) => {
          const invoiceArray: Array<{
            id: string;
            name: string;
            amount: string;
            product: string;
            created: Date;
          }> = [];
          docs.forEach((doc) => {
            const data = doc.data();
            invoiceArray.push({
              id: doc.id,
              name: data?.resource?.buyer?.company,
              amount: `${data?.resource?.amount_total}`,
              product: data?.resource?.items[0]?.name || "n/a",
              created: new Date(data?.resource?.created_at),
            });
          });
          setFailedInvoices(invoiceArray);
        })
        .catch((err) => {
          console.log(`Error getting dashbar invoices: ${err}`);
        })
        .finally(() => {
          setReload(false);
        });
    }
  }, [reload]);

  if (failedDashbarInvoices.length === 0 && failedInvoices.length === 0) {
    // setVisible(id || "_", false);
    return (
      <>
        <Typography
          style={{
            fontSize: 14,
            width: "100%",
            textAlign: "center",
            marginTop: 20,
            color: "white",
            fontFamily: "Roboto",
            fontWeight: "500",
          }}
        >
          {`Keine Rechnungen`}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ flexGrow: 1, width: "100%" }}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        alignContent="stretch"
      >
        {failedInvoices
          ?.sort((v1, v2) => {
            if (moment(v1).isBefore(v2)) {
              return 1;
            } else if (moment(v2).isBefore(v1)) {
              return -1;
            } else {
              return 0;
            }
          })
          .filter((_, index) => {
            return index < 2;
          })
          .map((v) => {
            return (
              <>
                <Grid item xs={12}>
                  <div
                    onClick={() => {
                      setModalId(v.id);
                    }}
                    style={{
                      width: "100%",
                      backgroundColor: "#c44221",
                      borderRadius: 15,
                      display: "flex",
                      flex: 1,
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      padding: 5,
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        width: "75%",
                        color: "white",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      {v.name}
                    </Typography>
                    <div
                      style={{
                        width: "23%",
                        padding: 3,
                        borderRadius: 5,
                        borderColor: "whitesmoke",
                        borderStyle: "solid",
                        borderWidth: 1,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 10,
                          color: "white",
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`${moment(v.created).format("DD.MM.YYYY hh:mm")}`}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        fontSize: 12,
                        width: "100%",
                        color: "white",
                        marginTop: 5,
                        marginBottom: 0,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >
                      {v.product}
                    </Typography>
                  </div>
                </Grid>
              </>
            );
          })}
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ flexGrow: 1, width: "100%", marginTop: 5 }}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        alignContent="stretch"
      >
        {failedDashbarInvoices
          ?.sort((v1, v2) => {
            if (moment(v1).isBefore(v2)) {
              return 1;
            } else if (moment(v2).isBefore(v1)) {
              return -1;
            } else {
              return 0;
            }
          })
          .filter((_, index) => {
            return index < 2;
          })
          .map((v) => {
            return (
              <>
                <Grid item xs={12}>
                  <div
                    onClick={() => setModalIdDB(v.id)}
                    style={{
                      width: "100%",
                      backgroundColor: "#c44221",
                      borderRadius: 15,
                      display: "flex",
                      flex: 1,
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      padding: 5,
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        width: "75%",
                        color: "white",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      {v.name}
                    </Typography>
                    <div
                      style={{
                        width: "23%",
                        padding: 3,
                        borderRadius: 5,
                        borderColor: "whitesmoke",
                        borderStyle: "solid",
                        borderWidth: 1,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 10,
                          color: "white",
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`${moment(v.created).format("DD.MM.YYYY hh:mm")}`}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        fontSize: 12,
                        width: "100%",
                        color: "white",
                        marginTop: 5,
                        marginBottom: 0,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >
                      {v.product}
                    </Typography>
                  </div>
                </Grid>
              </>
            );
          })}
      </Grid>
      <Dialog
        open={modalId !== null}
        onClose={clearModalId}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Vorgang manuell lösen?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Für die Bestellung der Firma ${
              failedInvoices.filter((invoice) => {
                return invoice.id === modalId;
              })[0]?.name || "n/a"
            } konnte keine Rechnung erstellt werden.\nDu kannst den Vorgang manuell abschließen, der Prozess wird anschließend als vollständig betrachtet.\n\n\nMöchtest du den Vorgang jetzt als abgeschlossen markieren?`}
          </DialogContentText>
          {handlingInvoice === true && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={clearModalId}>
            Abbrechen
          </Button>
          <Button
            variant="contained"
            onClick={() => handleFailedInvoice(modalId)}
          >
            Vorgang abschließen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalIdDB !== null}
        onClose={clearModalIdDB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Vorgang manuell lösen?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Für das dash.bar Abo der Firma ${
              failedDashbarInvoices.filter((invoice) => {
                return invoice.id === modalIdDB;
              })[0]?.name || "n/a"
            } konnte keine Rechnung erstellt werden.\nDu kannst den Vorgang manuell abschließen, der Prozess wird anschließend als vollständig betrachtet.\n\n\nMöchtest du den Vorgang jetzt als abgeschlossen markieren?`}
          </DialogContentText>
          {handlingDBInvoice === true && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={clearModalIdDB}>
            Abbrechen
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(`/dashbar/operations?tab=invoice`)}
          >
            Details
          </Button>
          <Button
            variant="contained"
            onClick={() => handleFailedInvoiceDB(modalIdDB)}
          >
            Vorgang abschließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FailedInvoices;
