import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import React from "react";
import HtmlTooltip from "./HtmlTooltip";
import { ICON_STYLE } from "../utils/constants";

const InfoMarkDownButton = ({ markdown }: { markdown: string }) => {
  return (
    <HtmlTooltip
      title={
        <MDEditor.Markdown
          style={{ width: "100%", fontSize: 12 }}
          source={markdown}
        />
      }
    >
      <Button>
        <FontAwesomeIcon icon={[ICON_STYLE, "question-circle"]} />
      </Button>
    </HtmlTooltip>
  );
};

export default InfoMarkDownButton;
