import { Card, CardContent, Chip, Grid, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import React, { useState } from "react";
import numbro from "numbro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../utils/constants";

const TrendCard = ({
  title,
  data,
}: {
  title: string;
  data: Array<Record<string, any>>;
}) => {
  const [selectedType, selectType] = useState<string | null>(null);

  const dataMappedAll = data
    .map((v) => {
      return v[selectedType === null ? "total" : selectedType];
    })
    .filter((v, i) => {
      return i > data.length - 4;
    });
  const valueOld = dataMappedAll[dataMappedAll.length - 2];
  const valueNew = dataMappedAll[dataMappedAll.length - 1];
  const valueOldChecked = valueOld ? +valueOld : 1;
  const diff = +valueNew - valueOldChecked;
  const per = Math.abs(((+valueNew - valueOldChecked) / valueOldChecked) * 100);
  const amount = dataMappedAll[dataMappedAll.length - 1];

  const handleClick = (type: string) => {
    if (selectedType === type) {
      selectType(null);
    } else {
      selectType(type);
    }
  };

  const getGradientColor = (type: string | null) => {
    switch (type) {
      case "free":
        return "#f5a623";
      case "business":
        return "#2985d0";
      case "pro":
        return "#73157c";
      case "trial":
        return "#73157c";
      default:
        return "#242526";
    }
  };

  return (
    <Card
      style={{
        width: 400,
        marginLeft: 50,
        marginRight: 50,
        marginBottom: 50,
        borderRadius: 15,
        background: `linear-gradient(to right bottom, ${getGradientColor(
          selectedType
        )}, #444445)`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "lighter", marginBottom: 10 }}
        >
          {title}
        </Typography>
        <div className="flex justify-evenly">
          {title === "Shops" ? (
            <>
              <Chip
                variant={selectedType === "jtlshop4" ? "default" : "outlined"}
                size="small"
                label="JTL-Shop 4"
                onClick={() => handleClick("jtlshop4")}
              />
              <Chip
                variant={selectedType === "jtlshop5" ? "default" : "outlined"}
                size="small"
                label="JTL-Shop 5"
                onClick={() => handleClick("jtlshop5")}
              />
              <Chip
                variant={selectedType === "shopware6" ? "default" : "outlined"}
                size="small"
                label="Shopware 6"
                onClick={() => handleClick("shopware6")}
              />
            </>
          ) : title === "Reports" ? (
            <>
              <Chip
                variant={selectedType === "daily" ? "default" : "outlined"}
                size="small"
                label="daily"
                onClick={() => handleClick("daily")}
              />
              <Chip
                variant={selectedType === "weekly" ? "default" : "outlined"}
                size="small"
                label="weekly"
                onClick={() => handleClick("weekly")}
              />
              <Chip
                variant={selectedType === "monthly" ? "default" : "outlined"}
                size="small"
                label="monthly"
                onClick={() => handleClick("monthly")}
              />
              <Chip
                variant={selectedType === "quarterly" ? "default" : "outlined"}
                size="small"
                label="quarterly"
                onClick={() => handleClick("quarterly")}
              />
            </>
          ) : (
            <>
              <Chip
                variant={selectedType === "free" ? "default" : "outlined"}
                size="small"
                label="free"
                onClick={() => handleClick("free")}
              />
              <Chip
                variant={selectedType === "business" ? "default" : "outlined"}
                size="small"
                label="business"
                onClick={() => handleClick("business")}
              />
              <Chip
                variant={selectedType === "pro" ? "default" : "outlined"}
                size="small"
                label="pro"
                onClick={() => handleClick("pro")}
              />
              <Chip
                variant={selectedType === "trial" ? "default" : "outlined"}
                size="small"
                label="trial"
                onClick={() => handleClick("trial")}
              />
            </>
          )}
        </div>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={7}>
            <Typography style={{ fontSize: 28, fontWeight: "bold" }}>
              {amount}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Chart
              options={{
                legend: { show: false },
                grid: { show: false },
                tooltip: { enabled: false },
                chart: {
                  type: "line",
                  toolbar: { show: false },
                  zoom: { enabled: false },
                  width: "100%",
                },
                stroke: { curve: "smooth", width: 2.5 },
                states: { hover: { filter: { type: "none" } } },
                xaxis: {
                  labels: { show: false },
                  axisBorder: { show: false },
                  axisTicks: { show: false },
                  crosshairs: { show: false },
                },
                yaxis: {
                  labels: { show: false },
                  axisBorder: { show: false },
                  axisTicks: { show: false },
                  crosshairs: { show: false },
                },
              }}
              series={[
                {
                  name: "Desktops",
                  data: dataMappedAll,
                },
              ]}
            />
          </Grid>
        </Grid>
        <div className="flex justify-start items-center">
          <FontAwesomeIcon
            icon={[
              ICON_STYLE,
              diff > 0 ? "arrow-up" : diff < 0 ? "arrow-down" : "arrow-right",
            ]}
            color={diff > 0 ? "green" : diff < 0 ? "red" : "white"}
            style={{
              width: 20,
              height: 20,
              borderRadius: 50,
              padding: 5,
              backgroundColor: "rgb(255, 255, 255,0.1)",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          />
          <Typography
            style={{ marginLeft: 5, fontSize: 15, fontWeight: "normal" }}
          >{`${diff > 0 ? "" : "-"}${numbro(per).format({
            mantissa: 2,
          })}%`}</Typography>
          <Typography
            style={{ marginLeft: 5, fontSize: 14, fontWeight: "lighter" }}
          >
            {diff > 0
              ? `mehr ${title} als letzte Woche`
              : diff < 0
              ? `weniger ${title} als letzte Woche`
              : `keine neuen ${title}`}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default TrendCard;
