import { Button, Grid, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Input from "../../../../components/Input";
import { DB } from "../../../../../../firebase";

const EditModal = ({
  cid,
  open,
  setOpen,
}: {
  cid: string;
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [sevdeskid, setSevdeskid] = useState<string>("");
  const [organisation, setOrganisation] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [vat, setVat] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const onSevdeskidChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSevdeskid(event.currentTarget.value);
  };
  const onOrganisationChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrganisation(event.currentTarget.value);
  };
  const onFirstnameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.currentTarget.value);
  };
  const onLastnameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.currentTarget.value);
  };
  const onVatChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVat(event.currentTarget.value);
  };
  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const onStreetChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStreet(event.currentTarget.value);
  };
  const onNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(event.currentTarget.value);
  };
  const onZipChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZip(event.currentTarget.value);
  };
  const onCityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.currentTarget.value);
  };
  const onCountryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(event.currentTarget.value);
  };

  useEffect(() => {
    setLoading(true);
    DB()
      .collection(`customer`)
      .doc(cid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          if (docData !== undefined) {
            setSevdeskid(docData?.sevdesk_id ?? "");
            setOrganisation(docData.organisation ?? "");
            setFirstname(docData.firstname ?? "");
            setLastname(docData.lastname ?? "");
            setEmail(docData.email ?? "");
            setVat(docData.vat ?? "");
            setStreet(docData.address?.street ?? "");
            setNumber(docData.address?.number ?? "");
            setZip(docData.address?.zip ?? "");
            setCity(docData.address?.city ?? "");
            setCountry(docData.address?.country ?? "");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [cid]);

  const saveCustomer = async () => {
    setLoading(true);
    await DB()
      .collection(`customer`)
      .doc(cid)
      .set(
        {
          address: {
            street: street,
            number: number,
            zip: zip,
            city: city,
            country: country,
          },
          sevdesk_id: sevdeskid,
          organisation: organisation,
          firstname: firstname,
          lastname: lastname,
          email: email,
          vat: vat,
          updateTime: new Date(),
        },
        { merge: true }
      );
    setOpen(false);
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          width: "100%",
          minHeight: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "40%",
            borderRadius: 15,
            backgroundColor: "#222",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            padding: 30,
          }}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
            spacing={1}
            className="w-full"
          >
            <Grid item xs={6}>
              <Input
                type={"text"}
                placeholder={"Kundennummer"}
                onChange={onSevdeskidChanged}
                value={sevdeskid}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
              <Input
                type={"text"}
                placeholder={"Organisationsname"}
                onChange={onOrganisationChanged}
                value={organisation}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                type={"text"}
                placeholder={"Vorname"}
                onChange={onFirstnameChanged}
                value={firstname}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                type={"text"}
                placeholder={"Nachname"}
                onChange={onLastnameChanged}
                value={lastname}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                type={"text"}
                placeholder={"E-Mail"}
                onChange={onEmailChanged}
                value={email}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                type={"text"}
                placeholder={"Straße"}
                onChange={onStreetChanged}
                value={street}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                type={"text"}
                placeholder={"Nummer"}
                onChange={onNumberChanged}
                value={number}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type={"text"}
                placeholder={"Plz"}
                onChange={onZipChanged}
                value={zip}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={7}>
              <Input
                type={"text"}
                placeholder={"Ort"}
                onChange={onCityChanged}
                value={city}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                type={"text"}
                placeholder={"Land"}
                onChange={onCountryChanged}
                value={country}
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Input
                type={"text"}
                placeholder={"UStId"}
                onChange={onVatChanged}
                value={vat}
                readOnly={loading}
              />
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Abbrechen
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={saveCustomer}
            >
              Speichern
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
