import React from "react";
import { Route, Routes } from "react-router-dom";
// import TestServerView from "./testserver";
import PluginsView from "./plugins";
import PluginDetails from "./plugins/details";
import TemplatesView from "./templates";
import TemplateDetails from "./templates/details";
import HostingsView from "./hostings";
import HostingDetails from "./hostings/details";
const DeveloperModule = () => {
  return (
    <Routes>
      {/* <Route path={`testserver`} element={<TestServerView />} /> */}
      <Route path={`plugins/:pluginId`} element={<PluginDetails />} />
      <Route path={`plugins`} element={<PluginsView />} />
      <Route path={`templates`} element={<TemplatesView />} />
      <Route path={`templates/:templateId`} element={<TemplateDetails />} />
      <Route path={`hosting`} element={<HostingsView />} />
      <Route path={`hosting/:hostingId`} element={<HostingDetails />} />
    </Routes>
  );
};

export default DeveloperModule;
