import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import DashboardCard from "../general/DashboardCard";
import getDashBar from "../../../../firebase/dashbar";
import { PolarArea } from "react-chartjs-2";
import { COMPONENTS /*DASHBAR*/ } from "../../../../constants/colors";
import { Chart, RadialLinearScale } from "chart.js";
import { UserRole } from "../../../../firestore/models";

const DashbarUserV2 = () => {
  Chart.register(RadialLinearScale);
  const [statistics, setStatistics] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getDashBar()
      .db.collection("statistic_weekly")
      .limit(12)
      .orderBy("createdAt", "desc")
      .get()
      .then((result) => {
        setStatistics(result.docs ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setStatistics]);

  const createCompanyArray = () => {
    const data = Object.entries(statistics || {})
      .map(([k, v]) => {
        const data = v.data();
        return {
          timestamp: data?.timestamp || null,
          createdAt: data.createdAt || null,
          total: data?.["user"]?.all || 0,
          free: data?.["user"]?.free || 0,
          business: data?.["user"]?.business || 0,
          pro: data?.["user"]?.pro || 0,
          trial: data?.["user"]?.trial || 0,
        };
      })
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1;
        } else if (a.createdAt < b.createdAt) {
          return -1;
        } else {
          return 0;
        }
      });
    return data;
  };
  const userData = createCompanyArray();
  const dataMappedAll = userData
    .map((v) => {
      return v["total"];
    })
    .filter((v, i) => {
      return i > userData.length - 4;
    });

  const amount = dataMappedAll[dataMappedAll.length - 1];

  const data = {
    labels: ["free", "business", "pro"],
    datasets: [
      {
        label: "30_days",
        data: [
          userData[0]?.free ?? 0,
          userData[0]?.business ?? 0,
          userData[0]?.pro ?? 0,
        ],
        // backgroundColor: [DASHBAR.FREE, DASHBAR.BUSINESS, DASHBAR.PRO],
        backgroundColor: [
          COMPONENTS.DASHBOARD.THEMES.COLOR_1,
          COMPONENTS.DASHBOARD.THEMES.COLOR_2,
          COMPONENTS.DASHBOARD.THEMES.COLOR_3,
        ],
        borderWidth: 0,
        // borderColor: "#FFF",
      },
    ],
  };

  return (
    <DashboardCard
      title="Nutzer"
      loading={loading}
      theme="dashbar"
      headerChip="Letzte Woche"
      role={UserRole.Dashbar}
    >
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={7}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#AAA",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Gesamt`}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#FFF",
                  fontSize: 22,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {amount}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#AAA",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`free`}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: COMPONENTS.DASHBOARD.THEMES.COLOR_1,
                  fontSize: 16,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {userData[0]?.free ?? 0}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#AAA",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`business`}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
                  fontSize: 16,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {userData[0]?.business ?? 0}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#AAA",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`pro`}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: COMPONENTS.DASHBOARD.THEMES.COLOR_3,
                  fontSize: 16,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {userData[0]?.pro ?? 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <PolarArea
            data={data}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                  position: "top",
                },
                title: {
                  display: false,
                  text: "",
                },
              },
              scales: {
                r: {
                  ticks: {
                    backdropColor: "rgba(0,0,0,0)",
                    color: "#EEE",
                    z: 3,
                    display: false,
                  },
                  grid: {
                    color: "#FFF",
                    display: false,
                  },
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default DashbarUserV2;
