import React, { useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import getDashBar from "../../../firebase/dashbar";
import dashbar from "../../../firebase/dashbar";
import { Authorization, BillingAddress, Company, User } from "@dash.bar/types";
import IDLabel from "../../../components/IDLabel";
import PageContainer from "../../../components/PageContainer";
import Table from "../../../components/Table";
import { WhereT } from "../../../types";
import { Button, Link } from "@material-ui/core";
import { useSnackbar } from "notistack";
import CompanySelect from "../modal/CompanySelect";

const Users = () => {
  const [users, setUsers] = useState<
    | undefined
    | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  >();
  const [limit, setLimit] = useState(15);
  const [where, setWhere] = useState<WhereT>();
  const [companySelectUser, setCompanySelectUser] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const { enqueueSnackbar } = useSnackbar();

  const handleSearch = useCallback(
    (key: string, value: string) => {
      if (value) {
        setWhere([
          key === "id" ? firebase.firestore.FieldPath.documentId() : key,
          ">=",
          value,
        ]);
      } else {
        setWhere(undefined);
      }
    },
    [setWhere]
  );

  const createCompany =
    (
      user: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ) =>
    async () => {
      if (!window.confirm("Really create new Company?")) {
        return;
      }

      const company: Company = {
        status: "ok",
        users: [] as Array<string>,
        owner: user.id,
        admin: [] as Array<string>,
        name: "",
        authorizations: {} as Authorization,
        ustId: "",
        config: { paymentTest: false },
        billingAddress: {
          address1: "",
          lastName: "",
          firstName: "",
          country: "DE",
          city: "",
          zipCode: "",
        } as BillingAddress,
      };
      try {
        const doc = await getDashBar().db.collection("company").add(company);
        await user.ref.set({ company: doc.id }, { merge: true });
        enqueueSnackbar(`Successfully created!`, { variant: "success" });
      } catch (e) {
        enqueueSnackbar(`${e}`, { variant: "error" });
      }
    };

  const loadEmail =
    (
      user: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ) =>
    () => {
      getDashBar()
        .functions.httpsCallable("auth-user-sync")(user.id)
        .then(() =>
          enqueueSnackbar("Successfully synced!", { variant: "success" })
        )
        .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
    };

  useEffect(() => {
    if (where) {
      return getDashBar()
        .db.collection("user")
        .where(...where)
        .limit(limit)
        .onSnapshot(setUsers);
    }
    return getDashBar()
      .db.collection("user")
      .orderBy("email", "asc")
      .limit(limit)
      .onSnapshot(setUsers);
  }, [where, setUsers, limit]);

  const handleSelectCompany = (id: string) => () => {
    if (
      companySelectUser?.exists &&
      window.confirm(`Really set company to '${id}'?`)
    ) {
      setCompanySelectUser(undefined);
      dashbar()
        .functions.httpsCallable("calls-user-setCompany")({
          userId: companySelectUser.id,
          companyId: id,
        })
        .then(() =>
          enqueueSnackbar("Successfully saved!", { variant: "success" })
        )
        .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
    }
  };

  return (
    <PageContainer title="Users">
      <Table
        striped
        header={{
          id: { content: "ID", searchable: true },
          firstName: { content: "Firstname", searchable: true },
          lastName: { content: "Lastname", searchable: true },
          email: { content: "E-Mail", searchable: true },
          company: { content: "Company", searchable: true },
        }}
        onSearch={handleSearch}
        onMore={() => setLimit((l) => l + 15)}
      >
        {users &&
          users.docs.map((doc) => {
            const user = doc.data() as User;
            return (
              <tr key={doc.id}>
                <td>
                  <IDLabel to={`/dashbar/users/${doc.id}`} label={doc.id} />
                </td>
                <td>{user?.firstName ?? "-"}</td>
                <td>{user?.lastName ?? "-"}</td>
                <td>
                  {user?.email ? (
                    <Link href={`mailto:${user.email}`}>{user.email}</Link>
                  ) : (
                    <Button color="primary" onClick={loadEmail(doc)}>
                      sync
                    </Button>
                  )}
                </td>
                <td className="text-center">
                  {user?.company ? (
                    <IDLabel
                      to={`/dashbar/companies/${user.company}`}
                      label={user.company}
                    />
                  ) : (
                    <>
                      <Button color="secondary" onClick={createCompany(doc)}>
                        create
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setCompanySelectUser(doc)}
                      >
                        select
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
      </Table>

      {companySelectUser ? (
        <CompanySelect
          onCancel={() => setCompanySelectUser(undefined)}
          onSave={handleSelectCompany}
          title="Select new Company"
        />
      ) : null}
    </PageContainer>
  );
};

export default Users;
