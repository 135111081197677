import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import { DB } from "../../../../../firebase";
import { getCountryNameFromId } from "../../../../abocloud/helper/sevdesk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../utils/constants";
import moment from "moment";
import HtmlTooltip from "../../../../../components/HtmlTooltip";
import EditModal from "./EditModal";
import "../../index.css";

const HEADER_TEXT_SIZE = 18;
const DEFAULT_TEXT_SIZE = 16;
const ICON_TEXT_SIZE = 18;

const LicensesTab = () => {
  const { customerid } = useParams<{ customerid: string }>();
  const [licenses, setLicenses] = useState<Array<Record<string, any>>>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);
  const [editLicense, setEditLicense] = useState<string>("");

  useEffect(() => {
    if (!customerid || customerid === null || refresh === false) return;
    DB()
      .collection(`customer/${customerid}/licenses`)
      .get()
      .then((licenses) => {
        const licensesArray: Array<Record<string, any>> = [];
        licenses.forEach((license) =>
          licensesArray.push({ ...license.data(), id: license.id })
        );
        setLicenses(licensesArray);
      })
      .finally(() => {
        setRefresh(false);
      });
  }, [customerid, refresh]);

  const activateLicense = async (customerid: string, licenseid: string) => {
    setUpdating(true);
    await DB()
      .collection(`customer/${customerid}/licenses`)
      .doc(licenseid)
      .set({ status: 1 }, { merge: true });
    setRefresh(true);
    setUpdating(false);
  };
  const disableLicense = async (customerid: string, licenseid: string) => {
    setUpdating(true);
    await DB()
      .collection(`customer/${customerid}/licenses`)
      .doc(licenseid)
      .set({ status: 2 }, { merge: true });
    setRefresh(true);
    setUpdating(false);
  };

  if (licenses === null || customerid === undefined) {
    return <p>Lizenzen</p>;
  }

  return (
    <>
      <EditModal
        cid={customerid}
        licenseid={editLicense}
        open={edit}
        setOpen={setEdit}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {licenses.map((license) => {
          return (
            <div
              // style={{
              //   width: "70%",
              //   borderRadius: 15,
              //   backgroundColor: "#DDD",
              //   padding: 20,
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "flex-start",
              //   alignContent: "flex-start",
              //   alignItems: "flex-start",
              // }}
              className="licenceWrapper"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <Typography
                  style={{
                    color: "#111",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  {`Lizenz`}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HtmlTooltip
                    title={
                      license.status === 0
                        ? "Die Lizenz wurde angefragt"
                        : license.status === 1
                        ? "Die Lizenz ist gültig"
                        : "Die Lizenz ist ungültig"
                    }
                  >
                    <FontAwesomeIcon
                      style={{ marginLeft: 7, fontSize: 20 }}
                      color={
                        license.status === 0
                          ? "#e08341"
                          : license.status === 1
                          ? "#2b9413"
                          : "#bf2a2a"
                      }
                      icon={[
                        ICON_STYLE,
                        license.status === 0
                          ? "seal-question"
                          : license.status === 1
                          ? "seal"
                          : "seal-exclamation",
                      ]}
                    />
                  </HtmlTooltip>
                  <HtmlTooltip
                    title={
                      license?.synced && license?.synced?.success === false
                        ? `Der Lizenz-Status konnte nicht an alle Shops übermittelt werden. Die Übertragung ist bereits ${
                            license?.synced?.count ?? 1
                          }x fehlgeschlagen. Nächster Versuch: ${moment(
                            license?.synced?.nextSync?.toDate()
                          ).format("DD.MM.YYYY HH:mm")}`
                        : "Der Lizenz-Status wurde erfolgreich an den Shop übermittelt"
                    }
                  >
                    <FontAwesomeIcon
                      style={{ marginLeft: 7, fontSize: 20 }}
                      color={
                        license?.synced && license?.synced?.success === false
                          ? "#bf2a2a"
                          : "#2b9413"
                      }
                      icon={[
                        ICON_STYLE,
                        license?.synced && license?.synced?.success === false
                          ? "rotate-exclamation"
                          : "rotate",
                      ]}
                    />
                  </HtmlTooltip>
                </div>
              </div>
              <Grid
                container
                justifyContent="flex-start"
                alignContent="center"
                alignItems="center"
                spacing={1}
                className="w-full"
              >
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`Domains:`}</Typography>
                  </Grid>
                  {license.domains.map((domain: string, index: number) => {
                    if (index === 0) {
                      return (
                        <Grid item xs={7}>
                          <Typography
                            style={{
                              color: "#111",
                              fontSize: DEFAULT_TEXT_SIZE,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {`${domain}`}
                          </Typography>
                        </Grid>
                      );
                    } else {
                      return (
                        <>
                          <Grid item xs={5} />
                          <Grid item xs={7}>
                            <Typography
                              style={{
                                color: "#111",
                                fontSize: DEFAULT_TEXT_SIZE,
                                fontFamily: "Roboto",
                                fontWeight: "300",
                              }}
                            >{`${domain}`}</Typography>
                          </Grid>
                        </>
                      );
                    }
                  })}
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`Angefragt:`}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`${moment(license.createTime.toDate()).format(
                      "DD.MM.YYYY HH:mm"
                    )}`}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`API-Key:`}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`${license.apikey}`}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`Extension:`}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`${license.exid}`}</Typography>
                  </Grid>
                  {license.license && license.license !== null && (
                    <>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "500",
                          }}
                        >{`Lizenzschlüssel:`}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "300",
                          }}
                        >{`${license.license?.licenseKey}`}</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "500",
                          }}
                        >{`Gültig ab:`}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "300",
                          }}
                        >{`${moment(license.license?.startDate.toDate()).format(
                          "DD.MM.YYYY HH:mm:ss"
                        )}`}</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "500",
                          }}
                        >{`Gültig bis:`}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "300",
                          }}
                        >{`${moment(license.license?.endDate.toDate()).format(
                          "DD.MM.YYYY HH:mm:ss"
                        )}`}</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "500",
                          }}
                        >{`Ausgestellt am:`}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          style={{
                            color: "#111",
                            fontSize: DEFAULT_TEXT_SIZE,
                            fontFamily: "Roboto",
                            fontWeight: "300",
                          }}
                        >{`${moment(license.license?.created.toDate()).format(
                          "DD.MM.YYYY HH:mm:ss"
                        )}`}</Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >{`Verwalten:`}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {license.status === 0 ? (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={updating}
                        onClick={() => {
                          activateLicense(license.customer_id, license.id);
                        }}
                      >
                        Freischalten
                      </Button>
                    ) : license.status === 1 ? (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={updating}
                        style={{ color: "#ab2c2c" }}
                        onClick={() => {
                          disableLicense(license.customer_id, license.id);
                        }}
                      >
                        Deaktivieren
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={updating}
                        style={{ color: "#608a42" }}
                        onClick={() => {
                          activateLicense(license.customer_id, license.id);
                        }}
                      >
                        Aktivieren
                      </Button>
                    )}
                  </Grid>
                  {license.license !== undefined &&
                    license.license !== null && (
                      <>
                        <Grid item xs={5} />
                        <Grid item xs={7}>
                          <Button
                            variant="contained"
                            size="small"
                            disabled={updating}
                            onClick={() => {
                              setEditLicense(license.id);
                              setEdit(true);
                            }}
                          >
                            Lizenz anpassen
                          </Button>
                        </Grid>
                      </>
                    )}
                </>
              </Grid>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LicensesTab;
