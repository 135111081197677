import React, { useCallback, useEffect, useState } from "react";
import getDashBar from "../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import { Company } from "@dash.bar/types";
import IDLabel from "../../../components/IDLabel";
import PageContainer from "../../../components/PageContainer";
import Table from "../../../components/Table";
import { WhereT } from "../../../types";

const Companies = () => {
  const [companies, setCompanies] = useState<
    | undefined
    | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  >();
  const [limit, setLimit] = useState(15);
  const [where, setWhere] = useState<WhereT>();

  const handleSearch = useCallback(
    (key: string, value: string) => {
      if (value) {
        setWhere([
          key === "id" ? firebase.firestore.FieldPath.documentId() : key,
          ">=",
          value,
        ]);
      } else {
        setWhere(undefined);
      }
    },
    [setWhere]
  );

  useEffect(() => {
    let query = getDashBar().db.collection("company").limit(limit);
    if (where) {
      query = query.where(...where);
    }
    return query.onSnapshot(setCompanies);
  }, [setCompanies, where, limit]);

  return (
    <PageContainer title={"Companies"}>
      <Table
        striped
        header={{
          id: { content: "ID", searchable: true },
          name: { content: "Company", searchable: true },
          ustId: { content: "VatID", searchable: true },
          address: { content: "Address" },
        }}
        onSearch={handleSearch}
        onMore={() => setLimit((prev) => prev + 15)}
      >
        {companies &&
          companies.docs.map((doc) => {
            const company = doc.data() as Company;
            return (
              <tr key={doc.id}>
                <td>
                  <IDLabel to={"/dashbar/companies/" + doc.id} label={doc.id} />
                </td>
                <td>{company.name}</td>
                <td>{company.ustId}</td>
                <td>
                  {company.billingAddress.country ?? "XX"}-
                  {company.billingAddress.zipCode ?? "00000"}{" "}
                  {company.billingAddress.city}
                </td>
              </tr>
            );
          })}
      </Table>
    </PageContainer>
  );
};

export default Companies;
