import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import InfoMarkDownButton from "../../../../../components/InfoMarkdownButton";
import { DB } from "../../../../../firebase";
import {
  campaignIdIsUnique,
  isAmount,
  startBeforeEndDate,
} from "../../../utils";
import Input, { Textarea } from "../InputComponent";
import LoadingCompoenent from "../LoadingComponent";
import { MuiColorInput } from "mui-color-input";
import { useNavigate } from "react-router-dom";

const CampaignForm = () => {
  const navigate = useNavigate();

  const [id, setId] = useState<{ value: string; error: string | null }>({
    value: "",
    error: null,
  });
  const [name, setName] = useState<{ value: string; error: string | null }>({
    value: "",
    error: null,
  });
  const [calendarName, setCalendarName] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [color, setColor] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [startDate, setStartDate] = useState<{
    value: Date | undefined;
    error: string | null;
  }>({
    value: undefined,
    error: null,
  });
  const [endDate, setEndDate] = useState<{
    value: Date | undefined;
    error: string | null;
  }>({
    value: undefined,
    error: null,
  });
  const [budget, setBudget] = useState<{ value: string; error: string | null }>(
    {
      value: "",
      error: null,
    }
  );
  const [comment, setComment] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [bitrixRef, setBitrixRef] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });

  const [bookingError, setBookingError] = useState<{
    type: string;
    message: string;
  } | null>();

  const [active, setActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [startDateType, setStartDateType] = useState<string>("text");
  const [endDateType, setEndDateType] = useState<string>("text");

  const onIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId({ ...id, value: event.currentTarget.value });
  };
  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName({ ...name, value: event.currentTarget.value });
  };
  const onCalendarNameChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCalendarName({ ...calendarName, value: event.currentTarget.value });
  };
  const onColorChanged = (colorValue: string) => {
    setColor({ ...color, value: colorValue });
  };
  const onBudgetChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudget({ ...budget, value: event.currentTarget.value });
  };
  const onCommentChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment({ ...comment, value: event.currentTarget.value });
  };
  const onBitrixRefChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBitrixRef({ ...bitrixRef, value: event.currentTarget.value });
  };
  const onStartDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate({ ...startDate, value: new Date(event.currentTarget.value) });
  };
  const onEndDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate({ ...endDate, value: new Date(event.currentTarget.value) });
  };

  const create = async () => {
    setLoading(true);
    setBookingError(null);

    if (!startDate.value || !endDate.value) {
      setBookingError({
        type: "incompatible_dates",
        message: "Es wird ein Start- und End-Datum benötigt!",
      });
      setLoading(false);
      return;
    }

    if (!startBeforeEndDate(startDate.value, endDate.value)) {
      setBookingError({
        type: "incompatible_dates",
        message: "Das End-Datum darf nicht vor dem Start-Datum liegen!",
      });
      setLoading(false);
      return;
    }

    const isUnique = await campaignIdIsUnique(id.value);
    if (!isUnique) {
      setBookingError({
        type: "duplicate_id",
        message: `Eine Kampagne mit der ID ${id.value} existiert bereits`,
      });
      setLoading(false);
      return;
    }
    const validAmount = isAmount(budget.value);
    if (!validAmount) {
      setBookingError({
        type: "invalid_amount",
        message: `Budget muss in der Form 100.00 sein`,
      });
      setLoading(false);
      return;
    }

    const campaign = {
      id: id.value,
      name: name.value,
      calendarName: calendarName.value,
      startDate: startDate.value,
      endDate: endDate.value,
      budget: budget.value,
      bitrixRef: bitrixRef.value,
      comment: comment.value,
      active: active,
      color: color.value,
      created: new Date(),
      updated: new Date(),
    };
    DB()
      .doc(`campaigns/${id.value}`)
      .set(campaign)
      .then(() => {
        setLoading(false);
        navigate("/marketing");
      });
  };

  const cancel = () => {
    navigate("/marketing");
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
        }}
      >
        Neue Kampagne
      </Typography>
      <div className="w-full lg:w-1/2 xl:w-1/3">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"ID"}
                  onChange={onIdChanged}
                  value={id.value}
                  error={id.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Eindeutige **ID** der Kampagne`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Name"}
                  onChange={onNameChanged}
                  value={name.value}
                  error={name.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton markdown={`**Name** der Kampagne`} />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Kalender Name"}
                  onChange={onCalendarNameChanged}
                  value={calendarName.value}
                  error={calendarName.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Dieses Feld dient der Synchronisation mit *Kalender.digital*.\nDer **Kalender Name** muss identisch mit dem Namen des Unterkalenders von\n*Kalender.digital* sein in welchen die Kampagne synchronisiert werden soll.`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={startDateType}
                  placeholder={"Datum (Start)"}
                  onFocus={() => {
                    setStartDateType("date");
                  }}
                  onChange={onStartDateChanged}
                  value={
                    startDate.value &&
                    moment(startDate.value).format(`YYYY-MM-DD`)
                  }
                  error={startDate.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton markdown={`**Start Datum** der Kampagne`} />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={endDateType}
                  placeholder={"Datum (Ende)"}
                  onFocus={() => {
                    setEndDateType("date");
                  }}
                  onChange={onEndDateChanged}
                  value={
                    endDate.value && moment(endDate.value).format(`YYYY-MM-DD`)
                  }
                  error={endDate.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton markdown={`**End Datum** der Kampagne`} />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Budget"}
                  onChange={onBudgetChanged}
                  value={budget.value}
                  error={budget.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Für die Kampagne vorgsehenes **Budget**. Muss in der Form `0.00` angegeben werden"
                  }
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Bitrix (CRM)"}
                  onChange={onBitrixRefChanged}
                  value={bitrixRef.value}
                  error={bitrixRef.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Hier kann eine URL zu einem zugehörigen **Bitrix24** Projekt oder Kontakt hinterlegt werden.`}
                />
              </Grid>
              <Grid item xs={11}>
                {/* <Input
                  type={"text"}
                  placeholder={"Farbe"}
                  onChange={onColorChanged}
                  value={color.value}
                  error={color.error}
                /> */}
                <MuiColorInput value={color.value} onChange={onColorChanged} />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Hier kann eine Farbe als HEX-Code inb der Form `#000000` angegeben werden. Die Farbe dient der Darstellung auf der Übersichtsseite"
                  }
                />
              </Grid>
              <Grid item xs={11}>
                <Textarea
                  placeholder={"Kommentar"}
                  onChange={onCommentChanged}
                  value={comment.value}
                  error={comment.error}
                  rows={3}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Interne Notizen oder **Kommentare** zur Kampagne`}
                />
              </Grid>

              <Grid item xs={11}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ fontSize: 18, color: "#FFF" }}
                      checked={active}
                      onChange={() => {
                        setActive(!active);
                      }}
                    />
                  }
                  label="Aktiv"
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Legt fest ob die Kampagne **aktiv** ist oder nicht`}
                />
              </Grid>
            </Grid>
            {bookingError !== null && (
              <div className="flex flex-row justify-center w-full my-8">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "lighter",
                    color: "#f5922f",
                    marginTop: 2,
                  }}
                >
                  {bookingError?.message}
                </Typography>
              </div>
            )}
            <div className="flex w-full justify-around">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={create}>
                Anlegen
              </Button>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default CampaignForm;
