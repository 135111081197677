import { MouseEventHandler, useEffect, useState } from "react";
import { useCollection } from "../../../../../firestore/hooks";
import { OLD_Plugin as Plugin } from "@dash.bar/types";
import React from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  LineElement,
  PointElement,
} from "chart.js";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import numbro from "numbro";
import { CSVLink } from "react-csv";
import useProfile from "../../../../../hooks/firestore/useProfile";
import { UserRole } from "../../../../../firestore/models";
import { Refresh } from "@material-ui/icons";

interface ICSV {
  plugin_id: string;
  sold_at: string;
  type: string;
  amount: string;
  order_type: string;
  products: string;
  interval_from: string;
  interval_to: string;
  selected_types: string;
}

type Props = {
  plugin: Plugin;
};

const SalesView = ({ plugin }: Props) => {
  const pluginExsId = plugin.exs_id || null;
  const profile = useProfile();

  const [from, setFrom] = React.useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [to, setTo] = React.useState<Date | null>(new Date());

  //Only updated after submit to prevent unnecessary requests
  const [fromSubmitted, setFromSubmitted] = React.useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [toSubmitted, setToSubmitted] = React.useState<Date | null>(new Date());

  const [prod, setProd] = useState<boolean>(false);
  const [test, setTest] = useState<boolean>(false);
  const [free, setFree] = useState<boolean>(false);

  // const [created, setCreated] = useState<boolean>(false);
  // const [extended, setExtended] = useState<boolean>(false);
  const [orderTypeFilter, setOrderTypeFilter] = useState<string>("all");

  const handleOrderTypeChange = (
    orderType: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderTypeFilter(orderType.target.value);
  };

  const handleFromChange = (fromNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(fromNew.target.value).toISOString();
    } catch {
      return;
    }
    setFrom(new Date(fromNew.target.value));
  };
  const handleToChange = (toNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(toNew.target.value).toISOString();
    } catch {
      return;
    }
    setTo(new Date(toNew.target.value));
  };

  const [typeFilter, setTypeFilter] = useState<Array<string> | null>(null);
  // const [orderTypeFilter, setOrderTypeFilter] = useState<Array<string> | null>(
  //   null
  // );
  const [filter, setFilter] = useState<Array<any>>([
    [`event_type`, "==", `exs.order.completed`],
    [`resource.metas.exs_id`, "==", pluginExsId],
  ]);

  // useEffect(() => {
  //   const newOrderFilterArray: Array<string> = [];
  //   if (created) newOrderFilterArray.push("create");
  //   if (extended) newOrderFilterArray.push("extend");
  //   if (newOrderFilterArray.length === 0) {
  //     setOrderTypeFilter(null);
  //   } else {
  //     setOrderTypeFilter(newOrderFilterArray);
  //   }
  // }, [created, extended]);

  useEffect(() => {
    const newFilterArray: Array<string> = [];
    if (prod) newFilterArray.push("prod");
    if (test) newFilterArray.push("test");
    if (free) newFilterArray.push("free");
    if (newFilterArray.length === 0) {
      setTypeFilter(null);
    } else {
      setTypeFilter(newFilterArray);
    }
  }, [prod, free, test]);

  useEffect(() => {
    const newFilterArray: Array<any> = [];
    newFilterArray.push([`event_type`, "==", `exs.order.completed`]);
    newFilterArray.push([`resource.metas.exs_id`, "==", pluginExsId]);

    if (typeFilter !== null) {
      newFilterArray.push([`resource.metas.license_type`, "in", typeFilter]);
    }
    if (orderTypeFilter !== null && orderTypeFilter !== "all") {
      newFilterArray.push([`resource.metas.order_type`, "==", orderTypeFilter]);
    }
    if (fromSubmitted && fromSubmitted !== null) {
      newFilterArray.push([`created_at`, ">", fromSubmitted.toISOString()]);
    }
    if (toSubmitted && toSubmitted !== null) {
      newFilterArray.push([`created_at`, "<", toSubmitted.toISOString()]);
    }
    //Update Filter
    setFilter(newFilterArray);
  }, [typeFilter, orderTypeFilter, fromSubmitted, toSubmitted]);

  const sales = useCollection<Record<string, any>>(
    "webhook",
    filter,
    undefined,
    10000
  );

  const orderedSales = Object.entries(sales || {})
    .sort((v1, v2) => {
      if (v1[1].created_at > v2[1].created_at) {
        return -1;
      } else if (v1[1].created_at < v2[1].created_at) {
        return 1;
      } else {
        return 0;
      }
    })
    .map(([k, v]) => {
      const items: Array<Record<string, any>> = v.resource.items;
      const products: Array<string> = [];
      items.map((v) => {
        v.name && products.push(v.name);
      });
      const amount = items.reduce<number>((acc, curr) => {
        return (acc = acc + curr.amount);
      }, 0);
      return {
        eventType: v.event_type,
        createdAt: new Date(v.created_at),
        type: v.resource.metas.license_type,
        order_type: v.resource.metas.order_type,
        exsId: v.resource.metas.exs_id,
        amount: +amount,
        products: products.join(", "),
      };
    });

  const totalAmount = (): string => {
    const amount = orderedSales.reduce<number>((acc, curr) => {
      return (acc = acc + curr.amount);
    }, 0);
    return `${numbro(amount / 100).format({
      mantissa: 2,
      thousandSeparated: true,
    })} EUR`;
  };

  const totalType = (type: string): number => {
    const total = orderedSales.filter((v) => {
      return v.type === type.toLowerCase();
    });
    return total.length;
  };

  const totalOrderType = (type: string): number => {
    const total = orderedSales.filter((v) => {
      return v.order_type === type.toLowerCase();
    });
    return total.length;
  };

  const prodQuotient = (): number => {
    const prodLicenses = totalType("prod");
    const testLicenses = totalType("test");
    if (testLicenses <= 0) return 0;
    return prodLicenses / testLicenses;
  };

  const preparedDateMap = (): Record<string, any> => {
    let currentDate = new Date(fromSubmitted || new Date());
    const endDate = new Date(toSubmitted || new Date());
    const dateMap: Record<string, any> = {};
    while (currentDate < endDate) {
      dateMap[moment(currentDate).format("DD.MM.YYYY")] = 0;
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dateMap;
  };

  const submitDates = () => {
    setFromSubmitted(from);
    setToSubmitted(to);
  };

  const reducedData = orderedSales
    ? Object.entries(orderedSales)
        .map(([, v]): string => {
          return moment(v.createdAt).format("DD.MM.YYYY");
        })
        .reduce<Record<string, number>>((prev, curr) => {
          if (prev?.[curr]) {
            return { ...prev, [curr]: prev[curr] + 1 };
          } else {
            return { ...prev, [curr]: 1 };
          }
        }, {})
    : [];

  const orderedData = Object.entries(reducedData)
    .sort((a, b) => {
      if (new Date(a[0]) < new Date(b[0])) {
        return 1;
      } else if (new Date(a[0]) > new Date(b[0])) {
        return -1;
      } else {
        return 0;
      }
    })
    .reverse()
    .reduce<Record<string, any>>((acc, curr) => {
      const key = curr[0];
      const value = curr[1];
      return { ...acc, [key]: value };
    }, preparedDateMap());

  ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
  );

  //   useEffect(() => {
  //     const data = Object.keys(orderedData);
  //     if (data.length > 0) {
  //       const dateParts = data[0].split(".");
  //       if (
  //         from !== new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`)
  //       ) {
  //         console.log(
  //           `New Date: ${data[0]} as Date: ${new Date(
  //             `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
  //           )}`
  //         );
  //         setFrom(new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`));
  //       }
  //     }
  //   }, [orderedData]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Lizenzverkäufe",
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };

  const data = {
    labels: Object.keys(orderedData),
    datasets: [
      {
        label: "Verkäufe",
        data: Object.entries(orderedData).map(([, v]) => {
          return v;
        }),
        backgroundColor: `rgba(52, 137, 235)`,
        borderColor: `#4f9fb3`,
      },
    ],
  };

  const [csv, setCSV] = useState<ICSV[]>([]);

  const csvHeaders = [
    {
      label: "Extension Interner Name",
      key: `plugin_id`,
    },
    {
      label: "Verkauft am",
      key: "sold_at",
    },
    {
      label: "Verkaufstyp",
      key: "type",
    },
    {
      label: "Betrag",
      key: "amount",
    },
    {
      label: "Vorgangstyp",
      key: "order_type",
    },
    {
      label: "Verkaufte Produkte",
      key: "products",
    },
    {
      label: "Ausgewähltes Startdatum",
      key: "interval_from",
    },
    {
      label: "Ausgewähltes Enddatum",
      key: "interval_to",
    },
    {
      label: "Ausgewählte Verkaufstypen",
      key: "selected_types",
    },
  ];

  const generateCSV = (
    event: MouseEventHandler<HTMLAnchorElement>,
    done: (proceed?: boolean) => void
  ) => {
    if (orderedSales && orderedSales.length > 0) {
      Promise.all(
        orderedSales.map((sale) => {
          const data = sale;
          setCSV((x) => [
            ...x,
            {
              plugin_id: plugin.pluginId,
              sold_at: moment(sale.createdAt).format("DD.MM.YYYY"),
              type: `${
                sale.type === "prod"
                  ? "Produktiv"
                  : sale.type === "test"
                  ? "Test"
                  : "Kostenlos"
              }`,
              amount: `${numbro(sale.amount / 100).format({
                mantissa: 2,
                thousandSeparated: true,
              })} EUR`,
              order_type: sale.order_type,
              products: sale.products,
              interval_from: `${moment(fromSubmitted).format("DD.MM.YYYY")}`,
              interval_to: `${moment(toSubmitted).format("DD.MM.YYYY")}`,
              selected_types: `${
                typeFilter && typeFilter?.length > 0
                  ? typeFilter.join(", ")
                  : "Alle Typen"
              }`,
            },
          ]);

          return data;
        })
      ).then(() => done());
    } else {
      done(false);
    }
  };

  if (!profile.roles.includes(UserRole.Operational)) {
    return null;
  }

  if (!pluginExsId || pluginExsId === null) {
    return <div>Exs ID required</div>;
  }

  return (
    <>
      <div
        className="bg-blue-700"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          borderRadius: 15,
          fontSize: 18,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={prod}
                onChange={(e) => {
                  setProd(e.target.checked);
                }}
              />
            }
            label="Produktiv"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={test}
                onChange={(e) => {
                  setTest(e.target.checked);
                }}
              />
            }
            label="Test"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={free}
                onChange={(e) => {
                  setFree(e.target.checked);
                }}
              />
            }
            label="Kostenlos"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RadioGroup
            defaultValue="all"
            onChange={handleOrderTypeChange}
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="Alle" />
            <FormControlLabel
              value="create"
              control={<Radio />}
              label="Neuausstellung"
            />
            <FormControlLabel
              value="upgrade"
              control={<Radio />}
              label="Upgrade"
            />
            <FormControlLabel
              value="extend"
              control={<Radio />}
              label="Verlängerung"
            />
          </RadioGroup>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={created}
                onChange={(e) => {
                  setCreated(e.target.checked);
                }}
              />
            }
            label="Neuausstellung"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={extended}
                onChange={(e) => {
                  setExtended(e.target.checked);
                }}
              />
            }
            label="Verlängerung"
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Von
          <input
            type={`date`}
            value={moment(from || new Date()).format("YYYY-MM-DD")}
            onChange={handleFromChange}
            style={{
              padding: 3,
              borderRadius: 10,
              marginLeft: 5,
              marginRight: 10,
            }}
          />
          Bis
          <input
            type={`date`}
            value={moment(to || new Date()).format("YYYY-MM-DD")}
            onChange={handleToChange}
            style={{
              padding: 3,
              borderRadius: 10,
              marginLeft: 5,
              marginRight: 10,
            }}
          />
          <IconButton onClick={submitDates}>
            <Refresh fontSize={"small"} />
          </IconButton>
        </div>
        {/* 
        //Currently not working
        <Button
          disabled={!sales || !Object.keys(sales).length}
          component={CSVLink}
          data={csv}
          asyncOnClick={true}
          headers={csvHeaders}
          onClick={generateCSV}
          style={{ float: "right" }}
          variant={"contained"}
          color={"secondary"}
          filename={`lic_${plugin.pluginId}}.csv`}
        >
          Export CSV
        </Button> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          height: 100,
          flexShrink: "revert",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            margin: 10,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            color: "black",
            width: "20%",
            flexShrink: "revert",
            padding: 10,
            borderRadius: 15,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 5 }}
              >
                {`Verkäufe (insgesamt)`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 5 }}
              >
                {Object.keys(sales || {}).length}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 5 }}
              >
                {`Umsatz (insgesamt)`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "bold", marginBottom: 5 }}
              >
                {totalAmount()}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 5 }}
              >
                {`Produktivquotient`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 5 }}
              >
                {numbro(prodQuotient()).format({ mantissa: 2 })}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            margin: 10,
            display: "flex",
            height: "100%",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            color: "black",
            width: "20%",
            flexShrink: "revert",
            padding: 10,
            borderRadius: 15,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
              >
                {`Anzahl Produktiv`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 2 }}
              >
                {totalType("prod")}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
              >
                {`Anzahl Test`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 2 }}
              >
                {totalType("test")}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
              >
                {`Anzahl Kostenlos`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 2 }}
              >
                {totalType("free")}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            margin: 10,
            display: "flex",
            height: "100%",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            color: "black",
            width: "20%",
            flexShrink: "revert",
            padding: 10,
            borderRadius: 15,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
              >
                {`Neue Lizenzen`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 2 }}
              >
                {totalOrderType("create")}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
              >
                {`Upgrades`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 2 }}
              >
                {totalOrderType("upgrade")}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
              >
                {`Verlängerungen`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ fontSize: 16, fontWeight: "normal", marginBottom: 2 }}
              >
                {totalOrderType("extend")}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          // backgroundColor: "#eee",
          borderWidth: 2,
          borderColor: "white",
          borderStyle: "solid",
          minWidth: "90%",
          flexGrow: 2,
          padding: 10,
          borderRadius: 15,
        }}
      >
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default SalesView;
