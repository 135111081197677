import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import InfoMarkDownButton from "../../../../../components/InfoMarkdownButton";
import { DB } from "../../../../../firebase";
import { adspaceIdIsUnique, isAmount } from "../../../utils";
import Input, { Textarea } from "../InputComponent";
import LoadingCompoenent from "../LoadingComponent";
import { useNavigate } from "react-router-dom";

const AdspaceForm = () => {
  const navigate = useNavigate();

  const [id, setId] = useState<{ value: string; error: string | null }>({
    value: "",
    error: null,
  });
  const [name, setName] = useState<{ value: string; error: string | null }>({
    value: "",
    error: null,
  });
  const [value, setValue] = useState<{ value: string; error: string | null }>({
    value: "",
    error: null,
  });

  const [bookingError, setBookingError] = useState<{
    type: string;
    message: string;
  } | null>();

  const [active, setActive] = useState<boolean>(false);
  //Determines if AdSpace can be booked more than once at same time
  const [exclusive, setExclusive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId({ ...id, value: event.currentTarget.value });
  };
  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName({ ...name, value: event.currentTarget.value });
  };
  const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, value: event.currentTarget.value });
  };

  const create = async () => {
    setLoading(true);
    setBookingError(null);

    const isUnique = await adspaceIdIsUnique(id.value);
    if (!isUnique) {
      setBookingError({
        type: "duplicate_id",
        message: `Ein Werbeplatz mit der ID ${id.value} existiert bereits`,
      });
      setLoading(false);
      return;
    }

    const validAmount = isAmount(value.value);
    if (!validAmount) {
      setBookingError({
        type: "invalid_amount",
        message: `Value muss in der Form 100.00 sein`,
      });
      setLoading(false);
      return;
    }

    const adspace = {
      id: id.value,
      name: name.value,
      value: { value: value.value, currency: "EUR" },
      exclusive: exclusive,
      active: active,
      created: new Date(),
      updated: new Date(),
    };
    DB()
      .doc(`adspace/${id.value}`)
      .set(adspace)
      .then(() => {
        setLoading(false);
        navigate("/marketing");
      });
  };

  const cancel = () => {
    navigate("/marketing");
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
        }}
      >
        Neue Werbefläche
      </Typography>
      <div className="w-full lg:w-1/2 xl:w-1/3">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"ID"}
                  onChange={onIdChanged}
                  value={id.value}
                  error={id.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Eindeutige **ID** des Werbeplatzes`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Name"}
                  onChange={onNameChanged}
                  value={name.value}
                  error={name.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton markdown={`**Name** des Werbeplatzes`} />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Value"}
                  onChange={onValueChanged}
                  value={value.value}
                  error={value.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Für den Werbeplatz festgelegter **Wert**.\n\n - Muss in der Form `0.00` angegeben werden\n - Der Wert bezieht sich immer auf 7 Tage, wird dieser Werbeplatz also für einen Tag gebucht ist das kalkulierte Budget `Wert/7`"
                  }
                />
              </Grid>
              <Grid item xs={11}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ fontSize: 18, color: "#FFF" }}
                      checked={exclusive}
                      onChange={() => {
                        setExclusive(!exclusive);
                      }}
                    />
                  }
                  label="Exklusiv"
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Legt fest ob der Werbeplatz **exklusiv** ist oder nicht.\nExklusive Werbeplätze dürfen sich nicht überschneiden,\nbei nicht exklusiven Werbeplätzen ist eine Überscheidung\noder Mehrfachauswahl möglich.`}
                />
              </Grid>
              <Grid item xs={11}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ fontSize: 18, color: "#FFF" }}
                      checked={active}
                      onChange={() => {
                        setActive(!active);
                      }}
                    />
                  }
                  label="Aktiv"
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Legt fest ob der Werbeplatz **aktiv** ist oder nicht`}
                />
              </Grid>
            </Grid>
            {bookingError !== null && (
              <div className="flex flex-row justify-center w-full my-8">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "lighter",
                    color: "#f5922f",
                    marginTop: 2,
                  }}
                >
                  {bookingError?.message}
                </Typography>
              </div>
            )}
            <div className="flex w-full justify-around">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={create}>
                Erstellen
              </Button>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default AdspaceForm;
