import { Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { DB, functions } from "../../../../../firebase";
import DashboardCard from "../../general/DashboardCard";
import { AvatarGroup } from "@material-ui/lab";
import md5 from "md5";
import Avatar from "@mui/material/Avatar/Avatar";
import ical from "node-ical";

const AbscentEmployeesV2 = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Array<{
    firstname: string;
    lastname: string;
    name_match: string;
    email: string;
  }> | null>(null);
  const [abscent, setAbscent] = useState<
    Array<{
      firstname: string;
      lastname: string;
      name_match: string;
      from: Date;
      to: Date;
      type: "HO" | "UL" | "KR";
    }>
  >([]);
  const [homeoffice, setHomeoffice] = useState<
    Array<{
      firstname: string;
      lastname: string;
      name_match: string;
      from: Date;
      to: Date;
      type: "HO" | "UL" | "KR";
    }>
  >([]);
  const [abscentSoon, setAbscentSoon] = useState<
    Array<{
      firstname: string;
      lastname: string;
      name_match: string;
      from: Date;
      to: Date;
      type: "HO" | "UL" | "KR";
    }>
  >([]);
  const getAbscences = functions().httpsCallable("calls-heavenhr-getAbscences");

  const getTypeString = (type?: "HO" | "UL" | "KR") => {
    switch (type) {
      case "HO":
        return `Im Homeoffice vom`;
      case "UL":
        return `Im Urlaub vom`;
      case "KR":
        return `Krank vom`;
      default:
        return `Abwesend vom`;
    }
  };

  useEffect(() => {
    DB()
      .collection(`user`)
      .get()
      .then((users) => {
        const userArray: Array<{
          firstname: string;
          lastname: string;
          name_match: string;
          email: string;
        }> = [];
        users.forEach((user) => {
          const userData = user.data();
          if (userData) {
            userArray.push({
              firstname: userData.firstname ?? `-`,
              lastname: userData.lastname ?? `-`,
              name_match:
                `${userData.firstname}${userData.lastname}`.toLowerCase(),
              email: userData.email ?? `-`,
            });
          }
        });
        setUser(userArray);
      });
  }, []);

  useEffect(() => {
    // setLoading(id, true);
    setLoading(true);
    getAbscences()
      .then((data) => {
        const events = ical.parseICS(data.data.events);
        // const events = {};
        console.log(events);
        const homeofficeArray: Array<{
          firstname: string;
          lastname: string;
          name_match: string;
          from: Date;
          to: Date;
          type: "HO" | "UL" | "KR";
        }> = [];
        const abscentArray: Array<{
          firstname: string;
          lastname: string;
          name_match: string;
          from: Date;
          to: Date;
          type: "HO" | "UL" | "KR";
        }> = [];
        const abscentSoonArray: Array<{
          firstname: string;
          lastname: string;
          name_match: string;
          from: Date;
          to: Date;
          type: "HO" | "UL" | "KR";
        }> = [];
        Object.values<Record<string, any>>(events || {})
          .filter((value) => {
            return (
              moment(new Date()).isBetween(
                moment(value?.start?.toISOString()),
                moment(value?.end?.toISOString()),
                undefined,
                "[]"
              ) === true
            );
          })
          .filter((value) => {
            return value?.description?.val?.split(",")[0] !== undefined;
          })
          .map((value) => {
            if (value?.summary?.includes(`(Home office)`)) {
              homeofficeArray.push({
                firstname: value?.description?.val?.split(",")[0].split(" ")[0],
                lastname: value?.description?.val?.split(",")[0].split(" ")[1],
                name_match: `${
                  value?.description?.val?.split(",")[0].split(" ")[0]
                }${
                  value?.description?.val?.split(",")[0].split(" ")[1]
                }`.toLowerCase(),
                from: moment(value?.start?.toISOString())
                  // .subtract(1, "day")
                  .toDate(),
                to: moment(value?.end?.toISOString())
                  .subtract(1, "day")
                  .toDate(),
                type: "HO",
              });
            } else if (value?.summary?.includes(`(Urlaub)`)) {
              abscentArray.push({
                firstname: value?.description?.val?.split(",")[0].split(" ")[0],
                lastname: value?.description?.val?.split(",")[0].split(" ")[1],
                name_match: `${
                  value?.description?.val?.split(",")[0].split(" ")[0]
                }${
                  value?.description?.val?.split(",")[0].split(" ")[1]
                }`.toLowerCase(),
                from: moment(value?.start?.toISOString())
                  // .subtract(1, "day")
                  .toDate(),
                to: moment(value?.end?.toISOString())
                  .subtract(1, "day")
                  .toDate(),
                type: "UL",
              });
            } else if (value?.summary?.includes(`(Krankheit)`)) {
              abscentArray.push({
                firstname: value?.description?.val?.split(",")[0].split(" ")[0],
                lastname: value?.description?.val?.split(",")[0].split(" ")[1],
                name_match: `${
                  value?.description?.val?.split(",")[0].split(" ")[0]
                }${
                  value?.description?.val?.split(",")[0].split(" ")[1]
                }`.toLowerCase(),
                from: moment(value?.start?.toISOString())
                  // .subtract(1, "day")
                  .toDate(),
                to: moment(value?.end?.toISOString())
                  .subtract(1, "day")
                  .toDate(),
                type: "KR",
              });
            } else {
              abscentArray.push({
                firstname: value?.description?.val?.split(",")[0].split(" ")[0],
                lastname: value?.description?.val?.split(",")[0].split(" ")[1],
                name_match: `${
                  value?.description?.val?.split(",")[0].split(" ")[0]
                }${
                  value?.description?.val?.split(",")[0].split(" ")[1]
                }`.toLowerCase(),
                from: moment(value?.start?.toISOString())
                  // .subtract(1, "day")
                  .toDate(),
                to: moment(value?.end?.toISOString())
                  .subtract(1, "day")
                  .toDate(),
                type: "UL",
              });
            }
          });
        Object.values<Record<string, any>>(events || {})
          .filter((value) => {
            return (
              moment(value?.start?.toISOString()).isAfter(moment(new Date())) &&
              Math.abs(
                moment(new Date()).diff(
                  moment(value?.start?.toISOString()),
                  "days"
                )
              ) <= 10
            );
          })
          .filter((value) => {
            return value?.description?.val?.split(",")[0] !== undefined;
          })
          .sort((v1, v2) => {
            if (
              moment(v1?.start?.toISOString()).isBefore(
                moment(v2?.start?.toISOString())
              )
            ) {
              return -1;
            } else if (
              moment(v1?.start?.toISOString()).isAfter(
                moment(v2?.start?.toISOString())
              )
            ) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((value) => {
            if (value?.summary?.includes(`(Home office)`)) {
              abscentSoonArray.push({
                firstname: value?.description?.val?.split(",")[0].split(" ")[0],
                lastname: value?.description?.val?.split(",")[0].split(" ")[1],
                name_match: `${
                  value?.description?.val?.split(",")[0].split(" ")[0]
                }${
                  value?.description?.val?.split(",")[0].split(" ")[1]
                }`.toLowerCase(),
                from: moment(value?.start?.toISOString())
                  // .subtract(1, "day")
                  .toDate(),
                to: moment(value?.end?.toISOString())
                  .subtract(1, "day")
                  .toDate(),
                type: "HO",
              });
            } else {
              abscentSoonArray.push({
                firstname: value?.description?.val?.split(",")[0].split(" ")[0],
                lastname: value?.description?.val?.split(",")[0].split(" ")[1],
                name_match: `${
                  value?.description?.val?.split(",")[0].split(" ")[0]
                }${
                  value?.description?.val?.split(",")[0].split(" ")[1]
                }`.toLowerCase(),
                from: moment(value?.start?.toISOString())
                  // .subtract(1, "day")
                  .toDate(),
                to: moment(value?.end?.toISOString())
                  .subtract(1, "day")
                  .toDate(),
                type: "UL",
              });
            }
          });
        setAbscentSoon(abscentSoonArray);
        setHomeoffice(homeofficeArray);
        setAbscent(abscentArray);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const hydratedAbscents = abscent.map((abscent) => {
    const userObject =
      user?.find((u) => {
        return u.name_match === abscent.name_match;
      }) ?? null;
    if (userObject !== null) {
      return { ...abscent, email: userObject.email };
    }
  });
  const hydratedHomeoffice = homeoffice.map((employee) => {
    const userObject =
      user?.find((u) => {
        return u.name_match === employee.name_match;
      }) ?? null;
    if (userObject !== null) {
      return { ...employee, email: userObject.email };
    }
  });

  const hydratedAbscentSoon = abscentSoon.map((employee) => {
    const userObject =
      user?.find((u) => {
        return u.name_match === employee.name_match;
      }) ?? null;
    if (userObject !== null) {
      return { ...employee, email: userObject.email };
    }
  });

  return (
    <DashboardCard
      title="Abwesenheiten"
      theme="webstollen"
      loading={loading || user === null}
    >
      {abscent.length > 0 && (
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              color: "#AAA",
              marginTop: 10,
              marginBottom: 3,
              fontFamily: "Roboto",
              fontWeight: "500",
            }}
          >
            {`Abwesend`}
          </Typography>
          <AvatarGroup max={10}>
            {hydratedAbscents.map((v) => {
              return (
                <Tooltip
                  title={`${getTypeString(v?.type)} ${moment(v?.from).format(
                    "DD.MM.YYYY"
                  )} bis ${moment(v?.to).format("DD.MM.YYYY")}`}
                >
                  <Avatar
                    alt={`${v?.firstname.toUpperCase()[0]}${
                      v?.lastname.toUpperCase()[0]
                    }`}
                    src={`https://gravatar.com/avatar/${md5(
                      v?.email ?? `-`
                    )}?s=480`}
                    sx={{ width: 56, height: 56 }}
                  />
                </Tooltip>
              );
            })}
          </AvatarGroup>
        </div>
      )}
      {hydratedHomeoffice.length > 0 && (
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              color: "#AAA",
              marginTop: 10,
              marginBottom: 3,
              fontFamily: "Roboto",
              fontWeight: "500",
            }}
          >
            {`Homeoffice`}
          </Typography>
          <AvatarGroup max={10}>
            {hydratedHomeoffice.map((v) => {
              return (
                <Tooltip
                  title={`${getTypeString(v?.type)} ${moment(v?.from).format(
                    "DD.MM.YYYY"
                  )} bis ${moment(v?.to).format("DD.MM.YYYY")}`}
                >
                  <Avatar
                    alt={`${v?.firstname.toUpperCase()[0]}${
                      v?.lastname.toUpperCase()[0]
                    }`}
                    src={`https://gravatar.com/avatar/${md5(
                      v?.email ?? `-`
                    )}?s=480`}
                    sx={{ width: 56, height: 56 }}
                  />
                </Tooltip>
              );
            })}
          </AvatarGroup>
        </div>
      )}
      {hydratedAbscentSoon.length > 0 && (
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              color: "#AAA",
              marginTop: 10,
              marginBottom: 3,
              fontFamily: "Roboto",
              fontWeight: "500",
            }}
          >
            {`Bald abwesend`}
          </Typography>
          <AvatarGroup max={10}>
            {hydratedAbscentSoon.map((v) => {
              return (
                <Tooltip
                  title={`${getTypeString(v?.type)} ${moment(v?.from).format(
                    "DD.MM.YYYY"
                  )} bis ${moment(v?.to).format("DD.MM.YYYY")}`}
                >
                  {v?.type === "HO" ? (
                    <Avatar
                      slotProps={{
                        img: {
                          className: `homeofficeAvatarImg`,
                        },
                      }}
                      alt={`${v?.firstname.toUpperCase()[0]}${
                        v?.lastname.toUpperCase()[0]
                      }`}
                      src={`https://gravatar.com/avatar/${md5(
                        v?.email ?? `-`
                      )}?s=480`}
                      sx={{ width: 56, height: 56 }}
                    />
                  ) : (
                    <Avatar
                      slotProps={{
                        img: {
                          className: `abscentAvatarImg`,
                        },
                      }}
                      alt={`${v?.firstname.toUpperCase()[0]}${
                        v?.lastname.toUpperCase()[0]
                      }`}
                      src={`https://gravatar.com/avatar/${md5(
                        v?.email ?? `-`
                      )}?s=480`}
                      sx={{ width: 56, height: 56 }}
                    />
                  )}
                </Tooltip>
              );
            })}
          </AvatarGroup>
        </div>
      )}
    </DashboardCard>
  );
};

export default AbscentEmployeesV2;

// const HOAvatar = styled(Avatar)(() => ({
//   border: `4px solid #FFF`,
// }));

// const ULAvatar = styled(Avatar)(() => ({
//   border: `4px solid #222`,
// }));
