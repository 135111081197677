import { useEffect, useState } from "react";
import React from "react";
import { Grid } from "@material-ui/core";
import { useFirestore } from "react-redux-firebase";
import DashboardCard from "../../general/DashboardCard";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart } from "chart.js";
import { COMPONENTS } from "../../../../../constants/colors";
import { UserRole } from "../../../../../firestore/models";

const PluginSalesType = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [from, setFrom] = React.useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [to, setTo] = React.useState<Date | null>(new Date());
  const firestore = useFirestore();
  const [salesRecord, setSalesRecord] = useState<Record<string, any>>({});

  Chart.register(ArcElement);

  useEffect(() => {
    setLoading(true);
    let query = firestore
      .collection(`webhook`)
      .where(`event_type`, "==", `exs.order.completed`);
    if (from && from !== null) {
      query = query.where(`created_at`, ">", from.toISOString());
    }
    if (to && to !== null) {
      query = query.where(`created_at`, "<", to.toISOString());
    }
    query
      .limit(10000)
      .get()
      .then((result) => {
        const resultMap = result.docs.reduce<Record<string, any>>(
          (prev, curr) => {
            const key = curr.id;
            const data = curr.data();
            return { ...prev, [key]: data };
          },
          {}
        );
        setSalesRecord(resultMap);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [from, to]);

  const orderedSales = Object.entries(salesRecord || {})
    .sort((v1, v2) => {
      if (v1[1].created_at > v2[1].created_at) {
        return -1;
      } else if (v1[1].created_at < v2[1].created_at) {
        return 1;
      } else {
        return 0;
      }
    })
    .map(([k, v]) => {
      const items: Array<Record<string, any>> = v.resource.items;
      const products: Array<string> = [];
      items.map((v) => {
        v.name && products.push(v.name);
      });
      const amount = items.reduce<number>((acc, curr) => {
        return (acc = acc + curr.amount);
      }, 0);
      return {
        eventType: v.event_type,
        createdAt: new Date(v.created_at),
        type: v.resource.metas.license_type,
        order_type: v.resource.metas.order_type,
        exsId: v.resource.metas.exs_id,
        amount: +amount,
        products: products.join(", "),
      };
    });

  const totalOrderType = (type: string): number => {
    const total = orderedSales.filter((v) => {
      return v.order_type === type.toLowerCase();
    });
    return total.length;
  };

  const data = {
    labels: ["Neu", "Upgrade", "Verlängerung"],
    datasets: [
      {
        label: "30_days",
        data: [
          totalOrderType("create"),
          totalOrderType("upgrade"),
          totalOrderType("extend"),
        ],
        backgroundColor: [
          COMPONENTS.DASHBOARD.CHART.COLOR_2,
          COMPONENTS.DASHBOARD.CHART.COLOR_3,
          COMPONENTS.DASHBOARD.CHART.COLOR_4,
        ],
        borderWidth: 0,
      },
    ],
  };

  return (
    <DashboardCard
      title="Verkäufe"
      headerChip={`30 Tage`}
      theme="plugin"
      loading={loading}
      role={UserRole.Developer}
    >
      <div
        style={{
          width: "100%",
          minWidth: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={4} alignItems={"center"}>
          <Grid item xs={4}>
            <Doughnut
              data={data}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    display: false,
                  },
                  title: {
                    display: false,
                    text: "",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: COMPONENTS.DASHBOARD.CHART.COLOR_2,
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                  }}
                />
              </Grid>
              <Grid item xs={6}>{`Neu`}</Grid>
              <Grid item xs={4}>{`${totalOrderType("create")}`}</Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: COMPONENTS.DASHBOARD.CHART.COLOR_3,
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                  }}
                />
              </Grid>
              <Grid item xs={6}>{`Upgrade`}</Grid>
              <Grid item xs={4}>{`${totalOrderType("upgrade")}`}</Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    backgroundColor: COMPONENTS.DASHBOARD.CHART.COLOR_4,
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                  }}
                />
              </Grid>
              <Grid item xs={6}>{`Verlängerung`}</Grid>
              <Grid item xs={4}>{`${totalOrderType("extend")}`}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </DashboardCard>
  );
};

export default PluginSalesType;
