import { Grid, IconButton, Typography } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import React from "react";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const CompanyInfo = ({ company }: { company: Record<string, any> }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Firma`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {company?.name ?? `n/a`}
        </Typography>
      </Grid>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Firmen-ID`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {company?.id ?? `n/a`}
          </Typography>
          {company?.id && (
            <IconButton className={"c2c"} data-clipboard-text={company?.id}>
              <FileCopy fontSize={"small"} />
            </IconButton>
          )}
        </div>
      </Grid>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Adresse`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {`${company?.billingAddress?.firstName ?? ``} ${
            company?.billingAddress?.lastName ?? ``
          }`}
        </Typography>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {company?.billingAddress?.address1 ?? `n/a`}
        </Typography>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {`${company?.billingAddress?.zipCode ?? ``} ${
            company?.billingAddress?.city ?? ``
          }`}
        </Typography>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {company?.billingAddress?.country ?? ``}
        </Typography>
      </Grid>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`E-Mail Rechnung`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {company?.billingAddress?.email ?? `n/a`}
        </Typography>
      </Grid>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`UStID`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {company?.ustId ?? `n/a`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CompanyInfo;
