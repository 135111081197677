import React, { useEffect, useState } from "react";
import { SxProps, Tab, Tabs, Theme } from "@mui/material";
import TabPanel from "./TabPanel";
import OverviewTab from "./Tabs/Overview";
import { useSearchParams } from "react-router-dom";
import LicensesTab from "./Tabs/Licenses";
import "./index.css";

const PluginCustomerDetail = () => {
  const [value, setValue] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const tab = +(searchParams.get(`t`) ?? 0);
    if (tab === value || tab > 3) return;
    setValue(tab);
  }, [searchParams]);

  const customTabStyle: SxProps<Theme> = {
    fontFamily: "Comfortaa",
    "& .Mui-selected": {
      color: "black",
      backgroundColor: "green",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "orange",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "red",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: `100%`,
      }}
    >
      <div
        // style={{
        //   width: `50%`,
        //   borderRadius: 15,
        //   padding: 10,
        //   margin: 8,
        //   backgroundColor: `#EEE`,
        // }}
        className="tabWrapper"
      >
        <Tabs
          className="mb-8"
          value={value}
          onChange={handleChange}
          sx={{
            "& button.Mui-selected": {
              color: "black",
              fontWeight: "bold",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "black",
              color: "black",
            },
          }}
        >
          <Tab sx={customTabStyle} label={`Übersicht`} {...a11yProps(0)} />
          <Tab sx={customTabStyle} label={`Lizenzen`} {...a11yProps(1)} />
        </Tabs>
        <TabPanel index={0} value={value}>
          <OverviewTab />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <LicensesTab />
        </TabPanel>
      </div>
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default PluginCustomerDetail;
