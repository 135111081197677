const errorCodes = () => ({
    'auth.popup-closed-by-user': 'error.auth.popup-closed-by-user',
    'auth.app-deleted': 'error.auth.app-deleted',
    'auth.app-not-authorized': 'error.auth.app-not-authorized',
    'auth.argument-error': 'error.auth.argument-error',
    'auth.invalid-api-key': 'error.auth.invalid-api-key',
    'auth.invalid-email': 'error.auth.invalid-email',
    'auth.invalid-user-token': 'error.auth.invalid-user-token',
    'auth.invalid-tenant-id': 'error.auth.invalid-tenant-id',
    'auth.network-request-failed': 'error.auth.network-request-failed',
    'auth.operation-not-allowed': 'error.auth.operation-not-allowed',
    'auth.requires-recent-login': 'error.auth.requires-recent-login',
    'auth.too-many-requests': 'error.auth.too-many-requests',
    'auth.unauthorized-domain': 'error.auth.unauthorized-domain',
    'auth.user-disabled': 'error.auth.user-disabled',
    'auth.user-token-expired': 'error.auth.user-token-expired',
    'auth.web-storage-unsupported': 'error.auth.web-storage-unsupported',
    'auth.email-already-in-use': 'error.auth.email-already-in-use',
    'auth.wrong-password': 'error.auth.wrong-password',
    'auth.user-not-found': 'error.auth.wrong-password',
    'signupDisabled': 'error.auth.signupDisabled',
})

export type ErrorCodes = {
    'auth.popup-closed-by-user': string
    'auth.app-deleted': string
    'auth.app-not-authorized': string
    'auth.argument-error': string
    'auth.invalid-api-key': string
    'auth.invalid-email': string
    'auth.invalid-user-token': string
    'auth.invalid-tenant-id': string
    'auth.network-request-failed': string
    'auth.operation-not-allowed': string
    'auth.requires-recent-login': string
    'auth.too-many-requests': string
    'auth.unauthorized-domain': string
    'auth.user-disabled': string
    'auth.user-token-expired': string
    'auth.web-storage-unsupported': string
    'auth.email-already-in-use': string
    'auth.wrong-password': string
    'auth.user-not-found': string
    signupDisabled: string
}

export type ErrorCode = keyof ErrorCodes

export default class AuthError extends Error {
    code: ErrorCode
    constructor(code: string) {
        const errorCode = code.replace('/', '.') as ErrorCode
        super(errorCode)

        this.code = errorCode
        this.message = errorCodes()[errorCode]
    }
}
