import React from "react";
import ActionBar from "./components/ActionBar";
import KeyView from "./components/KeyView";

const Dashboard = () => {
  return (
    <div
      style={{ width: "100%", minWidth: "100%" }}
      className="w-full flex flex-col justify-center content-center items-center"
    >
      <ActionBar />
      <KeyView />
    </div>
  );
};

export default Dashboard;
