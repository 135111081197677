import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import WebhookView from "./webhook";
import WebhookDetails from "./webhook/details";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";

const ExtensionstoreModule = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`webhook`} replace />} />
      <Route path={`webhook`} element={<WebhookView />} />
      <Route path={`webhook/:id`} element={<WebhookDetails />} />
    </Routes>
  );
};

export default withRole(UserRole.Webhooks)(ExtensionstoreModule);
