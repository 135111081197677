import React, { useEffect, useState } from "react";
import { Button, Fade, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { WhereOptions } from "react-redux-firebase";
import { useCollection } from "../../../firestore/hooks";
import { UserRole } from "../../../firestore/models/User";
import withRole from "../../../hoc/withRole";
import PageContainer from "../../../components/PageContainer";
import CustInput from "../../../components/Input";
import useAuth from "../../../hooks/useAuth";
import useProfile from "../../../hooks/firestore/useProfile";
import { Hosting } from "./types/Hosting";
import NewHosting from "./new";
import HostingCard from "./components/HostingCard";
// TODO: Disable Access Filter

const HostingView = () => {
  const navigate = useNavigate();
  const [searchedHostings, setSearchedHostings] = useState<Array<Hosting>>([]);

  const auth = useAuth();
  const profile = useProfile();
  const [newHosting, setNewHosting] = useState(false);
  const [searchString, setSearchString] = useState<string>("");

  const onSearchStringChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchString(event.currentTarget.value);
  };

  const [searchOptions, setSearchOptions] = useState<{
    query: string;
    filter: string;
    limit: number;
    where?: WhereOptions | WhereOptions[];
  }>({
    query: "",
    filter: "name",
    limit: 250,
  });

  const hostings = useCollection<Hosting>(
    "hosting",
    searchOptions?.where,
    [searchOptions.filter, "asc"],
    searchOptions.limit
  );

  useEffect(() => {
    if (searchString.length > 1) {
      const getData = setTimeout(() => {
        const searchedHostings = Object.entries(hostings || {})
          .filter(([k, v]) => {
            return (
              v.id.toLowerCase().includes(searchString.toLowerCase()) ||
              v.name.toLowerCase().includes(searchString.toLowerCase()) ||
              (v.exs_sku &&
                v.exs_sku.toLowerCase().includes(searchString.toLowerCase())) ||
              (v.description &&
                v.description
                  .toLowerCase()
                  .includes(searchString.toLowerCase()))
            );
          })
          .map(([k, v]) => {
            let score = 0.1;
            const sea = searchString.toLowerCase();
            if (v.exs_sku && v.exs_sku.toLowerCase().includes(sea)) {
              score = score + 100;
              if (v.exs_sku.indexOf(sea) === 0) {
                score = score * 2;
              }
            }
            if (v.id.toLowerCase().includes(sea)) {
              score = score + 100;
              if (v.id.indexOf(sea) === 0) {
                score = score * 2;
              }
            }
            if (v.name && v.name.toLowerCase().includes(sea)) {
              score = score + 50;
              if (v.name.indexOf(sea) === 0) {
                score = score * 1.5;
              }
            }
            if (v.description && v.description.toLowerCase().includes(sea)) {
              score = score + 20;
            }
            return { ...v, searchScore: score };
          })
          .sort((a, b) => {
            if (a.searchScore > b.searchScore) {
              return -1;
            } else if (a.searchScore < b.searchScore) {
              return 1;
            } else {
              return 0;
            }
          });
        if (searchedHostings.length === 1) {
          navigate(`${searchedHostings[0].id}`);
        }
        setSearchedHostings(searchedHostings);
      }, 200);
      return () => clearTimeout(getData);
    } else {
      setSearchedHostings(Object.values(hostings || {}));
    }
  }, [searchString, hostings]);

  const SearchBar = () => {
    return (
      <CustInput
        key={`SearchBar`}
        type="text"
        autoFocus
        placeholder="Hosting suchen"
        value={searchString}
        onChange={onSearchStringChanged}
        style={{ padding: 10, fontSize: 28, outline: "none" }}
      />
    );
  };

  return (
    <PageContainer
      title="Hostings"
      onAdd={
        auth && profile.roles.includes(UserRole.Developer)
          ? () => setNewHosting(true)
          : undefined
      }
    >
      <SearchBar />
      {searchedHostings && (
        <div className="grid grid-cols-3 gap-4">
          {searchedHostings.length === 0 ? (
            <Typography
              className="col-span-3"
              style={{
                fontSize: 20,
                fontWeight: "lighter",
                color: "white",
                margin: 15,
                width: "100%",
                textAlign: "center",
              }}
            >
              {`Es wurde kein Hosting gefunden`}
            </Typography>
          ) : (
            searchedHostings.map((hosting) => (
              <div key={hosting.id}>
                <Fade in={true}>
                  <HostingCard
                    hosting={hosting}
                    key={`HostingCard_${hosting.id}`}
                  />
                </Fade>
              </div>
            ))
          )}
        </div>
      )}
      {Object.keys(hostings || {}).length === searchOptions.limit && (
        <div className="text-center my-3">
          <Button
            onClick={() =>
              setSearchOptions((prev) => ({ ...prev, limit: prev.limit + 15 }))
            }
            variant="contained"
          >
            more
          </Button>
        </div>
      )}
      {auth && profile.roles.includes(UserRole.Developer) && (
        <NewHosting open={newHosting} onClose={() => setNewHosting(false)} />
      )}
    </PageContainer>
  );
};

export default withRole(UserRole.Developer)(HostingView);
