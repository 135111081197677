import { Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const ActionBar = () => {
  const navigate = useNavigate();

  const toOverview = () => {
    navigate(`/marketing`);
  };

  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
      }}
      className="flex"
    >
      <Button variant="outlined" onClick={toOverview}>
        Zur Marketing Übersicht
      </Button>
    </div>
  );
};

export default ActionBar;
