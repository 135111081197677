import {
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const ShopConfigEdit = ({
  title,
  enabledShopsystem,
  minVersionShopsystem,
  setEnabledShopsystem,
  setMinVersionShopsystem,
}: {
  title: string;
  enabledShopsystem: boolean;
  minVersionShopsystem: string;
  setEnabledShopsystem: React.Dispatch<React.SetStateAction<boolean>>;
  setMinVersionShopsystem: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [minVersion, setMinVersion] = useState<string>("0.0.0");
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setEnabledShopsystem(enabled);
  }, [enabled]);

  useEffect(() => {
    const match = minVersion.match(/^\d+[.]\d+[.]\d+$/gm) || [];
    if (match.length < 1) {
      setError(true);
    } else {
      setError(false);
      setMinVersionShopsystem(minVersion);
    }
  }, [minVersion]);

  useEffect(() => {
    setEnabled(enabledShopsystem);
  }, [enabledShopsystem]);

  useEffect(() => {
    setMinVersion(minVersionShopsystem);
  }, [minVersionShopsystem]);

  return (
    <Card
      style={{
        width: "90%",
        borderRadius: 15,
        backgroundColor: `#323232`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "lighter", marginBottom: 10 }}
        >
          {title}
        </Typography>
        <Grid
          container
          style={{ alignContent: "center", alignItems: "center" }}
        >
          <Grid item xs={8}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              minVersion
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={error}
              variant="outlined"
              value={minVersion}
              onChange={(e) => {
                const value = e.target.value || "";
                setMinVersion(value);
              }}
              helperText={error ? `Falsches Versions-Format` : ""}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              enabled
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={enabled}
              onChange={(e) => {
                setEnabled(e.target.checked);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ShopConfigEdit;
