import React from "react";
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormWatch,
} from "react-hook-form";
import { Box, Grid, Input, Typography } from "@material-ui/core";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import InfoMarkDownButton from "../../../../components/InfoMarkdownButton";

type Props = {
  onSave: (values: Record<string, any>) => void;
  register: any;
  errors: DeepMap<Record<string, any>, FieldError>;
  handleSubmit: Function;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<any>;
};

const Form = ({ onSave, register, handleSubmit }: Props) => {
  const inputStyle: React.CSSProperties = {
    padding: 5,
    width: "100%",
    borderRadius: 5,
    fontWeight: "normal",
    fontSize: 18,
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box style={{ overflow: "hidden" }}>
        <Grid
          container
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
          spacing={1}
          className="w-full"
        >
          <Grid item xs={3}>
            <Label text="Hosting-ID:" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"Hosting-ID"}
              id={"hostingId"}
              style={inputStyle}
              inputProps={register("hostingId", { required: true })}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <InfoMarkDownButton markdown={"**ID** des Hostings"} />
          </Grid>
          {/* <Grid item xs={3}>
            <Label text="EXS-ID:" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"EXS-ID"}
              defaultValue={templateInfo.exsID}
              id={"exsID"}
              style={inputStyle}
              inputProps={register("exsID", { required: true })}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <InfoMarkDownButton
              markdown={"**Extension-Store ID** des Templates"}
            />
          </Grid> */}
          <Grid item xs={3}>
            <Label text="Hosting Name:" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"Hosting Name"}
              id={"name"}
              style={inputStyle}
              inputProps={register("name", { required: true })}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <InfoMarkDownButton markdown={"**Name** des Hostings"} />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default Form;
