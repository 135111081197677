import { useEffect, useState } from "react";
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useFirestore } from "react-redux-firebase";
import DashboardCard from "../../general/DashboardCard";
import { ArcElement, Chart } from "chart.js";
import { COMPONENTS } from "../../../../../constants/colors";
import numbro from "numbro";
import { UserRole } from "../../../../../firestore/models";

const PluginSalesAmount = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [from, setFrom] = React.useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [to, setTo] = React.useState<Date | null>(new Date());

  const firestore = useFirestore();

  const [salesRecord, setSalesRecord] = useState<Record<string, any>>({});

  Chart.register(ArcElement);

  useEffect(() => {
    setLoading(true);
    let query = firestore
      .collection(`webhook`)
      .where(`event_type`, "==", `exs.order.completed`);
    if (from && from !== null) {
      query = query.where(`created_at`, ">", from.toISOString());
    }
    if (to && to !== null) {
      query = query.where(`created_at`, "<", to.toISOString());
    }
    query
      .limit(10000)
      .get()
      .then((result) => {
        const resultMap = result.docs.reduce<Record<string, any>>(
          (prev, curr) => {
            const key = curr.id;
            const data = curr.data();
            return { ...prev, [key]: data };
          },
          {}
        );
        setSalesRecord(resultMap);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [from, to]);

  const orderedSales = Object.entries(salesRecord || {})
    .sort((v1, v2) => {
      if (v1[1].created_at > v2[1].created_at) {
        return -1;
      } else if (v1[1].created_at < v2[1].created_at) {
        return 1;
      } else {
        return 0;
      }
    })
    .map(([k, v]) => {
      const items: Array<Record<string, any>> = v.resource.items;
      const products: Array<string> = [];
      items.map((v) => {
        v.name && products.push(v.name);
      });
      const amount = items.reduce<number>((acc, curr) => {
        return (acc = acc + curr.amount);
      }, 0);
      return {
        eventType: v.event_type,
        createdAt: new Date(v.created_at),
        type: v.resource.metas.license_type,
        order_type: v.resource.metas.order_type,
        exsId: v.resource.metas.exs_id,
        amount: +amount,
        products: products.join(", "),
      };
    });
  const totalAmount = (): string => {
    const amount = orderedSales.reduce<number>((acc, curr) => {
      return (acc = acc + curr.amount);
    }, 0);
    return `${numbro(amount / 100).format({
      mantissa: 2,
      thousandSeparated: true,
    })} EUR`;
  };

  return (
    <DashboardCard
      title="Verkäufe"
      headerChip={`30 Tage`}
      theme="plugin"
      loading={loading}
      role={UserRole.Operational}
    >
      <div
        style={{
          width: "100%",
          minWidth: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={0}
          alignItems={"center"}
          justifyContent="center"
        >
          <Grid item xs={12} justifyContent="center">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  color: "#FFF",
                  fontSize: 22,
                  fontFamily: "Roboto",
                  fontWeight: `500`,
                }}
              >
                {Object.keys(salesRecord || {}).length}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Typography
                style={{
                  color: "#BBB",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: `500`,
                }}
              >
                {`Gesamtverkäufe`}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  color: "#FFF",
                  fontSize: 22,
                  fontFamily: "Roboto",
                  fontWeight: `500`,
                }}
              >
                {totalAmount()
                  .replace(",", "#")
                  .replace(".", ",")
                  .replace("#", ".")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  color: "#BBB",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: `500`,
                }}
              >
                {`Gesamtumsatz`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </DashboardCard>
  );
};

export default PluginSalesAmount;
