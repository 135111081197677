import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import dashbar from "../../../../firebase/dashbar";
import { ICON_STYLE } from "../../../../utils/constants";

const WatcherTab = () => {
  const steps = ["Shop Domain", "Prüfen"];
  const stepButtons = ["Watcher starten", "Abschließen"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [domain, setDomain] = React.useState<string>("");

  const [msg, setMsg] = React.useState<string>("");
  const [online, setOnline] = React.useState<boolean>(false);
  const [maintenance, setMaintenance] = React.useState<boolean>(false);
  const [diffTime, setDiffTime] = React.useState<string>("");
  const [runningWatcher, setRunningWatcher] = useState<boolean>(false);
  const [watcherObject, setWatcherObject] = React.useState<Record<
    string,
    any
  > | null>(null);

  const onDomainChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };

  const checkWatcher = () => {
    setRunningWatcher(true);
    dashbar()
      .functions.httpsCallable("calls-helper-checkWatcher")(watcherObject)
      .then((data) => {
        setMsg(data.data.msg);
        setDiffTime(data.data.diffTime);
        setOnline(data.data.online);
        setMaintenance(data.data.maintenance);
      })
      .catch(() => {})
      .finally(() => {
        setRunningWatcher(false);
      });
  };

  useEffect(() => {
    if (domain !== "") {
      setWatcherObject({ url: domain });
    }
  }, [domain]);

  useEffect(() => {
    if (activeStep === 1) {
      checkWatcher();
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const WatcherData = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <input
          key={`domain`}
          type={"text"}
          value={domain}
          onChange={onDomainChanged}
          placeholder={"Domain"}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
          }}
        />
      </div>
    );
  };

  const WatcherResultCard = () => {
    if (runningWatcher) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Card
            style={{
              width: "30%",
              borderRadius: 15,
              marginBottom: 10,
              padding: 10,
              backgroundColor: `#fff`,
            }}
          >
            <LinearProgress />
          </Card>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={4}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={online ? "#19781f" : "#c90e0e"}
                icon={[ICON_STYLE, online ? "check-circle" : "exclamation-circle"]}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {online ? `Shop ist online` : `Shop ist offline`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={maintenance ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  maintenance ? "check-circle" : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {maintenance ? `Wartungsmodus aktiv` : `Wartungsmodus inaktiv`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {`Dauer:`}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {`${diffTime} Sekunden`}
              </Typography>
            </Grid>
            {!online && (
              <>
                <Grid
                  item
                  xs={4}
                  style={{ alignContent: "center", alignItems: "center" }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: "lighter",
                      color: "black",
                    }}
                  >
                    {`Info:`}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: "lighter",
                      color: "black",
                    }}
                  >
                    {`${msg}`}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </div>
    );
  };

  return (
    <div>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <React.Fragment>
          {activeStep === 0 ? <WatcherData /> : <WatcherResultCard />}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Zurück
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={activeStep === 1 ? handleReset : handleNext}
              disabled={activeStep === 0 && domain === ""}
            >
              {stepButtons[activeStep]}
            </Button>
          </Box>
        </React.Fragment>
      }
    </div>
  );
};

export default WatcherTab;
