import React, { useEffect, useState } from "react";
import { DB } from "../../../firebase";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";

const PluginInstalls = () => {
  const [pluginInstalls, setPluginInstalls] = useState<
    Array<Record<string, any>>
  >([]);

  const [summarize, setSummarize] = useState<boolean>(false);

  useEffect(() => {
    DB()
      .collectionGroup(`install`)
      .where(`pluginId`, ">", `ws5_`)
      .get()
      .then((docs) => {
        const installArray: Array<Record<string, any>> = [];
        docs.forEach((doc) => {
          installArray.push({ ...doc.data() });
        });
        console.log(installArray);
        setPluginInstalls(installArray);
      });
  }, []);

  const reducedPluginVersions =
    pluginInstalls
      .filter((install) => {
        return (
          install?.shopVersion !== undefined &&
          install?.shopVersion !== `undefined`
        );
      })
      .map((install) => {
        return install?.shopVersion;
      })
      .sort()
      .reduce<Record<string, any>>(
        (prev: Record<string, any>, curr: string) => {
          if (prev?.[curr]) {
            return { ...prev, [curr]: prev[curr] + 1 };
          } else {
            return { ...prev, [curr]: 1 };
          }
        },
        {}
      ) ?? {};

  const reducedSummarizedPluginVersions =
    pluginInstalls
      .filter((install) => {
        return (
          install?.shopVersion !== undefined &&
          install?.shopVersion !== `undefined`
        );
      })
      .map((install) => {
        const split = install?.shopVersion.split(".");
        return `${split[0]}.${split[1]}.x`;
      })
      .sort()
      .reduce<Record<string, any>>(
        (prev: Record<string, any>, curr: string) => {
          if (prev?.[curr]) {
            return { ...prev, [curr]: prev[curr] + 1 };
          } else {
            return { ...prev, [curr]: 1 };
          }
        },
        {}
      ) ?? {};
  console.log(reducedPluginVersions);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "60%" }}>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <code
            style={{ fontSize: 12 }}
          >{`Datengrundlage sind die übermittelten Shop-Versionen aus dem Tracking Pixel im Plugin-Backend (nur ws5_ -Plugins). Diese werden nur bei Aufruf des Plugin-Backends übertragen. Der Datenbestand ist dadurch nicht zwingend aktuell`}</code>
        </div>
        <FormControlLabel
          style={{ marginBottom: 5 }}
          control={
            <Checkbox
              checked={summarize}
              onChange={(event) => {
                setSummarize(event.currentTarget.checked);
              }}
            />
          }
          label="Versionen zusammenfassen"
        />
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
              >
                {`Version`}
              </Typography>
            }
          </Grid>
          <Grid item xs={8}>
            {
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
              >
                {`Anzahl Installationen`}
              </Typography>
            }
          </Grid>
          {Object.entries(
            (summarize
              ? reducedSummarizedPluginVersions
              : reducedPluginVersions) ?? {}
          ).map(([k, v]) => {
            return (
              <>
                <Grid item xs={4}>{`Version ${k}`}</Grid>
                <Grid item xs={8}>{`${v}`}</Grid>
              </>
            );
          })}
        </Grid>
      </div>
    </div>
  );
  //   return <div>{JSON.stringify(pluginInstalls)}</div>;
};

export default PluginInstalls;
