import {
  Button,
  Card,
  Checkbox,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import firebase from "firebase/compat/app";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InfoMarkDownButton from "../../../../../../components/InfoMarkdownButton";
import { Textarea } from "../../../../../../components/Input";
import { DB } from "../../../../../../firebase";
import Input from "../../../../../marketingNew/Dashboard/components/InputComponent";
import LoadingCompoenent from "../../../../../marketingNew/Dashboard/components/LoadingComponent";
import useAuth from "../../../../../../hooks/useAuth";
import { UserRole } from "../../../../../../firestore/models";
import useProfile from "../../../../../../hooks/firestore/useProfile";
import { useNavigate } from "react-router-dom";

const CURRENCIES = [{ name: `EUR`, value: `EUR` }];

interface IDescriptionFormProps {
  pluginId: string;
}

const DescriptionForm = ({ pluginId }: IDescriptionFormProps) => {
  const navigate = useNavigate();
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");
  const auth = useAuth();
  const profile = useProfile();

  const [loading, setLoading] = useState<boolean>(false);

  //Updating data (mostly images)
  const [updating, setUpdating] = useState<boolean>(false);

  //Name
  const [pluginName, setPluginName] = useState<string>("");
  const [pluginShortName, setPluginShortName] = useState<string>("");

  //ExtensionStore SKU
  const [exsSKU, setExsSku] = useState<string>("");

  //Preis
  const [pluginPriceValue, setPluginPriceValue] = useState<string>("0.00");
  const [pluginPriceCurrency, setPluginPriceCurrency] = useState<string>("EUR");

  //Sale Preis
  const [salePriceValue, setSalePriceValue] = useState<string>("0.00");
  const [salePriceCurrency, setSalePriceCurrency] = useState<string>("EUR");

  //Sale gültig bis
  const [salePriceValidUntil, setSalePriceValidUntil] = useState<Date>(
    new Date()
  );

  //Kaufargumente
  const [saleArgumentOne, setSaleArgumentOne] = useState<string>("");
  const [saleArgumentTwo, setSaleArgumentTwo] = useState<string>("");
  const [saleArgumentThree, setSaleArgumentThree] = useState<string>("");

  //Marekting Video URL
  const [marketingVideoUrl, setMarketingVideoUrl] = useState<string>("");

  //Beschreibung Plugin Markdown
  const [pluginDescription, setPluginDescription] = useState<string>("");

  //Beschreibung Service
  const [serviceDescription, setServiceDescription] = useState<string>("");

  //Beschreibung Marketing
  const [marketingDescription, setMarketingDescription] = useState<string>("");

  //Kurbeschreibung
  const [shortDescription, setShortdescription] = useState<string>("");

  //Vorteile
  const [advantageOne, setAdvantageOne] = useState<string>("");
  const [advantageTwo, setAdvantageTwo] = useState<string>("");
  const [advantageThree, setAdvantageThree] = useState<string>("");
  const [advantageFour, setAdvantageFour] = useState<string>("");
  const [advantageFive, setAdvantageFive] = useState<string>("");
  //Referenzen
  const [referenceUrlOne, setReferenceUrlOne] = useState<string>("");
  const [referenceUrlTwo, setReferenceUrlTwo] = useState<string>("");
  const [referenceUrlThree, setReferenceUrlThree] = useState<string>("");
  const [referenceUrlFour, setReferenceUrlFour] = useState<string>("");
  const [referenceUrlFive, setReferenceUrlFive] = useState<string>("");

  //Plugin Dokumentation URL
  const [pluginDokuUrl, setPluginDokuUrl] = useState<string>("");

  //Allgemeine Info URL (Lizenez) Standard: https://ws-url.de/lizenz-infos
  const [infoUrlLicence, setInfoUrlLicence] = useState<string>(
    "https://ws-url.de/lizenz-infos"
  );

  //Allgemeine Info URL (Verkauf) Standard: https://ws-url.de/plugin-kauf
  const [infoUrlSale, setInfoUrlSale] = useState<string>(
    "https://ws-url.de/plugin-kauf"
  );

  //ExtensionStore Url
  const [exStoreUrl, setExStoreUrl] = useState<string>("");

  //ExtensionStore Url
  const [exStoreOrgUrl, setExStoreOrgUrl] = useState<string>("");

  //Demostore Url
  const [wsStoreUrl, setWsStoreUrl] = useState<string>("");

  //Live-Demostore Url
  const [liveDemoStoreUrl, setLiveDemoStoreUrl] = useState<string>("");

  //Kompatibel mit Shop-Version
  const [compatibleShopVersion, setCompatibleShopVersion] = useState<
    Array<string>
  >([""]);

  //Kompatibel mit Templates
  const [compatibleTemplates, setCompatibleTemplates] = useState<Array<string>>(
    [""]
  );

  //Plugin Tags
  const [pluginTags, setPluginTags] = useState<Array<string>>([""]);

  //Plugin im Fokus
  const [pluginFocused, setPluginFocused] = useState<boolean>(false);

  //Plugin ist Top-Angebot
  const [pluginTopOffer, setPluginTopOffer] = useState<boolean>(false);

  //Plugin soll im Webstollen Store angezeigt werden
  const [showInStore, setShowInStore] = useState<boolean>(false);

  //Plugin ist Bestseller
  const [pluginIsBestseller, setPluginIsBestseller] = useState<boolean>(false);

  //Plugin ist neu
  const [pluginIsNew, setPluginIsNew] = useState<boolean>(false);

  //Erscheinungsdatum des Plugins
  const [publicationDate, setPublicationDate] = useState<Date>(new Date());

  //Plugin Icon
  const [pluginIconPng, setPluginIconPng] = useState<File>();
  const [pluginIconPngUrl, setPluginIconPngUrl] = useState<string>();

  //Plugin Icon
  const [pluginIconSvg, setPluginIconSvg] = useState<File>();
  const [pluginIconSvgUrl, setPluginIconSvgUrl] = useState<string>();

  //Plugin Images
  const [pluginImages, setPluginImages] = useState<FileList>();
  const [pluginImagesUrl, setPluginImagesUrl] = useState<Array<string>>();
  const [choosenPluginImage, setChoosenPluginImage] = useState<File>();
  const [pluginImagesUploadedList, setPluginImagesUploadedList] = useState<
    Array<{ path: string; url: string }>
  >([]);

  const inputRefs = useRef<Array<HTMLInputElement>>([]);

  const handleImageInputClick = (index: number) => {
    inputRefs.current[index].click();
  };

  const addRef = (el: HTMLInputElement) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const [error, setError] = useState<{
    type: string;
    message: string;
  } | null>();

  const onPluginNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPluginName(event.currentTarget.value);
  };
  const onPluginShortNameChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPluginShortName(event.currentTarget.value);
  };

  const onExsSkuChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExsSku(event.currentTarget.value);
  };

  const onPluginPriceValueChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPluginPriceValue(event.currentTarget.value);
  };

  const onSalePriceValueChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSalePriceValue(event.currentTarget.value);
  };

  const onPluginPriceCurrencyChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPluginPriceCurrency(event.currentTarget.value);
  };

  const onSalePriceCurrencyChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSalePriceCurrency(event.currentTarget.value);
  };

  const onSaleArgumentOneChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaleArgumentOne(event.currentTarget.value);
  };
  const onSaleArgumentTwoChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaleArgumentTwo(event.currentTarget.value);
  };
  const onSaleArgumentThreeChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaleArgumentThree(event.currentTarget.value);
  };

  const onMarketingVideoUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMarketingVideoUrl(event.currentTarget.value);
  };

  const onPluginDescriptionChanged = (event: string | undefined) => {
    setPluginDescription(event || "");
  };

  const onServiceDescriptionChanged = (event: string | undefined) => {
    setServiceDescription(event || "");
  };

  // const onShortdescriptionChanged = (event: string | undefined) => {
  const onShortdescriptionChanged = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setShortdescription(event.currentTarget.value);
  };
  const onMarketingDescriptionChanged = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMarketingDescription(event.currentTarget.value);
  };

  const onAdvantageOneChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageOne(event.currentTarget.value);
  };
  const onAdvantageTwoChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageTwo(event.currentTarget.value);
  };
  const onAdvantageThreeChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageThree(event.currentTarget.value);
  };
  const onAdvantageFourChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageFour(event.currentTarget.value);
  };
  const onAdvantageFiveChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageFive(event.currentTarget.value);
  };

  const onReferenceUrlOneChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferenceUrlOne(event.currentTarget.value);
  };
  const onReferenceUrlTwoChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferenceUrlTwo(event.currentTarget.value);
  };
  const onReferenceUrlThreeChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferenceUrlThree(event.currentTarget.value);
  };
  const onReferenceUrlFourChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferenceUrlFour(event.currentTarget.value);
  };
  const onReferenceUrlFiveChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferenceUrlFive(event.currentTarget.value);
  };
  const onPluginDokuUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPluginDokuUrl(event.currentTarget.value);
  };

  const onInfoUrlLicenceChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInfoUrlLicence(event.currentTarget.value);
  };

  const onInfoUrlSaleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfoUrlSale(event.currentTarget.value);
  };

  const onExStoreUrlChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExStoreUrl(event.currentTarget.value);
  };

  const onExStoreOrgUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExStoreOrgUrl(event.currentTarget.value);
  };

  const onPublicationDateChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPublicationDate(new Date(event.currentTarget.value));
  };
  const onSalePriceValidUntilChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSalePriceValidUntil(new Date(event.currentTarget.value));
  };

  const onWsStoreUrlChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWsStoreUrl(event.currentTarget.value);
  };
  const onLiveDemoStoreUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLiveDemoStoreUrl(event.currentTarget.value);
  };
  const onCompatibleShopVersionChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const versions: Array<string> = event.currentTarget.value.split(`,`);
    setCompatibleShopVersion(versions);
  };
  const onCompatibleTemplatesChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const templates: Array<string> = event.currentTarget.value.split(`,`);
    setCompatibleTemplates(templates);
  };

  const onPluginTagsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tags: Array<string> = event.currentTarget.value.split(`,`);
    setPluginTags(tags);
  };

  const onPluginIconPngChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setPluginIconPng(e.currentTarget?.files[0]);
    }
  };

  const onPluginIconSvgChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setPluginIconSvg(e.currentTarget?.files[0]);
    }
  };

  const onChoosenPluginImageChanged = async (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setUpdating(true);
      const image = e.currentTarget?.files[0];
      console.log(image.name);
      console.log(image.name.split(".").pop());
      setChoosenPluginImage(image);
      try {
        const currentRef = storage.ref(pluginImagesUploadedList[index].path);
        await currentRef.delete();
      } catch (e) {
        console.log(e);
      }
      const url = URL.createObjectURL(image);
      const response = await fetch(url);
      const blob = await response.blob();
      const path = `plugins/images/${pluginId}/${pluginId.replace(
        "ws5_",
        "ws_"
      )}_${moment().format("x")}.${image.name.split(".").pop()}`;
      const ref = storage.ref(path);
      await ref.put(blob);
      const imageUrl = await ref.getDownloadURL();
      const tempArray = [...pluginImagesUploadedList];
      tempArray.splice(index, 1);
      tempArray.splice(index, 0, { path: path, url: imageUrl });
      setPluginImagesUploadedList(tempArray);
      await DB()
        .collection(`plugin`)
        .doc(`${pluginId}`)
        .set(
          {
            media: {
              images: pluginImagesUploadedList,
            },
          },
          { merge: true }
        );
      setUpdating(false);
    }
  };

  const onPluginImagesChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setPluginImages(e.currentTarget?.files);
    }
  };

  const uploadPluginIcon = async (
    type: "PNG" | "SVG"
  ): Promise<{
    path: string;
    url: string;
  } | null> => {
    const currentIcon = type === "PNG" ? pluginIconPng : pluginIconSvg;
    if (!currentIcon || currentIcon === null || !pluginId) return null;
    const url = URL.createObjectURL(currentIcon);
    const response = await fetch(url);
    const blob = await response.blob();
    // const path = `plugins/icons/${pluginId.replace(
    //   "ws5_",
    //   "ws_"
    // )}.${currentIcon.name.split(".").pop()}`;
    const path = `plugins/icons/${pluginId.replace("ws5_", "ws_")}.${
      type === "PNG" ? "png" : "svg"
    }`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const iconUrl = await ref.getDownloadURL();
    type === "PNG"
      ? setPluginIconPngUrl(iconUrl)
      : setPluginIconSvgUrl(iconUrl);
    return { path: path, url: iconUrl };
  };

  const deletePluginImages = async () => {
    if (!pluginId) return;
    // if (
    //   !window.confirm("Really want to delete ALL images? (Can not be undone)")
    // )
    //   return;
    const path = `plugins/images/${pluginId}`;
    const ref = storage.ref(path);
    const files = await ref.listAll();
    for (const file of files.items) {
      await file.delete();
    }
    setPluginImagesUrl(undefined);
    await DB()
      .collection(`plugin`)
      .doc(`${pluginId}`)
      .set({ media: undefined }, { merge: true });
    return;
  };

  const uploadPluginImages = async (): Promise<Array<{
    path: string;
    url: string;
  }> | null> => {
    if (!pluginImages || pluginImages.length <= 0 || !pluginId) return null;
    const imageArray = Array.from(pluginImages).sort((a: File, b: File) => {
      const sortStringA = a.name.split(".")[0];
      const sortStringB = b.name.split(".")[0];
      if (
        sortStringA.substring(sortStringA.length - 2) >
        sortStringB.substring(sortStringB.length - 2)
      ) {
        return 1;
      } else if (
        sortStringA.substring(sortStringA.length - 2) <
        sortStringB.substring(sortStringB.length - 2)
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    const imagePaths: Array<{ path: string; url: string }> = [];
    const imageUrls: Array<string> = [];
    let counter: number = 0;
    for (const image of imageArray) {
      const url = URL.createObjectURL(image);
      const response = await fetch(url);
      const blob = await response.blob();
      const path = `plugins/images/${pluginId}/${pluginId.replace(
        "ws5_",
        "ws_"
      )}_${counter}.${image.name.split(".").pop()}`;
      const ref = storage.ref(path);
      await ref.put(blob);
      const imageUrl = await ref.getDownloadURL();
      imagePaths.push({ path: path, url: imageUrl });
      imageUrls.push(imageUrl);
      counter++;
    }
    setPluginImagesUrl(imageUrls);
    return imagePaths;
  };

  const save = async () => {
    setLoading(true);
    setUpdating(true);
    //ToDo Validation
    // const urlRegex =
    //   /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g;

    // if (!urlRegex.test(pluginDokuUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Plugin Doku muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }
    // if (!urlRegex.test(marketingVideoUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Marketing Video muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!urlRegex.test(infoUrlLicence)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Lizenz Infos muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }
    // if (!urlRegex.test(infoUrlSale)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Kauf Infos muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!urlRegex.test(exStoreUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Exetnsion Store muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!urlRegex.test(demoStoreUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Demoshop muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }
    if (pluginName.length > 75) {
      setError({
        type: "invalid_length",
        message: "Name darf nicht länger als 75 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (pluginShortName.length > 20) {
      setError({
        type: "invalid_length",
        message: "ShortName darf nicht länger als 20 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (
      saleArgumentOne.length > 100 ||
      saleArgumentTwo.length > 100 ||
      saleArgumentThree.length > 100
    ) {
      setError({
        type: "invalid_length",
        message: "Kaufargumente dürfen nicht länger als 100 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (pluginDescription.length > 3500) {
      setError({
        type: "invalid_length",
        message: "Plugin Beschreibung darf nicht länger als 3500 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (serviceDescription.length > 2000) {
      setError({
        type: "invalid_length",
        message:
          "Service Beschreibung darf nicht länger als 2000 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (
      advantageOne.length > 100 ||
      advantageTwo.length > 100 ||
      advantageThree.length > 100 ||
      advantageFour.length > 100 ||
      advantageFive.length > 100
    ) {
      setError({
        type: "invalid_length",
        message: "Vorteile dürfen nicht länger als 100 Zeichen sein!",
      });
      setLoading(false);
      return;
    }

    let iconPng = undefined;
    if (pluginIconPng && pluginIconPng !== null) {
      iconPng = await uploadPluginIcon("PNG");
    }

    let iconSvg = undefined;
    if (pluginIconSvg && pluginIconSvg !== null) {
      iconSvg = await uploadPluginIcon("SVG");
    }

    let images = undefined;
    if (pluginImages && pluginImages !== null) {
      await deletePluginImages();
      images = await uploadPluginImages();
    } else if (
      pluginImagesUploadedList &&
      pluginImagesUploadedList.length > 0
    ) {
      images = pluginImagesUploadedList;
    }

    const pluginObject = {
      name: pluginName,
      shortName: pluginShortName,
      exs_sku: exsSKU,
      price: { value: pluginPriceValue, currency: pluginPriceCurrency },
      sale: {
        price: { value: salePriceValue, currency: salePriceCurrency },
        validUntil: salePriceValidUntil,
      },
      description: pluginDescription,
      showInStore: showInStore === true,
      links: {
        dokuUrl: pluginDokuUrl,
        marketingVideo: marketingVideoUrl,
        licenceInfo: infoUrlLicence,
        saleInfo: infoUrlSale,
        exStore: exStoreUrl,
        exStoreOrg: exStoreOrgUrl,
        wsStore: wsStoreUrl,
        liveDemoShop: liveDemoStoreUrl,
        referenceUrls: {
          refUrl1: referenceUrlOne,
          refUrl2: referenceUrlTwo,
          refUrl3: referenceUrlThree,
          refUrl4: referenceUrlFour,
          refUrl5: referenceUrlFive,
        },
      },
      info: {
        saleArguments: {
          arg1: saleArgumentOne,
          arg2: saleArgumentTwo,
          arg3: saleArgumentThree,
        },
        advantages: {
          adv1: advantageOne,
          adv2: advantageTwo,
          adv3: advantageThree,
          adv4: advantageFour,
          adv5: advantageFive,
        },
        serviceDescription: serviceDescription,
        shortDescription: shortDescription,
        marketingDescription: marketingDescription,
        compatibleShopVersion: compatibleShopVersion,
        compatibleTemplates: compatibleTemplates,
        tags: pluginTags,
        // pluginFocused: pluginFocused === true,
        pluginIsTopOffer: pluginTopOffer === true,
        pluginIsBestseller: pluginIsBestseller === true,
        pluginIsNew: pluginIsNew === true,
        pluginPublicationDate: publicationDate,
      },
      media: {
        icon_png: iconPng,
        icon_svg: iconSvg,
        images: images,
      },
    };
    await DB()
      .collection(`plugin`)
      .doc(`${pluginId}`)
      .set(pluginObject, { merge: true });
    setLoading(false);
    setUpdating(false);
  };
  useEffect(() => {
    DB()
      .collection(`config`)
      .doc(`campaign`)
      .get()
      .then((data) => {
        if (data.data()?.plugin_im_fokus?.current === pluginId) {
          setPluginFocused(true);
        } else {
          setPluginFocused(false);
        }
      });
  }, [pluginId]);

  useEffect(() => {
    setLoading(true);
    DB()
      .collection(`plugin`)
      .doc(`${pluginId}`)
      .get()
      .then((doc) => {
        const data = doc.data();
        setPluginName(data?.name || "");
        setPluginShortName(data?.shortName || "");
        setExsSku(data?.exs_sku || "");
        setPluginPriceValue(data?.price?.value || "0.00");
        setPluginPriceCurrency(data?.price?.currency || "EUR");
        setSalePriceValue(data?.sale?.price?.value || "0.00");
        setSalePriceCurrency(data?.sale?.price?.currency || "EUR");
        setSalePriceValidUntil(data?.sale?.validUntil?.toDate());
        setPluginDescription(data?.description || "");
        setPluginDokuUrl(data?.links?.dokuUrl || "");
        setMarketingVideoUrl(data?.links?.marketingVideo || "");
        setInfoUrlLicence(
          data?.links?.licenceInfo || "https://ws-url.de/lizenz-infos"
        );
        setInfoUrlSale(
          data?.links?.saleInfo || "https://ws-url.de/plugin-kauf"
        );
        setExStoreUrl(data?.links?.exStore || "");
        setExStoreOrgUrl(data?.links?.exStoreOrg || "");
        setWsStoreUrl(data?.links?.wsStore || "");
        setLiveDemoStoreUrl(data?.links?.liveDemoShop || "");
        setSaleArgumentOne(data?.info?.saleArguments?.arg1 || "");
        setSaleArgumentTwo(data?.info?.saleArguments?.arg2 || "");
        setSaleArgumentThree(data?.info?.saleArguments?.arg3 || "");
        setAdvantageOne(data?.info?.advantages?.adv1 || "");
        setAdvantageTwo(data?.info?.advantages?.adv2 || "");
        setAdvantageThree(data?.info?.advantages?.adv3 || "");
        setAdvantageFour(data?.info?.advantages?.adv4 || "");
        setAdvantageFive(data?.info?.advantages?.adv5 || "");
        setReferenceUrlOne(data?.links?.referenceUrls?.refUrl1 || "");
        setReferenceUrlTwo(data?.links?.referenceUrls?.refUrl2 || "");
        setReferenceUrlThree(data?.ilinksnfo?.referenceUrls?.refUrl3 || "");
        setReferenceUrlFour(data?.links?.referenceUrls?.refUrl4 || "");
        setReferenceUrlFive(data?.links?.referenceUrls?.refUrl5 || "");
        setServiceDescription(data?.info?.serviceDescription || "");
        setShortdescription(data?.info?.shortDescription || "");
        setMarketingDescription(data?.info?.marketingDescription || "");
        // setPluginFocused(data?.info?.pluginFocused === true);
        setPluginTopOffer(data?.info?.pluginIsTopOffer === true);
        setPluginIsBestseller(data?.info?.pluginIsBestseller === true);
        setShowInStore(data?.showInStore === true);
        setPluginIsNew(data?.info?.pluginIsNew === true);
        setPublicationDate(data?.info?.pluginPublicationDate.toDate());
        setCompatibleShopVersion(data?.info?.compatibleShopVersion || "");
        setCompatibleTemplates(data?.info?.compatibleTemplates || "");
        setPluginTags(data?.info?.tags || "");
        if (data?.media?.images) {
          const imageArray: Array<string> = [];
          const uploadedArray: Array<{ path: string; url: string }> = [];
          data?.media?.images.map((v: Record<string, any>) => {
            imageArray.push(v.url);
            uploadedArray.push({ url: v.url, path: v.path });
          });
          setPluginImagesUrl(imageArray);
          setPluginImagesUploadedList(uploadedArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const moveImage = (index: number, backward: boolean = false) => {
    if (backward === true && index === 0) return;
    if (backward === false && index === pluginImagesUploadedList?.length - 1)
      return;
    setUpdating(true);
    const tempArray = [...pluginImagesUploadedList];
    const item = tempArray.splice(index, 1)[0];
    tempArray.splice(backward === true ? index - 1 : index + 1, 0, item);
    setPluginImagesUploadedList(tempArray);
    setUpdating(false);
  };

  if (
    !auth ||
    !(
      profile.roles.includes(UserRole.Developer) ||
      profile.roles.includes(UserRole.Admin) ||
      profile.roles.includes(UserRole.Operational)
    )
  ) {
    return null;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              justifyContent="flex-start"
              alignContent="center"
              alignItems="center"
              spacing={1}
              className="w-full"
            >
              <Grid item xs={3}>
                <Label text="Im WS-Store anzeigen:" />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={showInStore}
                  onChange={() => {
                    setShowInStore(!showInStore);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Plugin im **WS-Store** gelistet werden soll."
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Name:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Plugin Name"}
                  onChange={onPluginNameChanged}
                  value={pluginName}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton markdown={"**Name** des Plugins"} />
              </Grid>
              <Grid item xs={3}>
                <Label text="Short Name:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Plugin Short Name"}
                  onChange={onPluginShortNameChanged}
                  value={pluginShortName}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"**ShortName** des Plugins (max. 20 Zeichen)"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Extension-Store SKU:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"SKU"}
                  onChange={onExsSkuChanged}
                  value={exsSKU}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**SKU** des Plugins im JTL-Extension Store oder anderen Stores"
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Label text="Plugin Preis:" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"text"}
                  placeholder={"Wert"}
                  onChange={onPluginPriceValueChanged}
                  value={pluginPriceValue}
                />
              </Grid>
              <Grid item xs={4}>
                <select
                  key="price_currency"
                  value={pluginPriceCurrency}
                  onChange={onPluginPriceCurrencyChanged}
                  style={{
                    padding: 5,
                    width: "100%",
                    borderRadius: 5,
                    marginBottom: 5,
                    color: "black",
                    fontSize: 18,
                  }}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <option key={currency.value} value={currency.value}>
                        {currency.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**Preis** des Plugins im JTL-Extension Store oder anderen Stores.\n\n - Preis muss im Format `0.00` angegeben werden"
                  }
                />
              </Grid>
              {/* Beginn: Sale Preis */}
              <Grid item xs={3}>
                <Label text="Sale Preis:" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"text"}
                  placeholder={"Wert"}
                  onChange={onSalePriceValueChanged}
                  value={salePriceValue}
                />
              </Grid>
              <Grid item xs={4}>
                <select
                  key="price_currency"
                  value={salePriceCurrency}
                  onChange={onSalePriceCurrencyChanged}
                  style={{
                    padding: 5,
                    width: "100%",
                    borderRadius: 5,
                    marginBottom: 5,
                    color: "black",
                    fontSize: 18,
                  }}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <option key={currency.value} value={currency.value}>
                        {currency.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**Sale Preis** des Plugins im JTL-Extension Store oder anderen Stores.\n\n - Sale Preis muss im Format `0.00` angegeben werden"
                  }
                />
              </Grid>
              {/* Ende: Sale Preis */}
              {/* Beginn:Sale gültig bis: */}
              <Grid item xs={3}>
                <Label text="Sale gültig bis:" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"date"}
                  placeholder={"Sale gültig bis:"}
                  onChange={onSalePriceValidUntilChanged}
                  value={
                    salePriceValidUntil &&
                    moment(salePriceValidUntil).format(`YYYY-MM-DD`)
                  }
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Der Sale Preis wird bis zum Gültigkeitsablauf im WS Store angezeigt (mit Rabatt Badge)"
                  }
                />
              </Grid>
              {/* Ende: Sale gültig bis */}

              <Grid item xs={3}>
                <Label text="Kaufargumente:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Argument 1"}
                  onChange={onSaleArgumentOneChanged}
                  value={saleArgumentOne}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"Drei **Kaufargumente** für das Plugin"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Argument 2"}
                  onChange={onSaleArgumentTwoChanged}
                  value={saleArgumentTwo}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Argument 3"}
                  onChange={onSaleArgumentThreeChanged}
                  value={saleArgumentThree}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Marketing Video (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onMarketingVideoUrlChanged}
                  value={marketingVideoUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"**URL** zum Marketing Video (z.B. YouTube-Link)"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Plugin Beschreibung:" />
              </Grid>
              <Grid item xs={8}>
                <MDEditor
                  value={pluginDescription}
                  preview={"live"}
                  height={200}
                  onChange={onPluginDescriptionChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"**Beschreibung** des Plugins in Markdown-Language"}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <MDEditor.Markdown
                  style={{ width: "100%" }}
                  source={pluginDescription}
                />
              </Grid> */}
              <Grid item xs={3}>
                <Label text="Service Beschreibung:" />
              </Grid>
              <Grid item xs={8}>
                {/* <Textarea
                  placeholder={"Service Beschreibung"}
                  onChange={onServiceDescriptionChanged}
                  value={serviceDescription}
                  rows={3}
                /> */}
                <MDEditor
                  value={serviceDescription}
                  preview={"live"}
                  height={200}
                  onChange={onServiceDescriptionChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**Service Beschreibung** des Plugins in Markdown-Language"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Marketing Beschreibung:" />
              </Grid>
              <Grid item xs={8}>
                <Textarea
                  placeholder={"Marketing Beschreibung"}
                  onChange={onMarketingDescriptionChanged}
                  value={marketingDescription}
                  rows={3}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"**Marketing Beschreibung** des Plugins"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Kurzbeschreibung:" />
              </Grid>
              <Grid item xs={8}>
                <Textarea
                  placeholder={"Kurzbeschreibung"}
                  onChange={onShortdescriptionChanged}
                  value={shortDescription}
                  rows={3}
                />
                {/* <MDEditor
                  value={shortDescription}
                  preview={"live"}
                  height={200}
                  onChange={onShortdescriptionChanged}
                /> */}
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"**Kurzbeschreibung** des Plugins"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Vorteile:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 1"}
                  onChange={onAdvantageOneChanged}
                  value={advantageOne}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Fünf **Vorteile** die, dieses Plugin mit sich bringt"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 2"}
                  onChange={onAdvantageTwoChanged}
                  value={advantageTwo}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 3"}
                  onChange={onAdvantageThreeChanged}
                  value={advantageThree}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 4"}
                  onChange={onAdvantageFourChanged}
                  value={advantageFour}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 5"}
                  onChange={onAdvantageFiveChanged}
                  value={advantageFive}
                />
              </Grid>
              {/* ReferenceUrls */}
              <>
                <Grid item xs={3}>
                  <Label text="Referenz-URLs:" />
                </Grid>
                <Grid item xs={8}>
                  <Input
                    type={"text"}
                    placeholder={"1. Referenz-URL"}
                    onChange={onReferenceUrlOneChanged}
                    value={referenceUrlOne}
                  />
                </Grid>
                <Grid item xs={1}>
                  <InfoMarkDownButton
                    markdown={`**Referenz-URLs** von bis zu 5 Shops, in welchen ${
                      pluginShortName ?? `das Plugin`
                    } verwendet wird`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Label text="" />
                </Grid>
                <Grid item xs={8}>
                  <Input
                    type={"text"}
                    placeholder={"2. Referenz-URL"}
                    onChange={onReferenceUrlTwoChanged}
                    value={referenceUrlTwo}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Label text="" />
                </Grid>
                <Grid item xs={8}>
                  <Input
                    type={"text"}
                    placeholder={"3. Referenz-URL"}
                    onChange={onReferenceUrlThreeChanged}
                    value={referenceUrlThree}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Label text="" />
                </Grid>
                <Grid item xs={8}>
                  <Input
                    type={"text"}
                    placeholder={"4. Referenz-URL"}
                    onChange={onReferenceUrlFourChanged}
                    value={referenceUrlFour}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Label text="" />
                </Grid>
                <Grid item xs={8}>
                  <Input
                    type={"text"}
                    placeholder={"5. Referenz-URL"}
                    onChange={onReferenceUrlFiveChanged}
                    value={referenceUrlFive}
                  />
                </Grid>
              </>
              {/* Plugin Doku (URL) */}
              <Grid item xs={3}>
                <Label text="Plugin Doku (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onPluginDokuUrlChanged}
                  value={pluginDokuUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL zur **Dokumentation** des Plugins"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Lizenz Infos (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onInfoUrlLicenceChanged}
                  value={infoUrlLicence}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL für **Lizenzinformationen** zum Plugin"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Kauf Infos (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onInfoUrlSaleChanged}
                  value={infoUrlSale}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL zu **Kaufinfromationen** des Plugins"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Extension Store (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onExStoreUrlChanged}
                  value={exStoreUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "URL zur **Extension-Store Detailseite** des Plugins"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Extension Store Original (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onExStoreOrgUrlChanged}
                  value={exStoreOrgUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "URL zur **Extension-Store Detailseite** des Plugins (ohne ws-url Link)"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="WebStollen Store (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onWsStoreUrlChanged}
                  value={wsStoreUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL zur **Demoshop Detailseite** des Plugins"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Live-Demoshop (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onLiveDemoStoreUrlChanged}
                  value={liveDemoStoreUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL zur **Livedemo** des Plugins"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Kompatibel mit Shopversion:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Shopversionen (getrennt mit Komma)"}
                  onChange={onCompatibleShopVersionChanged}
                  value={
                    compatibleShopVersion ? compatibleShopVersion.join(`,`) : ""
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Auflistung aller **Shopversionen** mit welchen das Plugin kompatibel ist.\n\n Diese müssen mit Komma getrennt werden z.B. `5.1.0,5.2.1`"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Kompatibel mit Templates:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Templates (getrennt mit Komma)"}
                  onChange={onCompatibleTemplatesChanged}
                  value={
                    compatibleTemplates ? compatibleTemplates.join(`,`) : ""
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Auflistung aller **Templates** mit welchen das Plugin kompatibel ist.\n\n Diese müssen mit Komma getrennt werden z.B. `Nova, Snackys`"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Tags:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Tags (getrennt mit Komma)"}
                  onChange={onPluginTagsChanged}
                  value={pluginTags ? pluginTags.join(`,`) : ""}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Auflistung aller **Tags** die diesem Plugin zugeordnet sind.\n\n Diese müssen mit Komma getrennt werden z.B. `Analytics, Payment`"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Plugin im Fokus:" />
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={pluginFocused}
                  disabled
                  // onChange={() => {
                  //   setPluginFocused(!pluginFocused);
                  // }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    navigate(`/marketing/edit/default`);
                  }}
                  variant="text"
                >
                  Standard bearbeiten
                </Button>
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Plugin gerade im **Fokus** einer Marketingkampagne o.Ä. steht, um dieses besonders hervorheben zu können"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Plugin ist Topangebot:" />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={pluginTopOffer}
                  onChange={() => {
                    setPluginTopOffer(!pluginTopOffer);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Plugin als **Topangebot** gelistet werden soll"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Plugin ist Bestseller:" />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={pluginIsBestseller}
                  onChange={() => {
                    setPluginIsBestseller(!pluginIsBestseller);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Plugin als **Bestseller** gelistet werden soll"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Plugin ist Neu:" />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={pluginIsNew}
                  onChange={() => {
                    setPluginIsNew(!pluginIsNew);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Plugin als **neues Plugin** gelistet werden soll"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Erscheinungsdatum:" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"date"}
                  placeholder={"Erscheinungsdatum:"}
                  onChange={onPublicationDateChanged}
                  value={
                    publicationDate &&
                    moment(publicationDate).format(`YYYY-MM-DD`)
                  }
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Das **Erscheinungsdatum** des Plugins\nWird für die Sortierung im DEV-Shop verwendet"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Icon Upload (png):" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"file"}
                  disabled={updating}
                  accept="image/png"
                  onChange={onPluginIconPngChanged}
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{
                    maxWidth: "50px",
                    padding: 10,
                    borderRadius: 50,
                    backgroundColor: "#eee",
                  }}
                  alt={`Plugin Icon of ${pluginName}`}
                  className="mx-auto w-full"
                  src={`https://ws-cdn.de/plugins/icons/${pluginId.replace(
                    "ws5_",
                    "ws_"
                  )}.png`}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Dient dem Upload des Plugin Icons im **PNG-Fromat**. Bitte beachten:\n\n - hier dürfen nur Bilder in korrektem **PNG-Format** hochgeladen werden.\n - die hochgeladene Datei wird automatisch zu **${pluginId.replace(
                    "ws5_",
                    "ws_"
                  )}.png** umbenannt.`}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Icon Upload (svg):" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={updating}
                  type={"file"}
                  accept="image/svg+xml"
                  onChange={onPluginIconSvgChanged}
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{
                    maxWidth: "50px",
                    padding: 10,
                    borderRadius: 50,
                    backgroundColor: "#eee",
                  }}
                  alt={`Plugin Icon of ${pluginName}`}
                  className="mx-auto w-full"
                  src={`https://ws-cdn.de/plugins/icons/${pluginId.replace(
                    "ws5_",
                    "ws_"
                  )}.svg`}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Dient dem Upload des Plugin Icons im **SVG-Fromat**. Bitte beachten:\n\n - hier dürfen nur Bilder in korrektem **SVG-Format** hochgeladen werden.\n - die hochgeladene Datei wird automatisch zu **${pluginId.replace(
                    "ws5_",
                    "ws_"
                  )}.svg** umbenannt.`}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Bilder Upload (jpeg/png):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  disabled={updating}
                  type={"file"}
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={onPluginImagesChanged}
                />
                {/* <input
                  accept="image/jpeg"
                  type="file"
                  multiple
                  onChange={onPluginImagesChanged}
                /> */}
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Dient dem Upload von Bilder zur Darstellung des Plugins\n\n - z.B. Screenshots des Plugin-Backends o.Ä.\n - Es können mehrere Bilder zum Upload ausgewählt werden.\n - Beim Upload von neuen Bildern werden immer alle aktuell existierenden Bilder gelöscht.\n - Die Reihenfolge der Bilder kann über die letzen beiden Zeichen des Bildnamens festgelegt werden. `filenamexy2_**04**.png` wird beispielsweise vor `filenameab1_**08**.png` einsortiert"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ImageList cols={3} className="mt-4">
                  {pluginImagesUploadedList &&
                    pluginImagesUploadedList.map((image, index) => (
                      <ImageListItem key={`${image}_${index}`}>
                        <img
                          src={image.url}
                          srcSet={image.url}
                          alt={image.url}
                          loading="eager"
                        />
                        <ImageListItemBar
                          actionIcon={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              {index > 0 && (
                                <Button
                                  disabled={updating}
                                  onClick={() => {
                                    moveImage(index, true);
                                  }}
                                >{`<`}</Button>
                              )}
                              <input
                                disabled={updating}
                                ref={addRef}
                                accept="image/jpeg, image/png"
                                style={{ display: "none" }}
                                type="file"
                                onChange={(e) => {
                                  onChoosenPluginImageChanged(e, index);
                                }}
                              />
                              <Button
                                disabled={updating}
                                onClick={() => handleImageInputClick(index)}
                              >{`Tauschen`}</Button>
                              {index < pluginImagesUploadedList.length - 1 &&
                                pluginImagesUploadedList.length > 1 && (
                                  <Button
                                    disabled={updating}
                                    onClick={() => {
                                      moveImage(index);
                                    }}
                                  >{`>`}</Button>
                                )}
                            </div>
                          }
                        />
                      </ImageListItem>
                    ))}
                </ImageList>
              </Grid>
            </Grid>

            {error !== null && (
              <div className="flex flex-row justify-center w-full my-8">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "lighter",
                    color: "#f5922f",
                    marginTop: 2,
                  }}
                >
                  {error?.message}
                </Typography>
              </div>
            )}
            <div className="flex w-full justify-around">
              <Button variant="contained" disabled={loading} onClick={save}>
                Speichern
              </Button>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default DescriptionForm;
