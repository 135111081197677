import { Grid, IconButton, Typography } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import React from "react";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const FirstpromoterInfo = ({ company }: { company: Record<string, any> }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`RefID:`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {company?.firstpromoter?.ref_id ?? `n/a`}
          </Typography>
          {company?.firstpromoter?.ref_id && (
            <IconButton
              className={"c2c"}
              data-clipboard-text={company?.firstpromoter?.ref_id}
            >
              <FileCopy fontSize={"small"} />
            </IconButton>
          )}
        </div>
      </Grid>
      {/* <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`UID:`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {company?.firstpromoter?.uid ?? `n/a`}
          </Typography>
          {company?.firstpromoter?.uid && (
            <IconButton
              className={"c2c"}
              data-clipboard-text={company?.firstpromoter?.uid}
            >
              <FileCopy fontSize={"small"} />
            </IconButton>
          )}
        </div>
      </Grid> */}
    </Grid>
  );
};

export default FirstpromoterInfo;
