import React, { useCallback } from "react";
import useAuth from "../../hooks/useAuth";
import { Avatar, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LockOutlined as LockOutlinedIcon } from "@material-ui/icons";
import { useFirebase } from "react-redux-firebase";
import LoginForm from "./form";
import useLoginReducer, { Types } from "../../hooks/reducer/useLoginReducer";
import AuthError from "../../utils/AuthError";
import { Navigate } from "react-router-dom";
import getFirebase from "../../firebase";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Login = () => {
  const auth = useAuth();
  const firebase = useFirebase();
  const classes = useStyles();
  const [{ loading, alert }, dispatch] = useLoginReducer();

  const handleLogin = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      dispatch({ type: Types.START_LOGIN });

      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          getFirebase().analytics().logEvent("login", {
            method: "EmailAndPassword",
          });
          dispatch({ type: Types.FINISH_LOGIN });
        })
        .catch(({ code }: { code: string }) => {
          const error = new AuthError(code);
          dispatch({ type: Types.ERROR_LOGIN, payload: { error } });
        });
    },
    [firebase, dispatch]
  );

  if (auth.isAuthed) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <Container component={"main"} maxWidth={"xs"}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <LoginForm onSubmit={handleLogin} loading={loading} alert={alert} />
      </div>
    </Container>
  );
};

export default Login;
