import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import dashbar from "../../../../firebase/dashbar";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ReactTimeago from "react-timeago";
import { TypeComponent } from "../../audits";

const AuditCard = () => {
  const [audits, setAudits] = useState<
    | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    | undefined
  >();

  useEffect(() => {
    let query = dashbar()
      .db.collection("audit")
      .orderBy("createdAt", "desc")
      .limit(2);
    return query.onSnapshot(setAudits);
  }, [setAudits]);

  return (
    <Card
      style={{
        width: 400,
        borderRadius: 15,
        background: `linear-gradient(to right bottom, #242526, #444445)`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "lighter", marginBottom: 0 }}
        >
          {`Meldungen`}
        </Typography>
        <List dense={true}>
          {audits &&
            audits.docs.map((auditDoc) => {
              const audit = auditDoc.data() as Record<string, any>;
              return (
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    {<TypeComponent type={audit?.type} />}
                  </ListItemAvatar>
                  <div>
                    <ListItemText
                      style={{ width: "100%" }}
                      primary={
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {audit?.event ?? "-"}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: "lighter",
                          }}
                        >
                          {audit?.createdAt && (
                            <ReactTimeago date={audit?.createdAt.toDate()} />
                          )}
                        </Typography>
                      }
                    />
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: "normal",
                          }}
                        >
                          {audit?.company?.name &&
                          audit?.company?.name !== "n/a"
                            ? audit?.company?.name
                            : audit?.company?.id}
                        </Typography>
                      }
                    />
                  </div>
                </ListItem>
              );
            })}
        </List>
      </CardContent>
    </Card>
  );
};

export default AuditCard;
