import {useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {SelectorStateType} from "../types";

const useDocument = <T>(collection: string, id?: string) => {
  useFirestoreConnect({
    collection: collection,
    doc: id
  });
  return useSelector((state: SelectorStateType<T>) => {
    if (!state.firestore.data[collection]) {
      return undefined
    }
    return id ? (state.firestore.data[collection][id] || null) : null
  })
}

export default useDocument