import {InfoXML, Version4} from "@dash.bar/types";
import * as XML from "xml-js";

export const platforms: { [key: string]: string } = {
    jtlshop4: "JTL-Shop 4",
    jtlshop5: "JTL-Shop 5"
};

export type PluginInfo = {
    pluginID: string
    versions: PluginVersion[]
    name: string
    description: string
    minVersion: string
    platform: 'jtlshop4' | 'jtlshop5'
    version: string
    exsID?: string
}

export type PluginVersion = {
    createDate: string
    sql?: string
    nr: string
}

export const getPluginInfo = (infoXML: InfoXML, platform: string = 'jtlshop4'): PluginInfo | null => {

    if (infoXML.jtlshop3plugin) {
        const pInfo = {
            pluginID: infoXML.jtlshop3plugin.PluginID._text,
            versions: [] as PluginVersion[],
            name: infoXML.jtlshop3plugin.Name._text,
            description: infoXML.jtlshop3plugin.Description._text,
            minVersion: infoXML.jtlshop3plugin.Shop4Version?._text,
            platform: 'jtlshop4',
            version: ""
        } as PluginInfo

        if (Array.isArray(infoXML?.jtlshop3plugin.Install.Version)) {
            pInfo.versions = (infoXML?.jtlshop3plugin.Install.Version as Version4[]).map(value => {
                const version = pluginVersion4fromXML(value)
                if (pInfo.version === "" || parseInt(pInfo.version) < parseInt(version.nr)) {
                    pInfo.version = version.nr
                }
                return version
            })
        } else {
            pInfo.versions = [pluginVersion4fromXML(infoXML?.jtlshop3plugin.Install.Version)]
        }
        return pInfo
    }

    if (infoXML.jtlshopplugin) {
        return {
            minVersion: infoXML.jtlshopplugin.ShopVersion._text,
            description: infoXML.jtlshopplugin.Description._text,
            name: infoXML.jtlshopplugin.Name._text,
            pluginID: infoXML.jtlshopplugin.PluginID._text,
            versions: [],
            platform: 'jtlshop5',
            version: infoXML.jtlshopplugin.Version._text,
            exsID: infoXML.jtlshopplugin.ExsID?._text,
        } as PluginInfo
    }

    return null;
}

const pluginVersion4fromXML = (versionNode: XML.ElementCompact): PluginVersion => {
    return {
        createDate: versionNode.CreateDate?._text,
        sql: versionNode.SQL?._text ?? undefined,
        nr: versionNode._attributes?.nr
    } as PluginVersion;
}
