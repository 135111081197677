import { Typography } from "@material-ui/core";
import React from "react";
import { inputStyle } from "./styles";

type InputProps = {
  error?: string | null;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type TextareaProps = {
  error?: string | null;
} & React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const Input = ({ error, placeholder, ...props }: InputProps) => {
  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        flexDirection: "column",
        alignContent: "flex-start",
        alignItems: "flex-start",
        justifyContent: "center",
        marginBottom: 5,
      }}
    >
      <input
        {...props}
        placeholder={placeholder}
        key={`key_${placeholder?.toLowerCase()}`}
        style={inputStyle}
      />
      {error && error !== null && (
        <Typography
          style={{
            fontSize: 12,
            fontWeight: "lighter",
            color: "#f5922f",
            marginTop: 2,
          }}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export const Textarea = ({ error, placeholder, ...props }: TextareaProps) => {
  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        flexDirection: "column",
        alignContent: "flex-start",
        alignItems: "flex-start",
        justifyContent: "center",
        marginBottom: 5,
      }}
    >
      <textarea
        {...props}
        placeholder={placeholder}
        key={`key_${placeholder?.toLowerCase()}`}
        style={{ ...inputStyle, ...{ color: "#000000" } }}
      />
      {error && error !== null && (
        <Typography
          style={{
            fontSize: 12,
            fontWeight: "lighter",
            color: "#f5922f",
            marginTop: 2,
          }}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default Input;
