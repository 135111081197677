import { Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardCard from "../general/DashboardCard";
import { UserRole } from "../../../../firestore/models";

const OldDashboardV2 = () => {
  const navigate = useNavigate();
  return (
    <DashboardCard
      title="Altes Dashboard"
      role={UserRole.Operational}
      theme="webstollen"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 30,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            navigate(`/v2`);
          }}
          title="Zum Dashboard"
        >
          Zum Dashboard
        </Button>
      </div>
    </DashboardCard>
  );
};

export default OldDashboardV2;
