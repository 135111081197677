import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../utils/constants";

type LoadingProps = {
  label?: string;
  error?: any;
  retry?: () => void;
  loading: boolean;
  children?: React.ReactNode;
  cancelable?: boolean;
  onCancel?: () => void;
};

/**
 * @deprecated
 * @param label
 * @param error
 * @param retry
 * @param loading
 * @param children
 * @param cancelable
 * @param onCancel
 * @constructor
 */
const Loading = ({
  label,
  error,
  retry,
  loading,
  children,
  cancelable,
  onCancel,
}: LoadingProps) => {
  const theme = useTheme();

  if (error) {
    return (
      <LoadingWrapper>
        <>
          <div style={{ margin: "5px" }}>
            <FontAwesomeIcon
              color={theme.palette.error.main}
              size={"3x"}
              icon={[ICON_STYLE, "times-hexagon"]}
            />
          </div>
          <div
            style={{
              color: theme.palette.error.main,
              fontFamily: "monospace",
              fontSize: "1.2em",
            }}
          >
            {error.toString()}
          </div>
          {children && <div style={{ margin: "15px" }}>{children}</div>}
          {retry && (
            <Button onClick={retry} color={"secondary"}>
              Retry
            </Button>
          )}
          {cancelable && onCancel && (
            <Button onClick={onCancel} color={"secondary"}>
              Retry
            </Button>
          )}
        </>
      </LoadingWrapper>
    );
  }

  return loading ? (
    <LoadingWrapper>
      <>
        <CircularProgress />
        {label && (
          <div
            style={{ color: theme.palette.text.hint, fontFamily: "monospace" }}
          >
            {label}
          </div>
        )}
      </>
    </LoadingWrapper>
  ) : null;
};

const LoadingWrapper = ({ children }: { children: JSX.Element }) => (
  <div
    style={{
      textAlign: "center",
      margin: "15px auto",
      padding: "10px",
      maxWidth: "500px",
    }}
  >
    {children}
  </div>
);

export default Loading;
