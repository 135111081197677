import React, { useEffect, useState } from "react";
import PageContainer from "../../../../../components/PageContainer";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { functions } from "../../../../../firebase";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../utils/constants";

const SyncTab = () => {
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [previewSync, setPreviewSync] = useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [preview, setPreview] = useState<Record<string, any> | null>(null);

  const firestore = useFirestore();

  const fetchTasks = functions().httpsCallable(
    "billing-monthly_billing-runMonthlyBilling",
    { timeout: 540000 } //9 min
  );

  useEffect(() => {
    if (previewSync === true) {
      console.log(`Preview Sync is true`);
      firestore
        .collection(`billing/monthly_billing/prepared_data`)
        .doc(`temp_${moment().utc().format("YYYYMMDD")}`)
        .get()
        .then((res) => {
          console.log(`Result exists: ${res.exists}`);
          if (res.exists) {
            res.ref
              .collection(`tasks`)
              .get()
              .then((tasks) => {
                console.log(`Found ${tasks.size} tasks`);
                if (tasks.size > 0) {
                  const data: Record<string, any> = [];
                  tasks.forEach((task) => {
                    console.log(`Found task ${task.id}`);
                    data.push(task.data());
                  });
                  setPreview({ ...res.data(), data: data, id: res.id });
                } else {
                  setPreview(null);
                }
              })
              .catch(() => {
                setPreview(null);
              });
          } else {
            setPreview(null);
          }
        })
        .catch(() => {
          setPreview(null);
        });
    }
    setPreviewSync(false);
  }, [previewSync, firestore]);

  const fetchTask = async () => {
    setFetchingData(true);
    setPreview(null);
    setShowPreview(false);
    fetchTasks({ period: "previous" })
      .then(() => {})
      .finally(() => {
        setPreviewSync(true);
        setFetchingData(false);
      });
  };

  return (
    <>
      <PageContainer title="Monatslauf">
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={4}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Datenabgleich`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 14,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              {`[ACHTUNG: Aufgrund der Automatisierung des Prozesses ist eine manuelle Ausführung derzeit nicht möglich!] Ruft die Aufgaben mit Zeiterfassung aus Bitrix24 ab und bereitet diese mit zusätzlichen Informationen auf. Nach Abschluss des Vorgangs werden die Daten für maximal 1 Stunde gespeichert. Anschließend müssen die Daten erneut abgerufen werden. Der Vorgang kann mehrere Minuten in Anspruch nehmen.`}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            {fetchingData ? (
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Daten werden abgerufen...`}
              </Typography>
            ) : (
              <Button
                onClick={fetchTask}
                disabled={fetchingData || true}
                variant="contained"
              >{`Abgleich starten`}</Button>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Datenvorschau`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 14,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              {`Nachdem ein erfolgreicher Datenabgleich stattgefunden hat, können die Daten als Vorschau geladen werden um die Inhalte zu überprüfen. ${
                preview === null
                  ? `Derzeit existiert keine gültige Vorschau. Starten Sie zuerst den Datenabgleich!`
                  : `Aktuell kann die Datei ${preview.id} als Vorschau geladen werden.`
              }`}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            {preview === null ? (
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Keine Vorschau verfügbar`}
              </Typography>
            ) : (
              // <Button
              //   onClick={() => {
              //     setPreviewSync(true);
              //   }}
              //   disabled={fetchingData}
              //   variant="outlined"
              // >{`Aktualisieren`}</Button>
              <Button
                onClick={() => {
                  setShowPreview(true);
                }}
                disabled={fetchingData}
                variant="contained"
              >{`Vorschau laden`}</Button>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Sevdesk-Export`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 14,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              {`[ACHTUNG: Aufgrund der Automatisierung des Prozesses ist eine manuelle Ausführung derzeit nicht möglich!] Startet den Import aller Aufgaben aus der aktuellen Vorschaudatei in Sevdesk. Die Aufgaben werden dort als Aufträge angelegt. Aus den Aufträgen wird einen Tag später automatisch eine Rechnung erstellt.`}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            {preview === null ? (
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Kein Export möglich`}
              </Typography>
            ) : (
              // <Button
              //   onClick={() => {
              //     setPreviewSync(true);
              //   }}
              //   disabled={fetchingData}
              //   variant="outlined"
              // >{`Aktualisieren`}</Button>
              <Button
                onClick={() => {
                  setShowPreview(true);
                }}
                disabled={fetchingData || true}
                variant="contained"
              >{`Export starten`}</Button>
            )}
          </Grid>
        </Grid>
      </PageContainer>
      {preview && preview !== null && showPreview === true && (
        <PageContainer title="Vorschau">
          <TableContainer
            component={Paper}
            style={{ backgroundColor: "#333", marginTop: 20 }}
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography
                      style={{
                        color: "#EEE",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                    >
                      {`Kunde`}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      style={{
                        color: "#EEE",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                    >
                      {`Kundennummer`}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      style={{
                        color: "#EEE",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                    >
                      {`Anzahl Aufgaben`}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      style={{
                        color: "#EEE",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                    >
                      {`Aufgebrachte Zeit`}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      style={{
                        color: "#EEE",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                    >
                      {`Geschätzte Kosten`}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preview.data.map((task: Record<string, any>) => (
                  <Row key={task.customerID} row={task} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PageContainer>
      )}
    </>
  );
};

const Row = (props: { row: Record<string, any> }) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <FontAwesomeIcon icon={[ICON_STYLE, "arrow-up"]} />
            ) : (
              <FontAwesomeIcon icon={[ICON_STYLE, "arrow-down"]} />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "700",
            }}
          >
            {row.customerName}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row.customerID}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row.positions.length}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {`~${(row.positions as Array<Record<string, any>>)
              .reduce<number>((prev: number, curr: Record<string, any>) => {
                return (prev += curr.amount);
              }, 0)
              .toFixed(2)} Std`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {`~${(
              (row.positions as Array<Record<string, any>>).reduce<number>(
                (prev: number, curr: Record<string, any>) => {
                  return (prev += curr.amount);
                },
                0
              ) * 140
            ).toFixed(2)}€`}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#222" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginTop: 7, marginBottom: 7 }}>
              {/* <Typography
                style={{
                  color: "#EEE",
                  fontSize: 16,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
              >
                {`Aufgaben`}
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Aufgabe`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Task-ID`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Aufgebrachte Zeit`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.positions.map((position: Record<string, any>) => (
                    <TableRow key={position.id}>
                      <TableCell component="th" scope="row">
                        <Typography
                          style={{
                            color: "#EEE",
                            fontSize: 12,
                            fontFamily: "Roboto",
                            fontWeight: "300",
                          }}
                        >
                          {position.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            color: "#EEE",
                            fontSize: 12,
                            fontFamily: "Roboto",
                            fontWeight: "300",
                          }}
                        >
                          {position.id}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          style={{
                            color: "#EEE",
                            fontSize: 12,
                            fontFamily: "Roboto",
                            fontWeight: "300",
                          }}
                        >
                          {`~${position.amount.toFixed(2)} Std`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default SyncTab;
