import React from "react";
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormWatch,
} from "react-hook-form";
import {
  Box,
  Checkbox,
  Grid,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { InfoXML } from "@dash.bar/types";
import { Project } from "../../../../types/Plugins";
import Loading from "../../../../components/Loading";
import { getPluginInfo } from "../../../../utils/InfoXML";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import InfoMarkDownButton from "../../../../components/InfoMarkdownButton";
// import Input from "../../../marketingNew/Dashboard/components/InputComponent";

type Props = {
  infoxml?: InfoXML;
  project?: Project;
  onSave: (values: Record<string, any>) => void;
  register: any;
  errors: DeepMap<Record<string, any>, FieldError>;
  handleSubmit: Function;
  platform: string;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<any>;
};

const Form = ({
  infoxml,
  project,
  onSave,
  register,
  handleSubmit,
}: // errors,
// watch,
// setValue,
Props) => {
  const pluginInfo = infoxml ? getPluginInfo(infoxml) : null;

  // const wPublic = watch("isPublic");
  // const wLicence = watch("useLicence");
  const inputStyle: React.CSSProperties = {
    padding: 5,
    width: "100%",
    borderRadius: 5,
    fontWeight: "normal",
    fontSize: 18,
  };

  // useEffect(() => {
  //   setValue("isPublic", wPublic);
  // }, [wPublic, setValue]);

  // useEffect(() => {
  //   setValue("useLicence", wPublic);
  // }, [wPublic, setValue]);

  return pluginInfo ? (
    <form onSubmit={handleSubmit(onSave)}>
      <Box style={{ overflow: "hidden" }}>
        <Grid
          container
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
          spacing={1}
          className="w-full"
        >
          <Grid item xs={3}>
            <Label text="Plugin-ID:" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"Plugin-ID"}
              readOnly
              defaultValue={pluginInfo.pluginID}
              id={"pluginId"}
              style={inputStyle}
              inputProps={register("pluginId", { required: true })}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <InfoMarkDownButton markdown={"**ID** des Plugins"} />
          </Grid>
          <Grid item xs={3}>
            <Label text="EXS-ID:" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"EXS-ID"}
              defaultValue={pluginInfo.exsID}
              id={"exsID"}
              style={inputStyle}
              inputProps={register("exsID", { required: true })}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <InfoMarkDownButton
              markdown={"**Extension-Store ID** des Plugins"}
            />
          </Grid>
          <Grid item xs={3}>
            <Label text="Plugin Name:" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"Plugin Name"}
              defaultValue={pluginInfo.name}
              id={"name"}
              style={inputStyle}
              inputProps={register("name", { required: true })}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <InfoMarkDownButton markdown={"**Name** des Plugins"} />
          </Grid>
          <Grid item xs={3}>
            <Label text="Plugin Beschreibung:" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"text"}
              placeholder={"Plugin Beschreibung"}
              defaultValue={pluginInfo.description}
              id={"description"}
              style={inputStyle}
              inputProps={register("description", { required: true })}
            />
          </Grid>
          <Grid item xs={1}>
            <InfoMarkDownButton
              markdown={"**Extension-Store ID** des Plugins"}
            />
          </Grid>
        </Grid>
        <Input
          type={"hidden"}
          defaultValue={project?.id}
          inputProps={register("gitlabId", { required: true })}
        />
        <Input
          type={"hidden"}
          defaultValue={project?.web_url}
          id="gitlabUrl"
          required
        />
        <Input
          type={"hidden"}
          id="minVersion"
          defaultValue={`${pluginInfo.minVersion}`.replace(
            /^(\d)(\d{2})$/,
            (all: string, a: string, b: string) => `${a}.${b}`
          )}
        />

        {/* {pluginInfo.exsID && (
          <Input
            type="text"
            defaultValue={pluginInfo.exsID}
            id="exsID"
            required
            // inputProps={register("exsID", { required: true })}
          />
        )} */}

        {/* <TextField
          label={"PluginID"}
          fullWidth
          error={!!errors.pluginId}
          defaultValue={pluginInfo.pluginID}
          inputProps={register("pluginId", {
            required: true,
            pattern: /[a-z0-9_]+/,
          })}
        /> */}

        {/* <TextField
          label={"Name"}
          fullWidth
          error={!!errors.name}
          defaultValue={pluginInfo.name}
          inputProps={register("name", { required: true })}
        /> */}

        {/* <TextField
          label={"Description"}
          fullWidth
          error={!!errors.description}
          defaultValue={pluginInfo.description}
          inputProps={register("description")}
        /> */}
      </Box>
      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6} sm={3}>
              Plugin published:
            </Grid>
            <Grid item xs={6} sm={3}>
              <Switch
                inputProps={register("isPublic")}
                checked={wPublic}
                defaultChecked={false}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              Licence:
            </Grid>
            <Grid item xs={6} sm={3}>
              <Switch
                defaultChecked={false}
                checked={wLicence}
                inputProps={register("useLicence")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      {pluginInfo.versions.length ? (
        <Box>
          <Typography variant={"h6"}>Released Versions:</Typography>
          <List dense>
            {pluginInfo.versions.map((version) => (
              <ListItem key={version.nr}>
                <ListItemIcon>
                  <Input
                    type="hidden"
                    id={`version[${version.nr}][create_date]`}
                    // inputProps={register(`version[${version.nr}][create_date]`)}
                    defaultValue={version.createDate}
                  />
                  <Input
                    type="hidden"
                    id={`version[${version.nr}][nr]`}
                    // inputProps={register(`version[${version.nr}][nr]`)}
                    defaultValue={version.nr}
                  />
                  <Checkbox
                    edge={"start"}
                    inputProps={register(`version[${version.nr}][released]`)}
                    disableRipple
                    defaultValue={version.createDate}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={version.nr}
                  secondary={version.createDate}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      ) : null}
      {/*<Box style={{textAlign: "right"}}>
            <Button type={"submit"} color={"primary"}>save</Button>
        </Box>*/}
    </form>
  ) : (
    <Loading loading={true} />
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default Form;
