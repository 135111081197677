import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import CheckIcon from "../CheckIcon";

const ReportConfigCardShop = ({
  title,
  enabled,
  minVersion,
}: {
  title: string;
  enabled: boolean;
  minVersion: string;
}) => {
  return (
    <Card
      style={{
        width: "90%",
        borderRadius: 15,
        backgroundColor: `#323232`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "lighter", marginBottom: 10 }}
        >
          {title}
        </Typography>
        <Grid
          container
          style={{ alignContent: "center", alignItems: "center" }}
        >
          <Grid item xs={8}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              minVersion
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{ fontSize: 18, fontWeight: "normal", marginBottom: 10 }}
            >
              {minVersion}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              {"Enabled"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{ fontSize: 18, fontWeight: "normal", marginBottom: 10 }}
            >
              <CheckIcon checked={enabled} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReportConfigCardShop;
