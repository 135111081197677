import React, { MouseEventHandler, useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import GitlabStatus from "../Gitlab/GitlabStatus";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROLE, ROLES } from "../../hooks/useAuth";
import { ICON_STYLE } from "../../utils/constants";

type Props = {
  roles: string[];
};

type NavItem = {
  key: string;
  title: string;
  componentAfter?: React.ReactElement;
  icon?: React.ReactElement;
  to?: string;
  onClick?: MouseEventHandler;
  children?: NavItem[];
  role?: string | string[];
};

const Navigation = (props: Props) => {
  const navigate = useNavigate();

  const [subNav, setSubNav] = useState<Record<string, boolean>>(
    {} as Record<string, boolean>
  );

  const data = [
    {
      key: "dashboard",
      title: "Dashboard",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "tachometer"]} />,
      to: "/",
    },
    // {
    //   key: "plugins",
    //   title: "Plugins",
    //   icon: <FontAwesomeIcon icon={[ICON_STYLE, "plug"]} />,
    //   to: "/plugins",
    //   role: ROLES.PLUGINS,
    // },
    {
      key: "licences",
      title: "Licenses",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "file-certificate"]} />,
      to: "/licenses",
      role: ROLES.LICENCES,
      children: [
        {
          key: "plugin_extensions",
          title: "Plugin Erweiterungen",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "puzzle-piece-simple"]} />,
          to: "/licenses/plugin/extensions",
        },
        {
          key: "plugin_customer",
          title: "Kunden",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "user"]} />,
          to: "/licenses/plugin/customer",
        },
      ],
    },
    {
      key: "shorturl",
      title: "Short URLs",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "link"]} />,
      to: "/shorturl",
      role: ROLES.SHORTURL,
    },
    {
      key: "extensionstore",
      title: "Webhooks",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "store"]} />,
      to: "/extensionstore",
      role: ROLES.WEBHOOKS,
    },
    // {
    //   key: "lunchorder",
    //   title: "Essen",
    //   icon: <FontAwesomeIcon icon={[ICON_STYLE, "utensils"]} />,
    //   to: "/lunch",
    //   role: ROLES.LUNCH,
    // },
    {
      key: "developer",
      title: "Developer",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "plug"]} />,
      to: "/developer/plugins",
      componentAfter: <Divider />,
      role: ROLES.DEVELOPER,
      children: [
        {
          key: "developer_plugins",
          title: "Plugins",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "plug"]} />,
          to: "/developer/plugins",
          role: ROLES.DEVELOPER,
        },
        {
          key: "developer_templates",
          title: "Templates",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "palette"]} />,
          to: "/developer/templates",
          role: ROLES.DEVELOPER,
        },
        {
          key: "developer_hostings",
          title: "Hostings",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "globe"]} />,
          to: "/developer/hosting",
          role: ROLES.DEVELOPER,
        },
      ],
    },
    {
      key: "marketing",
      title: "Marketing",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "mail-bulk"]} />,
      to: "/marketing",
      componentAfter: <Divider />,
      role: ROLES.ADMIN,
    },
    {
      key: "config",
      title: "Config",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "cog"]} />,
      role: ADMIN_ROLE,
      children: [
        /*{
        key: "subscription_features",
        title: "Addons",
        to: "/subscription/features",
        icon: <FontAwesomeIcon icon={faHandHoldingBox}/>,
      }, {
        key: "subscription_bundles",
        title: "Bundles",
        to: "/subscription/bundles",
        icon: <FontAwesomeIcon icon={faBoxes}/>,
      // }, {
      //     key: "subscription_flatrates",
      //     title: "Flatrates",
      //     to: "/subscription/flatrates",
      //     icon: <FontAwesomeIcon icon={[ICON_STYLE, "cocktail"]} />,
      //   },*/
        {
          key: "api",
          title: "API",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "cog"]} />,
          to: "/api",
          role: ROLES.ADMIN,
        },
        {
          key: "users",
          title: "Users",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "users"]} />,
          to: "/users",
          role: ROLES.USERS,
        },
      ],
      componentAfter: <Divider />,
    },
    {
      key: "tools",
      title: "Tools",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "tools"]} />,
      componentAfter: <Divider />,
      role: ADMIN_ROLE,
      children: [
        // {
        //   key: "bitrix2gitlab",
        //   title: "Bitrix2Gitlab Sync",
        //   icon: <FontAwesomeIcon icon={[ICON_STYLE, "sync"]} />,
        //   to: "/tools/bitrix2gitlab",
        // },
        {
          key: "bitrix2sevdesk",
          title: "Bitrix2Sevdesk Sync",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "sync"]} />,
          to: "/tools/bitrix2sevdesk",
        },
        // {
        //   key: "licence_migration",
        //   title: "Licence Migration",
        //   icon: <FontAwesomeIcon icon={[ICON_STYLE, "shopping-cart"]} />,
        //   to: "/tools/licence-migration",
        // },
        {
          key: "firstpromoter",
          title: "Firstpromoter",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "hand-holding-usd"]} />,
          to: "/tools/firstpromoter",
        },
      ],
    },
    {
      key: "dashbar",
      title: "dash.bar",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "cogs"]} />,
      role: ROLES.DASHBAR,
      to: "/dashbar",
      children: [
        {
          key: "dashbar_operations",
          title: "Operations",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "terminal"]} />,
          to: "/dashbar/operations",
          role: ROLES.ADMIN,
        },
        // {
        //   key: "dashbar_news",
        //   title: "News",
        //   icon: <FontAwesomeIcon icon={[ICON_STYLE, "newspaper"]} />,
        //   to: "/dashbar/news",
        // },
        {
          key: "dashbar_companies",
          title: "Companies",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "industry"]} />,
          to: "/dashbar/companies",
        },
        {
          key: "dashbar_users",
          title: "Users",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "users"]} />,
          to: "/dashbar/users",
        },
        /*{
          key: 'dashbar_transactions',
          title: 'Transactions',
          icon: <FontAwesomeIcon icon={faHandHoldingUsd}/>,
          to: "/dashbar/transactions"
        },*/
        {
          key: "dashbar_shops",
          title: "Shops",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "shopping-basket"]} />,
          to: "/dashbar/shops",
        },
        // {
        //   key: "dashbar_extensions",
        //   title: "Extensions",
        //   icon: <FontAwesomeIcon icon={[ICON_STYLE, "puzzle-piece"]} />,
        //   to: "/dashbar/extensions",
        //   role: ROLES.ADMIN,
        // },
        {
          key: "dashbar_popups",
          title: "Popups",
          role: ROLES.ADMIN,
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "comment-alt-lines"]} />,
          to: "/dashbar/popup",
        },
        {
          key: "dashbar_coupons",
          title: "Coupons",
          role: ROLES.ADMIN,
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "money-check"]} />,
          to: "/dashbar/coupon",
        },
        {
          key: "dashbar_campaigns",
          title: "Campaigns",
          role: ROLES.ADMIN,
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "chart-line"]} />,
          to: "/dashbar/campaigns",
        },
        {
          key: "dashbar_reports",
          title: "Reports",
          role: ROLES.ADMIN,
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "chart-line"]} />,
          to: "/dashbar/reports",
        },
        {
          key: "dashbar_audits",
          title: "Audits",
          role: ROLES.DASHBAR,
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "list"]} />,
          to: "/dashbar/audits",
        },
        // {
        //   key: 'dashbar_plans',
        //   title: 'Plans',
        //   icon: <FontAwesomeIcon icon={faReceipt}/>,
        //   to: "/dashbar/plans"
        // },
        // {
        //   key: 'dashbar_addons',
        //   title: 'Addons',
        //   icon: <FontAwesomeIcon icon={faPuzzlePiece}/>,
        //   to: "/dashbar/addons"
        // },
      ],
      componentAfter: <Divider />,
    },
    {
      key: "abocloud",
      title: "abocloud",
      icon: <FontAwesomeIcon icon={[ICON_STYLE, "cloud"]} />,
      role: ROLES.ABOCLOUD,
      to: "/abocloud",
      children: [
        {
          key: "abocloud_merchants",
          title: "Merchants",
          icon: <FontAwesomeIcon icon={[ICON_STYLE, "industry"]} />,
          to: "/abocloud/merchant",
          role: ROLES.ABOCLOUD,
        },
      ],
      componentAfter: null /*<Divider />*/,
    },
    // {
    //   key: "logout",
    //   title: "Logout",
    //   icon: <FontAwesomeIcon icon={[ICON_STYLE, "sign-out"]} />,
    //   onClick: () => {
    //     firebase.auth().signOut();
    //   },
    // },
  ] as NavItem[];

  const renderNavList = (list: NavItem[]): any | null => {
    return list.map((item) => {
      if (item.role) {
        if (
          typeof item.role === "string" &&
          !props.roles.includes(item.role) &&
          !props.roles.includes(ADMIN_ROLE)
        ) {
          return null;
        }
        if (typeof item.role === "object" && item.role.length) {
          const check = item.role.filter((role) => props.roles.includes(role));
          if (!check.length && !props.roles.includes(ADMIN_ROLE)) {
            return null;
          }
        }
      }

      let sub = null;
      let action = null;
      if (item.children && item.children.length > 0) {
        action = (
          <ListItemSecondaryAction
            onClick={() =>
              setSubNav((prevState) => {
                const newState = { ...prevState };
                newState[item.key] = !newState[item.key];
                return newState;
              })
            }
          >
            {subNav[item.key] ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        );
        sub = (
          <Collapse in={subNav[item.key]} timeout={"auto"} unmountOnExit>
            <List component={"div"} disablePadding>
              {renderNavList(item.children)}
            </List>
          </Collapse>
        );
      }

      return (
        <React.Fragment key={item.key}>
          <ListItem
            button
            onClick={
              item.onClick ??
              (item.to ? () => navigate(item.to ?? "") : undefined)
            }
          >
            {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
            <ListItemText
              primary={
                <Typography
                  style={{
                    color: "#EEE",
                    fontSize: 16,
                    fontFamily: "Roboto",
                    fontWeight: 300,
                  }}
                >
                  {item.title}
                </Typography>
              }
            />
            {action}
          </ListItem>
          {sub}
          {item.componentAfter ?? null}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <List>{renderNavList(data)}</List>
      <hr />
      <List>
        <ListItem>
          <ListItemText primary={<GitlabStatus />} />
        </ListItem>
      </List>
    </>
  );
};

export default Navigation;
