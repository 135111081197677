import React from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

type LoginCredentials = {
  email: string;
  password: string;
};

interface Props {
  onSubmit: (params: { email: string; password: string }) => void;
  loading: boolean;
  alert?: { content: string } | null;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginForm = ({ onSubmit, loading, alert }: Props) => {
  const classes = useStyles();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<LoginCredentials>();

  return (
    <div>
      {alert && <Alert severity={"error"}>{alert.content}</Alert>}
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          disabled={loading}
          variant={"outlined"}
          margin="normal"
          fullWidth
          label="E-Mail Address"
          required
          error={!!errors.email}
          inputProps={register("email", {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
              message: "Ungültige E-Mail.",
            },
          })}
          autoComplete="email"
          autoFocus
        />

        {errors.email && errors.email.message}

        <TextField
          disabled={loading}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          error={!!errors.password}
          autoComplete="current-password"
          inputProps={register("password", {
            required: true,
          })}
        />

        {errors.password && errors.password.message}

        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign In
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
