import Modal from "../../../components/Modal";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import dashbar from "../../../firebase/dashbar";
import Table from "../../../components/Table";
import IDLabel from "../../../components/IDLabel";
import { Company } from "@dash.bar/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../utils/constants";

type CompanySelectProps = {
  onCancel: () => void;
  onSave: (id: string) => () => void;
  title?: string;
};

const CompanySelect = ({ title, onCancel, onSave }: CompanySelectProps) => {
  const [companies, setCompanies] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();
  const [limit, setLimit] = useState(15);

  useEffect(
    () =>
      dashbar().db.collection("company").limit(limit).onSnapshot(setCompanies),
    [limit, setCompanies]
  );

  return (
    <Modal>
      {title && <h4>{title}</h4>}
      <div>
        <Table
          onMore={() => setLimit((limit) => limit + 15)}
          header={{
            id: { content: "ID" },
            name: { content: "Name" },
            location: { content: "Location" },
            action: { content: "" },
          }}
        >
          {!companies?.empty &&
            companies?.docs.map((companyDoc) => {
              const company = companyDoc.data() as Company;
              return (
                <tr>
                  <td>
                    <IDLabel label={companyDoc.id} />
                  </td>
                  <td>{company.name ?? "-"}</td>
                  <td>
                    {company.billingAddress?.zipCode ?? "-"}{" "}
                    {company.billingAddress?.city ?? "-"}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={[ICON_STYLE, "check-square"]}
                      className="hover:text-blue-500 cursor-pointer"
                      onClick={onSave(companyDoc.id)}
                    />
                  </td>
                </tr>
              );
            })}
        </Table>
      </div>
      <div className="text-center">
        <Button onClick={onCancel}>cancel</Button>
      </div>
    </Modal>
  );
};

export default CompanySelect;
