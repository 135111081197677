import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import "firebase/compat/performance";
import "firebase/compat/storage";
import "firebase/compat/app-check";
import firebaseConfig from "../firebaseConfig";

// export const COLL_LICENCE = "licence";
// export const COLL_TESTSERVER = "testserver";
export const COLL_CONFIG = "config";
export const COLL_PLUGIN = "plugin";
export const COLL_PLUGINS = "plugins";
export const COLL_PLUGINS_PLATFORMS = (pluginId: string) =>
  `${COLL_PLUGINS}/${pluginId}/platforms`;
export const COLL_PLUGINS_PLATFORMS_RELEASES = (
  pluginId: string,
  platformId: string
) => `${COLL_PLUGINS}/${pluginId}/platforms/${platformId}/releases`;
export const COLL_PLUGINS_INSTALLS = (pluginId: string) =>
  `${COLL_PLUGINS}/${pluginId}/installs`;

export const COLL_PLUGIN_RELEASE = (pluginID: string) =>
  `${COLL_PLUGIN}/${pluginID}/release`;
export const COLL_PLUGIN_SHOPDESCR = (pluginID: string) =>
  `${COLL_PLUGIN}/${pluginID}/shopdescr`;
export const COLL_USER = "user";
export const COLL_USER_SETTINGS = (userID: string) =>
  `${COLL_USER}/${userID}/settings`;
// export const COLL_CONFIG_SUBSCRIPTIONS_BUNDLES = "config/subscriptions/bundles";
// export const COLL_CONFIG_SUBSCRIPTIONS_FLATRATES =
//   "config/subscriptions/flatrates";
export const COLL_SHORTURL = "shorturl";
export const COLL_WEBHOOK = "webhook";
export const COLL_SHORTURL_LOG = (id: string) => `shorturl/${id}/sllog`;

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean;
  }
}

if (process.env.NODE_ENV !== "production") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

let app: firebase.app.App;
let _funcs: firebase.functions.Functions;
let _auth: firebase.auth.Auth;

const getFirebase = (): firebase.app.App => {
  if (!firebase.apps.length) {
    app = firebase.initializeApp(firebaseConfig);
    _funcs = app.functions("europe-west3");
    firebase.firestore(app).settings({
      ignoreUndefinedProperties: true,
    });
    firebase.storage(app);
    _auth = firebase.auth(app);
    if (process.env.NODE_ENV === "production") {
      const appCheck = firebase.appCheck(app);
      appCheck.activate(process.env.APP_CHECK_SITE_KEY ?? "");
      appCheck.setTokenAutoRefreshEnabled(true);
      firebase.analytics(app);
      firebase.performance(app);
    } else {
      _funcs.useEmulator("localhost", 5100);
      _auth.useEmulator("http://localhost:9099");
      firebase.firestore(app).settings({
        ignoreUndefinedProperties: true,
        host: "localhost:8080",
        ssl: false,
      });
    }
  }
  return app;
};

export const functions = (region = "europe-west3") =>
  getFirebase().functions(region);

export const auth = () => _auth ?? getFirebase().auth();

let _db: firebase.firestore.Firestore | null = null;

export const DB = (): firebase.firestore.Firestore => {
  if (_db === null) {
    _db = getFirebase().firestore();
  }
  return _db;
};

// if (__DEV__) {
// functions.useFunctionsEmulator('http://localhost:5001')
// }

export default getFirebase;
