import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Modal,
  Typography,
} from "@material-ui/core";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import PageContainer from "../../../../components/PageContainer";
import getDashBar from "../../../../firebase/dashbar";
import BundleConfigEdit from "./BundleConfigEdit";
import ShopConfigEdit from "./ShopConfigEdit";
import SponsorEdit from "./SponsorEdit";
import { useNavigate } from "react-router-dom";

const ReportEdit = () => {
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");
  const navigate = useNavigate();
  const [reportConfig, setReportConfig] = useState<Record<string, any>>({});

  const [reportSponsorImage, setReportSponsorImage] = useState<File>();
  const [allowedDaily, setAllowedDaily] = useState<Array<string>>([]);
  const [allowedWeekly, setAllowedWeekly] = useState<Array<string>>([]);
  const [allowedMonthly, setAllowedMonthly] = useState<Array<string>>([]);
  const [allowedQuarterly, setAllowedQuarterly] = useState<Array<string>>([]);
  const [forcedDaily, setForcedDaily] = useState<Array<string>>([]);
  const [forcedWeekly, setForcedWeekly] = useState<Array<string>>([]);
  const [forcedMonthly, setForcedMonthly] = useState<Array<string>>([]);
  const [forcedQuarterly, setForcedQuarterly] = useState<Array<string>>([]);

  const [sponsor, setSponsor] = useState<string | null>(null);

  const [allow, setAllow] = useState<Record<string, Array<string>>>({});
  const [force, setForce] = useState<Record<string, Array<string>>>({});

  const [jtlEnabled, setJtlEnabled] = useState<boolean>(false);
  const [shopwareEnabled, setShopwareEnabled] = useState<boolean>(false);
  const [minJtlVersion, setMinJtlVersion] = useState<string>("0.0.0");
  const [minShopwareVersion, setMinShopwareVersion] = useState<string>("0.0.0");

  const [config, setConfig] = useState<Record<string, any>>({});

  const [saving, setSaving] = useState<boolean>(false);
  const [saveLevel, setSaveLevel] = useState<number>(0);

  useEffect(() => {
    setAllow({
      daily: allowedDaily,
      weekly: allowedWeekly,
      monthly: allowedMonthly,
      quarterly: allowedQuarterly,
    });
  }, [allowedDaily, allowedWeekly, allowedMonthly, allowedQuarterly]);

  useEffect(() => {
    setForce({
      daily: forcedDaily,
      weekly: forcedWeekly,
      monthly: forcedMonthly,
      quarterly: forcedQuarterly,
    });
  }, [forcedDaily, forcedWeekly, forcedMonthly, forcedQuarterly]);

  useEffect(() => {
    setConfig({
      allow: allow,
      force: force,
      jtlEnabled: jtlEnabled,
      shopwareEnabled: shopwareEnabled,
      minJtlVersion: minJtlVersion,
      minShopwareVersion: minShopwareVersion,
      ...(sponsor && sponsor !== null && { sponsor: sponsor }),
    });
  }, [
    allow,
    force,
    jtlEnabled,
    shopwareEnabled,
    minJtlVersion,
    minShopwareVersion,
    sponsor,
  ]);

  // useEffect(() => {
  //   console.log(config);
  // }, [config]);

  // useEffect(() => {
  //   console.log(reportSponsorImage);
  // }, [reportSponsorImage]);

  useEffect(() => {
    getDashBar()
      .db.doc(`settings/reportConfig`)
      .get()
      .then((doc) => {
        setReportConfig(doc.data() || {});
      });
  }, []);

  const saveConfig = async () => {
    setSaving(true);
    await uploadConfigImage();
    setSaveLevel(70);
    await getDashBar().db.doc(`settings/reportConfig`).set(config);
    setSaveLevel(100);
    setSaving(false);
    navigate(`/dashbar/reports`);
  };

  const deleteConfigImage = async () => {
    const ref = storage.ref(`marketing/dashbar/reports/sponsor`);
    await ref.delete();
    setReportSponsorImage(undefined);
    return;
  };

  useEffect(() => {
    if (reportConfig.sponsor && reportConfig.sponsor !== null) {
      setSponsor(reportConfig.sponsor);
    }
  }, [reportConfig]);

  const uploadConfigImage = async () => {
    if (!reportSponsorImage || reportSponsorImage === null) {
      console.log(`No image to upload`);
      return;
    }
    const url = URL.createObjectURL(reportSponsorImage);
    setSaveLevel(10);
    const response = await fetch(url);
    const blob = await response.blob();
    setSaveLevel(20);
    const ref = storage.ref(`marketing/dashbar/reports/sponsor`);
    await ref.put(blob);
    setSaveLevel(40);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    setSaveLevel(60);
    // const imageUrl = await ref.getDownloadURL();
    // setReportSponsorImage(imageUrl);
    return;
  };

  return (
    <PageContainer title={"Edit Reports"}>
      <div style={{ marginTop: 20 }}>
        <div className="flex flex-row justify-end w-full">
          <Button
            style={{ marginRight: 15 }}
            variant="outlined"
            onClick={() => {
              navigate(`/dashbar/reports`);
            }}
          >
            Abbrechen
          </Button>
          <Button
            style={{ marginRight: 15 }}
            variant="contained"
            onClick={saveConfig}
          >
            Speichern
          </Button>
        </div>
        <Grid container spacing={2} className="h-60 mt-24">
          <Grid xs={2}>
            <Typography
              style={{ fontSize: 16, fontWeight: "lighter", marginLeft: 5 }}
            >
              Sponsor
            </Typography>
          </Grid>
          <Grid xs={4}>
            <SponsorEdit
              sponsor={sponsor}
              setSponsor={setSponsor}
              setSponsorFile={setReportSponsorImage}
              deleteImage={deleteConfigImage}
            />
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>
        <Grid container className="h-60">
          <Grid xs={2}>
            <Typography
              style={{ fontSize: 16, fontWeight: "lighter", marginLeft: 5 }}
            >
              Bundle-Einstellungen
            </Typography>
          </Grid>
          <Grid xs={2}>
            <BundleConfigEdit
              allow={reportConfig?.allow ? reportConfig.allow["daily"] : []}
              force={reportConfig?.force ? reportConfig.force["daily"] : []}
              title="Daily"
              setAllowed={setAllowedDaily}
              setForced={setForcedDaily}
            />
          </Grid>
          <Grid xs={2}>
            <BundleConfigEdit
              allow={reportConfig?.allow ? reportConfig.allow["weekly"] : []}
              force={reportConfig?.force ? reportConfig.force["weekly"] : []}
              title="Weekly"
              setAllowed={setAllowedWeekly}
              setForced={setForcedWeekly}
            />
          </Grid>
          <Grid xs={2}>
            <BundleConfigEdit
              allow={reportConfig?.allow ? reportConfig.allow["monthly"] : []}
              force={reportConfig?.force ? reportConfig.force["monthly"] : []}
              title="Monthly"
              setAllowed={setAllowedMonthly}
              setForced={setForcedMonthly}
            />
          </Grid>
          <Grid xs={2}>
            <BundleConfigEdit
              allow={reportConfig?.allow ? reportConfig.allow["quarterly"] : []}
              force={reportConfig?.force ? reportConfig.force["quarterly"] : []}
              title="Quarterly"
              setAllowed={setAllowedQuarterly}
              setForced={setForcedQuarterly}
            />
          </Grid>
        </Grid>

        <Grid container className="h-60">
          <Grid xs={2}>
            <Typography
              style={{ fontSize: 16, fontWeight: "lighter", marginLeft: 5 }}
            >
              Shop-Einstellungen
            </Typography>
          </Grid>
          <Grid xs={4}>
            <ShopConfigEdit
              title="JTL"
              enabledShopsystem={reportConfig?.jtlEnabled}
              minVersionShopsystem={
                reportConfig?.minJtlVersion
                  ? reportConfig.minJtlVersion
                  : "0.0.0"
              }
              setEnabledShopsystem={setJtlEnabled}
              setMinVersionShopsystem={setMinJtlVersion}
            />
          </Grid>
          <Grid xs={4}>
            <ShopConfigEdit
              title="Shopware"
              enabledShopsystem={reportConfig?.shopwareEnabled}
              minVersionShopsystem={
                reportConfig?.minShopwareVersion
                  ? reportConfig.minShopwareVersion
                  : "0.0.0"
              }
              setEnabledShopsystem={setShopwareEnabled}
              setMinVersionShopsystem={setMinShopwareVersion}
            />
          </Grid>
        </Grid>
      </div>
      <Modal open={saving} onClose={() => {}}>
        <Box
          style={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: "white",
            padding: 4,
          }}
        >
          <Typography
            style={{
              color: "black",
              fontSize: 20,
              marginBottom: 20,
              fontWeight: "lighter",
            }}
          >
            Saving
          </Typography>
          <LinearProgress
            variant="determinate"
            value={saveLevel}
            style={{ height: 10, borderRadius: 5 }}
          />
          <Typography
            style={{ color: "black", fontSize: 12, fontWeight: "lighter" }}
          >
            {saveLevel < 70 ? `Saving sponsor image` : `Saving config`}
          </Typography>
        </Box>
      </Modal>
    </PageContainer>
  );
};

export default ReportEdit;
