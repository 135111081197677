export const createMonthlyStatCSV = (data: Array<Record<string, any>>) => {
  let str = "";
  str += `PluginID, Monat, Checkouts, Upgrades, Tests, Conversion Rate, Verlängerungen\r\n`;

  for (const entry of data) {
    const line = `${entry.pluginId},${entry.timestamp},${entry.checkouts},${entry.upgrades},${entry.tests},${entry.conversion_rate},${entry.extensions}`;
    str += line + `\r\n`;
  }
  return str;
};
export const createYearlyStatCSV = (data: Array<Record<string, any>>) => {
  let str = "";
  str += `PluginID, Jahr, Checkouts\r\n`;

  for (const entry of data) {
    const line = `${entry.pluginId},${entry.timestamp},${entry.checkouts}`;
    str += line + `\r\n`;
  }
  return str;
};

export const downloadCSV = (fileString: string) => {
  const exportFile = new Blob([fileString], {
    type: "text/csv;charset=utf8",
  });
  const url = URL.createObjectURL(exportFile);
  window.open(url, "_blank", "noreferrer");
};

