import React from "react";
// import PageContainer from "../../../../../components/PageContainer";
// import BillingAddress from "../../../../../components/BillingAddress";
// import FeatureConfiguration from "../../../../../components/FeatureConfiguration";
import { Company } from "@dash.bar/types";
import CompanyView from "../../components/CompanyView";

type TabInfoProps = {
  company: Company;
  id: string;
};

const TabInfo = ({ company, id }: TabInfoProps) => {
  return <CompanyView cid={id} />;
  // return (
  //   <>
  //     <PageContainer title="Billing Address" heading={3} bgColor="bg-gray-800">
  //       <BillingAddress company={company} />
  //     </PageContainer>
  //     <PageContainer title="Additional Info" heading={3} bgColor="bg-gray-800">
  //       <dl className={"horizontal center"}>
  //         <dt>dash.bar ID:</dt>
  //         <dd>{id || "n/a"}</dd>
  //         <dt>Bundle:</dt>
  //         <dd>
  //           {company?.oldBundle ? (
  //             <span className={`rounded bg-red-400 px-2 py-0.5`}>old</span>
  //           ) : (
  //             <span className={`rounded bg-green-700 px-2 py-0.5`}>new</span>
  //           )}
  //         </dd>
  //         <dt>Abocloud Customer:</dt>
  //         <dd>{company?.aboCloud?.customerId || "n/a"}</dd>
  //         <dt>Abocloud Abo:</dt>
  //         <dd>{company?.aboCloud?.aboId || "n/a"}</dd>
  //       </dl>
  //     </PageContainer>
  //     <PageContainer title="Feature Configuration" bgColor="bg-gray-800">
  //       <FeatureConfiguration company={company} />
  //     </PageContainer>
  //   </>
  // );
};

export default TabInfo;
