import React, { useState } from "react";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import ServicesAdd from "./servicesAdd";
import SourcesAdd from "./sourcesAdd";
// import Import from "./import";
import Export from "./export";

type Props = {
  pluginId: string;
};
const Addon = ({ pluginId }: Props) => {

  const [buttonFocusOne, setButtonFocusOne] = useState<boolean>(true);
  const [buttonFocusTwo, setButtonFocusTwo] = useState<boolean>(false);
  // const [importEnabled, setImportEnabled] = useState<boolean>(false);
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  // const [importConfirmationOpen, setImportConfirmationOpen] = useState<boolean>(false);
  const [exportConfirmationOpen, setExportConfirmationOpen] = useState<boolean>(false);



  const handleButtonClick = (value: number) => {
    if (value === 1) {
      setButtonFocusOne(true);
      setButtonFocusTwo(false);
    } else if (value === 2) {
      setButtonFocusOne(false);
      setButtonFocusTwo(true);
    }
  };

  // const handleImportClick = () => {
  //   setImportConfirmationOpen(true);
  // };

  const handleExportClick = () => {
    setExportConfirmationOpen(true);
  };

  // const handleImportConfirmationClose = () => {
  //   setImportConfirmationOpen(false);
  // };

  const handleExportConfirmationClose = () => {
    setExportConfirmationOpen(false);
  };

  // const handleImportConfirmationConfirm = () => {
  //   setImportEnabled(true);
  //   setImportConfirmationOpen(false);
  // };

  const handleExportConfirmationConfirm = () => {
    setExportEnabled(true);
    setExportConfirmationOpen(false);
  };
  const handleExportEnabledChange = (value: boolean) => {
    setExportEnabled(value);
  };

  return (

    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full">
        <Card
          style={{
            padding: 20,
            borderRadius: 15,
            flex: 1,
            flexDirection: "column",
            alignContent: "flex-start",
            justifyContent: "center",
          }}
        >
          <div className="flex w-full justify-end space-x-2 mb-4">
            {/* <Button variant="contained" onClick={handleImportClick}>
              Import
            </Button> */}
            <Typography
              key={"infoPublish"}
              style={{
                fontSize: 18,
                fontWeight: "lighter",
              }}
            >
              Nach dem Anlegen/Bearbeiten von Diensten oder Cookie-Quellen auf Veröffentlichen klicken, damit das Plugin auf die Änderungen zugreifen kann.
            </Typography>
            <Button variant="contained" onClick={handleExportClick}>
              Veröffentlichen
            </Button>
          </div>
          {exportEnabled && (<Export exportStart={exportEnabled} setExportEnabled={handleExportEnabledChange}
          />)}
          {/* {importEnabled && (
            <Import onClose={() => setImportEnabled(false)} />
          )} */}
          <div className="flex w-full justify-start space-x-2 mb-6">
            <Button variant="contained" onClick={() => handleButtonClick(1)} style={{ backgroundColor: buttonFocusOne ? 'blue' : '' }}>
              Services
            </Button>
            <Button variant="contained" onClick={() => handleButtonClick(2)} style={{ backgroundColor: buttonFocusTwo ? 'blue' : '' }}>
              Sources
            </Button>
          </div>

          {buttonFocusOne ? <ServicesAdd /> : null}
          {buttonFocusTwo ? <SourcesAdd /> : null}
          {/* <Dialog open={importConfirmationOpen} onClose={handleImportConfirmationClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <p>Diese Funktion sollte nur einmalig ausgeführt werden. Möchtest du die Datenbank komplett überschreiben, müssen erst alle Daten gelöscht werden. Danach ist ein Import möglich.</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImportConfirmationClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleImportConfirmationConfirm} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog> */}

          <Dialog open={exportConfirmationOpen} onClose={handleExportConfirmationClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <p>Möchtest du wirklich alle Daten veröffentlichen?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleExportConfirmationClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleExportConfirmationConfirm} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </div>
    </div >
  );

}




export default Addon;