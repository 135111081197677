import {faGitlab} from "@fortawesome/free-brands-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const GitlabMergeRequests: IconProp = {
  icon: [
    16,
    16,
    [],
    faGitlab.icon[3],
    "M10 0v2.6a5.002 5.002 0 0 1 3.995 4.674L14 7.5v3.768A2 2 0 0 1 13 15a2 2 0 0 1-1.134-3.647l.134-.085V7.5a3 3 0 0 0-1.828-2.762L10 4.67V7L6 3.5 10 0zM3 1a2 2 0 0 1 1.134 3.647L4 4.732v6.536A2 2 0 0 1 3 15a2 2 0 0 1-1.134-3.647L2 11.268V4.732A2 2 0 0 1 3 1z"
  ],
  iconName: 'democrat',
  prefix: 'fab'
} as IconProp

export default GitlabMergeRequests
