import React, { SetStateAction, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Portal,
} from "@material-ui/core";

type Props = {
  children: (
    confirm: React.Dispatch<SetStateAction<boolean>>
  ) => React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: React.ReactNode | string;
  content: React.ReactNode | string;
  okButton?: string;
  cancelButton?: string;
};

const Confirm = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOK = () => {
    setIsOpen(false);
    (props.onConfirm ?? empty)();
  };

  const empty = () => {};

  const handleCancel = () => {
    setIsOpen(false);
    (props.onCancel ?? empty)();
  };

  return !isOpen ? (
    <>{props.children(setIsOpen)}</>
  ) : (
    <>
      {props.children(setIsOpen)}
      <Portal>
        <Dialog open={isOpen} onClose={handleCancel}>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel} color="primary">
              {props.cancelButton || "cancel"}
            </Button>
            <Button onClick={handleOK} color="primary" autoFocus>
              {props.okButton || "OK"}
            </Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </>
  );
};

export default Confirm;
