import React, { PropsWithChildren, useEffect, useState } from "react";

export const DashboardContext = React.createContext<DashboardContextValueType>({
  //   isLoading: (_) => true,
  //   isVisible: (_) => true,
  setLoading: (_) => null,
  setVisible: (_) => null,
  loadingIds: [],
  invisibleIds: [],
});

export type DashboardContextValueType = {
  //   isLoading: (id: string) => boolean;
  setLoading: (id: string, loading: boolean) => void;
  //   isVisible: (id: string) => boolean;
  setVisible: (id: string, visible: boolean) => void;
  loadingIds: Array<string>;
  invisibleIds: Array<string>;
};

const DashboadProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [loadingArray, setLoadingArray] = useState<Array<string>>([]);
  const [visibleArray, setVisibleArray] = useState<Array<string>>([]);

  useEffect(() => {
    console.log(loadingArray);
  }, [loadingArray]);

  const setLoading = (id: string, loading: boolean) => {
    console.log(`Set loading ${loading} for ${id}`);
    if (loading === true) {
      const newLoadingArray = loadingArray;
      newLoadingArray.push(id);
      setLoadingArray(Array.from(new Set(newLoadingArray)));
    } else {
      const newLoadingArray = loadingArray.filter((aid) => {
        return aid !== id;
      });
      setLoadingArray(newLoadingArray);
    }
  };

  const isLoading = (id: string) => {
    return loadingArray.includes(id) || false;
  };

  const setVisible = (id: string, visible: boolean) => {
    if (visible === false) {
      const newVisibleArray = visibleArray;
      newVisibleArray.push(id);
      setVisibleArray(Array.from(new Set(newVisibleArray)));
    } else {
      const newVisibleArray = visibleArray.filter((aid) => {
        return aid !== id;
      });
      setVisibleArray(newVisibleArray);
    }
  };

  const isVisible = (id: string) => {
    return visibleArray.includes(id) || false;
  };

  return (
    <DashboardContext.Provider
      value={{
        // isLoading: isLoading,
        // isVisible: isVisible,
        setLoading: setLoading,
        setVisible: setVisible,
        loadingIds: loadingArray,
        invisibleIds: visibleArray,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
export default DashboadProvider;
