import React from "react";
import Menu from "@mui/material/Menu";
import { ListItemIcon, MenuItem, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../utils/constants";
import { useFirebase } from "react-redux-firebase";

const Menue = ({
  visible,
  setVisible,
  anchorEl,
}: {
  visible: boolean;
  setVisible: (value: React.SetStateAction<HTMLElement | null>) => void;
  anchorEl: HTMLElement | null;
}) => {
  const firebase = useFirebase();

  return (
    <Menu
      anchorEl={anchorEl}
      id="admin-svc-main-menue"
      open={visible}
      onClose={() => setVisible(null)}
      onClick={() => setVisible(null)}
      sx={{
        ".MuiList-root": {
          backgroundColor: "#222",
          minWidth: 280,
        },
      }}
      //   slotProps={{
      //     paper: {
      //       elevation: 0,
      //       sx: {
      //         overflow: "visible",
      //         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      //         mt: 1.5,
      //         "& .MuiAvatar-root": {
      //           width: 32,
      //           height: 32,
      //           ml: -0.5,
      //           mr: 1,
      //         },
      //         "&::before": {
      //           content: '""',
      //           display: "block",
      //           position: "absolute",
      //           top: 0,
      //           right: 14,
      //           width: 10,
      //           height: 10,
      //           bgcolor: "background.paper",
      //           transform: "translateY(-50%) rotate(45deg)",
      //           zIndex: 0,
      //         },
      //       },
      //     },
      //   }}
      //   transformOrigin={{ horizontal: "right", vertical: "top" }}
      //   anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          setVisible(null);
          firebase.auth().signOut();
        }}
        style={{ backgroundColor: "#222" }}
      >
        <ListItemIcon>
          <FontAwesomeIcon
            style={{ marginTop: 6, fontSize: 20 }}
            color={"#FFF"}
            icon={[ICON_STYLE, "sign-out"]}
          />
        </ListItemIcon>
        <Typography
          style={{
            color: `#EEE`,
            fontSize: 16,
            fontFamily: "Roboto",
            fontWeight: "500",
          }}
        >
          {`Logout`}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default Menue;
