import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./input.css";
import moment from "moment";
import getDashBar from "../../../../firebase/dashbar";
import useAuth from "../../../../hooks/useAuth";

const CouponCreateForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [name, setName] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [promoText, setPromoText] = useState<string | null>(null);
  const [type, setType] = useState<number>(0);
  const [amountValue, setAmountValue] = useState<string | null>(null);
  const [amountCurrency, setAmountCurrency] = useState<string | null>(`EUR`);
  const [percentage, setPercentage] = useState<number | null>(null);
  const [validFrom, setValidFrom] = useState<string | null>(null);
  const [excludedUser, setExcludedUser] = useState<string | null>(null);
  const [includedUser, setIncludedUser] = useState<string | null>(null);
  const [validUntil, setValidUntil] = useState<string | null>(null);
  const [fpRefId, setFpRefId] = useState<string | null>(null);
  const [active, setActive] = useState<boolean>(false);
  const [reusable, setReusable] = useState<boolean>(false);

  const cancel = async () => {
    navigate(`/dashbar/coupon`);
  };

  const save = async () => {
    const coupon = {
      active: active,
      amount:
        amountValue !== null && type === 0
          ? { value: amountValue, currency: amountCurrency }
          : null,
      code: code,
      createdAt: new Date(),
      description: description,
      excludedUser:
        excludedUser !== null && excludedUser.trim() !== ""
          ? excludedUser.split(",")
          : null,
      fpRefId: fpRefId?.trim() !== "" ? fpRefId : null,
      includedUser:
        includedUser !== null && includedUser.trim() !== ""
          ? includedUser.split(",")
          : null,
      name: name,
      percentage: percentage ?? null,
      promoText: promoText,
      reusable: reusable,
      type: type,
      validFrom:
        validFrom !== null && validFrom.trim() !== ""
          ? moment(validFrom).toDate()
          : null,
      validUntil:
        validUntil !== null && validUntil.trim() !== ""
          ? moment(validUntil).toDate()
          : null,
      lastModified: {
        modifiedAt: new Date(),
        modifiedBy: auth.email,
      },
    };
    if (code !== null) {
      const existingCoupon = await getDashBar()
        .db.collection(`coupon`)
        .doc(code)
        .get();
      if (existingCoupon.exists) {
        window.alert(
          `Der Coupon ${code} existiert bereits. Wähle einen anderen Code!`
        );
        return;
      }

      await getDashBar()
        .db.collection(`coupon`)
        .doc(code)
        .set(coupon, { merge: true });
      navigate(`/dashbar/coupon`);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <div style={{ width: "50%" }}>
        <Typography
          style={{
            color: "#FFF",
            fontSize: 26,
            fontFamily: "Roboto",
            fontWeight: "500",
          }}
        >
          {`Neuen Coupon erstellen`}
        </Typography>
        <Paper style={{ padding: 20, borderRadius: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                required
                value={name ?? ""}
                onChange={(event) => setName(event.currentTarget.value)}
                id="name"
                name="Name"
                label="Name"
                fullWidth
                autoComplete="name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="code"
                value={code ?? ""}
                onChange={(event) => setCode(event.currentTarget.value)}
                name="Code"
                label="Code"
                fullWidth
                autoComplete="code"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="description"
                value={description ?? ""}
                onChange={(event) => setDescription(event.currentTarget.value)}
                name="description"
                label="Beschreibung"
                fullWidth
                autoComplete="description"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="promo_text"
                value={promoText ?? ""}
                onChange={(event) => setPromoText(event.currentTarget.value)}
                name="promo_text"
                label="Promo Text"
                fullWidth
                autoComplete="promo_text"
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                id="type"
                value={type}
                label="Art"
                onChange={(event) => {
                  console.log(event.target.value as number);
                  setType(event.target.value as number);
                }}
              >
                <MenuItem value={0}>Betrag</MenuItem>
                <MenuItem value={1}>Prozentsatz</MenuItem>
              </Select>
            </Grid>
            {type === 0 ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="amount_value"
                    value={amountValue ?? ""}
                    onChange={(event) =>
                      setAmountValue(event.currentTarget.value)
                    }
                    name="amount_value"
                    label="Betrag"
                    fullWidth
                    autoComplete="amount_value"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="amount_currency"
                    value={amountCurrency ?? ""}
                    onChange={(event) =>
                      setAmountCurrency(event.currentTarget.value)
                    }
                    name="amount_currency"
                    label="Währung"
                    fullWidth
                    autoComplete="amount_currency"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <TextField
                  required
                  id="percentage"
                  value={percentage ?? 0}
                  onChange={(event) =>
                    setPercentage(+event.currentTarget.value as number)
                  }
                  type="number"
                  name="percentage"
                  label="Prozentsatz"
                  fullWidth
                  autoComplete="percentage"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                id="excluded_user"
                value={excludedUser ?? ""}
                onChange={(event) => setExcludedUser(event.currentTarget.value)}
                name="excluded_user"
                label="Firmen ausschließen"
                fullWidth
                autoComplete="excluded_user"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="included_user"
                value={includedUser ?? ""}
                onChange={(event) => setIncludedUser(event.currentTarget.value)}
                name="included_user"
                label="Bestimmte Firmen erlauben"
                fullWidth
                autoComplete="included_user"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="validFrom"
                value={validFrom ?? null}
                className="m_date_input"
                onChange={(event) => setValidFrom(event.currentTarget.value)}
                name="validFrom"
                label="Gültig ab"
                type="date"
                fullWidth
                autoComplete="validFrom"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="validUntil"
                value={validUntil ?? null}
                className="m_date_input"
                onChange={(event) => setValidUntil(event.currentTarget.value)}
                name="validUntil"
                label="Gültig bis"
                type="date"
                fullWidth
                autoComplete="validUntil"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="fp_ref_id"
                value={fpRefId ?? ""}
                onChange={(event) => setFpRefId(event.currentTarget.value)}
                name="fp_ref_id"
                label="Firstpromoter RefID"
                fullWidth
                autoComplete="fp_ref_id"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={(event) => {
                      setActive(event.currentTarget.checked);
                    }}
                  />
                }
                label="Aktiv"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reusable}
                    onChange={(event) => {
                      setReusable(event.currentTarget.checked);
                    }}
                  />
                }
                label="Mehrfachnutzung möglich"
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" onClick={save}>
                {`Speichern`}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" color="primary" onClick={cancel}>
                {`Abbrechen`}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default CouponCreateForm;
