import React, { useCallback } from "react";
import useProfile from "../hooks/firestore/useProfile";
import { UserRole } from "../firestore/models/User";
import LoadingWrapper from "../components/LoadingWrapper";
import { isLoaded } from "react-redux-firebase";

const withoutRole =
  <P extends object>(roles: UserRole | UserRole[]) =>
  (Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    const profile = useProfile();

    const isAllowed = useCallback(() => {
      if (profile && profile.roles) {
        const _roles = typeof roles === "string" ? [roles] : roles;
        let allowed = true;
        for (const role of _roles) {
          if (profile.roles.includes(role)) {
            allowed = false;
            break;
          }
        }
        return allowed;
      }
      return false;
    }, [profile]);

    return (
      <LoadingWrapper loading={!isLoaded(profile)} suspend>
        {isAllowed() ? <Component {...props} /> : <div>Access denied!</div>}
      </LoadingWrapper>
    );
  };

export default withoutRole;
