import {
    IconButton, MenuItem,
    TableCell,
    TableRow,
    TableBody,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

import React, { useState } from "react";
import { DB } from "../../../../../firebase";
//const { FieldValue } = require('firebase-admin/firestore');
import { useFirestore } from "react-redux-firebase";



type SourceObject = {
    cType: string,
    cParentServiceIdString: string,
    kParentCategory: number,
    cSource: string,
    cSourceHash: string,
    jAttributes: string,
    sourceId: string,
    serviceId: string,
}
type DetailPageProps = {
    selectedItem: SourceObject;
    services: { cServiceId: string; cServiceIdString: string }[];
};


const DetailPage = ({ selectedItem, services }: DetailPageProps) => {
    const firestore = useFirestore()
    const db = DB().collection("plugin").doc("ws5_eucookie").collection("services");


    const [type, setType] = useState(selectedItem.cType);
    const [serviceId, setServiceId] = useState<string>(selectedItem.serviceId);
    const [parentServiceIdString, setParentServiceIdString] = useState<string>(selectedItem.cParentServiceIdString);
    const [parentCategory, setParentCategory] = useState<number>(selectedItem.kParentCategory);
    const [code, setCode] = useState<string>(selectedItem.cSource);


    const handleDelete = async () => {
        try {
            const sourcesCollection = db.doc(serviceId).collection("sources");

            // Delete the selected source
            await sourcesCollection.doc(selectedItem.sourceId).delete();

            console.log("Source deleted successfully!");
            setParentServiceIdString("");
            setServiceId("");
            setType("");
            setParentCategory(0);
            setCode("");

        } catch (error) {
            console.error("Error deleting source:", error);
        }
    };


    return (

        <TableBody>
            <TableRow>
                <TableCell>
                    <MenuItem>{type}</MenuItem>
                </TableCell>
                <TableCell>
                    <MenuItem >
                        {parentServiceIdString}
                    </MenuItem>
                </TableCell>

                <TableCell>{parentCategory}
                </TableCell>

                <TableCell>{code}</TableCell>

            </TableRow>
            <TableRow>
                <TableCell colSpan={8} align="right">

                    <IconButton color="secondary" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>


        </TableBody>

    );
};

export default DetailPage;