import React, { useCallback, useEffect, useState } from "react";
import getDashBar from "../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Bundle } from "@dash.bar/types";
import TrendCard from "./TrendCard";
import AuditCard from "./AuditCard";
import NotificationCard from "./NotificationCard";

const Dashboard = () => {
  //const currentId = moment().format('YYYY_MMMM')
  const { enqueueSnackbar } = useSnackbar();
  const [statistics, setStatistics] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();
  const [refreshingStats, setRefreshingStats] = useState<boolean>(false);

  useEffect(() => {
    getDashBar()
      .db.collection("statistic_weekly")
      .limit(12)
      .orderBy("createdAt", "desc")
      .onSnapshot((snap) => {
        setStatistics(snap.docs ?? []);
      });
  }, [setStatistics]);

  const handleStatisticRefresh = useCallback(() => {
    if (
      !window.confirm("Really want to refresh? (Shouldn't be done too often)")
    )
      return;
    setRefreshingStats(true);
    getDashBar()
      .functions.httpsCallable("calls-statistics-createStatistics")()
      .then(() => {})
      .catch((error) => {
        enqueueSnackbar(`Error: ${error}`, { variant: "error" });
      })
      .finally(() => {
        setRefreshingStats(false);
      });
  }, []);

  const getCompanyDifference = (
    index: number,
    bundle: Bundle,
    all: boolean = false
  ): React.ReactNode => {
    if (!statistics || !statistics[index + 1] || index !== 0) return null;
    let currentValue = 0;
    let oldValue = 0;
    switch (bundle) {
      case Bundle.pro:
        currentValue = statistics[index].data().company.pro;
        oldValue = statistics[index + 1].data().company.pro;
        break;
      case Bundle.business:
        currentValue = statistics[index].data().company.business;
        oldValue = statistics[index + 1].data().company.business;
        break;
      case Bundle.free:
        currentValue = statistics[index].data().company.free;
        oldValue = statistics[index + 1].data().company.free;
        break;
    }
    if (all) {
      currentValue = statistics[index].data().company.all;
      oldValue = statistics[index + 1].data().company.all;
    }
    const isLower = currentValue - oldValue < 0;
    return (
      <span className={isLower ? "text-red-500" : "text-green-500"}>{` ${
        isLower ? `` : `+`
      }${currentValue - oldValue}`}</span>
    );
  };

  const getUserDifference = (
    index: number,
    bundle: Bundle,
    all: boolean = false
  ): React.ReactNode => {
    if (!statistics || !statistics[index + 1] || index !== 0) return null;
    let currentValue = 0;
    let oldValue = 0;
    switch (bundle) {
      case Bundle.pro:
        currentValue = statistics[index].data().user.pro;
        oldValue = statistics[index + 1].data().user.pro;
        break;
      case Bundle.business:
        currentValue = statistics[index].data().user.business;
        oldValue = statistics[index + 1].data().user.business;
        break;
      case Bundle.free:
        currentValue = statistics[index].data().user.free;
        oldValue = statistics[index + 1].data().user.free;
        break;
    }
    if (all) {
      currentValue = statistics[index].data().user.all;
      oldValue = statistics[index + 1].data().user.all;
    }
    const isLower = currentValue - oldValue < 0;
    return (
      <span className={isLower ? "text-red-500" : "text-green-500"}>{` ${
        isLower ? `` : `+`
      }${currentValue - oldValue}`}</span>
    );
  };

  const getShopDifference = (
    index: number,
    state: string,
    all: boolean = false
  ): React.ReactNode => {
    if (!statistics || !statistics[index + 1] || index !== 0) return null;
    let currentValue: number = 0;
    let oldValue: number = 0;
    switch (state) {
      case "offline":
        currentValue = statistics[index].data().shop.offline;
        oldValue = statistics[index + 1].data().shop.offline;
        break;
      case "maintenance":
        currentValue = statistics[index].data().shop.maintenance;
        oldValue = statistics[index + 1].data().shop.maintenance;
        break;
    }
    if (all) {
      currentValue = statistics[index].data().shop.all;
      oldValue = statistics[index + 1].data().shop.all;
    }
    const isLower = currentValue - oldValue < 0;
    return (
      <span className={isLower ? "text-red-500" : "text-green-500"}>{` ${
        isLower ? `` : `+`
      }${currentValue - oldValue}`}</span>
    );
  };

  const createCompanyArray = (
    type: "company" | "user" | "shop" | "reports"
  ) => {
    const data = Object.entries(statistics || {})
      .map(([k, v]) => {
        const data = v.data();
        return {
          timestamp: data?.timestamp || null,
          createdAt: data.createdAt || null,
          total: data?.[type]?.all || 0,
          free: data?.[type]?.free || 0,
          business: data?.[type]?.business || 0,
          pro: data?.[type]?.pro || 0,
          trial: data?.[type]?.trial || 0,
          jtlshop4: data?.[type]?.jtlshop4 || 0,
          jtlshop5: data?.[type]?.jtlshop5 || 0,
          shopware6: data?.[type]?.shopware6 || 0,
          daily: data?.[type]?.daily || 0,
          weekly: data?.[type]?.weekly || 0,
          monthly: data?.[type]?.monthly || 0,
          quarterly: data?.[type]?.quarterly || 0,
          notifications: data?.[type]?.notifications || 0,
          notificationsTotal: data?.[type]?.notificationsTotal || 0,
        };
      })
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1;
        } else if (a.createdAt < b.createdAt) {
          return -1;
        } else {
          return 0;
        }
      });
    return data;
  };

  return (
    <>
      <div>
        <div className="text-center flex justify-evenly my-5">
          {refreshingStats ? (
            <div className="text-sm">{`Loading`}</div>
          ) : (
            <Button onClick={handleStatisticRefresh}>{`Refresh`}</Button>
          )}
        </div>
        <div className="flex justify-evenly flex-wrap">
          <TrendCard title="Companies" data={createCompanyArray("company")} />
          <TrendCard title="User" data={createCompanyArray("user")} />
          <TrendCard title="Shops" data={createCompanyArray("shop")} />
          <TrendCard title="Reports" data={createCompanyArray("reports")} />
          <NotificationCard
            notifications={
              createCompanyArray("reports")[
                createCompanyArray("reports").length - 1
              ]?.notifications || 0
            }
            notificationsTotal={
              createCompanyArray("reports")[
                createCompanyArray("reports").length - 1
              ]?.notificationsTotal || 0
            }
          />
          <AuditCard />
        </div>
        {/* {statistics ? (
          statistics.map((stat, index) => (
            <div className="flex justify-evenly mx-10 text-center my-5">
              <div className="p-5 border-2 rounded w-1/5">
                <div className="font-bold">COMPANY</div>
                <hr />
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tarif</TableCell>
                        <TableCell align="center">Anzahl</TableCell>
                        {index === 0 && (
                          <TableCell align="right">Trend</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"all"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`ALL`}</TableCell>
                        <TableCell className="font-bold text-lg" align="center">
                          <Chip
                            style={{ fontSize: 16, fontWeight: "bold" }}
                            label={stat.data().company.all}
                          />
                        </TableCell>
                        {index === 0 && (
                          <TableCell
                            style={{ fontSize: 18, fontWeight: "bold" }}
                            align="right"
                          >
                            {getCompanyDifference(index, Bundle.pro, true)}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"pro"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`PRO`}</TableCell>
                        <TableCell align="center">
                          {stat.data().company.pro}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getCompanyDifference(index, Bundle.pro)}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"business"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`BUSINESS`}</TableCell>
                        <TableCell align="center">
                          {stat.data().company.business}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getCompanyDifference(index, Bundle.business)}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"free"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`FREE`}</TableCell>
                        <TableCell align="center">
                          {stat.data().company.free}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getCompanyDifference(index, Bundle.free)}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="p-3">
                  <code>{stat.id}</code>
                </div>
              </div>

              <div className="p-5 border-2 rounded w-1/5">
                <div className="font-bold">USER</div>
                <hr />
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tarif</TableCell>
                        <TableCell align="center">Anzahl</TableCell>
                        {index === 0 && (
                          <TableCell align="right">Trend</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"all"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`ALL`}</TableCell>
                        <TableCell className="font-bold text-lg" align="center">
                          <Chip
                            style={{ fontSize: 16, fontWeight: "bold" }}
                            label={stat.data().user.all}
                          />
                        </TableCell>
                        {index === 0 && (
                          <TableCell
                            style={{ fontSize: 18, fontWeight: "bold" }}
                            align="right"
                          >
                            {getUserDifference(index, Bundle.pro, true)}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"pro"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`PRO`}</TableCell>
                        <TableCell align="center">
                          {stat.data().user.pro}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getUserDifference(index, Bundle.pro)}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"business"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`BUSINESS`}</TableCell>
                        <TableCell align="center">
                          {stat.data().user.business}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getUserDifference(index, Bundle.business)}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"free"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`FREE`}</TableCell>
                        <TableCell align="center">
                          {stat.data().user.free}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getUserDifference(index, Bundle.free)}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="p-3">
                  <code>{stat.id}</code>
                </div>
              </div>

              <div className="p-5 border-2 rounded w-1/5">
                <div className="font-bold">SHOPS</div>
                <hr />
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Anzahl</TableCell>
                        {index === 0 && (
                          <TableCell align="right">Trend</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"all"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`ALL`}</TableCell>
                        <TableCell className="font-bold text-lg" align="center">
                          <Chip
                            style={{ fontSize: 16, fontWeight: "bold" }}
                            label={stat.data().shop.all}
                          />
                        </TableCell>
                        {index === 0 && (
                          <TableCell
                            style={{ fontSize: 18, fontWeight: "bold" }}
                            align="right"
                          >
                            {getShopDifference(index, "offline", true)}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"offline"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`OFFLINE`}</TableCell>
                        <TableCell align="center">
                          {stat.data().shop.offline}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getShopDifference(index, "offline")}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow key={"maintenance"}>
                        <TableCell
                          style={{ color: "gray" }}
                          align="left"
                        >{`MAINTENANCE`}</TableCell>
                        <TableCell align="center">
                          {stat.data().shop.maintenance}
                        </TableCell>
                        {index === 0 && (
                          <TableCell align="right">
                            {getShopDifference(index, "maintenance")}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component={Paper} className="mt-5">
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ color: "gray" }} align="center">
                          JTL-Shop 4
                        </TableCell>
                        <TableCell style={{ color: "gray" }} align="center">
                          JTL-Shop 5
                        </TableCell>
                        <TableCell style={{ color: "gray" }} align="center">
                          Shopware 6
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"plattfroms"}>
                        <TableCell align="center">
                          {stat.data().shop.jtlshop4 || "n/a"}
                        </TableCell>
                        <TableCell align="center">
                          {stat.data().shop.jtlshop5 || "n/a"}
                        </TableCell>
                        <TableCell align="center">
                          {stat.data().shop.shopware6 || "n/a"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="p-3">
                  <code>{stat.id}</code>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>no data</>
        )} */}
      </div>
    </>
  );
};

export default Dashboard;
