import React from "react";
import NotificationView from "../../../../../components/NotificationView";
import PageContainer from "../../../../../components/PageContainer";
import firebase from "firebase/compat/app";
import { useSnackbar } from "notistack";

type TabNotificationsProps = {
  notifications: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
};

const TabNotifications = ({ notifications }: TabNotificationsProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteDocument =
    (
      doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ) =>
    async () => {
      if (window.confirm("Really delete?")) {
        await doc.ref
          .delete()
          .then(() =>
            enqueueSnackbar("Successfully deleted!", { variant: "success" })
          )
          .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
      }
    };

  return (
    <PageContainer title="Notifications" heading={3}>
      {notifications &&
        notifications.docs.map((notificationDoc) => (
          <NotificationView
            onDelete={deleteDocument(notificationDoc)}
            notification={notificationDoc.data()}
          />
        ))}
    </PageContainer>
  );
};

export default TabNotifications;
