import {useFirestoreConnect} from "react-redux-firebase";
import {COLL_USER_SETTINGS, DB} from "../../firebase";
import {useSelector} from "react-redux";

const useUserSettings = <T>(uid: string, section: string) : T | null => {

    const storeAs = `userSettings:${uid}:${section}`

    useFirestoreConnect({
        collection: `${COLL_USER_SETTINGS(uid)}`,
        doc: section,
        storeAs: storeAs
    });

    return useSelector((state: { firestore: { data: {[key:string]: T} } }) => state.firestore.data[storeAs] as T) || null;
}

/**
 * @deprecated
 * @param uid
 * @param section
 * @param settings
 */
export const updateUserSettings = (uid: string, section: string, settings: any) => {
    return DB().collection(COLL_USER_SETTINGS(uid)).doc(section).set(settings, {merge: true});
}

export default useUserSettings;