import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import firebase from"firebase/compat/app";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DB } from "../../../../../../firebase";
import Input from "../../../../Dashboard/components/InputComponent";
import {
  startBeforeEndDate,
  verifyAdspaceBooking,
  verifyAdspaceCampaignBooking,
} from "../../../../utils";
import { Textarea } from "../../../../../../components/Input";

interface IBookingModalProps {
  id: string;
  open: boolean;
  campaign: Record<string, any>;
  close: () => void;
}

const ADSPACE_WITH_REF_URL = [
  `tm_banner`,
  `ws_spotlight_1`,
  `ws_spotlight_2`,
  `db_analytic_pagespeed_sponsoring`,
  `db_dashboard_widget`,
];
const ADSPACE_WITH_SECOND_IMAGE = [`db_analytic_pagespeed_sponsoring`];
const ADSPACE_AUTOMATIC_WARNING = [
  `tm_banner`,
  `ws_spotlight_1`,
  `ws_spotlight_2`,
  `db_dashboard_widget`,
  `db_report_sponsoring`,
  `db_analytic_pagespeed_sponsoring`,
  `ws_plugin_im_fokus`,
];

const BookingEditModal = ({
  id,
  open,
  campaign,
  close,
}: IBookingModalProps) => {
  const campaignId = campaign.id || null;
  const active = campaign.active || true;
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");
  const [adspace, setAdspace] = useState<string>();
  const [adspaceImage, setAdspaceImage] = useState<File>();
  const [adspaceImageUrl, setAdspaceImageUrl] = useState<string>();
  const [adspaceImageSecond, setAdspaceImageSecond] = useState<File>();
  const [adspaceImageUrlSecond, setAdspaceImageUrlSecond] = useState<string>();
  const [adspaces, setAdspaces] = React.useState<
    Array<{ name: string; value: string; exclusive?: boolean }>
  >([]);
  const [startDate, setStartDate] = useState<{
    value: Date;
    error: string | null;
  }>({
    value: new Date(),
    error: null,
  });
  const [plugins, setPlugins] = React.useState<
    Array<{ name: string; value: string }>
  >([]);
  const [endDate, setEndDate] = useState<{
    value: Date;
    error: string | null;
  }>({
    value: new Date(new Date().setDate(new Date().getDate() + 7)),
    error: null,
  });
  const [rerferenceUrl, setrerferenceUrl] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [sponsorText, setSponsorText] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [infoText, setInfoText] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [extBooked, setExtBooked] = useState<boolean>(false);
  const [extBookedDate, setExtBookedDate] = useState<{
    value: Date | null;
    error: string | null;
  }>({
    value: new Date(),
    error: null,
  });
  const [extBookedUrl, setExtBookedUrl] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [reminder, setReminder] = useState<boolean>(false);
  const [reminderAt, setReminderAt] = useState<{
    value: Date | null;
    error: string | null;
  }>({
    value: new Date(),
    error: null,
  });
  const [focusedPlugin, setFocusedPlugin] = useState<string>();

  const [bookingError, setBookingError] = useState<{
    type: string;
    message: string;
  } | null>();

  const [isBooking, setIsBooking] = useState<boolean>(false);

  useEffect(() => {
    DB()
      .collection(`plugin`)
      .get()
      .then((data) => {
        const pluginList: Array<{ name: string; value: string }> = [];
        if (!data.empty) {
          data.docs.forEach((doc) => {
            pluginList.push({ name: doc.data().name, value: doc.id });
          });
          const filteredPlugins = pluginList.filter((v) => {
            const regex = /^ws5_/gm;
            return regex.test(v.value);
          });
          setPlugins(filteredPlugins);
        } else {
          setPlugins([]);
        }
      });
  }, [setPlugins]);

  useEffect(() => {
    DB()
      .collectionGroup("booking")
      .get()
      .then((bookings) => {
        bookings.forEach((booking) => {
          if (booking.id === id) {
            setStartDate({
              ...startDate,
              value: booking.data().startDate?.toDate() || new Date(),
            });
            setEndDate({
              ...endDate,
              value: booking.data().endDate?.toDate() || new Date(),
            });
            setrerferenceUrl({
              ...rerferenceUrl,
              value: booking.data().refUrl || "",
            });
            setSponsorText({
              ...sponsorText,
              value: booking.data().sponsorText || "",
            });
            setAdspace(booking.data().adspaceId || "");
            setFocusedPlugin(booking.data().focusedPlugin || "");
            setExtBookedDate({
              ...extBookedDate,
              value: booking.data()?.extBookedAt?.toDate() || null,
            });
            setReminderAt({
              ...reminderAt,
              value: booking.data()?.reminderAt?.toDate() || null,
            });
            setExtBookedUrl({
              ...extBookedUrl,
              value: booking.data()?.extBookedUrl || "",
            });
            setInfoText({ ...infoText, value: booking.data()?.infoText || "" });
            if (
              booking.data().extBookedAt &&
              booking.data().extBookedAt !== null
            ) {
              setExtBooked(true);
            }
            if (
              booking.data().reminderAt &&
              booking.data().reminderAt !== null
            ) {
              setReminder(true);
            }
          }
        });
      });
  }, [id]);

  useEffect(() => {
    DB()
      .collection(`adspace`)
      .get()
      .then((data) => {
        const adspaceList: Array<{
          name: string;
          value: string;
          exclusive?: boolean;
        }> = [];
        if (!data.empty) {
          data.docs.forEach((doc) => {
            adspaceList.push({
              name: doc.data().name,
              value: doc.id,
              exclusive: doc.data()?.exclusive === true,
            });
          });
          setAdspace(adspaceList[0].value);
          setAdspaces(adspaceList);
        } else {
          setAdspaces([]);
        }
      });
  }, [setAdspaces]);

  const onAdspaceChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAdspace(event.currentTarget.value);
  };
  const onStartDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate({ ...startDate, value: new Date(event.currentTarget.value) });
  };
  const onEndDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate({ ...endDate, value: new Date(event.currentTarget.value) });
  };
  const onExtBookedDateChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExtBookedDate({
      ...extBookedDate,
      value: new Date(event.currentTarget.value),
    });
  };
  const onExtBookedUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExtBookedUrl({ ...extBookedUrl, value: event.currentTarget.value });
  };
  const onReminderAtChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReminderAt({
      ...reminderAt,
      value: new Date(event.currentTarget.value),
    });
  };
  const onReferenceUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setrerferenceUrl({ ...rerferenceUrl, value: event.currentTarget.value });
  };
  const onSponsorTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSponsorText({ ...sponsorText, value: event.currentTarget.value });
  };
  const onInfoTextChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInfoText({ ...infoText, value: event.currentTarget.value });
  };
  const onFocusedPluginChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFocusedPlugin(event.currentTarget.value);
  };

  const onAdspaceImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setAdspaceImage(e.currentTarget?.files[0]);
    }
  };
  const onSecondAdspaceImageChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setAdspaceImageSecond(e.currentTarget?.files[0]);
    }
  };
  const getUrlFromImagePath = async (path: string): Promise<string> => {
    return await storage.ref(path).getDownloadURL();
  };

  const book = async () => {
    setBookingError(null);
    setIsBooking(true);
    if (!adspace) {
      setBookingError({
        type: "missing_value",
        message:
          "Bitte wählen Sie einen Werbeplatz aus, um die Buchung abzuschließen",
      });
      setIsBooking(false);
      return;
    }
    if (!startBeforeEndDate(startDate.value, endDate.value)) {
      setBookingError({
        type: "incompatible_dates",
        message: "Das End-Datum darf nicht vor dem Start-Datum liegen!",
      });
      setIsBooking(false);
      return;
    }

    // if (adspace === "tm_banner") {
    if (ADSPACE_WITH_REF_URL.includes(adspace)) {
      const regex =
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
      if (!regex.test(rerferenceUrl.value)) {
        setBookingError({
          type: "missing_value",
          message:
            "Für den gewählten Werbeplatz muss eine korrekte URL angegeben werden!",
        });
        setIsBooking(false);
        return;
      }

      //   if (!adspaceImage) {
      //     setBookingError({
      //       type: "missing_value",
      //       message:
      //         "Für den gewählten Werbeplatz muss eine Bild-Datei hinterlegt werden!",
      //     });
      //     setIsBooking(false);
      //     return;
      //   }
    }
    // if (adspace === "db_report_sponsoring") {
    if (adspace === "db_report_sponsoring") {
      if (sponsorText.value.length <= 0) {
        setBookingError({
          type: "missing_value",
          message:
            "Für den gewählten Werbeplatz muss ein Sponsor angegeben werden!",
        });
        setIsBooking(false);
        return;
      }

      // if (!adspaceImage) {
      //   setBookingError({
      //     type: "missing_value",
      //     message:
      //       "Für den gewählten Werbeplatz muss eine Bild-Datei hinterlegt werden!",
      //   });
      //   setIsBooking(false);
      //   return;
      // }
    }
    if (adspace === "ws_plugin_im_fokus") {
      if (!focusedPlugin) {
        setBookingError({
          type: "missing_value",
          message:
            "Für den gewählten Werbeplatz muss ein Plugin ausgewählt sein!",
        });
        setIsBooking(false);
        return;
      }
    }

    //Only check exclusive adspaces for collision
    if (
      adspaces.filter((v) => {
        return v.value === adspace;
      })[0].exclusive === true
    ) {
      const verified = await verifyAdspaceBooking(
        adspace,
        startDate.value,
        endDate.value,
        false,
        id
      );

      //Only check overlapping for active campaigns
      if (active && verified.error) {
        setBookingError({
          type: "overlapping_booking",
          message: verified.message,
        });
        setIsBooking(false);
        return;
      }
    }

    const verifiedCampaign = await verifyAdspaceCampaignBooking(
      campaign.id,
      startDate.value,
      endDate.value
    );
    if (verifiedCampaign.error) {
      setBookingError({
        type: "overlapping_booking",
        message: verifiedCampaign.message,
      });
      setIsBooking(false);
      return;
    }

    const booking = {
      active: active,
      startDate: startDate.value,
      endDate: endDate.value,
      campaignId: campaign.id,
      campaignName: campaign.name,
      // ...(adspace === "tm_banner" && { refUrl: rerferenceUrl.value }),
      ...(ADSPACE_WITH_REF_URL.includes(adspace) && {
        refUrl: rerferenceUrl.value,
      }),
      ...(adspace === "db_report_sponsoring" && {
        sponsorText: sponsorText.value,
      }),
      ...(adspace === "ws_plugin_im_fokus" && {
        focusedPlugin: focusedPlugin,
      }),
      adspaceId: adspace,
      adspaceName:
        adspaces
          .filter((v) => {
            return v.value === adspace;
          })
          .map((v) => {
            return v.name;
          })[0] || "n/a",
      ...(extBooked
        ? { extBookedAt: extBookedDate.value }
        : { extBookedAt: null }),
      ...(extBooked
        ? { extBookedUrl: extBookedUrl.value }
        : { extBookedUrl: null }),
      ...(reminder ? { reminderAt: reminderAt.value } : { reminderAt: null }),
      infoText: infoText.value,
      updated: new Date(),
    };
    const bookingDoc = DB().collection(`adspace/${adspace}/booking`).doc(id);
    await bookingDoc.set(booking, { merge: true });

    if (adspaceImage) {
      const path = await uploadAdspaceImage(id);
      if (path === null) {
        setBookingError({
          type: "storage",
          message:
            "Fehler beim Datei-Upload: Die Datei konnte nicht gespeichert werden!",
        });
        setIsBooking(false);
      } else {
        const booking = {
          ...(path && { filePath: path }),
          ...(path && { fileUrl: await getUrlFromImagePath(path) }),
        };
        await bookingDoc.set(booking, { merge: true });
        setIsBooking(false);
        close();
      }
    }
    if (adspaceImageSecond) {
      const pathSecond = await uploadSecondAdspaceImage(bookingDoc.id);
      if (pathSecond === null) {
        await bookingDoc.delete();
        setBookingError({
          type: "storage",
          message:
            "Fehler beim Datei-Upload: Die Datei konnte nicht gespeichert werden!",
        });
        setIsBooking(false);
      } else {
        await bookingDoc.set(
          {
            ...(pathSecond && { filePathSecond: pathSecond }),
            ...(pathSecond && {
              fileUrlSecond: await getUrlFromImagePath(pathSecond),
            }),
          },
          { merge: true }
        );
        setIsBooking(false);
        close();
      }
    }
    setIsBooking(false);
    close();
  };

  const uploadAdspaceImage = async (id: string): Promise<string | null> => {
    console.log(
      `${!adspaceImage}||${adspaceImage === null} ||${
        campaignId === null
      } ||${!adspace}`
    );
    if (
      !adspaceImage ||
      adspaceImage === null ||
      campaignId === null ||
      !adspace
    )
      return null;
    const url = URL.createObjectURL(adspaceImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/${campaignId}/${adspace}_${id}.${adspaceImage.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const adUrl = await ref.getDownloadURL();
    setAdspaceImageUrl(adUrl);
    return path;
  };
  const uploadSecondAdspaceImage = async (
    id: string
  ): Promise<string | null> => {
    console.log(
      `${!adspaceImageSecond}||${adspaceImageSecond === null} ||${
        campaignId === null
      } ||${!adspace}`
    );
    if (
      !adspaceImageSecond ||
      adspaceImageSecond === null ||
      campaignId === null ||
      !adspace
    )
      return null;
    const url = URL.createObjectURL(adspaceImageSecond);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/${campaignId}/${adspace}_${id}_second.${adspaceImageSecond.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const adUrl = await ref.getDownloadURL();
    setAdspaceImageUrlSecond(adUrl);
    return path;
  };

  return (
    <Modal open={open} onClose={close}>
      <Card
        style={{
          padding: 20,
          borderRadius: 15,
          flex: 1,
          flexDirection: "column",
          alignContent: "flex-start",
          justifyContent: "center",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        }}
      >
        <div className="flex flex-row justify-center w-full">
          <Typography
            style={{
              fontSize: 24,
              fontWeight: "lighter",
              marginBottom: 20,
            }}
          >
            Werbeplatz Buchung bearbeiten
          </Typography>
        </div>
        <Grid
          container
          spacing={1}
          className="w-full"
          style={{ marginLeft: 10 }}
        >
          <Grid item xs={3}>
            <Label text="Werbeplatz" />
          </Grid>
          <Grid item xs={8}>
            <select
              key="valueName"
              disabled={true}
              value={adspace}
              onChange={onAdspaceChanged}
              style={{
                padding: 5,
                width: "100%",
                borderRadius: 5,
                marginBottom: 5,
                color: "black",
                fontSize: 18,
              }}
            >
              {adspaces.map((adspaceItem) => {
                return (
                  <option key={adspaceItem.value} value={adspaceItem.value}>
                    {adspaceItem.name}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xs={3}>
            <Label text="Datum (Start)" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"date"}
              placeholder={"Datum (Start)"}
              onChange={onStartDateChanged}
              value={
                startDate.value && moment(startDate.value).format(`YYYY-MM-DD`)
              }
              error={startDate.error}
            />
          </Grid>
          <Grid item xs={3}>
            <Label text="Datum (Ende)" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"date"}
              placeholder={"Datum (Ende)"}
              onChange={onEndDateChanged}
              value={
                endDate.value && moment(endDate.value).format(`YYYY-MM-DD`)
              }
              error={endDate.error}
            />
          </Grid>
          {/* {adspace === "tm_banner" && ( */}
          {ADSPACE_WITH_REF_URL.includes(adspace || "") && (
            <>
              <Grid item xs={3}>
                <Label text="Referenz URL" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Referenz URL"}
                  onChange={onReferenceUrlChanged}
                  value={rerferenceUrl.value}
                  error={rerferenceUrl.error}
                />
              </Grid>
            </>
          )}
          {adspace === "db_report_sponsoring" && (
            <>
              <Grid item xs={3}>
                <Label text="Sponsor Name" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Sponsor Name"}
                  onChange={onSponsorTextChanged}
                  value={sponsorText.value}
                  error={sponsorText.error}
                />
              </Grid>
            </>
          )}
          {adspace === "ws_plugin_im_fokus" && (
            <>
              <Grid item xs={3}>
                <Label text="Plugin im Fokus:" />
              </Grid>
              <Grid item xs={8}>
                <select
                  key="focusedPlugin"
                  value={focusedPlugin}
                  onChange={onFocusedPluginChanged}
                  style={{
                    padding: 5,
                    width: "100%",
                    borderRadius: 5,
                    marginBottom: 5,
                    color: "black",
                    fontSize: 18,
                  }}
                >
                  {plugins.map((plugin) => {
                    return (
                      <option key={plugin.value} value={plugin.value}>
                        {plugin.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Label text="Datei" />
          </Grid>
          <Grid item xs={8}>
            <Input
              type={"file"}
              accept={
                [
                  `tm_banner`,
                  `ws_spotlight_1`,
                  `ws_spotlight_2`,
                  `db_analytic_pagespeed_sponsoring`,
                ].includes(adspace || "")
                  ? "image/png"
                  : "image/*,.txt"
              }
              placeholder={"Datei"}
              onChange={onAdspaceImageChanged}
            />
          </Grid>
          {ADSPACE_WITH_SECOND_IMAGE.includes(adspace || "") && (
            <>
              <Grid item xs={3}>
                <Label text="Datei (sekundär)" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"file"}
                  accept={
                    [
                      `tm_banner`,
                      `ws_spotlight_1`,
                      `ws_spotlight_2`,
                      `db_analytic_pagespeed_sponsoring`,
                    ].includes(adspace || "")
                      ? "image/png"
                      : "image/*,.txt"
                  }
                  placeholder={"Datei 2"}
                  onChange={onSecondAdspaceImageChanged}
                />
              </Grid>
            </>
          )}
          {ADSPACE_AUTOMATIC_WARNING.includes(adspace || "") && (
            <>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Warning text="Der ausgewählte Werbeplatz wird automatisch aktualisiert" />
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Label text="Info Text" />
          </Grid>
          <Grid item xs={8}>
            <Textarea
              placeholder={"zusätzliche Informationen"}
              onChange={onInfoTextChanged}
              value={infoText.value}
              error={infoText.error}
              rows={2}
            />
          </Grid>
          <Grid item xs={3}>
            <Label text="Gebucht" />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={extBooked}
                  onChange={() => {
                    setExtBooked(!extBooked);
                  }}
                />
              }
              label="Gebucht"
            />
          </Grid>
          {extBooked === true && (
            <>
              <Grid item xs={3}>
                <Label text="Gebucht am" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"date"}
                  placeholder={"Gebucht am"}
                  onChange={onExtBookedDateChanged}
                  value={
                    extBookedDate.value && extBookedDate.value === null
                      ? ""
                      : moment(extBookedDate.value).format(`YYYY-MM-DD`)
                  }
                  error={extBookedDate.error}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Buchungs URL" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Buchungs URL"}
                  onChange={onExtBookedUrlChanged}
                  value={extBookedUrl.value}
                  error={extBookedUrl.error}
                />
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Label text="Erinnerung" />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={reminder}
                  onChange={() => {
                    setReminder(!reminder);
                  }}
                />
              }
              label="Erinnerung aktivieren"
            />
          </Grid>
          {reminder === true && (
            <>
              <Grid item xs={3}>
                <Label text="Erinnerung am" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"date"}
                  placeholder={"Erinnerung am"}
                  onChange={onReminderAtChanged}
                  value={
                    reminderAt.value && reminderAt.value === null
                      ? ""
                      : moment(reminderAt.value).format(`YYYY-MM-DD`)
                  }
                  error={reminderAt.error}
                />
              </Grid>
            </>
          )}
        </Grid>
        {bookingError !== null && (
          <div className="flex flex-row justify-center w-full mt-12">
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "lighter",
                color: "#f5922f",
                marginTop: 2,
              }}
            >
              {bookingError?.message}
            </Typography>
          </div>
        )}
        <div className="flex flex-row justify-center w-full mt-12">
          <Button onClick={book} disabled={isBooking} variant="contained">
            {isBooking ? "..." : "Aktualisieren"}
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

const Warning = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 14,
        color: `#ffa15e`,
        fontWeight: "bold",
      }}
    >
      {`Hinweis: ${text}`}
    </Typography>
  );
};

export default BookingEditModal;
