import React from "react";
import { OLD_Plugin as Plugin, Release } from "@dash.bar/types";
import ReleaseListItem from "./ReleaseListItem";
import useGitlab, { gitlabAPI } from "../../../../../hooks/gitlab/useGitlab";
import { asyncForEach } from "../../../../../utils/funcs";
import { useSetDocument } from "../../../../../firestore/hooks";
import { GitlabProject } from "../../../../../gitlab/types/GitlabProject";
import { GitlabTag } from "../../../../../gitlab/types/GitlabTag";

type Props = {
  data: Record<
    string,
    Release & {
      requirements: {
        minPhpVersion: string;
        minShopVersion: string;
        maxShopVersion: string;
      };
      internalChangelog: string;
      isPrivateRelease: boolean;
    }
  >;
  plugin: Plugin;
  readOnly?: boolean;
};

const ReleaseList = ({ data, plugin, readOnly = false }: Props) => {
  const gitlab = useGitlab();
  const updateRelease = useSetDocument<Release>(
    `plugin/${plugin.pluginId}/release`
  );

  if (gitlab.status === "loading") {
    return <p>loading...</p>;
  }

  if (gitlab.status === "unauthorized") {
    return <div>CHECK GITLAB TOKEN!</div>;
  }

  const handleReleaseSave = (release: Release) => {
    return updateRelease(release, release.version);
  };
  const handleReleaseUpdate = (release: Release) =>
    new Promise((resolve, reject) => {
      const changelogPromise = new Promise((resolve1) => {
        if (gitlab.status === "authorized" && plugin?.gitlabId) {
          gitlabAPI()({
            endpoint: `/projects/${plugin.gitlabId}/repository/tags`,
          })
            .then(async (res: any) => {
              await asyncForEach(res.data, (tag: GitlabTag) => {
                if (tag.name === `v${release.version}` && tag.release) {
                  resolve1(tag.release.description ?? null);
                }
              });
              resolve1(null);
            })
            .catch(() => resolve1(null));
        } else {
          resolve1(null);
        }
      });

      const tryDownload = async (url: string) => {
        try {
          const res = await fetch(url, { method: "HEAD" });
          return res.status === 200 ? url : false;
        } catch (e) {
          return false;
        }
      };

      const urlPromise = new Promise((resolve1) => {
        if (gitlab.status === "authorized" && plugin?.gitlabId) {
          gitlabAPI()({
            endpoint: `/projects/${plugin.gitlabId}`,
          })
            .then(async (res) => {
              const downloadURL1 = await tryDownload(
                `https://ws-download.de/jtl-plugins/${
                  (res.data as GitlabProject).path
                }/${(res.data as GitlabProject).path}-v${release.version}.zip`
              );
              console.debug("CHECK DOWNLOAD1", downloadURL1);
              const downloadURL2 = await tryDownload(
                `https://ws-download.de/jtl-plugins/${
                  (res.data as GitlabProject).path
                }/${(res.data as GitlabProject).path}.v${release.version}.zip`
              );
              console.debug("CHECK DOWNLOAD2", downloadURL2);
              if (downloadURL1) {
                resolve1(downloadURL1);
              } else if (downloadURL2) {
                resolve1(downloadURL2);
              } else {
                reject(null);
              }
            })
            .catch(() => resolve1(null));
        } else {
          resolve1(null);
        }
      });

      Promise.all([changelogPromise, urlPromise]).then(resolve).catch(reject);
    });

  return (
    <>
      {Object.keys(data).map((version) => (
        <ReleaseListItem
          onUpdateRelease={handleReleaseUpdate}
          key={version}
          readOnly={readOnly}
          onSaveRelease={handleReleaseSave}
          pluginId={plugin.pluginId}
          data={data[version]}
        />
      ))}
    </>
  );
};

export default ReleaseList;
