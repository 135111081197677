import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import DashboardCard from "../general/DashboardCard";
import getDashBar from "../../../../firebase/dashbar";
// import { PolarArea } from "react-chartjs-2";
import { COMPONENTS } from "../../../../constants/colors";
import { Chart, RadialLinearScale } from "chart.js";
import { UserRole } from "../../../../firestore/models";
import Dinero from "dinero.js";
import { Bar, Doughnut } from "react-chartjs-2";

const DashbarSalesVolumeV2 = () => {
  Chart.register(RadialLinearScale);
  const [bookings, setBookings] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getDashBar()
      .db.collectionGroup("booking")
      .where("status", "==", 1)
      .limit(4000)
      .get()
      .then((result) => {
        setBookings(result.docs ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setBookings]);

  const formattedBookings = (): Array<{
    status: number | null;
    bundle: string | null;
    interval: string | null;
    price: string | null;
    type: string | null;
  }> => {
    const data = Object.entries(bookings || {}).map(([k, v]) => {
      const data = v.data();
      return {
        status: data?.status || null,
        bundle: data?.bundle || null,
        interval: data?.interval || null,
        price: data?.price[data?.interval]?.value || null,
        type: data?.purchaseType ?? null,
      };
    });
    return data;
  };

  const monthlyAmount = formattedBookings()
    .filter((v) => {
      return v.interval === "month";
    })
    .map((v) => {
      const price = v.price ?? "0.00";
      return Dinero({
        amount: parseInt(price?.replace(".", "").replace(",", "")),
      });
    })
    .reduce<string>((prev, curr) => {
      return Dinero({
        amount: parseInt(prev.replace(".", "").replace(",", "")),
      })
        .add(curr)
        .toFormat("0,0.00");
    }, "0.00");

  const yearlyAmount = formattedBookings()
    .filter((v) => {
      return v.interval === "year";
    })
    .map((v) => {
      const price = v.price ?? "0.00";
      return Dinero({
        amount: parseInt(price?.replace(".", "").replace(",", "")),
      });
    })
    .reduce<string>((prev, curr) => {
      return Dinero({
        amount: parseInt(prev.replace(".", "").replace(",", "")),
      })
        .add(curr)
        .toFormat("0,0.00");
    }, "0.00");

  const totalAmountMonth = formattedBookings()
    .filter((v) => {
      return v.interval !== null;
    })
    .map((v) => {
      const interval = v.interval;
      const price = v.price ?? "0.00";
      if (interval === "year") {
        return Dinero({
          amount: parseInt(price?.replace(".", "").replace(",", "")),
        }).divide(12);
      } else {
        return Dinero({
          amount: parseInt(price?.replace(".", "").replace(",", "")),
        });
      }
    })
    .reduce<string>((prev, curr) => {
      return Dinero({
        amount: parseInt(prev.replace(".", "").replace(",", "")),
      })
        .add(curr)
        .toFormat("0,0.00");
    }, "0.00");

  const amountByAbocloud = formattedBookings()
    .filter((v) => {
      return v.interval !== null && (v.type === "abocloud" || v.type === null);
    })
    .map((v) => {
      const interval = v.interval;
      const price = v.price ?? "0.00";
      if (interval === "year") {
        return Dinero({
          amount: parseInt(price?.replace(".", "").replace(",", "")),
        }).divide(12);
      } else {
        return Dinero({
          amount: parseInt(price?.replace(".", "").replace(",", "")),
        });
      }
    })
    .reduce<string>((prev, curr) => {
      return Dinero({
        amount: parseInt(prev.replace(".", "").replace(",", "")),
      })
        .add(curr)
        .toFormat("0.00");
    }, "0.00");

  const amountByApple = formattedBookings()
    .filter((v) => {
      return v.interval !== null && v.type === "apple";
    })
    .map((v) => {
      const interval = v.interval;
      const price = v.price ?? "0.00";
      if (interval === "year") {
        return Dinero({
          amount: parseInt(price?.replace(".", "").replace(",", "")),
        }).divide(12);
      } else {
        return Dinero({
          amount: parseInt(price?.replace(".", "").replace(",", "")),
        });
      }
    })
    .reduce<string>((prev, curr) => {
      return Dinero({
        amount: parseInt(prev.replace(".", "").replace(",", "")),
      })
        .add(curr)
        .toFormat("0.00");
    }, "0.00");

  const data = {
    labels: ["abocloud", "ApplePay"],
    datasets: [
      {
        label: "30_days",
        data: [parseFloat(amountByAbocloud), parseFloat(amountByApple)],
        backgroundColor: [
          COMPONENTS.DASHBOARD.CHART.COLOR_3,
          COMPONENTS.DASHBOARD.CHART.COLOR_4,
        ],
        borderWidth: 0,
      },
    ],
  };

  //   const data = {
  //     labels: ["free", "business", "pro"],
  //     datasets: [
  //       {
  //         label: "30_days",
  //         data: [
  //           userData[0]?.free ?? 0,
  //           userData[0]?.business ?? 0,
  //           userData[0]?.pro ?? 0,
  //         ],
  //         // backgroundColor: [DASHBAR.FREE, DASHBAR.BUSINESS, DASHBAR.PRO],
  //         backgroundColor: [
  //           COMPONENTS.DASHBOARD.THEMES.COLOR_1,
  //           COMPONENTS.DASHBOARD.THEMES.COLOR_2,
  //           COMPONENTS.DASHBOARD.THEMES.COLOR_3,
  //         ],
  //         borderWidth: 0,
  //         // borderColor: "#FFF",
  //       },
  //     ],
  //   };

  return (
    <DashboardCard
      title="Umsatz"
      loading={loading}
      theme="dashbar"
      role={UserRole.Operational}
    >
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  {`${monthlyAmount
                    .replace(",", "#")
                    .replace(".", ",")
                    .replace("#", ".")} EUR`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  {`${yearlyAmount
                    .replace(",", "#")
                    .replace(".", ",")
                    .replace("#", ".")} EUR`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#FFF",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  {`${totalAmountMonth
                    .replace(",", "#")
                    .replace(".", ",")
                    .replace("#", ".")} EUR`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#AAA",
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  {`Monatlich`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#AAA",
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  {`Jährlich`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#AAA",
                    fontSize: 12,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  {`Gesamt (pro Monat)`}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: "#AAA",
                  fontSize: 12,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  marginTop: 5,
                  marginBottom: 2,
                }}
              >
                {`Umsatz nach Zahlungsart (monatlich)`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Doughnut
                data={data}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top",
                      display: false,
                    },
                    title: {
                      display: false,
                      text: "",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={2}>
                  <div
                    style={{
                      backgroundColor: COMPONENTS.DASHBOARD.CHART.COLOR_3,
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>{`abocloud`}</Grid>
                <Grid item xs={4}>{`${parseInt(amountByAbocloud)} EUR`}</Grid>
                <Grid item xs={2}>
                  <div
                    style={{
                      backgroundColor: COMPONENTS.DASHBOARD.CHART.COLOR_4,
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>{`ApplePay`}</Grid>
                <Grid item xs={4}>{`${parseInt(amountByApple)} EUR`}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default DashbarSalesVolumeV2;
