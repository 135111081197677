import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/app-check";

let app: firebase.app.App;
let _func: firebase.functions.Functions;
let _auth: firebase.auth.Auth;
let _db: firebase.firestore.Firestore;
let _storage: firebase.storage.Storage;

type DashBarApp = {
  app: firebase.app.App;
  functions: firebase.functions.Functions;
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  storage: firebase.storage.Storage;
};

const getDashBar = (): DashBarApp => {
  if (!app) {
    const firebaseConfig = require("../firebaseConfigDashBar").default;
    app = firebase.initializeApp(firebaseConfig, "dash.bar");
    const appCheck = firebase.appCheck(app);
    appCheck.activate(process.env.DASHBAR_APP_CHECK_SITE_KEY ?? "");
    appCheck.setTokenAutoRefreshEnabled(true);
    _db = firebase.firestore(app);
    _db.settings({
      merge: true,
      ignoreUndefinedProperties: true,
    });
    firebase.storage(app);
    _auth = firebase.auth(app);
    _func = app.functions("europe-west3");
    _storage = app.storage();
  }
  return {
    app: app,
    functions: _func,
    auth: _auth,
    db: _db,
    storage: _storage,
  };
};

export default getDashBar;
