import { useEffect, useState } from "react";
import { OLD_Plugin as Plugin } from "@dash.bar/types";
import React from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  LineElement,
  PointElement,
} from "chart.js";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import useProfile from "../../../../../hooks/firestore/useProfile";
import { UserRole } from "../../../../../firestore/models";
import { Refresh } from "@material-ui/icons";
import { DB } from "../../../../../firebase";
import { createMonthlyStatCSV, createYearlyStatCSV, downloadCSV } from "./csv";

type Props = {
  plugin: Plugin;
};

const StatsView = ({ plugin }: Props) => {
  const pluginExsId = plugin.exs_id || null;
  const profile = useProfile();

  const [rawData, setRawData] = useState<Array<Record<string, any>> | null>(
    null
  );

  const [monthArray, setMonthArray] = useState<Array<string>>([]);
  const [yearArray, setYearArray] = useState<Array<string>>([]);

  const [from, setFrom] = React.useState<Date | null | undefined>(undefined);
  const [to, setTo] = React.useState<Date | null | undefined>(undefined);

  //Only updated after submit to prevent unnecessary requests
  const [fromSubmitted, setFromSubmitted] = React.useState<Date | null>(null);
  const [toSubmitted, setToSubmitted] = React.useState<Date | null>(null);

  const [selcetedGraph, setSelectedGraph] = useState<number>(0);

  const handleFromChange = (fromNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(fromNew.target.value).toISOString();
    } catch {
      return;
    }
    setFrom(new Date(fromNew.target.value));
  };

  const handleToChange = (toNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(toNew.target.value).toISOString();
    } catch {
      return;
    }
    setTo(new Date(toNew.target.value));
  };

  const submitDates = () => {
    console.log(from);
    console.log(to);
    console.log(moment(from).format(`DD.MM.YYYY`));
    console.log(moment(to).format(`DD.MM.YYYY`));
    setFromSubmitted(from ?? null);
    setToSubmitted(to ?? null);
  };

  useEffect(() => {
    if (fromSubmitted !== null && toSubmitted !== null) {
      console.log(`Update Time arrays`);
      let currentDate = moment(fromSubmitted);
      const tempMonthArray: Array<string> = [];
      const tempYearArray: Array<string> = [];
      while (currentDate.isBefore(moment(toSubmitted).add(1, `month`))) {
        const monthFormat = currentDate.format(`MM.YYYY`);
        const yearFormat = currentDate.format(`YYYY`);
        if (
          !tempMonthArray.some((v) => {
            return v === monthFormat;
          })
        ) {
          tempMonthArray.push(monthFormat);
        }
        if (
          !tempYearArray.some((v) => {
            return v === yearFormat;
          })
        ) {
          tempYearArray.push(yearFormat);
        }
        currentDate = currentDate.add(1, `month`);
      }
      setMonthArray(tempMonthArray);
      setYearArray(tempYearArray);
    }
  }, [fromSubmitted, toSubmitted]);

  useEffect(() => {
    DB()
      .collection(`webhook`)
      .where(`event_type`, "==", `exs.order.completed`)
      .where(`resource.metas.exs_id`, "==", pluginExsId)
      .get()
      .then((data) => {
        const rawDataArray: Array<Record<string, any>> = [];
        data.docs.forEach((doc) => {
          rawDataArray.push({ ...doc.data() });
        });
        setRawData(rawDataArray);
      });
  }, []);

  const allCheckouts = rawData?.filter((data) => {
    return data?.resource?.metas?.license_type === `prod`;
  });

  const newCheckouts = rawData
    ?.filter((data) => {
      return data?.resource?.metas?.license_type === `prod`;
    })
    .filter((data) => {
      return data?.resource?.metas?.order_type === `create`;
    });

  const upgradedCheckouts = rawData
    ?.filter((data) => {
      return data?.resource?.metas?.license_type === `prod`;
    })
    .filter((data) => {
      return data?.resource?.metas?.order_type === `upgrade`;
    });

  const extendedCheckouts = rawData
    ?.filter((data) => {
      return data?.resource?.metas?.license_type === `prod`;
    })
    .filter((data) => {
      return data?.resource?.metas?.order_type === `extend`;
    });

  const testCheckkouts = rawData?.filter((data) => {
    return data?.resource?.metas?.license_type === `test`;
  });

  const newCheckoutsByMonth = newCheckouts
    ?.map((data) => {
      return moment(new Date(data.created_at)).format(`MM.YYYY`);
    })
    .reduce<Record<string, number>>((prev, curr) => {
      if (prev?.[curr]) {
        return { ...prev, [curr]: prev[curr] + 1 };
      } else {
        return { ...prev, [curr]: 1 };
      }
    }, {});

  const upgradedCheckoutsByMonth = upgradedCheckouts
    ?.map((data) => {
      return moment(new Date(data.created_at)).format(`MM.YYYY`);
    })
    .reduce<Record<string, number>>((prev, curr) => {
      if (prev?.[curr]) {
        return { ...prev, [curr]: prev[curr] + 1 };
      } else {
        return { ...prev, [curr]: 1 };
      }
    }, {});

  const extendedCheckoutsByMonth = extendedCheckouts
    ?.map((data) => {
      return moment(new Date(data.created_at)).format(`MM.YYYY`);
    })
    .reduce<Record<string, number>>((prev, curr) => {
      if (prev?.[curr]) {
        return { ...prev, [curr]: prev[curr] + 1 };
      } else {
        return { ...prev, [curr]: 1 };
      }
    }, {});

  const testCheckkoutsByMonth = testCheckkouts
    ?.map((data) => {
      return moment(new Date(data.created_at)).format(`MM.YYYY`);
    })
    .reduce<Record<string, number>>((prev, curr) => {
      if (prev?.[curr]) {
        return { ...prev, [curr]: prev[curr] + 1 };
      } else {
        return { ...prev, [curr]: 1 };
      }
    }, {});

  const allCheckoutsByYear = allCheckouts
    ?.map((data) => {
      return moment(new Date(data.created_at)).format(`YYYY`);
    })
    .reduce<Record<string, number>>((prev, curr) => {
      if (prev?.[curr]) {
        return { ...prev, [curr]: prev[curr] + 1 };
      } else {
        return { ...prev, [curr]: 1 };
      }
    }, {});

  // const newCheckoutsByMonthOrdered = Object.entries(newCheckoutsByMonth || {})
  //   .map(([k, v]) => {
  //     return {
  //       timestamp: k,
  //       sortNumber: +`${k.split(`.`)[1]}${k.split(`.`)[0]}`,
  //       value: v,
  //     };
  //   })
  //   .sort((a, b) => {
  //     if (a.sortNumber > b.sortNumber) {
  //       return 1;
  //     } else if (a.sortNumber < b.sortNumber) {
  //       return -1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // console.log(newCheckoutsByMonthOrdered);

  // console.log(
  //   newCheckoutsByMonthOrdered.reduce<Record<string, number>>((prev, curr) => {
  //     return { ...prev, [curr.timestamp]: curr.value };
  //   }, {})
  // );

  const conversionByMonth = Object.entries(upgradedCheckoutsByMonth || {})
    .map(([k, v]) => {
      const tests = testCheckkoutsByMonth ? testCheckkoutsByMonth[k] : 0;
      const sales = v;
      const conversionRate = tests === 0 ? 0 : sales / tests;
      return {
        timestamp: k,
        sortNumber: +`${k.split(`.`)[1]}${k.split(`.`)[0]}`,
        tests: tests,
        sales: sales,
        value: conversionRate,
      };
    })
    .sort((a, b) => {
      if (a.sortNumber > b.sortNumber) {
        return 1;
      } else if (a.sortNumber < b.sortNumber) {
        return -1;
      } else {
        return 0;
      }
    });

  const summarizedDataByMonth = monthArray
    .map((v) => {
      return {
        timestamp: v,
        sortNumber: +`${v.split(`.`)[1]}${v.split(`.`)[0]}`,
      };
    })
    .sort((a, b) => {
      if (a.sortNumber > b.sortNumber) {
        return 1;
      } else if (a.sortNumber < b.sortNumber) {
        return -1;
      } else {
        return 0;
      }
    })
    .map((v) => {
      const conversion = conversionByMonth.find((val) => {
        return v.timestamp === val.timestamp;
      });
      return {
        pluginId: plugin.pluginId,
        timestamp: v.timestamp,
        checkouts: newCheckoutsByMonth
          ? newCheckoutsByMonth[v.timestamp]
          : null,
        upgrades: conversion ? conversion.sales : null,
        tests: conversion ? conversion.tests : null,
        conversion_rate: conversion ? +conversion.value.toFixed(2) : null,
        extensions: extendedCheckoutsByMonth
          ? extendedCheckoutsByMonth[v.timestamp]
          : null,
      };
    });
  const summarizedDataByYear = yearArray
    .map((v) => {
      return {
        timestamp: v,
        sortNumber: +`${v.split(`.`)[1]}${v.split(`.`)[0]}`,
      };
    })
    .sort((a, b) => {
      if (a.sortNumber > b.sortNumber) {
        return 1;
      } else if (a.sortNumber < b.sortNumber) {
        return -1;
      } else {
        return 0;
      }
    })
    .map((v) => {
      return {
        pluginId: plugin.pluginId,
        timestamp: v.timestamp,
        checkouts: allCheckoutsByYear ? allCheckoutsByYear[v.timestamp] : null,
      };
    });

  const downloadMonthly = () => {
    downloadCSV(createMonthlyStatCSV(summarizedDataByMonth));
  };

  const downloadYearly = () => {
    downloadCSV(createYearlyStatCSV(summarizedDataByYear));
  };
  console.log(summarizedDataByMonth);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Lizenzverkäufe",
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };

  const getLabel = (): string => {
    switch (selcetedGraph) {
      case 3:
        return `Lizenzen pro Jahr`;
      case 2:
        return `Verlängerungen`;
      case 1:
        return `Conversionrate`;
      case 0:
      default:
        return `Checkouts`;
    }
  };

  const data = {
    labels: selcetedGraph === 3 ? yearArray : monthArray,
    datasets: [
      {
        label: getLabel(),
        data:
          selcetedGraph === 3
            ? summarizedDataByYear.map((v) => {
                return v.checkouts ?? 0;
              })
            : summarizedDataByMonth.map((v) => {
                switch (selcetedGraph) {
                  case 2:
                    return v.extensions ?? 0;
                  case 1:
                    return v.conversion_rate ?? 0;
                  case 0:
                  default:
                    return v.checkouts ?? 0;
                }
              }),
        backgroundColor: `rgba(52, 137, 235)`,
        borderColor: `#4f9fb3`,
      },
    ],
  };

  if (!profile.roles.includes(UserRole.Operational)) {
    return null;
  }

  if (!pluginExsId || pluginExsId === null) {
    return <div>Exs ID required</div>;
  }

  return (
    <>
      <div
        className="bg-blue-700"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          borderRadius: 15,
          fontSize: 18,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Von
          <input
            type={`date`}
            value={moment(from || new Date()).format("YYYY-MM-DD")}
            onChange={handleFromChange}
            style={{
              padding: 3,
              borderColor:
                to !== undefined && from === undefined ? `#bf3030` : `#FFF`,
              borderWidth: to !== undefined && from === undefined ? 3 : 0,
              borderStyle: `solid`,
              borderRadius: 10,
              marginLeft: 5,
              marginRight: 10,
            }}
          />
          Bis
          <input
            type={`date`}
            value={moment(to || new Date()).format("YYYY-MM-DD")}
            onChange={handleToChange}
            style={{
              padding: 3,
              borderColor:
                from !== undefined && to === undefined ? `#bf3030` : `#FFF`,
              borderWidth: from !== undefined && to === undefined ? 3 : 0,
              borderStyle: `solid`,
              borderRadius: 10,
              marginLeft: 5,
              marginRight: 10,
            }}
          />
          {rawData !== null && (
            <IconButton onClick={submitDates}>
              <Refresh fontSize={"small"} />
            </IconButton>
          )}
        </div>
      </div>
      {fromSubmitted !== null &&
        toSubmitted !== null &&
        (summarizedDataByMonth.length > 0 ||
          summarizedDataByYear.length > 0) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
              width: "100%",
              height: 100,
              flexShrink: "revert",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                margin: 10,
                display: "flex",
                height: "100%",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                color: "black",
                width: "20%",
                flexShrink: "revert",
                padding: 10,
                borderRadius: 15,
              }}
            >
              <Grid
                container
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <Grid item xs={7}>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: "light",
                      marginBottom: 2,
                    }}
                  >
                    {`Summe Checkouts`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: "normal",
                      marginBottom: 2,
                    }}
                  >
                    {summarizedDataByMonth
                      .map((v) => {
                        return v.checkouts ?? 0;
                      })
                      .reduce<number>((prev, curr) => {
                        return prev + curr;
                      }, 0)}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: "light",
                      marginBottom: 2,
                    }}
                  >
                    {`Summe Verlängerungen`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: "normal",
                      marginBottom: 2,
                    }}
                  >
                    {summarizedDataByMonth
                      .map((v) => {
                        return v.extensions ?? 0;
                      })
                      .reduce<number>((prev, curr) => {
                        return prev + curr;
                      }, 0)}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: "light",
                      marginBottom: 2,
                    }}
                  >
                    {`⌀ Conversionrate (Upgrades/Test)`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: "normal",
                      marginBottom: 2,
                    }}
                  >
                    {(
                      summarizedDataByMonth
                        .map((v) => {
                          return v.conversion_rate ?? 0;
                        })
                        .reduce<number>((prev, curr) => {
                          return prev + curr;
                        }, 0) /
                      (summarizedDataByMonth?.length === 0
                        ? 1
                        : summarizedDataByMonth?.length)
                    ).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
                alignItems: "flex-start",
                padding: 5,
                margin: 10,
                justifyContent: "space-between",
              }}
            >
              {fromSubmitted !== null &&
                toSubmitted !== null &&
                summarizedDataByMonth.length > 0 && (
                  <Button
                    style={{ margin: 2 }}
                    variant="contained"
                    onClick={downloadMonthly}
                  >
                    {`Download (monatlicher Report)`}
                  </Button>
                )}
              {fromSubmitted !== null &&
                toSubmitted !== null &&
                summarizedDataByYear.length > 0 && (
                  <Button
                    style={{ margin: 2 }}
                    variant="contained"
                    onClick={downloadYearly}
                  >
                    {`Download (jährlicher Report)`}
                  </Button>
                )}
            </div>
          </div>
        )}
      {fromSubmitted !== null &&
        toSubmitted !== null &&
        summarizedDataByMonth.length > 0 && (
          <div
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              // backgroundColor: "#eee",
              borderWidth: 2,
              borderColor: "white",
              borderStyle: "solid",
              minWidth: "90%",
              flexGrow: 2,
              padding: 10,
              borderRadius: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                padding: 5,
                justifyContent: "space-evenly",
              }}
            >
              <Button
                variant={selcetedGraph === 0 ? `contained` : `outlined`}
                onClick={() => setSelectedGraph(0)}
              >
                {`Neue Lizenzen`}
              </Button>

              <Button
                variant={selcetedGraph === 1 ? `contained` : `outlined`}
                onClick={() => setSelectedGraph(1)}
              >
                {`Conversion`}
              </Button>
              <Button
                variant={selcetedGraph === 2 ? `contained` : `outlined`}
                onClick={() => setSelectedGraph(2)}
              >
                {`Verlängerungen`}
              </Button>
              <Button
                variant={selcetedGraph === 3 ? `contained` : `outlined`}
                onClick={() => setSelectedGraph(3)}
              >
                {`Gesamtlizenzen (Jahr)`}
              </Button>
            </div>
            <Line data={data} options={options} />
          </div>
        )}
    </>
  );
};

export default StatsView;
