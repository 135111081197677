import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Link, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGitlab, { gitlabAPI } from "../../hooks/gitlab/useGitlab";
import { Alert } from "@material-ui/lab";
import GitlabIssues from "../../assets/icons/gitlab/issues";
import GitlabTodos from "../../assets/icons/gitlab/todos";
import useLoading from "../../hooks/useLoading";
import LoadingWrapper from "../LoadingWrapper";
import { UserRole } from "../../firestore/models";
import withRole from "../../hoc/withRole";
import GitlabMergeRequests from "../../assets/icons/gitlab/merge_requests";

type GitlabUser = {
  id: number;
  name: string;
  username: string;
  state: string;
  avatar_url: string;
  web_url: string;
  is_admin: boolean;
  external: boolean;
};

const GitlabStatus = () => {
  const [disabled, setDisabled] = useState(
    process.env.NODE_ENV !== "production"
  );

  const gitlab = useGitlab();
  const { setLoading, setError, finallyLoading, loadingProps } = useLoading();
  const [me, setMe] = useState<GitlabUser>();

  const getMe = useCallback(() => {
    console.debug("GitlabStatus::getMe");
    setLoading(true, "Load Gitlab Status");
    gitlabAPI()({
      endpoint: "/user",
    })
      .then((res) => {
        if (res.data) {
          setMe(res.data);
        }
      })
      .catch(setError(getMe))
      .finally(finallyLoading);
  }, [setMe, setError, setLoading, finallyLoading]);

  useEffect(() => {
    console.debug("GitlabStatus::useEffect", [gitlab.status]);
    if (!disabled && gitlab.status === "authorized" && !loadingProps.error) {
      getMe();
    }
  }, [getMe, gitlab.status, disabled, loadingProps.error]);

  if (disabled) {
    return (
      <div>
        GitLabStatus <Button onClick={() => setDisabled(false)}>ENABLE</Button>
      </div>
    );
  }

  if (gitlab.status === "unauthorized" && gitlab.loginUrl) {
    return (
      <Tooltip title={"GitLab"}>
        <Button
          fullWidth
          color={"secondary"}
          variant="contained"
          href={gitlab.loginUrl}
          target={gitlab.loginUrl}
        >
          <FontAwesomeIcon title={"GitLab"} icon={["fab", "gitlab"]} />
          &nbsp;Connect
        </Button>
      </Tooltip>
    );
  }

  return (
    <LoadingWrapper {...loadingProps}>
      <div>
        {gitlab.status === "authorized" && !me && (
          <Alert severity="error">
            Error.{" "}
            <Link href="#" onClick={getMe}>
              Retry!
            </Link>
          </Alert>
        )}
        <Button
          fullWidth
          color={"primary"}
          variant={"contained"}
          href={"https://gitlab.webstollen.de"}
          target="_blank"
          style={{ margin: "10px 0" }}
        >
          <FontAwesomeIcon icon={["fab", "gitlab"]} title="GitLab" />
          &nbsp;GitLab
        </Button>
      </div>

      <ButtonGroup fullWidth>
        <Button
          color={"primary"}
          variant={"contained"}
          target="_blank"
          style={{ margin: "10px 0" }}
          href={`https://gitlab.webstollen.de/dashboard/issues?assignee_username=${me?.username}`}
        >
          <FontAwesomeIcon icon={GitlabIssues} title="GitLab Issues" />
        </Button>
        <Button
          color={"primary"}
          variant={"contained"}
          target="_blank"
          style={{ margin: "10px 0" }}
          href={`https://gitlab.webstollen.de/dashboard/merge_requests?assignee_username=${me?.username}`}
        >
          <FontAwesomeIcon
            icon={GitlabMergeRequests}
            title="GitLab Merge Requests"
          />
        </Button>
        <Button
          color={"primary"}
          variant={"contained"}
          target="_blank"
          style={{ margin: "10px 0" }}
          href={`https://gitlab.webstollen.de/dashboard/todos`}
        >
          <FontAwesomeIcon icon={GitlabTodos} title="GitLab Todos" />
        </Button>
      </ButtonGroup>
    </LoadingWrapper>
  );
};

export default withRole(UserRole.Developer)(GitlabStatus);
