import React, { useEffect, useState } from "react";
import {
    Button, Grid, Typography, Select, MenuItem, Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody, TextField, Tooltip, Popover, IconButton
} from "@material-ui/core";
import Input from "../../../../../components/Input";
import { DB } from "../../../../../firebase";
import { useFirestore } from "react-redux-firebase";
import DetailPage from "./DetailPageService";
import InfoIcon from "@material-ui/icons/Info";

import md5 from "md5";

type ServiceObject = {
    cServiceId: string,
    cServiceIdString: string,
    jTranslations: string,
    jAttributes: string,
    kCategory: number,
    keywords?: string[],
    cJtlConsentIds?: string[],
}

const ServicesAdd = () => {

    const db = DB().collection("plugin").doc("ws5_eucookie");

    const itemsPerPage = 30;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [serviceIdString, setServiceIdString] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [cookieInfo, setCookieInfo] = useState<string>("");
    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [category, setCategory] = useState<number>(1);
    const [jtlConsentIds, setJtlConsentIds] = useState<Array<string>>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [services, setServices] = useState<Array<ServiceObject>>([]);
    const firestore = useFirestore();

    const fetchServices = () => {
        const collectionRef = firestore
            .collection("plugin")
            .doc("ws5_eucookie")
            .collection("services");

        collectionRef
            .get()
            .then((querySnapshot) => {
                const servicePromises: Promise<ServiceObject>[] = [];

                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        const serviceData = doc.data();
                        const serviceId = doc.id;

                        const keywords = serviceData.keywords || [];
                        const jtlConsentIds = serviceData.cJtlConsentIds || [];

                        const documentWithId: ServiceObject = {
                            cServiceId: serviceId,
                            cServiceIdString: serviceData.cServiceIdString,
                            jTranslations: serviceData.jTranslations,
                            jAttributes: serviceData.jAttributes,
                            kCategory: serviceData.kCategory,
                            keywords: keywords,
                            cJtlConsentIds: jtlConsentIds,
                        };
                        servicePromises.push(Promise.resolve(documentWithId));
                    }
                });

                Promise.all(servicePromises)
                    .then((serviceArr) => {
                        setServices(serviceArr);
                        console.log("Services:", serviceArr);
                    })
                    .catch((error) => {
                        console.error("Error getting services:", error);
                    });
            })
            .catch((error) => {
                console.error("Error getting documents:", error);
            });
    };

    useEffect(() => {
        fetchServices();
    }, []);


    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);

    };
    const idChangeHandler = (event: any) => {
        const value = event.target.value;
        setServiceIdString(value);
    };

    const nameChangeHandler = (event: any) => {
        const value = event.target.value;
        setName(value);
    };

    const descriptionChangeHandler = (event: any) => {
        const value = event.target.value;
        setDescription(value);
    };

    const cookieInfoChangeHandler = (event: any) => {
        const value = event.target.value;
        setCookieInfo(value);
    }

    const keywordsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const keywordArray = value.split(",").map((keyword) => keyword.trim());
        setKeywords(keywordArray);
    };

    const categoryChangeHandler = (event: any) => {
        const value = event.target.value;
        setCategory(value);
    };

    const jtlConsentIdsChangeHandler = (event: any) => {
        const { value } = event.target;
        const jtlConsentIdsArray = value.split(",").map((jtlConsentId: string) => jtlConsentId.trim());
        setJtlConsentIds(jtlConsentIdsArray);
    }

    const saveDataServices = async (newData: ServiceObject, cServiceId: string) => {
        try {
            const { cServiceId, ...serviceData } = newData;
            await db.collection("services").doc(cServiceId).set(serviceData, { merge: true });
            fetchServices();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSave = () => {
        // Check if all fields are filled in
        if (
            serviceIdString &&
            name &&
            description &&
            category
        ) {
            const cServiceId = md5(serviceIdString);
            const newData: ServiceObject = {
                cServiceId: cServiceId,
                cServiceIdString: serviceIdString,
                kCategory: category,
                jAttributes: "{}",
                jTranslations: JSON.stringify({
                    name: { de: name },
                    description: { de: description },
                    cookieInfo: { de: cookieInfo }
                }),

            };
            if (keywords.length > 0) {
                newData.keywords = keywords;
            }
            if (jtlConsentIds.length > 0) {
                newData.cJtlConsentIds = jtlConsentIds;
            }
            console.log(cServiceId);
            console.log(newData);
            console.log(keywords);
            saveDataServices(newData, cServiceId);


            setServiceIdString("");
            setName("");
            setDescription("");
            setCookieInfo("");
            setKeywords([]);
            setCategory(0);
            setJtlConsentIds([]);
            setErrorMessage('');


        } else {
            setErrorMessage('Speichern fehlgeschlagen. Alle Felder ausfüllen. Keyword und Jtl-Consent-Ids nicht notwendig!');
        }

    };
    const [searchText, setSearchText] = useState('');


    const filteredData = services.filter((service) => {
        const nameMatches = service.cServiceIdString.toLowerCase().includes(searchText.toLowerCase());
        return nameMatches;
    });
    const data2 = services.slice(startIndex, endIndex);
    const data3 = filteredData.slice(startIndex, endIndex);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        setCurrentPage(1);
    };

    const [selectedItem, setSelectedItem] = useState<ServiceObject | null>(null);

    const handleRowClick = (item: ServiceObject) => {
        setSelectedItem(item);
        setSearchText("");
    };
    const handleGoBack = () => {
        setSelectedItem(null);
        fetchServices();
    };
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleInfo = (event: any) => {
        setPopoverOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverOpen(false);
    };

    return <>
        <Grid
            container
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
            spacing={1}
            className="w-full"
        >
            <Grid item container alignItems="center" xs={3}>
                <Label text="ServiceIdString:" />
                <Tooltip title="Erklärung zu den Quellentypen">
                    <IconButton onClick={handleInfo}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 16 }}>
                        Der ServiceIdString kann einfach der Name des Services sein.
                    </Typography>
                </Popover>
            </Grid>
            <Grid item xs={9}>
                <Input
                    type={"text"}
                    placeholder={"Id"}
                    onChange={idChangeHandler}
                    value={serviceIdString}
                />
            </Grid>
            <Grid item xs={3}>
                <Label text="Name: (deutsch)" />
            </Grid>
            <Grid item xs={9}>
                <Input
                    type={"text"}
                    placeholder={"Name"}
                    onChange={nameChangeHandler}
                    value={name}
                />
            </Grid>
            <Grid item xs={3}>
                <Label text="Beschreibung: (deutsch)" />
            </Grid>
            <Grid item xs={9}>
                <Input
                    type={"text"}
                    placeholder={"Beschreibung"}
                    onChange={descriptionChangeHandler}
                    value={description}
                />
            </Grid>
            <Grid item xs={3}>
                <Label text="CookieInfo: (deutsch)" />
            </Grid>
            <Grid item xs={9}>
                <Input
                    type={"text"}
                    placeholder={"CookieInfo"}
                    onChange={cookieInfoChangeHandler}
                    value={cookieInfo}
                />
            </Grid>
            <Grid item xs={3}>
                <Label text="Keywords: (ausschließlich, wenn script nicht erkannt wird und mit Komma trennen!)" />
            </Grid>
            <Grid item xs={9}>
                <Input
                    type={"text"}
                    placeholder={"Keywords"}
                    onChange={keywordsChangeHandler}
                    value={keywords.join(", ")}
                />
            </Grid>
            <Grid item xs={3}>
                <Label text="Kategorie:" />
            </Grid>
            <Grid item xs={9}>
                <Select
                    value={category}
                    label="Kategorie"
                    onChange={categoryChangeHandler}
                >
                    <MenuItem value={1}>Kein Cookie</MenuItem>
                    <MenuItem value={2}>Notwendig</MenuItem>
                    <MenuItem value={3}>Marketing</MenuItem>
                    <MenuItem value={4}>Analytics</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={3}>
                <Label text="JTL-Consent-Ids: (mit Komma trennen!)" />
                <Tooltip title="Das sind die IDs der JTL-Consent-Manger-Einträge, z.B. ws5_sendinblue_consent">
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={9}>
                <Input
                    type={"text"}
                    placeholder={"JTL-Consent-Ids"}
                    onChange={jtlConsentIdsChangeHandler}
                    value={jtlConsentIds.join(", ")}
                />
            </Grid>
            {errorMessage && (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div style={{ color: 'red', marginBottom: '30px', marginTop: "40px" }}>{errorMessage}</div>
                </div>
            )}

            <div className="flex w-full justify-around mb-6">
                <Button variant="contained" onClick={handleSave}>
                    Speichern
                </Button>
            </div>
            {!selectedItem && <div style={{ textAlign: 'right', float: "right", width: '100%' }}>
                <TextField
                    style={{
                        width: '300px',
                        fontSize: '20px',
                        marginBottom: "50px",
                    }}
                    label="Search"
                    value={searchText}
                    onChange={handleSearchInputChange}
                />
            </div>}

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {!selectedItem && <TableCell>kId</TableCell>}
                            <TableCell>ServiceIdString</TableCell>
                            <TableCell>ServiceId</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Beschreibung</TableCell>
                            <TableCell>CookieInfo</TableCell>
                            <TableCell>Keywords</TableCell>
                            <TableCell>Kategorie</TableCell>
                            <TableCell>JTL-Consent-Ids</TableCell>
                            <TableCell>Attribute (Json)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!selectedItem &&
                            // useEffect better solution Warning: Using UNSAFE_componentWillReceiveProps in strict mode is not recommended and may indicate bugs in your code. See https://reactjs.org/link/unsafe-component-lifecycles for details.W
                            (searchText ?
                                data3 : data2).map((data, index) => {
                                    const translations = JSON.parse(data.jTranslations);
                                    const dataIndex = startIndex + index;
                                    return (
                                        <TableRow key={index} onClick={() => handleRowClick(data)} style={{ cursor: 'pointer' }}>
                                            <TableCell>{dataIndex}</TableCell>
                                            <TableCell>{data.cServiceIdString}</TableCell>
                                            <TableCell>{data.cServiceId}</TableCell>
                                            <TableCell>{translations.name.de}</TableCell>
                                            <TableCell>{translations.description.de}</TableCell>
                                            <TableCell>{translations.cookieInfo ? (translations.cookieInfo.de.slice(0, 200) + "...") : ""}</TableCell>
                                            <TableCell>
                                                {data.keywords !== undefined && data.keywords.length > 0
                                                    ? data.keywords.join(", ")
                                                    : "-"}
                                            </TableCell>
                                            <TableCell>{data.kCategory}</TableCell>
                                            <TableCell>
                                                {
                                                    data.cJtlConsentIds !== undefined && data.cJtlConsentIds.length > 0
                                                        ? data.cJtlConsentIds.join(", ")
                                                        : "-"
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {data?.jAttributes ?? '{}'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        }
                    </TableBody>
                    {selectedItem && <DetailPage selectedItem={selectedItem} />}

                </Table>
            </TableContainer>
            {!selectedItem && <div>
                <Button
                    variant="contained"
                    disabled={currentPage === 1}
                    onClick={handlePreviousPage}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    disabled={endIndex >= services.length}
                    onClick={handleNextPage}
                >
                    Next
                </Button>
            </div>}
            {selectedItem && <Button
                variant="contained"
                onClick={handleGoBack}
            >
                Go Back
            </Button>}
        </Grid >
    </>
}

const Label = ({ text }: { text: string }) => {
    return (
        <Typography
            key={text}
            style={{
                fontSize: 18,
                fontWeight: "lighter",
            }}
        >
            {text}
        </Typography>
    );
};

export default ServicesAdd;