import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ICON_STYLE } from "../../../../../utils/constants";
import { GREY, SUCCESS } from "../../../../../constants/colors";
import dashbar from "../../../../../firebase/dashbar";
import {
  checkAuthorization,
  checkData,
  checkPluginVersion,
  checkReachable,
} from "./components/ShopResults/helper";
import { ShopResultTabel } from "./components/ShopResults/Table";
import {
  checkDeviceVersion,
  checkLastActive,
  listPlatforms,
} from "./components/UserResults/helper";
import { UserResultTabel } from "./components/UserResults/Table";
import "./modal.css";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "80%",
  height: "80%",
  "overflow-y": "scroll",
  bgcolor: GREY[800],
  borderRadius: 20,
  boxShadow: 24,
  p: 4,
};

enum LOADING_STATE {
  INITIAL,
  LOAD_ACCOUNT_DATA,
  LOAD_SHOP_DATA,
  LOAD_USER_DATA,
  LOAD_BOOKING_DATA,
  LOAD_DASHBOARD_DATA,
  CHECK_SHOP_CONNECTION,
  CHECK_USER,
  CHECK_SHOP_CONFIGURATION,
}

const DiagnoseInfo = ({
  company,
  users,
}: {
  company: Record<string, any>;
  users: Array<Record<string, any>> | null;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [config, setConfig] = useState<Record<string, any> | null>(null);
  const [loadingState, setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITIAL
  );

  //Data
  const [accountData, setAccountData] = useState<
    Record<string, any> | null | undefined
  >(undefined);
  const [shopData, setShopData] = useState<
    Array<Record<string, any>> | null | undefined
  >(undefined);
  const [userData, setUserData] = useState<
    Array<Record<string, any>> | null | undefined
  >(undefined);
  const [bookingData, setBookingData] = useState<
    Array<Record<string, any>> | null | undefined
  >(undefined);
  const [dashboardData, setDashboardData] = useState<
    Array<Record<string, any>> | null | undefined
  >(undefined);

  //Results
  const [shopResults, setShopResults] = useState<
    Array<Record<string, any>> | null | undefined
  >(undefined);
  const [userResults, setUserResults] = useState<
    Array<Record<string, any>> | null | undefined
  >(undefined);

  const startDiagnose = () => {
    setModalOpen(true);
    setLoadingState(LOADING_STATE.LOAD_ACCOUNT_DATA);
    setAccountData(company);
  };

  const cancelDiagnose = () => {
    setModalOpen(false);
    setLoadingState(LOADING_STATE.INITIAL);
    setAccountData(undefined);
    setShopData(undefined);
    setUserData(undefined);
    setBookingData(undefined);
    setDashboardData(undefined);
    setShopResults(undefined);
  };

  useEffect(() => {
    dashbar()
      .db.collection(`config`)
      .doc(`general`)
      .get()
      .then((res) => setConfig({ ...res.data() }));
  }, []);

  useEffect(() => {
    if (
      loadingState === LOADING_STATE.LOAD_ACCOUNT_DATA &&
      accountData !== null
    ) {
      setLoadingState(LOADING_STATE.LOAD_SHOP_DATA);
    }
  }, [loadingState, accountData]);

  useEffect(() => {
    const loadShops = async () => {
      //Load Shop data
      const shops = await dashbar()
        .db.collection(`company/${company?.id}/shop`)
        .get();
      const shopArray: Array<Record<string, any>> = [];
      shops.forEach((shop) => {
        shopArray.push({ ...shop.data(), id: shop.id });
      });
      setShopData(shopArray);
      setLoadingState(LOADING_STATE.LOAD_USER_DATA);
    };
    if (
      loadingState === LOADING_STATE.LOAD_SHOP_DATA &&
      shopData === undefined
    ) {
      loadShops();
    }
  }, [loadingState, shopData]);

  useEffect(() => {
    if (loadingState === LOADING_STATE.LOAD_USER_DATA) {
      if (!userData) {
        setUserData(users);
        setLoadingState(LOADING_STATE.LOAD_BOOKING_DATA);
      } else {
        setLoadingState(LOADING_STATE.LOAD_BOOKING_DATA);
      }
    }
  }, [loadingState, userData]);

  useEffect(() => {
    const loadBooking = async () => {
      //Load Shop data
      const bookings = await dashbar()
        .db.collection(`company/${company?.id}/booking`)
        .get();
      const bookingArray: Array<Record<string, any>> = [];
      bookings.forEach((booking) => {
        bookingArray.push({ ...booking.data(), id: booking.id });
      });
      setBookingData(bookingArray);
      setLoadingState(LOADING_STATE.LOAD_DASHBOARD_DATA);
    };
    if (
      loadingState === LOADING_STATE.LOAD_BOOKING_DATA &&
      bookingData === undefined
    ) {
      loadBooking();
    }
  }, [loadingState, bookingData]);

  useEffect(() => {
    const loadDashboards = async () => {
      //Load Dashboard data
      const dashboards = await dashbar()
        .db.collection(`company/${company?.id}/dashboard`)
        .get();
      const dashboardArray: Array<Record<string, any>> = [];
      dashboards.forEach((dashboard) => {
        dashboardArray.push({ ...dashboard.data(), id: dashboard.id });
      });
      setDashboardData(dashboardArray);
      setLoadingState(LOADING_STATE.CHECK_SHOP_CONNECTION);
    };
    if (
      loadingState === LOADING_STATE.LOAD_DASHBOARD_DATA &&
      dashboardData === undefined
    ) {
      loadDashboards();
    }
  }, [loadingState, dashboardData]);

  useEffect(() => {
    const checkShops = async () => {
      if (!shopData || shopData === null) {
        return;
      }
      const shopResultArray: Array<Record<string, any>> = [];
      for (const shop of shopData) {
        const reachableResult = await checkReachable(shop);
        const authResult = await checkAuthorization(shop);
        const dataResult = await checkData(shop);
        const pluginVersionResult = checkPluginVersion(shop, config);
        shopResultArray.push({
          domain: shop.domain ?? `n/a`,
          system: shop.platform?.name ?? `n/a`,
          plugin_version: shop.platform?.pluginVersion ?? `n/a`,
          results: [
            reachableResult,
            authResult,
            dataResult,
            pluginVersionResult,
          ],
        });
      }
      if (shopResultArray.length > 0) {
        setShopResults(shopResultArray);
      } else {
        setShopResults(null);
      }
      setLoadingState(LOADING_STATE.CHECK_USER);
    };
    if (loadingState === LOADING_STATE.CHECK_SHOP_CONNECTION) {
      checkShops();
    }
  }, [loadingState, shopData]);

  useEffect(() => {
    const checkUser = async () => {
      if (!userData || userData === null) {
        return;
      }
      console.log(`Check user`);
      const userResultArray: Array<Record<string, any>> = [];
      for (const user of userData) {
        const oldVersionResult = checkDeviceVersion(user, config);
        const platformsResult = listPlatforms(user);
        const lastActiveResult = checkLastActive(user);
        userResultArray.push({
          firstname: user.firstName ?? `n/a`,
          lastname: user.lastName ?? `n/a`,
          email: user.email ?? `n/a`,
          id: user.id ?? `n/a`,
          results: [oldVersionResult, platformsResult, lastActiveResult],
        });
      }
      if (userResultArray.length > 0) {
        setUserResults(userResultArray);
      } else {
        setUserResults(null);
      }
      setLoadingState(LOADING_STATE.CHECK_SHOP_CONFIGURATION);
    };
    if (loadingState === LOADING_STATE.CHECK_USER) {
      console.log(`USers`, userData);
      checkUser();
    }
  }, [loadingState, userData]);

  useEffect(() => {
    const checkShops = async () => {
      if (!shopData || shopData === null) {
        return;
      }
      console.log(shopResults);
    };
    if (loadingState === LOADING_STATE.CHECK_SHOP_CONFIGURATION) {
      checkShops();
    }
  }, [loadingState, shopData]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={3} alignContent="center" alignItems="center">
          <Typography
            style={{
              color: "#EEE",
              fontSize: TITLE_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: TITLE_TEXT.WEIGHT,
            }}
          >
            {`Vollständige Diagnose:`}
          </Typography>
        </Grid>
        <Grid item xs={9} alignContent="center" alignItems="center">
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {`Überprüft den dash.bar Account auf mögliche Probleme bezüglich der Konfiguration und führt Verbindungstests durch`}
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9} alignContent="center" alignItems="center">
          <Button
            style={{ marginTop: 8 }}
            onClick={startDiagnose}
            variant="outlined"
          >{`Diagnose starten`}</Button>
        </Grid>
      </Grid>
      <Modal open={modalOpen} onClose={cancelDiagnose}>
        <Box sx={modalStyle} className="modalBox">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Typography
              style={{
                color: "#EEE",
                fontSize: 20,
                fontFamily: "Roboto",
                fontWeight: 500,
              }}
            >
              {`Diagnose [${company.id}]`}
            </Typography>
          </div>
          <Grid container spacing={0}>
            <Grid item xs={3} alignContent="center" alignItems="center">
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: DEFAULT_TEXT.SIZE,
                  fontFamily: "Roboto",
                  fontWeight: DEFAULT_TEXT.WEIGHT,
                }}
              >
                {`Account Daten laden`}
              </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center" alignItems="center">
              {loadingState > LOADING_STATE.LOAD_ACCOUNT_DATA ? (
                <FontAwesomeIcon
                  color={SUCCESS.DARK}
                  icon={[ICON_STYLE, "check-circle"]}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "spinner-scale"]}
                  spinPulse
                />
              )}
            </Grid>
            <Grid item xs={3} alignContent="center" alignItems="center">
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: DEFAULT_TEXT.SIZE,
                  fontFamily: "Roboto",
                  fontWeight: DEFAULT_TEXT.WEIGHT,
                }}
              >
                {`Shop Daten laden`}
              </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center" alignItems="center">
              {loadingState > LOADING_STATE.LOAD_SHOP_DATA ? (
                <FontAwesomeIcon
                  color={SUCCESS.DARK}
                  icon={[ICON_STYLE, "check-circle"]}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "spinner-scale"]}
                  spinPulse
                />
              )}
            </Grid>
            <Grid item xs={3} alignContent="center" alignItems="center">
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: DEFAULT_TEXT.SIZE,
                  fontFamily: "Roboto",
                  fontWeight: DEFAULT_TEXT.WEIGHT,
                }}
              >
                {`User Daten laden`}
              </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center" alignItems="center">
              {loadingState > LOADING_STATE.LOAD_USER_DATA ? (
                <FontAwesomeIcon
                  color={SUCCESS.DARK}
                  icon={[ICON_STYLE, "check-circle"]}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "spinner-scale"]}
                  spinPulse
                />
              )}
            </Grid>
            <Grid item xs={3} alignContent="center" alignItems="center">
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: DEFAULT_TEXT.SIZE,
                  fontFamily: "Roboto",
                  fontWeight: DEFAULT_TEXT.WEIGHT,
                }}
              >
                {`Buchungen laden`}
              </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center" alignItems="center">
              {loadingState > LOADING_STATE.LOAD_BOOKING_DATA ? (
                <FontAwesomeIcon
                  color={SUCCESS.DARK}
                  icon={[ICON_STYLE, "check-circle"]}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "spinner-scale"]}
                  spinPulse
                />
              )}
            </Grid>
            <Grid item xs={3} alignContent="center" alignItems="center">
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: DEFAULT_TEXT.SIZE,
                  fontFamily: "Roboto",
                  fontWeight: DEFAULT_TEXT.WEIGHT,
                }}
              >
                {`Dashboards laden`}
              </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center" alignItems="center">
              {loadingState > LOADING_STATE.LOAD_DASHBOARD_DATA ? (
                <FontAwesomeIcon
                  color={SUCCESS.DARK}
                  icon={[ICON_STYLE, "check-circle"]}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "spinner-scale"]}
                  spinPulse
                />
              )}
            </Grid>
            <Grid item xs={3} alignContent="center" alignItems="center">
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: DEFAULT_TEXT.SIZE,
                  fontFamily: "Roboto",
                  fontWeight: DEFAULT_TEXT.WEIGHT,
                }}
              >
                {`Shop-Verbindung prüfen`}
              </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center" alignItems="center">
              {loadingState > LOADING_STATE.CHECK_SHOP_CONNECTION ? (
                <FontAwesomeIcon
                  color={SUCCESS.DARK}
                  icon={[ICON_STYLE, "check-circle"]}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "spinner-scale"]}
                  spinPulse
                />
              )}
            </Grid>
            <Grid item xs={3} alignContent="center" alignItems="center">
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: DEFAULT_TEXT.SIZE,
                  fontFamily: "Roboto",
                  fontWeight: DEFAULT_TEXT.WEIGHT,
                }}
              >
                {`User prüfen`}
              </Typography>
            </Grid>
            <Grid item xs={9} alignContent="center" alignItems="center">
              {loadingState > LOADING_STATE.CHECK_USER ? (
                <FontAwesomeIcon
                  color={SUCCESS.DARK}
                  icon={[ICON_STYLE, "check-circle"]}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "spinner-scale"]}
                  spinPulse
                />
              )}
            </Grid>
            {shopResults && shopResults !== null && (
              <Grid item xs={12} alignContent="center" alignItems="center">
                <ShopResultTabel shopResultData={shopResults} />
              </Grid>
            )}
            {userResults && userResults !== null && (
              <Grid item xs={12} alignContent="center" alignItems="center">
                <UserResultTabel userResultData={userResults} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DiagnoseInfo;
