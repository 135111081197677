import { firestore } from "firebase-admin";

// export enum UserRole {
//   Intranet = "intranet",
//   Operational = "operational", //Insight in business operations
//   Admin = "admin", //Access to all operations excluding business operations
//   DevAdmin = "dev_admin",
//   Developer = "developer",
//   ShortURL = "shorturl",
//   Plugins = "plugins",
//   Licences = "licences",
//   Webhooks = "webhooks",
//   DashBar = "dashbar",
//   Abocloud = "abocloud",
//   Services = "services",
//   Extern = "extern",
//   LunchUser = "lunch_user",
//   LunchAdmin = "lunch_admin",
// }
export enum UserRole {
  Operational = "operational", //Access to everything including operational tasks
  Admin = "admin", //Access to everything except operational tasks
  Developer = "developer", //Access to Plugins and Templates
  ShortURL = "shorturl", //Access to shorturl
  Licences = "licences", //Access to plugin extension licences
  Webhooks = "webhooks", //Access to webhooks
  Dashbar = "dashbar", //Access to dash.bar
  Abocloud = "abocloud", //Access to abocloud
  LunchUser = "lunch_user",
  LunchAdmin = "lunch_admin",
}

/* Collection: "user" */
export type User = {
  id?: string;
  roles: UserRole[];
  firstname?: string;
  lastname?: string;
  email?: string;
  prn?: string;
};

export type DeveloperSettings = {
  gitlab?: {
    oauth?: OAuth;
  };
};

export type OAuth = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: string;
  expiresAt?: firestore.Timestamp;
  createdAt: firestore.Timestamp;
  host: string;
};
