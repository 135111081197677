import React, { useCallback, useState } from "react";
import IDLabel from "../../../../../components/IDLabel";
import { Link } from "@material-ui/core";
import PageContainer from "../../../../../components/PageContainer";
import { IShop } from "@dash.bar/types";
import { Alert } from "@material-ui/lab";
import Table from "../../../../../components/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeShopRequest } from "../../../../../utils/funcs";
import { useSnackbar } from "notistack";
import { ICON_STYLE } from "../../../../../utils/constants";

type TabInfoProps = {
  shop: IShop;
  companyID?: string;
};

const TabInfo = ({ shop, companyID }: TabInfoProps) => {
  const [shopInfo, setShopInfo] = useState<ShopInfoType | undefined | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();

  const loadShopInfos = useCallback(() => {
    setShopInfo(undefined);
    makeShopRequest<ShopInfoType>(shop, {
      request: "Analytics",
      widget: {
        info: ["plugins", "versions", "ping", "php"],
      },
    })
      .then((result) => {
        if (result.status === "ok") setShopInfo(result);
        else
          enqueueSnackbar(
            `API Error: (${result?.error}) ${result?.error_message}`,
            { variant: "error" }
          );
      })
      .catch((e) => {
        enqueueSnackbar(`${e}`, { variant: "error" });
        setShopInfo(null);
      });
  }, [shop, enqueueSnackbar]);

  const phpInfo = shopInfo?.widget?.info?.php?.php;
  return (
    <>
      <PageContainer title="Info" heading={3} bgColor="bg-gray-800">
        <dl className="horizontal center">
          {companyID && (
            <>
              <dt>Company:</dt>
              <dd>
                <IDLabel
                  label={companyID}
                  to={`/dashbar/companies/${companyID}`}
                />
              </dd>
            </>
          )}
          <dt>Domain:</dt>
          <dd>
            <Link href={`https://www.${shop?.domain}`}>{shop?.domain}</Link>
          </dd>
          <dt>Currency:</dt>
          <dd dangerouslySetInnerHTML={{ __html: `${shop?.currency}` }} />
          <dt>Endpoint:</dt>
          <dd>{shop?.endpoint}</dd>
          <dt>ClientID:</dt>
          <dd className="truncate">
            <code>{shop?.clientToken}</code>
          </dd>
          <dt>SecretToken:</dt>
          <dd className="truncate">
            <code>{shop?.secretToken}</code>
          </dd>
          <dt>Platform:</dt>
          <dd>{shop?.platform?.name}</dd>
          <dt>Shop Version:</dt>
          <dd>{shop?.platform?.version}</dd>
          <dt>PHP Version:</dt>
          <dd>{shop?.platform?.phpVersion}</dd>
          <dt>MySQL Version:</dt>
          <dd>{shop?.platform?.mysqlVersion}</dd>
          <dt>SDK Version:</dt>
          <dd>{shop?.platform?.sdkVersion}</dd>
          <dt>Plugin Version:</dt>
          <dd>{shop?.platform?.pluginVersion}</dd>
        </dl>
      </PageContainer>

      <PageContainer
        bgColor="bg-gray-800"
        title={"Shop Info"}
        onRefresh={loadShopInfos}
        loading={shopInfo === undefined}
      >
        {!shopInfo && (
          <Alert severity="warning">Click refresh to load Shop Infos.</Alert>
        )}
        <dl className="horizontal center">
          {shopInfo?.widget?.info?.ping && (
            <>
              <dt>Ping:</dt>
              <dd>
                <code>{shopInfo?.widget?.info?.ping}s</code>
              </dd>
            </>
          )}

          {shopInfo?.widget?.info?.versions?.shop && (
            <>
              <dt>Shop Version:</dt>
              <dd>
                <code>{shopInfo?.widget?.info?.versions?.shop}</code>
              </dd>
            </>
          )}

          {shopInfo?.widget?.info?.versions?.versions?.php && (
            <>
              <dt>PHP Version:</dt>
              <dd>
                <code>{shopInfo?.widget?.info?.versions?.versions?.php}</code>
              </dd>
            </>
          )}

          {shopInfo?.widget?.info?.versions?.versions?.sql && (
            <>
              <dt>MySQL Version:</dt>
              <dd>
                <code>{shopInfo?.widget?.info?.versions?.versions?.sql}</code>
              </dd>
            </>
          )}
        </dl>

        {shopInfo?.widget?.info?.plugins?.length && (
          <>
            <h4>Plugins</h4>
            <Table
              striped
              header={{
                name: { content: "Name" },
                version: { content: "Version" },
                author: { content: "Author" },
                status: { content: "Active" },
              }}
            >
              {shopInfo.widget.info.plugins.map((plugin) => (
                <tr>
                  <td>
                    {plugin.cName} ({plugin.cPluginID})
                  </td>
                  <td>{plugin.nVersion}</td>
                  <td>
                    <Link
                      href={plugin.cURL}
                      target="_blank"
                      referrerPolicy="no-referrer"
                    >
                      {plugin.cAutor}
                    </Link>
                  </td>
                  <td className="text-center">
                    {parseInt(plugin.nStatus) === 2 && (
                      <FontAwesomeIcon
                        className="text-green-600"
                        icon={[ICON_STYLE, "check-circle"]}
                      />
                    )}
                    {parseInt(plugin.nStatus) === 1 && (
                      <FontAwesomeIcon
                        className="text-red-600"
                        icon={[ICON_STYLE, "times-circle"]}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </Table>
          </>
        )}

        {phpInfo && (
          <>
            <h4>PHP Info</h4>
            <dl className="horizontal center">
              <dt>Allow URL fopen:</dt>
              <dd>
                {phpInfo?.Core.allow_url_fopen[0] === "On" ? (
                  <FontAwesomeIcon
                    className="text-green-600"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>

              <dt>Display Errors:</dt>
              <dd>
                {phpInfo?.Core.display_errors[0] === "On" ? (
                  <FontAwesomeIcon
                    className="text-green-600"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>

              <dt>Error Reporting:</dt>
              <dd>
                <code>{phpInfo?.Core.error_reporting[0]}</code>
              </dd>

              <dt>Memory Limit:</dt>
              <dd>
                <code>{phpInfo?.Core.memory_limit[0]}</code>
              </dd>

              <dt>Open BaseDir:</dt>
              <dd>
                <code>{phpInfo?.Core.open_basedir[0]}</code>
              </dd>

              <dt>APCu:</dt>
              <dd>
                {phpInfo?.apcu && phpInfo?.apcu["apc.enabled"][0] === "On" ? (
                  <FontAwesomeIcon
                    className="text-green-600"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>

              <dt>Zend OpCache:</dt>
              <dd>
                {phpInfo["Zend OPcache"] &&
                phpInfo["Zend OPcache"]["opcache.enable"][0] === "On" ? (
                  <FontAwesomeIcon
                    className="text-green-600"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>

              <dt>cURL:</dt>
              <dd>
                {phpInfo?.curl &&
                phpInfo?.curl["cURL support"] === "enabled" ? (
                  <>
                    <FontAwesomeIcon
                      className="text-green-600"
                      icon={[ICON_STYLE, "check-circle"]}
                    />{" "}
                    ({phpInfo.curl["cURL Information"]})
                  </>
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>

              <dt>OpenSSL:</dt>
              <dd>
                {phpInfo?.openssl &&
                phpInfo?.openssl["OpenSSL support"] === "enabled" ? (
                  <>
                    <FontAwesomeIcon
                      className="text-green-600"
                      icon={[ICON_STYLE, "check-circle"]}
                    />{" "}
                    ({phpInfo.openssl["OpenSSL Library Version"]})
                  </>
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>

              <dt>XDebug:</dt>
              <dd>
                {phpInfo.xdebug && phpInfo.xdebug["IDE Key"] ? (
                  <>
                    <FontAwesomeIcon
                      className="text-green-600"
                      icon={[ICON_STYLE, "check-circle"]}
                    />{" "}
                    ({phpInfo["xdebug"]["Version"]})
                  </>
                ) : (
                  <FontAwesomeIcon
                    className="text-red-600"
                    icon={[ICON_STYLE, "times-circle"]}
                  />
                )}
              </dd>
            </dl>
          </>
        )}
      </PageContainer>
    </>
  );
};

export default TabInfo;

type ShopInfoType = {
  status: string;
  error?: string;
  error_message?: string;
  widget?: {
    info: {
      plugins?: Array<{
        bBootstrap: string;
        cAutor: string;
        cBeschreibung: string;
        cFehler: string;
        cIcon: string;
        cLizenz: string;
        cLizenzKlasse: string;
        cLizenzKlasseName: string;
        cName: string;
        cPluginID: string;
        cURL: string;
        cVerzeichnis: string;
        dErstellt: string;
        dInstalliert: string;
        kPlugin: string;
        nPrio: string;
        nStatus: string;
        nVersion: string;
        nXMLVersion: string;
      }>;
      ping?: number;
      php?: {
        php?: Record<string, any>;
      };
      versions?: {
        shop?: number | string;
        versions?: {
          php?: string;
          sql?: string;
        };
      };
    };
  };
};
