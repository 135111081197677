import React from "react";
import {Button} from "@material-ui/core";

type Props = {
    error?: Error
    resetError: () => void
};

const FallbackComponent = (props: Props) => (
    <div>
        <h3>Ooops!</h3>
        <p>There is an error!</p>
        <p>{props.error?.message ?? 'unknown error'}</p>
        <Button onClick={props.resetError}>Try again!</Button>
    </div>
)

export default FallbackComponent;