import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

const SponsorEdit = ({
  setSponsorFile,
  deleteImage,
  sponsor,
  setSponsor,
}: {
  setSponsorFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  deleteImage: () => Promise<void>;
  sponsor: string | null;
  setSponsor: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [reportConfigImage, setReportConfigImage] = useState<File>();
  const [reportConfigImageUrl, setReportConfigImageUrl] = useState<
    string | undefined
  >("https://ws-cdn.de/marketing/dashbar/reports/sponsor");

  useEffect(() => {
    if (reportConfigImage) {
      const objectUrl = URL.createObjectURL(reportConfigImage);
      setReportConfigImageUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [reportConfigImage]);

  const onExtensionConfigImageChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setReportConfigImage(e.currentTarget?.files[0]);
      setSponsorFile(e.currentTarget?.files[0]);
    }
  };

  const onSponsorChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSponsor(e.target.value);
  };

  const deleteSponsorImage = async () => {
    if (
      window.confirm(
        `Really want to delete Sponsor image? No Sponsor will be shown in reports`
      )
    ) {
      await deleteImage();
      setReportConfigImageUrl(undefined);
    }
  };

  return (
    <Card
      style={{
        width: "100%",
        height: "150",
        borderRadius: 15,
        backgroundColor: "white",
        position: "relative",
      }}
      onClick={() => {}}
    >
      <CardContent>
        <div
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            flex: 1,
          }}
        >
          <img
            style={{ maxHeight: 100, margin: "auto" }}
            src={reportConfigImageUrl}
            alt={"Sponsor Logo"}
            loading="lazy"
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            flex: 1,
          }}
        >
          <input
            key={`sponsor`}
            type={"text"}
            value={sponsor || ""}
            onChange={onSponsorChanged}
            placeholder={"Sponsor"}
            style={{
              padding: 5,
              width: "50%",
              borderRadius: 5,
              marginBottom: 0,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "black",
            }}
          />
        </div>
      </CardContent>
      <CardActions>
        <CardActions>
          <input
            accept="image/*"
            type="file"
            ref={inputFile ? inputFile : null}
            onChange={onExtensionConfigImageChanged}
            style={{ display: "none" }}
          />
          <Button
            size="small"
            variant="contained"
            className="bg-blue-700"
            color="primary"
            onClick={() => {
              if (inputFile?.current) inputFile?.current?.click();
            }}
          >
            Ändern
          </Button>
          <Button
            size="small"
            variant="outlined"
            className="bg-blue-700"
            color="primary"
            onClick={() => {
              deleteSponsorImage();
            }}
          >
            Löschen
          </Button>
        </CardActions>
      </CardActions>
    </Card>
  );
};

export default SponsorEdit;
