import React from "react";
import { Route, Routes } from "react-router-dom";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";
import Dashboard from "./Dashboard";

const APIModule = () => {
  return (
    <>
      <Routes>
        <Route path={`/`} element={<Dashboard />} />
      </Routes>
    </>
  );
};

export default withRole(UserRole.Admin)(APIModule);
