import { Typography } from "@material-ui/core";
import React from "react";
import { useCollection } from "../../../../../firestore/hooks";
import KeyRow from "./KeyRow";

const KeyView = () => {
  const keys = useCollection<Record<string, any>>("config/api_plugin/apikeys");

  return (
    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
        }}
      >
        Keys für die Developer-API
      </Typography>
      {keys &&
        Object.values(keys).map((key) => {
          return (
            <KeyRow
              key={key.id}
              apikey={key.apikey}
              id={key.id}
              name={key.name}
              operations={key.operations}
              scopes={key.scopes}
              affId={key?.affiliateId}
            />
          );
        })}
    </div>
  );
};

export default KeyView;
