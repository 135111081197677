import React, { useEffect, useState, ChangeEvent } from "react";
import {
  Button,
  Tooltip,
  Grid,
  Typography,
  Select,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  InputLabel,
  IconButton,
  Popover,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useFirestore } from "react-redux-firebase";
import DetailPageSource from "./DetailPageSource";
import md5 from "md5";

type SourceObject = {
  cType: string;
  cParentServiceIdString: string;
  kParentCategory: number;
  cSource: string;
  cSourceHash: string;
  jAttributes: string;
  sourceId: string;
  serviceId: string;
};

const SourcesAdd = () => {
  const itemsPerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState<string>("");
  const [parentServiceIdString, setParentServiceIdString] =
    useState<string>("");
  const [code, setCode] = useState<string>("");
  const [serviceId, setServiceId] = useState<string>("");
  const [services, setServices] = useState<
    { cServiceId: string; cServiceIdString: string }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<SourceObject | null>(null);

  const handleInfoClick = () => {
    // Hier kannst du die Logik einfügen, um die Erklärung anzuzeigen
  };
  const handleTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };
  const handleParentServiceIdStringChange = (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    for (let i = 0; i < services.length; i++) {
      if (event.target.value === services[i].cServiceIdString) {
        setServiceId(services[i].cServiceId);
        setParentServiceIdString(services[i].cServiceIdString);
        console.log(serviceId);
        break;
      }
    }
  };
  const handleCodeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCode(event.target.value as string);
  };

  const [sources, setSources] = useState<Array<SourceObject>>([]);
  const firestore = useFirestore();

  const fetchSources = () => {
    const collectionRef = firestore
      .collection("plugin")
      .doc("ws5_eucookie")
      .collection("services");

    collectionRef
      .get()
      .then((querySnapshot) => {
        const promises: Promise<any>[] = [];

        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const serviceData = doc.data();
            const serviceId = doc.id;
            const category = serviceData.kCategory;
            const parentServiceIdString = serviceData.cServiceIdString;

            const sourcesRef = doc.ref.collection("sources");
            const promise = sourcesRef.get().then((sourcesSnapshot) => {
              const sourcesData: any[] = [];

              sourcesSnapshot.forEach((sourceDoc) => {
                if (sourceDoc.exists) {
                  const sourceData = sourceDoc.data();
                  const sourceId = sourceDoc.id;
                  sourcesData.push({
                    ...sourceData,
                    sourceId: sourceId,
                    serviceId: serviceId,
                  });
                }
              });

              return sourcesData.map((sourceData) => ({
                cType: sourceData.cType,
                cParentServiceIdString: parentServiceIdString,
                kParentCategory: category,
                cSource: sourceData.cSource,
                cSourceHash: sourceData.cSourceHash,
                jAttributes: sourceData.jAttributes,
                sourceId: sourceData.sourceId,
                serviceId: sourceData.serviceId,
              }));
            });

            promises.push(promise);
          }
        });

        Promise.all(promises)
          .then((sourceArr) => {
            const flattenedSources = sourceArr.flat();
            setSources(flattenedSources);
            console.log("Sources:", flattenedSources);
          })
          .catch((error) => {
            console.error("Error retrieving sources:", error);
          });
      })
      .catch((error) => {
        console.error("Error retrieving documents:", error);
      });
  };

  useEffect(() => {
    fetchSources();
  }, []);

  useEffect(() => {
    const collectionRef = firestore
      .collection("plugin")
      .doc("ws5_eucookie")
      .collection("services");

    collectionRef
      .get()
      .then((querySnapshot) => {
        const servicesData: { cServiceId: string; cServiceIdString: string }[] =
          [];

        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const serviceData = doc.data();
            const serviceId = doc.id;
            const serviceIdString = serviceData.cServiceIdString;
            const service = {
              cServiceId: serviceId,
              cServiceIdString: serviceIdString,
            };
            servicesData.push(service);
          }
        });

        setServices(servicesData);
        console.log("Services:", servicesData);
      })
      .catch((error) => {
        console.error("Error getting services:", error);
      });
  }, []);

  async function normalizeAndOrHashSource(
    sourceString: string,
    hash = true,
    replace = true,
    externalScript = false,
    iframe = false,
    HttpRequest = false
  ): Promise<string> {
    const formData = new FormData();
    formData.append("sourceString", sourceString);
    formData.append("hash", hash.toString());
    formData.append("replace", replace.toString());
    formData.append("externalScript", externalScript.toString());
    formData.append("iframe", iframe.toString());
    formData.append("HttpRequest", HttpRequest.toString());

    try {
      const response = await fetch('https://current.dev.ws-url.de/plugins/norm.php', {
        method: "POST",
        body: formData
      });
      const data = await response.json();
      return data.hash;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Re-throw the error after logging it
    }
  }

  // das wurde nur einmal benutzt, um die Sources einmal durch den NormalizundHasher zu jagen.
  async function oneTimeHashEverythingAgain() {
    console.log('starting hash');
    const services = firestore.collection("plugin/ws5_eucookie/services");

    const snapshot = await services.get();

    for (const serviceSnap of snapshot.docs) {
      const serviceId = serviceSnap.id;
      const sourcesRef = services.doc(serviceId).collection('sources');
      const sourceSnap = await sourcesRef.get();
      for (const sourceRef of sourceSnap.docs) {
        const source = sourceRef.data();
        const sourceId = sourceRef.id;
        const formData = new FormData();
        formData.append('sourceString', source.cSource);
        formData.append('hash', 'true');
        formData.append('replace', 'true');

        if (source.cType === 'script_external') {
          formData.append('externalScript', 'true');
        } else {
          formData.append('externalScript', 'false');
        }

        if (source.cType === 'iframe') {
          formData.append('iframe', 'true');
        } else {
          formData.append('iframe', 'false');
        }

        // does not matter
        formData.append('HttpRequest', 'true');

        try {
          let response = await fetch('https://current.dev.ws-url.de/plugins/norm.php', {
            method: "POST",
            body: formData
          });
          let responseJSON = await response.json();
          let hash = responseJSON.hash;
          console.log(hash);

          await services.doc(serviceId).collection('sources').doc(sourceId).update({cSourceHash: hash});
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }

    return services;
  }

  // useEffect(() => {
  //   oneTimeHashEverythingAgain();
  // }, [])

  const handleSave = async () => {
    try {
      if (type && code && serviceId) {
        let cSourceHash;
        switch (type) {
          case "httpHeader":
            cSourceHash = await normalizeAndOrHashSource(
              code,
              true,
              true,
              false,
              false,
              true
            );
            break;
          case "script_inline":
            cSourceHash = await normalizeAndOrHashSource(code, true, true, false);
            console.log(cSourceHash);
            break;
          case "script_external":
            cSourceHash = await normalizeAndOrHashSource(code, true, true, true);
            break;
          case "iframe":
            cSourceHash = await normalizeAndOrHashSource(
              code,
              true,
              true,
              false,
              true
            );
            break;
          default:
            cSourceHash = md5(code);
            break;
        }

        console.log(cSourceHash);
        const newData = {
          cType: type,
          cSource: code,
          cSourceHash: cSourceHash,
          jAttributes: "{}",
        };
        console.log(newData);
        const sourcesCollection = firestore
          .collection("plugin")
          .doc("ws5_eucookie")
          .collection("services")
          .doc(serviceId)
          .collection("sources");
        console.log(sourcesCollection);
        sourcesCollection.get().then((snapshot) => {
          console.log(snapshot);
        });
        await sourcesCollection.add(newData);
        fetchSources();
        console.log("Source saved successfully!");
        setType("");
        setParentServiceIdString("");
        setCode("");
        setErrorMessage("");
      } else {
        setErrorMessage("Speichern fehlgeschlagen. Alle Felder ausfüllen");
      }
    } catch (error) {
      console.error("Error saving source:", error);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const data2 = sources.slice(startIndex, endIndex);

  const [searchText, setSearchText] = useState("");

  const filteredData = sources.filter((source) => {
    const nameMatches = source.cParentServiceIdString
      .toLowerCase()
      .includes(searchText.toLowerCase());
    return nameMatches;
  });

  const data3 = filteredData.slice(startIndex, endIndex);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleRowClick = (item: SourceObject) => {
    setSelectedItem(item);
    setSearchText("");
  };
  const handleGoBack = () => {
    setSelectedItem(null);
    fetchSources();
  };

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleInfo = (event: any) => {
    setPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignContent="center"
        alignItems="center"
        spacing={1}
        className="w-full"
      >
        <Grid item xs={3}>
          <Label text="Quellentyp:" />
        </Grid>
        <Grid item xs={9}>
          <Select value={type} label="Quellentyp" onChange={handleTypeChange}>
            <MenuItem value={"script_external"}>script_external</MenuItem>
            <MenuItem value={"script_inline"}>script_inline</MenuItem>
            <MenuItem value={"httpHeader"}>httpHeader</MenuItem>
            <MenuItem value={"iframe"}>iframe</MenuItem>
          </Select>
          <Tooltip title="Erklärung zu den Quellentypen">
            <IconButton onClick={handleInfo}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography style={{ padding: 16 }}>
              Ein Quellentyp beschreibt, wie bzw. in welcher Form ein Script im
              Shop eingebunden ist.
            </Typography>
          </Popover>
        </Grid>
        <Grid item xs={3}>
          <Label text="ParentServiceIdString:" />
        </Grid>
        <Grid item xs={9}>
          <Select
            label="ParentServiceIdString"
            value={parentServiceIdString}
            onChange={handleParentServiceIdStringChange}
          >
            {services.length > 0 ? (
              services
                .sort((a, b) =>
                  a.cServiceIdString.localeCompare(b.cServiceIdString)
                )
                .map((service, index) => (
                  <MenuItem key={index} value={service.cServiceIdString}>
                    {service.cServiceIdString}
                  </MenuItem>
                ))
            ) : (
              <MenuItem>no ids found</MenuItem>
            )}
          </Select>
        </Grid>

        <Grid item xs={3}>
          <InputLabel>Code:</InputLabel>
        </Grid>
        <Grid item xs={9}>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="Code"
            onChange={handleCodeChange}
            value={code}
            fullWidth
          />
        </Grid>
        {errorMessage && (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div
              style={{ color: "red", marginBottom: "30px", marginTop: "40px" }}
            >
              {errorMessage}
            </div>
          </div>
        )}
        <div className="flex w-full justify-around mb-6">
          <Button
            style={{
              marginTop: "50px",
            }}
            variant="contained"
            onClick={handleSave}
          >
            Speichern
          </Button>
        </div>
        {!selectedItem && (
          <div style={{ textAlign: "right", float: "right", width: "100%" }}>
            <TextField
              style={{
                width: "300px",
                fontSize: "20px",
                marginBottom: "50px",
              }}
              label="Search"
              value={searchText}
              onChange={handleSearchInputChange}
            />
          </div>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {!selectedItem && <TableCell>kId</TableCell>}
                <TableCell>Quellentyp</TableCell>
                <TableCell>ParentServiceIdString</TableCell>
                <TableCell>ParentServiceKategorie</TableCell>
                <TableCell>Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!selectedItem &&
                // useEffecgt better solution Warning: Using UNSAFE_componentWillReceiveProps in strict mode is not recommended and may indicate bugs in your code. See https://reactjs.org/link/unsafe-component-lifecycles for details.

                (searchText ? data3 : data2).map((data, index) => {
                  const dataIndex = startIndex + index;

                  return (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(data)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>{dataIndex}</TableCell>
                      <TableCell>{data.cType}</TableCell>
                      <TableCell>{data.cParentServiceIdString}</TableCell>
                      <TableCell>{data.kParentCategory}</TableCell>
                      <TableCell>{data.cSource}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {selectedItem && (
              <DetailPageSource
                selectedItem={selectedItem}
                services={services}
              />
            )}
          </Table>
        </TableContainer>
        {!selectedItem && (
          <div>
            <Button
              variant="contained"
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              disabled={endIndex >= sources.length}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </div>
        )}
        {selectedItem && (
          <Button variant="contained" onClick={handleGoBack}>
            Go Back
          </Button>
        )}
      </Grid>
    </>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default SourcesAdd;
