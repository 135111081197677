import { Button, Card, Grid, Typography } from "@material-ui/core";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import InfoMarkDownButton from "../../../../../components/InfoMarkdownButton";
import { DB } from "../../../../../firebase";
import Input from "../InputComponent";
import LoadingCompoenent from "../LoadingComponent";
import { useNavigate } from "react-router-dom";

const DefaultEdit = () => {
  const navigate = useNavigate();
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");

  //WS Multibanner
  const [wsMultibannerReferenceUrl, setWsMultibannerReferenceUrl] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [wsMultibannerImage, setWsMultibannerImage] = useState<File>();
  const [wsMultibannerImageUrl, setWsMultibannerImageUrl] = useState<string>();
  const [wsMultibannerImagePath, setWsMultibannerImagePath] = useState<
    string | undefined
  >(undefined);

  const [wsMultibannerUpdatingError, setWsMultibannerUpdatingError] = useState<{
    type: string;
    message: string;
  } | null>(null);

  //WS Spotlight 1
  const [wsSpotlight1ReferenceUrl, setWsSpotlight1ReferenceUrl] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [wsSpotlight1Image, setWsSpotlight1Image] = useState<File>();
  const [wsSpotlight1ImageUrl, setWsSpotlight1ImageUrl] = useState<string>();
  const [wsSpotlight1ImagePath, setWsSpotlight1ImagePath] = useState<
    string | undefined
  >(undefined);

  const [wsSpotlight1UpdatingError, setWsSpotlight1UpdatingError] = useState<{
    type: string;
    message: string;
  } | null>(null);

  //WS Spotlight 2
  const [wsSpotlight2ReferenceUrl, setWsSpotlight2ReferenceUrl] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [wsSpotlight2Image, setWsSpotlight2Image] = useState<File>();
  const [wsSpotlight2ImageUrl, setWsSpotlight2ImageUrl] = useState<string>();
  const [wsSpotlight2ImagePath, setWsSpotlight2ImagePath] = useState<
    string | undefined
  >(undefined);

  const [wsSpotlight2UpdatingError, setWsSpotlight2UpdatingError] = useState<{
    type: string;
    message: string;
  } | null>(null);

  //DB Banner
  const [dbBannerReferenceUrl, setDbBannerReferenceUrl] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [dbBannerImage, setDbBannerImage] = useState<File>();
  const [dbBannerImageUrl, setDbBannerImageUrl] = useState<string>();
  const [dbBannerImagePath, setDbBannerImagePath] = useState<
    string | undefined
  >(undefined);

  const [dbBannerUpdatingError, setDbBannerUpdatingError] = useState<{
    type: string;
    message: string;
  } | null>(null);

  //DB Analytics Pagespeed Sponsoring
  const [dbAnalyticsPagespeedUrl, setDbAnalyticsPagespeedUrl] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [dbAnalyticsPagespeedImage, setDbAnalyticsPagespeedImage] =
    useState<File>();
  const [dbAnalyticsPagespeedImageUrl, setDbAnalyticsPagespeedImageUrl] =
    useState<string>();
  const [dbAnalyticsPagespeedImagePath, setDbAnalyticsPagespeedImagePath] =
    useState<string | undefined>(undefined);
  const [dbAnalyticsPagespeedImageSecond, setDbAnalyticsPagespeedImageSecond] =
    useState<File>();
  const [
    dbAnalyticsPagespeedImageUrlSecond,
    setDbAnalyticsPagespeedImageUrlSecond,
  ] = useState<string>();
  const [
    dbAnalyticsPagespeedImagePathSecond,
    setDbAnalyticsPagespeedImagePathSecond,
  ] = useState<string | undefined>(undefined);

  const [
    dbAnalyticsPagespeedUpdatingError,
    setDbAnalyticsPagespeedUpdatingError,
  ] = useState<{
    type: string;
    message: string;
  } | null>(null);

  //DB Reports Sponsoring
  const [dbReportsSponsorText, setDbReportsSponsorText] = useState<{
    value: string;
    error: string | null;
  }>({
    value: "",
    error: null,
  });
  const [dbReportsImage, setDbReportsImage] = useState<File>();
  const [dbReportsImageUrl, setDbReportsImageUrl] = useState<string>();
  const [dbReportsImagePath, setDbReportsImagePath] = useState<
    string | undefined
  >(undefined);

  const [dbReportsUpdatingError, setDbReportsUpdatingError] = useState<{
    type: string;
    message: string;
  } | null>(null);

  //WS Plugin im Fokus
  const [wsPluginImFokus, setWsPluginImFokus] = useState<string>();
  const [plugins, setPlugins] = React.useState<
    Array<{ name: string; value: string }>
  >([]);
  const [wsPluginImFokusImage, setWsPluginImFokusImage] = useState<File>();
  const [wsPluginImFokusImageUrl, setWsPluginImFokusImageUrl] =
    useState<string>();
  const [wsPluginImFokusImagePath, setWsPluginImFokusImagePath] = useState<
    string | undefined
  >(undefined);

  const [wsPluginImFokusUpdatingError, setWsPluginImFokusUpdatingError] =
    useState<{
      type: string;
      message: string;
    } | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  //WS Multibanner
  const onWsMultibannerRefernceUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWsMultibannerReferenceUrl({
      ...wsMultibannerReferenceUrl,
      value: event.currentTarget.value,
    });
  };

  const onWsMultibannerImageChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setWsMultibannerImage(e.currentTarget?.files[0]);
    }
  };

  //WS Spotlight 1
  const onWsSpotlight1RefernceUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWsSpotlight1ReferenceUrl({
      ...wsSpotlight1ReferenceUrl,
      value: event.currentTarget.value,
    });
  };

  const onWsSpotlight1ImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setWsSpotlight1Image(e.currentTarget?.files[0]);
    }
  };

  //WS Spotlight 2
  const onWsSpotlight2RefernceUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWsSpotlight2ReferenceUrl({
      ...wsSpotlight2ReferenceUrl,
      value: event.currentTarget.value,
    });
  };

  const onWsSpotlight2ImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setWsSpotlight2Image(e.currentTarget?.files[0]);
    }
  };

  //DB Banner
  const onDbBannerRefernceUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDbBannerReferenceUrl({
      ...dbBannerReferenceUrl,
      value: event.currentTarget.value,
    });
  };

  const onDbBannerImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setDbBannerImage(e.currentTarget?.files[0]);
    }
  };

  //DB Analytics Pagespeed Sponsoring
  const onDbAnalyticsPagespeedUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDbAnalyticsPagespeedUrl({
      ...dbAnalyticsPagespeedUrl,
      value: event.currentTarget.value,
    });
  };

  const onDbAnalyticsPagespeedImageChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setDbAnalyticsPagespeedImage(e.currentTarget?.files[0]);
    }
  };

  const onDbAnalyticsPagespeedImageSecondChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setDbAnalyticsPagespeedImageSecond(e.currentTarget?.files[0]);
    }
  };

  //DB Reports Sponsoring
  const onDbReportsSponsorTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDbReportsSponsorText({
      ...dbReportsSponsorText,
      value: event.currentTarget.value,
    });
  };

  const onDbReportsImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setDbReportsImage(e.currentTarget?.files[0]);
    }
  };

  //Plugin im Fokus
  const onWsPluginImFokusChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setWsPluginImFokus(event.currentTarget.value);
  };
  const onWsPluginImFokusImageChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setWsPluginImFokusImage(e.currentTarget?.files[0]);
    }
  };

  useEffect(() => {
    DB()
      .collection(`plugin`)
      .get()
      .then((data) => {
        const pluginList: Array<{ name: string; value: string }> = [];
        if (!data.empty) {
          data.docs.forEach((doc) => {
            pluginList.push({ name: doc.data().name, value: doc.id });
          });
          const filteredPlugins = pluginList.filter((v) => {
            const regex = /^ws5_/gm;
            return regex.test(v.value);
          });
          setPlugins(filteredPlugins);
        } else {
          setPlugins([]);
        }
      });
  }, [setPlugins]);

  const updateWsMultibannerBanner = async () => {
    setLoading(true);
    setWsMultibannerUpdatingError(null);
    if (wsMultibannerImage) {
      const path = await uploadWsMultibannerImage();
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            tm_banner: {
              default: {
                filePath: path,
                refUrl: wsMultibannerReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    } else {
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            tm_banner: {
              default: {
                refUrl: wsMultibannerReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    }
    navigate("/marketing");
    setLoading(false);
  };

  const updateWsSpotlight1 = async () => {
    setLoading(true);
    setWsSpotlight1UpdatingError(null);
    if (wsSpotlight1Image) {
      const path = await uploadWsSpotlight1Image();
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            ws_spotlight_1: {
              default: {
                filePath: path,
                refUrl: wsSpotlight1ReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    } else {
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            ws_spotlight_1: {
              default: {
                refUrl: wsSpotlight1ReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    }
    navigate("/marketing");
    setLoading(false);
  };
  const updateWsSpotlight2 = async () => {
    setLoading(true);
    setWsSpotlight2UpdatingError(null);
    if (wsSpotlight2Image) {
      const path = await uploadWsSpotlight2Image();
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            ws_spotlight_2: {
              default: {
                filePath: path,
                refUrl: wsSpotlight2ReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    } else {
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            ws_spotlight_2: {
              default: {
                refUrl: wsSpotlight2ReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    }
    navigate("/marketing");
    setLoading(false);
  };

  const updateDbBanner = async () => {
    setLoading(true);
    setDbBannerUpdatingError(null);
    if (dbBannerImage) {
      const path = await uploadDbBannerImage();
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            db_banner: {
              default: {
                filePath: path,
                refUrl: dbBannerReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    } else {
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            db_banner: {
              default: {
                refUrl: dbBannerReferenceUrl.value,
              },
            },
          },
          { merge: true }
        );
    }
    navigate("/marketing");
    setLoading(false);
  };

  const updateDbAnalyticsPagespeed = async () => {
    setLoading(true);
    setDbAnalyticsPagespeedUpdatingError(null);
    if (dbAnalyticsPagespeedImage && dbAnalyticsPagespeedImageSecond) {
      const path = await uploadDbAnalyticsPagespeedImage();
      const pathSecond = await uploadDbAnalyticsPagespeedImageSecond();
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            db_analytics_pagespeed: {
              default: {
                filePath: path,
                filePathSecond: pathSecond,
                refUrl: dbAnalyticsPagespeedUrl.value,
              },
            },
          },
          { merge: true }
        );
    } else {
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            db_analytics_pagespeed: {
              default: {
                refUrl: dbAnalyticsPagespeedUrl.value,
              },
            },
          },
          { merge: true }
        );
    }
    navigate("/marketing");
    setLoading(false);
  };

  const updateDbReportsImage = async () => {
    setLoading(true);
    setDbReportsUpdatingError(null);
    if (dbReportsImage) {
      const path = await uploadDbSponsorImage();
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            db_reports_sponsor: {
              default: {
                filePath: path,
                sponsorText: dbReportsSponsorText.value,
              },
            },
          },
          { merge: true }
        );
    } else {
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            db_reports_sponsor: {
              default: {
                sponsorText: dbReportsSponsorText.value,
              },
            },
          },
          { merge: true }
        );
    }
    navigate("/marketing");
    setLoading(false);
  };

  const updatePluginImFokus = async () => {
    setLoading(true);
    setWsPluginImFokusUpdatingError(null);
    if (wsPluginImFokusImage) {
      const path = await uploadPluginImFokusImage();
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            plugin_im_fokus: {
              default: {
                filePath: path,
                focusedPlugin: wsPluginImFokus,
              },
            },
          },
          { merge: true }
        );
    } else {
      await DB()
        .collection(`config`)
        .doc(`campaign`)
        .set(
          {
            plugin_im_fokus: {
              default: {
                focusedPlugin: wsPluginImFokus,
              },
            },
          },
          { merge: true }
        );
    }
    navigate("/marketing");
    setLoading(false);
  };

  useEffect(() => {
    DB()
      .collection(`config`)
      .doc(`campaign`)
      .get()
      .then((doc) => {
        setWsMultibannerReferenceUrl({
          ...wsMultibannerReferenceUrl,
          value: doc.data()?.tm_banner?.default?.refUrl || "",
        });
        setWsSpotlight1ReferenceUrl({
          ...wsSpotlight1ReferenceUrl,
          value: doc.data()?.ws_spotlight_1?.default?.refUrl || "",
        });
        setWsSpotlight2ReferenceUrl({
          ...wsSpotlight2ReferenceUrl,
          value: doc.data()?.ws_spotlight_2?.default?.refUrl || "",
        });
        setDbBannerReferenceUrl({
          ...dbBannerReferenceUrl,
          value: doc.data()?.db_banner?.default?.refUrl || "",
        });
        setDbAnalyticsPagespeedUrl({
          ...dbAnalyticsPagespeedUrl,
          value: doc.data()?.db_analytics_pagespeed?.default?.refUrl || "",
        });
        setDbReportsSponsorText({
          ...dbReportsSponsorText,
          value: doc.data()?.db_reports_sponsor?.default?.sponsorText || "",
        });
        setDbBannerImagePath(doc.data()?.db_banner?.default?.filePath);
        setDbAnalyticsPagespeedImagePath(
          doc.data()?.db_analytics_pagespeed?.default?.filePath
        );
        setDbAnalyticsPagespeedImagePathSecond(
          doc.data()?.db_analytics_pagespeed?.default?.filePathSecond
        );
        setWsMultibannerImagePath(doc.data()?.tm_banner?.default?.filePath);
        setWsSpotlight1ImagePath(doc.data()?.ws_spotlight_1?.default?.filePath);
        setWsSpotlight2ImagePath(doc.data()?.ws_spotlight_2?.default?.filePath);
        setDbReportsImagePath(
          doc.data()?.db_reports_sponsor?.default?.filePath
        );
        setWsPluginImFokusImagePath(
          doc.data()?.plugin_im_fokus?.default?.filePath
        );
        setWsPluginImFokus(doc.data()?.plugin_im_fokus?.default?.focusedPlugin);
      });
  }, []);

  const uploadWsMultibannerImage = async (): Promise<string | null> => {
    if (!wsMultibannerImage || wsMultibannerImage === null) return null;
    const url = URL.createObjectURL(wsMultibannerImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_tm_banner.${wsMultibannerImage.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setWsMultibannerImageUrl(imageUrl);
    return path;
  };
  const uploadWsSpotlight1Image = async (): Promise<string | null> => {
    if (!wsSpotlight1Image || wsSpotlight1Image === null) return null;
    const url = URL.createObjectURL(wsSpotlight1Image);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_ws_spotlight_1.${wsSpotlight1Image.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setWsSpotlight1ImageUrl(imageUrl);
    return path;
  };
  const uploadWsSpotlight2Image = async (): Promise<string | null> => {
    if (!wsSpotlight2Image || wsSpotlight2Image === null) return null;
    const url = URL.createObjectURL(wsSpotlight2Image);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_ws_spotlight_2.${wsSpotlight2Image.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setWsSpotlight2ImageUrl(imageUrl);
    return path;
  };
  const uploadDbBannerImage = async (): Promise<string | null> => {
    if (!dbBannerImage || dbBannerImage === null) return null;
    const url = URL.createObjectURL(dbBannerImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_db_banner.${dbBannerImage.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setDbBannerImageUrl(imageUrl);
    return path;
  };
  const uploadDbAnalyticsPagespeedImage = async (): Promise<string | null> => {
    if (!dbAnalyticsPagespeedImage || dbAnalyticsPagespeedImage === null)
      return null;
    const url = URL.createObjectURL(dbAnalyticsPagespeedImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_db_analytics_pagespeed.${dbAnalyticsPagespeedImage.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setDbAnalyticsPagespeedImagePath(imageUrl);
    return path;
  };
  const uploadDbAnalyticsPagespeedImageSecond = async (): Promise<
    string | null
  > => {
    if (
      !dbAnalyticsPagespeedImageSecond ||
      dbAnalyticsPagespeedImageSecond === null
    )
      return null;
    const url = URL.createObjectURL(dbAnalyticsPagespeedImageSecond);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_db_analytics_pagespeed_second.${dbAnalyticsPagespeedImageSecond.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setDbAnalyticsPagespeedImagePathSecond(imageUrl);
    return path;
  };
  const uploadDbSponsorImage = async (): Promise<string | null> => {
    if (!dbReportsImage || dbReportsImage === null) return null;
    const url = URL.createObjectURL(dbReportsImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_db_sponsor_image.${dbReportsImage.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setDbReportsImageUrl(imageUrl);
    return path;
  };

  const uploadPluginImFokusImage = async (): Promise<string | null> => {
    if (!wsPluginImFokusImage || wsPluginImFokusImage === null) return null;
    const url = URL.createObjectURL(wsPluginImFokusImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const path = `marketing/campaigns/default_campaign_config/default_ws_plugin_im_fokus_image.${wsPluginImFokusImage.name
      .split(".")
      .pop()}`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const imageUrl = await ref.getDownloadURL();
    setWsPluginImFokusImageUrl(imageUrl);
    return path;
  };

  const cancel = () => {
    navigate("/marketing");
  };

  return (
    <div className="w-full flex flex-col justify-center items-center mb-12">
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
        }}
      >
        Standards bearbeiten
      </Typography>
      <div className="w-full lg:w-1/2 xl:w-1/3">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              WS Multibanner
            </Typography>
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Referenz URL"}
                  onChange={onWsMultibannerRefernceUrlChanged}
                  value={wsMultibannerReferenceUrl.value}
                  error={wsMultibannerReferenceUrl.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`URL welche beim Klick auf den WS Multibanner aufgerufen wird`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/png"
                  placeholder={"Datei"}
                  onChange={onWsMultibannerImageChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für den WS Multibanner`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default WS Multibanner image`}
                  className="mx-auto w-full"
                  src={
                    wsMultibannerImagePath
                      ? `https://ws-cdn.de/${wsMultibannerImagePath}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            {wsMultibannerUpdatingError &&
              wsMultibannerUpdatingError !== null && (
                <div className="flex flex-row justify-center w-full my-8">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "#f5922f",
                      marginTop: 2,
                    }}
                  >
                    {wsMultibannerUpdatingError?.message}
                  </Typography>
                </div>
              )}
            <div className="flex w-full justify-around mt-4">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={updateWsMultibannerBanner}>
                Aktualisieren
              </Button>
            </div>
          </Card>
        )}
      </div>
      <div className="w-full lg:w-1/2 xl:w-1/3  mt-8">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              WS Spotlight 1
            </Typography>
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Referenz URL"}
                  onChange={onWsSpotlight1RefernceUrlChanged}
                  value={wsSpotlight1ReferenceUrl.value}
                  error={wsSpotlight1ReferenceUrl.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`URL welche beim Klick auf das WS Spotlight 1 aufgerufen wird`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/png"
                  placeholder={"Datei"}
                  onChange={onWsSpotlight1ImageChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für das WS Spotlight 1`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default WS Spotlight 1 image`}
                  className="mx-auto w-full"
                  src={
                    wsMultibannerImagePath
                      ? `https://ws-cdn.de/${wsSpotlight1ImagePath}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            {wsSpotlight1UpdatingError &&
              wsSpotlight1UpdatingError !== null && (
                <div className="flex flex-row justify-center w-full my-8">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "#f5922f",
                      marginTop: 2,
                    }}
                  >
                    {wsSpotlight1UpdatingError?.message}
                  </Typography>
                </div>
              )}
            <div className="flex w-full justify-around mt-4">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={updateWsSpotlight1}>
                Aktualisieren
              </Button>
            </div>
          </Card>
        )}
      </div>
      <div className="w-full lg:w-1/2 xl:w-1/3  mt-8">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              WS Spotlight 2
            </Typography>
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Referenz URL"}
                  onChange={onWsSpotlight2RefernceUrlChanged}
                  value={wsSpotlight2ReferenceUrl.value}
                  error={wsSpotlight2ReferenceUrl.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`URL welche beim Klick auf das WS Spotlight 2 aufgerufen wird`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/png"
                  placeholder={"Datei"}
                  onChange={onWsSpotlight2ImageChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für das WS Spotlight 2`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default WS Spotlight 2 image`}
                  className="mx-auto w-full"
                  src={
                    wsMultibannerImagePath
                      ? `https://ws-cdn.de/${wsSpotlight2ImagePath}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            {wsSpotlight2UpdatingError &&
              wsSpotlight2UpdatingError !== null && (
                <div className="flex flex-row justify-center w-full my-8">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "#f5922f",
                      marginTop: 2,
                    }}
                  >
                    {wsSpotlight2UpdatingError?.message}
                  </Typography>
                </div>
              )}
            <div className="flex w-full justify-around mt-4">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={updateWsSpotlight2}>
                Aktualisieren
              </Button>
            </div>
          </Card>
        )}
      </div>
      <div className="w-full lg:w-1/2 xl:w-1/3 mt-8">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              dash.bar Dashboard Banner
            </Typography>
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Referenz URL"}
                  onChange={onDbBannerRefernceUrlChanged}
                  value={dbBannerReferenceUrl.value}
                  error={dbBannerReferenceUrl.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`URL welche beim Klick auf den dash.bar Dashboard Banner aufgerufen wird`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/*,.txt"
                  placeholder={"Datei"}
                  onChange={onDbBannerImageChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für den dash.bar Dashboard Banner`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default DB Banner image`}
                  className="mx-auto w-full"
                  src={
                    dbBannerImagePath
                      ? `https://ws-cdn.de/${dbBannerImagePath}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            {dbBannerUpdatingError && dbBannerUpdatingError !== null && (
              <div className="flex flex-row justify-center w-full my-8">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "lighter",
                    color: "#f5922f",
                    marginTop: 2,
                  }}
                >
                  {dbBannerUpdatingError?.message}
                </Typography>
              </div>
            )}
            <div className="flex w-full justify-around mt-4">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={updateDbBanner}>
                Aktualisieren
              </Button>
            </div>
          </Card>
        )}
      </div>

      <div className="w-full lg:w-1/2 xl:w-1/3 mt-8">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              dash.bar Analytics Pagespeed Sponsoring
            </Typography>
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Referenz URL"}
                  onChange={onDbAnalyticsPagespeedUrlChanged}
                  value={dbAnalyticsPagespeedUrl.value}
                  error={dbAnalyticsPagespeedUrl.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`URL welche beim Klick auf die Werbefläche in der Pagespeed Analytics-Seite aufgerufen wird`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/*,.txt"
                  placeholder={"Datei"}
                  onChange={onDbAnalyticsPagespeedImageChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für das große Banner auf der Pagespeed Analytics-Seite`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/*,.txt"
                  placeholder={"Datei"}
                  onChange={onDbAnalyticsPagespeedImageSecondChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für das kleine Banner auf der Pagespeed Analytics-Seite`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default DB Analytics image`}
                  className="mx-auto w-full"
                  src={
                    dbAnalyticsPagespeedImagePath
                      ? `https://ws-cdn.de/${dbAnalyticsPagespeedImagePath}`
                      : undefined
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default DB Analytics second image`}
                  className="mx-auto w-full"
                  src={
                    dbAnalyticsPagespeedImagePathSecond
                      ? `https://ws-cdn.de/${dbAnalyticsPagespeedImagePathSecond}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            {dbAnalyticsPagespeedUpdatingError &&
              dbAnalyticsPagespeedUpdatingError !== null && (
                <div className="flex flex-row justify-center w-full my-8">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "#f5922f",
                      marginTop: 2,
                    }}
                  >
                    {dbAnalyticsPagespeedUpdatingError?.message}
                  </Typography>
                </div>
              )}
            <div className="flex w-full justify-around mt-4">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={updateDbAnalyticsPagespeed}>
                Aktualisieren
              </Button>
            </div>
          </Card>
        )}
      </div>
      <div className="w-full lg:w-1/2 xl:w-1/3 mt-8">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              dash.bar Reports Sponsor
            </Typography>
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Sponsor Name"}
                  onChange={onDbReportsSponsorTextChanged}
                  value={dbReportsSponsorText.value}
                  error={dbReportsSponsorText.error}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Name des Sponsors, welcher unter anderem in den Push-Benachrichtigungen angezeigt wird.`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/*,.txt"
                  placeholder={"Datei"}
                  onChange={onDbReportsImageChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für das Logo des Sponsors der Reports bei dash.bar`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default DB Report Sponsor logo`}
                  className="mx-auto w-full"
                  src={
                    dbReportsImagePath
                      ? `https://ws-cdn.de/${dbReportsImagePath}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            {dbBannerUpdatingError && dbBannerUpdatingError !== null && (
              <div className="flex flex-row justify-center w-full my-8">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "lighter",
                    color: "#f5922f",
                    marginTop: 2,
                  }}
                >
                  {dbBannerUpdatingError?.message}
                </Typography>
              </div>
            )}
            <div className="flex w-full justify-around mt-4">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={updateDbReportsImage}>
                Aktualisieren
              </Button>
            </div>
          </Card>
        )}
      </div>
      <div className="w-full lg:w-1/2 xl:w-1/3 mt-8">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              Plugin im Fokus
            </Typography>
            <Grid
              container
              className="flex items-center content-center"
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <select
                  key="focusedPlugin"
                  value={wsPluginImFokus}
                  onChange={onWsPluginImFokusChanged}
                  style={{
                    padding: 5,
                    width: "100%",
                    borderRadius: 5,
                    marginBottom: 5,
                    color: "black",
                    fontSize: 18,
                  }}
                >
                  {plugins.map((plugin) => {
                    return (
                      <option key={plugin.value} value={plugin.value}>
                        {plugin.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Plugin welches standardmäßig als im Fokus angezeigt werden soll`}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  type={"file"}
                  accept="image/*,.txt"
                  placeholder={"Datei"}
                  onChange={onWsPluginImFokusImageChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Bildatei für das **Plugin im Fokus**`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-start items-center content-center"
              >
                <img
                  style={{
                    maxWidth: "200px",
                    padding: 5,
                    borderRadius: 10,
                    backgroundColor: "#eee",
                  }}
                  alt={`Default Plugin im Fokus image`}
                  className="mx-auto w-full"
                  src={
                    dbBannerImagePath
                      ? `https://ws-cdn.de/${wsPluginImFokusImagePath}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>

            {wsPluginImFokusUpdatingError &&
              wsPluginImFokusUpdatingError !== null && (
                <div className="flex flex-row justify-center w-full my-8">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "#f5922f",
                      marginTop: 2,
                    }}
                  >
                    {wsPluginImFokusUpdatingError?.message}
                  </Typography>
                </div>
              )}
            <div className="flex w-full justify-around mt-4">
              <Button variant="outlined" onClick={cancel}>
                Abbrechen
              </Button>
              <Button variant="contained" onClick={updatePluginImFokus}>
                Aktualisieren
              </Button>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default DefaultEdit;
