import { useEffect, useState } from "react";
import React from "react";
import { useFirestore } from "react-redux-firebase";
import DashboardCard from "../../general/DashboardCard";
import { useNavigate } from "react-router-dom";

const RandomPlugin = () => {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [releases, setReleases] = useState<Array<Record<string, any>> | null>(
    null
  );

  useEffect(() => {
    setLoading(true);
    firestore
      .collectionGroup(`release`)
      .orderBy("releaseDate", "desc")
      .limit(30)
      .get()
      .then((results) => {
        const resultArray: Array<Record<string, any>> = [];
        results.forEach((result) => {
          const data = result.data();
          const pluginId = result.ref.parent.parent?.id ?? null;
          if (pluginId !== null) {
            resultArray.push({ ...data, pluginId: pluginId });
          }
        });
        setReleases(resultArray);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const randNum = Math.floor(Math.random() * (28 - 1 + 1)) + 1;

  return (
    <DashboardCard title="Entdecke Plugins" theme="plugin" loading={loading}>
      {releases && (
        <div
          style={{
            width: "100%",
            height: "100%",
            minWidth: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/developer/plugins/${releases[randNum].pluginId}`);
          }}
        >
          <img
            title={`Plugin Image`}
            width={150}
            // className="bg-contain h-72 mx-auto"
            style={{ width: "40%", maxWidth: 150, borderRadius: 50 }}
            src={`https://ws-cdn.de/plugins/icons/${releases[
              randNum
            ].pluginId.replace("ws5_", "ws_")}.svg`}
          />
        </div>
      )}
    </DashboardCard>
  );
};

export default RandomPlugin;
