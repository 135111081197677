import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import useGitlab, { gitlabAPI } from "../../../../hooks/gitlab/useGitlab";
import useAuth from "../../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as XML from "xml-js";
import Form from "./form";
import { InfoXML, OLD_Plugin as Plugin, Release } from "@dash.bar/types";
import { Project } from "../../../../types/Plugins";
import { saveRelease } from "../../../../hooks/firestore/usePlugins";
import { useForm } from "react-hook-form";
import Loading from "../../../../components/Loading";
import firebase from "firebase/compat/app";
import { platforms } from "../../../../utils/InfoXML";
import { useSetDocument } from "../../../../firestore/hooks";
import { ICON_STYLE } from "../../../../utils/constants";
import { xml2js } from "xml-js";

type Props = {
  open: boolean;
  onClose?: () => void;
};

type onChangeType = {
  target: { value: string };
};

const NewPlugin = (props: Props) => {
  const [platform, setPlatform] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [project, setProject] = useState<Project | undefined>(undefined);
  const [infoxml, setInfoxml] = useState<InfoXML | undefined>(undefined);
  const [options, setOptions] = useState<Project[]>([]);
  const [search, setSearch] = useState<string>("");
  const auth = useAuth();
  const gitlab = useGitlab();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const closeDialogAndReset = () => {
    setPlatform(undefined);
    setProject(undefined);
    reset();
    props.onClose?.();
  };

  const createPlugin = useSetDocument<Plugin>("plugin");

  const handleSave = (values: Record<string, any>) => {
    const plugin = {
      pluginId: values.pluginId,
      name: values.name,
      description: values.description,
      access: [auth.uid],
      gitlabId: parseInt(values.gitlabId),
      gitlabUrl: values.gitlabUrl,
      platform: platform,
      options: {
        flatrates: [],
        isPublic: values.isPublic,
        useLicence: values.useLicence,
        minVersion: values.minVersion,
      },
    } as Plugin;

    if (values.exsID) {
      plugin.exs_id = values.exsID;
    }
    createPlugin(plugin, plugin.pluginId)
      .then(() => {
        if (values.version) {
          values.version.map(
            (version: {
              create_date: string;
              nr: string;
              released: boolean;
            }) => {
              saveRelease(values.pluginId, version.nr, {
                version: version.nr,
                changelog: "",
                downloadUrl: "",
                public: version.released,
                releaseDate: firebase.firestore.Timestamp.fromDate(
                  new Date(version.create_date)
                ),
              } as Release);
              return version;
            }
          );
        }
      })
      .catch((err) => enqueueSnackbar(`Error: ${err}`, { variant: "error" }));
    // console.log(plugin);
    closeDialogAndReset();
  };

  const handleProjectSearch = (e: onChangeType) => {
    if (e.target.value.length >= 3) {
      setSearch(e.target.value);
    } else {
      setSearch("");
    }
  };

  const loadInfoXML = useCallback(() => {
    if (project && gitlab.status === "authorized") {
      setLoading(true);
      gitlabAPI()({
        endpoint: `/projects/${project.id}/repository/files/${encodeURI(
          "info.xml"
        )}/raw?ref=master`,
      })
        .then((res) => {
          setInfoxml(xml2js(res.data.toString(), { compact: true }) as InfoXML);
        })
        .catch((err) => {
          setProject(undefined);
          enqueueSnackbar(`Error: ${err}`, { variant: "error" });
        })
        .finally(() => setLoading(false));
    }
  }, [project, gitlab.status, enqueueSnackbar]);

  useEffect(() => {
    loadInfoXML();
  }, [loadInfoXML]);

  const loadSearch = useCallback(() => {
    if (gitlab.status === "authorized") {
      const projectGroup = platform === `jtlshop4` ? 5 : 145;
      setLoading(true);
      gitlabAPI()({
        // endpoint:
        //   "/projects?" +
        //   new URLSearchParams({
        //     membership: "true",
        //     archived: "false",
        //     min_access_level: "30",
        //     with_programming_language: "PHP",
        //     order_by: "last_activity_at",
        //     sort: "desc",
        //     search: search,
        //   }).toString(),
        endpoint:
          `/groups/${projectGroup}/projects?` +
          new URLSearchParams({
            membership: "true",
            archived: "false",
            min_access_level: "30",
            with_programming_language: "PHP",
            order_by: "last_activity_at",
            sort: "desc",
            search: search,
          }).toString(),
      })
        .then((res) => setOptions(res.data as Project[]))
        .catch((err) => enqueueSnackbar(`Error: ${err}`, { variant: "error" }))
        .finally(() => setLoading(false));
    }
  }, [search, enqueueSnackbar, gitlab.status, platform]);

  useEffect(() => {
    loadSearch();
  }, [loadSearch]);

  let content = null;
  if (!platform) {
    // Choose Platform
    content = (
      <div>
        <h4>Platform auswählen:</h4>
        {Object.keys(platforms).map((key: string) => (
          <Button
            onClick={() => setPlatform(key)}
            key={key}
            variant={"outlined"}
            fullWidth
            style={{ marginTop: 5, borderRadius: 20 }}
          >
            {platforms[key]}
          </Button>
        ))}
      </div>
    );
  } else if (platform && !project) {
    // Search Project
    content = (
      <>
        <TextField
          onChange={handleProjectSearch}
          autoFocus={true}
          fullWidth
          label={"Search"}
        />
        {loading ? (
          <Loading loading={true} />
        ) : (
          <List dense>
            {options.map((val: Project) => (
              <ListItem key={val.id}>
                <ListItemAvatar>
                  <Avatar src={val.avatar_url} />
                </ListItemAvatar>
                <ListItemText
                  primary={val.name}
                  secondary={val.namespace.name}
                />
                <ListItemSecondaryAction>
                  <IconButton edge={"end"} onClick={() => setProject(val)}>
                    <FontAwesomeIcon
                      icon={[ICON_STYLE, "arrow-circle-right"]}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </>
    );
  } else if (platform && project) {
    // Plugin/Release Form
    content = (
      <>
        <Typography variant={"h5"} style={{ marginBottom: 5 }}>
          {project?.namespace.name} / {project?.name}
        </Typography>
        <Form
          infoxml={infoxml}
          watch={watch}
          project={project}
          onSave={handleSave}
          register={register}
          setValue={setValue}
          handleSubmit={handleSubmit}
          errors={errors}
          platform={platform}
        />
      </>
    );
  }
  return (
    <Modal open={props.open}>
      <Dialog
        open={props.open}
        maxWidth={"sm"}
        fullWidth
        onClose={closeDialogAndReset}
      >
        <DialogTitle>Plugin hinzufügen</DialogTitle>
        <DialogContent style={{ marginTop: 10, marginBottom: 10 }}>
          {content}
        </DialogContent>
        <DialogActions>
          {project ? (
            <Button
              color={"primary"}
              onClick={() => {
                setProject(undefined);
                setInfoxml(undefined);
                reset();
                setSearch("");
              }}
            >
              Zurück
            </Button>
          ) : null}
          <Button onClick={closeDialogAndReset}>Abbrechen</Button>
          {project ? (
            <Button color={"primary"} onClick={handleSubmit(handleSave)}>
              Speichern
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default NewPlugin;
