import { Typography } from "@material-ui/core";
import React from "react";

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 16,
        fontWeight: "normal",
      }}
    >
      {text}
    </Typography>
  );
};
export default Label