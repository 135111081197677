import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import firebase from "firebase/compat/app";
import dashbar from "../../../firebase/dashbar";
import { WhereT } from "../../../types";
import Table from "../../../components/Table";
import { IShop } from "@dash.bar/types";
import IDLabel from "../../../components/IDLabel";
import ReactTimeago from "react-timeago";
import { Button } from "@material-ui/core";
import CompanySelect from "../modal/CompanySelect";
import { useSnackbar } from "notistack";

const Shops = () => {
  const [shops, setShops] = useState<
    | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    | undefined
  >();
  const [companySelect, setCompanySelect] = useState<
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined
  >();
  const [limit, setLimit] = useState(15);
  const [where, setWhere] = useState<WhereT>();
  const { enqueueSnackbar } = useSnackbar();

  const handleSearch = useCallback(
    (key: string, value: string) => {
      if (value) {
        setWhere([
          key === "id" ? firebase.firestore.FieldPath.documentId() : key,
          ">=",
          value,
        ]);
      } else {
        setWhere(undefined);
      }
    },
    [setWhere]
  );

  const deleteShop = useCallback(
    (
        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
      ) =>
      async () => {
        if (!window.confirm("Really delete this Shop?")) return;
        if (doc.exists) {
          await doc.ref.delete();
        }
      },
    []
  );

  const handleCompanySelect = useCallback(
    (
        shopDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
      ) =>
      (id: string) =>
      async () => {
        if (id && shopDoc.exists) {
          if (!window.confirm(`Really move Shop to '${id}' ?`)) return;

          setCompanySelect(undefined);
          try {
            const shopData = shopDoc.data() as Record<string, any>;
            if (shopData.users) {
              delete shopData.users;
            }
            const newPath = `company/${id}/shop`;
            await dashbar()
              .db.collection(newPath)
              .doc(shopDoc.id)
              .set(shopData);
            await shopDoc.ref.delete();
          } catch (e) {
            enqueueSnackbar(`${e}`, { variant: "error" });
          }
        }
      },
    [setCompanySelect, enqueueSnackbar]
  );

  useEffect(() => {
    let query = dashbar().db.collectionGroup("shop").limit(limit);
    if (where) {
      console.debug(where);
      query = query.where(...where);
    }
    return query.onSnapshot(setShops);
  }, [setShops, where, limit]);

  return (
    <PageContainer title={"Shops"} loading={shops === undefined}>
      <Table
        striped
        header={{
          domain: { content: "Domain", searchable: true },
          "platform.name": { content: "Platform", searchable: true },
          sdk: { content: "SDK" },
          plugin: { content: "Plugin" },
          shop: { content: "Shop" },
          currency: { content: "Currency" },
          company: { content: "Company" },
          connectedAt: { content: "Connected" },
          action: { content: "" },
        }}
        onSearch={handleSearch}
        onMore={() => setLimit((prev) => prev + 15)}
      >
        {shops &&
          shops.docs.map((shopDoc) => {
            const companyID = shopDoc.ref.parent?.parent?.id;
            const shop = shopDoc.data() as IShop;
            return (
              <tr key={shopDoc.id}>
                <td>
                  <IDLabel
                    to={
                      companyID
                        ? `/dashbar/companies/${companyID}/shops/${shopDoc.id}`
                        : undefined
                    }
                    label={shop.domain}
                  />
                </td>
                <td>{shop?.platform?.name ?? "-"}</td>
                <td>{shop?.platform?.sdkVersion ?? "-"}</td>
                <td>{shop?.platform?.pluginVersion ?? "-"}</td>
                <td>{shop?.platform?.version ?? "-"}</td>
                <td
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: `${shop?.currency ?? "-"}`,
                  }}
                />
                <td className="text-center">
                  {companyID ? (
                    <IDLabel
                      to={`/dashbar/companies/${companyID}`}
                      label={companyID}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setCompanySelect(shopDoc)}
                    >
                      Select
                    </Button>
                  )}
                </td>
                <td>
                  {shop?.connectedAt && (
                    <ReactTimeago date={shop?.connectedAt.toDate()} />
                  )}
                </td>
                <td className="text-center">
                  {companyID && (
                    <Button
                      variant="outlined"
                      onClick={() => setCompanySelect(shopDoc)}
                      color="secondary"
                    >
                      move
                    </Button>
                  )}
                  {!companyID && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={deleteShop(shopDoc)}
                    >
                      delete
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
      </Table>
      {companySelect && (
        <CompanySelect
          onCancel={() => setCompanySelect(undefined)}
          onSave={handleCompanySelect(companySelect)}
        />
      )}
    </PageContainer>
  );
};

export default Shops;
