import React, { useCallback, useEffect, useState } from "react";
import IDLabel from "../../../../../components/IDLabel";
import {
  Button,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@material-ui/core";
import PageContainer from "../../../../../components/PageContainer";
import { IShop } from "@dash.bar/types";
import axios, { AxiosError } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Textarea } from "../../../../marketingNew/Dashboard/components/InputComponent";
import { ICON_STYLE } from "../../../../../utils/constants";

type TabInfoProps = {
  shop: IShop;
  companyID?: string;
};

const TabConnectionCheck = ({ shop, companyID }: TabInfoProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [recommendation, setRecommendation] = useState<string | null>(null);

  const [connectionState, setConnectionState] = useState<{
    success: boolean;
    status: number;
    message: string;
  } | null>(null);

  const [authorizationState, setAuthorizationState] = useState<{
    success: boolean;
    status: number;
    message: string;
  } | null>(null);

  const [dataState, setDataState] = useState<{
    success: boolean;
    status: number;
    message: string;
  } | null>(null);

  const checkReachable = async () => {
    let status: number = 0;
    try {
      const result = await axios.get(shop.endpoint);
      //   console.log(result.status);
      //   console.log(result.data);
      status = +result.status;
    } catch (e) {
      const err = e as AxiosError;
      //   console.log(err.response?.status);
      status = +(err?.response?.status || 0);
    }
    switch (status) {
      case 401:
        setConnectionState({
          success: true,
          status: status,
          message: `Die Datei api.php wurde erreicht`,
        });
        break;
      default:
        setConnectionState({
          success: false,
          status: status,
          message: `Die Datei api.php konnte nicht erreicht werden`,
        });
    }
  };
  const checkData = async () => {
    let status: number = 0;
    try {
      const result = await axios.post(
        shop.endpoint,
        {
          request: "Analytics",
          widget: {
            order: {
              options: {
                active: true,
                details: false,
                refresh: undefined,
                interval: `D1`,
                pagination: { descending: true, page: 1, rowsPerPage: 1 },
              },
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${shop.secretToken}`,
            "User-Agent":
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17",
          },
          maxRedirects: 0,
        }
      );
      //   console.log(result.status);
      //   console.log(result.data);
      status = +result.status;
    } catch (e) {
      const err = e as AxiosError;
      //   console.log(err.response?.status);
      status = +(err?.response?.status || 0);
    }
    switch (status) {
      case 200:
        setDataState({
          success: true,
          status: status,
          message: `Der Abruf von Testdaten war erfolgreich`,
        });
        break;
      default:
        setDataState({
          success: false,
          status: status,
          message: `Der Abruf von Testdaten war nicht möglich`,
        });
    }
  };
  const checkAuthorization = async () => {
    let status: number = 0;
    try {
      const result = await axios.get(shop.endpoint, {
        headers: { Authorization: `Bearer ${shop.secretToken}` },
      });
      //   console.log(result.status);
      //   console.log(result.data);
      status = +result.status;
    } catch (e) {
      const err = e as AxiosError;
      //   console.log(err.response?.status);
      status = +(err?.response?.status || 0);
    }
    switch (status) {
      case 400:
        setAuthorizationState({
          success: true,
          status: status,
          message: `Die Authentifizierung mit dem SecretToken war erfolgreich`,
        });
        break;
      default:
        setAuthorizationState({
          success: false,
          status: status,
          message: `Die Authentifizierung mit dem SecretToken schlug fehl`,
        });
    }
  };

  useEffect(() => {
    setRecommendation(null);
    if (connectionState?.success === false) {
      setRecommendation(
        `Ich habe mir deinen Shop ${shop.domain} gerade einmal angesehen und\nfestgestellt, dass die Datei api.php unter ${shop.endpoint} nicht erreichbar ist.\nBitte stelle sicher, dass die Datei erreichbar ist, damit dash.bar korrekt funktioniert.`
      );
    } else if (authorizationState?.success === false) {
      setRecommendation(
        `Ich habe mir deinen Shop ${shop.domain} gerade einmal angesehen und\nfestgestellt, dass die Verknüpfung zu dash.bar nicht mehr aktuell ist.\nBitte versuche einmal die Verbindung mit deinem Shop zu trennen\nund diesen anschließend wieder neu zu verbinden.`
      );
    } else if (!dataState?.success) {
    }
  }, [connectionState, authorizationState, dataState]);

  const checkConnection = async () => {
    setProgress(0);
    setLoading(true);
    setConnectionState(null);
    setAuthorizationState(null);
    setDataState(null);
    await checkReachable();
    setProgress(25);
    await checkAuthorization();
    setProgress(65);
    await checkData();
    setProgress(100);
    setLoading(false);
  };

  return (
    <>
      <PageContainer title="Info" heading={3} bgColor="bg-gray-800">
        {loading && (
          <LinearProgress
            className="my-4"
            variant="determinate"
            value={progress}
          />
        )}
        <Grid
          container
          style={{ alignContent: "flex-start", alignItems: "flex-start" }}
        >
          {connectionState !== null && (
            <>
              <Grid
                item
                xs={2}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <FontAwesomeIcon
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                  color={connectionState?.success ? "#19781f" : "#c90e0e"}
                  icon={[
                    ICON_STYLE,
                    connectionState?.success
                      ? "check-circle"
                      : "exclamation-circle",
                  ]}
                />
              </Grid>
              <Grid
                item
                xs={8}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {connectionState?.message}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {`Status: ${connectionState?.status}`}
                </Typography>
              </Grid>
            </>
          )}
          {authorizationState !== null && (
            <>
              <Grid
                item
                xs={2}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <FontAwesomeIcon
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                  color={authorizationState?.success ? "#19781f" : "#c90e0e"}
                  icon={[
                    ICON_STYLE,
                    authorizationState?.success
                      ? "check-circle"
                      : "exclamation-circle",
                  ]}
                />
              </Grid>
              <Grid
                item
                xs={8}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {authorizationState?.message}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {`Status: ${authorizationState?.status}`}
                </Typography>
              </Grid>
            </>
          )}
          {dataState !== null && (
            <>
              <Grid
                item
                xs={2}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <FontAwesomeIcon
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                  color={dataState?.success ? "#19781f" : "#c90e0e"}
                  icon={[
                    ICON_STYLE,
                    dataState?.success ? "check-circle" : "exclamation-circle",
                  ]}
                />
              </Grid>
              <Grid
                item
                xs={8}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {dataState?.message}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ alignContent: "center", alignItems: "center" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {`Status: ${dataState?.status}`}
                </Typography>
              </Grid>
            </>
          )}
          {recommendation !== null && !loading && (
            <>
              <Grid
                item
                xs={2}
                className="mt-8"
                style={{ alignContent: "flex-start", alignItems: "flex-start" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {`Ticketbaustein:`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                className="mt-8"
                style={{ alignContent: "flex-start", alignItems: "flex-start" }}
              >
                <Textarea
                  placeholder={"Ticketvorschlag"}
                  value={recommendation}
                  style={{ color: "white", marginTop: 10, padding: 4 }}
                  onFocus={() => {}}
                  rows={8}
                />
              </Grid>
            </>
          )}
        </Grid>
        <div className="flex justify-center w-full my-4">
          <Button
            variant="outlined"
            disabled={loading}
            onClick={checkConnection}
          >
            Check
          </Button>
        </div>
      </PageContainer>
    </>
  );
};

export default TabConnectionCheck;
