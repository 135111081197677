import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormElements } from "./constants";
import { Button, FormControl, Input, InputLabel } from "@material-ui/core";
import HtmlTooltip from "../../../components/HtmlTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../utils/constants";

type TrackingFormProps<T extends Record<string, any>> = {
  validator: yup.ObjectSchema<any>;
  elements: FormElements<T>;
  onSubmit: (data: Record<string, any>) => void;
};

export function TrackingForm<T extends Record<string, any>>({
  validator,
  elements,
  onSubmit,
}: TrackingFormProps<T>) {
  const defaultValues: Array<{ [k in keyof T]: any }> = Object.keys(
    elements
  ).map(
    (k: keyof T) => ({ [k]: elements[k].default } as { [k in keyof T]: any })
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<T>({
    defaultValues: Object.assign(
      Object.assign.apply({}, [{}, ...defaultValues])
    ),
    //@ts-ignore
    resolver: yupResolver(validator),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center">
        <ul>
          {Object.keys(elements).map((el) => (
            <li key={el}>
              <div className="grid grid-cols-3 gap-2 max-w-md m-auto">
                <FormControl key={el} className="col-span-2">
                  <InputLabel error={!!errors[el]} htmlFor={`input-${el}`}>
                    {elements[el].label}
                  </InputLabel>
                  <Input
                    id={`input-${el}`} // @ts-ignore
                    inputProps={{ ...register(elements[el].key) }}
                  />
                </FormControl>
                {elements[el].info && (
                  <HtmlTooltip
                    title={<pre>{elements[el].info}</pre>}
                    className="max-w-xs col-span-1"
                  >
                    <Button>
                      <FontAwesomeIcon icon={[ICON_STYLE, "question-circle"]} />
                    </Button>
                  </HtmlTooltip>
                )}
              </div>
            </li>
          ))}
        </ul>
        <Button color="primary" type="submit">
          SEND
        </Button>
      </div>
    </form>
  );
}
