import React, { useEffect, useState } from "react";
import { DB } from "../../../firebase";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const PluginCustomer = () => {
  const [customers, setCustomers] = useState<Array<Record<string, any>>>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (refresh === false) return;
    DB()
      .collection(`customer`)
      .get()
      .then((docs) => {
        const licenseArray: Array<Record<string, any>> = [];
        docs.forEach((doc) => {
          licenseArray.push({ ...doc.data(), id: doc.id });
        });
        setCustomers(licenseArray);
      })
      .finally(() => {
        setRefresh(false);
      });
  }, [refresh]);

  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: 5,
          borderRadius: 10,
          marginTop: 4,
          backgroundColor: "#DDD",
          marginBottom: 4,
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={1}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "normal",
                color: "black",
              }}
            >
              {`Kundennummer`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "normal",
                color: "black",
              }}
            >
              {`Name`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "normal",
                color: "black",
              }}
            >
              {`E-Mail`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "normal",
                color: "black",
              }}
            >
              {`USt-ID`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "normal",
                color: "black",
              }}
            >
              {`Erstellungsdatum`}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {customers.map((customer) => {
        return (
          <div
            style={{
              width: "100%",
              padding: 5,
              borderRadius: 10,
              marginTop: 4,
              backgroundColor: "#3c3f45",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/licenses/plugin/customer/${customer.id}`)}
          >
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={1}>
                {customer.sevdesk_id ?? `n/a`}
              </Grid>
              <Grid item xs={4}>
                {`${customer.firstname ?? `n/a`} ${customer.lastname ?? ""}`}
              </Grid>
              <Grid item xs={2}>
                {customer.email ?? "n/a"}
              </Grid>
              <Grid item xs={2}>
                {customer.vat ?? "n/a"}
              </Grid>
              <Grid item xs={1}>
                {moment(customer.createTime.toDate()).format("DD.MM.YYYY") ??
                  `n/a`}
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};
export default PluginCustomer;
