import {
  Chip,
  Grid,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { ERROR, GREEN, RED } from "../../../../../../constants/colors";
import styled from "@emotion/styled";
import { tooltipClasses } from "@mui/material";
import moment from "moment";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const UserCard = ({
  user,
  config,
}: {
  config: Record<string, any> | null;
  user: Record<string, any>;
}) => {
  const currentVersion: string | null = config?.current_version ?? null;
  const [oldVersionsUsed, setOldVersionsUsed] = useState<Array<string> | null>(
    null
  );
  const [platformsUsed, setPlatformsUsed] = useState<Array<string> | null>(
    null
  );
  const [lastActive, setLastActive] = useState<Date | null>(null);

  useEffect(() => {
    if (currentVersion === null) setOldVersionsUsed(null);
    const oldVersions: Array<string> = (user?.sessions ?? [])
      .map((session: Record<string, any>) => {
        return session?.appVersion ?? null;
      })
      .filter((version: string) => {
        if (currentVersion === null || version === null) return false;
        return currentVersion?.trim() !== version?.trim();
      });
    if (oldVersions.length > 0) {
      setOldVersionsUsed([...new Set(oldVersions)].sort());
    } else {
      setOldVersionsUsed(null);
    }
  }, [config]);

  useEffect(() => {
    const platforms: Array<string> = (user?.sessions ?? [])
      .map((session: Record<string, any>) => {
        return session?.platform ?? null;
      })
      .filter((platform: string) => {
        return (
          platform !== null &&
          platform.trim() !== "" &&
          platform !== undefined &&
          platform !== "undefined"
        );
      });
    setPlatformsUsed([...new Set(platforms)].sort());
  }, [config]);

  useEffect(() => {
    const lastActive: Array<Date> = (user?.sessions ?? [])
      .map((session: Record<string, any>) => {
        return session?.created?.toDate() ?? null;
      })
      .filter((active: Date) => {
        return active !== null;
      })
      .sort((d1: Date, d2: Date) => {
        if (moment(d1).isBefore(moment(d2))) {
          return 1;
        } else if (moment(d2).isBefore(moment(d1))) {
          return -1;
        } else {
          return 0;
        }
      });
    setLastActive(lastActive.length > 0 ? lastActive[0] : null);
  }, [config]);

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={user?.profileImage}
            style={{
              width: 50,
              height: 50,
              borderRadius: 100,
              backgroundColor: "#FFF",
              marginBottom: 10,
            }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: TITLE_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: TITLE_TEXT.WEIGHT,
              marginBottom: 5,
            }}
          >
            {`${user?.firstName ?? ""} ${user?.lastName ?? ""}`.trim() === ""
              ? `${user.email}`
              : `${user.firstName} ${user.lastName}`}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`ID`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {user.id ?? `n/a`}
          </Typography>
          {user?.id && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(user?.id);
              }}
              // className={"c2c"}
              // data-clipboard-text={user?.id}
            >
              <FileCopy fontSize={"small"} />
            </IconButton>
          )}
        </div>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Rolle`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        {user?.isOwner ? (
          <Chip
            size="small"
            label={`Owner`}
            style={{
              marginTop: 5,
              marginBottom: 5,
              backgroundColor: GREEN,
            }}
            variant="default"
          />
        ) : user?.isAdmin ? (
          <Chip
            size="small"
            label={`Admin`}
            style={{
              marginTop: 5,
              marginBottom: 5,
              backgroundColor: RED,
            }}
            variant="default"
          />
        ) : (
          <Chip
            size="small"
            label={`User`}
            style={{
              marginTop: 5,
              marginBottom: 5,
            }}
            variant="default"
          />
        )}
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`E-Mail`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {user.email ?? `n/a`}
        </Typography>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Systeme`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        {(platformsUsed?.length ?? 0) > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {platformsUsed?.map((platform) => {
              return (
                <Chip
                  size="small"
                  label={platform}
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  variant="default"
                />
              );
            })}
          </div>
        ) : (
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {`Keine Geräte aktiv`}
          </Typography>
        )}
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Letzte Aktivität`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        {lastActive !== null ? (
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {`${moment(lastActive).locale("de").fromNow()}`}
          </Typography>
        ) : (
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {`Keine Aktivität`}
          </Typography>
        )}
      </Grid>
      {oldVersionsUsed !== null && (
        <Grid item xs={12} alignContent="center" alignItems="center">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 15,
                borderRadius: 15,
                borderColor: ERROR.LIGHT,
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <CustomWidthTooltip
                title={`Der Nutzer verwendet auf mindestens einem seiner Geräte eine veraltete dash.bar Version. Dies kann zu Problemen bei der Darstellung und Abstürzen führen. Gefunden: ${oldVersionsUsed.join(
                  ", "
                )}`}
              >
                <Typography
                  style={{
                    color: ERROR.LIGHT,
                    fontSize: DEFAULT_TEXT.SIZE,
                    fontFamily: "Roboto",
                    fontWeight: DEFAULT_TEXT.WEIGHT,
                  }}
                >
                  {`Veraltete Version gefunden`}
                </Typography>
              </CustomWidthTooltip>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default UserCard;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});
