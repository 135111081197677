import React, { useCallback, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ExsBasicHook } from "@dash.bar/types";
import useStyles from "./styles";
import { OrderByOptions, WhereOptions } from "react-redux-firebase";
import { exsBasicInfo, webhookStatusLabel } from "../../../utils/funcs";
import Retry from "./Retry";
import { useCollection } from "../../../firestore/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTimeago from "react-timeago";
import LoadingWrapper from "../../../components/LoadingWrapper";
import PageContainer from "../../../components/PageContainer";
import { ICON_STYLE } from "../../../utils/constants";

const WebhookView = () => {
  const [where, setWhere] = useState<Record<string, WhereOptions> | undefined>(
    undefined
  );
  const [filter, setFilter] = useState("all");
  const [limit, setLimit] = useState(15);
  const [order, setOrder] = useState<OrderByOptions>(["created_at", "desc"]);
  const [filterType, setFilterType] = useState("all");
  const webhooks = useCollection<ExsBasicHook>(
    "webhook",
    where ? Object.values(where) : undefined,
    order,
    limit
  );
  const cls = useStyles();

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newFilter = e.target.value as string;
    setFilter(newFilter);
    if (newFilter === "all") {
      setWhere((prevState) => {
        if (prevState) {
          const newState = { ...prevState };
          delete newState["status_intern"];
          return Object.keys(newState).length ? newState : undefined;
        }
        return undefined;
      });
    } else {
      setWhere((prevState) => {
        let newState = {
          status_intern: ["status_intern", "==", newFilter] as WhereOptions,
        };
        if (prevState) {
          newState = { ...prevState, ...newState };
        }
        return newState;
      });
    }
  };
  const handleTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newType = e.target.value as string;
    setFilterType(newType);
    if (newType === "all") {
      setWhere((prevState) => {
        if (prevState) {
          const newState = { ...prevState };
          delete newState["event_type"];
          return Object.keys(newState).length ? newState : undefined;
        }
        return undefined;
      });
    } else {
      setWhere((prevState) => {
        let newState = {
          event_type: ["event_type", "==", newType] as WhereOptions,
        };
        if (prevState) {
          newState = { ...prevState, ...newState };
        }
        return newState;
      });
    }
  };

  const toggleSort = useCallback(
    (field: string) => () => {
      if (order[1] === "asc") {
        setOrder([field, "desc"]);
      } else {
        setOrder([field, "asc"]);
      }
    },
    [order, setOrder]
  );

  return (
    <PageContainer title={"WebHooks"}>
      <FormControl className={cls.formControl}>
        <InputLabel id="il-filter">Status</InputLabel>
        <Select
          labelId="il-filter"
          id="ds-filter"
          value={filter}
          onChange={handleChange}
        >
          <MenuItem value={"all"}>all</MenuItem>
          <MenuItem value={"open"}>Open</MenuItem>
          <MenuItem value={"ready"}>Ready</MenuItem>
          <MenuItem value={"complete"}>Complete</MenuItem>
          <MenuItem value={"error"}>Error</MenuItem>
        </Select>
      </FormControl>

      <FormControl className={cls.formControl}>
        <InputLabel id="il-event">EventType</InputLabel>
        <Select
          labelId="il-event"
          id="ds-event"
          value={filterType}
          onChange={handleTypeChange}
        >
          <MenuItem value={"all"}>all</MenuItem>
          <MenuItem value={"exs.order.completed"}>Order completed</MenuItem>
          <MenuItem value={"exs.license.upgraded"}>License upgraded</MenuItem>
          <MenuItem value={"exs.license.migrated"}>License migrated</MenuItem>
          <MenuItem value={"exs.license.created"}>License created</MenuItem>
          <MenuItem value={"exs.license.extended"}>License extended</MenuItem>
          <MenuItem value={"exs.license.expired"}>License expired</MenuItem>
          <MenuItem value={"exs.license.released"}>License released</MenuItem>
          <MenuItem value={"exs.license.bound"}>License bound</MenuItem>
        </Select>
      </FormControl>
      <LoadingWrapper loading={!webhooks}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  ID{" "}
                  {order[0] === "id" ? (
                    <FontAwesomeIcon
                      onClick={toggleSort("id")}
                      icon={
                        order[1] === "desc"
                          ? [ICON_STYLE, "sort-alpha-down"]
                          : [ICON_STYLE, "sort-alpha-up"]
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={toggleSort("id")}
                      icon={[ICON_STYLE, "sort"]}
                    />
                  )}
                </TableCell>
                <TableCell>EventType</TableCell>
                <TableCell>Infos</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>
                  Created{" "}
                  {order[0] === "created_at" ? (
                    <FontAwesomeIcon
                      onClick={toggleSort("created_at")}
                      icon={
                        order[1] === "desc"
                          ? [ICON_STYLE, "sort-amount-down"]
                          : [ICON_STYLE, "sort-amount-up"]
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={toggleSort("created_at")}
                      icon={[ICON_STYLE, "sort"]}
                    />
                  )}
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {webhooks &&
                Object.keys(webhooks).map(
                  (id) =>
                    webhooks[id] && (
                      <TableRow key={webhooks[id].id}>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to={`${webhooks[id].id}`}
                          >
                            {webhooks[id].id}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {webhooks[id].event_type}
                          {webhooks[id].test ? (
                            <>
                              {" "}
                              <Chip label={"test"} color={"secondary"} />
                            </>
                          ) : null}
                        </TableCell>
                        <TableCell>{exsBasicInfo(webhooks[id])}</TableCell>
                        <TableCell>
                          {webhookStatusLabel(
                            webhooks[id].status_intern as string
                          )}
                        </TableCell>
                        <TableCell>
                          <ReactTimeago date={webhooks[id].created_at} />
                        </TableCell>
                        <TableCell>
                          {["open", "error"].includes(
                            webhooks[id].status_intern as string
                          ) ? (
                            <Retry text={null} id={webhooks[id].id} />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    )
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="text-center my-5">
          <Button
            variant={"contained"}
            onClick={() => setLimit((limit) => limit + 15)}
          >
            more
          </Button>
        </div>
      </LoadingWrapper>
    </PageContainer>
  );
};

export default WebhookView;
