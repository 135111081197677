import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Alert } from "@material-ui/lab";
import {
  BookingBase,
  BookingStatus,
  BundleBooking,
  BundleT,
  BundleType,
} from "@dash.bar/types";
import IDLabel from "../../../../../components/IDLabel";
import BookingStatusLabel from "../../../../../components/BookingStatusLabel";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTimeago from "react-timeago";
import Table from "../../../../../components/Table";
import HtmlTooltip from "../../../../../components/HtmlTooltip";
import PageContainer from "../../../../../components/PageContainer";
import { ICON_STYLE } from "../../../../../utils/constants";

type TabBookingsProps = {
  bookings: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
  companyId: string;
};

export interface ExtensionBooking extends BookingBase {
  extension: string;
  authorizations: BundleT;
}

const TabBookings = ({ bookings }: TabBookingsProps) => {
  // const [showAdd, setShowAdd] = useState(false);
  const [, setHasBundle] = useState(false);
  const [, setBundle] = useState<BundleType>();

  useEffect(() => {
    if (bookings.size)
      bookings.docs.map((doc) => {
        if ((doc.data() as BookingBase).key === "BUNDLE") {
          setHasBundle(true);
          setBundle((doc.data() as BundleBooking).bundle);
        }
      });
  }, [bookings, setHasBundle]);

  return bookings.empty ? (
    <Alert color={"warning"}>No Bookings found.</Alert>
  ) : (
    <PageContainer
      className="mixn-h-screen"
      title={"Bookings"}
      bgColor="bg-gray-800"
      /*onAdd={() => setShowAdd(true)}*/
    >
      <Table
        striped
        header={{
          id: { content: "ID" },
          type: { content: "Type" },
          name: { content: "Name" },
          status: { content: "Status" },
          value: { content: "Value" },
          startDate: { content: "Start Date" },
          endDate: { content: "End Date" },
        }}
      >
        {bookings.docs.map((bookingDoc) => {
          const bookingBase = bookingDoc.data() as BookingBase;
          const color = getBookingLabelColor(bookingBase);
          if ((bookingBase.key as string).toLowerCase() === "bundle") {
            const booking = bookingBase as BundleBooking;
            return (
              <tr key={bookingDoc.id}>
                <td>
                  <IDLabel className={color} label={bookingDoc.id} truncate />
                </td>
                <td>
                  <code>{booking.key}</code>
                </td>
                <td>
                  <b>{booking.bundle}</b>
                </td>
                <td className="text-center">
                  <BookingStatusLabel label={booking.status as any} />
                  {/* @ts-ignore */}
                  {booking.isTrial ? (
                    <div
                      className={`px-2 py-1 mx-1 inline rounded shadow bg-green-500`}
                    >
                      TRIAL
                    </div>
                  ) : null}
                  {/* @ts-ignore */}
                  {booking.isMigration ? (
                    <div
                      className={`px-2 py-1 mx-1 inline rounded shadow bg-blue-300`}
                    >
                      MIGRATED
                    </div>
                  ) : null}
                </td>
                <td className="text-center">
                  <HtmlTooltip
                    title={
                      <pre>
                        {JSON.stringify(booking.authorizations, null, 2)}
                      </pre>
                    }
                  >
                    <Button>
                      <FontAwesomeIcon icon={[ICON_STYLE, "question-circle"]} />
                    </Button>
                  </HtmlTooltip>
                </td>

                <td>
                  <ReactTimeago date={booking.startDate.toDate()} />
                </td>
                <td>
                  <ReactTimeago date={booking.endDate?.toDate() || 0} />
                </td>
              </tr>
            );
          } else if (bookingBase.key.toLowerCase() === "extension") {
            const booking = bookingBase as ExtensionBooking;
            return (
              <tr>
                <td>
                  <IDLabel className={color} label={bookingDoc.id} truncate />
                </td>
                <td>
                  <code>{booking.key}</code>
                </td>
                <td>
                  <b>{booking.extension}</b>
                </td>
                <td className="text-center">
                  <BookingStatusLabel label={booking.status as any} />
                </td>
                <td className="text-center">
                  <HtmlTooltip
                    title={
                      <pre>
                        {JSON.stringify(booking.authorizations, null, 2)}
                      </pre>
                    }
                  >
                    <Button>
                      <FontAwesomeIcon icon={[ICON_STYLE, "question-circle"]} />
                    </Button>
                  </HtmlTooltip>
                </td>
                <td>
                  <ReactTimeago date={booking.startDate.toDate()} />
                </td>
                <td>
                  <ReactTimeago date={booking.endDate?.toDate() || 0} />
                </td>
              </tr>
            );
          }
        })}
      </Table>

      {/*showAdd && bookings && (
        <Modal>
          <AddBooking
            companyId={companyId}
            onlyAddons={hasBundle}
            onCancel={() => setShowAdd(false)}
          />
        </Modal>
      )*/}
    </PageContainer>
  );
};

export default TabBookings;

const getBookingLabelColor = (booking: BookingBase) => {
  const isStarted = booking.startDate.toDate().getDate() < new Date().getDate();
  const isEnded = booking.endDate
    ? booking.endDate.toDate().getDate() > new Date().getDate()
    : false;

  switch (booking.status) {
    case BookingStatus.ACTIVE:
      return isStarted && !isEnded
        ? "bg-green-400"
        : isEnded
        ? "bg-gray-400"
        : "bg-blue-200";
    case BookingStatus.INACTIVE:
      return isStarted && !isEnded
        ? "bg-red-400"
        : isEnded
        ? "bg-gray-400"
        : "bg-red-200";
    case BookingStatus.PENDING:
      return isStarted && !isEnded
        ? "bg-yellow-400"
        : isEnded
        ? "bg-gray-400"
        : "bg-yellow-200";
  }
};
