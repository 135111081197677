import React from "react";
import { Route, Routes } from "react-router-dom";
import ShorturlList from "./list";
import Details from "./details";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";
import DetailsNew from "./details/index_new";

const ShorturlModule = () => {
  return (
    <Routes>
      <Route path={`:keyword`} element={<DetailsNew />} />
      <Route path="/" element={<ShorturlList />} />
    </Routes>
  );
};

export default withRole(UserRole.ShortURL)(ShorturlModule);
