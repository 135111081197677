import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import getDashBar from "../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import { Button, Fab, Grid, TextField } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import PopupView from "./popup";

const Popups = () => {
  const cls = useStyles();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(15);
  const [coupons, setCoupons] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();
  const [couponsFiltered, setCouponsFiltered] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();

  const [searchString, setSearchString] = useState<string | null>(null);

  useEffect(() => {
    getDashBar()
      .db.collection("popup")
      .limit(limit)
      .onSnapshot((snap) => {
        setCoupons(snap.docs ?? []);
      });
  }, [setCoupons, limit]);

  useEffect(() => {
    if (searchString && searchString !== null && coupons) {
      const filtered = coupons?.filter((coupon) => {
        if (
          coupon.data().code.toLowerCase().includes(searchString.toLowerCase())
        )
          return true;
        if (
          coupon.data().name.toLowerCase().includes(searchString.toLowerCase())
        )
          return true;
        return false;
      });
      setCouponsFiltered(filtered);
    } else if (coupons) {
      setCouponsFiltered(coupons);
    }
  }, [searchString, coupons]);

  return (
    <>
      <PageContainer title={"Popups"}>
        <>
          <div className="my-4">
            <TextField
              type={"text"}
              placeholder={"Suchen"}
              variant="outlined"
              onChange={(value) => {
                setSearchString(value.target.value);
              }}
            />
          </div>
          {couponsFiltered && (
            <Grid container spacing={2}>
              {Object.entries(couponsFiltered).map(([key, value]) => (
                <Grid item xs={12} key={key}>
                  <PopupView key={value.id} id={value.id} data={value.data()} />
                </Grid>
              ))}
              <Grid
                container
                spacing={2}
                className="text-center my-3"
                justifyContent={"center"}
              >
                <Button
                  variant="contained"
                  onClick={() => setLimit((limit) => limit + 15)}
                >
                  more
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      </PageContainer>
      <Fab
        className={cls.fab}
        onClick={() => navigate(`new`)}
        color={"primary"}
      >
        <Add />
      </Fab>
    </>
  );
};

export default Popups;
