import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as RouterLink } from "react-router-dom";
import TimeAgo from "react-timeago";
import { shorten } from "../../utils/funcs";
import React from "react";
import LoadingWrapper from "../../components/LoadingWrapper";
import useCollectionGroup from "../../firestore/hooks/useCollectionGroup";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";
import { ShortURLLog } from "../../firestore/models/ShortURLLog";
import { Skeleton } from "@material-ui/lab";

const ShortURL = () => {
  const logs = useCollectionGroup<ShortURLLog>(
    "sllog",
    undefined,
    ["clickTime", "desc"],
    3
  );

  return (
    // <LoadingWrapper loading={logs === undefined}>
    <>
      {/* <Card>
      <CardHeader avatar={<FontAwesomeIcon icon={['fad', 'thunderstorm']}/>} title={"Last Shorturl Clicks"}/>
      <CardContent> */}
      {logs === undefined ? (
        <>
          <Skeleton
            width={"100%"}
            height={90}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
          <Skeleton
            width={"100%"}
            height={90}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
          <Skeleton
            width={"100%"}
            height={90}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
        </>
      ) : (
        <List dense disablePadding>
          {logs &&
            Object.keys(logs).map((id) => (
              <ListItem key={logs[id].clickTime.toMillis()} disableGutters>
                <ListItemText
                  primary={
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Link
                          component={RouterLink}
                          to={`/shorturl/${logs[id].shortUrl}`}
                        >
                          <strong>{logs[id].shortUrl}</strong>
                        </Link>
                      </Grid>
                      <Grid item>
                        <TimeAgo date={logs[id].clickTime.toDate()} />
                      </Grid>
                    </Grid>
                  }
                  secondary={
                    <>
                      {logs[id].referrer ? (
                        <>
                          <strong>Referer</strong>: {shorten(logs[id].referrer)}
                          <br />
                        </>
                      ) : null}
                      {logs[id].userAgent ? (
                        <>
                          <strong>UserAgent</strong>:{" "}
                          {shorten(logs[id].userAgent)}
                          <br />
                        </>
                      ) : null}
                      {logs[id].ipAddress ? (
                        <>
                          <strong>IP</strong>: {shorten(logs[id].ipAddress)}
                          <br />
                        </>
                      ) : null}
                    </>
                  }
                />
              </ListItem>
            ))}
        </List>
      )}
      {/* </CardContent>
    </Card> */}
    </>
  );
};

export default withRole(UserRole.ShortURL)(ShortURL);
