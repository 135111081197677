//MUI Green
const mui_green_50 = `#E8F5E9`;
const mui_green_100 = `#C8E6C9`;
const mui_green_200 = `#A5D6A7`;
const mui_green_300 = `#81C784`;
const mui_green_400 = `#66BB6A`;
const mui_green_500 = `#4CAF50`;
const mui_green_600 = `#43A047`;
const mui_green_700 = `#388E3C`;
const mui_green_800 = `#2E7D32`;
const mui_green_900 = `#1B5E20`;

//MUI Blue
const mui_blue_50 = `#E3F2FD`;
const mui_blue_100 = `#BBDEFB`;
const mui_blue_200 = `#90CAF9`;
const mui_blue_300 = `#64B5F6`;
const mui_blue_400 = `#42A5F5`;
const mui_blue_500 = `#2196F3`;
const mui_blue_600 = `#1E88E5`;
const mui_blue_700 = `#1976D2`;
const mui_blue_800 = `#1565C0`;
const mui_blue_900 = `#0D47A1`;

//MUI LightGreen
const mui_lightgreen_50 = `#F1F8E9`;
const mui_lightgreen_100 = `#DCEDC8`;
const mui_lightgreen_200 = `#C5E1A5`;
const mui_lightgreen_300 = `#AED581`;
const mui_lightgreen_400 = `#9CCC65`;
const mui_lightgreen_500 = `#8BC34A`;
const mui_lightgreen_600 = `#7CB342`;
const mui_lightgreen_700 = `#689F38`;
const mui_lightgreen_800 = `#558B2F`;
const mui_lightgreen_900 = `#33691E`;

//MUI Red
const mui_red_50 = `#FFEBEE`;
const mui_red_100 = `#FFCDD2`;
const mui_red_200 = `#EF9A9A`;
const mui_red_300 = `#E57373`;
const mui_red_400 = `#EF5350`;
const mui_red_500 = `#F44336`;
const mui_red_600 = `#E53935`;
const mui_red_700 = `#D32F2F`;
const mui_red_800 = `#C62828`;
const mui_red_900 = `#B71C1C`;

//MUI Red
const mui_deeporange_50 = `#FBE9E7`;
const mui_deeporange_100 = `#FFCCBC`;
const mui_deeporange_200 = `#FFAB91`;
const mui_deeporange_300 = `#FF8A65`;
const mui_deeporange_400 = `#FF7043`;
const mui_deeporange_500 = `#FF5722`;
const mui_deeporange_600 = `#F4511E`;
const mui_deeporange_700 = `#E64A19`;
const mui_deeporange_800 = `#D84315`;
const mui_deeporange_900 = `#BF360C`;

//MUI Grey
const mui_grey_50 = `#FAFAFA`;
const mui_grey_100 = `#F5F5F5`;
const mui_grey_200 = `#EEEEEE`;
const mui_grey_300 = `#E0E0E0`;
const mui_grey_400 = `#BDBDBD`;
const mui_grey_500 = `#9E9E9E`;
const mui_grey_600 = `#757575`;
const mui_grey_700 = `#616161`;
const mui_grey_800 = `#424242`;
const mui_grey_900 = `#212121`;

const STATES = {
  ACTIVE: mui_green_700,
  CANCELED: mui_red_600,
  PENDING: mui_grey_400,
};

const DASHBAR = {
  FREE: `#f5a623`,
  BUSINESS: `#2985d0`,
  PRO: `#73157c`,
};

const GREY = {
  "50": mui_grey_50,
  "100": mui_grey_100,
  "200": mui_grey_200,
  "300": mui_grey_300,
  "400": mui_grey_400,
  "500": mui_grey_500,
  "600": mui_grey_600,
  "700": mui_grey_700,
  "800": mui_grey_800,
  "900": mui_grey_900,
};
const GREEN = mui_green_400;
const RED = mui_red_700;

const ERROR = { DARK: mui_red_600, LIGHT: mui_red_400 };
const SUCCESS = { DARK: mui_green_600, LIGHT: mui_green_400 };

//Components
const PAGECONTAINER = { BACKGROUND: mui_grey_900 };

const TABLE = {
  ROW_EVEN: mui_grey_700,
  ROW_ODD: mui_grey_900,
};

const CARD = {
  HEADER_BACKGROUND: mui_grey_800,
  CONTENT_BACKGROUND: mui_grey_700,
};

const DASHBOARD = {
  BACKGROUND: mui_grey_900,
  CARD: {
    BACKGROUND: mui_grey_800,
  },
  // CHART: {
  //   COLOR_1: mui_grey_400,
  //   COLOR_2: mui_blue_400,
  //   COLOR_3: mui_deeporange_400,
  //   COLOR_4: mui_green_400,
  // },
  // THEMES: {
  //   COLOR_1: mui_grey_400,
  //   COLOR_2: mui_blue_400,
  //   COLOR_3: mui_deeporange_400,
  //   COLOR_4: mui_green_400,
  // },
  CHART: {
    COLOR_1: mui_green_100,
    COLOR_2: mui_green_300,
    COLOR_3: mui_green_600,
    COLOR_4: mui_green_900,
  },
  THEMES: {
    COLOR_1: mui_green_100,
    COLOR_2: mui_green_300,
    COLOR_3: mui_green_600,
    COLOR_4: mui_green_900,
  },
};

//Exports
const COMPONENTS = {
  DASHBOARD,
  PAGECONTAINER,
  TABLE,
  CARD,
};

export { STATES, ERROR, SUCCESS, GREY, GREEN, RED, COMPONENTS, DASHBAR };
