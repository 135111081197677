import * as yup from "yup";
import { AvailablePlatforms } from "./constants";

export const ShortURLSchema = yup.object().shape({
  keyword: yup
    .string()
    .matches(/^[0-9a-zA-Z-]+$/)
    .required(),
  url: yup.string().url().required(),
  title: yup.string().required(),
  category: yup.string().notRequired(),
});

export const NewsEntrySchema = yup.object().shape({
  tags: yup.string().required(),
  text: yup.string().required(),
  mediaUrl: yup.string().url().notRequired(),
  profileImageUrl: yup.string().url().required(),
  userName: yup.string().required(),
});

export const BundleSchema = yup.object().shape({
  type: yup.string().required().oneOf(["main", "addon"]),
  public: yup.boolean().notRequired().default(false),
  price: yup.number().required().min(0),
  discount: yup.number().notRequired().min(0).max(100).default(0),
  id: yup
    .string()
    .required()
    .min(3)
    .max(32)
    .matches(/^[a-z0-9-_]+$/i),
  addons: yup.object().required(),
});

export const PluginSchema = yup.object().shape({
  id: yup
    .string()
    .matches(/^[a-z0-9]+$/i)
    .min(3)
    .max(64)
    .required(),
  name: yup.string().required(),
  description: yup.string().default("").notRequired(),
  documentationUrl: yup.string().url().notRequired(),
  shopUrl: yup.string().url().notRequired(),
});

export const Amount = yup.object().shape({
  currency: yup.string().required().oneOf(["EUR"]).default("EUR"),
  value: yup
    .string()
    .required()
    .matches(/[0-9]+.[0-9][0-9]/),
});

export const BundlePrice = yup.object().shape({
  month: Amount.required(),
  year: Amount.required(),
});

export const ExtensionPrice = yup.object().shape({
  free: BundlePrice.required(),
  business: BundlePrice.required(),
  pro: BundlePrice.required(),
});

export const ExtensionSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  name_eng: yup.string().required(),
  addons: yup.object().required(),
  descriptionData: yup.object().required(),
  descriptionData_eng: yup.object().required(),
  bundleFree: yup.boolean(),
  sevdesk_partId: yup.number(),
  bundleBusiness: yup.boolean(),
  bundlePro: yup.boolean(),
  description: yup.string().required(),
  description_eng: yup.string().required(),
  descriptionLong: yup.string().required(),
  descriptionLong_eng: yup.string().required(),
  sort: yup.number().required().default(0),
  price: ExtensionPrice.required(),
});

export const CouponSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  code: yup.string().required(),
  //type: yup.string().required(),
  amountValue: yup.string().notRequired().nullable().default("0.00"),
  validUntil: yup.date().notRequired(),
  percentage: yup.number().notRequired().nullable().default(0).min(0).max(100),
  maxUses: yup.number().notRequired().nullable().default(-1),
});

export const PopupSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  users: yup.string().notRequired(),
  actionUrl: yup.string().required(),
});

export const CampaignSchema = yup.object().shape({
  id: yup.string().required(),
  url: yup.string().required(),
  name: yup.string().required(),
  //imagePath: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  priority: yup.number().min(1).max(100).required(),
  //active: yup.bool().required(),
});

export const AdConfigSchema = yup.object().shape({
  action: yup.string().required(),
  text: yup.string().required(),
});

export const ExtensionConfigSchema = yup.object().shape({
  extension: yup.string().required(),
  image: yup.string().required(),
});

export const PluginPlatformSchema = yup.object().shape({
  platform: yup.string().required().oneOf(Object.keys(AvailablePlatforms)),
  pluginId: yup
    .string()
    .required()
    .matches(/^[a-z0-9_]+$/i),
  checkoutUrl: yup.string().url().notRequired(),
  options: yup.object().shape({
    isPublic: yup.boolean().required().default(false),
    useLicence: yup.boolean().required().default(false),
    exs: yup
      .object()
      .shape({
        id: yup
          .string()
          .matches(/^$|^[a-f0-9-]+$/i)
          .max(36)
          .nullable(),
        artNo: yup
          .string()
          .matches(/^$|^exs_[a-z0-9_]+$/)
          .nullable(),
        url: yup.string().url().nullable(),
      })
      .nullable(),
    gitlab: yup
      .object()
      .shape({
        id: yup.number().nullable(),
        url: yup.string().url().nullable(),
      })
      .nullable(),
    requirements: yup.object().shape({
      minPHP: yup
        .string()
        .oneOf([
          ...AvailablePlatforms.jtlshop4.phpVersions,
          ...AvailablePlatforms.jtlshop5.phpVersions,
        ])
        .default("")
        .required(),
      maxPHP: yup
        .string()
        .oneOf([
          "",
          ...AvailablePlatforms.jtlshop4.phpVersions,
          ...AvailablePlatforms.jtlshop5.phpVersions,
        ])
        .default("")
        .notRequired(),
      minSQL: yup
        .string()
        .oneOf([
          ...AvailablePlatforms.jtlshop4.sqlVersions,
          ...AvailablePlatforms.jtlshop5.sqlVersions,
        ])
        .default("")
        .required(),
      maxSQL: yup
        .string()
        .oneOf([
          "",
          ...AvailablePlatforms.jtlshop4.sqlVersions,
          ...AvailablePlatforms.jtlshop5.sqlVersions,
        ])
        .default("")
        .notRequired(),
      minVersion: yup
        .string()
        .oneOf([
          ...AvailablePlatforms.jtlshop4.shopVersions,
          ...AvailablePlatforms.jtlshop5.shopVersions,
        ])
        .default("")
        .required(),
      maxVersion: yup
        .string()
        .oneOf([
          "",
          ...AvailablePlatforms.jtlshop4.shopVersions,
          ...AvailablePlatforms.jtlshop5.shopVersions,
        ])
        .default("")
        .notRequired(),
    }),
  }),
});
