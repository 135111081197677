import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import HtmlTooltip from "../../../../../../components/HtmlTooltip";
import { functions } from "../../../../../../firebase";
import { ICON_STYLE } from "../../../../../../utils/constants";

interface IKeyRowProps {
  id: string;
  apikey: string;
  name: string;
  scopes: Array<string>;
  operations: Array<string>;
  affId?: string;
}

const KeyRow = ({
  id,
  apikey,
  name,
  scopes,
  operations,
  affId,
}: IKeyRowProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showToken, setShowToken] = useState<boolean>(false);

  const deleteKey = functions().httpsCallable("calls-api-deleteApiKey");

  const deleteCurrent = async () => {
    //ToDo create DeleteFunction which also deletes images etc
    if (!window.confirm("Soll dieser API-Key wirklich gelöscht werden?"))
      return;
    setLoading(true);
    await deleteKey({ id: id });
    setLoading(false);
  };

  return (
    <div
      key={`${id}_${name}`}
      style={{
        backgroundColor: "#171717",
        width: "100%",
        borderRadius: 10,
        padding: 3,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 4,
      }}
      className="flex flex-row"
    >
      <Grid
        container
        spacing={1}
        style={{}}
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={2}>
          <Typography
            style={{
              color: "#EEE",
              fontSize: 16,
              fontFamily: "Roboto",
              fontWeight: 500,
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div>
            <input
              style={{
                borderRadius: 10,
                padding: 3,
                backgroundColor: "#000",
                minWidth: 300,
              }}
              type={showToken ? "text" : "password"}
              readOnly={true}
              value={apikey}
            />
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                setShowToken(true);
              }}
              variant="outlined"
              size="small"
            >
              Anzeigen
            </Button>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <HtmlTooltip title={<pre>{JSON.stringify(scopes, null, 2)}</pre>}>
              <Button>
                <div
                  className={`px-2 py-1 inline rounded shadow white bg-gray-700`}
                >
                  SCOPES
                </div>
              </Button>
            </HtmlTooltip>
            <HtmlTooltip
              title={<pre>{JSON.stringify(operations, null, 2)}</pre>}
            >
              <Button>
                <div
                  className={`px-2 py-1 inline rounded shadow white bg-gray-700`}
                >
                  OPERATIONS
                </div>
              </Button>
            </HtmlTooltip>
            {affId && (
              <HtmlTooltip
                title={
                  <pre>{JSON.stringify({ Affiliate_ID: affId }, null, 2)}</pre>
                }
              >
                <Button>
                  <div
                    className={`px-2 py-1 inline rounded shadow white bg-gray-700`}
                  >
                    Affiliate
                  </div>
                </Button>
              </HtmlTooltip>
            )}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className="flex flex-row justify-end content-center items-center">
            <IconButton
              size={"small"}
              disabled={loading}
              title="Löschen"
              style={{ margin: "0 5px" }}
              onClick={deleteCurrent}
            >
              <FontAwesomeIcon icon={[ICON_STYLE, "trash"]} size={"sm"} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default KeyRow;
