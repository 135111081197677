import { Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const OldDashboard = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        padding: 30,
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          navigate(`/v1`);
        }}
        title="Zum Dashboard"
      >
        Zum Dashboard
      </Button>
    </div>
  );
};

export default OldDashboard;
