import {
  OrderByOptions,
  useFirestoreConnect,
  WhereOptions,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { IDProp, SelectorStateType } from "../types";
import md5 from "md5";

const useCollection = <T>(
  collection: string,
  where?: WhereOptions | WhereOptions[],
  orderBy?: OrderByOptions | OrderByOptions[],
  limit = 15
): undefined | Record<string, T & IDProp> => {
  const storeHash = (
    where
      ? `col_${collection}_${md5(JSON.stringify([where]))}`
      : `col_${collection}`
  ).replaceAll("/", "_");

  useFirestoreConnect({
    collection: collection,
    orderBy: orderBy,
    limit: limit,
    where: where,
    storeAs: storeHash,
  });
  return useSelector((state: SelectorStateType<T>) => {
    if (!state.firestore.ordered[storeHash]) {
      return undefined;
    }
    return (state.firestore.ordered[storeHash] || {}) as Record<
      string,
      T & IDProp
    >;
  });
};

export default useCollection;
