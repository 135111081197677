import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import useTheme from "@material-ui/core/styles/useTheme";
import PageContainer from "../../../../components/PageContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getDashBar from "../../../../firebase/dashbar";
import { useSnackbar } from "notistack";
import HtmlTooltip from "../../../../components/HtmlTooltip";
import moment from "moment";
import { ICON_STYLE } from "../../../../utils/constants";
const apppng = require("./assets/app.png");
type FlatrateProps = {
  id: string;
  data: Record<string, any>;
};

const PopupView = ({ id, data }: FlatrateProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const deleteCoupon = async (id: string) => {
    if (window.confirm(`Really want to delete Popup '${data?.name}'?`)) {
      getDashBar()
        .db.doc(`popup/${id}`)
        .delete()
        .then(() => {
          enqueueSnackbar("Success: Deleted Popup " + data?.name, {
            variant: "success",
          });
        })
        .catch(() => {
          enqueueSnackbar("Error: Deleting Popup " + data?.name, {
            variant: "error",
          });
        });
    }
  };
  const resetPopup = async (id: string) => {
    if (window.confirm(`Really want to reset Popup '${data?.name}'?`)) {
      getDashBar()
        .db.doc(`popup/${id}`)
        .set({ used: [] }, { merge: true })
        .then(() => {
          enqueueSnackbar("Success: Reset Popup " + data?.name, {
            variant: "success",
          });
        })
        .catch(() => {
          enqueueSnackbar("Error: Reseting Popup " + data?.name, {
            variant: "error",
          });
        });
    }
  };

  return (
    <>
      <Accordion defaultExpanded={process.env.NODE_ENV !== "production"}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid
            container
            justifyContent={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <strong>{data.name}</strong>
            </Grid>
            <Grid item>
              <strong>{data.code}</strong>
            </Grid>
            <Grid item>
              <IconButton
                size={"medium"}
                title="reset"
                onClick={() => resetPopup(id)}
                style={{ margin: "0 5px" }}
              >
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "redo"]}
                  size={"sm"}
                  color={theme.palette.primary.main}
                />
              </IconButton>
              <IconButton
                size={"medium"}
                title="delete"
                onClick={() => deleteCoupon(id)}
                style={{ margin: "0 5px" }}
              >
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "trash"]}
                  size={"sm"}
                  color={theme.palette.error.main}
                />
              </IconButton>
              <IconButton
                size={"medium"}
                title="edit"
                component={RouterLink}
                to={`${id}/edit`}
                style={{ margin: "0 5px" }}
              >
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "edit"]}
                  size={"sm"}
                  color={theme.palette.primary.main}
                />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="flex flex-wrap flex-row">
          <PageContainer
            className="w-full min-w-full"
            title="General"
            heading={3}
            bgColor="bg-gray-800"
          >
            <dl className={"horizontal center"}>
              <dt>Name:</dt>
              <dd>{data?.name || "n/a"}</dd>
              <dt>Active:</dt>
              <dd>
                <span
                  className={`rounded mr-4 ${
                    data?.active ? "bg-green-500" : "bg-red-500"
                  } px-2 py-0.5`}
                >
                  {data?.active ? "Aktiv" : "Inaktiv"}
                </span>
              </dd>
              <dt>Users:</dt>
              <dd>
                <HtmlTooltip
                  title={<pre>{JSON.stringify(data.users || "", null, 2)}</pre>}
                >
                  <Button>
                    <FontAwesomeIcon icon={[ICON_STYLE, "question-circle"]} />
                  </Button>
                </HtmlTooltip>
              </dd>
              <dt>Action:</dt>
              <dd>{data?.actionUrl || "n/a"}</dd>
              <dt>No Abo/Free:</dt>
              <dd>
                <span
                  className={`rounded mr-4 ${
                    data?.noAbo ? "bg-green-500" : "bg-red-500"
                  } px-2 py-0.5`}
                >
                  {data?.noAbo ? "Aktiv" : "Inaktiv"}
                </span>
              </dd>
              <dt>{`ValidFor:`}</dt>
              <dd className="flex flex-wrap">
                {data?.validFor
                  ? data?.validFor.map((option: string) => {
                      return (
                        <span
                          className={`rounded mr-4 bg-gray-900 px-2 py-0.5`}
                        >
                          {option}
                        </span>
                      );
                    })
                  : "n/a"}
              </dd>
              <dt>ValidFrom:</dt>
              <dd>
                {data?.validFrom
                  ? moment(data.validFrom.toDate()).format("DD.MM.YYYY hh:mm")
                  : "n/a"}
              </dd>
              <dt>ValidUntil:</dt>
              <dd>
                {data?.validUntil
                  ? moment(data.validUntil.toDate()).format("DD.MM.YYYY hh:mm")
                  : "n/a"}
              </dd>
              <dt>Connected Coupon:</dt>
              <dd>
                {data?.coupon ? (
                  <a href={`/dashbar/coupons/${data.coupon}`}>{data.coupon}</a>
                ) : (
                  "n/a"
                )}
              </dd>
              <dt>Shown:</dt>
              <dd>
                <span
                  className={`rounded mr-4 bg-gray-200 px-2 py-0.5 text-black`}
                >
                  {data?.used?.length || 0}
                </span>
              </dd>
            </dl>
          </PageContainer>
          <PageContainer
            className="w-full min-w-full"
            title="Images"
            heading={3}
            bgColor="bg-gray-800"
          >
            <div>
              <img src={apppng} />
              <img
                width={250}
                src={data.imageLarge}
                srcSet={data.imageLarge}
                style={{ position: "absolute", top: 150, left: 225 }}
              />
              <img
                width={80}
                src={data.imageSmall}
                srcSet={data.imageSmall}
                style={{ position: "absolute", top: 305, left: 566 }}
              />
            </div>
          </PageContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PopupView;
