import useCollection from "../useCollection";
import useAuth from "../useAuth";

const useNotifications = () => {
  const auth = useAuth();

  const notifications = useCollection<Record<string, any>>(
    `user/${auth.uid}/notifications`,
    undefined,
    ["createTime", "desc"],
    8
  );

  const notificationsArray = Object.entries(notifications ?? {})
    .filter(([_k, v]) => {
      return v !== undefined && v !== null;
    })
    .map(([_k, v]) => {
      return { ...v };
    });

  return notificationsArray;
};

export default useNotifications;
