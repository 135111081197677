import axios, { AxiosError } from "axios";

export type ResultType = {
  res_index: number;
  res_name: string;
  success: boolean;
  status?: number;
  message?: string;
  meta?: string;
};

export const checkReachable = async (
  shop: Record<string, any>
): Promise<ResultType> => {
  let status: number = 0;
  try {
    const result = await axios.get(shop.endpoint);
    status = +result.status;
  } catch (e) {
    const err = e as AxiosError;
    status = +(err?.response?.status || 0);
  }
  switch (status) {
    case 401:
      return {
        res_index: 0,
        res_name: `Shop Erreichbarkeit`,
        success: true,
        meta: `Status: ${status}`,
        message: `Die Datei api.php wurde erreicht`,
      };
    default:
      return {
        res_index: 0,
        res_name: `Shop Erreichbarkeit`,
        success: false,
        meta: `Status: ${status}`,
        message: `Die Datei api.php konnte nicht erreicht werden`,
      };
  }
};

export const checkAuthorization = async (
  shop: Record<string, any>
): Promise<ResultType> => {
  let status: number = 0;
  try {
    const result = await axios.get(shop.endpoint, {
      headers: { Authorization: `Bearer ${shop.secretToken}` },
    });
    status = +result.status;
  } catch (e) {
    const err = e as AxiosError;
    status = +(err?.response?.status || 0);
  }
  switch (status) {
    case 400:
      return {
        res_index: 1,
        res_name: `Shop Authentifizierung`,
        success: true,
        meta: `Status: ${status}`,
        message: `Die Authentifizierung mit dem SecretToken war erfolgreich`,
      };
    default:
      return {
        res_index: 1,
        res_name: `Shop Authentifizierung`,
        success: false,
        meta: `Status: ${status}`,
        message: `Die Authentifizierung mit dem SecretToken schlug fehl`,
      };
  }
};

export const checkData = async (
  shop: Record<string, any>
): Promise<ResultType> => {
  let status: number = 0;
  try {
    const result = await axios.post(
      shop.endpoint,
      {
        request: "Analytics",
        widget: {
          order: {
            options: {
              active: true,
              details: false,
              refresh: undefined,
              interval: `D1`,
              pagination: { descending: true, page: 1, rowsPerPage: 1 },
            },
          },
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${shop.secretToken}`,
          "User-Agent":
            "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17",
        },
        maxRedirects: 0,
      }
    );
    status = +result.status;
  } catch (e) {
    const err = e as AxiosError;
    status = +(err?.response?.status || 0);
  }
  switch (status) {
    case 200:
      return {
        res_index: 2,
        res_name: `Datenabfrage aus Shop`,
        success: true,
        meta: `Status: ${status}`,
        message: `Der Abruf von Testdaten war erfolgreich`,
      };
    default:
      return {
        res_index: 2,
        res_name: `Datenabfrage aus Shop`,
        success: false,
        meta: `Status: ${status}`,
        message: `Der Abruf von Testdaten war nicht möglich`,
      };
  }
};

export const checkPluginVersion = (
  shop: Record<string, any>,
  config: Record<string, any> | null
): ResultType => {
  if (config === null) {
    return {
      res_index: 3,
      res_name: `Plugin Version`,
      success: false,
      meta: `Prüfung auf Plugin-Version nicht möglich!`,
    };
  }
  const currentPluginVersion = config?.current_plugin_version?.trim() ?? null;
  const oldVersionUsed =
    config !== null &&
    config?.current_plugin_version.trim() !==
      shop?.platform?.pluginVersion.trim();
  if (oldVersionUsed === true) {
    return {
      res_index: 3,
      res_name: `Plugin Version`,
      success: false,
      meta: `Aktuelle Plugin-Version: ${currentPluginVersion}`,
    };
  } else {
    return {
      res_index: 3,
      res_name: `Plugin Version`,
      success: true,
    };
  }
};
