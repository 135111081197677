import { IconPrefix } from "@fortawesome/fontawesome-svg-core";

export type AvailablePlatfromType = {
  name: string;
  phpVersions: string[];
  sqlVersions: string[];
  shopVersions: string[];
};

export const AvailablePlatforms = {
  jtlshop4: {
    name: "JTL-Shop 4",
    phpVersions: ["5.6", "7.0", "7.1", "7.2", "7.3", "7.4", "8.0"],
    sqlVersions: ["5.5", "5.6", "5.7", "8.0"],
    shopVersions: ["404", "405", "406"],
  },
  jtlshop5: {
    name: "JTL-Shop 5",
    phpVersions: ["7.3", "7.4", "8.0"],
    sqlVersions: ["5.6", "5.7", "8.0"],
    shopVersions: ["5.0.0"],
  },
} as Record<string, AvailablePlatfromType>;

export const phpVersions = {
  jtlshop4: ["5.6", "7.0", "7.1", "7.2", "7.3", "7.4", "8.0"],
  jtlshop5: ["7.3", "7.4", "8.0"],
} as Record<string, string[]>;
export const sqlVersions = {
  jtlshop4: ["5.5", "5.6", "5.7", "8.0"],
  jtlshop5: ["5.6", "5.7", "8.0"],
} as Record<string, string[]>;
export const shopVersions = {
  jtlshop4: ["404", "405", "406"],
  jtlshop5: ["5.0.0"],
} as Record<string, string[]>;

export const ICON_STYLE: IconPrefix = "fas";
