import React, { useEffect, useState } from "react";
import PageContainer from "../../../../components/PageContainer";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import dashbar from "../../../../firebase/dashbar";
import { IShop } from "@dash.bar/types";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import TabPanel, { a11yProps } from "../../../../components/TabPanel";
import TabInfo from "./tabs/tabInfo";
import TabNotifications from "./tabs/tabNotifications";
import TabConnectionCheck from "./tabs/tabConnectionCheck";

const ShopDetails = () => {
  const { id, cid } = useParams<{ id: string; cid: string }>();
  const [doc, setShop] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();

  const [notifications, setNotifications] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();

  const [tab, setTab] = useState(0);

  useEffect(
    () =>
      dashbar()
        .db.collection(`/company/${cid}/shop`)
        .doc(id)
        .onSnapshot(setShop),
    [cid, id, setShop]
  );

  useEffect(
    () =>
      dashbar()
        .db.collection(`/company/${cid}/shop/${id}/notification`)
        .limit(15)
        .orderBy("receivedAt", "desc")
        .onSnapshot(setNotifications),
    [cid, id, setNotifications]
  );

  const shop = doc?.data() as IShop;

  const companyID = doc?.ref.parent?.parent?.id;

  return (
    <PageContainer title={"Shop"}>
      <AppBar title="Shop" position="static">
        <Tabs
          value={tab}
          onChange={(_, val) => setTab(val)}
          aria-label="shps tabs example"
        >
          <Tab label="Info" {...a11yProps(0)} />
          <Tab label="Notifications" {...a11yProps(1)} />
          <Tab label="Connection Check" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      {tab === 0 && (
        <TabPanel value={tab} index={0}>
          {shop && <TabInfo shop={shop} companyID={companyID} />}
        </TabPanel>
      )}

      {tab === 1 && (
        <TabPanel value={tab} index={1}>
          {notifications && <TabNotifications notifications={notifications} />}
        </TabPanel>
      )}
      {tab === 2 && (
        <TabPanel value={tab} index={2}>
          {shop && <TabConnectionCheck shop={shop} companyID={companyID} />}
        </TabPanel>
      )}
    </PageContainer>
  );
};

export default ShopDetails;
