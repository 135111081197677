import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import getDashBar from "../../../../firebase/dashbar";
import ReportConfigCardBundle from "./BundleConfigCard";
import ReportConfigCardShop from "./ShopConfigCard";
import SponsorCard from "./SponsorCard";
import { useNavigate } from "react-router-dom";

const ReportConfigNewView = () => {
  const [reportConfig, setReportConfig] = useState<Record<string, any>>({});
  const navigate = useNavigate();
  // console.log(reportConfig);

  useEffect(() => {
    getDashBar()
      .db.doc(`settings/reportConfig`)
      .get()
      .then((doc) => {
        setReportConfig(doc.data() || {});
      });
  }, []);
  return (
    <div style={{ marginTop: 20 }}>
      <div className="flex flex-row justify-end w-full">
        <Button
          style={{ marginRight: 15 }}
          variant="outlined"
          onClick={() => {
            navigate("reports/reportConfig/edit");
          }}
        >
          Bearbeiten
        </Button>
      </div>
      <Grid container spacing={2} className="h-60 mt-24">
        <Grid xs={2}>
          <Typography
            style={{ fontSize: 16, fontWeight: "lighter", marginLeft: 5 }}
          >
            Sponsor
          </Typography>
        </Grid>
        <Grid xs={4}>
          <SponsorCard sponsor={reportConfig.sponsor} />
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>
      <Grid container className="h-60">
        <Grid xs={2}>
          <Typography
            style={{ fontSize: 16, fontWeight: "lighter", marginLeft: 5 }}
          >
            Bundle-Einstellungen
          </Typography>
        </Grid>
        <Grid xs={2}>
          <ReportConfigCardBundle
            allow={reportConfig?.allow ? reportConfig.allow["daily"] : []}
            force={reportConfig?.force ? reportConfig.force["daily"] : []}
            title="Daily"
          />
        </Grid>
        <Grid xs={2}>
          <ReportConfigCardBundle
            allow={reportConfig?.allow ? reportConfig.allow["weekly"] : []}
            force={reportConfig?.force ? reportConfig.force["weekly"] : []}
            title="Weekly"
          />
        </Grid>
        <Grid xs={2}>
          <ReportConfigCardBundle
            allow={reportConfig?.allow ? reportConfig.allow["monthly"] : []}
            force={reportConfig?.force ? reportConfig.force["monthly"] : []}
            title="Monthly"
          />
        </Grid>
        <Grid xs={2}>
          <ReportConfigCardBundle
            allow={reportConfig?.allow ? reportConfig.allow["quarterly"] : []}
            force={reportConfig?.force ? reportConfig.force["quarterly"] : []}
            title="Quarterly"
          />
        </Grid>
      </Grid>
      <Grid container className="h-60">
        <Grid xs={2}>
          <Typography
            style={{ fontSize: 16, fontWeight: "lighter", marginLeft: 5 }}
          >
            Shop-Einstellungen
          </Typography>
        </Grid>
        <Grid xs={2}>
          <ReportConfigCardShop
            title={"JTL"}
            enabled={
              reportConfig?.jtlEnabled !== undefined
                ? reportConfig.jtlEnabled
                : false
            }
            minVersion={
              reportConfig?.minJtlVersion ? reportConfig.minJtlVersion : "n/a"
            }
          />
        </Grid>
        <Grid xs={2}>
          <ReportConfigCardShop
            title={"Shopware"}
            enabled={
              reportConfig?.shopwareEnabled !== undefined
                ? reportConfig.shopwareEnabled
                : false
            }
            minVersion={
              reportConfig?.minShopwareVersion
                ? reportConfig.minShopwareVersion
                : "n/a"
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportConfigNewView;
