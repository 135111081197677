import React from "react";
import {Link as RouterLink} from 'react-router-dom'
import {Link} from '@material-ui/core'

export type IDLabelProps = {
  label: string
  to?: string
  href?: string
  onClick?: () => void
  truncate?: boolean
  className?: string
}

const truncateStr = function (fullStr: string, strLen: number, separator?: string) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

  return fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars);
};

const IDLabel = ({to, label, href, truncate = false, className, onClick}: IDLabelProps) => {

  const cls = [
    'px-2 py-1 m-1',
    'bg-gray-400',
    'text-white font-bold',
    'rounded shadow',
    'inline-block',
    'font-mono',
    onClick || href || to ? 'cursor-pointer' : '',
    className
  ];

  const myLabel = truncate ? truncateStr(label, 12, '..') : label

  if (onClick) {

    return <div className={cls.join(' ')} onClick={onClick}>{myLabel}</div>
  }

  if (href) {
    return <Link className={cls.join(' ')} href={href}>{myLabel}</Link>
  }

  if (to) {
    return <RouterLink className={cls.join(' ')} to={to}>{myLabel}</RouterLink>
  }

  return <div className={cls.join(' ')}>{myLabel}</div>
}

export default IDLabel;