import React from "react";
import {ExsLicense} from "@dash.bar/types";
import {Grid} from "@material-ui/core";
import RawJSON from "./RawJSON";

const Licence = ({webhook}: { webhook: ExsLicense }) => {


    return <Grid container spacing={3}>

        <Grid item xs={12}>
            <RawJSON object={webhook}/>
        </Grid>
    </Grid>

}

export default Licence;
