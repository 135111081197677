import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import CheckIcon from "../CheckIcon";

const ReportConfigCardBundle = ({
  title,
  allow,
  force,
}: {
  title: string;
  allow: Array<string>;
  force: Array<string>;
}) => {
  const isAllowed = (bundle: string): boolean =>
    allow.includes(bundle.toLowerCase());

  const isForced = (bundle: string): boolean =>
    force.includes(bundle.toLowerCase());

  return (
    <Card
      style={{
        width: "90%",
        borderRadius: 15,
        backgroundColor: `#323232`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "lighter", marginBottom: 10 }}
        >
          {title}
        </Typography>
        <Grid
          container
          style={{ alignContent: "center", alignItems: "center" }}
        >
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              Allow
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              Force
            </Typography>
          </Grid>
          <Grid item xs={6}>
            free
          </Grid>
          <Grid item xs={3}>
            <CheckIcon checked={isAllowed("free")} />
          </Grid>
          <Grid item xs={3}>
            <CheckIcon checked={isForced("free")} />
          </Grid>
          <Grid item xs={6}>
            business
          </Grid>
          <Grid item xs={3}>
            <CheckIcon checked={isAllowed("business")} />
          </Grid>
          <Grid item xs={3}>
            <CheckIcon checked={isForced("business")} />
          </Grid>
          <Grid item xs={6}>
            pro
          </Grid>
          <Grid item xs={3}>
            <CheckIcon checked={isAllowed("pro")} />
          </Grid>
          <Grid item xs={3}>
            <CheckIcon checked={isForced("pro")} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReportConfigCardBundle;
