const mapping = {
  [`abilita Pay`]: "ws5_abilitapay",
  [`abocloud`]: "ws5_abocloud",
  ["Affiliate Programm"]: "ws5_affiliateprogramm",
  ["Aftersales"]: "ws5_aftersales",
  ["attriXus"]: "ws5_attrixus",
  ["Autolinker"]: "ws5_autolinker",
  ["Better Payment"]: "ws5_betterpayment",
  ["Billie"]: "ws5_billie",
  ["Billiger.de"]: "ws5_billigerde",
  [`Blogbuster`]: "ws5_blogbuster",
  ["BoniGateway"]: null,
  [`Booster`]: "ws5_booster",
  ["Bounce Landingpage"]: "ws5_bouncelandingpage",
  ["Brevo"]: "ws5_sendinblue",
  ["Contact Forms"]: "ws5_contact_forms",
  ["Cookiebot"]: "ws5_cookiebot",
  // ["Coupon Plus"]: "ws5_couponplus",
  ["dash.bar"]: "ws5_dashbar",
  ["doofinder"]: "ws5_doofinder",
  ["easycredit"]: "ws5_easycredit",
  ["emarketing"]: "ws5_emarketing",
  ["EU Cookie"]: "ws5_eucookie",
  // ["Facebook Pixel"]: "ws5_facebookpixel",
  ["Glossary"]: "ws5_glossary",
  ["Google Bewertungen"]: "ws5_googlebewertungen",
  ["Google Tracking"]: "ws5_googletracking",
  ["Hinweis Fenster"]: null,
  ["Indiviuelle Angebote"]: null,
  ["Kundengruppen B2C/B2B"]: "ws5_b2b",
  ["loopingo"]: "ws5_loopingo",
  ["Maileon"]: "ws5_maileon",
  ["Meta Pixel"]: "ws5_facebookpixel",
  ["Microsoft Advertising"]: "ws5_microsoftadvertising",
  ["Microsoft Clarity"]: "ws5_clarity",
  ["Mollie"]: "ws5_mollie",
  ["Nosto"]: "ws5_nosto",
  ["Oft zusammen gekauft"]: "ws5_oftzusammengekauft",
  ["OSS Lieferland"]: "ws5_oss",
  ["Payone"]: "ws5_payone",
  ["Pricium"]: "ws5_pricium",
  ["Rabatt Coupon Master"]: "ws5_couponplus",
  ["Releva.nz Remarketing"]: "ws5_relevanz",
  ["Review Connect"]: "ws5_reviewconnect",
  ["Sendcloud"]: "ws5_sendcloud",
  ["SMS Service by LINK Mobility"]: "ws5_linkmobility",
  ["Tagbox"]: "ws5_taggbox",
  ["Trackboxx"]: "ws5_trackboxx",
  ["TikTok Pixel"]: "ws5_tiktokpixel",
  ["Tipps & Empfehlungen zum Produkt"]: "ws5_mitarbeiterempfehlung",
  ["Touchicons"]: "ws5_touchicons",
  ["Trustpilot"]: "ws5_trustpilot",
  ["Ultimate SEO"]: "ws5_ultimateseo",
  ["Uptain"]: "ws5_uptain",
  ["Unregistered Ratings"]: "ws5_unregisteredratings",
  ["Verfügbarkeit Plus"]: "ws5_verfuegbarkeit_plus",
  ["Video Connect"]: "ws5_videoconnect",
  ["Warenkorb Rabatt"]: "ws5_warenkorbrabatt",
};
export const getCFPluginByID = (id: string): string | null => {
  const cfPlugin = Object.entries(mapping)
    .filter(([k, v]) => {
      return v === id;
    })
    .map(([k]) => {
      return k;
    })
    .reduce<string | null>((_prev, curr) => {
      return curr !== "" ? curr : null;
    }, null);
  return cfPlugin;
};
