import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import React from "react";
import { UserRole } from "../../../firestore/models";
import useProfile from "../../../hooks/firestore/useProfile";
import useAuth from "../../../hooks/useAuth";
import { ICON_STYLE } from "../../../utils/constants";

interface IDashboardCardProps {
  children: React.ReactElement;
  title: string;
  size?: "small" | "medium" | "large" | "larger" | "xlarge";
  role?: UserRole;
}

const DashboardCard = ({
  children,
  title,
  role,
  size = "medium",
}: IDashboardCardProps) => {
  const profile = useProfile();
  const auth = useAuth();
  if (role) {
    if (auth && profile?.roles && profile?.roles?.includes(role)) {
      return (
        <DCard title={title} role={role} size={size}>
          {children}
        </DCard>
      );
    } else {
      return null;
    }
  } else {
    return (
      <DCard title={title} role={role} size={size}>
        {children}
      </DCard>
    );
  }
};

const DCard = ({
  children,
  title,
  size,
  role,
}: {
  children: React.ReactElement;
  title: string;
  size: "small" | "medium" | "large" | "larger" | "xlarge";
  role?: UserRole;
}) => {
  const getHeight = (): number => {
    switch (size) {
      case "xlarge":
        return 1200;
      case "larger":
        return 1050;
      case "large":
        return 800;
      case "small":
        return 200;
      case "medium":
      default:
        return 400;
    }
  };

  return (
    <Card
      style={{
        borderRadius: 15,
        height: getHeight(),
        // backgroundColor: "#000000",
        background: `linear-gradient(to right bottom, #000000, #242526)`,
      }}
    >
      <CardHeader
        title={
          <Typography
            style={{
              fontSize: 26,
              fontWeight: "lighter",
            }}
          >
            {title}
          </Typography>
        }
        action={
          role && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                borderRadius: 10,
                backgroundColor: "white",
                padding: 5,
                margin: 5,
              }}
            >
              <FontAwesomeIcon
                color={/*"#19781f"*/ "#000"}
                icon={[ICON_STYLE, "user"]}
              />
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  marginLeft: 5,
                  color: "black",
                }}
              >
                {role}
              </Typography>
            </div>
          )
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default DashboardCard;
