import { Card } from "@material-ui/core";
import React from "react";
import { useDocument } from "../../../firestore/hooks";
import ActionBar from "./components/ActionBar";
import Booked from "./components/Booked";
import Overview from "./components/Overview";
import Preview from "./components/Preview";
import { useParams } from "react-router-dom";

const Campaign = () => {
  const { id } = useParams();

  const campaign = useDocument<Record<string, any>>("campaigns", id);

  if (!campaign || campaign === null) return null;

  return (
    <>
      <ActionBar />
      <div className="w-full flex flex-col justify-center items-center mt-12">
        <div className="w-full lg:w-1/2">
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Overview campaign={campaign} />
            <Booked campaign={campaign} />
            <Preview campaign={campaign} />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Campaign;
