import React, { useEffect, useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import firebase from "firebase/compat/app";
import { OLD_Plugin } from "@dash.bar/types";
import { useSetDocument } from "../../../../../firestore/hooks";
import Input from "../../../../../components/Input";

const Ads = ({ plugin }: { plugin: OLD_Plugin | null | undefined }) => {
  const [loading, setLoading] = useState<boolean>(false);

  //Action URL
  const [actionUrl, setActionUrl] = useState<string>("");

  //Action Text
  const [actionText, setActionText] = useState<string>("");

  //Advertising Image
  const [advertisingImage, setAdvertisingImage] = useState<File>();
  const [advertisingImageUrl, setAdvertisingImageUrl] = useState<
    string | undefined
  >();

  // const [ad, setAd] = useState<File>();
  // const [adUrl, setAdUrl] = useState<string>();
  const updatePlugin = useSetDocument<OLD_Plugin>("plugin");
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");

  // const form = useForm<AdConfig>({
  //   resolver: yupResolver(AdConfigSchema),
  // });

  useEffect(() => {
    if (plugin?.adConfig?.image)
      setAdvertisingImageUrl(plugin?.adConfig?.image);
    if (plugin?.adConfig?.action) setActionUrl(plugin?.adConfig?.action);
    if (plugin?.adConfig?.text) setActionText(plugin?.adConfig?.text);
  }, [plugin?.adConfig]);

  const save = async () => {
    setLoading(true);
    updatePlugin(
      { adConfig: { action: actionUrl, text: actionText } },
      plugin?.pluginId
    );
    await uploadAdvertisingImage();
  };

  // const handleSave = async (data: AdConfig) => {
  //   updatePlugin({ adConfig: data }, plugin?.pluginId);
  //   try {
  //     if (ad && ad !== null) {
  //       await uploadAd(plugin?.pluginId || "");
  //     }
  //     enqueueSnackbar("Success: Saved Ads" + { variant: "success" });
  //   } catch (e) {
  //     enqueueSnackbar("Something went wrong." + e, { variant: "error" });
  //     console.log(e);
  //   }
  // };

  //OnChange Handlers
  const onActionUrlChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionUrl(event.currentTarget.value);
  };

  const onActionTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionText(event.currentTarget.value);
  };

  const onAdChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setAdvertisingImage(e.currentTarget?.files[0]);
    }
  };

  const deleteAdvertisingImage = async () => {
    if (!plugin?.pluginId) return;
    if (
      !window.confirm("Really want to delete the ad-image? (Can not be undone)")
    )
      return;
    const ref = storage.ref(`plugins/ads/${plugin.pluginId}`);
    await ref.delete();
    updatePlugin({ adConfig: { image: undefined } }, plugin.pluginId);
    setAdvertisingImageUrl(undefined);
    return;
  };

  const uploadAdvertisingImage = async () => {
    if (!advertisingImage || advertisingImage === null || !plugin?.pluginId)
      return;
    const url = URL.createObjectURL(advertisingImage);
    const response = await fetch(url);
    const blob = await response.blob();
    const ref = storage.ref(`plugins/ads/${plugin?.pluginId}_adImage`);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const adUrl = await ref.getDownloadURL();
    setAdvertisingImageUrl(adUrl);
    updatePlugin({ adConfig: { image: adUrl } }, plugin?.pluginId);
    return;
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full">
        <Card
          style={{
            padding: 20,
            borderRadius: 15,
            flex: 1,
            flexDirection: "column",
            alignContent: "flex-start",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
            spacing={1}
            className="w-full"
          >
            <Grid item xs={3}>
              <Label text="Action URL:" />
            </Grid>
            <Grid item xs={9}>
              <Input
                type={"text"}
                placeholder={"URL"}
                onChange={onActionUrlChanged}
                value={actionUrl}
              />
            </Grid>
            <Grid item xs={3}>
              <Label text="Action Text:" />
            </Grid>
            <Grid item xs={9}>
              <Input
                type={"text"}
                placeholder={"Text"}
                onChange={onActionTextChanged}
                value={actionText}
              />
            </Grid>
            <Grid item xs={3}>
              <Label text="Bild:" />
            </Grid>
            <Grid item xs={9}>
              <Input
                type={"file"}
                accept="image/*,.txt"
                placeholder={"Datei"}
                onChange={onAdChanged}
              />
            </Grid>
            {advertisingImageUrl && (
              <>
                <Grid item xs={3}>
                  <Label text="" />
                </Grid>
                <Grid item xs={6}>
                  <img
                    className="max-h-32 mx-3 rounded mt-2"
                    alt="No Ad Image"
                    src={advertisingImageUrl}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button variant={"outlined"} onClick={deleteAdvertisingImage}>
                    Löschen
                  </Button>
                </Grid>
              </>
            )}
            {/* <Grid item xs={3}>
              <Label text="Vorschau:" />
            </Grid>
            <Grid item xs={9}>
              <Card
                style={{
                  backgroundColor: "#fff",
                  padding: 20,
                  borderRadius: 15,
                }}
              >
                <img
                  id="adlinkimage"
                  alt={"ad"}
                  style={{
                    borderTopRightRadius: 15,
                    borderTopLeftRadius: 15,
                    width: "100%",
                    height: "75%",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    margin: "auto",
                  }}
                  src={advertisingImageUrl}
                />
                <div style={{ flex: 1, height: "25%", paddingTop: 5 }}>
                  <a
                    style={{
                      color: "black",
                      backgroundColor: "black",
                      margin: "auto",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 15,
                      paddingRight: 15,
                      borderRadius: 25,
                      marginTop: 18,
                      marginBottom: 16,
                      fontSize: 13,
                      fontWeight: "bolder",
                    }}
                    href={actionUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={[ICON_STYLE, "link"]} />
                    {actionText}
                  </a>
                </div>
              </Card>
            </Grid> */}
          </Grid>
          <div className="flex w-full justify-around">
            <Button variant="contained" disabled={loading} onClick={save}>
              Speichern
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
  // return (
  //   <>
  //     <h3>Ads</h3>
  //     <form onSubmit={form.handleSubmit(handleSave)}>
  //       <Grid
  //         container
  //         justifyContent="flex-start"
  //         alignContent="center"
  //         alignItems="center"
  //         spacing={1}
  //         className="w-full"
  //       >
  //         <Grid item xs={4}>
  //           <Label text="Name:" />
  //         </Grid>
  //         <Grid item xs={8}>
  //           <Input
  //             type={"text"}
  //             placeholder={"Plugin Name"}
  //             onChange={onPluginNameChanged}
  //             value={pluginName}
  //           />
  //         </Grid>
  //       </Grid>
  //       <Grid container spacing={3}>
  //         <>
  //           <Grid item xs={10}>
  //             <p>Ad Image</p>
  //             <input accept="image/*" type="file" onChange={onAdChanged} />
  //           </Grid>
  //           <Grid item xs={2}>
  //             <img
  //               className="max-h-16 mx-3 rounded mt-2"
  //               alt="No Ad Image"
  //               src={adUrl}
  //             />
  //             {adUrl && (
  //               <Button
  //                 variant={"outlined"}
  //                 color={"primary"}
  //                 onClick={deleteAd}
  //               >
  //                 löschen
  //               </Button>
  //             )}
  //           </Grid>

  //           <Grid item xs={12}>
  //             <TextField
  //               label="Action URL:"
  //               fullWidth
  //               inputProps={form.register("action")}
  //               error={!!form.formState.errors.action}
  //               defaultValue={plugin?.adConfig?.action}
  //               helperText={form.formState.errors.action?.message ?? null}
  //             />
  //           </Grid>
  //           <Grid item xs={12}>
  //             <TextField
  //               label="Action Text:"
  //               fullWidth
  //               inputProps={form.register("text")}
  //               error={!!form.formState.errors.text}
  //               defaultValue={plugin?.adConfig?.text}
  //               helperText={form.formState.errors.text?.message ?? null}
  //             />
  //           </Grid>
  //           <Grid item xs={12} style={{ textAlign: "center" }}>
  //             <Button variant={"outlined"} color={"primary"} type={"submit"}>
  //               speichern
  //             </Button>
  //           </Grid>
  //         </>
  //       </Grid>
  //     </form>
  //   </>
  // );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default Ads;
