import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getAbocloud from "../../../../../firebase/abocloud";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../utils/constants";
import moment from "moment";

const UserTab = () => {
  const { mid } = useParams<{ mid: string }>();
  const [user, setUser] = useState<Array<Record<string, any>> | null>(null);

  useEffect(() => {
    if (!mid || mid === null) return;
    getAbocloud()
      .db.collection(`merchants/${mid}/user`)
      .get()
      .then((users) => {
        const userArray: Array<Record<string, any>> = [];
        users.forEach((user) => {
          userArray.push({ ...user.data(), id: user.id });
        });
        setUser(userArray);
      });
  }, [mid]);

  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignContent: "stretch",
        alignItems: "stretch",
        gap: 4,
      }}
    >
      {user
        ?.sort((v1, v2) => {
          if (v1.role > v2.role) return 1;
          if (v1.role < v2.role) return -1;
          return 0;
        })
        .map((userObj) => {
          return (
            <div
              style={{
                width: "33%",
                padding: 5,
                borderRadius: 10,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "#111",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {userObj.createTime && (
                <div
                  style={{
                    top: 3,
                    right: 3,
                    display: "flex",
                    alignSelf: "flex-end",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    backgroundColor: "#DDD",
                    borderRadius: 5,
                    padding: 4,
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: 10,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {`${moment(userObj.createTime.toDate()).format(
                      "DD.MM.YYYY"
                    )}`}
                  </Typography>
                </div>
              )}
              <FontAwesomeIcon
                style={{
                  marginTop: 2,
                  fontSize: 45,
                  textAlign: "center",
                  verticalAlign: "middle",
                  margin: 8,
                }}
                size="5x"
                color={"#111"}
                icon={[
                  ICON_STYLE,
                  userObj.role === 0
                    ? "user-tie"
                    : userObj.role === 1
                    ? "user"
                    : "user-graduate",
                ]}
              />
              <Typography
                style={{
                  color: "#111",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {`${userObj.firstname ?? `n/a`} ${userObj.lastname ?? ``}`}
              </Typography>
              <Typography
                style={{
                  color: "#111",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {userObj.email ?? `n/a`}
              </Typography>
            </div>
          );
        })}
    </div>
  );
};

export default UserTab;
