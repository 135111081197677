import { Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const ActionBar = () => {
  const navigate = useNavigate();

  const createCampaign = () => {
    navigate(`create/campaign`);
  };

  const createAdvertisingSpace = () => {
    navigate(`create/adspace`);
  };

  const editDefaultValues = () => {
    navigate(`edit/default`);
  };

  const showOverview = () => {
    navigate(`overview`);
  };

  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
      }}
      className="flex"
    >
      <Button variant="outlined" onClick={createCampaign}>
        Kampagne erstellen
      </Button>
      <Button variant="outlined" onClick={createAdvertisingSpace}>
        Werbeplatz erstellen
      </Button>
      {/* <Button variant="outlined" onClick={showOverview}>
        Planer
      </Button> */}
      <Button variant="outlined" onClick={editDefaultValues}>
        Standard bearbeiten
      </Button>
    </div>
  );
};

export default ActionBar;
