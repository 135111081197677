import React from "react";
import { Route, Routes } from "react-router-dom";
import PluginInstalls from "./PluginInstalls";

const DebugModule = () => {
  return (
    <Routes>
      <Route path={`plugin/installs`} element={<PluginInstalls />} />
    </Routes>
  );
};

export default DebugModule;
