import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";

const SponsorCard = ({ sponsor }: { sponsor: string | null }) => {
  return (
    <Card
      style={{
        width: "100%",
        height: "150",
        borderRadius: 15,
        backgroundColor: "white",
      }}
    >
      <CardContent>
        <div
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            flex: 1,
          }}
        >
          <img
            style={{ maxHeight: 150, margin: "auto" }}
            src={"https://ws-cdn.de/marketing/dashbar/reports/sponsor"}
            alt={"Sponsor Logo"}
            loading="lazy"
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            flex: 1,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: "lighter",
                  marginBottom: 0,
                  color: "black",
                }}
              >
                Sponsor:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "normal",
                  marginBottom: 0,
                  color: "black",
                }}
              >
                {sponsor || "n/a"}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default SponsorCard;
