import { Button, Grid, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Input from "../../../../components/Input";
import { DB } from "../../../../../../firebase";
import moment from "moment";

const EditModal = ({
  cid,
  licenseid,
  open,
  setOpen,
}: {
  cid: string;
  licenseid: string;
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [created, setCreated] = useState<Date | null>(null);
  const [licenseKey, setLicenseKey] = useState<string | null>(null);

  const onStartDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(new Date(event.currentTarget.value));
  };
  const onEndDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(new Date(event.currentTarget.value));
  };

  useEffect(() => {
    if (licenseid.trim() === "") return;
    setLoading(true);
    DB()
      .collection(`customer/${cid}/licenses`)
      .doc(licenseid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          if (docData !== undefined) {
            setStartDate(docData?.license?.startDate?.toDate() ?? new Date());
            setEndDate(docData?.license?.endDate?.toDate() ?? new Date());
            setCreated(docData?.license?.created?.toDate() ?? null);
            setLicenseKey(docData?.license?.licenseKey ?? null);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [cid, licenseid]);

  const saveLicense = async () => {
    setLoading(true);
    await DB()
      .collection(`customer/${cid}/licenses`)
      .doc(licenseid)
      .set(
        {
          license: {
            startDate: startDate,
            endDate: endDate,
            ...(licenseKey !== null && { licenseKey: licenseKey }),
            ...(created !== null && { created: created }),
          },
          updateTime: new Date(),
        },
        { merge: true }
      );
    setOpen(false);
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          width: "100%",
          minHeight: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "40%",
            borderRadius: 15,
            backgroundColor: "#222",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            padding: 30,
          }}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
            spacing={1}
            className="w-full"
          >
            <Grid item xs={6}>
              <Input
                type={"date"}
                placeholder={"Gültig ab"}
                onChange={onStartDateChanged}
                value={
                  startDate &&
                  moment(startDate ?? undefined).format(`YYYY-MM-DD`)
                }
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Input
                type={"date"}
                placeholder={"Gültig bis"}
                onChange={onEndDateChanged}
                value={
                  endDate && moment(endDate ?? undefined).format(`YYYY-MM-DD`)
                }
                readOnly={loading}
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Abbrechen
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={saveLicense}
            >
              Speichern
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
