import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DB } from "../../../../firebase";
import moment from "moment";
import { IComponentProps } from "../../helper/ComponentProps";

const MarketingShortUrls = ({ id }: IComponentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [urlData, setUrlData] = useState<
    Array<{
      name: string;
      count: number;
    }>
  >([]);

  useEffect(() => {
    // const getUrlLogs = async () => {
    //   //   setLoading(id, true);
    setLoading(true);

    DB()
      .collection("dashboard")
      .doc("shorturls")
      .get()
      .then((urls) => {
        setUrlData(urls.data()?.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  if (loading) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={1} alignItems={"center"}>
        {urlData
          .filter((_v, index) => {
            return index < 8;
          })
          .map((v, index) => {
            return (
              <>
                <Grid item xs={2} style={{ margin: 0 }}>
                  <Typography style={{ fontSize: 14, fontWeight: "light" }}>
                    {`${index + 1}.`}
                  </Typography>
                </Grid>
                <Grid item xs={7} style={{ margin: 0 }}>
                  <Typography style={{ fontSize: 14, fontWeight: "light" }}>
                    {v.name}
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ margin: 0 }}>
                  <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                    {v.count}
                  </Typography>
                </Grid>
              </>
            );
          })}
      </Grid>
    </div>
  );
};

export default MarketingShortUrls;
