import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { functions } from "../../../../../firebase";
import DashboardCard from "../../general/DashboardCard";
import { COMPONENTS } from "../../../../../constants/colors";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {
  CategoryScale,
  Chart,
  LineElement,
  LinearScale,
  PointElement,
} from "chart.js";
import { UserRole } from "../../../../../firestore/models";

const getDayLabel = (day: number) => {
  switch (day) {
    case 0:
      return `Mo`;
    case 1:
      return `Di`;
    case 2:
      return `Mi`;
    case 3:
      return `Do`;
    case 4:
      return `Fr`;
    case 5:
      return `Sa`;
    case 6:
      return `So`;
  }
};

const TicketsV2 = () => {
  Chart.register(CategoryScale, LinearScale, PointElement, LineElement);
  const [loading, setLoading] = useState<boolean>(true);
  const [tickets, setTickets] = useState<Record<string, any> | null>(null);
  const getTicketStats = functions().httpsCallable(
    "calls-freshdesk-getTicketStats"
  );

  useEffect(() => {
    setLoading(true);
    getTicketStats({})
      .then((data) => {
        console.log(data.data);
        setTickets(data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const dataLastWeek = tickets?.lastWeek
    .map((v: Record<string, any>) => {
      return getDayLabel(moment(v.created_at).day() - 1);
    })
    .reduce(
      (prev: Record<string, any>, curr: number) => {
        if (prev?.[curr]) {
          return { ...prev, [curr]: prev[curr] + 1 };
        } else {
          return { ...prev, [curr]: 1 };
        }
      },
      { Mo: 0, Di: 0, Mi: 0, Do: 0, Fr: 0, Sa: 0, So: 0 }
    ) ?? { Mo: 0, Di: 0, Mi: 0, Do: 0, Fr: 0, Sa: 0, So: 0 };

  const dataLastWeekArray = Object.entries(dataLastWeek).map((v) => {
    return v;
  });

  const dataByPluginLastWeek =
    tickets?.lastWeek
      .map((v: Record<string, any>) => {
        return v.plugin;
      })
      .reduce((prev: Record<string, any>, curr: number) => {
        if (prev?.[curr]) {
          return { ...prev, [curr]: prev[curr] + 1 };
        } else {
          return { ...prev, [curr]: 1 };
        }
      }, {}) ?? {};

  const dataByPluginLastWeekArray = Object.entries<number>(dataByPluginLastWeek)
    .sort((v1, v2) => {
      if (v1[1] > v2[1]) {
        return -1;
      } else if (v1[1] < v2[1]) {
        return 1;
      } else {
        return 0;
      }
    })
    .filter((v) => {
      return v[0] !== null && v[0] !== "null" && v[0] !== undefined;
    })
    .map((v) => {
      return v;
    });

  const dataThisWeek = tickets?.thisWeek
    .map((v: Record<string, any>) => {
      return getDayLabel(moment(v.created_at).day() - 1);
    })
    .reduce(
      (prev: Record<string, any>, curr: number) => {
        if (prev?.[curr]) {
          return { ...prev, [curr]: prev[curr] + 1 };
        } else {
          return { ...prev, [curr]: 1 };
        }
      },
      { Mo: 0, Di: 0, Mi: 0, Do: 0, Fr: 0, Sa: 0, So: 0 }
    ) ?? { Mo: 0, Di: 0, Mi: 0, Do: 0, Fr: 0, Sa: 0, So: 0 };

  const dataThisWeekArray = Object.entries(dataThisWeek).map((v) => {
    return v;
  });

  const dataByPluginThisWeek =
    tickets?.thisWeek
      .map((v: Record<string, any>) => {
        return v.plugin;
      })
      .reduce((prev: Record<string, any>, curr: number) => {
        if (prev?.[curr]) {
          return { ...prev, [curr]: prev[curr] + 1 };
        } else {
          return { ...prev, [curr]: 1 };
        }
      }, {}) ?? {};

  const dataByPluginThisWeekArray = Object.entries<number>(dataByPluginThisWeek)
    .sort((v1, v2) => {
      if (v1[1] > v2[1]) {
        return -1;
      } else if (v1[1] < v2[1]) {
        return 1;
      } else {
        return 0;
      }
    })
    .filter((v) => {
      return v[0] !== null && v[0] !== "null" && v[0] !== undefined;
    })
    .map((v) => {
      return v;
    });

  const data = {
    labels: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    datasets: [
      {
        label: "Letzte Woche",
        fill: true,
        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_1,
        borderColor: COMPONENTS.DASHBOARD.THEMES.COLOR_1,
        data: dataLastWeekArray,
      },
      {
        label: "Diese Woche",
        fill: true,
        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
        borderColor: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
        data: dataThisWeekArray,
      },
    ],
  };

  return (
    <DashboardCard
      title="Tickets"
      theme="webstollen"
      loading={loading}
      role={UserRole.Operational}
    >
      <Grid container spacing={4} alignItems={"center"}>
        <Grid item xs={5}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={4} alignItems={"center"} justifyContent="center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 20,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {tickets?.today?.length ?? 0}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} alignItems={"center"} justifyContent="center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 20,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {tickets?.thisWeek?.length ?? 0}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} alignItems={"center"} justifyContent="center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 20,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {tickets?.lastWeek?.length ?? 0}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 0,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`Heute`}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 0,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`Diese Woche`}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 0,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`Letzte Woche`}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems={"center"}>
                    <Grid
                      item
                      xs={12}
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <Typography
                        style={{
                          color: "#CCC",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          fontWeight: `500`,
                          marginBottom: 5,
                        }}
                      >
                        {`Diese Woche`}
                      </Typography>
                    </Grid>
                    {dataByPluginThisWeekArray
                      .filter((_v, index) => {
                        return index < 5;
                      })
                      .map((plugin) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={2}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#FFF",
                                  fontSize: 16,
                                  fontFamily: "Roboto",
                                  fontWeight: `500`,
                                }}
                              >
                                {`${plugin[1]}`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#FFF",
                                  fontSize: 14,
                                  fontFamily: "Roboto",
                                  fontWeight: `300`,
                                }}
                              >
                                {`${plugin[0]}`}
                              </Typography>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems={"center"}>
                    <Grid
                      item
                      xs={12}
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <Typography
                        style={{
                          color: "#CCC",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          fontWeight: `500`,
                          marginBottom: 5,
                        }}
                      >
                        {`Letzte Woche`}
                      </Typography>
                    </Grid>
                    {dataByPluginLastWeekArray
                      .filter((_v, index) => {
                        return index < 5;
                      })
                      .map((plugin) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={2}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#BBB",
                                  fontSize: 16,
                                  fontFamily: "Roboto",
                                  fontWeight: `500`,
                                }}
                              >
                                {`${plugin[1]}`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#BBB",
                                  fontSize: 14,
                                  fontFamily: "Roboto",
                                  fontWeight: `300`,
                                }}
                              >
                                {`${plugin[0]}`}
                              </Typography>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={12}>
              <div style={{ maxHeight: 250 }}>
                <Line
                  data={data}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                        position: "top",
                      },
                      title: {
                        display: false,
                        text: "",
                      },
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={1}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        minWidth: 20,
                        minHeight: 20,
                        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `300`,
                      }}
                    >
                      {`Letzte Woche`}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        minWidth: 20,
                        minHeight: 20,
                        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `300`,
                      }}
                    >
                      {`Diese Woche`}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default TicketsV2;
