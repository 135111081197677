import useAuth from "../useAuth";
import useUserSettings from "../firestore/useUserSettings";

import {useEffect, useState} from "react";
import {functions} from "../../firebase";
import { DeveloperSettings } from "../../firestore/models/User";

const GITLAB_LOGIN_URL = "https://gitlab.webstollen.de/oauth/authorize"
const GITLAB_CLIENT_ID = '1f8a062b11b292d424d5e5511ea5beb58cd82abdd96247b12c65341661b95b25';
const GITLAB_REDIRECT_URI = process.env.NODE_ENV === 'production' ? 'https://oauth.ws-svc.de/gitlab' : 'https://proske.2ly.de/gitlab'

type useGitlabReturn = {
  status: string | 'authorized' | 'loading' | 'unauthorized',
  loginUrl?: string
}

export const gitlabAPI = () => functions().httpsCallable('service-gitlab');
export const bitrixAPI = () => functions().httpsCallable('service-bitrix');

const useGitlab = (): useGitlabReturn => {

  const [status, setStatus] = useState('loading');
  const [loginUrl, setLoginUrl] = useState<string | undefined>(undefined);
  const auth = useAuth();
  const developerSettings = useUserSettings<DeveloperSettings>(auth.uid, 'developer');

  useEffect(() => {
    if (developerSettings?.gitlab?.oauth && auth.uid) {
      setStatus('authorized')
    } else {
      if (!loginUrl && auth.uid && !developerSettings?.gitlab?.oauth) {

        setStatus('unauthorized');

        const params = new URLSearchParams({
          client_id: GITLAB_CLIENT_ID,
          redirect_uri: GITLAB_REDIRECT_URI,
          response_type: 'code',
          state: auth.uid,
          scope: 'api',
        })
        setLoginUrl(`${GITLAB_LOGIN_URL}?${params.toString()}`);
      }
    }
  }, [developerSettings, auth.uid, loginUrl])

  return {status, loginUrl};
}

export default useGitlab;
