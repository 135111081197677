import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import getDashBar from "../../../../firebase/dashbar";
import { IComponentProps } from "../../helper/ComponentProps";

const DashbarPopup = ({ setLoading }: IComponentProps) => {
  const [popups, setPopups] = useState<Array<{
    name: string;
    count: number;
  }> | null>(null);
  useEffect(() => {
    // setLoading(true);
    getDashBar()
      .db.collection("popup")
      .get()
      .then((popupList) => {
        const popUpArray: Array<{
          name: string;
          count: number;
        }> = [];
        popupList.forEach((popup) => {
          const data = popup.data();
          popUpArray.push({
            name: data?.name || "Unknown",
            count: data?.used?.length || 0,
          });
        });
        setPopups(popUpArray);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  return (
    <Grid
      container
      spacing={2}
      style={{ flexGrow: 1, width: "100%" }}
      justifyContent={"flex-start"}
      alignItems={"stretch"}
      alignContent="stretch"
    >
      {popups?.map((v) => {
        return (
          <>
            <Grid item xs={5}>
              <Typography
                style={{
                  fontSize: 14,
                  color: "white",
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {v.name}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{
                  fontSize: 16,
                  color: "white",
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
              >
                {v.count}
              </Typography>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};

export default DashbarPopup;
