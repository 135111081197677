import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CouponTable } from "./components/Table";
import getDashBar from "../../../firebase/dashbar";
import { Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const Coupons = () => {
  const [coupons, setCoupons] = useState<Array<Record<string, any>>>([]);
  // const [updating, setUpdating] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (refresh === false) return;
    getDashBar()
      .db.collection(`coupon`)
      .orderBy("code", "desc")
      .get()
      .then((docs) => {
        console.log(docs.size);
        const couponArray: Array<Record<string, any>> = [];
        docs.forEach((doc) => {
          couponArray.push({ ...doc.data(), id: doc.id });
        });
        setCoupons(couponArray);
      })
      .finally(() => {
        setRefresh(false);
      });
  }, [refresh]);

  const onAdd = () => {
    navigate(`/dashbar/coupon/create`);
  };

  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <CouponTable coupons={coupons} />
      <div className="absolute bottom-5 right-5">
        <Fab color="primary" onClick={onAdd}>
          <Add />
        </Fab>
      </div>
    </div>
  );
};
export default Coupons;
