import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import getDashBar from "../../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import "./styles.css";
import { IComponentProps } from "../../helper/ComponentProps";

const DashbarCoupon = ({ setLoading }: IComponentProps) => {
  const [coupons, setCoupons] = useState<Array<{
    name: string;
    code: string;
    count: number;
  }> | null>(null);
  useEffect(() => {
    const loadCoupons = async () => {
      const couponArray: Array<{
        name: string;
        code: string;
        count: number;
      }> = [];
      const couponList = await getDashBar().db.collection("coupons").get();
      const couponRefList: Array<{
        data: Record<string, any>;
        ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
      }> = [];
      couponList.forEach((coupon) => {
        const data = coupon.data();
        couponRefList.push({ data: data, ref: coupon.ref });
      });
      for (const coupon of couponRefList) {
        const usage = await coupon.ref.collection(`used`).get();
        couponArray.push({
          name: coupon.data?.name || "Unknown",
          code: coupon.data?.code || "n/a",
          count: usage.size || 0,
        });
      }
      setCoupons(couponArray);
    };
    loadCoupons();
  }, []);

  console.log(coupons);

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ flexGrow: 1, width: "100%" }}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        alignContent="stretch"
      >
        {coupons
          ?.filter((_v, index) => {
            return index < 4;
          })
          .map((v) => {
            return (
              <>
                <Grid item xs={6}>
                  <div className="card">
                    <div className="main">
                      <div className="co-img">
                        <img
                          src="https://ws-cdn.de/plugins/icons/ws_dashbar.svg"
                          alt="dash.bar"
                        />
                      </div>
                      <div className="vertical"></div>
                      <div className="content">
                        <h2>dash.bar</h2>
                        <h1>{v.name}</h1>
                        <p>{`${v.count}x verwendet`}</p>
                      </div>
                    </div>
                    {/* <div className="copy-button">
                    <input id="copyvalue" type="text" value={v.code} />
                  </div> */}
                  </div>
                </Grid>
              </>
            );
          })}
      </Grid>
    </>
  );
};

export default DashbarCoupon;
