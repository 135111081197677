import { Typography } from "@material-ui/core";
import React from "react";
import { useCollection } from "../../../../../../firestore/hooks";
import CampaignRow from "../CampaignRow";

const PlannedCampaigns = () => {
  const activeCampaigns = useCollection<Record<string, any>>("campaigns", [
    ["active", "==", true],
  ]);

  console.log(activeCampaigns);

  const filteredPlannedCampaigns = Object.entries(activeCampaigns || {})
    .filter(([k, v]) => {
      const validStart = v.startDate.toDate() > new Date();
      const validEnd = v.endDate.toDate() > new Date();
      return validStart && validEnd;
    })
    .map(([k, v]) => {
      return v;
    });

  if (filteredPlannedCampaigns.length <= 0) return null;

  return (
    <div className="w-full lg:w-1/2 flex flex-col mt-8">
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
        }}
      >
        Geplante Kampagnen
      </Typography>
      {filteredPlannedCampaigns.map((campaign) => {
        return (
          <CampaignRow
            key={campaign.id}
            id={campaign.id}
            bitrixRef={campaign.bitrixRef}
            name={campaign.name}
            startDate={campaign.startDate}
            endDate={campaign.endDate}
            color={"#419aba"}
          />
        );
      })}
    </div>
  );
};

export default PlannedCampaigns;
