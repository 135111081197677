import useCollection from "../useCollection";
import useAuth from "../useAuth";

const useNotificationsCount = (): number => {
  const auth = useAuth();

  const notifications = useCollection<Record<string, any>>(
    `user/${auth.uid}/notifications`,
    ["read", "==", false],
    undefined,
    200
  );
  return Object.entries(notifications ?? {}).length;
};

export default useNotificationsCount;
