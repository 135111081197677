import { AppBar, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import TabPanel, { a11yProps } from "../../../components/TabPanel";
import BookingTab from "./tabs/bookingTab";
import WatcherTab from "./tabs/watcherTab";
import DomainChangeTab from "./tabs/domainChangeTab";
import FailedOrdersTab from "./tabs/failedOrders";
import { useSearchParams } from "react-router-dom";

const Operations = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [queryParameters] = useSearchParams();
  const tab = queryParameters.get("tab");

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case "invoice":
          setCurrentTab(3);
          break;
        default:
          setCurrentTab(0);
      }
    }
  }, []);

  return (
    <>
      <PageContainer title={"Operations"}>
        <AppBar title="Operations" position="static">
          <Tabs
            value={currentTab}
            onChange={(_, val) => setCurrentTab(val)}
            aria-label="operations tabs"
          >
            <Tab label="Bookings" {...a11yProps(0)} />
            <Tab label="Watcher" {...a11yProps(1)} />
            <Tab label="Domain ändern" {...a11yProps(2)} />
            <Tab label="Rechnungen" {...a11yProps(3)} />
            {/* <Tab label="Users" {...a11yProps(1)} />
            <Tab label="Shops" {...a11yProps(2)} />
            <Tab label="Notifications" {...a11yProps(3)} />
            <Tab label="Bookings" {...a11yProps(4)} /> */}
          </Tabs>
        </AppBar>
        {currentTab === 0 && (
          <TabPanel value={currentTab} index={0}>
            {<BookingTab />}
          </TabPanel>
        )}
        {currentTab === 1 && (
          <TabPanel value={currentTab} index={1}>
            {<WatcherTab />}
          </TabPanel>
        )}
        {currentTab === 2 && (
          <TabPanel value={currentTab} index={2}>
            {<DomainChangeTab />}
          </TabPanel>
        )}
        {currentTab === 3 && (
          <TabPanel value={currentTab} index={3}>
            {<FailedOrdersTab />}
          </TabPanel>
        )}
      </PageContainer>
    </>
  );
};

export default Operations;
