import { useCollection } from "../../firestore/hooks";
import LoadingWrapper from "../../components/LoadingWrapper";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as RouterLink } from "react-router-dom";
import TimeAgo from "react-timeago";
import { exsBasicInfo } from "../../utils/funcs";
import React from "react";
import { ExsBasicHook } from "@dash.bar/types";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";
import { Skeleton } from "@material-ui/lab";

const WebHooks = () => {
  const webhooks = useCollection<ExsBasicHook>(
    "webhook",
    undefined,
    ["created_at", "desc"],
    3
  );

  return (
    // <LoadingWrapper loading={webhooks === undefined}>
    <>
      {/* <Card>
      <CardHeader avatar={<FontAwesomeIcon icon={['fad', 'thunderstorm']}/>} title={"Last Exs Webhooks"}/>
      <CardContent> */}
      {webhooks === undefined ? (
        <>
          <Skeleton
            width={"100%"}
            height={90}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
          <Skeleton
            width={"100%"}
            height={90}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
          <Skeleton
            width={"100%"}
            height={90}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
        </>
      ) : (
        webhooks && (
          <List dense disablePadding>
            {Object.keys(webhooks).map((id) => (
              <ListItem
                key={`${webhooks[id].created_at}-${webhooks[id].id}`}
                disableGutters
              >
                <ListItemText
                  primary={
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Link
                          component={RouterLink}
                          to={`/extensionstore/webhook/${webhooks[id].id}`}
                        >
                          <strong>{webhooks[id].event_type}</strong>
                        </Link>
                      </Grid>
                      <Grid item>
                        <TimeAgo date={webhooks[id].created_at} />
                      </Grid>
                    </Grid>
                  }
                  secondary={<>{exsBasicInfo(webhooks[id])}</>}
                />
              </ListItem>
            ))}
          </List>
        )
      )}
      {/* </CardContent>
    </Card> */}
      {/* </LoadingWrapper> */}
    </>
  );
};

export default withRole(UserRole.Webhooks)(WebHooks);
