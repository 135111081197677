import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import getDashBar from "../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import { Button, Grid, TextField } from "@material-ui/core";
import DashbarCampaignView from "./campaign";

const DashbarCampaigns = () => {
  const [limit, setLimit] = useState(15);
  const [campaigns, setCampaigns] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();
  const [campaignsFiltered, setCampaignsFiltered] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();

  const [searchString, setSearchString] = useState<string | null>(null);

  useEffect(() => {
    getDashBar()
      .db.collection("campaigns")
      .limit(limit)
      .onSnapshot((snap) => {
        setCampaigns(snap.docs ?? []);
      });
  }, [setCampaigns, limit]);

  useEffect(() => {
    if (searchString && searchString !== null && campaigns) {
      const filtered = campaigns?.filter((campaign) => {
        if (
          campaign
            .data()
            .name.toLowerCase()
            .includes(searchString.toLowerCase())
        )
          return true;
        return false;
      });
      setCampaignsFiltered(filtered);
    } else if (campaigns) {
      setCampaignsFiltered(campaigns);
    }
  }, [searchString, campaigns]);

  return (
    <>
      <PageContainer title={"Campaigns"}>
        <>
          <div className="my-4">
            <TextField
              type={"text"}
              placeholder={"Suchen"}
              variant="outlined"
              onChange={(value) => {
                setSearchString(value.target.value);
              }}
            />
          </div>
          {campaignsFiltered && (
            <Grid container spacing={2}>
              {Object.entries(campaignsFiltered).map(([key, value]) => (
                <Grid item xs={12} key={key}>
                  <DashbarCampaignView
                    key={value.id}
                    id={value.id}
                    data={value.data()}
                  />
                </Grid>
              ))}
              <Grid
                container
                spacing={2}
                className="text-center my-3"
                justifyContent={"center"}
              >
                <Button
                  variant="contained"
                  onClick={() => setLimit((limit) => limit + 15)}
                >
                  more
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      </PageContainer>
    </>
  );
};

export default DashbarCampaigns;
