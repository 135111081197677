import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import Companies from "./companies";
import getDashBar from "../../firebase/dashbar";
import firebase from "firebase/compat/app";
// import Plans from "./plans";
// import Addons from "./addons";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";
import Users from "./users";
import CompanyDetails from "./companies/details";
import UserDetails from "./users/details";
import PageContainer from "../../components/PageContainer";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import Shops from "./shops";
import ShopDetails from "./shops/details";
// import NewsList from "./news";
import Dashboard from "./dashboard";
// import Extensions from "./extensions";
// import ExtensionForm from "./extensions/extensionForm";
// import ExtensionConfigForm from "./extensions/extensionConfig/form";
import Audits from "./audits";
// import Coupons from "./coupons";
// import CouponForm from "./coupons/couponForm";
import PopupForm from "./popups/popupForm";
import Popups from "./popups";
import DashbarCampaigns from "./campaigns";
import Reports from "./reports";
import ReportEdit from "./reports/reportEdit";
import Operations from "./operations";
import Coupons from "./couponsNew";
import CouponEditForm from "./couponsNew/CouponEditForm";
import CouponCreateForm from "./couponsNew/CouponCreateForm";

const DashbarModule = () => {
  const [user, setUser] = useState<firebase.User | undefined | null>();
  const [access, setAccess] = useState<boolean | undefined>();
  const { enqueueSnackbar } = useSnackbar();

  const [credentials, setCredentials] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user) {
      user
        .getIdTokenResult(true)
        .then((token) => setAccess(!!token.claims.admin))
        .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
    }
  }, [user, enqueueSnackbar]);

  useEffect(() => {
    getDashBar().auth.onAuthStateChanged((user) => setUser(user));
  }, []);

  const handleLogin = () => {
    getDashBar()
      .auth.signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((c) => setCredentials((prev) => ({ ...prev, password: "" })))
      .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
  };

  if (!user) {
    return (
      <>
        <PageContainer title="Login to dash.bar" loading={user === undefined}>
          <div className="flex justify-center content-around">
            <TextField
              name={"email"}
              placeholder={"E-Mail"}
              type={"email"}
              value={credentials.email}
              onChange={(e) =>
                setCredentials((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <TextField
              name={"password"}
              placeholder={"Password"}
              type={"password"}
              value={credentials.password}
              onChange={(e) =>
                setCredentials((prev) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
            />
          </div>
          <div className="my-2 text-center">
            <Button onClick={handleLogin} variant="contained" color="primary">
              login
            </Button>
          </div>
        </PageContainer>
      </>
    );
  }

  if (!access) {
    return (
      <>
        <PageContainer title={"Access denied!"} loading={access === undefined}>
          <Alert severity="error">
            This User is not allowed to administrate dash.bar!
          </Alert>
          <div className="my-2 text-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => getDashBar().auth.signOut()}
            >
              Logout
            </Button>
          </div>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Button
        className="float-right"
        variant="contained"
        color="primary"
        onClick={() => getDashBar().auth.signOut()}
      >
        Logout
      </Button>
      {/* <Outlet /> */}
      <Routes>
        <Route path={`companies/:cid/shops/:id`} element={<ShopDetails />} />
        <Route path={`companies/:id`} element={<CompanyDetails />} />
        <Route path={`companies`} element={<Companies />} />

        <Route path={`users/:id`} element={<UserDetails />} />
        <Route path={`users`} element={<Users />} />

        <Route path={`shops`} element={<Shops />} />

        {/* <Route path={`news`} element={<NewsList />} />
        <Route path={`extensions/new`} element={<ExtensionForm />} />
        <Route
          path={`extensions/extensionConfig/edit`}
          element={<ExtensionConfigForm />}
        />
        <Route path={`extensions/:id/edit`} element={<ExtensionForm />} />
        <Route path={`extensions`} element={<Extensions />} /> */}
        <Route path={`reports/reportConfig/edit`} element={<ReportEdit />} />
        <Route path={`reports`} element={<Reports />} />
        {/* <Route path={`coupons/new`} element={<CouponForm />} />
        <Route path={`coupons/:id`} element={<Coupons />} />
        <Route path={`coupons/:id/edit`} element={<CouponForm />} /> */}
        <Route path={`coupon`} element={<Coupons />} />
        <Route path={`coupon/:id`} element={<CouponEditForm />} />
        <Route path={`coupon/create`} element={<CouponCreateForm />} />
        <Route path={`popup/new`} element={<PopupForm />} />
        <Route path={`popup/:id/edit`} element={<PopupForm />} />
        <Route path={`popup`} element={<Popups />} />
        <Route path={`campaigns`} element={<DashbarCampaigns />} />

        {/* <Route path={`addons`} element={<Addons />} />
        <Route path={`plans`} element={<Plans />} /> */}
        <Route path={`audits`} element={<Audits />} />
        <Route path={`transactions`}>Transactions</Route>
        <Route path={`operations`} element={<Operations />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </>
  );
};

export default withRole(UserRole.Dashbar)(DashbarModule);
