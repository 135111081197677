import { Avatar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTimeago from "react-timeago";
import React from "react";
import { NotType } from "@dash.bar/types";
import { ICON_STYLE } from "../utils/constants";

type NotificationViewProps = {
  notification: NotType;
  onDelete: () => void;
};

const NotificationView = ({
  notification,
  onDelete,
}: NotificationViewProps) => {
  return (
    <div className="my-2 rounded shadow bg-gray-600 flex p-2 items-center">
      <Avatar className="my-2 mx-3" />
      <div className="flex-grow">
        <h5>{notification?.title}</h5>
        <h6>{notification?.subtitle}</h6>
        <p>{notification?.body}</p>

        <div className="text-sm text-right">
          {notification?.status?.length &&
          notification.status[0]?.status === "ok" ? (
            <FontAwesomeIcon
              color="green"
              icon={[ICON_STYLE, "check-circle"]}
            />
          ) : (
            <FontAwesomeIcon color="red" icon={[ICON_STYLE, "times-circle"]} />
          )}

          <code className="mx-3 rounded bg-gray-800 p-1">
            {notification?.data?.type}
          </code>
          {notification?.receivedAt && (
            <ReactTimeago date={notification?.receivedAt.toDate()} />
          )}
          <FontAwesomeIcon
            onClick={onDelete}
            icon={[ICON_STYLE, "trash"]}
            className="ml-1 cursor-pointer"
            fixedWidth
            color="red"
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationView;
