import React, { useEffect, useState } from "react";
import { DB } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { LicenceTable } from "./components/Table";

const PluginLicenses = () => {
  const [licenses, setLicenses] = useState<Array<Record<string, any>>>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (refresh === false) return;
    DB()
      .collectionGroup(`licenses`)
      .orderBy("createTime", "desc")
      .get()
      .then((docs) => {
        const licenseArray: Array<Record<string, any>> = [];
        docs.forEach((doc) => {
          licenseArray.push({ ...doc.data(), id: doc.id });
        });
        setLicenses(licenseArray);
      })
      .finally(() => {
        setRefresh(false);
      });
  }, [refresh]);

  // const activateLicense = async (customerid: string, licenseid: string) => {
  //   setUpdating(true);
  //   await DB()
  //     .collection(`customer/${customerid}/licenses`)
  //     .doc(licenseid)
  //     .set({ status: 1 }, { merge: true });
  //   setRefresh(true);
  //   setUpdating(false);
  // };
  // const disableLicense = async (customerid: string, licenseid: string) => {
  //   setUpdating(true);
  //   await DB()
  //     .collection(`customer/${customerid}/licenses`)
  //     .doc(licenseid)
  //     .set({ status: 2 }, { merge: true });
  //   setRefresh(true);
  //   setUpdating(false);
  // };

  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <LicenceTable licences={licenses} />
    </div>
  );
};
export default PluginLicenses;
