import React, { useEffect, useState } from "react";
import { IAuth } from "../../types/Auth";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import useAuth, { ADMIN_ROLE } from "../../hooks/useAuth";
import md5 from "md5";
import { auth as fbAuth, functions } from "../../firebase";
import { User, UserRole } from "../../firestore/models/User";
import { useFirestore } from "react-redux-firebase";
import { COMPONENTS, GREY } from "../../constants/colors";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 14, WEIGHT: "500" };

const UserViewNew = ({
  user,
  onChange,
  openAddRoleModal,
}: {
  user: IAuth;
  onChange?: Function;
  openAddRoleModal?: Function;
}) => {
  const auth = useAuth();
  const firestore = useFirestore();
  const [profile, setProfile] = useState<User | null>(null);

  useEffect(() => {
    if (!user.uid) return;
    firestore
      .collection(`user`)
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProfile({ ...doc.data(), id: doc.id } as User);
        } else {
          setProfile(null);
        }
      });
  }, [user.uid]);

  const update = (uid: string, data: Record<string, any>) => () => {
    functions()
      .httpsCallable("resource")({
        resource: "user",
        action: "update",
        data: {
          id: uid,
          resource: data,
        },
      })
      .then(onChange && onChange())
      .catch(console.error);
  };

  const removeRole = (user: IAuth, role: string) => {
    functions()
      .httpsCallable("resource")({
        resource: "user",
        action: "removeRoles",
        data: {
          uid: user.uid,
          roles: [role],
        },
      })
      .then((res) => {
        if (res.data && onChange) {
          onChange();
        }
      })
      .catch(console.error);
  };

  const impersonate = (user: IAuth) => () => {
    functions()
      .httpsCallable("resource")({
        resource: "user",
        action: "impersonate",
        data: user.uid,
      })
      .then((res) => {
        console.log(res);
        if (res.data) {
          fbAuth().signInWithCustomToken(res.data);
        }
      })
      .catch(console.error);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar
            src={`https://gravatar.com/avatar/${md5(user.email)}?s=480`}
          />
        }
        title={`${profile?.firstname} ${profile?.lastname}`}
        subheader={`${profile?.email}`}
        style={{
          backgroundColor: COMPONENTS.CARD.HEADER_BACKGROUND,
        }}
      />
      <CardContent
        style={{
          backgroundColor: COMPONENTS.CARD.CONTENT_BACKGROUND,
          alignSelf: "stretch",
          justifySelf: "stretch",
        }}
      >
        <Grid
          container
          spacing={0}
          alignItems="stretch"
          alignContent="stretch"
          style={{ display: "flex" }}
        >
          <Grid
            item
            xs={3}
            alignContent="center"
            alignItems="center"
            style={{ height: "100%", alignSelf: "stretch" }}
          >
            <Typography
              style={{
                color: "#EEE",
                fontSize: TITLE_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: TITLE_TEXT.WEIGHT,
              }}
            >
              {`E-Mail`}
            </Typography>
          </Grid>
          <Grid item xs={9} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: DEFAULT_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: DEFAULT_TEXT.WEIGHT,
              }}
            >
              {`${profile?.email}`}
            </Typography>
          </Grid>
          <Grid item xs={3} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: TITLE_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: TITLE_TEXT.WEIGHT,
              }}
            >
              {`PRN`}
            </Typography>
          </Grid>
          <Grid item xs={9} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: DEFAULT_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: DEFAULT_TEXT.WEIGHT,
              }}
            >
              {`${profile?.prn ?? `n/a`}`}
            </Typography>
          </Grid>
          <Grid item xs={3} alignContent="flex-start" alignItems="flex-start">
            <Typography
              style={{
                color: "#EEE",
                fontSize: TITLE_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: TITLE_TEXT.WEIGHT,
              }}
            >
              {`Rollen`}
            </Typography>
          </Grid>
          <Grid item xs={9} alignContent="flex-start" alignItems="flex-start">
            {user.customClaims &&
              Object.keys(user.customClaims).map((role) => (
                <Chip
                  className="m-1"
                  size="small"
                  onDelete={
                    !(auth.uid === user.uid && role === ADMIN_ROLE)
                      ? () => removeRole(user, role)
                      : undefined
                  }
                  label={role}
                  style={{
                    backgroundColor: GREY[800],
                  }}
                />
              ))}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color={"secondary"}
          onClick={update(user.uid, { disabled: !user.disabled })}
        >
          {user.disabled ? "enable" : "disable"}
        </Button>
        <Button
          size={"small"}
          color={"primary"}
          onClick={openAddRoleModal && openAddRoleModal(user)}
        >
          add role
        </Button>
        {profile && profile.roles && profile.roles.includes(UserRole.Admin) && (
          <Button size="small" color={"primary"} onClick={impersonate(user)}>
            impersonate
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default UserViewNew;
