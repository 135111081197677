import React from "react";
import { Grid } from "@material-ui/core";
import WebHooks from "./WebHooks";
import ShortURL from "./ShortURL";
import DashboardCard from "./DashboardCard";
import Sales from "./components/Sales";
import { UserRole } from "../../firestore/models";
import User from "./components/User";
import withoutRole from "../../hoc/withoutRole";

const DashboardModule = () => {
  return (
    // <Container maxWidth="" style={{ width: "100%" }}>
    <div style={{ width: "100%", padding: 20 }}>
      <Grid
        container
        spacing={2}
        style={{ flexGrow: 1, width: "100%" }}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        alignContent="stretch"
      >
        <Grid item xs={12} lg={4}>
          <DashboardCard title="User">
            <User />
          </DashboardCard>
        </Grid>

        <Grid item xs={12} lg={4}>
          <DashboardCard title="ShortURL">
            <ShortURL />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <DashboardCard title="Webhooks">
            <WebHooks />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DashboardCard
            size="larger"
            title="Sales"
            role={UserRole.Operational}
          >
            <Sales />
          </DashboardCard>
        </Grid>
      </Grid>
    </div>
    // </Container>
  );
};

export default withoutRole(UserRole.Developer)(DashboardModule);
