export const getIPAddress = () => new Promise<string>((resolve, reject) => {
    fetch('https://geolocation-db.com/json/')
        .then(res => res.json())
        .then(json => resolve(json.IPv4))
        .catch(() => resolve('n/a'));
})
const useIPAddress = (ip: string, setter: Function) => {
    console.log("RUN useIPAddress", ip, "-")
    if (ip === '') {
        console.log("RUNNING useIPAddress", ip)
        fetch('https://geolocation-db.com/json/')
            .then(res => res.json())
            .then(json => setter(json.IPv4));
    }
};

export default useIPAddress;