import { Typography } from "@material-ui/core";
import React from "react";
import useCollectionGroup from "../../../../../firestore/hooks/useCollectionGroup";
import PreviewBox from "./PreviewBox";

interface IOverviewProps {
  campaign: Record<string, any>;
}

const Preview = ({ campaign }: IOverviewProps) => {
  const booked = useCollectionGroup<Record<string, any>>("booking", [
    ["campaignId", "==", campaign.id],
  ]);
  return (
    <div className="w-full flex flex-col">
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
          marginBottom: 14,
          marginTop: 14,
        }}
      >
        Vorschau
      </Typography>
      <div className="flex flex-row flex-wrap justify-start content-center items-center mt-8">
        {Object.entries(booked || {}).map(([k, v]) => {
          return (
            <PreviewBox
              adspaceName={v.adspaceName}
              startDate={v.startDate.toDate()}
              endDate={v.endDate.toDate()}
              fileUrl={v.fileUrl}
              filePath={v.filePath}
              fileUrlSecond={v.fileUrlSecond}
              filePathSecond={v.filePathSecond}
              bookedAt={v.extBookedAt?.toDate() || null}
              reminderAt={v.reminderAt?.toDate() || null}
              bookedUrl={v.extBookedUrl}
              key={k}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Preview;
