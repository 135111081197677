import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DB, functions } from "../../../../../firebase";
import DashboardCard from "../../general/DashboardCard";
import { COMPONENTS } from "../../../../../constants/colors";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {
  CategoryScale,
  Chart,
  LineElement,
  LinearScale,
  PointElement,
} from "chart.js";
import { UserRole } from "../../../../../firestore/models";

const getDayLabel = (day: number) => {
  switch (day) {
    case 0:
      return `Mo`;
    case 1:
      return `Di`;
    case 2:
      return `Mi`;
    case 3:
      return `Do`;
    case 4:
      return `Fr`;
    case 5:
      return `Sa`;
    case 6:
    case -1:
      return `So`;
  }
};

const CheckoutTimelineV2 = ({ type = "prod" }: { type: "prod" | "test" }) => {
  Chart.register(CategoryScale, LinearScale, PointElement, LineElement);
  const [loading, setLoading] = useState<boolean>(true);

  const relevantCheckoutTypes: Array<string> =
    type === "prod" ? ["prod", "free"] : ["test"];

  const splitDate: Date = moment()
    .startOf("week")
    .set("hour", 1)
    .set("minute", 0)
    .add(1, "day")
    .toDate();

  const dateString = moment()
    .subtract(1, "week")
    .startOf("week")
    .set("hour", 1)
    .set("minute", 0)
    .add(1, "day")
    .toDate()
    .toISOString();
  const [checkouts, setCheckouts] = useState<
    Array<{ exsId: string; createdAt: Date; type: string }>
  >([]);
  const [hydratedCheckouts, setHydratedCheckouts] = useState<
    Array<{
      exsId: string;
      pluginName: string;
      pluginId: string;
      createdAt: Date;
      type: string;
    }>
  >([]);
  const [plugins, setPlugins] = useState<Array<Record<string, any>>>([]);

  useEffect(() => {
    const pluginArray: Array<Record<string, any>> = [];
    DB()
      .collection("plugin")
      .where("platform", "in", ["jtlshop5"])
      .limit(400)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          pluginArray.push(doc.data());
        });
        setPlugins(pluginArray);
      });
  }, []);

  useEffect(() => {
    const loadData = () => {
      DB()
        .collection("webhook")
        .where("event_type", "==", "exs.order.completed")
        .where("created_at", ">", dateString)
        .get()
        .then((docs) => {
          const checkoutArray: Array<{
            exsId: string;
            createdAt: Date;
            type: string;
          }> = [];
          docs.forEach((doc) => {
            const data = doc.data();
            if (data?.resource?.metas) {
              if (
                relevantCheckoutTypes.includes(
                  data?.resource?.metas?.license_type
                ) &&
                data?.resource?.metas?.order_type === "create"
              ) {
                checkoutArray.push({
                  exsId: data?.resource?.metas?.exs_id,
                  createdAt: moment(data?.created_at).toDate(),
                  type: data?.resource?.metas?.license_type,
                });
              }
            }
          });
          setCheckouts(checkoutArray);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    loadData();
    const interval = setInterval(loadData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const hydrateCheckouts = async () => {
    const hydratedCheckoutArray: Array<{
      exsId: string;
      pluginId: string;
      pluginName: string;
      createdAt: Date;
      type: string;
    }> = [];
    for (const checkout of checkouts) {
      const plugin = plugins.filter((p) => {
        return p.exs_id === checkout.exsId;
      })[0];
      if (plugin) {
        hydratedCheckoutArray.push({
          ...checkout,
          pluginId: plugin?.pluginId,
          pluginName: plugin?.shortName,
        });
      }
    }
    setHydratedCheckouts(hydratedCheckoutArray);
  };

  useEffect(() => {
    if (checkouts.length > 0 && plugins.length > 0) {
      hydrateCheckouts();
    }
  }, [checkouts, plugins]);

  const totalCheckoutsThisWeek = hydratedCheckouts.filter((v) => {
    return v.createdAt > splitDate;
  }).length;

  const totalCheckoutsLastsWeek = hydratedCheckouts.filter((v) => {
    return v.createdAt <= splitDate;
  }).length;

  const checkoutsThisWeek = hydratedCheckouts
    .filter((v) => {
      return v.createdAt > splitDate;
    })
    .map(
      (
        v
      ): {
        exsId: string;
        pluginName: string;
        pluginId: string;
        createdAt: Date;
        dateString: string;
        type: string;
      } => {
        return {
          ...v,
          dateString: getDayLabel(moment(v.createdAt).day() - 1) ?? "Mo",
        };
      }
    )
    .reduce<Record<string, number>>(
      (prev, curr) => {
        if (prev?.[curr.dateString]) {
          return { ...prev, [curr.dateString]: prev[curr.dateString] + 1 };
        } else {
          return { ...prev, [curr.dateString]: 1 };
        }
      },
      { Mo: 0, Di: 0, Mi: 0, Do: 0, Fr: 0, Sa: 0, So: 0 }
    );

  const checkoutsThisWeekByPlugin = hydratedCheckouts
    .filter((v) => {
      return v.createdAt > splitDate;
    })
    .map(
      (
        v
      ): {
        exsId: string;
        pluginName: string;
        pluginId: string;
        createdAt: Date;
        dateString: string;
        type: string;
      } => {
        return {
          ...v,
          dateString: getDayLabel(moment(v.createdAt).day() - 1) ?? "Mo",
        };
      }
    )
    .reduce<Record<string, number>>((prev, curr) => {
      if (prev?.[curr.pluginName]) {
        return { ...prev, [curr.pluginName]: prev[curr.pluginName] + 1 };
      } else {
        return { ...prev, [curr.pluginName]: 1 };
      }
    }, {});

  const dataByPluginThisWeekArray = Object.entries<number>(
    checkoutsThisWeekByPlugin
  )
    .sort((v1, v2) => {
      if (v1[1] > v2[1]) {
        return -1;
      } else if (v1[1] < v2[1]) {
        return 1;
      } else {
        return 0;
      }
    })
    .filter((v) => {
      return v[0] !== null && v[0] !== "null" && v[0] !== undefined;
    })
    .map((v) => {
      return v;
    });

  const dataThisWeekArray = Object.entries(checkoutsThisWeek).map((v) => {
    return v;
  });

  const checkoutsLastWeek = hydratedCheckouts
    .filter((v) => {
      return v.createdAt <= splitDate;
    })
    .map(
      (
        v
      ): {
        exsId: string;
        pluginName: string;
        pluginId: string;
        createdAt: Date;
        dateString: string;
        type: string;
      } => {
        // console.log(
        //   `${moment(v.createdAt).format("DD.MM.YYYY HH:mm:ss")} ${
        //     getDayLabel(moment(v.createdAt).day() - 1) ?? "Mo"
        //   } ${moment(v.createdAt).day()}`
        // );
        return {
          ...v,
          dateString: getDayLabel(moment(v.createdAt).day() - 1) ?? "Mo",
        };
      }
    )
    .reduce<Record<string, number>>(
      (prev, curr) => {
        if (prev?.[curr.dateString]) {
          return { ...prev, [curr.dateString]: prev[curr.dateString] + 1 };
        } else {
          return { ...prev, [curr.dateString]: 1 };
        }
      },
      { Mo: 0, Di: 0, Mi: 0, Do: 0, Fr: 0, Sa: 0, So: 0 }
    );

  const checkoutsLastWeekByPlugin = hydratedCheckouts
    .filter((v) => {
      return v.createdAt <= splitDate;
    })
    .map(
      (
        v
      ): {
        exsId: string;
        pluginName: string;
        pluginId: string;
        createdAt: Date;
        dateString: string;
        type: string;
      } => {
        return {
          ...v,
          dateString: getDayLabel(moment(v.createdAt).day() - 1) ?? "Mo",
        };
      }
    )
    .reduce<Record<string, number>>((prev, curr) => {
      if (prev?.[curr.pluginName]) {
        return { ...prev, [curr.pluginName]: prev[curr.pluginName] + 1 };
      } else {
        return { ...prev, [curr.pluginName]: 1 };
      }
    }, {});

  const dataByPluginLastWeekArray = Object.entries<number>(
    checkoutsLastWeekByPlugin
  )
    .sort((v1, v2) => {
      if (v1[1] > v2[1]) {
        return -1;
      } else if (v1[1] < v2[1]) {
        return 1;
      } else {
        return 0;
      }
    })
    .filter((v) => {
      return v[0] !== null && v[0] !== "null" && v[0] !== undefined;
    })
    .map((v) => {
      return v;
    });

  const dataLastWeekArray = Object.entries(checkoutsLastWeek).map((v) => {
    return v;
  });

  const data = {
    labels: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    datasets: [
      {
        label: "Letzte Woche",
        fill: true,
        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_1,
        borderColor: COMPONENTS.DASHBOARD.THEMES.COLOR_1,
        data: dataLastWeekArray,
      },
      {
        label: "Diese Woche",
        fill: true,
        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
        borderColor: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
        data: dataThisWeekArray,
      },
    ],
  };
  //   console.log(checkoutsThisWeek);
  //   console.log(checkoutsLastWeek);
  //   console.log(
  //     moment()
  //       .startOf("week")
  //       .set("hour", 1)
  //       .set("minute", 0)
  //       .add(1, "day")
  //       .format("DD.MM.YYYY HH:mm:ss")
  //   );

  return (
    <DashboardCard
      title="Checkouts"
      theme="plugin"
      loading={loading}
      headerChip={type === "prod" ? "Produktiv" : "Test"}
      role={UserRole.Operational}
    >
      {/* <p>{JSON.stringify(hydratedCheckouts)}</p> */}
      <Grid container spacing={4} alignItems={"center"}>
        <Grid item xs={6}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={6} alignItems={"center"} justifyContent="center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 20,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {totalCheckoutsThisWeek ?? 0}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} alignItems={"center"} justifyContent="center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 20,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {totalCheckoutsLastsWeek ?? 0}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 0,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`Diese Woche`}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 0,
                    }}
                  >
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`Letzte Woche`}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems={"flex-start"}>
                    <Grid
                      item
                      xs={12}
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <Typography
                        style={{
                          color: "#CCC",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          fontWeight: `500`,
                          marginBottom: 5,
                        }}
                      >
                        {`Diese Woche`}
                      </Typography>
                    </Grid>
                    {dataByPluginThisWeekArray
                      .filter((_v, index) => {
                        return index < 5;
                      })
                      .map((plugin) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={2}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#FFF",
                                  fontSize: 16,
                                  fontFamily: "Roboto",
                                  fontWeight: `500`,
                                }}
                              >
                                {`${plugin[1]}`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#FFF",
                                  fontSize: 14,
                                  fontFamily: "Roboto",
                                  fontWeight: `300`,
                                }}
                              >
                                {`${plugin[0]}`}
                              </Typography>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems={"flex-start"}>
                    <Grid
                      item
                      xs={12}
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <Typography
                        style={{
                          color: "#CCC",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          fontWeight: `500`,
                          marginBottom: 5,
                        }}
                      >
                        {`Letzte Woche`}
                      </Typography>
                    </Grid>
                    {dataByPluginLastWeekArray
                      .filter((_v, index) => {
                        return index < 5;
                      })
                      .map((plugin) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={2}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#BBB",
                                  fontSize: 16,
                                  fontFamily: "Roboto",
                                  fontWeight: `500`,
                                }}
                              >
                                {`${plugin[1]}`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  color: "#BBB",
                                  fontSize: 14,
                                  fontFamily: "Roboto",
                                  fontWeight: `300`,
                                }}
                              >
                                {`${plugin[0]}`}
                              </Typography>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={12}>
              <div style={{ maxHeight: 250 }}>
                <Line
                  data={data}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                        position: "top",
                      },
                      title: {
                        display: false,
                        text: "",
                      },
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={1}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        minWidth: 20,
                        minHeight: 20,
                        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `300`,
                      }}
                    >
                      {`Letzte Woche`}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        minWidth: 20,
                        minHeight: 20,
                        backgroundColor: COMPONENTS.DASHBOARD.THEMES.COLOR_2,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        color: "#BBB",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        fontWeight: `300`,
                      }}
                    >
                      {`Diese Woche`}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default CheckoutTimelineV2;
