import { Chip, Grid, LinearProgress, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import dashbar from "../../../../../firebase/dashbar";
import { linearProgressClasses } from "@mui/material";
import moment from "moment";
import { STATES } from "../../../../../constants/colors";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const TarifInfo = ({ company }: { company: Record<string, any> }) => {
  const [booking, setBooking] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    dashbar()
      .db.collection(`company/${company?.id}/booking`)
      .get()
      .then((docs) => {
        if (docs.size === 1) {
          setBooking({ ...docs.docs[0].data(), id: docs.docs[0].id });
        } else {
          setBooking(null);
        }
      })
      .catch(() => {
        setBooking(null);
      });
  }, [company?.id]);

  const percentageLeft = (): number => {
    if (booking === null) return 0;
    const totalDays = moment(booking?.endDate?.toDate()).diff(
      moment(booking?.startDate.toDate()),
      "day"
    );
    const daysUsed = moment().diff(moment(booking?.startDate?.toDate()), "day");
    return +((daysUsed / totalDays) * 100).toFixed(0);
  };
  const daysLeft = (): number => {
    if (booking === null) return 0;
    const days = moment(booking?.endDate?.toDate()).diff(moment(), "day");
    return +days.toFixed(0);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Aktueller Tarif`}
        </Typography>
      </Grid>
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {booking === null ? `free` : booking.bundle}
        </Typography>
      </Grid>
      {booking !== null && (
        <>
          <Grid item xs={3} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: TITLE_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: TITLE_TEXT.WEIGHT,
              }}
            >
              {`Zahlungsanbieter`}
            </Typography>
          </Grid>
          <Grid item xs={9} alignContent="center" alignItems="center">
            <Chip
              label={booking.purchaseType === `apple` ? `ApplePay` : `abocloud`}
              style={{
                marginTop: 5,
                marginBottom: 5,
              }}
              variant="outlined"
            />
          </Grid>
        </>
      )}
      <Grid item xs={3} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Tarifstatus`}
        </Typography>
      </Grid>
      {booking !== null && (
        <>
          <Grid item xs={9} alignContent="center" alignItems="center">
            <BorderLinearProgress
              active={booking !== null && booking?.status === 1}
              variant="determinate"
              value={percentageLeft()}
              style={{ marginTop: 5, marginBottom: 5 }}
            />
          </Grid>
          <Grid item xs={3} alignContent="center" alignItems="center" />
        </>
      )}
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {booking === null
            ? `Tarif läuft unbegrenzt`
            : booking.status === 2
            ? `${daysLeft()} Tage verbleibend`
            : `Wird in ${daysLeft()} Tagen verlängert`}
        </Typography>
      </Grid>
      <Grid item xs={3} alignContent="center" alignItems="center" />
      <Grid item xs={9} alignContent="center" alignItems="center">
        <Chip
          label={
            booking === null || booking.status === 1
              ? `aktiv`
              : booking.isTrial === true
              ? `Testphase`
              : `gekündigt`
          }
          style={{
            marginTop: 5,
            marginBottom: 5,
            backgroundColor:
              booking === null || booking.status === 1
                ? STATES.ACTIVE
                : STATES.PENDING,
          }}
        />
      </Grid>
      {booking !== null && (
        <>
          <Grid item xs={3} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: TITLE_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: TITLE_TEXT.WEIGHT,
              }}
            >
              {`Gebucht am`}
            </Typography>
          </Grid>
          <Grid item xs={9} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: DEFAULT_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: DEFAULT_TEXT.WEIGHT,
              }}
            >
              {moment(booking?.created?.toDate()).format("DD.MM.YYYY HH:mm")}
            </Typography>
          </Grid>
          <Grid item xs={3} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: TITLE_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: TITLE_TEXT.WEIGHT,
              }}
            >
              {`Gültig von`}
            </Typography>
          </Grid>
          <Grid item xs={9} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: DEFAULT_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: DEFAULT_TEXT.WEIGHT,
              }}
            >
              {moment(booking?.startDate?.toDate()).format("DD.MM.YYYY HH:mm")}
            </Typography>
          </Grid>
          <Grid item xs={3} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: TITLE_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: TITLE_TEXT.WEIGHT,
              }}
            >
              {`Gültig bis`}
            </Typography>
          </Grid>
          <Grid item xs={9} alignContent="center" alignItems="center">
            <Typography
              style={{
                color: "#EEE",
                fontSize: DEFAULT_TEXT.SIZE,
                fontFamily: "Roboto",
                fontWeight: DEFAULT_TEXT.WEIGHT,
              }}
            >
              {moment(booking?.endDate?.toDate()).format("DD.MM.YYYY HH:mm")}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default TarifInfo;

const BorderLinearProgress = styled(LinearProgress)(
  ({ active }: { active: boolean }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: `#222`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: active === true ? "#308fe8" : "#c92c2c",
    },
  })
);
