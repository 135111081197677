import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Row } from "./Row";

export const UserResultTabel = ({
  userResultData,
}: {
  userResultData: Array<Record<string, any>>;
}) => (
  <TableContainer
    component={Paper}
    style={{ backgroundColor: "#333", marginTop: 20 }}
  >
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`ID`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Vorname`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Nachname`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`E-Mail`}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userResultData.map((user, index) => (
          <Row key={`${index}_${user.email}`} row={user} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
