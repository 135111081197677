import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@material-ui/core/styles";
import { ICON_STYLE } from "../../utils/constants";

interface LoadingWrapperProps {
  label?: string;
  loading: boolean;
  error?: any;
  onCancel?: () => void;
  onRetry?: () => void;
  suspend?: boolean;
  fixed?: boolean;
  children?: React.ReactNode;
}

const LoadingWrapper = (props: LoadingWrapperProps) => {
  const theme = useTheme();
  return (
    <div className="z-0">
      {props.loading && props.error && (
        <div
          className={
            (props.fixed ? "fixed" : "absolute") +
            " inset-0 z-10 backdrop-filter backdrop-blur-sm"
          }
        >
          <div
            className="absolute text-center"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ margin: "5px" }}>
              <FontAwesomeIcon
                color={theme.palette.error.main}
                size={"3x"}
                icon={[ICON_STYLE, "times-hexagon"]}
              />
            </div>
            <div>{props.error.toString() ?? "Unexpected Error."}</div>
            {props.onRetry && (
              <Button onClick={props.onRetry} color={"primary"}>
                Retry
              </Button>
            )}
            {props.onCancel && (
              <Button onClick={props.onCancel} color={"secondary"}>
                Cancel
              </Button>
            )}
          </div>
        </div>
      )}
      {props.loading && !props.error && (
        <div
          className={
            (props.fixed ? "fixed" : "absolute") +
            " inset-0 z-10 backdrop-filter backdrop-blur-sm"
          }
        >
          <div
            className="absolute text-center"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
            <div>{props.label ?? "loading..."}</div>
          </div>
        </div>
      )}
      {props.loading && props.suspend ? null : props.children}
    </div>
  );
};

export default LoadingWrapper;
