import {faGitlab} from "@fortawesome/free-brands-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const GitlabIssues: IconProp = {
  icon: [
    16,
    16,
    [],
    faGitlab.icon[3],
    "M1 3a2 2 0 0 1 2-2h6a2 2 0 0 1 1.956 1.58l3.039 1.755a2 2 0 0 1 .732 2.732c-1.281 2.219-3.727 6.448-3.882 6.72C10.457 14.469 9.813 15 9 15H3a2 2 0 0 1-2-2V3zm2 0h6v10H3V3zm8 6.522l1.995-3.455L11 4.915v4.607z"
  ],
  iconName: 'democrat',
  prefix: 'fab'
} as IconProp

export default GitlabIssues
