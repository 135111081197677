import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ICON_STYLE } from "../../../../../../../utils/constants";
import { ERROR } from "../../../../../../../constants/colors";

export const Row = (props: { row: Record<string, any> }) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const hasError = () =>
    row.results.some((e: Record<string, any>) => !e.success);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <FontAwesomeIcon icon={[ICON_STYLE, "arrow-up"]} />
            ) : (
              <FontAwesomeIcon icon={[ICON_STYLE, "arrow-down"]} />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            style={{
              color: hasError() ? ERROR.DARK : "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "700",
            }}
          >
            {row.id}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row?.firstname ?? `n/a`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row.lastname ?? `n/a`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row.email ?? `n/a`}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#222" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginTop: 7, marginBottom: 7 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Prüfung`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Status`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Metadaten`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.results
                    .sort(
                      (
                        res1: Record<string, any>,
                        res2: Record<string, any>
                      ) => {
                        if (res1.res_index > res2.res_index) {
                          return 1;
                        } else if (res1.res_index < res2.res_index) {
                          return -1;
                        } else {
                          return 0;
                        }
                      }
                    )
                    .map((result: Record<string, any>) => (
                      <TableRow key={result.message}>
                        <TableCell component="th" scope="row">
                          <Typography
                            style={{
                              color: "#EEE",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {result.res_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              color:
                                result.success === false ? ERROR.DARK : "#EEE",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {result.success === true
                              ? `Erfolgreich`
                              : `Fehlerhaft`}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            style={{
                              color: "#EEE",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {`${result.meta ?? `-`}`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
