import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Row } from "./Row";

export const LicenceTable = ({
  licences,
}: {
  licences: Array<Record<string, any>>;
}) => (
  <TableContainer
    component={Paper}
    style={{ backgroundColor: "#333", marginTop: 20 }}
  >
    <Table size="small" aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Plugin ID`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Extension ID`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Gültigkeit`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Lizenzschlüssel`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Gültig ab`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Gültig bis`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Angefragt am`}
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {licences.map((licence, index) => (
          <Row key={`${index}_${licence?.apikey}`} row={licence} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
