import {
  Button,
  Chip,
  Grid,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { ERROR } from "../../../../../../constants/colors";
import styled from "@emotion/styled";
import { tooltipClasses } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../../utils/constants";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const ShopCard = ({
  shop,
  config,
}: {
  shop: Record<string, any>;
  config: Record<string, any> | null;
}) => {
  const currentPluginVersion = config?.current_plugin_version?.trim() ?? null;
  const oldVersionUsed =
    config !== null &&
    config?.current_plugin_version.trim() !==
      shop?.platform?.pluginVersion.trim();

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={shop?.icon}
            style={{
              width: 50,
              height: 50,
              borderRadius: 100,
              backgroundColor: "#FFF",
              marginBottom: 10,
            }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: TITLE_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
              marginBottom: 5,
            }}
          >
            {shop?.id}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Shopsystem`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <Chip
          label={shop?.platform?.name ?? `n/a`}
          style={{
            marginTop: 5,
            marginBottom: 5,
          }}
          variant="default"
        />
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Hinzugefügt am`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {moment(shop?.addedAt?.toDate()).format("DD.MM.YYYY HH:mm")}
        </Typography>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Plugin-Version`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              color: "#EEE",
              fontSize: DEFAULT_TEXT.SIZE,
              fontFamily: "Roboto",
              fontWeight: DEFAULT_TEXT.WEIGHT,
            }}
          >
            {shop?.platform?.pluginVersion ?? `n/a`}
          </Typography>
          {oldVersionUsed === true && (
            <CustomWidthTooltip
              title={`Die Plugin-Version ist veraltet. Bestimmte Features sind möglicherweiße nicht verfügbar. Aktuelle Version: ${
                currentPluginVersion ?? `n/a`
              }`}
            >
              <div style={{ marginLeft: 7, width: 30 }}>
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "info-circle"]}
                  color={ERROR.LIGHT}
                />
              </div>
            </CustomWidthTooltip>
          )}
        </div>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`Shop-Version`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {shop?.platform?.version ?? `n/a`}
        </Typography>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`PHP-Version`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {shop?.platform?.phpVersion ?? `n/a`}
        </Typography>
      </Grid>
      <Grid item xs={4} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: TITLE_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: TITLE_TEXT.WEIGHT,
          }}
        >
          {`MySQL-Version`}
        </Typography>
      </Grid>
      <Grid item xs={8} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {shop?.platform?.mysqlVersion ?? `n/a`}
        </Typography>
      </Grid>
      {/* <Grid
        item
        xs={2}
        alignContent="center"
        alignItems="center"
        style={{ paddingTop: 10 }}
      >
        <Button variant="outlined">{`Prüfen`}</Button>
      </Grid> */}
    </Grid>
  );
};

export default ShopCard;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});
