import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "../../../../components/PageContainer";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import dashbar from "../../../../firebase/dashbar";
import getDashBar from "../../../../firebase/dashbar";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { auth } from "firebase-admin";
import { User } from "@dash.bar/types";
import TabPanel, { a11yProps } from "../../../../components/TabPanel";
import TabInfo from "./tabs/tabInfo";
import TabSessions from "./tabs/tabSessions";
import TabNotifications from "./tabs/tabNotifications";

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [userDoc, setUser] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const [companyDoc, setCompany] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const [sessionDocs, setSessions] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();
  const { enqueueSnackbar } = useSnackbar();
  const [authUser, setAuthUser] = useState<auth.UserRecord | undefined>();

  const [notifications, setNotifications] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();
  const [tab, setTab] = useState(0);

  const loadAuthUser = useCallback(() => {
    if (id) {
      dashbar()
        .functions.httpsCallable("auth-user-get")(id)
        .then((res) => {
          setAuthUser(res.data);
        })
        .catch((error) => {
          enqueueSnackbar(`Error: ${error}`, { variant: "error" });
        });
    }
  }, [id, setAuthUser, enqueueSnackbar]);

  const makeSuperAdmin = useCallback(
    (admin: boolean) => () => {
      if (!id || !window.confirm("Really make admin?")) return;

      setAuthUser(undefined);
      getDashBar()
        .functions.httpsCallable("auth-user-admin")({ uid: id, admin: admin })
        .then(() => {
          enqueueSnackbar("Successfully updated!", { variant: "success" });
          loadAuthUser();
        })
        .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
    },
    [id, loadAuthUser, enqueueSnackbar]
  );

  const syncUser = useCallback(() => {
    setAuthUser(undefined);
    getDashBar()
      .functions.httpsCallable("auth-user-sync")(id)
      .then(() => {
        enqueueSnackbar("Successfully synced!", { variant: "success" });
        loadAuthUser();
      })
      .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
  }, [loadAuthUser, id, enqueueSnackbar]);

  const handleDelete = useCallback(() => {
    if (authUser?.uid) {
      if (!window.confirm("Really delete User, cannot be undone?")) return;

      dashbar()
        .functions.httpsCallable("calls-user-deleteUser")({
          userId: authUser.uid,
        })
        .then(() =>
          enqueueSnackbar(`Successfully deleted User '${authUser.uid}'`, {
            variant: "success",
          })
        )
        .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
    }
  }, [authUser, enqueueSnackbar]);

  const makeOwner = useCallback(() => {
    if (!userDoc || !companyDoc || !window.confirm("Really make new owner?"))
      return;

    dashbar()
      .functions.httpsCallable("calls-company-setOwner")({
        userId: userDoc?.id,
        companyId: companyDoc?.id,
      })
      .then(() =>
        enqueueSnackbar("Successfully saved!", { variant: "success" })
      )
      .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
  }, [userDoc, companyDoc, enqueueSnackbar]);

  const makeAdmin = useCallback(
    (admin: boolean) => () => {
      if (
        !userDoc ||
        !companyDoc ||
        !window.confirm("Really make change admin status?")
      )
        return;

      dashbar()
        .functions.httpsCallable("calls-company-setAdmin")({
          userId: userDoc?.id,
          companyId: companyDoc?.id,
          admin: admin,
        })
        .then(() =>
          enqueueSnackbar("Successfully saved!", { variant: "success" })
        )
        .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
    },
    [userDoc, companyDoc, enqueueSnackbar]
  );

  const handleDisable = useCallback(
    (disable: boolean) => () => {
      if (!authUser || !window.confirm(`Really disable user '${authUser.uid}'`))
        return;

      dashbar()
        .functions.httpsCallable("calls-user-disable")({
          userId: authUser.uid,
          disable: disable,
        })
        .then(
          () =>
            enqueueSnackbar(
              `Successfully ${disable ? "disabled" : "enabled"}!`,
              { variant: "success" }
            ) && loadAuthUser()
        )
        .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
    },
    [authUser, loadAuthUser, enqueueSnackbar]
  );

  useEffect(() => loadAuthUser(), [loadAuthUser]);
  useEffect(
    () => dashbar().db.collection("user").doc(id).onSnapshot(setUser),
    [id, setUser]
  );
  useEffect(
    () =>
      dashbar()
        .db.collection("company")
        .doc(userDoc?.data()?.company ?? "-")
        .onSnapshot(setCompany),
    [userDoc, setCompany]
  );
  useEffect(
    () =>
      dashbar().db.collection(`user/${id}/sessions`).onSnapshot(setSessions),
    [id, setSessions]
  );
  useEffect(
    () =>
      dashbar()
        .db.collection(`/user/${id}/notification`)
        .limit(15)
        .orderBy("receivedAt", "desc")
        .onSnapshot(setNotifications),
    [id, setNotifications]
  );

  if (userDoc && !userDoc.exists) {
    return <Navigate to={"/dashbar/users"} replace />;
  }

  const user = userDoc?.data() as User;

  return (
    <PageContainer
      title={`User ${userDoc && userDoc.id}`}
      onClose={() => navigate("/dashbar/users")}
      loading={!userDoc && !authUser}
    >
      <AppBar title="Shop" position="static">
        <Tabs
          value={tab}
          onChange={(_, val) => setTab(val)}
          aria-label="shps tabs example"
        >
          <Tab label="Info" {...a11yProps(0)} />
          <Tab label="Sessions" {...a11yProps(1)} />
          <Tab label="Notifications" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      {tab === 0 && (
        <TabPanel value={tab} index={0}>
          {user && authUser && (
            <TabInfo
              userDoc={userDoc}
              id={`${id}`}
              authUser={authUser}
              handleDisable={handleDisable}
              handleDelete={handleDelete}
              makeAdmin={makeAdmin}
              syncUser={syncUser}
              makeSuperAdmin={makeSuperAdmin}
              companyDoc={companyDoc}
              makeOwner={makeOwner}
            />
          )}
        </TabPanel>
      )}
      {tab === 1 && (
        <TabPanel value={tab} index={1}>
          <TabSessions sessionDocs={sessionDocs} />
        </TabPanel>
      )}
      {tab === 2 && (
        <TabPanel value={tab} index={2}>
          <TabNotifications notifications={notifications} />
        </TabPanel>
      )}
    </PageContainer>
  );
};

export default UserDetails;
