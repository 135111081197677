import { Typography } from "@material-ui/core";
import React from "react";
import { useCollection } from "../../../../../../firestore/hooks";
import AdspaceRow from "../AdspaceRow";
import CampaignRow from "../CampaignRow";

const Adspaces = () => {
  const activeCampaigns = useCollection<Record<string, any>>(
    "adspace",
    undefined,
    undefined,
    40
  );

  const filteresAdspaces = Object.entries(activeCampaigns || {})
    .filter(([k, v]) => {
      return true;
    })
    .map(([k, v]) => {
      return v;
    });

  if (filteresAdspaces.length <= 0) return null;

  return (
    <div className="w-full lg:w-1/2 flex flex-col mt-16">
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
        }}
      >
        Werbeplätze
      </Typography>
      {filteresAdspaces.map((adspace) => {
        return (
          <AdspaceRow
            key={adspace.id}
            id={adspace.id}
            name={adspace.name}
            value={adspace.value}
          />
        );
      })}
    </div>
  );
};

export default Adspaces;
