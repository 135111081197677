import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import dashbar from "../../../../../firebase/dashbar";
import ShopCard from "./ShopCard";
import { GREY } from "../../../../../constants/colors";
import { useNavigate } from "react-router-dom";

const ShopInfo = ({ company }: { company: Record<string, any> }) => {
  const navigate = useNavigate();
  const [shops, setShops] = useState<Array<Record<string, any>> | null>(null);
  const [config, setConfig] = useState<Record<string, any> | null>(null);
  useEffect(() => {
    dashbar()
      .db.collection(`config`)
      .doc(`general`)
      .get()
      .then((res) => setConfig({ ...res.data() }));
  }, []);

  useEffect(() => {
    dashbar()
      .db.collection(`company/${company?.id}/shop`)
      .get()
      .then((docs) => {
        if (docs.size > 0) {
          const shopArray: Array<Record<string, any>> = [];
          docs.forEach((doc) => {
            shopArray.push({ ...doc.data(), id: doc.id });
          });
          setShops(shopArray);
        } else {
          setShops(null);
        }
      })
      .catch(() => {
        setShops(null);
      });
  }, [company?.id]);

  return (
    <Grid container spacing={2}>
      {shops !== null &&
        shops.map((shop) => {
          return (
            <Grid item xs={4} alignContent="stretch" alignItems="stretch">
              <Paper
                onClick={() =>
                  navigate(
                    `/dashbar/companies/${company?.id}/shops/${shop?.id}`
                  )
                }
                elevation={4}
                style={{
                  padding: 20,
                  borderRadius: 15,
                  backgroundColor: GREY[900],
                  cursor: "pointer",
                }}
              >
                <ShopCard shop={shop} config={config} />
              </Paper>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ShopInfo;
