import React from "react";
import {ExsOrderCompleted} from "@dash.bar/types";
import {Box, Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {formatCurrency, webhookStatusLabel} from "../../../../utils/funcs";
import RawJSON from "./RawJSON";
import Retry from "../Retry";

const OrderCompleted = ({webhook}: { webhook: ExsOrderCompleted }) => {

    return <Grid container spacing={3}>

        {webhook.status_intern === "error" && webhook.error_intern ?
            <Grid item xs={12}>
                <Alert severity={"error"}>Error: {webhook.error_intern}</Alert>
            </Grid> : null}

        <Grid item xs={12} md={6}>
            <h3>Details</h3>

            <ul>
                <li><b>Interner Status:</b></li>

                <li><b>OrderNumber:</b> {webhook.resource.order_number}</li>
                <li>
                    <b>AmountTotal:</b> {formatCurrency(parseFloat(webhook.resource.amount_total), webhook.resource.currency_code)}
                </li>
                <li><b>Total:</b> {formatCurrency(parseFloat(webhook.resource.total), webhook.resource.currency_code)}
                </li>
                <li>
                    <b>TaxTotal:</b> {formatCurrency(parseFloat(webhook.resource.tax_total), webhook.resource.currency_code)}
                </li>
                <li><b>Status:</b> {webhook.resource.status}</li>
                <li><b>Webhook-Test:</b> {webhook.test ? 'Yes' : 'No'}</li>
            </ul>
        </Grid>

        <Grid item xs={12} md={6}>

            <Box>
                <h3>Status</h3>
                <div>
                    {webhookStatusLabel(webhook.status_intern as string)}
                    <div style={{display: "inline", margin: "0 10px"}}>
                        {['open', 'error'].includes(webhook.status_intern as string) ?
                            <Retry id={webhook.id}/> : null}
                    </div>
                </div>


            </Box>

            <h3>Items</h3>
            <ul>
                {webhook.resource.items.map((item, i) => <li key={i}>
                    {item.quantity}x <abbr title={item.sku}>{item.name}</abbr> - <abbr
                    title={`Tax: ${formatCurrency(parseFloat(item.tax), item.currency_code)}`}>
                    {formatCurrency(parseFloat(item.amount), item.currency_code)}</abbr>
                </li>)}
            </ul>
        </Grid>

        <Grid item xs={12} md={6}>
            <h3>Buyer</h3>
            <ul>
                <li><b>Company:</b> {webhook.resource.buyer.company}</li>
                <li><b>VAT ID:</b> {webhook.resource.buyer.vatin}</li>
                <li><b>E-Mail:</b> {webhook.resource.buyer.email}</li>
                <li><b>Name:</b> {webhook.resource.buyer.surname} {webhook.resource.buyer.given_name}</li>
                <li><b>Address:</b>
                    {webhook.resource.buyer.address_line_1}
                    {webhook.resource.buyer.address_line_2 ? <><br/>{webhook.resource.buyer.address_line_2}</> : null}
                    <br/>{webhook.resource.buyer.postal_code} {webhook.resource.buyer.admin_area_1}
                    {webhook.resource.buyer.admin_area_2 ? <><br/>{webhook.resource.buyer.admin_area_2}</> : null}
                    <br/>{webhook.resource.buyer.country_code}
                </li>
            </ul>
        </Grid>

        <Grid item xs={12} md={6}>
            <h3>Meta</h3>
            <ul>
                <li><b>ExsID:</b> {webhook.resource.metas.exs_id}</li>
                <li><b>LicenseKey:</b> {webhook.resource.metas.license_key}</li>
                {webhook.resource.metas.sevdeskPartID ?
                    <li><b>PartID:</b> {webhook.resource.metas.sevdeskPartID}</li> : null}
                {webhook.resource.metas.sevdeskContactID ?
                    <li><b>ContactID:</b> {webhook.resource.metas.sevdeskContactID}</li> : null}
                {webhook.resource.metas.sevdeskNextInvoiceID ?
                    <li><b>NextInvoiceID:</b> {webhook.resource.metas.sevdeskNextInvoiceID}</li> : null}
                {webhook.resource.metas.sevdeskCountryID ?
                    <li><b>CountryID:</b> {webhook.resource.metas.sevdeskCountryID}</li> : null}
                {webhook.resource.metas.sevdeskTaxType ?
                    <li><b>TaxType:</b> {webhook.resource.metas.sevdeskTaxType}</li> : null}
            </ul>
        </Grid>
        <Grid item xs={12}>
            <RawJSON object={webhook}/>
        </Grid>
    </Grid>;
}

export default OrderCompleted;