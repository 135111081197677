import {Company} from "@dash.bar/types"
import React, {Fragment} from "react";
import AddonKey from "./AddonKey";

type FeatureConfigurationProps = {
  company?: Company
}

const FeatureConfiguration = ({company}: FeatureConfigurationProps) => {
  return <dl className={'horizontal'}>
    {company && Object.keys(company.authorizations).map(key => <Fragment key={key}>
      <dt><AddonKey addOnKey={key}/></dt>
      <dd className='text-center'>{JSON.stringify(company.authorizations[key])}</dd>
    </Fragment>)}
  </dl>
}

export default FeatureConfiguration