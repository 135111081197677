import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { DB } from "../../../../../firebase";
import { getCountryNameFromId } from "../../../../abocloud/helper/sevdesk";
import EditModal from "./EditModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../utils/constants";

const HEADER_TEXT_SIZE = 18;
const DEFAULT_TEXT_SIZE = 16;
const ICON_TEXT_SIZE = 18;

const OverviewTab = () => {
  const { customerid } = useParams<{ customerid: string }>();
  const [customer, setCustomer] = useState<Record<string, any> | null>(null);

  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    if (!customerid || customerid === null) return;
    DB()
      .collection(`customer`)
      .doc(customerid)
      .get()
      .then((customer) => {
        if (customer.exists) {
          setCustomer({ ...customer.data(), id: customer.id });
        }
      });
  }, [customerid, edit]);

  if (customer === null) {
    return <p>Kundenübersicht</p>;
  }

  return (
    <>
      <EditModal cid={customer.id} open={edit} setOpen={setEdit} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#eee",
            borderRadius: 10,
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignContent: "start",
              alignItems: "start",
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                style={{
                  color: "#111",
                  fontSize: 28,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {customer?.organisation ?? `Unbekannter Firmenname`}
              </Typography>
              <Typography
                style={{
                  color: "#111",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {customer?.sevdesk_id
                  ? `KNr: ${customer?.sevdesk_id}`
                  : `Unbekannte Kundenummer`}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div onClick={() => setEdit(true)}>
                <FontAwesomeIcon
                  size="2x"
                  color="#000"
                  icon={[ICON_STYLE, "pen-to-square"]}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "start",
              alignItems: "start",
            }}
          >
            <div style={{ width: "50%" }}>
              <div
                style={{
                  backgroundColor: "#111",
                  padding: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  marginTop: 4,
                  marginBottom: 10,
                  width: "80%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#EEE",
                    fontSize: HEADER_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`Adresse`}
                </Typography>
              </div>
              <Grid container spacing={0} style={{ width: "80%" }}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {customer.organisation ?? `n/a`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {`${customer?.address?.street ?? `n/a`} ${
                      customer?.address?.number ?? ``
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {`${customer?.address?.zip ?? `n/a`} ${
                      customer?.address?.city ?? ``
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {getCountryNameFromId(customer?.address?.country ?? 1)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {`UstID: ${customer?.vat ?? `n/a`}`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  backgroundColor: "#111",
                  padding: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  marginTop: 4,
                  marginBottom: 10,
                  width: "80%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#EEE",
                    fontSize: HEADER_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`Ansprechpartner`}
                </Typography>
              </div>
              <Grid container spacing={0} style={{ width: "80%" }}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {customer?.organisation ?? `n/a`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {`${customer?.firstname ?? `n/a`} ${
                      customer?.lastname ?? ``
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {`${customer?.email ?? ``}`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewTab;
