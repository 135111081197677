import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Button, TextField } from "@material-ui/core";
import clsx from "clsx";
import { COMPONENTS } from "../../constants/colors";

export type TableProps = PropsWithChildren<{
  header: Record<string, TableHeaderT>;
  className?: string | undefined;
  onMore?: () => void;
  onSearch?: (key: string, value: string) => void;
  striped?: boolean;
}>;

export type TableHeaderT = {
  onSort?: () => void;
  searchable?: boolean;
  sort?: "asc" | "desc";
  content: ReactNode;
};

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  (
    { children, header, className, onMore, onSearch, striped }: TableProps,
    ref
  ) => {
    const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | undefined>();
    const [search, setSearch] = useState<Record<string, string>>({
      key: "",
      value: "",
    });

    const handleSearch =
      (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (timeoutID) {
          clearTimeout(timeoutID);
        }
        setSearch({ key: e.target.value ? key : "", value: e.target.value });
      };

    useEffect(() => {
      if (onSearch) {
        setTimeoutID(setTimeout(() => onSearch(search.key, search.value), 750));
      }
    }, [search, onSearch, setTimeoutID]);

    const classes = [className ?? "table-auto w-full"];
    if (striped) {
      classes.push("table-striped");
    }

    return (
      <table className={clsx(classes)} ref={ref}>
        <thead>
          <tr>
            {Object.keys(header).map((key) => (
              <th key={`head_${key}`}>{header[key].content}</th>
            ))}
          </tr>
          <tr>
            {Object.keys(header).map((key) =>
              header[key].searchable ? (
                <td key={`search_${key}`}>
                  <TextField
                    name={key}
                    disabled={search.key !== key && search.key !== ""}
                    placeholder="search..."
                    type="search"
                    fullWidth
                    onChange={handleSearch(key)}
                  />
                </td>
              ) : (
                <td key={`search_${key}`} />
              )
            )}
          </tr>
        </thead>
        <tbody style={{ backgroundColor: COMPONENTS.TABLE.ROW_EVEN }}>
          {children}
        </tbody>
        <tfoot>
          {onMore && (
            <tr>
              <td colSpan={Object.keys(header).length} className="text-center">
                <Button className="m-1" variant="contained" onClick={onMore}>
                  more
                </Button>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    );
  }
);

export default Table;
