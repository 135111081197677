import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import dashbar from "../../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import { Company } from "@dash.bar/types";
import PageContainer from "../../../../components/PageContainer";
import { useSnackbar } from "notistack";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import TabPanel, { a11yProps } from "../../../../components/TabPanel";
import TabInfo from "./tabs/tabInfo";
import TabNotifications from "./tabs/tabNotifications";
import TabBookings from "./tabs/tabBookings";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState(0);
  const [doc, setCompany] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const [users, setUsers] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();
  const [shops, setShops] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();

  const [notifications, setNotifications] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();

  const [bookings, setBookings] =
    useState<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >();

  useEffect(
    () => dashbar().db.collection("company").doc(id).onSnapshot(setCompany),
    [id, setCompany]
  );
  useEffect(
    () => dashbar().db.collection(`company/${id}/shop`).onSnapshot(setShops),
    [id, setShops]
  );

  useEffect(
    () =>
      dashbar()
        .db.collection(`company/${id}/notification`)
        .onSnapshot(setNotifications),
    [id, setNotifications]
  );

  useEffect(
    () =>
      dashbar()
        .db.collection(`company/${id}/booking`)
        // .where("endDate", ">", firebase.firestore.Timestamp.fromDate(maxEndDate))
        .orderBy("endDate", "asc")
        .orderBy("startDate", "asc")
        .onSnapshot(setBookings),
    [id, setBookings]
  );
  useEffect(
    () =>
      dashbar()
        .db.collection("user")
        .where("company", "==", id)
        .onSnapshot(setUsers),
    [id, setUsers]
  );

  const { enqueueSnackbar } = useSnackbar();

  const makeAdmin = useCallback(
    (id: string, admin: boolean) => () => {
      if (
        !doc ||
        !window.confirm(admin ? "Really make admin?" : "Really remove admin?")
      )
        return;

      dashbar()
        .functions.httpsCallable("calls-company-setAdmin")({
          userId: id,
          companyId: doc?.id,
          admin: admin,
        })
        .then(() =>
          enqueueSnackbar("Successfully saved!", { variant: "success" })
        )
        .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
    },
    [doc, enqueueSnackbar]
  );

  const makeOwner = useCallback(
    (id: string) => () => {
      if (!doc || !window.confirm("Really make new owner?")) return;

      dashbar()
        .functions.httpsCallable("calls-company-setOwner")({
          userId: id,
          companyId: doc?.id,
        })
        .then(() =>
          enqueueSnackbar("Successfully saved!", { variant: "success" })
        )
        .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
    },
    [doc, enqueueSnackbar]
  );

  const handleDelete = useCallback(() => {
    if (
      !doc ||
      !window.confirm(`Really delete Company? All users are set free.`)
    )
      return;

    dashbar()
      .functions.httpsCallable("calls-company-deleteCompany")({
        companyId: doc.id,
      })
      .then(() =>
        enqueueSnackbar(`Successfully deleted company '${doc.id}'.`, {
          variant: "success",
        })
      )
      .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
  }, [doc, enqueueSnackbar]);

  if (doc && !doc.exists) {
    return <Navigate to="/dashbar/companies" replace />;
  }

  const company = doc?.data() as Company;

  return (
    <PageContainer
      loading={!company}
      title={`${company?.name}`}
      // onClose={() => navigate("/dashbar/companies")}
      // onDelete={handleDelete}
    >
      <AppBar title="Company" position="static">
        <Tabs
          value={tab}
          onChange={(_, val) => setTab(val)}
          aria-label="company tabs example"
        >
          <Tab label="Info" {...a11yProps(0)} />
          {/* <Tab label="Users" {...a11yProps(1)} /> */}
          {/* <Tab label="Shops" {...a11yProps(2)} /> */}
          <Tab label="Notifications" {...a11yProps(1)} />
          <Tab label="Bookings" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      {tab === 0 && (
        <TabPanel value={tab} index={0}>
          {company && <TabInfo company={company} id={`${id}`} />}
        </TabPanel>
      )}
      {/* {tab === 1 && (
        <TabPanel value={tab} index={1}>
          {company && users && (
            <TabUsers
              company={company}
              users={users}
              makeAdmin={makeAdmin}
              makeOwner={makeOwner}
            />
          )}
        </TabPanel>
      )}
      {tab === 2 && (
        <TabPanel value={tab} index={2}>
          {shops && <TabShops shops={shops} id={`${id}`} />}
        </TabPanel>
      )} */}
      {tab === 1 && (
        <TabPanel value={tab} index={1}>
          {notifications && <TabNotifications notifications={notifications} />}
        </TabPanel>
      )}
      {tab === 2 && (
        <TabPanel value={tab} index={2}>
          {bookings && <TabBookings bookings={bookings} companyId={`${id}`} />}
        </TabPanel>
      )}
    </PageContainer>
  );
};

export default CompanyDetails;
