import { Chip, Grid, LinearProgress, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import dashbar from "../../../../../firebase/dashbar";
import { linearProgressClasses } from "@mui/material";
import moment from "moment";
import { ERROR, STATES } from "../../../../../constants/colors";

const DEFAULT_TEXT = { SIZE: 14, WEIGHT: "300" };
const TITLE_TEXT = { SIZE: 16, WEIGHT: "500" };

const DeletionInfo = ({ company }: { company: Record<string, any> }) => {
  const percentageLeft = (): number => {
    const totalDays = moment(company.noShopSince.toDate())
      .add(30, "day")
      .diff(moment(company.noShopSince.toDate()), "day");
    const daysUsed = moment().diff(moment(company.noShopSince.toDate()), "day");
    return +((daysUsed / totalDays) * 100).toFixed(0);
  };
  const daysLeft = (): number => {
    const days = moment(company.noShopSince.toDate())
      .add(30, "day")
      .diff(moment(), "day");
    return +days.toFixed(0);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {`Für diesen Account konnte kein Shop gefunden werden. Der Account wurde deshalb als inaktiv markiert.`}
        </Typography>
      </Grid>
      <Grid item xs={12} alignContent="center" alignItems="center">
        <BorderLinearProgress
          variant="determinate"
          value={percentageLeft()}
          style={{ marginTop: 5, marginBottom: 5 }}
        />
      </Grid>
      <Grid item xs={12} alignContent="center" alignItems="center">
        <Typography
          style={{
            color: "#EEE",
            fontSize: DEFAULT_TEXT.SIZE,
            fontFamily: "Roboto",
            fontWeight: DEFAULT_TEXT.WEIGHT,
          }}
        >
          {`Der Account wird in ${daysLeft()} Tagen gelöscht.`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeletionInfo;

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `#222`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: ERROR.DARK,
  },
}));
