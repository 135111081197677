import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import Label from "../../components/Label";
import Text from "../../components/Text";
import { Hosting } from "../types/Hosting";

const HostingCard = ({ hosting }: { hosting?: Hosting }) => {
  const navigate = useNavigate();
  return (
    <Card
      style={{
        borderTopLeftRadius: 10,
        borderTopRightRadius: 25,
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 10,
        overflow: "hidden",
      }}
    >
      <CardActionArea
        className="relative"
        onClick={() => navigate(`${hosting?.id}`)}
      >
        <CardMedia
          className="relative p-5"
          style={{
            backgroundColor: "#eee",
          }}
        >
          <img
            title={`${hosting?.name} öffnen`}
            className="bg-contain h-72 mx-auto"
            src={`https://ws-cdn.de/hostings/icons/${hosting?.id}.svg`}
          />
          <div
            className="absolute top-0 right-0 p-4 bg-black overflow-hidden"
            style={{ borderBottomLeftRadius: 25 }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "lighter",
                color: "white",
                textAlign: "center",
              }}
            >
              {`Shop 5`}
            </Typography>
          </div>
        </CardMedia>
      </CardActionArea>
      <CardContent
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            marginBottom: 10,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {hosting?.name}
        </Typography>
        <Grid
          container
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
          spacing={1}
          className="w-full"
        >
          <Grid item xs={3}>
            <Label text="Name:" />
          </Grid>
          <Grid item xs={9}>
            <Text text={`${hosting?.name}`} />
          </Grid>
          <Grid item xs={3}>
            <Label text="Preis:" />
          </Grid>
          <Grid item xs={9}>
            <Text
              text={`${hosting?.price?.value || "0.00"} ${
                hosting?.price?.currency || "EUR"
              }`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default HostingCard;
