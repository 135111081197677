import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ExsBasicHook, ExsOrderCompleted } from "@dash.bar/types";
import OrderCompleted from "./details/OrderCompleted";
import Licence from "./details/License";
import { ExsLicense } from "@dash.bar/types";
import RawJSON from "./details/RawJSON";
import { useDocument } from "../../../firestore/hooks";
import PageContainer from "../../../components/PageContainer";

const WebhookDetails = () => {
  const { id } = useParams();
  const webhook = useDocument<ExsBasicHook>("webhook", id);
  const navigate = useNavigate();
  if (!id) {
    return <Navigate to={"/extensionstore/webhook"} replace />;
  }

  let detailComponent = null;

  if (webhook) {
    switch (webhook.event_type) {
      case "exs.order.completed":
        detailComponent = (
          <OrderCompleted webhook={webhook as ExsOrderCompleted} />
        );
        break;
      case "exs.license.bound":
        detailComponent = <Licence webhook={webhook as ExsLicense} />;
        break;
      default:
        detailComponent = <RawJSON object={webhook} />;
    }
  }

  return !webhook ? (
    <>no data</>
  ) : (
    <PageContainer
      title={`WebHook: ${id}`}
      onClose={() => navigate("/extensionstore/webhook")}
    >
      {detailComponent}
    </PageContainer>
  );
};

export default WebhookDetails;
