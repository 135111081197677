import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const BundleConfigEdit = ({
  title,
  allow,
  force,
  setAllowed,
  setForced,
}: {
  title: string;
  allow: Array<string>;
  force: Array<string>;
  setAllowed: React.Dispatch<React.SetStateAction<string[]>>;
  setForced: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [freeAllowed, setFreeAllowed] = useState<boolean>(false);
  const [businessAllowed, setBusinessAllowed] = useState<boolean>(false);
  const [proAllowed, setProAllowed] = useState<boolean>(false);

  const [freeForced, setFreeForced] = useState<boolean>(false);
  const [businessForced, setBusinessForced] = useState<boolean>(false);
  const [proForced, setProForced] = useState<boolean>(false);

  useEffect(() => {
    const tempArr: Array<string> = [];
    if (freeAllowed) {
      tempArr.push("free");
    }
    if (businessAllowed) {
      tempArr.push("business");
    }
    if (proAllowed) {
      tempArr.push("pro");
    }
    setAllowed(tempArr);
  }, [freeAllowed, businessAllowed, proAllowed]);

  useEffect(() => {
    const tempArr: Array<string> = [];
    if (freeForced) {
      tempArr.push("free");
    }
    if (businessForced) {
      tempArr.push("business");
    }
    if (proForced) {
      tempArr.push("pro");
    }
    setForced(tempArr);
  }, [freeForced, businessForced, proForced]);

  useEffect(() => {
    if (allow) {
      if (allow.includes("free")) {
        setFreeAllowed(true);
      }
      if (allow.includes("business")) {
        setBusinessAllowed(true);
      }
      if (allow.includes("pro")) {
        setProAllowed(true);
      }
    }
  }, [allow]);

  useEffect(() => {
    if (force) {
      if (force.includes("free")) {
        setFreeForced(true);
      }
      if (force.includes("business")) {
        setBusinessForced(true);
      }
      if (force.includes("pro")) {
        setProForced(true);
      }
    }
  }, [force]);

  return (
    <Card
      style={{
        width: "90%",
        borderRadius: 15,
        backgroundColor: `#323232`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "lighter", marginBottom: 10 }}
        >
          {title}
        </Typography>
        <Grid
          container
          style={{ alignContent: "center", alignItems: "center" }}
        >
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              Allow
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", marginBottom: 10 }}
            >
              Force
            </Typography>
          </Grid>
          <Grid item xs={6}>
            free
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={freeAllowed}
              onChange={(e) => {
                setFreeAllowed(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={freeForced}
              onChange={(e) => {
                setFreeForced(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            business
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={businessAllowed}
              onChange={(e) => {
                setBusinessAllowed(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={businessForced}
              onChange={(e) => {
                setBusinessForced(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            pro
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={proAllowed}
              onChange={(e) => {
                setProAllowed(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={proForced}
              onChange={(e) => {
                setProForced(e.target.checked);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BundleConfigEdit;
