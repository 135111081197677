import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ICON_STYLE } from "../../../../utils/constants";
import { ERROR, GREY, SUCCESS } from "../../../../constants/colors";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const Row = (props: { row: Record<string, any> }) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Tooltip title={"Comming soon!"}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <FontAwesomeIcon icon={[ICON_STYLE, "eye-slash"]} />
              ) : (
                <FontAwesomeIcon icon={[ICON_STYLE, "eye"]} />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="left">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row?.plugin_id ?? `n/a`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row?.exid ?? `n/a`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          {row.status === 0 ? (
            <Tooltip title={"Eine Lizenz wurde angefragt"}>
              <FontAwesomeIcon
                size="1x"
                color={GREY[700]}
                icon={[ICON_STYLE, "circle-question"]}
              />
            </Tooltip>
          ) : row.status === 1 ? (
            <Tooltip title={"Die Lizenz ist gültig"}>
              <FontAwesomeIcon
                size="1x"
                color={SUCCESS.LIGHT}
                icon={[ICON_STYLE, "circle-check"]}
              />
            </Tooltip>
          ) : (
            <Tooltip title={"Die Lizenz ist ungültig"}>
              <FontAwesomeIcon
                size="1x"
                color={ERROR.LIGHT}
                icon={[ICON_STYLE, "circle-xmark"]}
              />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row?.apikey ?? `n/a`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row?.license?.startDate
              ? moment(row?.license?.startDate.toDate()).format(
                  "DD.MM.YYYY HH:mm"
                ) ?? `n/a`
              : `n/a`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row?.license?.endDate
              ? moment(row?.license?.endDate.toDate()).format(
                  "DD.MM.YYYY HH:mm"
                ) ?? `n/a`
              : `n/a`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            style={{
              color: "#EEE",
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {row?.createTime
              ? moment(row?.createTime?.toDate()).format("DD.MM.YYYY HH:mm") ??
                `n/a`
              : `n/a`}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              navigate(
                `/licenses/plugin/customer/${row?.customer_id ?? ""}?t=1`
              )
            }
          >
            <FontAwesomeIcon
              icon={[ICON_STYLE, "arrow-up-right-from-square"]}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#222" }}
          colSpan={6}
        >
          <Collapse in={false} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginTop: 7, marginBottom: 7 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Prüfung`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Status`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                      >
                        {`Metadaten`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                  {row.results
                    .sort(
                      (
                        res1: Record<string, any>,
                        res2: Record<string, any>
                      ) => {
                        if (res1.res_index > res2.res_index) {
                          return 1;
                        } else if (res1.res_index < res2.res_index) {
                          return -1;
                        } else {
                          return 0;
                        }
                      }
                    )
                    .map((result: Record<string, any>) => (
                      <TableRow key={result.message}>
                        <TableCell component="th" scope="row">
                          <Typography
                            style={{
                              color: "#EEE",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {result.res_name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            style={{
                              color: "#EEE",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {`${result.meta ?? `-`}`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody> */}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
