import React, {useCallback, useState} from "react";
import PageContainer from "../../../components/PageContainer";
import {SaleDataType, saleElements, saleSchema, SignUpDataType, signupElements, signupSchema} from "./constants";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import TabPanel, {a11yProps} from "../../../components/TabPanel";
import {TrackingForm} from "./trackingForm";
import {functions} from "../../../firebase";
import {useSnackbar} from "notistack";

const Firstpromoter = () => {

  const {enqueueSnackbar} = useSnackbar();
  const [tabsValue, setTabsValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback((event: 'signup' | 'sale') => async (data: Record<string, any>) => {
    try {
      setLoading(true);
      const result = await functions().httpsCallable('call-firstpromoter-track')({event: event, data})
      console.log(event, data, result)
      enqueueSnackbar(`Event getrackt!`, {variant: 'success'})
    } catch (e) {
      enqueueSnackbar(`ERROR: ${e}`, {variant: 'error'})
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading]);


  return <PageContainer title='Firstpromoter' loading={loading}>
    <AppBar position='static'>
      <Tabs value={tabsValue} onChange={(_, v) => setTabsValue(v)}>
        <Tab label="SignUp / Lead" {...a11yProps(0)}/>
        <Tab label="Sale" {...a11yProps(1)}/>
      </Tabs>
    </AppBar>
    <TabPanel value={tabsValue} index={0}>
      <TrackingForm<SignUpDataType> validator={signupSchema} elements={signupElements}
                                    onSubmit={handleSubmit('signup')}/>
    </TabPanel>
    <TabPanel value={tabsValue} index={1}>
      <TrackingForm<SaleDataType> validator={saleSchema} elements={saleElements} onSubmit={handleSubmit('sale')}/>
    </TabPanel>


  </PageContainer>
}

export default Firstpromoter;
