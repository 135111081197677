import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const NotificationCard = ({
  notifications,
  notificationsTotal,
}: {
  notifications: number;
  notificationsTotal: number;
}) => {
  return (
    <Card
      style={{
        width: 400,
        borderRadius: 15,
        background: `linear-gradient(to right bottom, #242526, #444445)`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "lighter", marginBottom: 0 }}
        >
          {`Push-Benachrichtigungen`}
        </Typography>
        <Grid container spacing={0} alignItems={"center"} style={{marginTop: 10}}>
          <Grid item xs={7}>
            <Typography style={{ fontSize: 14, fontWeight: "lighter" }}>
              {`Reports (letzte Woche)`}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
              {notifications}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography style={{ fontSize: 14, fontWeight: "lighter" }}>
              {`Reports (insgesamt)`}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
              {notificationsTotal}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
