import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            flex: {
                display: 'flex',
                '& > *': {
                    margin: theme.spacing(1),
                },
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center"
            },
            formControl: {
                margin: theme.spacing(1),
                minWidth: 120,
            },
        }
    )
);
export default useStyles;