import moment from "moment";

export type UserResultType = {
  res_index: number;
  res_name: string;
  success: boolean;
  status?: number;
  message?: string;
  meta?: string;
};

export const checkDeviceVersion = (
  user: Record<string, any>,
  config: Record<string, any> | null
): UserResultType => {
  if (config === null) {
    return {
      res_index: 0,
      res_name: `App Version`,
      success: false,
      meta: `Prüfung auf App-Version nicht möglich!`,
    };
  }
  let oldVersionsUsed: Array<string> | null = null;
  const currentVersion: string | null = config?.current_version ?? null;

  //Find old versions
  if (currentVersion === null) oldVersionsUsed = null;
  const oldVersions: Array<string> = (user?.sessions ?? [])
    .map((session: Record<string, any>) => {
      return session?.appVersion ?? null;
    })
    .filter((version: string) => {
      if (currentVersion === null || version === null) return false;
      return currentVersion?.trim() !== version?.trim();
    });
  if (oldVersions.length > 0) {
    oldVersionsUsed = [...new Set(oldVersions)].sort();
  } else {
    oldVersionsUsed = null;
  }
  if (oldVersionsUsed !== null && oldVersionsUsed.length > 0) {
    return {
      res_index: 0,
      res_name: `App Version`,
      success: false,
      meta: `Der Nutzer verwendet noch alte Versionen von dash.bar [${oldVersionsUsed.join(
        ", "
      )}]`,
    };
  } else {
    return {
      res_index: 0,
      res_name: `App Version`,
      success: true,
    };
  }
};

export const listPlatforms = (user: Record<string, any>): UserResultType => {
  let platformsUsed: Array<string> | null = null;
  //Get platforms used by user
  const platforms: Array<string> = (user?.sessions ?? [])
    .map((session: Record<string, any>) => {
      return session?.platform ?? null;
    })
    .filter((platform: string) => {
      return (
        platform !== null &&
        platform.trim() !== "" &&
        platform !== undefined &&
        platform !== "undefined"
      );
    });
  platformsUsed = [...new Set(platforms)].sort();
  return {
    res_index: 1,
    res_name: `Genutzte Platformen`,
    success: platformsUsed.length > 0,
    meta:
      platformsUsed.length > 0
        ? `Der Nutzer verwendet folgende Platformen: ${platformsUsed.join(
            ", "
          )}`
        : `Der Nutzer hat derzeit keine aktiven Geräte`,
  };
};

export const checkLastActive = (user: Record<string, any>): UserResultType => {
  let lastActive: Date | null = null;

  //Find last activity of user
  const lastActiveArray: Array<Date> = (user?.sessions ?? [])
    .map((session: Record<string, any>) => {
      return session?.created?.toDate() ?? null;
    })
    .filter((active: Date) => {
      return active !== null;
    })
    .sort((d1: Date, d2: Date) => {
      if (moment(d1).isBefore(moment(d2))) {
        return 1;
      } else if (moment(d2).isBefore(moment(d1))) {
        return -1;
      } else {
        return 0;
      }
    });
  lastActive = lastActiveArray.length > 0 ? lastActiveArray[0] : null;
  return {
    res_index: 2,
    res_name: `Zuletzt aktiv`,
    success: lastActive !== null,
    meta:
      lastActive !== null
        ? `Der Nutzer war zuletzt am ${moment(lastActive).format(
            "DD.MM.YYYY"
          )} um ${moment(lastActive).format("HH:mm")} aktiv`
        : `Der Nutzer ist derzeit auf keinem Gerät aktiv`,
  };
};
