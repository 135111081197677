import React from "react";
import DashboardCard from "./components/DashboardCard";
import MarketingBanner from "./components/MarketingBanner";
import DashbarUser from "./components/DashbarUser";
import DashbarPopup from "./components/DashbarPopup";
import DashbarCoupon from "./components/DashbarCoupon";
import MarketingTMBanner from "./components/MarketingTMBanner";
import AbscentEmployees from "./components/AbscentEmployees";
import PluginSales from "./components/PluginSales";
import { UserRole } from "../../firestore/models";
import PluginCheckoutNew from "./components/PluginCheckoutNew";
import OldDashboard from "./components/OldDashboard";
import FailedInvoices from "./components/FailedInvoices";
import moment from "moment";
import DashboadProvider from "./components/DashboardContext";
import MarketingShortUrls from "./components/MarketingShortUrls";
const background = require("./assets/background.jpg");

const DashboardNew = () => {
  // const [loading_Card1, setLoading_Card1] = useState<boolean>(true);
  // const [loading_Card2, setLoading_Card2] = useState<boolean>(false);
  // const [loading_Card3, setLoading_Card3] = useState<boolean>(false);
  // const [loading_Card4, setLoading_Card4] = useState<boolean>(false);
  // const [loading_Card5, setLoading_Card5] = useState<boolean>(false);
  // const [loading_Card6, setLoading_Card6] = useState<boolean>(false);
  // const [loading_Card7, setLoading_Card7] = useState<boolean>(false);

  // console.log(loading_Card1);
  // console.log(loading_Card2);
  // console.log(loading_Card3);
  // console.log(loading_Card4);
  // console.log(loading_Card5);
  // console.log(loading_Card6);
  // console.log(loading_Card7);

  return (
    <div style={{ width: "100%", overflow: "hidden", maxWidth: "100%" }}>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -10,
          minHeight: "100%",
          overflow: "hidden",
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "stretch",
          alignContent: "stretch",
          flexWrap: "wrap",
          gap: 20,
          padding: 40,
        }}
      >
        <DashboadProvider>
          <DashboardCard
            // loading={loading_Card1}
            title="WebStollen"
            subtitle="Fehlgeschlagene Rechnungen"
            role={UserRole.Operational}
            id="failed_invoices"
            content={<FailedInvoices id={`failed_invoices`} />}
          />
          <DashboardCard
            // loading={loading_Card1}
            title="WebStollen"
            subtitle="Heute nicht im Büro"
            id="abscent_employees"
            content={
              <AbscentEmployees
                id="abscent_employees" /*setLoading={setLoading_Card1}*/
              />
            }
          />
          {/* <DashboardCard
        // loading={loading_Card3}
        title="Plugin"
        subtitle="Verlängerte Lizenzen (heute)"
        content={<PluginCheckoutExtend />}
      /> */}
          <DashboardCard
            // loading={loading_Card2}
            title="Marketing"
            subtitle="TM-Banner"
            img={{
              url: `https://ws-cdn.de/marketing/aktionsbanner/tm-image`,
              alt: "Marketing Banner",
            }}
            id="marketing_banner"
            content={<MarketingBanner id="marketing_banner" />}
          />
          <DashboardCard
            // loading={loading_Card3}
            title="Marketing"
            subtitle="TM-Banner"
            id="tm_banner"
            content={<MarketingTMBanner id="tm_banner" />}
          />
          <DashboardCard
            // loading={loading_Card3}
            title="Marketing"
            subtitle="Beliebte URLs (15 Tage)"
            id="short_urls"
            content={<MarketingShortUrls id="short_urls" />}
          />
          <DashboardCard
            // loading={loading_Card4}
            title="Plugin"
            subtitle="Umsatz & Verkäufe (30 Tage)"
            id="plugin_sales"
            content={<PluginSales id="plugin_sales" />}
            role={UserRole.Operational}
          />
          <DashboardCard
            // loading={loading_Card5}
            title="dash.bar"
            subtitle="Nutzer"
            id="dashbar_user"
            content={<DashbarUser id="dashbar_user" />}
          />
          <DashboardCard
            // loading={loading_Card6}
            title="dash.bar"
            subtitle="Popups"
            id="dashbar_popup"
            content={<DashbarPopup id="dashbar_popup" />}
          />
          <DashboardCard
            // loading={loading_Card3}
            title="Plugin"
            subtitle="Neue Checkouts (heute)"
            content={<PluginCheckoutNew type="day" />}
          />
          <DashboardCard
            // loading={loading_Card7}
            title="dash.bar"
            subtitle="Coupons"
            id="dashbar_coupon"
            content={<DashbarCoupon id="dashbar_coupon" />}
          />
          <DashboardCard
            // loading={loading_Card3}
            title="Plugin"
            subtitle={`Neue Checkouts (${moment().format("MMMM")})`}
            content={<PluginCheckoutNew type="month" />}
          />
          <DashboardCard
            title="WebStollen"
            subtitle="Altes Dashboard"
            content={<OldDashboard />}
          />
        </DashboadProvider>
      </div>
    </div>
  );
};

export default DashboardNew;
