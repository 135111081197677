import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {deepOrange, green} from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            green: {
                color: theme.palette.getContrastText(green[500]),
                backgroundColor: green[500],
            },
            red: {
                color: theme.palette.getContrastText(deepOrange[500]),
                backgroundColor: deepOrange[500],
            },
            heading: {
                fontSize: theme.typography.pxToRem(15),
                //fontWeight: theme.typography.fontWeight,
            },
            mdEditor: {
                width: "100%",
                //minHeight: "200px"
            },
            dt: {
                textAlign: "center"
            }
        }
    )
);
export default useStyles;
