import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Grid, IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useCollectionGroup from "../../../../../firestore/hooks/useCollectionGroup";
import {
  calculateBudget,
  calculateClicks,
  deleteCampaign,
} from "../../../utils";
import { ICON_STYLE } from "../../../../../utils/constants";
import { useNavigate } from "react-router-dom";

interface IOverviewProps {
  campaign: Record<string, any>;
}

const Overview = ({ campaign }: IOverviewProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clicks, setClicks] = useState<number | null>(null);
  const [calculatedBudget, setCalculatedBudget] = useState<string>("0.00");
  const [calculating, setCalculating] = useState<boolean>(true);
  const navigate = useNavigate();
  const deleteCurrent = async () => {
    if (!window.confirm("Soll diese Kampagne wirklich gelöscht werden?"))
      return;
    setLoading(true);
    await deleteCampaign(campaign.id);
    navigate(`/marketing`);
    setLoading(false);
  };

  const bookings = useCollectionGroup("booking", [
    ["campaignId", "==", campaign.id],
  ]);

  useEffect(() => {
    setCalculating(true);
    calculateBudget(campaign.id)
      .then((result) => {
        setCalculatedBudget(result);
      })
      .finally(() => setCalculating(false));
  }, [bookings]);

  useEffect(() => {
    calculateClicks(campaign.id).then((clickResult) => {
      setClicks(clickResult);
    });
  }, [bookings]);

  const editCampaign = () => {
    navigate(`/marketing/edit/campaign/${campaign.id}`);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-row justify-end items-center content-center">
        {clicks !== null && (
          <Typography
            style={{
              fontSize: 16,
              fontWeight: "lighter",
              marginRight: 20,
            }}
          >
            {`${clicks} Klicks`}
          </Typography>
        )}
        <IconButton
          size={"small"}
          title="In Bitrix öffnen"
          style={{ margin: "0 5px" }}
        >
          <a target="_blank" href={campaign.bitrixRef}>
            <FontAwesomeIcon icon={[ICON_STYLE, "link"]} size={"sm"} />
          </a>
        </IconButton>
        <IconButton
          size={"small"}
          title="Bearbeiten"
          style={{ margin: "0 5px" }}
          onClick={editCampaign}
        >
          <FontAwesomeIcon icon={[ICON_STYLE, "edit"]} size={"sm"} />
        </IconButton>
        <IconButton
          size={"small"}
          disabled={loading}
          title="Löschen"
          style={{ margin: "0 5px" }}
          onClick={deleteCurrent}
        >
          <FontAwesomeIcon icon={[ICON_STYLE, "trash"]} size={"sm"} />
        </IconButton>
      </div>
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
          marginBottom: 14,
        }}
      >
        Übersicht
      </Typography>
      <Grid container spacing={1} className="w-full" style={{ marginLeft: 10 }}>
        <Grid item xs={2}>
          <TableTitle title="ID" />
        </Grid>
        <Grid item xs={4}>
          <TableText text={campaign.id} />
        </Grid>
        <Grid item xs={2}>
          <TableTitle title="Name" />
        </Grid>
        <Grid item xs={4}>
          <TableText text={campaign.name} />
        </Grid>
        <Grid item xs={2}>
          <TableTitle title="Startdatum" />
        </Grid>
        <Grid item xs={4}>
          <TableText
            text={moment(campaign.startDate.toDate()).format("DD.MM.YYYY")}
          />
        </Grid>
        <Grid item xs={2}>
          <TableTitle title="Enddatum" />
        </Grid>
        <Grid item xs={4}>
          <TableText
            text={moment(campaign.endDate.toDate()).format("DD.MM.YYYY")}
          />
        </Grid>
        <Grid item xs={2}>
          <TableTitle title="Budget" />
        </Grid>
        <Grid item xs={4}>
          <TableText text={`${campaign.budget}€`} />
        </Grid>
        <Grid item xs={2}>
          <TableTitle title="errechnetes Budget" />
        </Grid>
        <Grid item xs={4}>
          <TableText
            text={calculating ? `Berechne...` : `${calculatedBudget}€`}
          />
        </Grid>
        {campaign?.color && (
          <>
            <Grid item xs={2}>
              <TableTitle title="Farbe" />
            </Grid>
            <Grid item xs={4}>
              <Chip
                style={{ backgroundColor: campaign?.color }}
                label={`${campaign?.color}`}
              />
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <TableTitle title="Kommentar" />
        </Grid>
        <Grid item xs={12}>
          <TableText text={campaign.comment} />
        </Grid>
      </Grid>
    </div>
  );
};

const TableTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      style={{
        fontSize: 16,
        fontWeight: "bold",
      }}
    >
      {title}
    </Typography>
  );
};

const TableText = ({ text }: { text: string }) => {
  return (
    <Typography
      style={{
        fontSize: 14,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default Overview;
