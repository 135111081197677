import React, { useEffect, useState } from "react";
import { Button, Fade, Typography } from "@material-ui/core";
import { OLD_Plugin as Plugin } from "@dash.bar/types";
import NewPlugin from "./new";
import { useNavigate } from "react-router-dom";
import { WhereOptions } from "react-redux-firebase";
import { useCollection } from "../../../firestore/hooks";
import { UserRole } from "../../../firestore/models/User";
import withRole from "../../../hoc/withRole";
import PageContainer from "../../../components/PageContainer";
import CustInput from "../../../components/Input";
import { IDProp } from "../../../firestore/types";
import PluginCard from "./components/PluginCard";
import useAuth from "../../../hooks/useAuth";
import useProfile from "../../../hooks/firestore/useProfile";

// TODO: Disable Access Filter

const PluginsView = () => {
  const navigate = useNavigate();
  const [searchedPluginsS, setSearchedPluginsS] = useState<
    Array<
      Plugin & {
        price: { value: string; currency: string };
        links: { dokuUrl: string; exStore: string };
      } & IDProp
    >
  >([]);

  const [newPlugin, setNewPlugin] = useState(false);
  const auth = useAuth();
  const profile = useProfile();
  //const cls = useStyles();
  const [searchString, setSearchString] = useState<string>("");
  const onSearchStringChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchString(event.currentTarget.value);
  };

  const [searchOptions, setSearchOptions] = useState<{
    query: string;
    filter: string;
    limit: number;
    where?: WhereOptions | WhereOptions[];
  }>({
    query: "",
    filter: "name",
    limit: 250,
    where: ["platform", "in", ["jtlshop5"]],
  });

  const plugins = useCollection<
    Plugin & {
      price: { value: string; currency: string };
      links: { dokuUrl: string; exStore: string };
    }
  >(
    "plugin",
    searchOptions?.where,
    [searchOptions.filter, "asc"],
    searchOptions.limit
  );

  useEffect(() => {
    if (searchString.length > 1) {
      const getData = setTimeout(() => {
        const searchedPlugins = Object.entries(plugins || {})
          .filter(([k, v]) => {
            return (
              v.id.toLowerCase().includes(searchString.toLowerCase()) ||
              v.name.toLowerCase().includes(searchString.toLowerCase()) ||
              (v.exs_sku &&
                v.exs_sku.toLowerCase().includes(searchString.toLowerCase())) ||
              (v.description &&
                v.description
                  .toLowerCase()
                  .includes(searchString.toLowerCase()))
            );
          })
          .map(([k, v]) => {
            let score = 0.1;
            const sea = searchString.toLowerCase();
            if (v.exs_sku && v.exs_sku.toLowerCase().includes(sea)) {
              score = score + 100;
              if (v.exs_sku.indexOf(sea) === 0) {
                score = score * 2;
              }
            }
            if (v.id.toLowerCase().includes(sea)) {
              score = score + 100;
              if (v.id.indexOf(sea) === 0) {
                score = score * 2;
              }
            }
            if (v.name && v.name.toLowerCase().includes(sea)) {
              score = score + 50;
              if (v.name.indexOf(sea) === 0) {
                score = score * 1.5;
              }
            }
            if (v.description && v.description.toLowerCase().includes(sea)) {
              score = score + 20;
            }
            return { ...v, searchScore: score };
          })
          .sort((a, b) => {
            if (a.searchScore > b.searchScore) {
              return -1;
            } else if (a.searchScore < b.searchScore) {
              return 1;
            } else {
              return 0;
            }
          });
        if (searchedPlugins.length === 1) {
          navigate(`${searchedPlugins[0].pluginId}`);
        }
        setSearchedPluginsS(searchedPlugins);
      }, 200);
      return () => clearTimeout(getData);
    } else {
      setSearchedPluginsS(Object.values(plugins || {}));
    }
  }, [searchString, plugins]);

  const SearchBar = () => {
    return (
      <CustInput
        key={`SearchBar`}
        type="text"
        autoFocus
        placeholder="Plugin suchen"
        value={searchString}
        onChange={onSearchStringChanged}
        style={{ padding: 10, fontSize: 28, outline: "none" }}
      />
    );
  };

  return (
    <PageContainer title="Plugins" onAdd={() => setNewPlugin(true)}>
      <SearchBar />
      <p>{}</p>

      {searchedPluginsS && (
        <div className="grid grid-cols-3 gap-4">
          {searchedPluginsS.length === 0 ? (
            <Typography
              className="col-span-3"
              style={{
                fontSize: 20,
                fontWeight: "lighter",
                color: "white",
                margin: 15,
                width: "100%",
                textAlign: "center",
              }}
            >
              {`Es wurde kein Plugin gefunden`}
            </Typography>
          ) : (
            searchedPluginsS.map((plugin) => (
              <div key={plugin.id}>
                <Fade in={true}>
                  <PluginCard plugin={plugin} key={`PluginCard_${plugin.id}`} />
                </Fade>
              </div>
            ))
          )}
        </div>
      )}
      {Object.keys(plugins || {}).length === searchOptions.limit && (
        <div className="text-center my-3">
          <Button
            onClick={() =>
              setSearchOptions((prev) => ({ ...prev, limit: prev.limit + 15 }))
            }
            variant="contained"
          >
            more
          </Button>
        </div>
      )}
      {auth && profile.roles.includes(UserRole.Developer) && (
        <NewPlugin open={newPlugin} onClose={() => setNewPlugin(false)} />
      )}
    </PageContainer>
  );
};

export default withRole(UserRole.Developer)(PluginsView);
