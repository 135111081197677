import React from "react";
import ActionBar from "./components/ActionBar";
import ActiveCampaigns from "./components/CampaignViews/ActiveCampaigns";
import Adspaces from "./components/CampaignViews/Adspaces";
import InactiveCampaigns from "./components/CampaignViews/InactiveCampaigns";
import PlannedCampaigns from "./components/CampaignViews/PlannedCampaigns";

const Dashboard = () => {
  return (
    <div
      style={{ width: "100%", minWidth: "100%" }}
      className="w-full flex flex-col justify-center content-center items-center"
    >
      <ActionBar />
      <ActiveCampaigns />
      <PlannedCampaigns />
      <InactiveCampaigns />
      <Adspaces />
    </div>
  );
};

export default Dashboard;
