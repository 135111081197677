import { MouseEventHandler, useEffect, useState } from "react";
import React from "react";
import moment from "moment";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  LineElement,
  PointElement,
} from "chart.js";
import { Grid, Typography } from "@material-ui/core";
import numbro from "numbro";
import { useFirestore } from "react-redux-firebase";
import { IComponentProps } from "../../helper/ComponentProps";

interface ICSV {
  plugin_id: string;
  sold_at: string;
  type: string;
  amount: string;
  order_type: string;
  products: string;
  interval_from: string;
  interval_to: string;
  selected_types: string;
}

const PluginSales = ({ setLoading }: IComponentProps) => {
  const [from, setFrom] = React.useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [to, setTo] = React.useState<Date | null>(new Date());

  const firestore = useFirestore();

  const [salesRecord, setSalesRecord] = useState<Record<string, any>>({});

  useEffect(() => {
    // setLoading(true);
    let query = firestore
      .collection(`webhook`)
      .where(`event_type`, "==", `exs.order.completed`);
    if (from && from !== null) {
      query = query.where(`created_at`, ">", from.toISOString());
    }
    if (to && to !== null) {
      query = query.where(`created_at`, "<", to.toISOString());
    }
    query
      .limit(10000)
      .get()
      .then((result) => {
        const resultMap = result.docs.reduce<Record<string, any>>(
          (prev, curr) => {
            const key = curr.id;
            const data = curr.data();
            return { ...prev, [key]: data };
          },
          {}
        );
        setSalesRecord(resultMap);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, [from, to]);

  const orderedSales = Object.entries(salesRecord || {})
    .sort((v1, v2) => {
      if (v1[1].created_at > v2[1].created_at) {
        return -1;
      } else if (v1[1].created_at < v2[1].created_at) {
        return 1;
      } else {
        return 0;
      }
    })
    .map(([k, v]) => {
      const items: Array<Record<string, any>> = v.resource.items;
      const products: Array<string> = [];
      items.map((v) => {
        v.name && products.push(v.name);
      });
      const amount = items.reduce<number>((acc, curr) => {
        return (acc = acc + curr.amount);
      }, 0);
      return {
        eventType: v.event_type,
        createdAt: new Date(v.created_at),
        type: v.resource.metas.license_type,
        order_type: v.resource.metas.order_type,
        exsId: v.resource.metas.exs_id,
        amount: +amount,
        products: products.join(", "),
      };
    });

  const totalAmount = (): string => {
    const amount = orderedSales.reduce<number>((acc, curr) => {
      return (acc = acc + curr.amount);
    }, 0);
    return `${numbro(amount / 100).format({
      mantissa: 2,
      thousandSeparated: true,
    })} EUR`;
  };

  const totalType = (type: string): number => {
    const total = orderedSales.filter((v) => {
      return v.type === type.toLowerCase();
    });
    return total.length;
  };

  const totalOrderType = (type: string): number => {
    const total = orderedSales.filter((v) => {
      return v.order_type === type.toLowerCase();
    });
    return total.length;
  };

  const preparedDateMap = (): Record<string, any> => {
    let currentDate = new Date(from || new Date());
    const endDate = new Date(to || new Date());
    const dateMap: Record<string, any> = {};
    while (currentDate < endDate) {
      dateMap[moment(currentDate).format("DD.MM.YYYY")] = 0;
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dateMap;
  };

  const reducedData = orderedSales
    ? Object.entries(orderedSales)
        .map(([, v]): string => {
          return moment(v.createdAt).format("DD.MM.YYYY");
        })
        .reduce<Record<string, number>>((prev, curr) => {
          if (prev?.[curr]) {
            return { ...prev, [curr]: prev[curr] + 1 };
          } else {
            return { ...prev, [curr]: 1 };
          }
        }, {})
    : [];

  const orderedData = Object.entries(reducedData)
    .sort((a, b) => {
      if (new Date(a[0]) < new Date(b[0])) {
        return 1;
      } else if (new Date(a[0]) > new Date(b[0])) {
        return -1;
      } else {
        return 0;
      }
    })
    .reverse()
    .reduce<Record<string, any>>((acc, curr) => {
      const key = curr[0];
      const value = curr[1];
      return { ...acc, [key]: value };
    }, preparedDateMap());

  ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Lizenzverkäufe",
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };

  const data = {
    labels: Object.keys(orderedData),
    datasets: [
      {
        label: "Verkäufe",
        data: Object.entries(orderedData).map(([, v]) => {
          return v;
        }),
        backgroundColor: `rgba(52, 137, 235)`,
        borderColor: `#4f9fb3`,
      },
    ],
  };

  const [csv, setCSV] = useState<ICSV[]>([]);

  const csvHeaders = [
    {
      label: "Extension Interner Name",
      key: `plugin_id`,
    },
    {
      label: "Verkauft am",
      key: "sold_at",
    },
    {
      label: "Verkaufstyp",
      key: "type",
    },
    {
      label: "Betrag",
      key: "amount",
    },
    {
      label: "Vorgangstyp",
      key: "order_type",
    },
    {
      label: "Verkaufte Produkte",
      key: "products",
    },
    {
      label: "Ausgewähltes Startdatum",
      key: "interval_from",
    },
    {
      label: "Ausgewähltes Enddatum",
      key: "interval_to",
    },
    {
      label: "Ausgewählte Verkaufstypen",
      key: "selected_types",
    },
  ];

  const generateCSV = (
    event: MouseEventHandler<HTMLAnchorElement>,
    done: (proceed?: boolean) => void
  ) => {
    if (orderedSales && orderedSales.length > 0) {
      Promise.all(
        orderedSales.map((sale) => {
          const data = sale;
          setCSV((x) => [
            ...x,
            {
              plugin_id: sale.exsId,
              sold_at: moment(sale.createdAt).format("DD.MM.YYYY"),
              type: `${
                sale.type === "prod"
                  ? "Produktiv"
                  : sale.type === "test"
                  ? "Test"
                  : "Kostenlos"
              }`,
              amount: `${numbro(sale.amount / 100).format({
                mantissa: 2,
                thousandSeparated: true,
              })} EUR`,
              order_type: sale.order_type,
              products: sale.products,
              interval_from: `${moment(from).format("DD.MM.YYYY")}`,
              interval_to: `${moment(to).format("DD.MM.YYYY")}`,
              selected_types: `Alle Typen`,
            },
          ]);

          return data;
        })
      ).then(() => done());
    } else {
      done(false);
    }
  };

  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={4}>
        <Typography
          style={{ fontSize: 14, fontWeight: "light", textAlign: "center" }}
        >
          {`Neue Lizenzen`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          style={{ fontSize: 14, fontWeight: "light", textAlign: "center" }}
        >
          {`Upgrades`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          style={{ fontSize: 14, fontWeight: "light", textAlign: "center" }}
        >
          {`Verlängerungen`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          style={{ fontSize: 14, fontWeight: "bold", textAlign: "center" }}
        >
          {totalOrderType("create")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          style={{ fontSize: 14, fontWeight: "bold", textAlign: "center" }}
        >
          {totalOrderType("upgrade")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          style={{ fontSize: 14, fontWeight: "bold", textAlign: "center" }}
        >
          {totalOrderType("extend")}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <Typography
          style={{ fontSize: 12, fontWeight: "light", textAlign: "center" }}
        >
          {`Verkäufe (gesamt)`}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 0 }}>
        <Typography
          style={{ fontSize: 22, fontWeight: "bold", textAlign: "center" }}
        >
          {Object.keys(salesRecord || {}).length}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography
          style={{ fontSize: 12, fontWeight: "light", textAlign: "center" }}
        >
          {`Umsatz (gesamt)`}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 0 }}>
        <Typography
          style={{ fontSize: 22, fontWeight: "bold", textAlign: "center" }}
        >
          {totalAmount()}
        </Typography>
      </Grid>
    </Grid>
  );

  //   return (
  //     <>
  //       {/* <div
  //         className="bg-blue-700"
  //         style={{
  //           margin: 10,
  //           display: "flex",
  //           flexDirection: "row",
  //           alignContent: "center",
  //           alignItems: "center",
  //           justifyContent: "space-between",
  //           padding: 10,
  //           borderRadius: 15,
  //           fontSize: 18,
  //         }}
  //       >
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignContent: "center",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <FormControlLabel
  //             control={
  //               <Checkbox
  //                 disabled={loading}
  //                 checked={prod}
  //                 onChange={(e) => {
  //                   setProd(e.target.checked);
  //                 }}
  //               />
  //             }
  //             label="Produktiv"
  //           />
  //           <FormControlLabel
  //             control={
  //               <Checkbox
  //                 disabled={loading}
  //                 checked={test}
  //                 onChange={(e) => {
  //                   setTest(e.target.checked);
  //                 }}
  //               />
  //             }
  //             label="Test"
  //           />
  //           <FormControlLabel
  //             control={
  //               <Checkbox
  //                 disabled={loading}
  //                 checked={free}
  //                 onChange={(e) => {
  //                   setFree(e.target.checked);
  //                 }}
  //               />
  //             }
  //             label="Kostenlos"
  //           />
  //         </div>
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignContent: "center",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <RadioGroup
  //             defaultValue="all"
  //             onChange={handleOrderTypeChange}
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               alignContent: "center",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               fontSize: 12,
  //             }}
  //           >
  //             <FormControlLabel
  //               disabled={loading}
  //               value="all"
  //               control={<Radio />}
  //               label="Alle"
  //             />
  //             <FormControlLabel
  //               disabled={loading}
  //               value="create"
  //               control={<Radio />}
  //               label="Neuausstellung"
  //             />
  //             <FormControlLabel
  //               disabled={loading}
  //               value="upgrade"
  //               control={<Radio />}
  //               label="Upgrade"
  //             />
  //             <FormControlLabel
  //               disabled={loading}
  //               value="extend"
  //               control={<Radio />}
  //               label="Verlängerung"
  //             />
  //           </RadioGroup>
  //         </div>
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignContent: "center",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           Von
  //           <input
  //             type={`date`}
  //             value={moment(from || new Date()).format("YYYY-MM-DD")}
  //             onChange={handleFromChange}
  //             disabled={loading}
  //             style={{
  //               padding: 3,
  //               borderRadius: 10,
  //               marginLeft: 5,
  //               marginRight: 10,
  //             }}
  //           />
  //           Bis
  //           <input
  //             type={`date`}
  //             value={moment(to || new Date()).format("YYYY-MM-DD")}
  //             onChange={handleToChange}
  //             disabled={loading}
  //             style={{
  //               padding: 3,
  //               borderRadius: 10,
  //               marginLeft: 5,
  //               marginRight: 10,
  //             }}
  //           />
  //         </div>
  //         <Button
  //           disabled={!salesRecord || !Object.keys(salesRecord).length}
  //           component={CSVLink}
  //           data={csv}
  //           asyncOnClick={true}
  //           headers={csvHeaders}
  //           onClick={generateCSV}
  //           style={{ float: "right" }}
  //           variant={"contained"}
  //           color={"secondary"}
  //           filename={`sales.csv`}
  //         >
  //           Export CSV
  //         </Button>
  //       </div> */}
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "flex-start",
  //           alignContent: "center",
  //           alignItems: "center",
  //           width: "100%",
  //           height: 100,
  //           flexShrink: "revert",
  //           flexWrap: "wrap",
  //         }}
  //       >
  //         {loading ? (
  //           <Skeleton
  //             width={"20%"}
  //             height={"100%"}
  //             variant="rect"
  //             style={{ borderRadius: 15, margin: 10 }}
  //           />
  //         ) : (
  //           <div
  //             style={{
  //               margin: 10,
  //               height: "100%",
  //               display: "flex",
  //               flexDirection: "column",
  //               alignContent: "center",
  //               alignItems: "center",
  //               backgroundColor: "white",
  //               color: "black",
  //               width: "20%",
  //               flexShrink: "revert",
  //               padding: 10,
  //               borderRadius: 15,
  //             }}
  //           >
  //             <Grid
  //               container
  //               style={{ alignContent: "center", alignItems: "center" }}
  //             >
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 5 }}
  //                 >
  //                   {`Verkäufe (insgesamt)`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: 18,
  //                     fontWeight: "normal",
  //                     marginBottom: 5,
  //                   }}
  //                 >
  //                   {Object.keys(salesRecord || {}).length}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 5 }}
  //                 >
  //                   {`Umsatz (insgesamt)`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{ fontSize: 18, fontWeight: "bold", marginBottom: 5 }}
  //                 >
  //                   {totalAmount()}
  //                 </Typography>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         )}
  //         {loading ? (
  //           <Skeleton
  //             width={"20%"}
  //             height={"100%"}
  //             variant="rect"
  //             style={{ borderRadius: 15, margin: 10 }}
  //           />
  //         ) : (
  //           <div
  //             style={{
  //               margin: 10,
  //               display: "flex",
  //               height: "100%",
  //               flexDirection: "column",
  //               alignContent: "center",
  //               alignItems: "center",
  //               backgroundColor: "white",
  //               color: "black",
  //               width: "20%",
  //               flexShrink: "revert",
  //               padding: 10,
  //               borderRadius: 15,
  //             }}
  //           >
  //             <Grid
  //               container
  //               style={{ alignContent: "center", alignItems: "center" }}
  //             >
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
  //                 >
  //                   {`Anzahl Produktiv`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: 16,
  //                     fontWeight: "normal",
  //                     marginBottom: 2,
  //                   }}
  //                 >
  //                   {totalType("prod")}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
  //                 >
  //                   {`Anzahl Test`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: 16,
  //                     fontWeight: "normal",
  //                     marginBottom: 2,
  //                   }}
  //                 >
  //                   {totalType("test")}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
  //                 >
  //                   {`Anzahl Kostenlos`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: 16,
  //                     fontWeight: "normal",
  //                     marginBottom: 2,
  //                   }}
  //                 >
  //                   {totalType("free")}
  //                 </Typography>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         )}
  //         {loading ? (
  //           <Skeleton
  //             width={"20%"}
  //             height={"100%"}
  //             variant="rect"
  //             style={{ borderRadius: 15, margin: 10 }}
  //           />
  //         ) : (
  //           <div
  //             style={{
  //               margin: 10,
  //               display: "flex",
  //               height: "100%",
  //               flexDirection: "column",
  //               alignContent: "center",
  //               alignItems: "center",
  //               backgroundColor: "white",
  //               color: "black",
  //               width: "20%",
  //               flexShrink: "revert",
  //               padding: 10,
  //               borderRadius: 15,
  //             }}
  //           >
  //             <Grid
  //               container
  //               style={{ alignContent: "center", alignItems: "center" }}
  //             >
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
  //                 >
  //                   {`Neue Lizenzen`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: 16,
  //                     fontWeight: "normal",
  //                     marginBottom: 2,
  //                   }}
  //                 >
  //                   {totalOrderType("create")}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
  //                 >
  //                   {`Upgrades`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: 16,
  //                     fontWeight: "normal",
  //                     marginBottom: 2,
  //                   }}
  //                 >
  //                   {totalOrderType("upgrade")}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={7}>
  //                 <Typography
  //                   style={{ fontSize: 12, fontWeight: "light", marginBottom: 2 }}
  //                 >
  //                   {`Verlängerungen`}
  //                 </Typography>
  //               </Grid>
  //               <Grid item xs={5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: 16,
  //                     fontWeight: "normal",
  //                     marginBottom: 2,
  //                   }}
  //                 >
  //                   {totalOrderType("extend")}
  //                 </Typography>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         )}
  //       </div>
  //       {loading ? (
  //         <Skeleton
  //           width={"90%"}
  //           height={500}
  //           variant="rect"
  //           style={{ borderRadius: 15, margin: 10 }}
  //         />
  //       ) : (
  //         <div
  //           style={{
  //             margin: 10,
  //             display: "flex",
  //             flexDirection: "column",
  //             alignContent: "center",
  //             alignItems: "center",
  //             justifyContent: "center",
  //             // backgroundColor: "#eee",
  //             borderWidth: 2,
  //             borderColor: "white",
  //             borderStyle: "solid",
  //             width: "90%",
  //             maxWidth: 1000,
  //             flexGrow: 2,
  //             padding: 10,
  //             borderRadius: 15,
  //           }}
  //         >
  //           <Line data={data} options={options} />
  //         </div>
  //       )}
  //     </>
  //   );
};

export default PluginSales;
