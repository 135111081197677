import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ICON_STYLE } from "../../../../utils/constants";

const CheckIcon = ({ checked = false }: { checked: boolean }) => {
  return (
    <FontAwesomeIcon
      color={checked ? "#19781f" : "#ababab"}
      icon={[ICON_STYLE, "check-circle"]}
    />
  );
};

export default CheckIcon;
