import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, CalendarProps, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import useCollectionGroup from "../../../../../firestore/hooks/useCollectionGroup";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  Button,
  Card,
  CardMedia,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { DB } from "../../../../../firebase";
import {
  verifyAdspaceBooking,
  verifyAdspaceCampaignBooking,
} from "../../../utils";
import LoadingCompoenent from "../LoadingComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const Overview = () => {
  moment.locale("de", {
    week: {
      dow: 1,
      doy: 1,
    },
  });
  const localizer = momentLocalizer(moment);
  const DnDCalendar = withDragAndDrop(
    Calendar as React.ComponentType<CalendarProps<object, object>>
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [changeModalOpen, setChangeModalOpen] = useState<boolean>(false);

  const [eventToUpate, setEventToUpdate] = useState<Record<string, any> | null>(
    null
  );
  const [adspaces, setAdspaces] = React.useState<
    Array<{ name: string; value: string; exclusive?: boolean }>
  >([]);
  const [selectedEvent, setSelectedEvent] = useState<Record<
    string,
    any
  > | null>(null);

  const [campaigns, setCampaigns] = React.useState<Array<Record<string, any>>>(
    []
  );

  const [campaignList, setCampaignList] = React.useState<string[]>([]);

  const onCampaignListChanged = (event: SelectChangeEvent<Array<string>>) => {
    const {
      target: { value },
    } = event;
    setCampaignList(typeof value === "string" ? value.split(",") : value);
  };

  const [adspaceList, setAdspaceList] = React.useState<string[]>([]);

  const onAdspaceListChanged = (event: SelectChangeEvent<Array<string>>) => {
    const {
      target: { value },
    } = event;
    setAdspaceList(typeof value === "string" ? value.split(",") : value);
  };

  console.log(campaignList);
  const bookedAdspaces = useCollectionGroup<Record<string, any>>(
    "booking",
    undefined,
    undefined,
    300
    // [["active", "==", true]],
  );

  useEffect(() => {
    DB()
      .collection(`adspace`)
      .get()
      .then((data) => {
        const adspaceList: Array<{
          name: string;
          value: string;
          exclusive?: boolean;
        }> = [];
        if (!data.empty) {
          data.docs.forEach((doc) => {
            adspaceList.push({
              name: doc.data().name,
              value: doc.id,
              exclusive: doc.data()?.exclusive === true,
            });
          });
          setAdspaces(adspaceList);
        } else {
          setAdspaces([]);
        }
      });
  }, [setAdspaces]);

  useEffect(() => {
    DB()
      .collection(`campaigns`)
      .get()
      .then((data) => {
        const campaigsArray: Array<Record<string, any>> = [];
        if (!data.empty) {
          data.docs.forEach((doc) => {
            campaigsArray.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          setCampaigns(campaigsArray);
        } else {
          setCampaigns([]);
        }
      });
  }, [setCampaigns]);

  const events = Object.entries(bookedAdspaces || {})
    .filter(([k, v]) => {
      if (campaignList && campaignList.length > 0) {
        return campaignList.includes(v?.campaignId || "-");
      } else {
        return true;
      }
    })
    .filter(([k, v]) => {
      if (adspaceList && adspaceList.length > 0) {
        return adspaceList.includes(v?.adspaceId || "-");
      } else {
        return true;
      }
    })
    .map(([k, v]) => {
      return {
        start: (v.startDate.toDate() as Date).setHours(7),
        end: (v.endDate.toDate() as Date).setHours(8),
        title: `${v.campaignName} | ${v.adspaceName}`,
        allDay: true,
        resource: v,
      };
    });

  const messages = {
    today: `Heute`,
    week: `Woche`,
    month: `Monat`,
    day: `Tag`,
    Agenda: `Termine`,
    previous: `Vorheriger`,
    next: "Nächster",
    showMore: (total: any) => `${total} weitere Kampagnen`,
  };

  const handleSelect = (event: Record<string, any>) => {
    // window.alert(event.resource.campaignName);
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const handleEventDrop = async (event: any) => {
    setEventToUpdate(event);
    setChangeModalOpen(true);
  };

  const handleEventResize = (event: any) => {
    console.log(event);
  };

  const handleDragStart = () => {};

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        padding: 30,
        color: "black",
        backgroundColor: "whitesmoke",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 20,
          backgroundColor: "gainsboro",
          padding: 5,
          borderRadius: 15,
        }}
      >
        <FormControl style={{ width: 300 }}>
          <InputLabel id="select-campaign-label">Kampagne</InputLabel>
          <Select
            labelId="select-campaign-label"
            id="campaign-checkbox"
            multiple
            value={campaignList}
            onChange={onCampaignListChanged}
            input={<OutlinedInput label="Kampagne" style={{ color: "#000" }} />}
            renderValue={(selected: Array<string>) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{ borderColor: "#000", color: "#000" }}
            color="info"
            sx={{
              " .MuiFormLabel-root.Mui-focused": {
                color: "#000",
              },
              ".MuiInputLabel-animated": {
                color: "#000",
              },
            }}
          >
            {campaigns.map((campaign) => (
              <MenuItem
                key={campaign.id}
                value={campaign.id}
                style={{ color: "#000" }}
              >
                <Checkbox
                  checked={campaignList.indexOf(campaign.id) > -1}
                  style={{ borderColor: "#000", color: "#000" }}
                />
                <ListItemText
                  color="#000"
                  primary={campaign.name}
                  style={{ color: "#000" }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 300 }}>
          <InputLabel id="select-campaign-label">Adspace</InputLabel>
          <Select
            labelId="select-campaign-label"
            id="campaign-checkbox"
            multiple
            value={adspaceList}
            onChange={onAdspaceListChanged}
            input={<OutlinedInput label="Adspace" style={{ color: "#000" }} />}
            renderValue={(selected: Array<string>) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{ borderColor: "#000", color: "#000" }}
            color="info"
            sx={{
              " .MuiFormLabel-root.Mui-focused": {
                color: "#000",
              },
              ".MuiInputLabel-animated": {
                color: "#000",
              },
              border: "#000",
              borderColor: "#000",
              color: "#000",
            }}
          >
            {adspaces.map((adspace) => (
              <MenuItem
                key={adspace.value}
                value={adspace.value}
                style={{ color: "#000" }}
              >
                <Checkbox
                  checked={adspaceList.indexOf(adspace.value) > -1}
                  style={{ borderColor: "#000", color: "#000" }}
                />
                <ListItemText
                  color="#000"
                  primary={adspace.name}
                  style={{ color: "#000" }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          style={{ height: 30 }}
          onClick={() => {
            setCampaignList([]);
            setAdspaceList([]);
          }}
        >
          {`Zurücksetzen`}
        </Button>
      </div>
      <DnDCalendar
        localizer={localizer}
        events={events}
        // startAccessor="start"
        // endAccessor="end"
        draggableAccessor={() => true}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        onDragStart={handleDragStart}
        views={["month", "day"]}
        culture="de-DE"
        messages={messages}
        selected={eventToUpate}
        onSelectEvent={handleSelect}
        popup
        style={{
          width: "100%",
          maxHeight: 1000,
          minHeight: 1000,
          color: "black",
        }}
        eventPropGetter={eventStyleGetter}
        showAllEvents={false}
      />
      <EventModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        event={selectedEvent}
      />
      <ChangeModal
        open={changeModalOpen}
        close={() => setChangeModalOpen(false)}
        event={eventToUpate}
        adspaces={adspaces}
      />
    </div>
  );
};

const eventStyleGetter = (event: Record<string, any> | undefined) => {
  const backgroundColor = "#215e85";
  var style = {
    backgroundColor: event?.resource?.color
      ? event.resource.color
      : backgroundColor,
    borderRadius: "5px",
    opacity: event?.resource?.active === true ? 1 : 0.5,
    color: "black",
    border: "0px",
    display: "block",
  };
  return {
    style: style,
  };
};

const EventModal = ({
  open,
  event,
  close,
}: {
  open: boolean;
  event: Record<string, any> | null;
  close: () => void;
}) => {
  if (event === null) return null;
  const data = event.resource;
  return (
    <Modal open={open} onClose={close}>
      <Card
        style={{
          padding: 20,
          borderRadius: 15,
          backgroundColor: "whitesmoke",
          flex: 1,
          flexDirection: "column",
          alignContent: "flex-start",
          justifyContent: "center",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        }}
      >
        <>
          {data?.fileUrl && data?.fileUrl !== null && (
            <CardMedia
              style={{ height: 150 }}
              image={data.fileUrl}
              title={data.campaignName}
            />
          )}
          <div className="flex flex-row justify-center w-full">
            <Typography
              style={{
                color: "black",
                fontSize: 24,
                fontWeight: "lighter",
                marginBottom: 10,
              }}
            >
              {`Kampagne ${data.campaignName}`}
            </Typography>
          </div>
          <Grid
            container
            spacing={1}
            className="w-full"
            style={{ marginLeft: 10 }}
          >
            <Grid item xs={6}></Grid>
            <Grid item xs={2}>
              {data.active && data.active === true ? (
                <Chip label={`Aktiv`} style={{ backgroundColor: "#2a782e" }} />
              ) : (
                <Chip
                  label={`Inaktiv`}
                  style={{ backgroundColor: "#a83838" }}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {moment().isBetween(
                moment(data.startDate.toDate()),
                moment(data.endDate.toDate()),
                "D"
              ) ? (
                <Chip
                  style={{ backgroundColor: "#2a782e" }}
                  label={`Läuft gerade`}
                />
              ) : moment().isBefore(moment(data.startDate.toDate())) ? (
                <Chip
                  style={{ backgroundColor: "#387da8" }}
                  label={`Läuft demnächst`}
                />
              ) : (
                <Chip
                  style={{ backgroundColor: "#787878" }}
                  label={`Ausgelaufen`}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {data.extBookedAt && data.extBookedAt !== null ? (
                <Chip
                  icon={<FontAwesomeIcon icon={["fab", "check"]} />}
                  label={`Gebucht am ${moment(data.extBookedAt.toDate()).format(
                    "DD.MM.YYYY"
                  )}`}
                  style={{ backgroundColor: "#2a782e" }}
                />
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
              >
                Werbeplatz:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "lighter",
                  marginBottom: 2,
                }}
              >
                {`${data.adspaceName}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
              >
                Kampagne:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "lighter",
                  marginBottom: 2,
                }}
              >
                {`${data.campaignName}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
              >
                Beginn:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "lighter",
                  marginBottom: 2,
                }}
              >
                {`${moment(data.startDate.toDate()).format(`DD.MM.YYYY`)}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
              >
                Ende:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "lighter",
                  marginBottom: 2,
                }}
              >
                {`${moment(data.endDate.toDate()).format(`DD.MM.YYYY`)}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
              >
                Beschreibung:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "lighter",
                  marginBottom: 2,
                }}
              >
                {`${data.infoText}`}
              </Typography>
            </Grid>
            {data?.created && data?.created !== null && (
              <>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 2,
                    }}
                  >
                    Erstellt am:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontWeight: "lighter",
                      marginBottom: 2,
                    }}
                  >
                    {`${moment(data?.created?.toDate()).format("DD.MM.YYYY")}`}
                  </Typography>
                </Grid>
              </>
            )}
            {data?.reminderAt && data?.reminderAt !== null && (
              <>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 2,
                    }}
                  >
                    Erinnerung am:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontWeight: "lighter",
                      marginBottom: 2,
                    }}
                  >
                    {`${moment(data?.reminderAt?.toDate()).format(
                      "DD.MM.YYYY"
                    )}`}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </Card>
    </Modal>
  );
};

const ChangeModal = ({
  open,
  event,
  close,
  adspaces,
}: {
  open: boolean;
  event: Record<string, any> | null;
  close: () => void;
  adspaces: Array<{ name: string; value: string; exclusive?: boolean }>;
}) => {
  const data = event?.event?.resource || {};
  const [validatingChange, setValidatingChange] = useState<boolean>(false);
  const [isBooking, setIsBooking] = useState<boolean>(false);
  const [adspaceError, setAdspaceError] = useState<string | null>(null);
  const [camapignError, setCampaignError] = useState<string | null>(null);
  const startDate = event?.start || new Date();
  const endDate = event?.end || new Date();
  const adspace = event?.event?.resource?.adspaceId || "";
  const campaign = event?.event?.resource?.campaignId || "";
  const active = event?.event?.resource?.active || true;
  const id = event?.event?.resource?.id || "";

  const book = async () => {
    setIsBooking(true);
    await DB()
      .collection(`adspace/${adspace}/booking`)
      .doc(id)
      .set({ startDate: startDate, endDate: endDate }, { merge: true });
    close();
    setIsBooking(false);
  };

  const checkData = async () => {
    setAdspaceError(null);
    setCampaignError(null);
    setValidatingChange(true);
    //Only check exclusive adspaces for collision
    if (
      adspaces.filter((v) => {
        return v.value === adspace;
      })[0]?.exclusive === true
    ) {
      const verified = await verifyAdspaceBooking(
        adspace,
        startDate,
        endDate,
        false,
        id
      );

      //Only check overlapping for active campaigns
      if (active && verified.error) {
        console.log(verified.message);
        setAdspaceError(verified.message);
        setValidatingChange(false);
        return;
      }
    }
    const verifiedCampaign = await verifyAdspaceCampaignBooking(
      campaign,
      startDate,
      endDate
    );
    if (verifiedCampaign.error) {
      console.log(verifiedCampaign.message);
      setCampaignError(verifiedCampaign.message);
      setValidatingChange(false);
      return;
    }

    setValidatingChange(false);
    console.log(event);
  };

  useEffect(() => {
    checkData();
  }, [event]);

  if (!event) return null;

  return (
    <Modal open={open} onClose={close}>
      <Card
        style={{
          padding: 20,
          borderRadius: 15,
          backgroundColor: "whitesmoke",
          flex: 1,
          flexDirection: "column",
          alignContent: "flex-start",
          justifyContent: "center",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        }}
      >
        {validatingChange ? (
          <LoadingCompoenent />
        ) : (
          <>
            <div className="flex flex-row justify-center w-full">
              <Typography
                style={{
                  color: "black",
                  fontSize: 24,
                  fontWeight: "lighter",
                  marginBottom: 10,
                }}
              >
                {`Kampagne ${data.campaignName} aktualisieren`}
              </Typography>
            </div>
            <Grid
              container
              spacing={1}
              className="w-full"
              style={{ marginLeft: 10 }}
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 2,
                  }}
                >
                  Werbeplatz:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "lighter",
                    marginBottom: 2,
                  }}
                >
                  {`${data.adspaceName}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 2,
                  }}
                >
                  Alter Zeitraum:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "lighter",
                    marginBottom: 2,
                  }}
                >
                  {`${moment(data.startDate.toDate()).format(
                    `DD.MM.YYYY`
                  )} - ${moment(data.endDate.toDate()).format(`DD.MM.YYYY`)}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 2,
                  }}
                >
                  Neuer Zeitraum:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "normal",
                    marginBottom: 2,
                  }}
                >
                  {`${moment(startDate).format(`DD.MM.YYYY`)} - ${moment(
                    endDate
                  ).format(`DD.MM.YYYY`)}`}
                </Typography>
              </Grid>
              {adspaceError !== null && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#d6441c",
                      fontSize: 12,
                      fontWeight: "lighter",
                      marginBottom: 5,
                      marginTop: 5,
                    }}
                  >
                    {adspaceError}
                  </Typography>
                </Grid>
              )}
              {camapignError !== null && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "#d6441c",
                      fontSize: 12,
                      fontWeight: "lighter",
                      marginBottom: 5,
                      marginTop: 5,
                    }}
                  >
                    {camapignError}
                  </Typography>
                </Grid>
              )}
              <div className="flex flex-row justify-center w-full mt-12">
                <Button
                  onClick={book}
                  disabled={
                    isBooking || adspaceError !== null || camapignError !== null
                  }
                  variant="contained"
                >
                  {isBooking
                    ? "..."
                    : adspaceError !== null || camapignError !== null
                    ? "Nicht möglich"
                    : "Aktualisieren"}
                </Button>
              </div>
            </Grid>
          </>
        )}
      </Card>
    </Modal>
  );
};

export default Overview;
