import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

const BasicInfo = () => {
  return (
    <Grid container style={{ alignContent: "center", alignItems: "center" }}>
      <Grid item xs={3}>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "lighter",
            marginTop: 10,
            marginBottom: 10,
            color: "white",
          }}
        >
          {`Nächste Prüfung:`}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          style={{ fontSize: 18, fontWeight: "normal", marginBottom: 10 }}
        >
          {moment(nextExecutionRun()).format("DD.MM.YYYY hh:mm")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const nextExecutionRun = (): Date => {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  if (currentHour > 0 && currentHour < 6) {
    return new Date(currentDate.setHours(6, 0, 0));
  } else if (currentHour < 12) {
    return new Date(currentDate.setHours(12, 0, 0));
  } else if (currentHour < 18) {
    return new Date(currentDate.setHours(18, 0, 0));
  } else {
    return new Date(
      new Date(currentDate.setDate(currentDate.getDate() + 1)).setHours(0, 0, 0)
    );
  }
};

export default BasicInfo;
