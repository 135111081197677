import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { deleteAdspace } from "../../../../utils";
import { ICON_STYLE } from "../../../../../../utils/constants";
import { useNavigate } from "react-router-dom";

interface IAdspaceRowProps {
  id: string;
  name: string;
  value: { value: string; currency: string };
}

const AdspaceRow = ({ id, name, value }: IAdspaceRowProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const deleteCurrent = async () => {
    //ToDo create DeleteFunction which also deletes images etc
    if (!window.confirm("Soll dieser Werbeplatz wirklich gelöscht werden?"))
      return;
    setLoading(true);
    await deleteAdspace(id);
    setLoading(false);
  };
  const editCampaign = () => {
    //ToDo open Campaign Edit
    navigate(`/marketing/edit/adspace/${id}`);
  };

  return (
    <div
      key={`${id}_${name}`}
      style={{
        backgroundColor: "#171717",
        width: "100%",
        borderRadius: 10,
        padding: 3,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 4,
      }}
      className="flex flex-row"
    >
      <Grid
        container
        spacing={1}
        className="w-full content-center items-center"
        style={{}}
      >
        <Grid item xs={7}>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: "normal",
              padding: 4,
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: "normal",
              padding: 4,
            }}
          >
            {`${value.value}€`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <div className="flex flex-row justify-end content-center items-center">
            <IconButton
              size={"small"}
              title="Bearbeiten"
              style={{ margin: "0 5px", fontSize: 18 }}
              onClick={editCampaign}
            >
              <FontAwesomeIcon icon={[ICON_STYLE, "edit"]} size={"sm"} />
            </IconButton>
            <IconButton
              size={"small"}
              title="Löschen"
              style={{ margin: "0 5px", fontSize: 18 }}
              onClick={deleteCurrent}
            >
              <FontAwesomeIcon icon={[ICON_STYLE, "trash"]} size={"sm"} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdspaceRow;
