import {
  Button,
  Card,
  Checkbox,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import firebase from "firebase/compat/app";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InfoMarkDownButton from "../../../../components/InfoMarkdownButton";
import { Textarea } from "../../../../components/Input";
import { DB } from "../../../../firebase";
import Input from "../../../marketingNew/Dashboard/components/InputComponent";
import LoadingCompoenent from "../../../marketingNew/Dashboard/components/LoadingComponent";
import useAuth from "../../../../hooks/useAuth";
import { UserRole } from "../../../../firestore/models";
import useProfile from "../../../../hooks/firestore/useProfile";
import { useNavigate } from "react-router-dom";

const CURRENCIES = [{ name: `EUR`, value: `EUR` }];

interface IDescriptionFormProps {
  hostingId: string;
}

const DescriptionForm = ({ hostingId }: IDescriptionFormProps) => {
  const navigate = useNavigate();
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");
  const auth = useAuth();
  const profile = useProfile();

  const [loading, setLoading] = useState<boolean>(false);

  //Updating data (mostly images)
  const [updating, setUpdating] = useState<boolean>(false);

  //Name
  const [hostingName, setHostingName] = useState<string>("");
  const [hostingShortName, setHostingShortName] = useState<string>("");

  //ExtensionStore SKU
  const [exsSKU, setExsSku] = useState<string>("");

  //Preis
  const [hostingPriceValue, setHostingPriceValue] = useState<string>("0.00");
  const [hostingPriceCurrency, setHostingPriceCurrency] =
    useState<string>("EUR");

  //Sale Preis
  const [salePriceValue, setSalePriceValue] = useState<string>("0.00");
  const [salePriceCurrency, setSalePriceCurrency] = useState<string>("EUR");

  //Sale gültig bis
  const [salePriceValidUntil, setSalePriceValidUntil] = useState<Date>(
    new Date()
  );

  //Kaufargumente
  const [saleArgumentOne, setSaleArgumentOne] = useState<string>("");
  const [saleArgumentTwo, setSaleArgumentTwo] = useState<string>("");
  const [saleArgumentThree, setSaleArgumentThree] = useState<string>("");

  //Beschreibung Hosting Markdown
  const [hostingDescription, setHostingDescription] = useState<string>("");

  //Vorteile
  const [advantageOne, setAdvantageOne] = useState<string>("");
  const [advantageTwo, setAdvantageTwo] = useState<string>("");
  const [advantageThree, setAdvantageThree] = useState<string>("");
  const [advantageFour, setAdvantageFour] = useState<string>("");
  const [advantageFive, setAdvantageFive] = useState<string>("");

  //Hosting Dokumentation URL
  const [hostingDokuUrl, setHostingDokuUrl] = useState<string>("");

  //Allgemeine Info URL (Lizenez) Standard: https://ws-url.de/lizenz-infos
  const [infoUrlLicence, setInfoUrlLicence] = useState<string>(
    "https://ws-url.de/lizenz-infos"
  );

  //Allgemeine Info URL (Verkauf) Standard: https://ws-url.de/hosting-kauf
  const [infoUrlSale, setInfoUrlSale] = useState<string>(
    "https://ws-url.de/hosting-kauf"
  );

  //ExtensionStore Url
  const [exStoreUrl, setExStoreUrl] = useState<string>("");

  //Template soll im Webstollen Store angezeigt werden
  const [showInStore, setShowInStore] = useState<boolean>(false);

  //Template ist Bestseller
  const [hostingIsBestseller, setHostingIsBestseller] =
    useState<boolean>(false);

  //Template ist neu
  const [hostingIsNew, setHostingIsNew] = useState<boolean>(false);

  //Template Icon
  const [hostingIconPng, setHostingIconPng] = useState<File>();
  const [hostingIconPngUrl, setHostingIconPngUrl] = useState<string>();

  //Template Icon
  const [hostingIconSvg, setHostingIconSvg] = useState<File>();
  const [hostingIconSvgUrl, setHostingIconSvgUrl] = useState<string>();

  //Template Images
  const [hostingImages, setHostingImages] = useState<FileList>();
  const [hostingImagesUrl, setHostingImagesUrl] = useState<Array<string>>();
  const [choosenHostingImage, setChoosenHostingImage] = useState<File>();
  const [hostingImagesUploadedList, setHostingImagesUploadedList] = useState<
    Array<{ path: string; url: string }>
  >([]);

  const inputRefs = useRef<Array<HTMLInputElement>>([]);

  const handleImageInputClick = (index: number) => {
    inputRefs.current[index].click();
  };

  const addRef = (el: HTMLInputElement) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const [error, setError] = useState<{
    type: string;
    message: string;
  } | null>();

  const onHostingNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHostingName(event.currentTarget.value);
  };
  const onHostingShortNameChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHostingShortName(event.currentTarget.value);
  };

  const onExsSkuChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExsSku(event.currentTarget.value);
  };

  const onHostingPriceValueChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHostingPriceValue(event.currentTarget.value);
  };

  const onSalePriceValueChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSalePriceValue(event.currentTarget.value);
  };

  const onHostingPriceCurrencyChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setHostingPriceCurrency(event.currentTarget.value);
  };

  const onSalePriceCurrencyChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSalePriceCurrency(event.currentTarget.value);
  };

  const onSaleArgumentOneChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaleArgumentOne(event.currentTarget.value);
  };
  const onSaleArgumentTwoChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaleArgumentTwo(event.currentTarget.value);
  };
  const onSaleArgumentThreeChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaleArgumentThree(event.currentTarget.value);
  };

  const onHostingDescriptionChanged = (event: string | undefined) => {
    setHostingDescription(event || "");
  };
  const onAdvantageOneChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageOne(event.currentTarget.value);
  };
  const onAdvantageTwoChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageTwo(event.currentTarget.value);
  };
  const onAdvantageThreeChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageThree(event.currentTarget.value);
  };
  const onAdvantageFourChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageFour(event.currentTarget.value);
  };
  const onAdvantageFiveChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdvantageFive(event.currentTarget.value);
  };
  const onHostingDokuUrlChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHostingDokuUrl(event.currentTarget.value);
  };

  const onInfoUrlLicenceChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInfoUrlLicence(event.currentTarget.value);
  };

  const onInfoUrlSaleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfoUrlSale(event.currentTarget.value);
  };

  const onExStoreUrlChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExStoreUrl(event.currentTarget.value);
  };

  const onSalePriceValidUntilChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSalePriceValidUntil(new Date(event.currentTarget.value));
  };

  const onHostingIconPngChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setHostingIconPng(e.currentTarget?.files[0]);
    }
  };

  const onHostingIconSvgChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setHostingIconSvg(e.currentTarget?.files[0]);
    }
  };

  const onChoosenHostingImageChanged = async (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setUpdating(true);
      const image = e.currentTarget?.files[0];
      console.log(image.name);
      console.log(image.name.split(".").pop());
      setChoosenHostingImage(image);
      try {
        const currentRef = storage.ref(hostingImagesUploadedList[index].path);
        await currentRef.delete();
      } catch (e) {
        console.log(e);
      }
      const url = URL.createObjectURL(image);
      const response = await fetch(url);
      const blob = await response.blob();
      const path = `hostings/images/${hostingId}/${hostingId}_${moment().format(
        "x"
      )}.${image.name.split(".").pop()}`;
      const ref = storage.ref(path);
      await ref.put(blob);
      const imageUrl = await ref.getDownloadURL();
      const tempArray = [...hostingImagesUploadedList];
      tempArray.splice(index, 1);
      tempArray.splice(index, 0, { path: path, url: imageUrl });
      setHostingImagesUploadedList(tempArray);
      await DB()
        .collection(`hosting`)
        .doc(`${hostingId}`)
        .set(
          {
            media: {
              images: hostingImagesUploadedList,
            },
          },
          { merge: true }
        );
      setUpdating(false);
    }
  };

  const onHostingImagesChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files[0]) {
      setHostingImages(e.currentTarget?.files);
    }
  };

  const uploadHostingIcon = async (
    type: "PNG" | "SVG"
  ): Promise<{
    path: string;
    url: string;
  } | null> => {
    const currentIcon = type === "PNG" ? hostingIconPng : hostingIconSvg;
    if (!currentIcon || currentIcon === null || !hostingId) return null;
    const url = URL.createObjectURL(currentIcon);
    const response = await fetch(url);
    const blob = await response.blob();

    const path = `hostings/icons/${hostingId}.${
      type === "PNG" ? "png" : "svg"
    }`;
    const ref = storage.ref(path);
    await ref.put(blob);
    await ref.updateMetadata({ cacheControl: "no-cache" });
    const iconUrl = await ref.getDownloadURL();
    type === "PNG"
      ? setHostingIconPngUrl(iconUrl)
      : setHostingIconSvgUrl(iconUrl);
    return { path: path, url: iconUrl };
  };

  const deleteHostingImages = async () => {
    if (!hostingId) return;
    // if (
    //   !window.confirm("Really want to delete ALL images? (Can not be undone)")
    // )
    //   return;
    const path = `hostings/images/${hostingId}`;
    const ref = storage.ref(path);
    const files = await ref.listAll();
    for (const file of files.items) {
      await file.delete();
    }
    setHostingImagesUrl(undefined);
    await DB()
      .collection(`hosting`)
      .doc(`${hostingId}`)
      .set({ media: undefined }, { merge: true });
    return;
  };

  const uploadHostingImages = async (): Promise<Array<{
    path: string;
    url: string;
  }> | null> => {
    if (!hostingImages || hostingImages.length <= 0 || !hostingId) return null;
    const imageArray = Array.from(hostingImages).sort((a: File, b: File) => {
      const sortStringA = a.name.split(".")[0];
      const sortStringB = b.name.split(".")[0];
      if (
        sortStringA.substring(sortStringA.length - 2) >
        sortStringB.substring(sortStringB.length - 2)
      ) {
        return 1;
      } else if (
        sortStringA.substring(sortStringA.length - 2) <
        sortStringB.substring(sortStringB.length - 2)
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    const imagePaths: Array<{ path: string; url: string }> = [];
    const imageUrls: Array<string> = [];
    let counter: number = 0;
    for (const image of imageArray) {
      const url = URL.createObjectURL(image);
      const response = await fetch(url);
      const blob = await response.blob();
      const path = `hostings/images/${hostingId}/${hostingId}_${counter}.${image.name
        .split(".")
        .pop()}`;
      const ref = storage.ref(path);
      await ref.put(blob);
      const imageUrl = await ref.getDownloadURL();
      imagePaths.push({ path: path, url: imageUrl });
      imageUrls.push(imageUrl);
      counter++;
    }
    setHostingImagesUrl(imageUrls);
    return imagePaths;
  };

  const save = async () => {
    setLoading(true);
    setUpdating(true);
    //ToDo Validation
    // const urlRegex =
    //   /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g;

    // if (!urlRegex.test(templateDokuUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Template Doku muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }
    // if (!urlRegex.test(marketingVideoUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Marketing Video muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!urlRegex.test(infoUrlLicence)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Lizenz Infos muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }
    // if (!urlRegex.test(infoUrlSale)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Kauf Infos muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!urlRegex.test(exStoreUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Exetnsion Store muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!urlRegex.test(demoStoreUrl)) {
    //   setError({
    //     type: "invalid_url",
    //     message: "Demoshop muss eine gültige URL sein!",
    //   });
    //   setLoading(false);
    //   return;
    // }
    if (hostingName.length > 75) {
      setError({
        type: "invalid_length",
        message: "Name darf nicht länger als 75 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (hostingShortName.length > 20) {
      setError({
        type: "invalid_length",
        message: "ShortName darf nicht länger als 20 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (
      saleArgumentOne.length > 100 ||
      saleArgumentTwo.length > 100 ||
      saleArgumentThree.length > 100
    ) {
      setError({
        type: "invalid_length",
        message: "Kaufargumente dürfen nicht länger als 100 Zeichen sein!",
      });
      setLoading(false);
      return;
    }
    if (hostingDescription.length > 3500) {
      setError({
        type: "invalid_length",
        message:
          "Template Beschreibung darf nicht länger als 3500 Zeichen sein!",
      });
      setLoading(false);
      return;
    }

    if (
      advantageOne.length > 100 ||
      advantageTwo.length > 100 ||
      advantageThree.length > 100 ||
      advantageFour.length > 100 ||
      advantageFive.length > 100
    ) {
      setError({
        type: "invalid_length",
        message: "Vorteile dürfen nicht länger als 100 Zeichen sein!",
      });
      setLoading(false);
      return;
    }

    let iconPng = undefined;
    if (hostingIconPng && hostingIconPng !== null) {
      iconPng = await uploadHostingIcon("PNG");
    }

    let iconSvg = undefined;
    if (hostingIconSvg && hostingIconSvg !== null) {
      iconSvg = await uploadHostingIcon("SVG");
    }

    let images = undefined;
    if (hostingImages && hostingImages !== null) {
      await deleteHostingImages();
      images = await uploadHostingImages();
    } else if (
      hostingImagesUploadedList &&
      hostingImagesUploadedList.length > 0
    ) {
      images = hostingImagesUploadedList;
    }

    const templateObject = {
      name: hostingName,
      shortName: hostingShortName,
      exs_sku: exsSKU,
      price: { value: hostingPriceValue, currency: hostingPriceCurrency },
      sale: {
        price: { value: salePriceValue, currency: salePriceCurrency },
        validUntil: salePriceValidUntil,
      },
      description: hostingDescription,
      showInStore: showInStore === true,
      links: {
        dokuUrl: hostingDokuUrl,
        licenceInfo: infoUrlLicence,
        saleInfo: infoUrlSale,
        exStore: exStoreUrl,
      },
      info: {
        saleArguments: {
          arg1: saleArgumentOne,
          arg2: saleArgumentTwo,
          arg3: saleArgumentThree,
        },
        advantages: {
          adv1: advantageOne,
          adv2: advantageTwo,
          adv3: advantageThree,
          adv4: advantageFour,
          adv5: advantageFive,
        },
        templateIsBestseller: hostingIsBestseller === true,
        templateIsNew: hostingIsNew === true,
      },
      media: {
        icon_png: iconPng,
        icon_svg: iconSvg,
        images: images,
      },
    };
    await DB()
      .collection(`hosting`)
      .doc(`${hostingId}`)
      .set(templateObject, { merge: true });
    setLoading(false);
    setUpdating(false);
  };

  useEffect(() => {
    setLoading(true);
    DB()
      .collection(`hosting`)
      .doc(`${hostingId}`)
      .get()
      .then((doc) => {
        const data = doc.data();
        setHostingName(data?.name || "");
        setHostingShortName(data?.shortName || "");
        setExsSku(data?.exs_sku || "");
        setHostingPriceValue(data?.price?.value || "0.00");
        setHostingPriceCurrency(data?.price?.currency || "EUR");
        setSalePriceValue(data?.sale?.price?.value || "0.00");
        setSalePriceCurrency(data?.sale?.price?.currency || "EUR");
        setSalePriceValidUntil(data?.sale?.validUntil?.toDate());
        setHostingDescription(data?.description || "");
        setHostingDokuUrl(data?.links?.dokuUrl || "");
        setInfoUrlLicence(
          data?.links?.licenceInfo || "https://ws-url.de/lizenz-infos"
        );
        setInfoUrlSale(
          data?.links?.saleInfo || "https://ws-url.de/hosting-kauf"
        );
        setExStoreUrl(data?.links?.exStore || "");
        setSaleArgumentOne(data?.info?.saleArguments?.arg1 || "");
        setSaleArgumentTwo(data?.info?.saleArguments?.arg2 || "");
        setSaleArgumentThree(data?.info?.saleArguments?.arg3 || "");
        setAdvantageOne(data?.info?.advantages?.adv1 || "");
        setAdvantageTwo(data?.info?.advantages?.adv2 || "");
        setAdvantageThree(data?.info?.advantages?.adv3 || "");
        setAdvantageFour(data?.info?.advantages?.adv4 || "");
        setAdvantageFive(data?.info?.advantages?.adv5 || "");

        setHostingIsBestseller(data?.info?.hostingIsBestseller === true);
        setShowInStore(data?.showInStore === true);
        setHostingIsNew(data?.info?.hostingIsNew === true);
        if (data?.media?.images) {
          const imageArray: Array<string> = [];
          const uploadedArray: Array<{ path: string; url: string }> = [];
          data?.media?.images.map((v: Record<string, any>) => {
            imageArray.push(v.url);
            uploadedArray.push({ url: v.url, path: v.path });
          });
          setHostingImagesUrl(imageArray);
          setHostingImagesUploadedList(uploadedArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const moveImage = (index: number, backward: boolean = false) => {
    if (backward === true && index === 0) return;
    if (backward === false && index === hostingImagesUploadedList?.length - 1)
      return;
    setUpdating(true);
    const tempArray = [...hostingImagesUploadedList];
    const item = tempArray.splice(index, 1)[0];
    tempArray.splice(backward === true ? index - 1 : index + 1, 0, item);
    setHostingImagesUploadedList(tempArray);
    setUpdating(false);
  };

  if (!auth || !profile.roles.includes(UserRole.Developer)) {
    return null;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full">
        {loading ? (
          <LoadingCompoenent />
        ) : (
          <Card
            style={{
              padding: 20,
              borderRadius: 15,
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              justifyContent="flex-start"
              alignContent="center"
              alignItems="center"
              spacing={1}
              className="w-full"
            >
              <Grid item xs={3}>
                <Label text="Im WS-Store anzeigen:" />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={showInStore}
                  onChange={() => {
                    setShowInStore(!showInStore);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Hosting im **WS-Store** gelistet werden soll."
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Name:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Template Name"}
                  onChange={onHostingNameChanged}
                  value={hostingName}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton markdown={"**Name** des Hostings"} />
              </Grid>
              <Grid item xs={3}>
                <Label text="Short Name:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Hosting Short Name"}
                  onChange={onHostingShortNameChanged}
                  value={hostingShortName}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"**ShortName** des Hostings (max. 20 Zeichen)"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Extension-Store SKU:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"SKU"}
                  onChange={onExsSkuChanged}
                  value={exsSKU}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**SKU** des Hostings im JTL-Extension Store oder anderen Stores"
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Label text="Hosting Preis:" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"text"}
                  placeholder={"Wert"}
                  onChange={onHostingPriceValueChanged}
                  value={hostingPriceValue}
                />
              </Grid>
              <Grid item xs={4}>
                <select
                  key="price_currency"
                  value={hostingPriceCurrency}
                  onChange={onHostingPriceCurrencyChanged}
                  style={{
                    padding: 5,
                    width: "100%",
                    borderRadius: 5,
                    marginBottom: 5,
                    color: "black",
                    fontSize: 18,
                  }}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <option key={currency.value} value={currency.value}>
                        {currency.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**Preis** des Hostings im JTL-Extension Store oder anderen Stores.\n\n - Preis muss im Format `0.00` angegeben werden"
                  }
                />
              </Grid>
              {/* Beginn: Sale Preis */}
              <Grid item xs={3}>
                <Label text="Sale Preis:" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"text"}
                  placeholder={"Wert"}
                  onChange={onSalePriceValueChanged}
                  value={salePriceValue}
                />
              </Grid>
              <Grid item xs={4}>
                <select
                  key="price_currency"
                  value={salePriceCurrency}
                  onChange={onSalePriceCurrencyChanged}
                  style={{
                    padding: 5,
                    width: "100%",
                    borderRadius: 5,
                    marginBottom: 5,
                    color: "black",
                    fontSize: 18,
                  }}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <option key={currency.value} value={currency.value}>
                        {currency.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**Sale Preis** des Hostings im JTL-Extension Store oder anderen Stores.\n\n - Sale Preis muss im Format `0.00` angegeben werden"
                  }
                />
              </Grid>
              {/* Ende: Sale Preis */}
              {/* Beginn:Sale gültig bis: */}
              <Grid item xs={3}>
                <Label text="Sale gültig bis:" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"date"}
                  placeholder={"Sale gültig bis:"}
                  onChange={onSalePriceValidUntilChanged}
                  value={
                    salePriceValidUntil &&
                    moment(salePriceValidUntil).format(`YYYY-MM-DD`)
                  }
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Der Sale Preis wird bis zum Gültigkeitsablauf im WS Store angezeigt (mit Rabatt Badge)"
                  }
                />
              </Grid>
              {/* Ende: Sale gültig bis */}

              <Grid item xs={3}>
                <Label text="Kaufargumente:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Argument 1"}
                  onChange={onSaleArgumentOneChanged}
                  value={saleArgumentOne}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"Drei **Kaufargumente** für das Hosting"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Argument 2"}
                  onChange={onSaleArgumentTwoChanged}
                  value={saleArgumentTwo}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Argument 3"}
                  onChange={onSaleArgumentThreeChanged}
                  value={saleArgumentThree}
                />
              </Grid>

              <Grid item xs={3}>
                <Label text="Template Beschreibung:" />
              </Grid>
              <Grid item xs={8}>
                <MDEditor
                  value={hostingDescription}
                  preview={"live"}
                  height={200}
                  onChange={onHostingDescriptionChanged}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "**Beschreibung** des Hostings in Markdown-Language"
                  }
                />
              </Grid>
              {/* <Grid item xs={4}>
                  <MDEditor.Markdown
                    style={{ width: "100%" }}
                    source={templateDescription}
                  />
                </Grid> */}
              <Grid item xs={3}>
                <Label text="Vorteile:" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 1"}
                  onChange={onAdvantageOneChanged}
                  value={advantageOne}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Fünf **Vorteile** die, dieses Hosting mit sich bringt"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 2"}
                  onChange={onAdvantageTwoChanged}
                  value={advantageTwo}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 3"}
                  onChange={onAdvantageThreeChanged}
                  value={advantageThree}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 4"}
                  onChange={onAdvantageFourChanged}
                  value={advantageFour}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"Vorteil 5"}
                  onChange={onAdvantageFiveChanged}
                  value={advantageFive}
                />
              </Grid>

              {/* Template Doku (URL) */}
              <Grid item xs={3}>
                <Label text="Template Doku (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onHostingDokuUrlChanged}
                  value={hostingDokuUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL zur **Dokumentation** des Hostings"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Lizenz Infos (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onInfoUrlLicenceChanged}
                  value={infoUrlLicence}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL für **Lizenzinformationen** zum Hosting"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Kauf Infos (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onInfoUrlSaleChanged}
                  value={infoUrlSale}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={"URL zu **Kaufinfromationen** des Hostings"}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Extension Store (URL):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  type={"text"}
                  placeholder={"URL"}
                  onChange={onExStoreUrlChanged}
                  value={exStoreUrl}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "URL zur **Extension-Store Detailseite** des Hostings"
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Label text="TemplHostingate ist Bestseller:" />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={hostingIsBestseller}
                  onChange={() => {
                    setHostingIsBestseller(!hostingIsBestseller);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Hosting als **Bestseller** gelistet werden soll"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Hosting ist Neu:" />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  style={{ fontSize: 18, color: "#FFF" }}
                  checked={hostingIsNew}
                  onChange={() => {
                    setHostingIsNew(!hostingIsNew);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Legt fest, ob das Hosting als **neues Hosting** gelistet werden soll"
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Label text="Icon Upload (png):" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  type={"file"}
                  disabled={updating}
                  accept="image/png"
                  onChange={onHostingIconPngChanged}
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{
                    maxWidth: "50px",
                    padding: 10,
                    borderRadius: 50,
                    backgroundColor: "#eee",
                  }}
                  alt={`Template Icon of ${hostingName}`}
                  className="mx-auto w-full"
                  src={`https://ws-cdn.de/hostings/icons/${hostingId}.png`}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Dient dem Upload des Hosting Icons im **PNG-Fromat**. Bitte beachten:\n\n - hier dürfen nur Bilder in korrektem **PNG-Format** hochgeladen werden.\n - die hochgeladene Datei wird automatisch zu **${hostingId}.png** umbenannt.`}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Icon Upload (svg):" />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={updating}
                  type={"file"}
                  accept="image/svg+xml"
                  onChange={onHostingIconSvgChanged}
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{
                    maxWidth: "50px",
                    padding: 10,
                    borderRadius: 50,
                    backgroundColor: "#eee",
                  }}
                  alt={`Template Icon of ${hostingName}`}
                  className="mx-auto w-full"
                  src={`https://ws-cdn.de/hostings/icons/${hostingId}.svg`}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={`Dient dem Upload des Hosting Icons im **SVG-Fromat**. Bitte beachten:\n\n - hier dürfen nur Bilder in korrektem **SVG-Format** hochgeladen werden.\n - die hochgeladene Datei wird automatisch zu **${hostingId}.svg** umbenannt.`}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Bilder Upload (jpeg/png):" />
              </Grid>
              <Grid item xs={8}>
                <Input
                  disabled={updating}
                  type={"file"}
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={onHostingImagesChanged}
                />
                {/* <input
                    accept="image/jpeg"
                    type="file"
                    multiple
                    onChange={onTemplateImagesChanged}
                  /> */}
              </Grid>
              <Grid item xs={1}>
                <InfoMarkDownButton
                  markdown={
                    "Dient dem Upload von Bilder zur Darstellung des Hostings\n\n - z.B. Screenshots des Hosting-Backends o.Ä.\n - Es können mehrere Bilder zum Upload ausgewählt werden.\n - Beim Upload von neuen Bildern werden immer alle aktuell existierenden Bilder gelöscht.\n - Die Reihenfolge der Bilder kann über die letzen beiden Zeichen des Bildnamens festgelegt werden. `filenamexy2_**04**.png` wird beispielsweise vor `filenameab1_**08**.png` einsortiert"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ImageList cols={3} className="mt-4">
                  {hostingImagesUploadedList &&
                    hostingImagesUploadedList.map((image, index) => (
                      <ImageListItem key={`${image}_${index}`}>
                        <img
                          src={image.url}
                          srcSet={image.url}
                          alt={image.url}
                          loading="eager"
                        />
                        <ImageListItemBar
                          actionIcon={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              {index > 0 && (
                                <Button
                                  disabled={updating}
                                  onClick={() => {
                                    moveImage(index, true);
                                  }}
                                >{`<`}</Button>
                              )}
                              <input
                                disabled={updating}
                                ref={addRef}
                                accept="image/jpeg, image/png"
                                style={{ display: "none" }}
                                type="file"
                                onChange={(e) => {
                                  onChoosenHostingImageChanged(e, index);
                                }}
                              />
                              <Button
                                disabled={updating}
                                onClick={() => handleImageInputClick(index)}
                              >{`Tauschen`}</Button>
                              {index < hostingImagesUploadedList.length - 1 &&
                                hostingImagesUploadedList.length > 1 && (
                                  <Button
                                    disabled={updating}
                                    onClick={() => {
                                      moveImage(index);
                                    }}
                                  >{`>`}</Button>
                                )}
                            </div>
                          }
                        />
                      </ImageListItem>
                    ))}
                </ImageList>
              </Grid>
            </Grid>

            {error !== null && (
              <div className="flex flex-row justify-center w-full my-8">
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "lighter",
                    color: "#f5922f",
                    marginTop: 2,
                  }}
                >
                  {error?.message}
                </Typography>
              </div>
            )}
            <div className="flex w-full justify-around">
              <Button variant="contained" disabled={loading} onClick={save}>
                Speichern
              </Button>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 18,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default DescriptionForm;
