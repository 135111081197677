import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UserCard from "./UserCard";
import dashbar from "../../../../../firebase/dashbar";
import { GREY } from "../../../../../constants/colors";
import { useNavigate } from "react-router-dom";

const UserInfo = ({
  users,
}: {
  company: Record<string, any>;
  users: Array<Record<string, any>> | null;
}) => {
  const [config, setConfig] = useState<Record<string, any> | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    dashbar()
      .db.collection(`config`)
      .doc(`general`)
      .get()
      .then((res) => setConfig({ ...res.data() }));
  }, []);

  return (
    <Grid container spacing={2}>
      {users !== null &&
        users
          .sort((u1: Record<string, any>, u2: Record<string, any>) => {
            if (u1.isOwner === true || u2.isOwner === true) {
              if (u1.isOwner === true && u2.isOwner === false) {
                return -1;
              } else if (u1.isOwner === false && u2.isOwner === true) {
                return 1;
              } else {
                return 0;
              }
            } else if (u1.isAdmin === true || u2.isAdmin === true) {
              if (u1.isAdmin === true && u2.isAdmin === false) {
                return -1;
              } else if (u1.isAdmin === false && u2.isAdmin === true) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          })
          .map((user) => {
            return (
              <Grid item xs={4} alignContent="stretch" alignItems="stretch">
                <Paper
                  onClick={() => navigate(`/dashbar/users/${user?.id}`)}
                  elevation={4}
                  style={{
                    padding: 20,
                    borderRadius: 15,
                    backgroundColor: GREY[900],
                    cursor: "pointer",
                  }}
                >
                  <UserCard config={config} user={user} />
                </Paper>
              </Grid>
            );
          })}
    </Grid>
  );
};

export default UserInfo;
