import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { firestore } from "firebase-admin";
import moment from "moment";
import React from "react";
import { useCollection } from "../../../../../../../firestore/hooks";
import { ICON_STYLE } from "../../../../../../../utils/constants";

const NextInvoices = () => {
  const orders = useCollection<{
    order: string;
    created: firestore.Timestamp;
    discount: number;
    isSepa: boolean;
    organisation_name: string;
    company_name: string;
  }>(`sevdesk_orders`, undefined, undefined, 30);
  return (
    <>
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          marginTop: 10,
          marginBottom: 10,
          color: "white",
        }}
      >
        {`Erstellte Aufträge:`}
      </Typography>
      <div className="flex flex-row justify-start items-stretch content-stretch w-full flex-wrap">
        {Object.entries(orders || {}).map(([k, v]) => {
          return (
            <InvoiceCard
              id={k}
              company_name={v.company_name}
              created={v.created.toDate()}
              discount={v.discount}
              isSepa={v.isSepa}
              order={v.order}
              organisation_name={v.organisation_name}
              key={k}
            />
          );
        })}
      </div>
    </>
  );
};

export default NextInvoices;

const InvoiceCard = ({
  id,
  order,
  created,
  discount,
  isSepa,
  organisation_name,
  company_name,
}: {
  id: string;
  order: string;
  created: Date;
  discount: number;
  isSepa: boolean;
  organisation_name: string;
  company_name: string;
}) => {
  return (
    <Card
      style={{
        maxWidth: 400,
        borderRadius: 15,
        margin: 10,
        background: `linear-gradient(to right bottom, #242526, #444445)`,
      }}
    >
      <CardContent style={{ flexDirection: "column" }}>
        <Grid
          container
          style={{ alignContent: "stretch", alignItems: "stretch" }}
        >
          <Grid item xs={11}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "white",
              }}
            >
              {`${company_name}`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              size={"small"}
              title="In Sevdesk öffnen"
              style={{ margin: "0 5px" }}
            >
              <a
                target="_blank"
                href={`https://my.sevdesk.de/#/om/detail/type/AB/id/${order}`}
              >
                <FontAwesomeIcon icon={[ICON_STYLE, "link"]} size={"sm"} />
              </a>
            </IconButton>
          </Grid>
          <Grid item xs={12} className="mb-4">
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "lighter",
                marginBottom: 14,
                color: "white",
              }}
            >
              {`${organisation_name}`}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "lighter",
                color: "white",
              }}
            >
              {`Erstellt:`}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "normal",
                color: "white",
              }}
            >
              {`${moment(created).format("DD.MM.YYYY hh:mm")}`}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "lighter",
                color: "white",
              }}
            >
              {`Order ID:`}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "normal",
                color: "white",
              }}
            >
              {`${order}`}
            </Typography>
          </Grid>
          {discount && discount !== null && (
            <>
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: "lighter",
                    color: "white",
                  }}
                >
                  {`Rabatt:`}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: "normal",
                    color: "white",
                  }}
                >
                  {`${discount}%`}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "lighter",
                marginTop: 8,
                color: "white",
              }}
            >
              {`Rechnung am:`}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "normal",
                marginTop: 8,
                color: "white",
              }}
            >
              {`${moment(getExecutionTime(created)).format(
                "DD.MM.YYYY hh:mm"
              )}`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const getExecutionTime = (date: Date): Date => {
  const currentDate = new Date(date.setDate(date.getDate() + 3));
  const currentHour = currentDate.getHours();
  if (currentHour > 0 && currentHour < 6) {
    return new Date(currentDate.setHours(6, 0, 0));
  } else if (currentHour < 12) {
    return new Date(currentDate.setHours(12, 0, 0));
  } else if (currentHour < 18) {
    return new Date(currentDate.setHours(18, 0, 0));
  } else {
    return new Date(
      new Date(currentDate.setDate(currentDate.getDate() + 1)).setHours(0, 0, 0)
    );
  }
};
