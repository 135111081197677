import { Card, CircularProgress } from "@material-ui/core";
import React from "react";

const LoadingCompoenent = () => {
  return (
    <Card
      style={{
        padding: 20,
        borderRadius: 15,
        flex: 1,
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 300,
      }}
    >
      <CircularProgress />
    </Card>
  );
};

export default LoadingCompoenent;
