import React, { useEffect, useState } from "react";
import moment from "moment";
import "./style.css";
import { DB } from "../../../../firebase";
import axios from "axios";

const PluginCheckoutNew = ({ type = "day" }: { type: "month" | "day" }) => {
  const dateString =
    type === "day"
      ? moment().set("hour", 1).set("minute", 0).toDate().toISOString()
      : moment()
          .startOf("month")
          .set("hour", 1)
          .set("minute", 0)
          .toDate()
          .toISOString();
  const [checkoutSize, setCheckoutSize] = useState<number>(0);

  useEffect(() => {
    const loadData = () => {
      DB()
        .collection("webhook")
        .where("event_type", "==", "exs.order.completed")
        .where("created_at", ">", dateString)
        .get()
        .then((docs) => {
          let counter = 0;
          docs.forEach((doc) => {
            const data = doc.data();
            if (data?.resource?.metas) {
              if (
                ["prod", "free"].includes(
                  data?.resource?.metas?.license_type
                ) &&
                data?.resource?.metas?.order_type === "create"
              ) {
                counter++;
              }
            }
          });
          setCheckoutSize(counter);
        });
    };
    loadData();
    const interval = setInterval(loadData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const toInt32 = (f: number): number => {
    return f >> 0;
  };
  const separateDigits = (num: number): number[] => {
    let arr: number[] = [];
    let lastDigit: number;

    num = toInt32(num);

    do {
      lastDigit = num % 10;
      arr.push(lastDigit);
      num = toInt32(num / 10);
    } while (num !== 0);

    return arr.reverse();
  };

  // useEffect(() => {
  //   try {
  //     axios
  //       .get(`http://numbersapi.com/${checkoutSize}`, {})
  //       .then((data) => {
  //         console.log(data.data);
  //       })
  //       .catch(() => {});
  //   } catch {}
  // }, [checkoutSize]);

  const position1 = checkoutSize > 99 ? separateDigits(checkoutSize)[0] : 0;
  const position2 =
    checkoutSize > 99
      ? separateDigits(checkoutSize)[1]
      : checkoutSize > 9
      ? separateDigits(checkoutSize)[0]
      : 0;
  const position3 =
    checkoutSize > 99
      ? separateDigits(checkoutSize)[2]
      : checkoutSize > 9
      ? separateDigits(checkoutSize)[1]
      : checkoutSize || 0;

  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          width: "100%",
          minWidth: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <span className="number" data-number={position1}>
          <span className="primary"></span>
          <span className="secondary"></span>
        </span>
        <span className="number" data-number={position2}>
          <span className="primary"></span>
          <span className="secondary"></span>
        </span>
        <span className="number" data-number={position3}>
          <span className="primary"></span>
          <span className="secondary"></span>
        </span>
      </div>
      <div></div>
    </div>
  );
};

export default PluginCheckoutNew;
