import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import numbro from "numbro";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import getDashBar from "../../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import { IComponentProps } from "../../helper/ComponentProps";
import { ICON_STYLE } from "../../../../utils/constants";

const DashbarUser = ({ setLoading }: IComponentProps) => {
  const [statistics, setStatistics] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();
  useEffect(() => {
    getDashBar()
      .db.collection("statistic_weekly")
      .limit(12)
      .orderBy("createdAt", "desc")
      .onSnapshot((snap) => {
        setStatistics(snap.docs ?? []);
      });
  }, [setStatistics]);

  const createCompanyArray = () => {
    const data = Object.entries(statistics || {})
      .map(([k, v]) => {
        const data = v.data();
        return {
          timestamp: data?.timestamp || null,
          createdAt: data.createdAt || null,
          total: data?.["user"]?.all || 0,
          free: data?.["user"]?.free || 0,
          business: data?.["user"]?.business || 0,
          pro: data?.["user"]?.pro || 0,
          trial: data?.["user"]?.trial || 0,
          jtlshop4: data?.["user"]?.jtlshop4 || 0,
          jtlshop5: data?.["user"]?.jtlshop5 || 0,
          shopware6: data?.["user"]?.shopware6 || 0,
          daily: data?.["user"]?.daily || 0,
          weekly: data?.["user"]?.weekly || 0,
          monthly: data?.["user"]?.monthly || 0,
          quarterly: data?.["user"]?.quarterly || 0,
          notifications: data?.["user"]?.notifications || 0,
          notificationsTotal: data?.["user"]?.notificationsTotal || 0,
        };
      })
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1;
        } else if (a.createdAt < b.createdAt) {
          return -1;
        } else {
          return 0;
        }
      });
    return data;
  };
  const userData = createCompanyArray();
  const dataMappedAll = userData
    .map((v) => {
      return v["total"];
    })
    .filter((v, i) => {
      return i > userData.length - 4;
    });
  const valueOld = dataMappedAll[dataMappedAll.length - 2];
  const valueNew = dataMappedAll[dataMappedAll.length - 1];
  const valueOldChecked = valueOld ? +valueOld : 1;
  const diff = +valueNew - valueOldChecked;
  const per = Math.abs(((+valueNew - valueOldChecked) / valueOldChecked) * 100);
  const amount = dataMappedAll[dataMappedAll.length - 1];
  return (
    <div>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={5}>
          <Typography style={{ fontSize: 28, fontWeight: "bold" }}>
            {amount}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Chart
            options={{
              legend: { show: false },
              grid: { show: false },
              tooltip: { enabled: false },
              // colors: ["#014938"],
              colors: ["#1b9e7f"],
              chart: {
                type: "line",
                toolbar: { show: false },
                zoom: { enabled: false },
                width: "100%",
              },
              stroke: { curve: "smooth", width: 2.5 },
              states: { hover: { filter: { type: "none" } } },
              xaxis: {
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: { show: false },
                crosshairs: { show: false },
              },
              yaxis: {
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: { show: false },
                crosshairs: { show: false },
              },
            }}
            series={[
              {
                name: "Desktops",
                data: dataMappedAll,
              },
            ]}
          />
        </Grid>
      </Grid>
      <div className="flex justify-start items-center">
        <FontAwesomeIcon
          icon={[
            ICON_STYLE,
            diff > 0 ? "arrow-up" : diff < 0 ? "arrow-down" : "arrow-right",
          ]}
          color={diff > 0 ? "green" : diff < 0 ? "red" : "white"}
          style={{
            width: 20,
            height: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: "rgb(255, 255, 255,0.1)",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        />
        <Typography
          style={{ marginLeft: 5, fontSize: 15, fontWeight: "normal" }}
        >{`${diff > 0 ? "" : "-"}${numbro(per).format({
          mantissa: 2,
        })}%`}</Typography>
        <Typography
          style={{ marginLeft: 5, fontSize: 14, fontWeight: "lighter" }}
        >
          {diff > 0
            ? `mehr Nutzer als letzte Woche`
            : diff < 0
            ? `weniger Nutzer als letzte Woche`
            : `keine neuen Nutzer`}
        </Typography>
      </div>
    </div>
  );
};

export default DashbarUser;
