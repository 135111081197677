import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Chip,
  Grid,
  IconButton,
  Link as MLink,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { OLD_Plugin as Plugin, Release } from "@dash.bar/types";
import { FileCopy, OpenInNew } from "@material-ui/icons";
import ReleaseList from "./ReleaseList";
import LicencesView from "./licences";
import { useCollection, useDocument } from "../../../../firestore/hooks";
import LoadingWrapper from "../../../../components/LoadingWrapper";
import withRole from "../../../../hoc/withRole";
import { UserRole } from "../../../../firestore/models/User";
import PageContainer from "../../../../components/PageContainer";
import Ads from "./ads";
import InstallView from "./installs";
import SalesView from "./sales";
import useAuth from "../../../../hooks/useAuth";
import useProfile from "../../../../hooks/firestore/useProfile";
import DescriptionForm from "./shop/DescriptionForm";
import Addon from "./eucookie";
import { compareSemanticVersions } from "../../../../utils/helper-functions";
import TicketsView from "./ticktes";
import StatsView from "./stats";

const PluginDetails = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();
  const { pluginId } = useParams<{ pluginId: string }>();
  const [releaseComplete, setReleaseComplete] = useState<boolean>(true);
  const plugin = useDocument<
    Plugin & { price: { value: string; currency: string } }
  >("plugin", pluginId);

  const unsortedReleases = useCollection<
    Release & {
      requirements: {
        minPhpVersion: string;
        minShopVersion: string;
        maxShopVersion: string;
      };
      isPrivateRelease: boolean;
    }
  >(`plugin/${pluginId}/release`, undefined, ["version", "desc"], 400);

  useEffect(() => {
    if (unsortedReleases) {
      Object.values(unsortedReleases || {}).map((v) => {
        if (
          (v.changelog === undefined ||
            v.changelog.trim() === "" ||
            v.requirements?.minPhpVersion === undefined ||
            v.requirements?.minPhpVersion.trim() === "" ||
            v?.downloadUrl === undefined ||
            v?.downloadUrl.trim() === "" ||
            v.requirements?.minShopVersion === undefined ||
            v.requirements?.minShopVersion.trim() === "") &&
          releaseComplete === true
        )
          setReleaseComplete(false);
      });
    }
  }, [unsortedReleases]);

  // TODO: make this work
  const releases = Object.values(unsortedReleases || {})
    .sort(compareSemanticVersions("version"))
    .reduce<Record<string, any>>((prev, curr, index) => {
      return { ...prev, [index]: curr };
    }, {});

  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = false; //We want to use only light mode at the moment

  const [value, setValue] = useState(0);
  //const cls = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <LoadingWrapper loading={plugin === undefined}>
      <PageContainer
        title={
          <>
            <div className="flex justify-start items-center content-center w-full">
              <Typography
                key={`plugin_title`}
                style={{
                  fontSize: 30,
                  fontWeight: "normal",
                  marginRight: 15,
                }}
              >
                {plugin?.name}
              </Typography>
              {plugin?.platform ? (
                <Chip label={plugin?.platform} color={"primary"} />
              ) : (
                <Chip label={"Platform missing"} color={"secondary"} />
              )}
            </div>
          </>
        }
        onClose={() => navigate("/developer/plugins")}
      >
        <Grid container>
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            alignContent={"center"}
          >
            <Grid item xs={12} sm={8} lg={9} xl={10}>
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs={2}>
                  <Label text="Plugin ID:" />
                </Grid>
                <Grid item xs={10}>
                  <Text text={plugin?.pluginId || "n/a"} />
                </Grid>
                <Grid item xs={2}>
                  <Label text="Plugin Preis:" />
                </Grid>
                <Grid item xs={10}>
                  <Text
                    text={`${plugin?.price?.value || "0.00"} ${
                      plugin?.price?.currency || "EUR"
                    }`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Label text="GitLab ID:" />
                </Grid>
                <Grid item xs={10}>
                  <Text text={`${plugin?.gitlabId}` || "n/a"} />
                </Grid>
                <Grid item xs={2}>
                  <Label text="GitLab:" />
                </Grid>
                <Grid item xs={6}>
                  <Text text={`${plugin?.gitlabUrl}` || "n/a"} />
                </Grid>
                <Grid item xs={4}>
                  <div className="flex justify-start items-center content-center">
                    <IconButton
                      className={"c2c"}
                      data-clipboard-text={plugin?.gitlabUrl}
                    >
                      <FileCopy fontSize={"small"} />
                    </IconButton>
                    <IconButton
                      component={MLink}
                      href={plugin?.gitlabUrl}
                      target={"_blank"}
                    >
                      <OpenInNew fontSize={"small"} />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <Label text="Exs SKU:" />
                </Grid>
                <Grid item xs={10}>
                  <Text text={plugin?.exs_sku || "n/a"} />
                </Grid>
                <Grid item xs={2}>
                  <Label text="Exs ID:" />
                </Grid>
                <Grid item xs={10}>
                  <Text text={plugin?.exs_id || "n/a"} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} lg={3} xl={2} className="text-center">
              <img
                style={{
                  maxWidth: "200px",
                  padding: 10,
                  borderRadius: 150,
                  ...(prefersDarkMode ? null : { backgroundColor: "#eee" }),
                }}
                alt={`Plugin Icon of ${plugin?.name}`}
                className="mx-auto w-full"
                // src={
                //   `https://cdn.webstollen.de/plugins/${pluginId.replace(
                //     "ws5_",
                //     "ws_"
                //   )}` + (prefersDarkMode ? "_ws_w.png" : "_ws.svg")
                // }
                src={
                  `https://ws-cdn.de/plugins/icons/${pluginId?.replace(
                    "ws5_",
                    "ws_"
                  )}` + (prefersDarkMode ? "_w.png" : ".svg")
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
              >
                {/* {auth && !profile.roles.includes(UserRole.Extern) && ( */}
                <Tab
                  value={0}
                  label={
                    <Typography
                      key={`releases`}
                      style={{
                        fontSize: 14,
                        fontWeight: "light",
                      }}
                    >
                      {`BESCHREIBUNG`}
                    </Typography>
                  }
                />
                {/* )} */}
                <Tab
                  value={1}
                  label={
                    releaseComplete ? (
                      <Typography
                        key={`releases`}
                        style={{
                          fontSize: 14,
                          fontWeight: "light",
                        }}
                      >
                        {`RELEASES`}
                      </Typography>
                    ) : (
                      <Badge color="error" badgeContent={`!`}>
                        <Typography
                          key={`releases`}
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          {`RELEASES`}
                        </Typography>
                      </Badge>
                    )
                  }
                />
                {/* {auth && !profile.roles.includes(UserRole.Extern) && ( */}
                <Tab
                  value={3}
                  label={
                    <Typography
                      key={`releases`}
                      style={{
                        fontSize: 14,
                        fontWeight: "light",
                      }}
                    >
                      {`LIZENZEN`}
                    </Typography>
                  }
                />
                {/* )} */}
                <Tab
                  value={4}
                  label={
                    <Typography
                      key={`releases`}
                      style={{
                        fontSize: 14,
                        fontWeight: "light",
                      }}
                    >
                      {`INSTALLATIONEN`}
                    </Typography>
                  }
                />
                {auth && profile.roles.includes(UserRole.Operational) && (
                  <Tab
                    value={5}
                    label={
                      <Typography
                        key={`releases`}
                        style={{
                          fontSize: 14,
                          fontWeight: "light",
                        }}
                      >
                        {`VERKAUFT`}
                      </Typography>
                    }
                  />
                )}
                {auth && profile.roles.includes(UserRole.Operational) && (
                  <Tab
                    value={8}
                    label={
                      <Typography
                        key={`tickets`}
                        style={{
                          fontSize: 14,
                          fontWeight: "light",
                        }}
                      >
                        {`TICKETS`}
                      </Typography>
                    }
                  />
                )}
                {auth && profile.roles.includes(UserRole.Operational) && (
                  <Tab
                    value={9}
                    label={
                      <Typography
                        key={`stats`}
                        style={{
                          fontSize: 14,
                          fontWeight: "light",
                        }}
                      >
                        {`STATS`}
                      </Typography>
                    }
                  />
                )}
                {pluginId === "ws5_eucookie" && (
                  <Tab
                    value={7}
                    label={
                      <Typography
                        key={`eucookie`}
                        style={{
                          fontSize: 14,
                          fontWeight: "light",
                        }}
                      >
                        {`EU-Cookie`}
                      </Typography>
                    }
                  />
                )}
                <Tab
                  value={6}
                  label={
                    <Typography
                      key={`releases`}
                      style={{
                        fontSize: 14,
                        fontWeight: "light",
                      }}
                    >
                      {`BACKEND`}
                    </Typography>
                  }
                />
              </Tabs>

              {/* {value === 0 ? (
                <div>
                  <Typography variant="h4">Releases</Typography>
                  {plugin && releases && (
                    <ReleaseList data={releases} plugin={plugin} />
                  )}
                </div>
              ) : null}
              {value === 1 ? (
                <div>{plugin && <Config plugin={plugin} />}</div>
              ) : null}
              {/* {plugin && value === 2 ? (
                <ShopDescriptions plugin={plugin} />
              ) : null} }
              {plugin && value === 2 ? (
                <DescriptionForm pluginId={pluginId} />
              ) : null}
              {plugin && value === 3 ? (
                <LicencesView pluginId={pluginId} />
              ) : null}
              {plugin && value === 4 ? (
                <InstallView pluginId={pluginId} />
              ) : null}
              {plugin &&
              value === 5 &&
              auth &&
              profile.roles.includes(UserRole.Operativ) ? (
                <SalesView plugin={plugin} />
              ) : null}
              {plugin && value === 6 ? <Ads plugin={plugin} /> : null} */}
              {value === 0 ? (
                <DescriptionForm pluginId={`${pluginId}`} />
              ) : null}
              {value === 1 ? (
                <div>
                  <Typography variant="h4">Releases</Typography>
                  {plugin && releases && (
                    <ReleaseList data={releases} plugin={plugin} />
                  )}
                </div>
              ) : null}
              {/* {plugin && value === 2 ? (
                <ShopDescriptions plugin={plugin} />
              ) : null} */}
              {/* {plugin && value === 2 ? (
                <div>{plugin && <Config plugin={plugin} />}</div>
              ) : null} */}
              {plugin && value === 3 ? (
                <LicencesView pluginId={`${pluginId}`} />
              ) : null}
              {plugin && value === 4 ? (
                <InstallView pluginId={`${pluginId}`} />
              ) : null}
              {plugin &&
              value === 5 &&
              auth &&
              profile.roles.includes(UserRole.Operational) ? (
                <SalesView plugin={plugin} />
              ) : null}
              {plugin &&
              value === 8 &&
              auth &&
              profile.roles.includes(UserRole.Operational) ? (
                <TicketsView plugin={plugin} />
              ) : null}
              {plugin &&
              value === 9 &&
              auth &&
              profile.roles.includes(UserRole.Operational) ? (
                <StatsView plugin={plugin} />
              ) : null}
              {plugin && value === 6 ? <Ads plugin={plugin} /> : null}
              {plugin && value === 7 && pluginId === "ws5_eucookie" ? (
                <Addon pluginId={pluginId} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </LoadingWrapper>
  );
};

const Label = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 16,
        fontWeight: "bold",
      }}
    >
      {text}
    </Typography>
  );
};

const Text = ({ text }: { text: string }) => {
  return (
    <Typography
      key={text}
      style={{
        fontSize: 16,
        fontWeight: "lighter",
      }}
    >
      {text}
    </Typography>
  );
};

export default withRole(UserRole.Developer)(PluginDetails);
