import React from "react";
import { BLACK, WHITE } from "../../helper/colors";
import { Typography } from "@material-ui/core";

const Badge = ({
  title,
  color,
  fontWhite = false,
  solid = false,
}: {
  title: string;
  color?: string;
  fontWhite?: boolean;
  solid?: boolean;
}) => {
  return (
    <div
      style={{
        padding: 5,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: 10,
        ...(solid === true && { backgroundColor: color ?? BLACK }),
        borderColor: color ?? BLACK,
        borderStyle: "solid",
        borderWidth: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        style={{
          color: fontWhite === true ? WHITE : BLACK,
          fontSize: 14,
          fontFamily: "Roboto",
          fontWeight: "500",
        }}
      >
        {`${title.toUpperCase()}`}
      </Typography>
    </div>
  );
};

export default Badge;
