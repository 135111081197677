import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Row } from "./Row";

export const CouponTable = ({
  coupons,
}: {
  coupons: Array<Record<string, any>>;
}) => (
  <TableContainer
    component={Paper}
    style={{ backgroundColor: "#333", marginTop: 20 }}
  >
    <Table size="small" aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Name`}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Code`}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Art`}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Aktiv`}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Gültig ab`}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Gültig bis`}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Erstellt am`}
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {coupons.map((coupon, index) => (
          <Row key={`${index}_${coupon?.code}`} row={coupon} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
