import React, { useEffect, useState } from "react";
import { DB } from "../../../../firebase";
import { Grid, Typography } from "@material-ui/core";
import { IComponentProps } from "../../helper/ComponentProps";

const MarketingBanner = ({ setLoading }: IComponentProps) => {
  const [currentCampaign, setCurrentCampaign] = useState<string | null>(null);
  useEffect(() => {
    DB()
      .collection(`config`)
      .doc(`campaign`)
      .get()
      .then((data) => {
        const campaignData = data.data();
        if (campaignData?.tm_banner?.live !== undefined) {
          setCurrentCampaign(campaignData?.tm_banner?.live);
        } else {
          setCurrentCampaign(null);
        }
      });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {currentCampaign !== null && currentCampaign !== "default" ? (
        <Grid
          container
          spacing={2}
          style={{ flexGrow: 1, width: "100%" }}
          justifyContent={"flex-start"}
          alignItems={"stretch"}
          alignContent="stretch"
        >
          <Grid item xs={5}>
            <Typography
              style={{
                fontSize: 16,
                color: "white",
                marginTop: 10,
                marginBottom: 10,
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
            >
              {`Aktuelle Kampagne:`}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              style={{
                fontSize: 16,
                color: "white",
                marginTop: 10,
                marginBottom: 10,
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              {currentCampaign}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography
          style={{
            fontSize: 14,
            color: "white",
            marginTop: 10,
            marginBottom: 10,
            fontFamily: "Roboto",
            fontWeight: "300",
          }}
        >
          {`Derzeit ist keine gebuchte Kampagne aktiv. Als Bild wird der Default-Banner angezeigt. Zum Default-Banner sind keine Leistungswerte verfügbar.`}
        </Typography>
      )}
    </div>
  );
};

export default MarketingBanner;
