import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Login from "../../public/login";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import md5 from "md5";
// import ShopsModule from "../../modules/licencesOld";
// import ConfigModule from "../../modules/config";
import { isEmpty, isLoaded } from "react-redux-firebase";
import useStyles from "./styles";
import Navigation from "../../components/Navigation";
import useProfile from "../../hooks/firestore/useProfile";
import ShorturlModule from "../../modules/shorturl";
import DashboardModule from "../../modules/dashboard";
import { useSnackbar } from "notistack";
import ClipboardJS from "clipboard";
import UsersModule from "../../modules/users";
import ExtensionstoreModule from "../../modules/extensionstore";
import ToolsModule from "../../modules/tools";
import DeveloperModule from "../../modules/developer";
import useLoading from "../../hooks/useLoading";
import LoadingWrapper from "../../components/LoadingWrapper";
import DashbarModule from "../../modules/dashbar";
// import MarketingModule from "../../modules/marketing";
import MarketingModule from "../../modules/marketingNew";
import APIModule from "../../modules/api";
// import Intranet from "../../modules/intranet";
import DashboardNew from "../../modules/dashboardNew";
// import LunchOrderModule from "../../modules/lunchOrder";
import AbocloudModule from "../../modules/abocloud";
import LicenseModul from "../../modules/licenses";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../utils/constants";
import Notifications from "../Notifications";
import useNotificationsCount from "../../hooks/notifications/useNotificationsCount";
import Menue from "../Menu";
import DashboardV2 from "../../modules/dashboard_v2";
import DebugModule from "../../modules/debug";

const MainLayout = () => {
  const auth = useAuth();
  const location = useLocation();
  const classes = useStyles();
  const profile = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showMenue, setShowMenue] = useState<boolean>(false);
  const notificationsCount = useNotificationsCount();
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const [anchorElMenue, setAnchorElMenue] = React.useState<null | HTMLElement>(
    null
  );

  const { setLoading, loadingProps } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    console.debug("MainLayout::useEffect", auth);
    if (isLoaded(auth)) {
      if (auth.isAuthed) {
        console.debug("setLoading(false)");
        setLoading(false);
      } else {
        navigate("/login");
        setLoading(false);
      }
    } else {
      setLoading(true, "Authentication");
    }
  }, [auth?.isAuthed, auth?.isEmpty, auth?.isLoaded, setLoading]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(anchorElNotification ? null : event.currentTarget);
    setShowNotifications(anchorElNotification ? false : true);
  };

  const handleMenueClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenue(anchorElMenue ? null : event.currentTarget);
    setShowMenue(anchorElMenue ? false : true);
  };

  useEffect(() => {
    new ClipboardJS(".c2c")
      .on("success", (e) =>
        enqueueSnackbar("Copied to clipboard!", {
          variant: "success",
          preventDuplicate: true,
        })
      )
      .on("error", (e) =>
        enqueueSnackbar("Failed to copy clipboard!", { variant: "error" })
      );
  }, [enqueueSnackbar]);

  if (isLoaded(auth) && !auth.isAuthed && location.pathname !== "/login") {
    return <Navigate to={"/login"} replace />;
  }

  if (isLoaded(profile) && !isEmpty(profile) && !profile.roles.length) {
    return <div>You have no roles!</div>;
  }

  return (
    <LoadingWrapper {...loadingProps}>
      {/* <Routes>
        <Route path={"/dashboard/view"} element={<Intranet />} />
      </Routes> */}
      <div className={classes.root}>
        {auth.isAuthed && (
          <AppBar
            position={"absolute"}
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar style={{ backgroundColor: "#1a2c3b" }}>
              <IconButton
                onClick={handleDrawerOpen}
                edge={"start"}
                color={"inherit"}
                aria-label={"open drawer"}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                style={{
                  color: "#FFF",
                  fontSize: 24,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Webstollen Admin`}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <>
                  <IconButton
                    aria-label="Notifications"
                    color="inherit"
                    onClick={handleNotificationClick}
                  >
                    <Badge badgeContent={notificationsCount} color="error">
                      <FontAwesomeIcon
                        style={{ marginTop: 6, fontSize: 20 }}
                        color={"#FFF"}
                        icon={[ICON_STYLE, "bell"]}
                      />
                    </Badge>
                  </IconButton>
                  <Notifications
                    anchorEl={anchorElNotification}
                    visible={anchorElNotification !== null}
                    setVisible={setAnchorElNotification}
                  />
                </>
                <IconButton
                  onClick={handleMenueClick}
                  size="medium"
                  aria-controls={showMenue ? "admin-svc-main-menue" : undefined}
                  aria-haspopup="true"
                  aria-expanded={showMenue ? "true" : undefined}
                >
                  <Avatar
                    alt={`${auth.displayName ?? `User`}`}
                    src={`https://gravatar.com/avatar/${md5(
                      auth.email
                    )}?s=2048`}
                  />
                </IconButton>
                <Menue
                  anchorEl={anchorElMenue}
                  visible={anchorElMenue !== null}
                  setVisible={setAnchorElMenue}
                />
              </Box>
            </Toolbar>
          </AppBar>
        )}
        {auth.isAuthed && (
          <Drawer
            variant={"permanent"}
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Navigation roles={profile.roles ?? []} />
          </Drawer>
        )}

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Routes>
            <Route path={"/"} element={<DashboardV2 />} />
            <Route path={"/v1/*"} element={<DashboardModule />} />
            <Route path={"/v2/*"} element={<DashboardNew />} />
            <Route path={"/v3/*"} element={<DashboardV2 />} />

            {/* <Route path={"/plugins"} component={PluginsModule} /> */}

            {/* <Route path="/licences/*" element={<ShopsModule />} /> */}

            <Route path="/debug/*" element={<DebugModule />} />
            <Route path="/licenses/*" element={<LicenseModul />} />

            <Route path={"/users/*"} element={<UsersModule />} />

            <Route path={"/developer/*"} element={<DeveloperModule />} />

            <Route
              path={"/extensionstore/*"}
              element={<ExtensionstoreModule />}
            />

            <Route path={"/shorturl/*"} element={<ShorturlModule />} />

            <Route path={"/tools/*"} element={<ToolsModule />} />

            <Route path={"/login/*"} element={<Login />} />

            <Route path={"/dashbar/*"} element={<DashbarModule />} />
            <Route path={"/abocloud/*"} element={<AbocloudModule />} />

            {/* <Route path={"/lunch/*"} element={<LunchOrderModule />} /> */}

            <Route path={"/marketing/*"} element={<MarketingModule />} />

            <Route path={"/api/*"} element={<APIModule />} />
          </Routes>
        </main>
      </div>
    </LoadingWrapper>
  );
};

export default MainLayout;
