import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import dashbar from "../../../../firebase/dashbar";
import getDashBar from "../../../../firebase/dashbar";
import { ICON_STYLE } from "../../../../utils/constants";

const DomainChangeTab = () => {
  const steps = ["Firma wählen", "Shop wählen", "Prüfen", "Abschließen"];
  const stepButtons = [
    "Shop auswählen",
    "Prüfen",
    "Domain ändern",
    "Abschließen",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [cid, setCid] = React.useState<string>("");
  const [domains, setDomains] = React.useState<Array<string>>([]);
  const [updateDashboards, setUpdateDashboards] =
    React.useState<boolean>(false);
  const [oldShopDomain, setOldShopDomain] = React.useState<string>("");
  const [newShopDomain, setNewShopDomain] = React.useState<string>("");

  const [companyFound, setCompanyFound] = React.useState<boolean>(false);
  const [updatingDomain, setUpdatingDomain] = useState<boolean>(false);
  const [domainChangeSuccessful, setDomainChangeSuccessful] =
    useState<boolean>(false);
  const [changeReady, setChangeReady] = useState<boolean>(false);
  const [searchingCompany, setSearchingCompany] =
    React.useState<boolean>(false);

  const onCidChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCid(e.currentTarget.value);
  };

  const onOldShopDomainChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOldShopDomain(e.currentTarget.value);
  };
  const onNewShopDomainChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewShopDomain(e.currentTarget.value);
  };

  const onUpdateDashboardsChecked = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUpdateDashboards(e.target.checked);
  };

  const resetInputFields = () => {
    setCid("");
    setOldShopDomain("");
    setNewShopDomain("");
    setUpdateDashboards(false);
  };

  const updateDomain = () => {
    setUpdatingDomain(true);
    dashbar()
      .functions.httpsCallable("calls-helper-changeDomain")({
        companyId: cid,
        oldDomain: oldShopDomain,
        newDomain: newShopDomain,
        updateDashboards: updateDashboards,
      })
      .then(() => {
        setDomainChangeSuccessful(true);
        resetInputFields();
      })
      .catch((err) => {
        console.log(err);
        setDomainChangeSuccessful(false);
      })
      .finally(() => {
        setUpdatingDomain(false);
      });
  };

  const checkDataComplete = (): { complete: boolean; msg: string } => {
    if (!cid || cid === "") {
      return { complete: false, msg: `Company ID fehlt` };
    }
    if (!oldShopDomain || oldShopDomain === "") {
      return { complete: false, msg: `Alte Domain fehlt` };
    }
    if (!newShopDomain || newShopDomain === "") {
      return { complete: false, msg: `Neue Domain fehlt` };
    }
    return { complete: true, msg: `Daten vollständig` };
  };

  const searchCompany = () => {
    setSearchingCompany(true);
    getDashBar()
      .db.collection(`company`)
      .doc(cid || "-")
      .collection(`shop`)
      .get()
      .then((data) => {
        if (data.size > 0) {
          const domainArray: Array<string> = [];
          data.forEach((v) => {
            domainArray.push(v.id);
          });
          setDomains(domainArray);
          setOldShopDomain(domainArray[0]);
          setCompanyFound(true);
        } else {
          setCompanyFound(false);
        }
      })
      .finally(() => {
        setSearchingCompany(false);
      });
  };

  useEffect(() => {
    if (activeStep === 1) {
      searchCompany();
    } else if (activeStep === 0) {
      setCompanyFound(false);
    } else if (activeStep === 3) {
      updateDomain();
    }
  }, [activeStep]);

  useEffect(() => {
    if (!searchingCompany && companyFound && checkDataComplete().complete) {
      setChangeReady(true);
      console.log(changeReady);
    } else {
      setChangeReady(false);
    }
  }, [searchingCompany, companyFound, newShopDomain]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const BookingData = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <input
          key={`cid`}
          type={"text"}
          value={cid}
          onChange={onCidChanged}
          placeholder={"Company ID"}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
          }}
        />
      </div>
    );
  };

  const ShopSelection = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <select
          key="old_domain"
          value={oldShopDomain}
          onChange={onOldShopDomainChanged}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
            color: "black",
          }}
        >
          {domains.map((domain) => {
            return <option value={domain}>{domain}</option>;
          })}
        </select>
        <input
          key={`cid`}
          type={"text"}
          value={newShopDomain}
          onChange={onNewShopDomainChanged}
          placeholder={"Neue Domain"}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
          }}
        />
        <div style={{ width: "30%", marginBottom: 5, padding: 5 }}>
          <input
            checked={updateDashboards}
            onChange={onUpdateDashboardsChecked}
            type="checkbox"
          />
          <label> Dashboards aktualisieren</label>
        </div>
      </div>
    );
  };

  const StatusCard = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={3}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={checkDataComplete().complete ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  checkDataComplete().complete
                    ? "check-circle"
                    : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {checkDataComplete().msg}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={3}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={companyFound ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  companyFound ? "check-circle" : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {companyFound
                  ? `Firma existiert`
                  : `Firma wurde nicht gefunden`}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  };

  const UpdatedCard = () => {
    if (updatingDomain) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Card
            style={{
              width: "30%",
              borderRadius: 15,
              marginBottom: 10,
              padding: 10,
              backgroundColor: `#fff`,
            }}
          >
            <LinearProgress />
          </Card>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={3}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={domainChangeSuccessful ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  domainChangeSuccessful
                    ? "check-circle"
                    : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {domainChangeSuccessful
                  ? `Domain wurde erfolgreich geändert`
                  : `Fehler beim Ändern der Domain`}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  };

  return (
    <div>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <React.Fragment>
          {activeStep === 0 ? (
            <BookingData />
          ) : activeStep === 1 ? (
            <ShopSelection />
          ) : activeStep === 2 ? (
            <StatusCard />
          ) : (
            <UpdatedCard />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Zurück
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={activeStep === 3 ? handleReset : handleNext}
              disabled={activeStep === 2 && !changeReady}
            >
              {stepButtons[activeStep]}
            </Button>
          </Box>
        </React.Fragment>
      }
    </div>
  );
};

export default DomainChangeTab;
