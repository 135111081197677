import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { DB } from "../../../../../firebase";
import DashboardCard from "../../general/DashboardCard";

const MarketingBannerV2 = () => {
  const [currentCampaign, setCurrentCampaign] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    DB()
      .collection(`config`)
      .doc(`campaign`)
      .get()
      .then((data) => {
        const campaignData = data.data();
        if (campaignData?.tm_banner?.live !== undefined) {
          setCurrentCampaign(campaignData?.tm_banner?.live);
        } else {
          setCurrentCampaign(null);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardCard title="TM-Banner" theme="marketing" loading={loading}>
      <div
        style={{
          width: "100%",
          //   padding: 10,
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://ws-cdn.de/marketing/aktionsbanner/tm-image"
          style={{ width: "80%", borderRadius: 10 }}
        />
        {currentCampaign !== null && currentCampaign !== "default" ? (
          <Grid
            container
            spacing={2}
            style={{ flexGrow: 1, width: "100%" }}
            justifyContent={"flex-start"}
            alignItems={"stretch"}
            alignContent="stretch"
          >
            <Grid item xs={5}>
              <Typography
                style={{
                  fontSize: 14,
                  color: "#BBB",
                  marginTop: 10,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
              >
                {`Aktuelle Kampagne:`}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{
                  fontSize: 14,
                  color: "#FFF",
                  marginTop: 10,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {currentCampaign}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography
            style={{
              fontSize: 14,
              color: "#FFF",
              marginTop: 10,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {`Derzeit wurde keine Kampagne gebucht`}
          </Typography>
        )}
      </div>
    </DashboardCard>
  );
};

export default MarketingBannerV2;
