import { Grid, IconButton, Popper, Typography } from "@material-ui/core";
import React from "react";
import { DB } from "../../firebase";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useNotifications from "../../hooks/notifications/useNotifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../utils/constants";

const COLOR_UNREAD = `#FFF`;
const COLOR_READ = `#999`;

const Notifications = ({
  visible,
  setVisible,
  anchorEl,
}: {
  visible: boolean;
  setVisible: (value: React.SetStateAction<HTMLElement | null>) => void;
  anchorEl: HTMLElement | null;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  // const [notifications, setNotifications] = useState<
  //   Array<Record<string, any>>
  // >([]);

  // useEffect(() => {
  //   DB()
  //     .collection(`user/${auth.uid}/notifications`)
  //     // .where("read", "==", false)
  //     .orderBy("createTime", "desc")
  //     .limit(8)
  //     .get()
  //     .then((docs) => {
  //       const notificationArray: Array<Record<string, any>> = [];
  //       docs.forEach((doc) => {
  //         notificationArray.push({ ...doc.data(), id: doc.id });
  //       });
  //       setNotifications(notificationArray);
  //       setCount(notificationArray.length);
  //     });
  // }, [auth.uid]);
  const notifications = useNotifications();
  console.log(notifications);
  // useEffect(() => {
  //   DB()
  //     .collection(`user/${auth.uid}/notifications`)
  //     .where("read", "==", false)
  //     .orderBy("createTime", "desc")
  //     .get()
  //     .then((docs) => {
  //       setCount(docs.docs.length);
  //     });
  // }, [auth.uid]);

  const onNotificationClicked = (id: string, path: string | null) => {
    if (path !== undefined && path !== null) {
      navigate(path);
    }
    DB()
      .collection(`user/${auth.uid}/notifications`)
      .doc(id)
      .set({ read: true }, { merge: true });
    setVisible(null);
  };

  const deleteNotification = async (id: string) => {
    await DB().collection(`user/${auth.uid}/notifications`).doc(id).delete();
  };

  return (
    <Popper
      open={visible}
      placement="bottom-end"
      anchorEl={anchorEl}
      style={{
        backgroundColor: "#222",
        padding: 20,
        borderRadius: 10,
        minWidth: 300,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      {notifications.length <= 0 ? (
        <Typography
          style={{
            color: "#FFF",
            fontSize: 16,
            fontFamily: "Roboto",
            fontWeight: "300",
          }}
        >
          {`Keine Benachrichtigungen`}
        </Typography>
      ) : (
        notifications.map((notification, index) => {
          return (
            <>
              {index > 0 && (
                <div
                  style={{
                    height: 0.4,
                    width: 340,
                    marginTop: 10,
                    marginBottom: 10,
                    minWidth: 340,
                    // borderColor: "#CCC",
                    // borderWidth: 0.3,
                    backgroundColor: "#CCC",
                  }}
                />
              )}
              <div
                style={{
                  width: 350,
                  maxWidth: 350,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        onNotificationClicked(
                          notification.id,
                          notification.openPath ?? null
                        )
                      }
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              color:
                                notification.read === true
                                  ? COLOR_READ
                                  : COLOR_UNREAD,
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight:
                                notification.read === true ? "300" : "500",
                              marginBottom: 3,
                            }}
                          >
                            {`${notification.title}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              color:
                                notification.read === true
                                  ? COLOR_READ
                                  : COLOR_UNREAD,
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                              lineClamp: 2,
                            }}
                          >
                            {`${notification.message}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size={"small"}
                      title="reset"
                      onClick={() => deleteNotification(notification.id)}
                    >
                      <FontAwesomeIcon
                        icon={[ICON_STYLE, "trash-can"]}
                        color={"#FFF"}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </>
          );
        })
      )}
    </Popper>
  );
};

export default Notifications;
