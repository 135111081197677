import React, { useState } from "react";
import PageContainer from "../../../components/PageContainer";
import firebase from "firebase/compat/app";
import ReportConfigNewView from "./reportConfigNew";

const Reports = () => {
  const [extensionConfig, setExtensionConfig] = useState<
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined
  >();

  return (
    <>
      <PageContainer title={"Reports"}>
        <ReportConfigNewView />
      </PageContainer>
    </>
  );
};

export default Reports;
