import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getAbocloud from "../../../../../firebase/abocloud";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../utils/constants";
import {
  profileStatusColorFromId,
  profileStatusNameFromId,
} from "../../../helper/utils";

const ProfileTab = () => {
  const { mid } = useParams<{ mid: string }>();
  const [profiles, setProfiles] = useState<Array<Record<string, any>> | null>(
    null
  );

  useEffect(() => {
    if (!mid || mid === null) return;
    getAbocloud()
      .db.collection(`merchants/${mid}/keys`)
      .get()
      .then((profileDocs) => {
        const profileArray: Array<Record<string, any>> = [];
        profileDocs.forEach((profileDoc) => {
          profileArray.push({ ...profileDoc.data(), id: profileDoc.id });
        });
        setProfiles(profileArray);
        console.log(profileArray);
      });
  }, [mid]);

  if (profiles === null || profiles.length === 0) {
    return (
      <div
        style={{
          width: "100%",
          padding: 10,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Typography
          style={{
            color: "#111",
            fontSize: 14,
            fontFamily: "Roboto",
            fontWeight: "300",
          }}
        >
          {`Keine Profile angelegt`}
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {profiles?.map((profile) => {
        return (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              padding: 2,
              marginTop: 10,
              borderColor: profileStatusColorFromId(profile?.status || 2),
              borderRadius: 10,
              borderWidth: 3,
              borderStyle: "solid",
            }}
          >
            <Grid
              item
              xs={1}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <FontAwesomeIcon
                style={{
                  marginTop: 2,
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                  margin: 2,
                }}
                size="2x"
                color={"#111"}
                icon={[ICON_STYLE, "key"]}
              />
            </Grid>
            <Grid
              item
              xs={4}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  color: "#111",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {`${profile.name ?? `n/a`}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  color: "#111",
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {profile.url ?? `n/a`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  color: profileStatusColorFromId(profile.status),
                  fontSize: 14,
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                {profileStatusNameFromId(profile.status)}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default ProfileTab;
