const config = {
  apiKey: "AIzaSyCXbSEmRVVp174mtpB7UqI4MOsDARvZXTs",
  authDomain: "abo-cloud.firebaseapp.com",
  databaseURL: "http://localhost:4000/firestore",
  projectId: "abo-cloud",
  storageBucket: "abo-cloud.appspot.com",
  messagingSenderId: "1045365290488",
  appId: "1:1045365290488:web:cf9734bef9215c9e24920d",
  measurementId: "G-D0LZ2Z209C",
};

export default config;
