import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import firebase from "firebase/compat/app";
import dashbar from "../../../firebase/dashbar";
import { WhereT } from "../../../types";
import Table from "../../../components/Table";
import ReactTimeago from "react-timeago";
import CompanySelect from "../modal/CompanySelect";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HtmlTooltip from "../../../components/HtmlTooltip";
import IDLabel from "../../../components/IDLabel";
import { ICON_STYLE } from "../../../utils/constants";

const Audits = () => {
  const [audits, setAudits] = useState<
    | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    | undefined
  >();
  const [companySelect, setCompanySelect] = useState<
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined
  >();
  const [limit, setLimit] = useState(15);
  const [where, setWhere] = useState<WhereT>();
  const { enqueueSnackbar } = useSnackbar();

  const handleSearch = useCallback(
    (key: string, value: string) => {
      if (value) {
        setWhere([
          key === "id" ? firebase.firestore.FieldPath.documentId() : key,
          ">=",
          value,
        ]);
      } else {
        setWhere(undefined);
      }
    },
    [setWhere]
  );

  const handleCompanySelect = useCallback(
    (
        shopDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
      ) =>
      (id: string) =>
      async () => {
        if (id && shopDoc.exists) {
          if (!window.confirm(`Really move Shop to '${id}' ?`)) return;

          setCompanySelect(undefined);
          try {
            const shopData = shopDoc.data() as Record<string, any>;
            if (shopData.users) {
              delete shopData.users;
            }
            const newPath = `company/${id}/shop`;
            await dashbar()
              .db.collection(newPath)
              .doc(shopDoc.id)
              .set(shopData);
            await shopDoc.ref.delete();
          } catch (e) {
            enqueueSnackbar(`${e}`, { variant: "error" });
          }
        }
      },
    [setCompanySelect, enqueueSnackbar]
  );

  useEffect(() => {
    let query = dashbar()
      .db.collection("audit")
      .orderBy("createdAt", "desc")
      .limit(limit);
    if (where) {
      console.debug(where);
      query = query.where(...where);
    }
    return query.onSnapshot(setAudits);
  }, [setAudits, where, limit]);

  return (
    <PageContainer title={"Audits"} loading={audits === undefined}>
      <Table
        striped
        header={{
          domain: { content: "Type" },
          event: { content: "Event" },
          data: { content: "Data" },
          company: { content: "Company" },
          user: { content: "User" },
          createdAt: { content: "Erstellt" },
        }}
        onSearch={handleSearch}
        onMore={() => setLimit((prev) => prev + 15)}
      >
        {audits &&
          audits.docs.map((auditDoc) => {
            const audit = auditDoc.data() as Record<string, any>;
            return (
              <tr key={auditDoc.id}>
                <td style={{ textAlign: "center" }}>
                  {<TypeComponent type={audit?.type} />}
                </td>
                <td>{audit?.event ?? "-"}</td>
                <td>
                  <HtmlTooltip
                    title={<pre>{JSON.stringify(audit.data, null, 2)}</pre>}
                  >
                    <Button>
                      <FontAwesomeIcon icon={[ICON_STYLE, "question-circle"]} />
                    </Button>
                  </HtmlTooltip>
                </td>
                <td>
                  {audit?.company?.id ? (
                    <IDLabel
                      to={`/dashbar/companies/${audit?.company?.id}`}
                      label={
                        audit?.company?.name && audit?.company?.name !== "n/a"
                          ? audit?.company?.name
                          : audit?.company?.id
                      }
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td>{audit?.user?.email ?? "-"}</td>
                <td>
                  {audit?.createdAt && (
                    <ReactTimeago date={audit?.createdAt.toDate()} />
                  )}
                </td>
              </tr>
            );
          })}
      </Table>
      {companySelect && (
        <CompanySelect
          onCancel={() => setCompanySelect(undefined)}
          onSave={handleCompanySelect(companySelect)}
        />
      )}
    </PageContainer>
  );
};

export const TypeComponent = ({ type }: { type?: string }) => {
  switch (type) {
    case "INFO":
      return (
        <FontAwesomeIcon color="#5aaafa" icon={[ICON_STYLE, "info-circle"]} />
      );
    case "WARNING":
      return (
        <FontAwesomeIcon
          color="#ff9a1f"
          icon={[ICON_STYLE, "exclamation-circle"]}
        />
      );
    case "ERROR":
      return (
        <FontAwesomeIcon
          color="#d40000"
          icon={[ICON_STYLE, "exclamation-square"]}
        />
      );
    default:
      return (
        <FontAwesomeIcon color="#8c8c8c" icon={[ICON_STYLE, "info-circle"]} />
      );
  }
};

export default Audits;
