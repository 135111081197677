import React, { PropsWithChildren, ReactNode } from "react";
import { Fab, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add } from "@material-ui/icons";
import LoadingWrapper from "../LoadingWrapper";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ICON_STYLE } from "../../utils/constants";
import { COMPONENTS } from "../../constants/colors";

export type PageContainerProps = PropsWithChildren<{
  title: ReactNode;
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
  onClose?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onRefresh?: () => void;
  onAdd?: () => void;
  loading?: boolean;
  heading?: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string | undefined;
  bgColor?: string;
  customButton?: {
    title?: string;
    icon: IconProp;
    onClick?: () => void;
  };
}>;

const PageContainer = ({
  title,
  children,
  loading,
  headerLeft,
  headerRight,
  onAdd,
  onClose,
  onRefresh,
  onDelete,
  onEdit,
  className,
  customButton,
  bgColor = COMPONENTS.PAGECONTAINER.BACKGROUND,
}: PageContainerProps) => {
  // const Heading = `h${heading}` as keyof JSX.IntrinsicElements;

  return (
    <>
      <div className={className + " relative container mx-auto"}>
        <LoadingWrapper loading={loading ?? false}>
          {/* <div className={`${bgColor} rounded m-2 shadow`}> */}
          <div
            style={{
              backgroundColor: bgColor,
              borderRadius: 15,
              margin: 5,
            }}
          >
            <div className="p-2">
              {/* <div className="flex"> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                {headerLeft && <div>{headerLeft}</div>}
                {title && (
                  <Typography
                    style={{
                      color: "#FFF",
                      fontSize: 32,
                      fontFamily: "Roboto",
                      fontWeight: `700`,
                    }}
                  >
                    {title}
                  </Typography>
                )}
                {headerRight && <div>{headerRight}</div>}
                <div className="flex align-center items-center justify-center">
                  {customButton && (
                    <div
                      className="cursor-pointer hover:shadow px-2 py-1 bg-blue-900 hover:bg-blue-700 rounded mx-1"
                      onClick={customButton.onClick}
                    >
                      <FontAwesomeIcon
                        title={customButton.title}
                        fixedWidth
                        icon={customButton.icon}
                        size="lg"
                      />
                    </div>
                  )}
                  {onEdit && (
                    <div
                      className="cursor-pointer hover:shadow px-2 py-1 bg-blue-900 hover:bg-blue-700 rounded mx-1"
                      onClick={onEdit}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={[ICON_STYLE, "edit"]}
                        size="lg"
                      />
                    </div>
                  )}
                  {onDelete && (
                    <div
                      className="cursor-pointer hover:shadow px-2 py-1 bg-red-700 hover:red rounded mx-1"
                      onClick={onDelete}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={[ICON_STYLE, "trash"]}
                        size="lg"
                      />
                    </div>
                  )}
                  {onRefresh && (
                    <div
                      className="cursor-pointer hover:shadow px-2 py-1 bg-blue-900 hover:bg-blue-700 rounded mx-1"
                      onClick={onRefresh}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={[ICON_STYLE, "sync"]}
                        size="lg"
                      />
                    </div>
                  )}
                  {onClose && (
                    <div
                      className="cursor-pointer hover:shadow px-2 py-1 bg-red-900 hover:bg-red-700 rounded mx-1"
                      onClick={onClose}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={[ICON_STYLE, "times"]}
                        size="lg"
                      />
                    </div>
                  )}
                </div>
              </div>
              {children && children}
            </div>
          </div>
        </LoadingWrapper>
      </div>
      {onAdd && (
        <div className="absolute bottom-5 right-5">
          <Fab color="primary" onClick={onAdd}>
            <Add />
          </Fab>
        </div>
      )}
    </>
  );
};

export default PageContainer;
