import React, { MouseEventHandler, useEffect, useState } from "react";
import { ILicence } from "@dash.bar/types";
import {
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LicenceStatusChip } from "../../../../../components/Chips";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useCollection } from "../../../../../firestore/hooks";

type Props = {
  pluginId: string;
};

enum LicenceState {
  NEW,
  EXTENDED,
  EXPIRED,
}

enum LicenceType {
  FREE,
  TEST,
  PRODUCTIVE,
}

interface ICSV {
  plugin_id: string;
  mail: string;
  company: string;
  valid_until: string;
  domain: string;
}

const LicencesView = ({ pluginId }: Props) => {
  //const [shops, setShops] = useState<firebase.firestore.QuerySnapshot | null>(null);
  const [test, setTest] = useState(false);
  const [filter, setFilter] = useState<Array<any>>([
    [`plugins.${pluginId}.id`, "==", pluginId],
    [`plugins.${pluginId}.test`, "==", test],
  ]);
  const [typeFilter, setTypeFilter] = useState<Array<LicenceType> | null>(null);
  const [stateFilter, setStateFilter] = useState<Array<LicenceState> | null>(
    null
  );

  useEffect(() => {
    const newFilterArray: Array<any> = [];
    newFilterArray.push([`plugins.${pluginId}.id`, "==", pluginId]);
    newFilterArray.push([`plugins.${pluginId}.test`, "==", test]);

    if (typeFilter !== null) {
      newFilterArray.push([`plugins.${pluginId}.type`, "in", typeFilter]);
    }
    if (stateFilter !== null) {
      newFilterArray.push([`plugins.${pluginId}.state`, "in", stateFilter]);
    }
    //Update Filter
    setFilter(newFilterArray);
  }, [typeFilter, stateFilter, test]);

  const shops = useCollection<ILicence>(
    "licence",
    // [
    //   [`plugins.${pluginId}.test`, "==", test],
    //   [`plugins.${pluginId}.id`, "==", pluginId],
    // ],
    filter,
    undefined,
    1000
  );

  const [csv, setCSV] = useState<ICSV[]>([]);

  const csvHeaders = [
    {
      label: "Extension Interner Name",
      key: `plugin_id`,
    },
    {
      label: "E-Mail des Kunden",
      key: "mail",
    },
    {
      label: "Firmenname des Kunden",
      key: "company",
    },
    {
      label: "Subscription gültig bis",
      key: "valid_until",
    },
    {
      label: "Lizenz-Domain",
      key: "domain",
    },
  ];

  const generateCSV = (
    event: MouseEventHandler<HTMLAnchorElement>,
    done: (proceed?: boolean) => void
  ) => {
    if (shops && !shops.empty) {
      Promise.all(
        Object.keys(shops).map((id) => {
          const data = shops[id];
          if (
            data.plugins[pluginId] &&
            !data.plugins[pluginId].test &&
            moment(data.plugins[pluginId].validUntil.toDate()).isAfter(moment())
          ) {
            setCSV((x) => [
              ...x,
              {
                plugin_id: data.plugins[pluginId].id,
                mail: data.mail ?? "",
                company: "",
                valid_until: moment(
                  data.plugins[pluginId].validUntil.toDate()
                ).format("YYYY-MM-DD"),
                domain: data.domain,
              },
            ]);
          }
          return data;
        })
      ).then(() => done());
    } else {
      done(false);
    }
  };

  return (
    <div>
      <FormControlLabel
        label={"Show Test"}
        control={
          <Switch checked={test} onChange={() => setTest((prev) => !prev)} />
        }
      />
      {/* 
      Currently not working
      <Button
        disabled={!shops || !Object.keys(shops).length}
        component={CSVLink}
        data={csv}
        asyncOnClick={true}
        headers={csvHeaders}
        onClick={generateCSV}
        style={{ float: "right" }}
        variant={"contained"}
        color={"secondary"}
        filename={`lic_${pluginId}.csv`}
      >
        Download active
      </Button> */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>PluginID</TableCell> */}
              <TableCell>Mail</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Extended at</TableCell>
              <TableCell>Valid until</TableCell>
            </TableRow>
          </TableHead>

          {shops
            ? Object.keys(shops).map((id) => {
                const lic = shops[id];
                return lic.plugins[pluginId] ? (
                  <TableRow key={lic.domain}>
                    <TableCell>{lic.domain}</TableCell>
                    <TableCell>
                      <LicenceStatusChip plugin={lic.plugins[pluginId]} />
                    </TableCell>
                    {/* <TableCell>{lic.plugins[pluginId].id}</TableCell> */}
                    <TableCell>{lic.mail}</TableCell>
                    <TableCell>
                      {lic?.plugins[pluginId]?.createdAt
                        ? moment(
                            lic.plugins[pluginId].createdAt?.toDate()
                          ).format("DD.MM.YYYY")
                        : "n/a"}
                    </TableCell>
                    <TableCell>
                      {(lic?.plugins[pluginId] as Record<string, any>)
                        ?.extendedAt
                        ? moment(
                            (
                              lic?.plugins[pluginId] as Record<string, any>
                            )?.extendedAt?.toDate()
                          ).format("DD.MM.YYYY")
                        : `n/a`}
                    </TableCell>
                    <TableCell>
                      {lic?.plugins[pluginId]?.validUntil
                        ? moment(
                            lic.plugins[pluginId].validUntil.toDate()
                          ).format("DD.MM.YYYY")
                        : "n/a"}
                    </TableCell>
                  </TableRow>
                ) : null;
              })
            : null}
        </Table>
      </TableContainer>
    </div>
  );
};

export default LicencesView;
