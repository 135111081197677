import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getAbocloud from "../../../../../firebase/abocloud";
import { Grid, Typography } from "@material-ui/core";
import { getCountryNameFromId } from "../../../helper/sevdesk";
import { ICON_STYLE } from "../../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeAgo from "react-timeago";
import Badge from "../../../components/Badge";
import {
  merchantStatusColorFromId,
  merchantStatusNameFromId,
} from "../../../helper/utils";
import { ERROR, SUCCESS } from "../../../helper/colors";
import moment from "moment";

const HEADER_TEXT_SIZE = 18;
const DEFAULT_TEXT_SIZE = 16;
const ICON_TEXT_SIZE = 18;

const OverviewTab = () => {
  const { mid } = useParams<{ mid: string }>();
  const [merchant, setMerchant] = useState<Record<string, any> | null>(null);
  const [tariff, setTariff] = useState<Record<string, any> | null>(null);

  const unitMapper = (unit: string): string => {
    switch (unit) {
      case `year`:
        return `Jahren`;
      case `month`:
        return `Monaten`;
      case `week`:
        return `Wochen`;
      case `day`:
        return `Tagen`;
      case `hour`:
        return `Stunden`;
      case `minute`:
        return `Minuten`;
      case `second`:
      default:
        return `Sekunden`;
    }
  };

  useEffect(() => {
    if (!mid || mid === null) return;
    getAbocloud()
      .db.collection(`merchants`)
      .doc(mid)
      .get()
      .then((merchant) => {
        if (merchant.exists) {
          setMerchant({ ...merchant.data(), id: merchant.id });
        }
      });
  }, [mid]);

  useEffect(() => {
    if (merchant === null) return;
    if (merchant.tariff && merchant.tariff?.tariffRef) {
      (merchant.tariff.tariffRef as FirebaseFirestore.DocumentReference)
        .get()
        .then((tariff) => {
          if (tariff.exists) setTariff({ ...tariff.data() });
        });
    } else {
      getAbocloud()
        .db.collection(`tariff`)
        .doc(`default`)
        .get()
        .then((tariff) => {
          if (tariff.exists) setTariff({ ...tariff.data() });
        });
    }
  }, [merchant]);

  if (merchant === null) {
    return <p>Merchant-Detail</p>;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#eee",
          borderRadius: 10,
          padding: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignContent: "start",
            alignItems: "start",
            marginBottom: 20,
          }}
        >
          <Typography
            style={{
              color: "#111",
              fontSize: 28,
              fontFamily: "Roboto",
              fontWeight: "500",
            }}
          >
            {merchant?.company?.organisation ?? `Unbekannter Firmenname`}
          </Typography>
          <Typography
            style={{
              color: "#111",
              fontSize: 22,
              fontFamily: "Roboto",
              fontWeight: "300",
            }}
          >
            {merchant.id}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "start",
            alignItems: "start",
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                backgroundColor: "#111",
                padding: 8,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 10,
                marginTop: 4,
                marginBottom: 10,
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: HEADER_TEXT_SIZE,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Adresse`}
              </Typography>
            </div>
            <Grid container spacing={0} style={{ width: "80%" }}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {merchant.company?.organisation ?? `n/a`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`${merchant.company?.street ?? `n/a`} ${
                    merchant.company?.number ?? ``
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`${merchant.company?.zip ?? `n/a`} ${
                    merchant.company?.city ?? ``
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {getCountryNameFromId(merchant.company?.country ?? 1)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`UstID: ${merchant?.company?.vat ?? `n/a`}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                backgroundColor: "#111",
                padding: 8,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 10,
                marginTop: 4,
                marginBottom: 10,
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: HEADER_TEXT_SIZE,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Status`}
              </Typography>
            </div>
            <Grid container spacing={1} style={{ width: "80%" }}>
              <Grid item xs={8}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`Merchant-Status`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Badge
                  title={merchantStatusNameFromId(merchant.status)}
                  color={merchantStatusColorFromId(merchant.status)}
                  solid
                  fontWhite
                />
              </Grid>
              <Grid item xs={8}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`Initialisiert`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      fontSize: ICON_TEXT_SIZE,
                      textAlign: "center",
                      verticalAlign: "middle",
                      marginTop: 3,
                    }}
                    color={merchant.initialized === true ? SUCCESS : ERROR}
                    icon={[
                      ICON_STYLE,
                      merchant.initialized === true
                        ? "check-circle"
                        : "xmark-circle",
                    ]}
                  />
                </div>
              </Grid>
              {merchant?.stats?.total_sales_volume?.value && (
                <>
                  <Grid item xs={8}>
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >
                      {`Gesamtumsatz`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} direction="row" justifyContent="flex-end">
                    <Typography
                      style={{
                        color: "#111",
                        fontSize: DEFAULT_TEXT_SIZE,
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        width: "100%",
                        textAlign: "end",
                      }}
                    >
                      {`${merchant.stats.total_sales_volume.value} €`}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={8}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`Zuletzt aktiv`}
                </Typography>
              </Grid>
              <Grid item xs={4} direction="row" justifyContent="flex-end">
                {merchant.lastActivity ? (
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      width: "100%",
                      textAlign: "end",
                    }}
                  >
                    <TimeAgo
                      formatter={(value, unit, suffix) => {
                        return `vor ${value} ${unitMapper(unit)}`;
                      }}
                      date={merchant.lastActivity.toDate()}
                    />
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    {`keine Aktivität`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "start",
            alignItems: "start",
            marginTop: 20,
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                backgroundColor: "#111",
                padding: 8,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 10,
                marginTop: 4,
                marginBottom: 10,
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: HEADER_TEXT_SIZE,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Ansprechpartner`}
              </Typography>
            </div>
            <Grid container spacing={0} style={{ width: "80%" }}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {merchant.company?.organisation ?? `n/a`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`${merchant?.company?.firstname ?? `n/a`} ${
                    merchant?.company?.lastname ?? ``
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#111",
                    fontSize: DEFAULT_TEXT_SIZE,
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  {`${merchant?.company?.email ?? ``}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                backgroundColor: "#111",
                padding: 8,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 10,
                marginTop: 4,
                marginBottom: 10,
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#EEE",
                  fontSize: HEADER_TEXT_SIZE,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {`Tarif`}
              </Typography>
            </div>
            {tariff !== null && (
              <Grid container spacing={0} style={{ width: "80%" }}>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: DEFAULT_TEXT_SIZE,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {`Tarif`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      backgroundColor: tariff.color ?? `#000`,
                      padding: 6,
                      paddingTop: 2,
                      paddingBottom: 2,
                      borderRadius: 10,
                      marginTop: 2,
                      marginBottom: 2,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#EEE",
                        fontSize: 14,
                        fontFamily: "Roboto",
                        fontWeight: "300",
                      }}
                    >
                      {tariff?.name?.toLowerCase()}
                    </Typography>
                  </div>
                </Grid>
                {tariff.addons && (
                  <>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`Abonnements`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} direction="row" justifyContent="flex-end">
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {tariff?.addons[`general.abo`] === -1
                          ? `unbegrenzt`
                          : `${tariff?.addons[`general.abo`]}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`Kunden`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} direction="row" justifyContent="flex-end">
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {tariff?.addons[`general.customer`] === -1
                          ? `unbegrenzt`
                          : `${tariff?.addons[`general.customer`]}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`Transaktionen`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} direction="row" justifyContent="flex-end">
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {tariff?.addons[`general.transaction`] === -1
                          ? `unbegrenzt`
                          : `${tariff?.addons[`general.transaction`]}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`Profile`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} direction="row" justifyContent="flex-end">
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {tariff?.addons[`general.profile`] === -1
                          ? `unbegrenzt`
                          : `${tariff?.addons[`general.profile`]}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`Nutzer`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} direction="row" justifyContent="flex-end">
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {tariff?.addons[`general.user`] === -1
                          ? `unbegrenzt`
                          : `${tariff?.addons[`general.user`]}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {`Gültig bis`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} direction="row" justifyContent="flex-end">
                      <Typography
                        style={{
                          color: "#111",
                          fontSize: DEFAULT_TEXT_SIZE,
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {`${
                          merchant?.tariff?.validUntil
                            ? moment(
                                merchant?.tariff?.validUntil?.toDate()
                              ).format("DD.MM.YYYY")
                            : `-`
                        }`}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
