import {Reducer, useReducer} from 'react'

import AuthError from '../../utils/AuthError'

export enum Types {
    START_LOGIN = 'login-start',
    FINISH_LOGIN = 'login-finish',
    ERROR_LOGIN = 'login-error',

    START_SIGN_UP = 'signup-start',
    FINISH_SIGN_UP = 'signup-finish',
    ERROR_SIGN_UP = 'signup-error',

    START_RESET = 'reset-start',
    FINISH_RESET = 'reset-finish',
    ERROR_RESET = 'reset-error',
}

const useLoginReducer = () => {
    return useReducer<Reducer<Readonly<{
        loading: boolean
        alert?: { content: string } | null
    }>,
        { type: Types; payload?: { error?: AuthError } }>>(
        (
            state,
            {type, payload}
        ): Readonly<{
            loading: boolean
            alert?: { content: string } | null
        }> => {
            switch (type) {
                case Types.START_LOGIN:
                case Types.START_SIGN_UP:
                case Types.START_RESET:
                    return {...state, loading: true, alert: null}
                case Types.FINISH_LOGIN:
                case Types.FINISH_SIGN_UP:
                case Types.FINISH_RESET:
                    return {...state, loading: false, alert: null}
                case Types.ERROR_LOGIN:
                case Types.ERROR_SIGN_UP:
                case Types.ERROR_RESET: {
                    if (!payload || payload.error == null) {
                        throw new Error()
                    }
                    const {error} = payload
                    return {
                        ...state,
                        loading: false,
                        alert: {
                            content: error.message,
                        },
                    }
                }
                default:
                    throw new Error(`action type '${type}' not defined`)
            }
        },
        {loading: false, alert: null}
    )
}

export default useLoginReducer
