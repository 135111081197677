import { useEffect, useState } from "react";
import dashbar from "../../../../../firebase/dashbar";
import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import CompanyInfo from "../CompanyInfo";
import AbocloudInfo from "../AbocloudInfo";
import TarifInfo from "../TarifInfo";
import ShopInfo from "../ShopInfo";
import UserInfo from "../UserInfo";
import DiagnoseInfo from "../DiagnoseInfo";
import { ERROR } from "../../../../../constants/colors";
import DeletionInfo from "../DeletionInfo";
import FirstpromoterInfo from "../FirstpromoterInfo";

const CompanyView = ({ cid }: { cid: string }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Array<Record<string, any>> | null>(null);
  const [company, setCompany] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    dashbar()
      .db.collection(`company`)
      .doc(cid)
      .get()
      .then((companyDoc) => {
        if (companyDoc.exists) {
          setCompany({ ...companyDoc.data(), id: companyDoc.id });
        } else {
          setCompany(null);
        }
      })
      .catch(() => {
        setCompany(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = company?.users ?? [];
      const userArray: Array<Record<string, any>> = [];
      for (const user of users) {
        const userDoc = await dashbar().db.collection(`user`).doc(user).get();
        if (userDoc.exists) {
          const isOwner = company?.owner === user;
          const isAdmin = company?.admin?.includes(user);
          const userSessions = await dashbar()
            .db.collection(`user/${user}/sessions`)
            .get();
          if (userSessions.size > 0) {
            const sessions: Array<Record<string, any>> = [];
            userSessions.forEach((session) => {
              sessions.push({ ...session.data(), id: session.id });
            });
            userArray.push({
              ...userDoc.data(),
              isAdmin: isAdmin,
              isOwner: isOwner,
              sessions: sessions,
              id: userDoc.id,
            });
          } else {
            userArray.push({
              ...userDoc.data(),
              isAdmin: isAdmin,
              isOwner: isOwner,
              id: userDoc.id,
            });
          }
        }
      }
      setUser(userArray);
    };
    if (company !== null) {
      fetchUsers();
    }
  }, [company]);

  if (company === null) {
    return (
      <Typography
        style={{
          color: "#EEE",
          fontSize: 16,
          fontFamily: "Roboto",
          fontWeight: "300",
        }}
      >
        {`Die Firma mit der ID ${cid} konnte nicht gefunden werden!`}
      </Typography>
    );
  }

  return (
    <Grid container spacing={2} alignContent="stretch" alignItems="stretch">
      {company.noShopSince && company.noShopSince !== null && (
        <Grid item xs={12}>
          <Paper style={{ padding: 20, borderRadius: 15 }}>
            <Typography
              style={{
                color: ERROR.DARK,
                fontSize: 22,
                fontFamily: "Roboto",
                fontWeight: "700",
                marginBottom: 10,
              }}
            >
              {`Löschvorgang`}
            </Typography>
            <DeletionInfo company={company} />
          </Paper>
        </Grid>
      )}
      <Grid item xs={6}>
        <Paper style={{ padding: 20, borderRadius: 15 }}>
          <Typography
            style={{
              color: "#AAA",
              fontSize: 22,
              fontFamily: "Roboto",
              fontWeight: "700",
              marginBottom: 10,
            }}
          >
            {`Allgemein`}
          </Typography>
          <CompanyInfo company={company} />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} alignContent="stretch" alignItems="stretch">
          <Grid item xs={12}>
            <Paper style={{ padding: 20, borderRadius: 15 }}>
              <Typography
                style={{
                  color: "#AAA",
                  fontSize: 22,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  marginBottom: 10,
                }}
              >
                {`abocloud`}
              </Typography>
              <AbocloudInfo company={company} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: 20, borderRadius: 15 }}>
              <Typography
                style={{
                  color: "#AAA",
                  fontSize: 22,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  marginBottom: 10,
                }}
              >
                {`Firstpromoter`}
              </Typography>
              <FirstpromoterInfo company={company} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 20, borderRadius: 15 }}>
          <Typography
            style={{
              color: "#AAA",
              fontSize: 22,
              fontFamily: "Roboto",
              fontWeight: "700",
              marginBottom: 10,
            }}
          >
            {`Tarif`}
          </Typography>
          <TarifInfo company={company} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 20, borderRadius: 15 }}>
          <Typography
            style={{
              color: "#AAA",
              fontSize: 22,
              fontFamily: "Roboto",
              fontWeight: "700",
              marginBottom: 10,
            }}
          >
            {`Diagnose`}
          </Typography>
          <DiagnoseInfo company={company} users={user} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 20, borderRadius: 15 }}>
          <Typography
            style={{
              color: "#AAA",
              fontSize: 22,
              fontFamily: "Roboto",
              fontWeight: "700",
              marginBottom: 10,
            }}
          >
            {`Shops`}
          </Typography>
          <ShopInfo company={company} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 20, borderRadius: 15 }}>
          <Typography
            style={{
              color: "#AAA",
              fontSize: 22,
              fontFamily: "Roboto",
              fontWeight: "700",
              marginBottom: 10,
            }}
          >
            {`Nutzer`}
          </Typography>
          <UserInfo users={user} company={company} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CompanyView;
