import {Company} from "@dash.bar/types";
import React from "react";

type BillingAddressProps = {
  company?: Company
}

const BillingAddress = ({company}: BillingAddressProps) => {
  return <dl className={'horizontal center'}>
    <dt>Company:</dt>
    <dd>{company?.name}</dd>
    <dt>Name:</dt>
    <dd>{company?.billingAddress?.firstName} {company?.billingAddress?.lastName}</dd>
    <dt>Address1:</dt>
    <dd>{company?.billingAddress?.address1}</dd>
    {company?.billingAddress?.address2 && <>
        <dt>Address2:</dt>
        <dd>{company.billingAddress.address2}</dd>
    </>}
    <dt>City:</dt>
    <dd>{company?.billingAddress?.zipCode} {company?.billingAddress?.city}</dd>
    <dt>Country:</dt>
    <dd>{company?.billingAddress?.country}</dd>
  </dl>
}

export default BillingAddress