import * as yup from "yup";

export type SignUpDataType = {
  uid?: string;
  email?: string;
  ref_id: string;
};

export type SaleDataType = {
  uid?: string;
  email?: string;
  ref_id: string;
  event_id: string;
  amount: number;
  quantity?: number;
  plan?: string;
  currency?: string;
};

export type FormInputElement<T> = {
  label: string;
  key: keyof T;
  type: string;
  default?: any;
  info?: string;
};

export type FormElements<T extends Record<string, any>> = {
  [k in keyof T]: FormInputElement<T>;
};

export const signupSchema = yup.object().shape({
  email: yup.string().email().notRequired(),
  uid: yup.string().required(),
  ref_id: yup.string().required(),
});

export const saleSchema = yup.object().shape({
  email: yup.string().email().notRequired(),
  uid: yup.string().required(),
  ref_id: yup.string().required(),
  event_id: yup.string().required(),
  amount: yup.number().required().min(1),
  quantity: yup.number().min(1).notRequired(),
  plan: yup.string().notRequired(),
  promo_code: yup.string().notRequired(),
  currency: yup.string().notRequired().default("EUR"),
});

export const signupElements: FormElements<SignUpDataType> = {
  // email: {
  //   key: "email",
  //   label: "E-Mail",
  //   type: "email",
  // },
  uid: {
    key: "uid",
    label: "UserID",
    type: "string",
    info: "Die UID sollte die folgende Form aufweisen:\nCustomerID_Kampagnenname_Email_RefId",
  },
  ref_id: {
    key: "ref_id",
    type: "text",
    label: "Ref ID",
  },
};
export const saleElements: FormElements<SaleDataType> = {
  // email: {
  //   key: "email",
  //   label: "E-Mail",
  //   type: "email",
  // },
  uid: {
    key: "uid",
    label: "UserID",
    type: "string",
    info: "Die UID sollte die folgende Form aufweisen:\nCustomerID_Kampagnenname_Email_RefId",
  },
  ref_id: {
    key: "ref_id",
    type: "text",
    label: "Ref ID",
  },
  amount: {
    key: "amount",
    type: "number",
    label: "Amount (in Cent)",
  },
  currency: {
    key: "currency",
    type: "text",
    label: "Currency",
    default: "EUR",
  },
  event_id: {
    key: "event_id",
    type: "text",
    label: "Event ID",
  },
  plan: {
    key: "plan",
    type: "text",
    label: "Plan",
  },
  quantity: {
    key: "quantity",
    type: "number",
    label: "Quantity",
  },
};
