import React, {PropsWithChildren} from "react";

const Modal = ({children}: PropsWithChildren<{}>) => {
  return <div className='absolute top-0 left-0 right-0 bottom-0 backdrop-filter backdrop-blur'>
    <div
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded shadow bg-gray-700 p-4 m-10'>
      {children}
    </div>
  </div>
}

export default Modal;