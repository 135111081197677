import React, { useEffect, useState } from "react";
import { UserRole } from "../../firestore/models";
import withRole from "../../hoc/withRole";
import { useSnackbar } from "notistack";
import getAbocloud from "../../firebase/abocloud";
import PageContainer from "../../components/PageContainer";
import { Button, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import firebase from "firebase/compat/app";
import { Route, Routes } from "react-router-dom";
import Merchants from "./merchants";
import Merchant from "./merchant";

const AbocloudModule = () => {
  const [user, setUser] = useState<firebase.User | undefined | null>();
  const [access, setAccess] = useState<boolean | undefined>();
  const { enqueueSnackbar } = useSnackbar();

  const [credentials, setCredentials] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user) {
      user
        .getIdTokenResult(true)
        .then((token) => setAccess(!!token.claims.admin))
        .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
    }
  }, [user, enqueueSnackbar]);

  useEffect(() => {
    getAbocloud().auth.onAuthStateChanged((user) => setUser(user));
  }, []);

  const handleLogin = () => {
    getAbocloud()
      .auth.signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((c) => setCredentials((prev) => ({ ...prev, password: "" })))
      .catch((e) => enqueueSnackbar(`${e}`, { variant: "error" }));
  };

  if (!user) {
    return (
      <>
        <PageContainer title="Login to abocloud" loading={user === undefined}>
          <div className="flex justify-center content-around">
            <TextField
              name={"email"}
              placeholder={"E-Mail"}
              type={"email"}
              value={credentials.email}
              onChange={(e) =>
                setCredentials((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <TextField
              name={"password"}
              placeholder={"Password"}
              type={"password"}
              value={credentials.password}
              onChange={(e) =>
                setCredentials((prev) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
            />
          </div>
          <div className="my-2 text-center">
            <Button onClick={handleLogin} variant="contained" color="primary">
              login
            </Button>
          </div>
        </PageContainer>
      </>
    );
  }

  if (!access) {
    return (
      <>
        <PageContainer title={"Access denied!"} loading={access === undefined}>
          <Alert severity="error">
            This User is not allowed to administrate dash.bar!
          </Alert>
          <div className="my-2 text-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => getAbocloud().auth.signOut()}
            >
              Logout
            </Button>
          </div>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Button
        className="float-right"
        variant="contained"
        color="primary"
        onClick={() => getAbocloud().auth.signOut()}
      >
        Logout
      </Button>
      {/* <Outlet /> */}
      <Routes>
        <Route path={`merchant/:mid`} element={<Merchant />} />
        <Route path={`merchant`} element={<Merchants />} />
      </Routes>
    </>
  );
};

export default withRole(UserRole.Abocloud)(AbocloudModule);
