import firebase from "firebase/compat/app";
import { firestore } from "firebase-admin";
import moment from "moment";
import { DB } from "../../../firebase";
import Dinero from "dinero.js";

export const deleteAdspaceImage = async (
  path: string | undefined
): Promise<boolean> => {
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");
  if (!path) {
    return false;
  }
  try {
    const ref = storage.ref(path);
    await ref.delete();
  } catch (e: any) {
    console.log(e.code);
    switch (e.code) {
      case "storage/object-not-found":
      case "storage/bucket-not-found":
        return true;
      default:
        return false;
    }
  }
  return true;
};

export const deleteAdspace = async (adspaceId: string): Promise<boolean> => {
  if (!adspaceId) return false;
  await DB().collection("adspace").doc(adspaceId).delete();
  return true;
};

export const deleteCampaign = async (campaignId: string): Promise<boolean> => {
  if (!campaignId) return false;

  const bookedAdspaceList: Array<{
    filePath: string | null;
    ref: firebase.firestore.DocumentReference;
  }> = [];
  const bookedAdspaces = await DB()
    .collectionGroup("booking")
    .where("campaignId", "==", campaignId)
    .get();
  bookedAdspaces.forEach((adspace) => {
    bookedAdspaceList.push({
      ref: adspace.ref,
      filePath: adspace.data().filePath || null,
    });
  });

  //Delete all files from Storage and Adspace-Bookings first
  for (const adspace of bookedAdspaceList) {
    if (adspace.filePath !== null) {
      await deleteAdspaceImage(adspace.filePath);
    }
    await adspace.ref.delete();
  }

  //Finally delete Campaign
  await DB().collection("campaigns").doc(campaignId).delete();

  return true;
};

export const verifyAdspaceBooking = async (
  adspaceId: string,
  startDate: Date,
  endDate: Date,
  verifiesCampaign: boolean = false,
  bookingId?: string
): Promise<{ error: boolean; message: string }> => {
  const bookings = await DB()
    .collection(`adspace/${adspaceId}/booking`)
    .where("endDate", ">=", startDate)
    .get();
  console.log(bookings.size);
  let error: { error: boolean; message: string } = {
    error: false,
    message: "",
  };

  bookings.forEach((booking) => {
    if (bookingId && bookingId === booking.id) {
      return;
    }
    const bookingStartDate = booking.data()?.startDate.toDate();
    const bookingEndDate = booking.data()?.endDate.toDate();
    const bookingCampaign = booking.data()?.campaignName || "n/a";
    const adspaceName = booking.data()?.adspaceName || "n/a";
    const active = booking.data().active;
    if (bookingStartDate <= endDate && active === true) {
      error = {
        error: true,
        message: verifiesCampaign
          ? `Eine Aktivierung der Kampagne ist nicht möglich! Die Kampagne beeinhaltet eine Buchung für den Werbeplatz ${adspaceName} welche sich mit einer Buchung für die Kampagne ${bookingCampaign} gebucht vom ${moment(
              bookingStartDate
            ).format("DD.MM.YYYY")} bis ${moment(bookingEndDate).format(
              "DD.MM.YYYY"
            )} überschneidet.`
          : `Eine Buchung zum angegebenen Zeitraum ist nicht möglich! Der Zeitraum überschneidet sich mit der Kampagne ${bookingCampaign}; gebucht vom ${moment(
              bookingStartDate
            ).format("DD.MM.YYYY")} bis ${moment(bookingEndDate).format(
              "DD.MM.YYYY"
            )} `,
      };
    }
  });
  return error;
};

export const verifyAdspaceCampaignBooking = async (
  campaignId: string,
  startDate: Date,
  endDate: Date
): Promise<{ error: boolean; message: string }> => {
  let error: { error: boolean; message: string } = {
    error: false,
    message: "",
  };
  const campaign = await DB().collection(`campaigns`).doc(campaignId).get();
  const campaignStartDate = campaign.data()?.startDate.toDate();
  const campaignEndDate = campaign.data()?.endDate.toDate();
  if (campaignStartDate > startDate || campaignEndDate < endDate) {
    error = {
      error: true,
      message: `Eine Buchung zum angegebenen Zeitraum ist nicht möglich! Der Zeitraum liegt nicht innerhalb des Zeitraums der zugehörigen Kampagne`,
    };
  }
  return error;
};

export const canBeActivated = async (
  campaignId: string
): Promise<{ error: boolean; message: string }> => {
  const bookingList: Array<{
    startDate: Date;
    endDate: Date;
    adspace: string;
    bookingId: string;
  }> = [];
  const bookings = await DB()
    .collectionGroup("booking")
    .where("campaignId", "==", campaignId)
    .get();
  bookings.forEach((booking) => {
    bookingList.push({
      startDate: booking.data()?.startDate.toDate(),
      endDate: booking.data()?.endDate.toDate(),
      adspace: booking.data()?.adspaceId,
      bookingId: booking.id,
    });
  });

  let errorObj = { error: false, message: "" };

  for (const booking of bookingList) {
    const verify = await verifyAdspaceBooking(
      booking.adspace,
      booking.startDate,
      booking.endDate,
      true,
      booking.bookingId
    );
    if (verify.error) {
      errorObj = verify;
    }
  }
  return errorObj;
};

export const setAdspaceBookingMode = async (
  campaignId: string,
  active: boolean
): Promise<boolean> => {
  const bookingList: Array<{
    ref: firestore.DocumentReference<firebase.firestore.DocumentData> & any;
  }> = [];
  const bookings = await DB()
    .collectionGroup("booking")
    .where("campaignId", "==", campaignId)
    .get();

  bookings.forEach((booking) => {
    bookingList.push({ ref: booking.ref });
  });

  for (const booking of bookingList) {
    booking.ref.set({ active: active }, { merge: true });
  }
  return true;
};

export const calculateBudget = async (campaignId: string): Promise<string> => {
  let amount: number = 0;
  const bookingList: Array<{
    adspaceId: string;
    startDate: Date;
    endDate: Date;
  }> = [];
  const adspaceList: Array<{ id: string; value: number }> = [];
  const adspaces = await DB().collection("adspace").get();
  adspaces.forEach((adspace) => {
    adspaceList.push({
      id: adspace.id,
      value: +adspace.data()?.value?.value.replace(".", ""),
    });
  });
  const adspaceValueMap = adspaceList.reduce<Record<string, number>>(
    (prev, curr) => {
      return { ...prev, [curr.id]: curr.value };
    },
    {}
  );
  const bookings = await DB()
    .collectionGroup("booking")
    .where("campaignId", "==", campaignId)
    .get();
  bookings.forEach((booking) => {
    bookingList.push({
      adspaceId: booking.data().adspaceId,
      startDate: booking.data()?.startDate.toDate(),
      endDate: booking.data()?.endDate.toDate(),
    });
  });

  for (const booking of bookingList) {
    const adspaceValuePerDay = Dinero({
      amount: adspaceValueMap[booking.adspaceId] || 0,
    }).divide(7, "HALF_UP");
    const firstDay = moment(booking.startDate);
    const lastDay = moment(booking.endDate);
    const totalDays = Math.abs(firstDay.diff(lastDay, "days")) + 1;
    const totalAmount = adspaceValuePerDay.multiply(totalDays);
    amount = amount + totalAmount.getAmount();
  }

  return Dinero({ amount: amount }).toFormat("0,0.00");
};

export const calculateClicks = async (
  campaignId: string
): Promise<number | null> => {
  let clicks: number = 0;
  const bookingList: Array<{
    startDate: Date;
    endDate: Date;
  }> = [];
  const bookings = await DB()
    .collection(`adspace/tm_banner/booking`)
    .where("campaignId", "==", campaignId)
    .get();
  if (bookings.size === 0) {
    return null;
  }
  bookings.forEach((booking) => {
    bookingList.push({
      startDate: booking.data()?.startDate.toDate(),
      endDate: booking.data()?.endDate.toDate(),
    });
  });
  for (const booking of bookingList) {
    const clickList: Array<{ clicked: Date }> = [];
    const clickLogs = await DB()
      .collection(`shorturl/tm/sllog`)
      .where("clickTime", ">=", booking.startDate)
      .get();
    clickLogs.forEach((clickLog) => {
      clickList.push({ clicked: clickLog.data().clickTime.toDate() });
    });
    const totalClicks = clickList.filter((v) => {
      return v.clicked <= booking.endDate;
    }).length;
    clicks = clicks + totalClicks;
  }
  return clicks;
};

export const campaignIdIsUnique = async (id: string): Promise<boolean> => {
  const currentIds: Array<string> = [];
  const campaigns = await DB().collection(`campaigns`).get();
  campaigns.forEach((campaign) => {
    currentIds.push(campaign.id);
  });
  return !currentIds.includes(id);
};

export const adspaceIdIsUnique = async (id: string): Promise<boolean> => {
  const currentIds: Array<string> = [];
  const adspaces = await DB().collection(`adspace`).get();
  adspaces.forEach((adspace) => {
    currentIds.push(adspace.id);
  });
  return !currentIds.includes(id);
};

export const isAmount = (value: string): boolean => {
  const regex = /^\d*.\d\d$/gm;
  return regex.test(value);
};

export const startBeforeEndDate = (startDate: Date, endDate: Date) => {
  return startDate <= endDate;
};
