import React from "react";

const getLabelText = (status: number): string => {
  switch (status) {
    case 1:
      return "active";
    case 2:
      return "canceled";
    default:
      return "pending";
  }
};

const BookingStatusLabel = ({ label }: { label: 0 | 1 | 2 }) => {
  let color;
  switch (label) {
    case 1:
      color = "bg-green-400";
      break;
    case 2:
      color = "bg-red-400";
      break;
    case 0:
      color = "bg-yellow-400";
      break;
    default:
      color = "bg-gray-400";
      break;
  }
  return (
    <div className={`px-2 py-1 inline rounded shadow ${color}`}>
      {getLabelText(+label)}
    </div>
  );
};

export default BookingStatusLabel;
