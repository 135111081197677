import "firebase/firestore";
import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer, getFirebase } from "react-redux-firebase";
import { withExtraArgument } from "redux-thunk";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

// let composeEnhancers = compose;

// if (process.env.NODE_ENV !== "production") {
//   const { composeWithDevTools } = require("redux-devtools-extension");
//   composeEnhancers = composeWithDevTools({
//     trace: true,
//     traceLimit: 25,
//   });
// }

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      withExtraArgument(getFirebase)
    ),
  devTools:
    process.env.NODE_ENV !== "production"
      ? {
          trace: true,
          traceLimit: 25,
        }
      : false,
});

export default store;
