import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import PageContainer from "../../../../components/PageContainer";
import getDashBar from "../../../../firebase/dashbar";
import firebase from "firebase/compat/app";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

type FlatrateProps = {
  id: string;
  data: Record<string, any>;
};

const DashbarCampaignView = ({ id }: FlatrateProps) => {
  const [signUps, setSignUps] = useState<
    Array<firebase.firestore.QueryDocumentSnapshot>
  >([]);

  useEffect(() => {
    getDashBar()
      .db.collection(`campaigns/${id}/signups`)
      .onSnapshot((snap) => {
        setSignUps(snap.docs ?? []);
      });
  }, [setSignUps, id]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  //   const filteredData = signUps
  //     ? signUps
  //         ?.map((signup) => {
  //           const signupData = signup.data();
  //           return {
  //             id: id,
  //             date: signupData.signUpDate.toDate(),
  //             source: signupData.campaignSource,
  //           };
  //         })
  //         .reduce<Record<string, any>>((prev, curr) => {
  //           if (prev?.[curr.source]) {
  //             return { ...prev, [curr.source]: prev[curr.source] + 1 };
  //           } else {
  //             return { ...prev, [curr.source]: 1 };
  //           }
  //         }, {})
  //     : {};
  const filteredDataDate = signUps
    ? signUps
        ?.map((signup) => {
          const signupData = signup.data();
          return {
            id: id,
            date: signupData.signUpDate.toDate(),
            source: signupData.campaignSource,
          };
        })
        .sort((a, b) => {
          if (a.date > b.date) return 1;
          else if (a.date < b.date) return -1;
          return 0;
        })
        .reduce<Record<string, any>>((prev, curr) => {
          if (prev?.[moment(curr.date).format("DD.MM.YY")]) {
            return {
              ...prev,
              [moment(curr.date).format("DD.MM.YY")]:
                prev[moment(curr.date).format("DD.MM.YY")] + 1,
            };
          } else {
            return { ...prev, [moment(curr.date).format("DD.MM.YY")]: 1 };
          }
        }, {})
    : {};
  //   const filteredData = signUps
  //     ? signUps
  //         ?.map((signup) => {
  //           const signupData = signup.data();
  //           return {
  //             id: id,
  //             date: signupData.signUpDate.toDate(),
  //             source: signupData.campaignSource,
  //           };
  //         })
  //         .reduce<Record<string, Record<string, any>>>((prev, curr) => {
  //           if (prev?.[moment(curr.date).format("DD.MM.YY")]) {
  //             if (prev?.[moment(curr.date).format("DD.MM.YY")][curr.source]) {
  //               return {
  //                 ...prev,
  //                 [moment(curr.date).format("DD.MM.YY")]: {
  //                   ...prev?.[moment(curr.date).format("DD.MM.YY")],
  //                   [curr.source]:
  //                     prev?.[moment(curr.date).format("DD.MM.YY")][curr.source] +
  //                     1,
  //                 },
  //               };
  //             } else {
  //               return {
  //                 ...prev,
  //                 [moment(curr.date).format("DD.MM.YY")]: {
  //                   ...prev?.[moment(curr.date).format("DD.MM.YY")],
  //                   [curr.source]: 1,
  //                 },
  //               };
  //             }
  //           } else {
  //             return {
  //               ...prev,
  //               [moment(curr.date).format("DD.MM.YY")]: {
  //                 [curr.source]: 1,
  //               },
  //             };
  //           }
  //         }, {})
  //     : {};

  const filteredDataSource = signUps
    ? signUps
        ?.map((signup) => {
          const signupData = signup.data();
          return {
            id: id,
            date: signupData.signUpDate.toDate(),
            source: signupData.campaignSource,
          };
        })
        .reduce<Record<string, Record<string, any>>>((prev, curr) => {
          if (prev?.[curr.source]) {
            if (prev?.[curr.source][moment(curr.date).format("DD.MM.YY")]) {
              return {
                ...prev,
                [curr.source]: {
                  ...prev?.[curr.source],
                  [moment(curr.date).format("DD.MM.YY")]:
                    prev?.[curr.source][moment(curr.date).format("DD.MM.YY")] +
                    1,
                },
              };
            } else {
              return {
                ...prev,
                [curr.source]: {
                  ...prev?.[curr.source],
                  [moment(curr.date).format("DD.MM.YY")]: 1,
                },
              };
            }
          } else {
            return {
              ...prev,
              [curr.source]: {
                [moment(curr.date).format("DD.MM.YY")]: 1,
              },
            };
          }
        }, {})
    : {};

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Kampagnen Anmeldungen",
      },
    },
  };
  const datasets = Object.entries(filteredDataSource).map(([k, v], index) => {
    return {
      label: `${k.charAt(0).toUpperCase() + k.slice(1).toLowerCase()}`,
      data: Object.keys(filteredDataDate || {}).map((k) => {
        console.log(k);
        return v[k] ? v[k] : 0;
      }),
      backgroundColor: `rgba(${52 + 30 * index}, ${137 + 25 * index}, 235,${
        1 - 0.1 * index
      })`,
    };
  });

  //   const graphData = {
  //     labels: Object.keys(filteredData || {}),
  //     datasets: [
  //       {
  //         label: "Kampagnen Ursprung",
  //         data: Object.entries(filteredData || {}).map(([k, v]) => {
  //           return v;
  //         }),
  //         backgroundColor: `rgba(52, 137, 235)`,
  //       },
  //     ],
  //     // datasets: Object.entries(filteredData || {}).map(([, v]) => {
  //     //   return Object.entries(v || {}).map(([k, v]) => {
  //     //     return {
  //     //       label: `${k}`,
  //     //       data: v,
  //     //       backgroundColor: `rgba(52, 137, 235)`,
  //     //     };
  //     //   });
  //     // }),
  //   };

  const graphDataNew = {
    labels: Object.keys(filteredDataDate || {}),
    datasets: datasets,
  };

  //   const graphDataDate = {
  //     labels: Object.keys(filteredDataDate || {}),
  //     datasets: [
  //       {
  //         label: "Anmeldungen am Tag",
  //         data: Object.entries(filteredDataDate || {}).map(([k, v]) => {
  //           return v;
  //         }),
  //         backgroundColor: `rgba(52, 137, 235)`,
  //       },
  //     ],
  //     // datasets: Object.entries(filteredData || {}).map(([, v]) => {
  //     //   return Object.entries(v || {}).map(([k, v]) => {
  //     //     return {
  //     //       label: `${k}`,
  //     //       data: v,
  //     //       backgroundColor: `rgba(52, 137, 235)`,
  //     //     };
  //     //   });
  //     // }),
  //   };

  return (
    <>
      <Accordion defaultExpanded={process.env.NODE_ENV !== "production"}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid
            container
            justifyContent={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <strong>{id}</strong>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="flex flex-wrap flex-row">
          <PageContainer
            className="w-full min-w-full"
            title="Übersicht"
            heading={3}
            bgColor="bg-gray-800"
          >
            {/* <div className="m-auto w-1/2">
              <Bar data={graphData} options={options} />
            </div> */}
            <div className="m-auto w-1/2">
              <Bar data={graphDataNew} options={options} />
            </div>
          </PageContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DashbarCampaignView;
