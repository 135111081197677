import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";

const RawJSON = ({object}: { object: any }) => {

    return <Accordion>
        <AccordionSummary expandIcon={<ExpandMore/>}
                          aria-controls="panel1-content"
                          id="panel1-header">
            <Typography>Raw JSON</Typography>
        </AccordionSummary>
        <AccordionDetails>
<pre>
{JSON.stringify(object, null, 2)}
</pre>
        </AccordionDetails>
    </Accordion>


}

export default RawJSON;