import React, { useEffect, useState } from "react";
import getDashBar from "../../../../firebase/dashbar";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import dashbar from "../../../../firebase/dashbar";
import MDEditor from "@uiw/react-md-editor";

const FailedOrdersTab = () => {
  const [loading, updateLoading] = useState<Array<string>>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const setLoading = (id: string, isLoading: boolean) => {
    const loadingArray = loading;
    if (isLoading) {
      loadingArray.push(id);
      updateLoading(
        loadingArray.filter(
          (item, index) => loadingArray.indexOf(item) === index
        )
      );
    } else {
      updateLoading(loadingArray.filter((item) => item !== id));
    }
  };

  const [invoices, setInvoices] = useState<
    Array<{
      id: string;
      cid: string;
      retryCounter?: number;
      name: string;
      discountPositions: Array<Record<string, any>>;
      invoiceData: Record<string, any>;
      invoicePositions: Array<Record<string, any>>;
    }>
  >([]);

  const deleteInvoice = async (id: string) => {
    setLoading(id, true);
    await getDashBar()
      .db.collection(`failed_invoices`)
      .doc(id)
      .delete()
      .finally(() => {
        setLoading(id, false);
      });
  };

  const increaseRetries = async (invoice: Record<string, any>) => {
    await getDashBar()
      .db.collection(`failed_invoices`)
      .doc(invoice.id)
      .set({ retryCounter: (invoice?.retryCounter || 0) + 1 }, { merge: true });
    setRefresh(true);
  };

  const recreateInvoice = async (invoice: Record<string, any>) => {
    if (invoice.retryCounter && invoice.retryCounter >= 3) return;
    setLoading(invoice.id, true);
    dashbar()
      .functions.httpsCallable("calls-helper-recreateInvoice")({
        id: invoice.id,
        invoiceData: invoice.invoiceData,
        invoicePositions: invoice.invoicePositions,
        discountPositions: invoice.discountPositions,
      })
      .then((data) => {
        if (data.data === true) {
          console.log(`Successful`);
          setLoading(invoice.id, false);
        } else {
          increaseRetries(invoice).finally(() => {
            setLoading(invoice.id, false);
          });
          console.log(`Failure`);
        }
      });
    // .finally(() => {
    //   setLoading(invoice.id, false);
    // });
  };

  useEffect(() => {
    getDashBar()
      .db.collection(`failed_invoices`)
      .get()
      .then((docs) => {
        const invoiceArray: Array<{
          id: string;
          cid: string;
          name: string;
          retryCounter?: number;
          discountPositions: Array<Record<string, any>>;
          invoiceData: Record<string, any>;
          invoicePositions: Array<Record<string, any>>;
        }> = [];
        docs.forEach((doc) => {
          const data = doc.data();
          invoiceArray.push({
            id: doc.id,
            cid: data.companyId,
            name: data.name,
            retryCounter: data?.retryCounter,
            invoiceData: data.invoiceData,
            discountPositions: data.discountPositions,
            invoicePositions: data.invoicePositions,
          });
        });
        setInvoices(invoiceArray);
        console.log(invoiceArray);
      })
      .catch((err) => {
        console.log(`Error getting invoices: ${err}`);
      })
      .finally(() => {
        setRefresh(false);
      });
  }, [refresh]);

  return (
    <div style={{ minHeight: 200, width: "100%" }}>
      {invoices.map((invoice) => {
        return (
          <Card>
            <CardHeader
              style={{ width: "100%" }}
              title={`${invoice?.invoiceData?.invoiceNumber} (${invoice.name})`}
              subheader={`Erstellt am ${moment(
                invoice?.invoiceData?.invoiceDate?.toDate()
              ).format("DD.MM.YYYY hh:mm")}`}
            />
            <CardContent>
              <MDEditor.Markdown
                style={{ width: "100%", fontSize: 12, padding: 30 }}
                source={`${invoice.invoiceData.address}\n\n## ${
                  invoice.invoiceData.header
                }\n\n${
                  invoice.invoiceData.headText
                }\n\n| Position | Name |Preis|\n| ---|---|---|\n${invoice.invoicePositions.map(
                  (position, index) => {
                    return `| ${index}|${position.name}|${position.price}|\n`;
                  }
                )}\n\n
                `}
              />
            </CardContent>
            {!loading.includes(invoice.id) ? (
              <CardActions>
                {invoice?.retryCounter === undefined ||
                invoice?.retryCounter < 3 ? (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => recreateInvoice(invoice)}
                  >
                    Erneut anlegen
                  </Button>
                ) : (
                  <p
                    style={{ color: "red" }}
                  >{`Muss manuell angelegt werden!`}</p>
                )}
                <Button
                  size="small"
                  variant="outlined"
                  style={{ color: "red" }}
                  onClick={() => deleteInvoice(invoice.id)}
                >
                  Löschen
                </Button>
              </CardActions>
            ) : (
              <CircularProgress />
            )}
          </Card>
        );
      })}
    </div>
  );
};

export default FailedOrdersTab;
