import React from "react";
import BasicInfo from "./components/BasicInfo";
import NextInvoices from "./components/NextInvoices";

const OverviewTab = () => {
  return (
    <>
      <BasicInfo />
      <NextInvoices />
    </>
  );
};

export default OverviewTab;
