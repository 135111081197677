import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
} from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import Form from "./form";
import { Template } from "../types/Template";
import useSetDocument from "../../../../firestore/hooks/useSetDocument";

type Props = {
  open: boolean;
  onClose?: () => void;
};

const NewTemplate = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const closeDialogAndReset = () => {
    reset();
    props.onClose?.();
  };

  const createTemplate = useSetDocument<Template>("template");
  const handleSave = (values: Record<string, any>) => {
    const template = {
      id: values.templateId,
      name: values.name,
    } as Template;
    createTemplate(template, template.id);
    closeDialogAndReset();
  };

  const content = (
    <>
      <Form
        watch={watch}
        onSave={handleSave}
        register={register}
        setValue={setValue}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );

  return (
    <Modal open={props.open}>
      <Dialog
        open={props.open}
        maxWidth={"sm"}
        fullWidth
        onClose={closeDialogAndReset}
      >
        <DialogTitle>Plugin hinzufügen</DialogTitle>
        <DialogContent style={{ marginTop: 10, marginBottom: 10 }}>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogAndReset}>Abbrechen</Button>
          <Button color={"primary"} onClick={handleSubmit(handleSave)}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};
export default NewTemplate;
