import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import firebase from "firebase/compat/app";
import moment from "moment";
import { DB } from "../../../../../firebase";
import DashboardCard from "../../general/DashboardCard";

const MarketingTMBannerV2 = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [urlData, setUrlData] = useState<
    Array<{
      name: string;
      count: number;
      countLastThirty: number;
      data: Array<number>;
    }>
  >([]);

  useEffect(() => {
    const getTms = async () => {
      setLoading(true);
      const urlDataArray: Array<{
        name: string;
        count: number;
        countLastThirty: number;
        data: Array<number>;
      }> = [];
      const urlArray: Array<{
        data: Record<string, any>;
        id: string;
        ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
      }> = [];
      const urls = await DB().collection("shorturl").limit(6000).get();
      urls.forEach((url) => {
        if (url.id.startsWith(`tm`)) {
          urlArray.push({ data: url.data(), id: url.id, ref: url.ref });
        }
      });
      for (const url of urlArray) {
        const callArray: Array<string> = [];
        const callArrayClicks: Array<string> = [];
        const calls = await url.ref
          .collection(`sllog`)
          .orderBy(`clickTime`, "desc")
          .limit(500)
          .get();
        calls.forEach((call) => {
          const date = moment(call?.data()?.clickTime?.toDate()).format(
            "YYYYMMDD"
          );
          callArray.push(date);
          if (
            moment(call?.data()?.clickTime?.toDate()).isAfter(
              moment().subtract(30, "days")
            )
          ) {
            callArrayClicks.push(date);
          }
        });
        const mappedArray = callArray.reduce<Record<string, number>>(
          (prev, curr) => {
            if (prev?.[curr]) {
              return { ...prev, [curr]: prev[curr] + 1 };
            } else {
              return { ...prev, [curr]: 1 };
            }
          },
          {}
        );
        const dataMappedAll = Object.entries(mappedArray || {})
          .sort((v1, v2) => {
            if (+v1[0] > +v2[0]) {
              return -1;
            } else if (+v1[0] < +v2[0]) {
              return 1;
            } else {
              return 0;
            }
          })
          .filter((_v, index) => {
            return index < 3;
          })
          .map((v) => {
            return +v[1];
          });
        urlDataArray.push({
          name: url.id,
          count: url.data?.clicks || 0,
          countLastThirty: callArrayClicks.length || 0,
          data: dataMappedAll,
        });
      }
      setUrlData(urlDataArray);
      // setLoading(false);
    };
    // setLoading(id || "_", true);
    getTms().finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <DashboardCard title="TM Banner" loading={loading} theme="marketing">
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={5} style={{ margin: 0 }}>
          <Typography style={{ fontSize: 14, fontWeight: "lighter" }}>
            {`ShortURL`}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ margin: 0 }}>
          <Typography style={{ fontSize: 14, fontWeight: "lighter" }}>
            {`Klicks (gesamt)`}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ margin: 0 }}>
          <Typography style={{ fontSize: 14, fontWeight: "lighter" }}>
            {`Klicks (30 Tage)`}
          </Typography>
        </Grid>
        {urlData
          .filter((_v, index) => {
            return index < 8;
          })
          .map((v) => {
            return (
              <>
                <Grid item xs={5} style={{ margin: 0 }}>
                  <Typography style={{ fontSize: 12, fontWeight: "light" }}>
                    {v.name}
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ margin: 0 }}>
                  <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                    {v.count}
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ margin: 0 }}>
                  <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                    {v.countLastThirty}
                  </Typography>
                </Grid>
                {/* <Grid item xs={2}>
                  <Chart
                    options={{
                      legend: { show: false },
                      grid: { show: false },
                      tooltip: { enabled: false },
                      chart: {
                        type: "line",
                        toolbar: { show: false },
                        zoom: { enabled: false },
                        width: "100%",
                      },
                      stroke: { curve: "smooth", width: 2.5 },
                      states: { hover: { filter: { type: "none" } } },
                      xaxis: {
                        labels: { show: false },
                        axisBorder: { show: false },
                        axisTicks: { show: false },
                        crosshairs: { show: false },
                      },
                      yaxis: {
                        labels: { show: false },
                        axisBorder: { show: false },
                        axisTicks: { show: false },
                        crosshairs: { show: false },
                      },
                    }}
                    series={[
                      {
                        name: "Desktops",
                        data: v.data,
                      },
                    ]}
                  />
                </Grid> */}
              </>
            );
          })}
      </Grid>
    </DashboardCard>
  );
};

export default MarketingTMBannerV2;
