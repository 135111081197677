import React from "react";

const AddonKey = ({addOnKey}: { addOnKey: string }) => {
  const [cat, key] = (addOnKey).split('.')
  const {catColor, keyColor} = getCatColor(cat)
  return <div className='my-1'>
    <span className={`rounded-l ${catColor} px-2 py-1`}>{cat.toUpperCase()}</span>
    <span className={`rounded-r ${keyColor} px-2 py-1`}>{key}</span>
  </div>
}

export default AddonKey;

export const getCatColor = (cat: string) => {
  let catColor = 'bg-gray-700'
  let keyColor = 'bg-gray-400'
  switch (cat) {
    case 'general':
      catColor = 'bg-blue-700'
      keyColor = 'bg-blue-400'
      break;
    case 'dashboard':
      catColor = 'bg-green-700'
      keyColor = 'bg-green-400'
      break;
    case 'shop':
      catColor = 'bg-green-700'
      keyColor = 'bg-green-400'
      break;
    case 'page':
      catColor = 'bg-pink-700'
      keyColor = 'bg-pink-400'
      break;
  }
  return {catColor, keyColor}
}