import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import dashbar from "../../../../firebase/dashbar";
import getDashBar from "../../../../firebase/dashbar";
import { ICON_STYLE } from "../../../../utils/constants";

const BOOKING_TOLERANCE_REPEATED_IN_DAYS = 8;
const BOOKING_TOLERANCE_FIRST_IN_DAYS = 14;
const BOOKING_TOLERANCE_EXTENSION = 4;

const BookingTab = () => {
  const steps = ["Buchungsdaten", "Prüfen", "Abschließen"];
  const stepButtons = ["Prüfen", "Buchung durchführen", "Abschließen"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [cid, setCid] = React.useState<string>("");
  const [key, setKey] = React.useState<string>("BUNDLE");
  const [valueName, setValueName] = React.useState<string>("free");
  const [interval, setInterval] = React.useState<string>("month");
  const [status, setStatus] = React.useState<number>(1);
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const [isTrial, setIsTrial] = React.useState<boolean>(false);
  const [companyFound, setCompanyFound] = React.useState<boolean>(false);
  const [creatingBooking, setCreatingBooking] = useState<boolean>(false);
  const [bookingSuccessful, setBookingSuccessful] = useState<boolean>(false);
  const [bookingReady, setBookingReady] = useState<boolean>(false);
  const [searchingCompany, setSearchingCompany] =
    React.useState<boolean>(false);
  const [bundles, setBundles] = React.useState<
    Array<{ name: string; value: string }>
  >([]);
  const [extensions, setExtensions] = React.useState<
    Array<{ name: string; value: string }>
  >([]);
  const [bookingObject, setBookingObject] = React.useState<Record<
    string,
    any
  > | null>(null);

  useEffect(() => {
    const newBookingObject = {
      companyId: cid,
      booking: {
        key: key,
        startDate: startDate !== "" ? new Date(startDate).toISOString() : null,
        endDate: endDate !== "" ? new Date(endDate).toISOString() : null,
        status: status,
        interval: interval,
        isTrial: isTrial,
        ...(key === "BUNDLE" && { bundle: valueName }),
        ...(key === "EXTENSION" && { extension: valueName }),
      },
    };
    setBookingObject(newBookingObject);
  }, [cid, key, startDate, endDate, status, isTrial, valueName, interval]);

  const onStartDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const onEndDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const onCidChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCid(e.currentTarget.value);
  };

  const onValueNameChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValueName(e.currentTarget.value);
  };

  const onIntervalChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInterval(e.currentTarget.value);
  };

  const onKeyChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setKey(e.target.value);
  };

  const onStatusChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(+e.currentTarget.value);
  };

  const onTrialChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTrial(e.target.checked);
  };

  const resetInputFields = () => {
    setCid("");
    setKey("BUNDLE");
    setValueName("free");
    setStatus(1);
    setStartDate("");
    setEndDate("");
    setInterval("month");
    setIsTrial(false);
  };

  const createBooking = () => {
    setCreatingBooking(true);
    dashbar()
      .functions.httpsCallable("calls-helper-addBooking")(bookingObject)
      .then(() => {
        setBookingSuccessful(true);
        resetInputFields();
      })
      .catch(() => {
        setBookingSuccessful(false);
      })
      .finally(() => {
        setCreatingBooking(false);
      });
  };

  useEffect(() => {
    getDashBar()
      .db.collection(`bundle`)
      .get()
      .then((data) => {
        const bundleList: Array<{ name: string; value: string }> = [];
        if (!data.empty) {
          data.docs.forEach((doc) => {
            bundleList.push({ name: doc.data().name, value: doc.id });
          });
          setBundles(bundleList);
        } else {
          setBundles([]);
        }
      });
  }, [setBundles]);

  useEffect(() => {
    getDashBar()
      .db.collection(`extensions`)
      .get()
      .then((data) => {
        const extensionList: Array<{ name: string; value: string }> = [];
        if (!data.empty) {
          data.docs.forEach((doc) => {
            extensionList.push({ name: doc.data().name, value: doc.id });
          });
          setExtensions(extensionList);
        } else {
          setExtensions([]);
        }
      });
  }, [setExtensions]);

  const checkDataComplete = (): { complete: boolean; msg: string } => {
    if (!cid || cid === "") {
      return { complete: false, msg: `Company ID fehlt` };
    }
    if (!key || key === "") {
      return { complete: false, msg: `Buchungstyp fehlt` };
    }
    if (!startDate || startDate === "") {
      return { complete: false, msg: `Startdatum fehlt` };
    }
    if (!endDate || endDate === "") {
      return { complete: false, msg: `Enddatum fehlt` };
    }
    if (!status) {
      return { complete: false, msg: `Status fehlt` };
    }
    if (!valueName || valueName === "") {
      return { complete: false, msg: `Buchungswert fehlt` };
    }
    if (!interval || interval === "") {
      return { complete: false, msg: `Interval fehlt` };
    }
    return { complete: true, msg: `Daten vollständig` };
  };

  const bookingIsValid = (): boolean => {
    const isExtension = key === "EXTENSION" || false;
    const startDateFixed = startDate
      ? getFixedDate(new Date(startDate))
      : undefined;
    const startDateNew = new Date(startDate);
    const endDateNew = new Date(endDate);
    const todayDate = getFixedDate();
    const toleranceDateRepeatedBooking = new Date(
      new Date().setDate(
        new Date().getDate() - BOOKING_TOLERANCE_REPEATED_IN_DAYS
      )
    );
    const toleranceDateFirstBooking = new Date(
      new Date().setDate(new Date().getDate() - BOOKING_TOLERANCE_FIRST_IN_DAYS)
    );
    const toleranceDateExtension = new Date(
      new Date().setDate(new Date().getDate() - BOOKING_TOLERANCE_EXTENSION)
    );

    if (
      startDate &&
      endDate &&
      (startDateNew <= todayDate ||
        (startDateFixed && startDateFixed <= todayDate))
    ) {
      //Repeated booking
      if (status === 0 || status === 1) {
        if (endDateNew > toleranceDateRepeatedBooking) {
          //Booking valid (tolerance)
          return true;
        }
      } else if (status === 2) {
        if (endDateNew > todayDate) {
          //Booking valid (no tolerance because it was canceled)
          return true;
        }
      }
    } else if (startDate && startDateNew <= todayDate) {
      //First booking
      if (startDateNew > toleranceDateFirstBooking) {
        //Booking valid (tolerance)
        return true;
      }
      //Make Extenion valid 4 days after creation to handle sepa mandates
      if (isExtension && new Date() && new Date() > toleranceDateExtension) {
        return true;
      }
    }
    return false;
  };

  const searchCompany = () => {
    setSearchingCompany(true);
    getDashBar()
      .db.collection(`company`)
      .doc(cid || "-")
      .get()
      .then((data) => {
        if (data.exists) {
          setCompanyFound(true);
        } else {
          setCompanyFound(false);
        }
      })
      .finally(() => {
        setSearchingCompany(false);
      });
  };

  useEffect(() => {
    if (activeStep === 1) {
      searchCompany();
    } else if (activeStep === 0) {
      setCompanyFound(false);
    } else if (activeStep === 2) {
      createBooking();
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      !searchingCompany &&
      companyFound &&
      bookingIsValid() &&
      checkDataComplete().complete
    ) {
      setBookingReady(true);
    } else {
      setBookingReady(false);
    }
  }, [searchingCompany, companyFound]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const BookingData = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <input
          key={`cid`}
          type={"text"}
          value={cid}
          onChange={onCidChanged}
          placeholder={"Company ID"}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
          }}
        />
        {/* <select
          key="type"
          placeholder="Typ"
          value={key}
          onChange={onKeyChanged}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
            color: "black",
          }}
        >
          <option value="BUNDLE" selected>
            Bundle
          </option>
          {/* <option value="EXTENSION">Extension</option> 
        </select> */}
        <select
          key="valueName"
          value={valueName}
          onChange={onValueNameChanged}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
            color: "black",
          }}
        >
          {key === "EXTENSION"
            ? extensions.map((extension) => {
                return (
                  <option value={extension.value}>{extension.name}</option>
                );
              })
            : bundles.map((bundle) => {
                return <option value={bundle.value}>{bundle.name}</option>;
              })}
        </select>
        <input
          key={`startDate`}
          type={"date"}
          value={startDate}
          onChange={onStartDateChanged}
          placeholder={"Startdatum"}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
          }}
        />
        <input
          key={`endDate`}
          type={"date"}
          value={endDate}
          onChange={onEndDateChanged}
          placeholder={"Enddatum"}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
          }}
        />
        <select
          key="interval"
          value={interval}
          onChange={onIntervalChanged}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
            color: "black",
          }}
        >
          <option value={"month"} selected>
            Monatlich
          </option>
          <option value={"year"}>Jährlich</option>
        </select>
        <select
          key="status"
          value={status}
          onChange={onStatusChanged}
          style={{
            padding: 5,
            width: "30%",
            borderRadius: 5,
            marginBottom: 5,
            color: "black",
          }}
        >
          <option value={0}>Pending</option>
          <option value={1} selected>
            Active
          </option>
          <option value={2}>Canceled</option>
          <option value={3}>Deleted</option>
        </select>
        <div style={{ width: "30%", marginBottom: 5, padding: 5 }}>
          <input checked={isTrial} onChange={onTrialChanged} type="checkbox" />
          <label> Trial Buchung</label>
        </div>
      </div>
    );
  };

  const StatusCard = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={3}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={checkDataComplete().complete ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  checkDataComplete().complete
                    ? "check-circle"
                    : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {checkDataComplete().msg}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={3}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={bookingIsValid() ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  bookingIsValid() ? "check-circle" : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {bookingIsValid() ? `Gültige Buchung` : `Ungültige Buchung`}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={3}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={companyFound ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  companyFound ? "check-circle" : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {companyFound
                  ? `Firma existiert`
                  : `Firma wurde nicht gefunden`}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  };

  const BookingCard = () => {
    if (creatingBooking) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Card
            style={{
              width: "30%",
              borderRadius: 15,
              marginBottom: 10,
              padding: 10,
              backgroundColor: `#fff`,
            }}
          >
            <LinearProgress />
          </Card>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Card
          style={{
            width: "30%",
            borderRadius: 15,
            marginBottom: 10,
            padding: 10,
            backgroundColor: `#fff`,
          }}
        >
          <Grid
            container
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={3}
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                color={bookingSuccessful ? "#19781f" : "#c90e0e"}
                icon={[
                  ICON_STYLE,
                  bookingSuccessful ? "check-circle" : "exclamation-circle",
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: "black",
                }}
              >
                {bookingSuccessful
                  ? `Buchung war erfolgreich`
                  : `Fehler bei der Buchung`}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  };

  return (
    <div>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <React.Fragment>
          {activeStep === 0 ? (
            <BookingData />
          ) : activeStep === 1 ? (
            <StatusCard />
          ) : (
            <BookingCard />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Zurück
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={activeStep === 2 ? handleReset : handleNext}
              disabled={activeStep === 1 && !bookingReady}
            >
              {stepButtons[activeStep]}
            </Button>
          </Box>
        </React.Fragment>
      }
    </div>
  );
};

const getFixedDate = (date = new Date()): Date => {
  date.setHours(8, 0, 0, 0);
  return date;
};
export default BookingTab;
