import React, { useEffect, useState } from "react";
import { DB } from "../../../../../firebase";
import firebase from "firebase/compat/app";

type ExportProps = {
  exportStart: boolean;
  setExportEnabled: (value: boolean) => void;
};

const Export = ({ exportStart, setExportEnabled }: ExportProps) => {
  const db = DB().collection("plugin").doc("ws5_eucookie");
  const storage = firebase.app().storage("gs://cdn.ws-cdn.de");

  const [uploadStatus, setUploadStatus] = useState<string>("");

  const jsonData: {
    services: {
      cServiceId: string;
      cServiceIdString: string;
      kCategory: string;
      jAttributes: string;
      jTranslations: string;
      cJtlConsentIds: [];
    }[];
    sources: {
      cType: string;
      cParentServiceId: string;
      kParentCategory: string;
      cSource: string;
      cSourceHash: string;
      jAttributes: string;
    }[];
    keywords: {
      cServiceId: string;
      cKeyword: string;
    }[];
  } = {
    services: [],
    sources: [],
    keywords: [],
  };

  // TODO: Reactor the whole update process
  useEffect(() => {
    const exportHandler = async () => {
      // Retrieve all services
      const servicesSnapshot = await db.collection("services").get();
      const servicePromises = servicesSnapshot.docs.map(async (serviceDoc) => {
        const serviceData = serviceDoc.data();

        jsonData.services.push({
          cServiceId: serviceDoc.id,
          cServiceIdString: serviceData.cServiceIdString || "",
          kCategory: serviceData.kCategory || "",
          jAttributes: serviceData.jAttributes || "",
          jTranslations: serviceData.jTranslations || "",
          cJtlConsentIds: serviceData.cJtlConsentIds || [],
        });

        if (serviceData.keywords) {
          serviceData.keywords.forEach((keyword: string) =>
            jsonData.keywords.push({
              cServiceId: serviceDoc.id,
              cKeyword: keyword,
            })
          );
        }

        // Retrieve sources for the current service
        const sourcesSnapshot = await serviceDoc.ref
          .collection("sources")
          .get();
        const sourcePromises = sourcesSnapshot.docs.map((sourceDoc) => {
          const sourceData = sourceDoc.data();
          jsonData.sources.push({
            cType: sourceData.cType || "",
            cParentServiceId: serviceDoc.id,
            kParentCategory: serviceData.kCategory || "",
            cSource: sourceData.cSource || "",
            cSourceHash: sourceData.cSourceHash || "",
            jAttributes: sourceData.jAttributes || "",
          });
        });

        await Promise.all([...sourcePromises]);
      });

      await Promise.all(servicePromises);

      const jsonContent = JSON.stringify(jsonData);
      const blob = new Blob([jsonContent], { type: "application/json" });

      const storageRef = storage.ref("eucookie/eucookie_data.json");

      storageRef
        .put(blob)
        .then(() => {
          storageRef.updateMetadata({ cacheControl: "no-cache" });
          setUploadStatus("File Export successful");
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setUploadStatus("File Export failed");
        });

      setUploadStatus("File Export successful");
      setExportEnabled(false);
    };

    if (exportStart) {
      exportHandler();
    }
  }, []);

  return (
    <>
      {uploadStatus && (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{ color: "green", marginBottom: "30px", marginTop: "40px" }}
          >
            {uploadStatus}
          </div>
        </div>
      )}
    </>
  );
};

export default Export;
