import {faGitlab} from "@fortawesome/free-brands-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const GitlabTodos: IconProp = {
  icon: [
    16,
    16,
    [],
    faGitlab.icon[3],
    "M4 4h5a1 1 0 0 0 0-2H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-2a1 1 0 1 0-2 0v2H4V4zm11.207-1.707a1 1 0 0 0-1.414 0L8.5 7.586l-.793-.793a1 1 0 0 0-1.414 1.414l1.5 1.5a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414z"
  ],
  iconName: 'democrat',
  prefix: 'fab'
} as IconProp

export default GitlabTodos
