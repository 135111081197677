import React, { useState } from "react";
import PageContainer from "../../../components/PageContainer";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import TabPanel, { a11yProps } from "../../../components/TabPanel";
import SyncTab from "./tabs/SyncTabNew";
import OverviewTab from "./tabs/OverviewTab";

const Bitrix2SevdeskView = () => {
  const [tab, setTab] = useState(0);

  return (
    <>
      <PageContainer title={"Bitrix 2 SevDesk Sync"}>
        <AppBar title="Operations" position="static">
          <Tabs
            value={tab}
            onChange={(_, val) => setTab(val)}
            aria-label="operations tabs"
          >
            <Tab label="Übersicht" {...a11yProps(0)} />
            <Tab label="Synchronistaion" {...a11yProps(1)} />
            {/* <Tab label="Users" {...a11yProps(1)} />
        <Tab label="Shops" {...a11yProps(2)} />
        <Tab label="Notifications" {...a11yProps(3)} />
        <Tab label="Bookings" {...a11yProps(4)} /> */}
          </Tabs>
        </AppBar>
        {tab === 0 && (
          <TabPanel value={tab} index={0}>
            {<OverviewTab />}
          </TabPanel>
        )}
        {tab === 1 && (
          <TabPanel value={tab} index={1}>
            {<SyncTab />}
          </TabPanel>
        )}
      </PageContainer>
    </>
  );
};

export default Bitrix2SevdeskView;
