import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@material-ui/core";
import { firestore } from "firebase-admin";
import moment from "moment";
import React, { useState } from "react";
import { DB } from "../../../../../../firebase";
import { deleteAdspaceImage } from "../../../../utils";
import BookingEditModal from "../BookingEditModal";
import { ICON_STYLE } from "../../../../../../utils/constants";

interface IBookedRowProps {
  id: string;
  name: string;
  startDate: firestore.Timestamp;
  endDate: firestore.Timestamp;
  adspaceId: string;
  campaign: Record<string, any>;
  fileUrl?: string;
  filePath?: string;
}

const BookedRow = ({
  id,
  name,
  startDate,
  endDate,
  campaign,
  fileUrl,
  filePath,
  adspaceId,
}: IBookedRowProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const deleteBookedAdspace = async () => {
    if (
      !window.confirm(
        "Soll dieser Werbeplatz wirklich aus der Kampagne entfernt werden?"
      )
    )
      return;
    setLoading(true);
    //ToDo create DeleteFunction which also deletes images etc
    if (filePath) {
      const couldDeleteFile = await deleteAdspaceImage(filePath);
      if (couldDeleteFile === true) {
        await DB().collection(`adspace/${adspaceId}/booking`).doc(id).delete();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      await DB().collection(`adspace/${adspaceId}/booking`).doc(id).delete();
      setLoading(false);
    }
  };
  // const editCampaign = () => {
  //   //ToDo open Campaign Edit
  // };

  return (
    <div
      key={`${id}_${name}`}
      style={{
        backgroundColor: "#171717",
        width: "45%",
        maxWidth: "45%",
        minWidth: "45%",
        borderRadius: 10,
        padding: 5,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        margin: 5,
      }}
      className="flex flex-row"
    >
      <div className="flex flex-col content-start items-start justify-center">
        <Typography
          style={{
            fontSize: 16,
            fontWeight: "normal",
          }}
        >
          {name}
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "lighter",
          }}
        >
          {`${moment(startDate.toDate()).format("DD.MM.YYYY")} - ${moment(
            endDate.toDate()
          ).format("DD.MM.YYYY")}`}
        </Typography>
      </div>

      <div>
        <IconButton
          size={"small"}
          title="Bearbeiten"
          style={{ margin: "0 5px" }}
          disabled={loading}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <FontAwesomeIcon icon={[ICON_STYLE, "edit"]} size={"sm"} />
        </IconButton>
        <IconButton
          size={"small"}
          disabled={loading}
          title="Löschen"
          style={{ margin: "0 5px" }}
          onClick={deleteBookedAdspace}
        >
          <FontAwesomeIcon icon={[ICON_STYLE, "trash"]} size={"sm"} />
        </IconButton>
        {fileUrl && (
          <IconButton
            disabled={loading}
            size={"small"}
            title="Medien herunterladen"
            style={{ margin: "0 5px" }}
          >
            <a target="_blank" href={fileUrl}>
              <FontAwesomeIcon icon={[ICON_STYLE, "download"]} size={"sm"} />
            </a>
          </IconButton>
        )}
      </div>
      <BookingEditModal
        id={id}
        open={modalOpen}
        campaign={campaign}
        close={() => setModalOpen(false)}
      />
    </div>
  );
};

export default BookedRow;
