import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { functions } from "../../../../firebase";
import { IComponentProps } from "../../helper/ComponentProps";
import { DashboardContext } from "../DashboardContext";
const ical = require("node-ical");

const AbscentEmployees = ({ id }: IComponentProps) => {
  const [abscent, setAbscent] = useState<Array<string>>([]);
  const [homeoffice, setHomeoffice] = useState<Array<string>>([]);
  const [abscentSoon, setAbscentSoon] = useState<Array<string>>([]);
  const getAbscences = functions().httpsCallable("calls-heavenhr-getAbscences");
  const { setLoading } = useContext(DashboardContext);

  useEffect(() => {
    // setLoading(id, true);
    getAbscences()
      .then((data) => {
        const events = ical.parseICS(data.data.events);
        // const events = {};
        const homeofficeArray: Array<string> = [];
        const abscentArray: Array<string> = [];
        const abscentSoonArray: Array<string> = [];
        Object.values<Record<string, any>>(events || {})
          .filter((value) => {
            return (
              moment(new Date()).isBetween(
                moment(value?.start?.toISOString()),
                moment(value?.end?.toISOString()),
                undefined,
                "[]"
              ) === true
            );
          })
          .filter((value) => {
            return value?.description?.val?.split(",")[0] !== undefined;
          })
          .map((value) => {
            if (value?.summary?.includes(`(Home office)`)) {
              homeofficeArray.push(
                `${value?.description?.val?.split(",")[0]} (bis ${moment(
                  value?.end?.toISOString()
                )
                  .subtract(1, "day")
                  .format("DD.MM.YYYY")})`
              );
            } else {
              abscentArray.push(
                `${value?.description?.val?.split(",")[0]} (bis ${moment(
                  value?.end?.toISOString()
                )
                  .subtract(1, "day")
                  .format("DD.MM.YYYY")})`
              );
            }
          });
        Object.values<Record<string, any>>(events || {})
          .filter((value) => {
            return (
              moment(value?.start?.toISOString()).isAfter(moment(new Date())) &&
              Math.abs(
                moment(new Date()).diff(
                  moment(value?.start?.toISOString()),
                  "days"
                )
              ) <= 10
            );
          })
          .filter((value) => {
            return value?.description?.val?.split(",")[0] !== undefined;
          })
          .sort((v1, v2) => {
            if (
              moment(v1?.start?.toISOString()).isBefore(
                moment(v2?.start?.toISOString())
              )
            ) {
              return -1;
            } else if (
              moment(v1?.start?.toISOString()).isAfter(
                moment(v2?.start?.toISOString())
              )
            ) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((value) => {
            if (value?.summary?.includes(`(Home office)`)) {
            } else {
              abscentSoonArray.push(
                `${value?.description?.val?.split(",")[0]} (ab ${moment(
                  value?.start?.toISOString()
                ).format("DD.MM.YYYY")})`
              );
            }
          });
        setAbscentSoon(abscentSoonArray);
        setHomeoffice(homeofficeArray);
        setAbscent(abscentArray);
      })
      .finally(() => {
        setLoading(id || "_", false);
      });
  }, []);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {abscent.length > 0 && (
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              color: "white",
              marginTop: 10,
              marginBottom: 3,
              fontFamily: "Roboto",
              fontWeight: "700",
            }}
          >
            {`Abwesend`}
          </Typography>
          {abscent.map((v) => {
            return (
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  marginTop: 0,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {v}
              </Typography>
            );
          })}
        </div>
      )}
      {homeoffice.length > 0 && (
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              color: "white",
              marginTop: 10,
              marginBottom: 3,
              fontFamily: "Roboto",
              fontWeight: "700",
            }}
          >
            {`Homeoffice`}
          </Typography>
          {homeoffice.map((v) => {
            return (
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  marginTop: 0,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {v}
              </Typography>
            );
          })}
        </div>
      )}
      {abscentSoon.length > 0 && (
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              color: "white",
              marginTop: 12,
              marginBottom: 3,
              fontFamily: "Roboto",
              fontWeight: "700",
            }}
          >
            {`Bald abwesend`}
          </Typography>
          {abscentSoon.map((v) => {
            return (
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  marginTop: 0,
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                {v}
              </Typography>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AbscentEmployees;
