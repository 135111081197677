import React, { useEffect, useState } from "react";
import getAbocloud from "../../../firebase/abocloud";
import { Grid, Typography } from "@material-ui/core";
import firebase from "firebase/compat/app";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Badge from "../components/Badge";
import {
  merchantStatusColorFromId,
  merchantStatusNameFromId,
} from "../helper/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ERROR, SUCCESS } from "../helper/colors";
import { ICON_STYLE } from "../../../utils/constants";

const Merchants = () => {
  const navigate = useNavigate();
  const [merchants, setMerchants] = useState<Array<Record<string, any>> | null>(
    null
  );
  const [tariffs, setTariffs] = useState<Array<Record<string, any>> | null>(
    null
  );

  useEffect(() => {
    const merchantArray: Array<Record<string, any>> = [];
    getAbocloud()
      .db.collection(`merchants`)
      .orderBy("createTime", "desc")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          merchantArray.push({ ...doc.data(), id: doc.id });
        });
        setMerchants(merchantArray);
      });
  }, []);

  useEffect(() => {
    const tariffArray: Array<Record<string, any>> = [];
    getAbocloud()
      .db.collection(`tariff`)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          tariffArray.push({ ...doc.data(), ref: doc.ref, id: doc.id });
        });
        setTariffs(tariffArray);
      });
  }, []);

  const getTariffName = (
    ref: firebase.firestore.DocumentReference | null
  ): string => {
    if (ref === null) {
      return (
        tariffs?.filter((v) => {
          return v.id === `default`;
        })[0].name ?? "n/a"
      );
    }
    return (
      tariffs?.filter((v) => {
        return v.ref.path === ref.path;
      })[0].name ?? "n/a"
    );
  };

  const getTariffColor = (
    ref: firebase.firestore.DocumentReference | null
  ): string => {
    if (ref === null) {
      return (
        tariffs?.filter((v) => {
          return v.id === `default`;
        })[0].color ?? "#EEE"
      );
    }
    return (
      tariffs?.filter((v) => {
        return v.ref.path === ref.path;
      })[0].color ?? "#EEE"
    );
  };

  const getActivityValue = (lastActivity: Date) => {
    if (moment(lastActivity).add(2, "days") > moment()) {
      return `Sehr aktiv`;
    } else if (moment(lastActivity).add(5, "days") > moment()) {
      return `Aktiv`;
    } else if (moment(lastActivity).add(10, "days") > moment()) {
      return `Wenig Aktiv`;
    } else {
      return `Inaktiv`;
    }
  };

  const getActivityColor = (lastActivity: Date) => {
    if (moment(lastActivity).add(2, "days") > moment()) {
      return `#4158cc`;
    } else if (moment(lastActivity).add(5, "days") > moment()) {
      return `#2f3d82`;
    } else if (moment(lastActivity).add(10, "days") > moment()) {
      return `#151c40`;
    } else {
      return `#0a0d1c`;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          backgroundColor: "#111",
          padding: 7,
          borderRadius: 10,
          marginTop: 4,
          marginBottom: 4,
          cursor: "pointer",
        }}
      >
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={3}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {`ID`}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {`Firma`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {`Erstellungsdatum`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {`Status`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {`Initialisiert`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {`Tarif`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                color: "#EEE",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {`Aktivität`}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {merchants !== null &&
        merchants.map((merchant) => {
          return (
            <div
              style={{
                width: "90%",
                backgroundColor: "#EEE",
                padding: 5,
                borderRadius: 10,
                marginTop: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`${merchant.id}`);
              }}
            >
              <Grid container spacing={2} style={{ width: "100%" }}>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: 16,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {merchant?.id ?? `n/a`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: 16,
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    {merchant?.company?.organisation ?? `n/a`}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      color: "#111",
                      fontSize: 16,
                      fontFamily: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {moment(merchant.createTime.toDate()).format(
                      "DD.MM.YYYY HH:mm"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Badge
                    title={merchantStatusNameFromId(merchant.status)}
                    color={merchantStatusColorFromId(merchant.status)}
                    solid
                    fontWhite
                  />
                </Grid>
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    style={{
                      marginTop: 2,
                      fontSize: 18,
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    color={merchant.initialized === true ? SUCCESS : ERROR}
                    icon={[
                      ICON_STYLE,
                      merchant.initialized === true
                        ? "check-circle"
                        : "xmark-circle",
                    ]}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Badge
                    title={getTariffName(merchant?.tariff?.tariffRef ?? null)}
                    color={getTariffColor(merchant?.tariff?.tariffRef ?? null)}
                    solid
                    fontWhite
                  />
                </Grid>
                <Grid item xs={1}>
                  <Badge
                    title={getActivityValue(
                      merchant?.lastActivity?.toDate() ??
                        moment().subtract(30, "days").toDate()
                    )}
                    color={getActivityColor(
                      merchant?.lastActivity?.toDate() ??
                        moment().subtract(30, "days").toDate()
                    )}
                    solid
                    fontWhite
                  />
                </Grid>
              </Grid>
            </div>
          );
        })}
    </div>
  );
};

export default Merchants;
