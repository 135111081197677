import { Card, CardContent, CardHeader, CardMedia } from "@material-ui/core";
import React from "react";
import { UserRole } from "../../../../firestore/models";
import useAuth from "../../../../hooks/useAuth";
import useProfile from "../../../../hooks/firestore/useProfile";
import "./styles.css";
import { Skeleton } from "@material-ui/lab";
// import { DashboardContext } from "../DashboardContext";

const DashboardCard = ({
  title,
  subtitle,
  content,
  role,
  img,
  id,
}: {
  title: string;
  subtitle?: string;
  content: React.ReactNode;
  role?: UserRole;
  img?: { url: string; alt: string };
  id?: string;
}) => {
  const auth = useAuth();
  const profile = useProfile();
  // const { loadingIds, invisibleIds } = useContext(DashboardContext);

  // console.log(`Loading id in card: ${loadingIds.toString()}`);

  // const loading = loadingIds.includes(id || "_");

  const hasPermission = () => {
    if (role === undefined || role === null) return true;
    if (auth && profile && profile.roles) {
      return profile.roles.includes(role);
    } else {
      return false;
    }
  };

  // const LoadingSkeleton = () => {
  //   return (
  //     <Skeleton
  //       width={"100%"}
  //       height={230}
  //       variant="rect"
  //       style={{
  //         minHeight: 230,
  //         maxHeight: 230,
  //         top: 0,
  //         bottom: 0,
  //         left: 0,
  //         right: 0,
  //       }}
  //     />
  //   );
  // };

  if (!hasPermission()) {
    return null;
  }
  // if (invisibleIds.includes(id || "_")) {
  //   return null;
  // }

  return (
    <Card
      style={{ borderRadius: 10, width: 460, minWidth: 460, flex: "1 1 450" }}
    >
      <CardHeader
        // avatar={
        //   <Avatar
        //     src="assets/webstollen.png"
        //     style={{ backgroundColor: "white" }}
        //   />
        // }
        title={title}
        // subheader={loading === true ? `wird geladen...` : subtitle}
        subheader={subtitle}
        sx={{
          "& .MuiCardHeader-title": { font: "Roboto", margin: 0 },
          "& .MuiCardHeader-subheader": { font: "Roboto", margin: 0 },
        }}
      />
      {img !== undefined && (
        <CardMedia
          component="img"
          height="120"
          style={{ height: 120, maxHeight: 120 }}
          image={img?.url}
          alt={img?.alt}
        />
      )}
      {/* {loading === true && (
        <CardContent style={{ bottom: 0 }}>
          <LoadingSkeleton />
        </CardContent>
      )} */}
      {hasPermission() === true && (
        <CardContent
          // style={{ visibility: loading === true ? "hidden" : "visible" }}
          children={content}
        />
      )}
    </Card>
  );
};

export default DashboardCard;
