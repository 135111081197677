import React, { useCallback, useState } from "react";
import { LicenceChip, SubscriptionChip } from "../../../../../components/Chips";
import moment from "moment";
import { useCollection } from "../../../../../firestore/hooks";
import Table from "../../../../../components/Table";
import firebase from "firebase/compat/app";
import { WhereT } from "../../../../../types";
import { FormControlLabel, Switch } from "@material-ui/core";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";

type Props = {
  pluginId: string;
};

const InstallView = ({ pluginId }: Props) => {
  const [where, setWhere] = useState<WhereT>();
  const [graph, setGraph] = useState(false);
  const installs = useCollection<Record<string, any>>(
    `plugin/${pluginId}/install`,
    where,
    where ? undefined : ["last", "desc"],
    1000
  );

  const handleSearch = useCallback(
    (key: string, value: string) => {
      if (value) {
        setWhere([
          key === "id" ? firebase.firestore.FieldPath.documentId() : key,
          ">=",
          value,
        ]);
      } else {
        setWhere(undefined);
      }
    },
    [setWhere]
  );

  const reducedData = installs
    ? Object.entries(installs)
        .filter(([, v]) => {
          return v?.version !== undefined && v?.version !== "undefined";
        })
        .map(([, v]): string => {
          return v.version;
        })
        .reduce<Record<string, number>>((prev, curr) => {
          if (prev?.[curr]) {
            return { ...prev, [curr]: prev[curr] + 1 };
          } else {
            return { ...prev, [curr]: 1 };
          }
        }, {})
    : [];
  const orderedData = Object.entries(reducedData)
    .sort((a, b) => {
      if (a[0] < b[0]) {
        return -1;
      } else if (a[0] > b[0]) {
        return 1;
      } else {
        return 0;
      }
    })
    .reduce((acc, curr) => {
      const key = curr[0];
      const value = curr[1];
      return { ...acc, [key]: value };
    }, {});

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Plugin Versions",
      },
    },
  };
  const data = {
    labels: Object.keys(orderedData),
    datasets: [
      {
        label: "Versions",
        data: Object.entries(orderedData).map(([, v]) => {
          return v;
        }),
        backgroundColor: `rgba(52, 137, 235)`,
      },
    ],
  };
  return (
    <div className="mt-6">
      <FormControlLabel
        label={"Visualize Versions"}
        control={
          <Switch checked={graph} onChange={() => setGraph((prev) => !prev)} />
        }
      />
      {graph ? (
        <div className="m-auto w-1/2">
          <Bar data={data} options={options} />
        </div>
      ) : (
        <Table
          striped
          header={{
            domain: { content: "Domain", searchable: true },
            version: { content: "Version", searchable: true },
            hasLicense: { content: "License" },
            hasSubscription: { content: "Subscription" },
            shopVersion: { content: "ShopVersion", searchable: true },
            phpVersion: { content: "PHPVersion", searchable: true },
            last: { content: "Aktualisiert" },
          }}
          onSearch={handleSearch}
        >
          {installs
            ? Object.entries(installs).map(([k, v]) => {
                return (
                  <tr key={v.id}>
                    <td>{v?.domain || "n/a"}</td>
                    <td>{v?.version || "n/a"}</td>
                    <td>
                      <LicenceChip
                        hasLicense={
                          v?.hasLicense !== undefined ? v?.hasLicense : null
                        }
                      />
                    </td>
                    <td>
                      <SubscriptionChip
                        hasSubscription={
                          v?.hasSubscription !== undefined
                            ? v?.hasSubscription
                            : null
                        }
                      />
                    </td>
                    <td>{v?.shopVersion || "n/a"}</td>
                    <td>{v?.phpVersion || "n/a"}</td>
                    <td>{moment(v?.last.toDate()).format("DD.MM.YYYY")}</td>
                  </tr>
                );
              })
            : null}
        </Table>
      )}
    </div>
  );
};

export default InstallView;
