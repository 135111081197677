import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { functions } from "../../firebase";
import { IAuth } from "../../types/Auth";
import useLoading from "../../hooks/useLoading";
import { Alert } from "@material-ui/lab";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";
import PageContainer from "../../components/PageContainer";
import UserViewNew from "./UserNew";
import { FileCopy } from "@material-ui/icons";

const UsersModule = () => {
  const ROLES = {
    ADMIN: "admin",
    SHORTURL: "shorturl",
    LICENCES: "licences",
    WEBHOOKS: "webhooks",
    DEVELOPER: "developer",
    DASHBAR: "dashbar",
    ABOCLOUD: "abocloud",
    LUNCH: "lunch_user",
  };

  const [users, setUsers] = useState<null | Record<string, IAuth>>(null);
  const [addRoleTo, setAddRoleTo] = useState<undefined | IAuth>();
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [checkedRoles, setCheckedRoles] = useState<string[]>([]);
  const createUser = functions().httpsCallable("calls-user-create");
  const {
    setLoading,
    setError,
    finallyLoading,
    loading,
    Loading,
    loadingProps,
  } = useLoading();

  const [addingUser, setAddingUser] = useState<boolean>(false);

  const [email, setEmail] = useState<string | null>(null);
  const [firstname, setFirstname] = useState<string | null>(null);
  const [lastname, setLastname] = useState<string | null>(null);
  const [prn, setPRN] = useState<number | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [result, setResult] = useState<{
    resetLink: string;
    uid: string;
  } | null>(null);

  const cleanUserModal = () => {
    setEmail(null);
    setFirstname(null);
    setLastname(null);
    setPRN(null);
    setUserRoles([]);
    setResult(null);
    setShowUserModal(false);
  };

  const addUser = async () => {
    setAddingUser(true);
    await createUser({
      email: email,
      firstname: firstname,
      lastname: lastname,
      prn: prn,
      roles: userRoles,
    })
      .then((res) => {
        setResult({ resetLink: res?.data?.reset_link, uid: res?.data?.uid });
      })
      .finally(() => {
        setAddingUser(false);
      });
  };

  const loadUsers = useCallback(() => {
    setLoading(true, "Load Users");
    functions()
      .httpsCallable("resource")({
        resource: "user",
        action: "list",
        data: {
          limit: 100,
        },
      })
      .then((res) => setUsers(res.data.data))
      .catch(setError(loadUsers))
      .finally(finallyLoading);
  }, [setUsers, setError, setLoading, finallyLoading]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const addRole = (user: IAuth, roles: string[]) => () => {
    functions()
      .httpsCallable("resource")({
        resource: "user",
        action: "addRoles",
        data: {
          uid: user.uid,
          roles: roles,
        },
      })
      .then((res) => {
        if (res.data) {
          loadUsers();
        }
        setAddRoleTo(undefined);
      })
      .catch(console.error);
  };

  const handleRoleToggle = (role: string) => () => {
    const currentIndex = checkedRoles.indexOf(role);
    const newChecked = [...checkedRoles];
    if (currentIndex === -1) {
      newChecked.push(role);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedRoles(newChecked);
  };
  const handleUserRoleToggle = (role: string) => () => {
    const currentIndex = userRoles.indexOf(role);
    const newChecked = [...userRoles];
    if (currentIndex === -1) {
      newChecked.push(role);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setUserRoles(newChecked);
  };

  const openAddRoleModal = (user: IAuth) => () => {
    setCheckedRoles([]);
    setAddRoleTo(user);
  };

  if (loading) {
    return <Loading {...loadingProps} />;
  }

  if (!users) {
    return <Alert severity={"warning"}>No users.</Alert>;
  }

  return (
    <PageContainer
      title="Users"
      onAdd={() => {
        setShowUserModal(true);
      }}
    >
      <Grid container spacing={2}>
        {Object.keys(users).map((uid: string) => (
          <Grid
            xs={12}
            sm={6}
            md={4}
            lg={3}
            item
            key={uid}
            alignContent="stretch"
            alignItems="stretch"
          >
            <UserViewNew
              user={users[uid]}
              onChange={loadUsers}
              openAddRoleModal={openAddRoleModal}
            />
          </Grid>
        ))}
      </Grid>

      <Modal open={!!addRoleTo}>
        <Dialog open={!!addRoleTo} onClose={() => setAddRoleTo(undefined)}>
          <DialogTitle>Add role to {addRoleTo?.displayName}</DialogTitle>
          <DialogContent>
            <List dense>
              {Object.values(ROLES).map((role) =>
                !addRoleTo?.customClaims ||
                !Object.keys(addRoleTo?.customClaims).includes(role) ? (
                  <ListItem
                    dense
                    key={role}
                    button
                    onClick={handleRoleToggle(role)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge={"start"}
                        checked={checkedRoles.indexOf(role) !== -1}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    {role}
                  </ListItem>
                ) : null
              )}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddRoleTo(undefined)} color="primary">
              cancel
            </Button>
            <Button
              onClick={addRoleTo && addRole(addRoleTo, checkedRoles)}
              color="primary"
            >
              save
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
      <Modal open={showUserModal}>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={showUserModal}
          onClose={cleanUserModal}
        >
          <DialogTitle>{`Neuen Mitarbeiter hinzufügen`}</DialogTitle>
          <DialogContent>
            {addingUser ? (
              <CircularProgress />
            ) : (
              <>
                {result === null && (
                  <Grid
                    container
                    style={{ alignContent: "center", alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="E-Mail"
                        variant="outlined"
                        value={email ?? ``}
                        onChange={(v) => {
                          setEmail(v.currentTarget.value);
                        }}
                        style={{ width: "60%" }}
                        size="small"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Vorname"
                        variant="outlined"
                        value={firstname ?? ``}
                        onChange={(v) => {
                          setFirstname(v.currentTarget.value);
                        }}
                        style={{ width: "60%" }}
                        size="small"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Nachname"
                        variant="outlined"
                        value={lastname ?? ``}
                        onChange={(v) => {
                          setLastname(v.currentTarget.value);
                        }}
                        style={{ width: "60%" }}
                        size="small"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="PRN"
                        type="number"
                        variant="outlined"
                        value={prn ?? 0}
                        onChange={(v) => {
                          setPRN(+v.currentTarget.value);
                        }}
                        style={{ width: "60%" }}
                        size="small"
                        margin="dense"
                      />
                    </Grid>
                    {Object.entries(ROLES).map(([key, role]) => {
                      return (
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={handleUserRoleToggle(role)}
                                checked={userRoles.indexOf(role) !== -1}
                              />
                            }
                            label={key}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {result !== null && (
                  <Grid
                    container
                    style={{ alignContent: "center", alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontWeight: "500",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`Neuer Mitarbeiter`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: "300",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`UID`}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontWeight: "500",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`${result.uid}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: "300",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`Vorname`}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontWeight: "500",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`${firstname}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: "300",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`Nachname`}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontWeight: "500",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`${lastname}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: "300",
                          marginBottom: 5,
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {`Reset-Link`}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <IconButton
                        className={"c2c"}
                        data-clipboard-text={result.resetLink}
                      >
                        <FileCopy fontSize={"small"} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={cleanUserModal} color="primary">
              Abbrechen
            </Button>
            <Button onClick={addUser} color="primary">
              Mitarbeiter anlegen
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
    </PageContainer>
  );
};

export default withRole(UserRole.Admin)(UsersModule);
